import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Header, Image, Card, Form, Icon ,Modal,Button,Divider} from "semantic-ui-react";
import * as config from "../config";
import { postLogin ,sendMailResetPassword,postLoginGoogle} from "./service";
import { GoogleLogin } from 'react-google-login';

class Login extends Component {
  state = {
    data: {
      username: {
        value: "",
        validation: {
          required: true
        },
        valid: false
      },
      password: {
        value: "",
        validation: {
          required: true,
          minLength: 6
        },
        valid: false
      }
    },
    errormsg: null
  };

  componentDidMount() {
    // call api
  }

  forgotPassword = () => {
    this.setState({ loading_sendmail: true})
    sendMailResetPassword({"username":this.state.username_forgot}).then(res => {
      if(res.status === 404){
        this.setState({
          errormsg_sendmail: res.data.message,
          loading_sendmail: false
        });
      }else if (res.status === 200) {
        this.setState({
          msg_sendmail: res.data.message,
          loading_sendmail: false
        });
      }
    })
  }
  handleChange = (e, { value }) => this.setState({ username_forgot:value })

  onChangeHandler = e => {
    var target = e.target;
    const { data } = this.state;
    data[target.name].value = target.value;
    this.setState({
      data: data
    });
  };
  checkValidate = (value, rule) => {
    let isValid = false;
    if (rule.required) {
      isValid = value.trim() === "";
    }
    if (rule.minLength) {
      isValid = value.length < rule.minLength;
    }
    return isValid;
  };

  login = () => {
    const { data } = this.state;
    this.setState({ errormsg: null, loading: true });
    var x, error;
    for (x in data) {
      data[x].valid = this.checkValidate(data[x].value, data[x].validation);
      if (data[x].valid) error = true;
    }
    if (!error) {
      let postdata = {
        username: data.username.value,
        password: data.password.value
      };
      postLogin(postdata).then(res => {
        if (res && res.status === 200) {
          localStorage.setItem("bac_access_token", res.data.access_token);
          localStorage.setItem("bac_roles", JSON.stringify(res.data.roles));
          localStorage.setItem(
            "bac_permission",
            JSON.stringify(res.data.permission)
          );
          localStorage.setItem(
            "bacUserData",
            JSON.stringify({
              id: res.data.id,
              imageUrl:res.data.imageUrl,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phoneNumber: res.data.phone,
              emailAddress: res.data.email
            })
          );
          localStorage.setItem("login", "Y");
          window.location.reload();
          // this.props.history.push("/Home");
        } else {
          this.setState({
            errormsg: res.data.message,
            loading: false
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
    this.setState({ data });
  };

  responseGoogle = (response) => {
    const { data } = this.state;
    this.setState({ errormsg: null, loading: true });
    postLoginGoogle(response).then(res => {
        if (res && res.status === 200) {
          localStorage.setItem("bac_access_token", res.data.access_token);
          localStorage.setItem("bac_roles", JSON.stringify(res.data.roles));
          localStorage.setItem(
            "bac_permission",
            JSON.stringify(res.data.permission)
          );
          localStorage.setItem(
            "bacUserData",
            JSON.stringify({
              id: res.data.id,
              imageUrl:res.data.imageUrl,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              phoneNumber: res.data.phone,
              emailAddress: res.data.email
            })
          );
          localStorage.setItem("login", "Y");
          window.location.reload();
          // this.props.history.push("/Home");
        } else {
          this.setState({
            errormsg: res.data.message,
            loading: false
          });
        }
      });
  }
  onFailure = (response) => {
     this.setState({
            errormsg: response.error,
            loading: false
    });
  }

  render() {
    const { data, errormsg, loading ,open,errormsg_sendmail,loading_sendmail,msg_sendmail} = this.state;
    return (
      <Grid
        textAlign="center"
        style={{
          height: "100vh",
          backgroundColor: config.colorTheme,
          margin: "0px"
        }}
      >
        <Grid.Column
          textAlign="center"
          computer="5"
          tablet="8"
          mobile="14"
          verticalAlign="middle"
        >
          <Card fluid raised>
            <Card.Content style={{ padding: 25 }}>
              <Image
                src="../img/logo-bac.png"
                style={{
                  height: "15vh",
                  marginTop: "20px",
                  marginBottom: "14px",
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
              />
              <Card.Header style={{ color: config.colorTheme }}>
                Bangkok Aviation Center
              </Card.Header>
              <Card.Meta>
                <span>Log in below to access your account.</span>
              </Card.Meta>
              <br />
              <Form>
                <Form.Input
                  type="text"
                  name="username"
                  placeholder="Email/Username"
                  onChange={this.onChangeHandler}
                  error={data.username.valid}
                />
                <Form.Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.onChangeHandler}
                  error={data.password.valid}
                />
                {errormsg && (
                  <p
                    style={{
                      color: "#F44336",
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "90%"
                    }}
                  >
                    {errormsg}
                  </p>
                )}
                    <Form.Field>
                      <a onClick={()=>this.setState({open:true,errormsg_sendmail:null,msg_sendmail:null})}>
                      Forgot your password?
                    </a>
                  </Form.Field>
              </Form>

            </Card.Content>
             <Card.Content>
              <Button
                fluid
                onClick={this.login} 
                style={{background:config.colorTheme,color: '#ffffff'}}
                content={loading ? (
                  <Icon loading size="large" name="spinner" />
                  ) : "LOGIN"
                  }
              />
              <Divider horizontal>Or</Divider>
             <GoogleLogin
               clientId="559614217028-qofcftpnilkttk3shec5f245cvd2e1th.apps.googleusercontent.com"
               // theme='dark'
               scope='profile email'
               onSuccess={this.responseGoogle}
               onFailure={this.onFailure}
               cookiePolicy={'single_host_origin'}
               // isSignedIn={true}
             />
             </Card.Content>
          </Card>
        </Grid.Column>
        <Modal open={open} centered={false} size='mini'
              dimmer="blurring"
              closeIcon={true}
              closeOnDimmerClick={false}
              onClose={()=>this.setState({open:false,errormsg_sendmail:null,msg_sendmail:null})}
          >
          {msg_sendmail ?
            <Modal.Content style={{textAlign:'center'}} >
            <Header as='h2' icon>
               <Icon name='mail outline' color='green'/>
               <span style={{color:config.colorTheme}}> FORGOT YOUR PASSWORD </span>
                <Header.Subheader>
                 {msg_sendmail}
               </Header.Subheader>
             </Header>
            </Modal.Content>
            :   <Modal.Content style={{textAlign:'center'}} >
                  <Header as='h2' icon>
                     <Icon name='lock' />
                     <span style={{color:config.colorTheme}}> FORGOT YOUR PASSWORD </span>
                   </Header>
                 <Form>
                   <Form.Input
                     type="text"
                     name="username_forgot"
                     placeholder="Email/Username"
                     width={16}
                     onChange={this.handleChange}
                   />
                   {errormsg_sendmail && (
                     <p
                       style={{
                         color: "#F44336",
                         textAlign: "left",
                         fontWeight: "bold",
                         fontSize: "90%"
                       }}
                     >
                       {errormsg_sendmail}
                     </p>
                   )}
                 <Button content='Send' loading={loading_sendmail} primary fluid onClick={this.forgotPassword}/>
                 </Form>
              </Modal.Content>
          }
            </Modal>
      </Grid>
    );
  }
}

export default withRouter(Login);
