import axios from "axios";
import * as config from "../../config";

export function getInstructorListBySearch(search) {
  return axios
    .get(config.urlAPI + "/instructors/perpage" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postPersonnel(data) {
  return axios
    .post(config.urlAPI + "/personnel", data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postInstructors(data) {
  return axios
    .post(config.urlAPI + "/instructors", data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updatePersonnel(data) {
  return axios
    .put(config.urlAPI + "/personnel/" + data.id, data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateInstructors(data) {
  return axios
    .put(config.urlAPI + "/instructor/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}


export function getInstructorById(id) {
  return axios
    .get(config.urlAPI + "/instructor/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getInstructorsWork() {
  return axios
    .get(config.urlAPI + "/instructorsWork")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getInstructorDocById(id) {
  return axios
    .get(config.urlAPI + "/instructorsDoc/" + id)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getInstructorTypes() {
  return axios
    .get(config.urlAPI + "/personnel/instructorstype", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getCourse() {
  return axios
    .get(config.urlAPI + "/course")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCourseModule() {
  return axios
    .get(config.urlAPI + "/courseModule")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getCheckCourse(search) {
  return axios
    .get(config.urlAPI + "/subject" + search)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getAllNotes(id) {
  return axios
    .get(config.urlAPI + "/instructornote/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getAllGroup() {
  return axios
    .get(config.urlAPI + "/group")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getAllCompany() {
  return axios
    .get(config.urlAPI + "/company")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postNoteInstructor(id, data) {
  return axios
    .post(
      config.urlAPI + "/instructornote/" + id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteNoteInstructor(id) {
  return axios
    .delete(
      config.urlAPI + "/instructornote/" + id,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function uploadImageProfile(id, data) {
  return axios
    .post(
      config.urlAPI + "/user/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteImageProfile(id) {
  return axios
    .delete(
      config.urlAPI + "/user/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getLogbookByID(id,search) {
  return axios
    .get(config.urlAPI + "/totallogbookIP/"+ id + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function exportLogbook(id,search) {
  return axios
    .get(config.urlAPI + "/totallogbookIP/export/"+ id + search,{
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
          Accept: "application/json"
        }
      }
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
