import React, { Component } from "react";
import { Form, Button, Table, Icon,Loader } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import moment from "moment";
import Wrap from "../../components/wrap";

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = true;
  // if (!values.desc) errors.desc = true;
  if (!values.groundinstructiontype) errors.groundinstructiontype = true;

  return errors;
};

class FormcourseModule extends Component {
  state = {
    option_subject: this.props.option_subject,
    option_flightSubject: this.props.option_flightSubject,
    option_flightAddSubject: this.props.option_flightAddSubject
  };

  renderMembers = ({ fields }) => {
    return (
      <Wrap>
        {fields.map((member, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign="center" className="bold">
              Stage #{index + 1}
            </Table.Cell>
            <Table.Cell>
              <Field
                name={`${member}.stage`}
                component={renderSelectField}
                placeholder="Flight Subject"
                options={this.state.option_flightSubject}
                multiple
                search
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              {index !== 0 && (
                <Icon
                  link
                  size="small"
                  color="red"
                  name="trash alternate"
                  onClick={() => fields.remove(index)}
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell className="footer-table" textAlign="right">
            Total
          </Table.Cell>
          <Table.Cell className="footer-table" />
          <Table.Cell className="footer-table" textAlign="center">
            <Button
              color="blue"
              size="small"
              compact
              type="button"
              onClick={() => fields.push({})}
              content="Add"
            />
          </Table.Cell>
        </Table.Row>
      </Wrap>
    );
  };
  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      groundSubjects,
      flightSubjects,
      loading,
      fetching,
      masterModule,
      isNew,
      moduleStart
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
         <Field
          name="name"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Module Name"
        />
        {!masterModule && <Field
          name="desc"
          type="text"
          // required={true}
          width="16"
          component={renderField}
          label="Module Description"
        />}
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table" width={2} />
              <Table.HeaderCell className="header-table" width={14}>
                Ground Subjects
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {fetching ? (
            <Table.Row>
              <Table.Cell textAlign="right"> </Table.Cell>
              <Table.Cell textAlign="left" colSpan="2">
                Loading..
              </Table.Cell>
            </Table.Row>
          ) :
            <Table.Row>
              <Table.Cell textAlign="right" />
              <Table.Cell>
                <Field
                  name="groundSubjects"
                  component={renderSelectField}
                  placeholder="Ground Subject"
                  options={state.option_subject}
                  multiple
                  search
                />
              </Table.Cell>
            </Table.Row>
          }
          </Table.Body>
        </Table>
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table" width={2} />
              <Table.HeaderCell className="header-table" width={14}>
                Flight Subjects
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {fetching ? (
            <Table.Row>
              <Table.Cell textAlign="right"> </Table.Cell>
              <Table.Cell textAlign="left" colSpan="2">
                Loading..
              </Table.Cell>
            </Table.Row>
          ) : <Wrap>
            <Table.Row>
              <Table.Cell textAlign="center" className="bold" />
              <Table.Cell>
                <Field
                  name="flightSubjects"
                  component={renderSelectField}
                  placeholder="Flight Subject"
                  options={state.option_flightSubject}
                  multiple
                  search
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="center" className="bold">
                Additional
              </Table.Cell>
              <Table.Cell>
                <Field
                  name="flightAddSubjects"
                  component={renderSelectField}
                  placeholder="Additional Subject"
                  options={state.option_flightAddSubject}
                  multiple
                  search
                />
              </Table.Cell>
            </Table.Row>
            </Wrap>
          }
          </Table.Body>
        </Table>

        <br />
        <Button
          floated="left"
          compact
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          compact
          type="button"
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }

  totalDurations = data => {
    let durations = [];
    data.map(item => durations.push(item.hours));
    const totalDurations = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );

    return this.convertMS(totalDurations.asMilliseconds());
  };

  convertMS = ms => {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return ((h + "").length === 1 ? "0" + h : h) + ":" + ("0" + m).substr(-2);
  };
}

FormcourseModule = reduxForm({
  // a unique name for the form
  form: "coursemodule",
  validate,
  enableReinitialize: true
})(FormcourseModule);

// Decorate with connect to read form values
const selector = formValueSelector("coursemodule"); // <-- same as form name
FormcourseModule = connect(state => {
  const groundSubjects = selector(state, "groundSubjects");
  const flightSubjects = selector(state, "flightSubjects");

  return {
    groundSubjects,
    flightSubjects
  };
})(FormcourseModule);
export default FormcourseModule;
