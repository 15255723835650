import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Segment, Image, Button, Icon } from "semantic-ui-react";
import * as config from "../../config";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import TableList from "../../components/tablePerPage";
import ModalForm from "../../components/modal";
import Wrap from "../../components/wrap";

import {
  getPersonnel,
  getRoles,
  postPersonnel,
  updatePersonnel,
  deleteUser,
  resetpassUser
} from "./service";
import DropdownCheckbox from "../../components/dropdownCheckbox";
import FormUser from "./userForm";
import Confirmmodal from "../../components/confirmmodal";

class User extends Component {
  state = {
    data: [],
    open: false,
    loading: true,
    options_roles: [],
    options_rolesfilter:[],
    dataEdit: null,
    checked_roles: [],
    current_page: 0,
    per_page: 10,
    last_page: 1,
    typeConfirm: null,
    filterRolesByText: "All Roles",
    search: {
      name: "",
      status: "Active",
      role: "",
      sort: {
        key: "firstNameEng",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC "
      }
    },
    sortText: "Name [ASC]",
    options_sortby: [
      {
        key: "firstNameEng",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC"
      },
      {
        key: "firstNameEng",
        text: "Name",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC"
      }
    ],
    column: [
      {
        Header: "Image",
        accessor: "image",
        style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <Image
            avatar
            size="mini"
            verticalAlign="middle"
            src={cellInfo.original.imageURL}
            onError={e => {
              e.target.onerror = null;
              e.target.src =
                "https://app.flightschedulepro.com/images/instructor-default.gif";
            }}
          />
        ),
        width: 60
      },
      {
        Header: "Name",
        accessor: "name",
        sortable: false,
        width: 280,
        Cell: cellInfo => (
          <Wrap>
            <span
              className="bold"
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickEdit(cellInfo.original)}
            >
              {`${cellInfo.original.firstNameEng} ${cellInfo.original.lastNameEng}`}
            </span>
            <br />
          </Wrap>
        )
      },
      {
        Header: "Username",
        accessor: "username",
        sortable: false,
        width: 150
      },
      {
        Header: "Roles",
        accessor: "rolesName",
        style: { textAlign: "left" },
        sortable: false,
        Cell: cellInfo => (
          <span>
            {cellInfo.original && cellInfo.original.rolesName
              ? cellInfo.original.rolesName.toString()
              : "-"}
          </span>
        ),
        width: 200
      },
      {
        Header: "Phone",
        accessor: "phone",
        sortable: false,
        Cell: cellInfo => (
          <Wrap>
            <p>{cellInfo.original.phone}</p>
          </Wrap>
        ),
        width: 150
      },
      {
        Header: "Email",
        accessor: "email",
        sortable: false,
        style: { textAlign: "center" },
        Cell: cellInfo => (
          <span>
            {cellInfo.original.email ? (
              <a href={`mailto:${cellInfo.original.email}`}>
                  <Icon link circular color="red" name="mail"  />
              </a>
            ) : (
                <Icon circular color="grey" name="mail" />
              )}
          </span>
        ),
        width: 70
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <Wrap>
            {/* <Icon link circular color='grey' name='key' onClick={() => this.onClickChangePassword(cellInfo.original)} /> */}
            <Icon
              link
              name="key"
              onClick={() => this.onClickResetPassword(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              // circular
              // color="orange"
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </Wrap>
        ),
        width: 150
      }
    ]
  };

  componentDidMount() {
    this.getData();
    let options = [],
      options_rolesfilter=[
        { key: 'Instructor', text: 'Instructor' , value: 'Instructor'  }
      ],
      checked_roles = ['Instructor'];
    getRoles().then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          if (item.name !== "Student") {
            //วานีแก้ ไม่เอานักเรียน
            options.push({ key: item.id, text: item.name, value: item.name });
            checked_roles.push(item.name);
          }
        });
        options_rolesfilter.push(...options)
        this.setState({
          options_roles: options,
          options_rolesfilter:options_rolesfilter,
          roles: res.data,
          checked_roles
        });
      }
    });
  }
  onClickResetPassword = value => {
    this.setState({
      openConfirmReset: true,
      currentData: value
    });
  }
  resetPassword = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      resetpassUser(currentData.id).then(res => {
        if (res && res.status === 200) {
          let qString = '?isActive=true'
          this.getData(qString)
          this.setState({ openConfirmReset: false });
        }
        this.setState({ loading: false });
      });
  }
  close = () =>
    this.setState({ dataEdit: null, open: false, openChangePassword: false });
  onClickEdit = data => {
    data.roles = data.rolesName;
    if (data.isActive === "true") this.setState({ activeUser: true });
    else this.setState({ activeUser: false });
    this.setState({ open: true, dataEdit: data });
  };
  onClickChangePassword = () => {
    this.setState({ openChangePassword: true });
  };
  onSubmitAdd = data => {
    this.setState({ errorMessage: null, loading: true });
    postPersonnel(data).then(res => {
      if (res && res.status === 201) {
        this.getData();
        this.close();
      } else if (res && res.status === 409) {
        this.setState({ errorMessage: "Username or Email already exists." });
      }
      this.setState({ loading: false });
    });
  };
  onSubmitEdit = data => {
    this.setState({ errorMessage: null, loading: true });
    let {activeUser} = this.state
    if(activeUser) data.isActive = 'true'
    else data.isActive = 'false'
    updatePersonnel(data).then(res => {
      if (res && res.status === 200) {
        this.close();
        this.getData();
      } else if (res && res.status === 409) {
        this.setState({ errorMessage: "Username or Email already exists." });
      }
      this.setState({ loading: false });
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirmDelete: true,
      currentData: value
    });
  };
  deleteData = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteUser(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.setState({ openConfirmDelete: false });
        }
        this.setState({ loading: false });
      });
  };

    // วานี ปิด เสิช เพราะพี่ส่งเปน perpage ต้องไปเสิชที่api
  // วานีแก้ให้คิวรี่แบบperpage
  getData = qString => {
    this.setState({ loading: true });
    let { per_page, current_page, search } = this.state;
    current_page = current_page + 1;
    if (qString) {
      if (search.status === "Active") qString = qString + "&isActive=true";
      else qString = qString + "&isActive=false";
    } else {
      if (search.status === "Active") qString = "?isActive=true";
      else qString = "?isActive=false";
    }

    qString = qString + "&_perpage=" + per_page + "&page=" + current_page;

    if (search['name']) {
      qString += "&name=" + search.name
    }
    getPersonnel(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data.data,current_page:res.data.current_page -1 ,last_page:res.data.last_page});
      }
      this.setState({ loading: false });
    });
  };
  onDisable = () => {
    let { dataEdit } = this.state;
    this.setState({ loadingUp: true });
    dataEdit.isActive = "false";
    updatePersonnel(dataEdit).then(res => {
      if (res && res.status === 200) {
        this.setState({ activeUser: false, openConfirm: false });
        this.getData();
      }
      this.setState({ loadingUp: false });
    });
  };
  onEnable = () => {
    let { dataEdit } = this.state;
    this.setState({ loadingUp: true });
    dataEdit.isActive = "true";
    updatePersonnel(dataEdit).then(res => {
      if (res && res.status === 200) {
        this.setState({ activeUser: true, openConfirm: false });
        this.getData();
      }
      this.setState({ loadingUp: false });
    });
  };
  onConfirm = (type) => { this.setState({ openConfirm: true, typeConfirm: type }) }
  render() {
    const state = this.state;
    return (
      <div>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirmDelete}
          title="User"
          onClose={() => this.setState({ openConfirmDelete: false })}
          onComfirm={this.deleteData}
        />
        <Confirmmodal
            loading={state.loading}
            open={state.openConfirmReset}
            actionText='Reset password'
            title="User"
            onClose={() => this.setState({ openConfirmReset: false })}
            onComfirm={this.resetPassword}
          />
        <Segment className="contentSegment" textAlign="left">
          {/* <Header as='h2' style={{ color: config.colorTheme }}>Instructors</Header> */}
          <HeaderWithMenu name="User (Instructors / Staff)">
            <MainButton
              size="small"
              onClick={() => this.setState({ open: true })}
              text="Add User"
            />
          </HeaderWithMenu>
          <Form>
            <Form.Group>
              <Form.Input
                width='3'
                icon='search'
                size='small'
                placeholder='Search'
                name='name'
                onChange={this.handleChangeSearch} />
              <Form.Field width="3">
                <DropdownCheckbox
                  style={{ textAlign: "left" }}
                  checked={state.checked_roles}
                  options={state.options_rolesfilter}
                  onClick={this.filterByRoles}
                >
                  <Button
                    fluid
                    size="small"
                    icon
                    basic
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    {state.filterRolesByText}
                    <Icon name="angle down" />
                  </Button>
                </DropdownCheckbox>
              </Form.Field>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='3'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                selectOnBlur={false}
                onChange={this.handleChangeSort}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='3'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{state.search.status}</b>
                  </Button>
                }
                options={config.options_active}
                defaultValue={state.search.status}
                selectOnBlur={false}
                onChange={this.handleChangeStatus}
              />
              {/* <Form.Field width='5' style={{ textAlign: 'right' }}>
                            <MainButton
                                size='small'
                                onClick={this.show}
                                text='Add Instructors' />
                        </Form.Field> */}
            </Form.Group>
          </Form>
          <ModalForm
            icon="add user"
            content={state.dataEdit ? "Edit Personnel" : "Add Personnel"}
            open={state.open}
            close={this.close}
          >
            <FormUser
              activeUser={state.activeUser}
              errorMessage={state.errorMessage}
              // onDisable={this.onDisable}
              // onEnable={this.onEnable}
              onDisable={() => this.onConfirm('Disable')}
              onEnable={() => this.onConfirm('Enable')}
              loadingUp={state.loadingUp}
              isEdit={state.dataEdit ? true : false}
              loading={state.loading}
              initialValues={state.dataEdit ? state.dataEdit : null}
              options_roles={state.options_roles}
              onSubmit={state.dataEdit ? this.onSubmitEdit : this.onSubmitAdd}
              onCancel={this.close}
            />
          </ModalForm>
          <ModalForm
            size="mini"
            icon="key"
            content="Change Password"
            open={state.openChangePassword}
            close={this.close}
            style={{ marginBottom: 0 }}
          >
            <Form>
              <Form.Input
                size="small"
                name="password"
                label="Old Password"
              // onChange={this.handleChangeSearch}
              />
              <Form.Input
                size="small"
                name="newpassword"
                label="New Password"
              // onChange={this.handleChangeSearch}
              />
              <Form.Input
                size="small"
                name="comfirmpassword"
                label="Confirm Password"
              // onChange={this.handleChangeSearch}
              />
              <Button
                floated="left"
                type="submit"
                content="Save"
                color="green"
              />
              <Button
                floated="right"
                type="button"
                content="Cancel"
                color="green"
                basic
              />
            </Form>
          </ModalForm>
          <Confirmmodal
            loading={state.loading}
            open={state.openConfirm}
            title={state.typeConfirm}
            onClose={() => this.setState({ openConfirm: false })}
            onComfirm={state.typeConfirm === 'Disable' ? this.onDisable : this.onEnable}
          />
          <TableList
            data={state.data}
            columns={state.column}
            loading={state.loading}
            pageSize={state.per_page}
            page={state.current_page}
            pages={state.last_page}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
          />
        </Segment>
      </div>
    );
  }

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({
      per_page: pageSize,current_page : pageIndex
    }, () => {
        this.getData();
    });
  };

  onPageChange = pageIndex => {
    this.setState({
    current_page : pageIndex
    }, () => {
        this.getData();
    });

  };

  handleChangeSearch = (e) => {
      let { search } = this.state;
      let active;
      this.setState({loading:true})
      if(this._timeout){ //if there is already a timeout in process cancel it
        clearTimeout(this._timeout);
      }
      const val = e.target.value;
      const name = e.target.name
      this._timeout = setTimeout(()=>{
         this._timeout = null;
         search[name] = val
         let qString = "?name="+ val
         if (search.status === "Active") active = true;
         else active = false;
         qString += "&isActive=" + active;

         if (search["role"]) qString += "&role[]=" + search["role"];

         if (search["sort"])
           qString +=
             "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

           this.getData(qString);
      },2000);
  }
  handleChangeStatus = (e, data) => {
    let { search } = this.state;
    let active, qString;

    if (data.value === "Active") active = true;
    else active = false;

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search });
    qString = "?isActive=" + active;

    if (search["name"]) qString += "&name=" + search["name"];
    if (search["role"]) qString += "&role[]=" + search["role"];

    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    this.getData(qString);
  };
  filterByRoles = data => {
    let { search, options_rolesfilter } = this.state;
    let active;

    if (search.status === "Active") active = true;
    else active = false;

    let qString = "?isActive=" + active;
    let checked_roles = [];

    if (data)
      data.map(item => {
        qString += `&role[]=${item}&`;
        checked_roles.push(item);
      });
    if (data.length === options_rolesfilter.length) {
      this.setState({ filterRolesByText: "All Roles" });
    } else {
      this.setState({ filterRolesByText: `Roles (${data.length})` });
    }
    this.setState({ checked_roles });
    if (search["name"]) qString += "&name=" + search["name"];
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    // console.log(qString)
    this.getData(qString);
  };
  handleChangeSort = (e, data) => {
    let { search } = this.state;
    let active, qString;

    if (search.status === "Active") active = true;
    else active = false;

    const result = data.options.find(member => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`
    });

    qString =
      "?isActive=" +
      active +
      "&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    if (search["name"]) qString += "&name=" + search["name"];
    if (search["group"]) qString += "&role[]=" + search["role"];
    // console.log('in sort',qString)
    this.getData(qString);
  };
}

export default withRouter(User);
