import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Loader,
  Icon,
  Form,
  Button,
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import FromGroundSubjects from "./courseSubjectsForm";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";
import TableList from "../../components/tablePerPage";

import {
  getSubjects,
  postSubjects,
  updateSubjects,
  getCourseMasterList,
  deleteSubject,
} from "./service";

class GroundSubjects extends Component {
  state = {
    current_page: 0,
    per_page: 10,
    last_page: 1,
    rawData: [],
    data: [],
    loading: true,
    search: {
      sort: {
        key: "name",
        text: "Subjects",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
    },
    sortText: "Subjects (ASC)",
    filterText: "All Course",
    options_sortby: [
      {
        key: "name",
        text: "Subjects",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
      {
        key: "name",
        text: "Subjects",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "hours",
        text: "Hours",
        value: "3",
        icon: "sort numeric ascending",
        description: "ASC ",
      },
      {
        key: "hours",
        text: "Hours",
        value: "4",
        icon: "sort numeric descending",
        description: "DESC",
      },
    ],
    options_course: [],
    options_courseAll: [
      {
        key: "All Course",
        text: "All Course",
        value: "All",
      },
    ],
    column: [
      {
        Header: "Code",
        accessor: "name",
        sortable: false,
        maxWidth: 100,
        style: { fontWeight: "bold", cursor: "pointer", textAlign: "center" },
        Cell: (cellInfo) => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.code}
            </p>
          </span>
        ),
      },
      {
        Header: "Subject",
        accessor: "name",
        sortable: false,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: (cellInfo) => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.name}
            </p>
          </span>
        ),
      },
      {
        Header: "Hours",
        accessor: "hours",
        sortable: false,
        style: { textAlign: "center" },
        width: 250,
      },
      {
        Header: "Material Requirement",
        accessor: "material",
        sortable: false,
      },
      {
        Header: "Ref. Course",
        accessor: "reference",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "",
        accessor: "checkSubject",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <span>
            <p className="bold">
              {cellInfo.original.checkSubject && "GROUND CHECK"}
            </p>
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <span>
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        ),
      },
    ],
  };

  componentDidMount() {
    let { search, options_courseAll, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;

    this.getData(qString);
    getCourseMasterList().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: item.name,
            value: item.name,
          });
        });
        options_courseAll.push(...options);
        this.setState({
          options_course: options,
          options_courseAll: options_courseAll,
        });
      }
    });
  }

  getData = (qString) => {
    getSubjects(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data.data,
          current_page: res.data.current_page - 1,
          last_page: res.data.last_page,
        });
      }
      this.setState({ loading: false });
    });
  };

  onClickNew = () => {
    this.setState({
      open: true,
      currentData: null,
      isNew: true,
      content: "New Ground Subjects",
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, currentData: null, isNew: false });
  };
  onClickEdit = (value) => {
    if (value.reference) value.course = value.reference.split(",");
    this.setState({
      open: true,
      currentData: value,
      isNew: false,
      content: "Ground Subjects",
    });
  };
  onClickDelete = (value) => {
    this.setState({
      openConfirm: true,
      currentData: value,
    });
  };
  deleteData = () => {
    let { search, per_page, current_page, currentData } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    this.setState({ loading: true });
    if (currentData && currentData.id) {
      deleteSubject(currentData.id).then((res) => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getData(qString);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
    }
  };
  saveData = (value) => {
    this.setState({ loading: true });

    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    let reference = "";
    if (value.course && value.course.length > 0)
      reference = value.course.toString();
    let data = {
      code: value.code,
      name: value.name,
      hours: value.hours,
      type: "G",
      checkSubject: value.checkSubject,
      material: value.material,
      reference: reference,
    };
    postSubjects(data).then((res) => {
      if (res && res.status === 201) {
        this.getData(qString);
        this.onClickCancel();
      }

      this.setState({ loading: false });
    });
  };
  updateData = (value) => {
    this.setState({ loading: true });
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    let reference = null;
    if (value.course && value.course.length > 0)
      reference = value.course.toString();
    let data = {
      id: value.id,
      code: value.code,
      name: value.name,
      hours: value.hours,
      type: "G",
      checkSubject: value.checkSubject,
      material: value.material,
      reference: reference,
    };
    updateSubjects(data).then((res) => {
      if (res && res.status === 200) {
        this.getData(qString);
        this.onClickCancel();
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const state = this.state;
    let maxRow = Math.ceil(state.data.length / 2);
    if (maxRow < 10) maxRow = 10;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Ground Subjects">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Subjects"
            />
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>
            {/* <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section
                            onClick={() => this.props.history.push('/Course/Course')}
                            style={{ color: 'grey' }}>
                            Course
                    </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section
                            onClick={() => this.props.history.push('/Course/Module')}
                            style={{ color: 'grey' }}>
                            Module
                    </Breadcrumb.Section> */}
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Ground Subjects</Breadcrumb.Section>
            {/* <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section
                            onClick={() => this.props.history.push('/Course/FlightSubjects')}
                            style={{ color: 'grey' }}>
                            Flight Subjects
                    </Breadcrumb.Section> */}
          </Breadcrumb>
          <br />
          <br />
          <Form>
            <Form.Group>
              <Form.Input
                // width='4'
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                scrolling
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Filter by : <b>{state.filterText}</b>
                  </Button>
                }
                options={state.options_courseAll}
                defaultValue={state.search.filter}
                onChange={this.handleChangeFilter}
                selectOnBlur={false}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                onChange={this.handleChangeSort}
                selectOnBlur={false}
              />
            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <TableList
              loading={state.loading}
              data={state.data}
              defaultPageSize={20}
              columns={state.column}
              pageSize={state.per_page}
              page={state.current_page}
              pages={state.last_page}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
            />
          )}
        </Segment>
        <ModalForm
          open={state.open}
          icon="book"
          content={state.content}
          close={this.onClickCancel}
        >
          <FromGroundSubjects
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.isNew ? this.saveData : this.updateData}
            options_course={state.options_course}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Ground Subjects"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState(
      {
        per_page: pageSize,
        current_page: pageIndex,
      },
      () => {
        let { search, per_page, current_page } = this.state;
        current_page = current_page + 1;

        let qString =
          "/perpage?type[]=G&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description +
          "&_perpage=" +
          per_page +
          "&page=" +
          current_page;
        if (search.name) {
          qString = qString + "&name=" + search.name;
        }
        if (search.filter && search.filter !== "All") {
          qString = qString + "&course=" + encodeURIComponent(search.filter);
        }
        this.getData(qString);
      }
    );
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        current_page: pageIndex,
      },
      () => {
        let { search, per_page, current_page } = this.state;
        current_page = current_page + 1;
        let qString =
          "/perpage?type[]=G&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description +
          "&_perpage=" +
          per_page +
          "&page=" +
          current_page;
        if (search.name) {
          qString = qString + "&name=" + search.name;
        }
        if (search.filter && search.filter !== "All") {
          qString = qString + "&course=" + encodeURIComponent(search.filter);
        }
        this.getData(qString);
      }
    );
  };

  handleChangeSort = (e, data) => {
    let { search, per_page } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
      loading: true,
      current_page: 0,
    });
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=1";
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    if (search.filter && search.filter !== "All") {
      qString = qString + "&course=" + encodeURIComponent(search.filter);
    }
    this.getData(qString);
  };
  handleChangeFilter = (e, data) => {
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    search.filter = data.value;
    this.setState({ search, filterText: data.value });
    let qString =
      "/perpage?type[]=G&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    if (data.filter !== "All") {
      qString = qString + "&course=" + encodeURIComponent(data.value);
    }
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;

    this.setState({ loading: true });
    if (this._timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    const val = e.target.value;
    const name = e.target.name;
    search[name] = val;
    this.setState({ search });
    this._timeout = setTimeout(() => {
      this._timeout = null;
      let qString =
        "/perpage?type[]=G&_sort=" +
        search.sort.key +
        "&_order=" +
        search.sort.description +
        "&_perpage=" +
        per_page +
        "&page=" +
        current_page +
        "&name=" +
        val;
      if (search.filter && search.filter !== "All") {
        qString = qString + "&course=" + encodeURIComponent(search.filter);
      }
      this.getData(qString);
    }, 2000);
  };
}

export default withRouter(GroundSubjects);
