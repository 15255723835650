import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Card, Grid, Table, Statistic, List, Button } from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";


class Logbook extends Component {
  state = {
    data: this.props.data
  };

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps',nextProps);
    if (nextProps.data !== this.state.data) {
      // if (nextProps.course) {
      this.setState({ data: nextProps.data });
      // }
    }
  }
  componentDidMount() {
  }

  render() {
    const state = this.state;
    console.log('logbook', state.data);
    return (
      <div>
        <Grid>
          <Grid.Row columns='equal'>
            <Grid.Column>
              {state.data &&
                <Wrap>
                  <Grid>
                    <Grid.Row columns='equal'>
                      <Grid.Column style={{ textAlign: 'center' }}>
                        <Statistic color='orange' size='small'>
                          <Statistic.Label>FLIGHT INSTRUCTION</Statistic.Label>
                          <Statistic.Value>{state.data.flightinstruction || '00:00'} HR(S)</Statistic.Value>
                        </Statistic>
                        <Statistic color='orange' size='small'>
                          <Statistic.Label>FLIGHT HOUR</Statistic.Label>
                          <Statistic.Value>{state.data.flighthour || '00:00'} HR(S)</Statistic.Value>
                        </Statistic>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns='2'>
                    <Grid.Row >
                      <Grid.Column>
                        {state.data.logbook.length > 0 &&
                          <Table compact >
                            <Table.Header>
                              <Table.HeaderCell className="header-table">Flight Training</Table.HeaderCell>
                              <Table.HeaderCell className="header-table" textAlign='center'>Hour/Time</Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                              {state.data.logbook.map(item =>
                                item.group ?
                                  <Wrap>
                                    <Table.Row><Table.Cell colSpan='2'>{item.name}</Table.Cell></Table.Row>
                                    {item.group.length > 0 && item.group.map(grp =>
                                      <Table.Row>
                                        <Table.Cell> &emsp;{grp.name}</Table.Cell>
                                        <Table.Cell textAlign='center'>{grp.hours} {grp.unit}</Table.Cell>
                                      </Table.Row>
                                    )}
                                  </Wrap>
                                  : <Table.Row>
                                    <Table.Cell>{item.name}</Table.Cell>
                                    <Table.Cell textAlign='center'>{item.hours} {item.unit}</Table.Cell>
                                  </Table.Row>
                              )}
                            </Table.Body>
                          </Table>
                        }
                        {/*{state.data.logbook.length > 0 && state.data.logbook.map(item =>
                        <List as='ol'>
                          <List.Item as='li' value=''>
                            {item.group ?
                              <Wrap>
                                <b>{item.name}</b>
                                  <List.Item as='ol'>
                                    {item.group.length > 0 && item.group.map(grp =>
                                      <List.Item as='li' value='-'>
                                        {grp.name} &emsp; {grp.hours} {grp.unit}
                                      </List.Item>
                                    )}
                                  </List.Item>
                              </Wrap>
                              :<b>{item.name} &emsp; {item.hours} {item.unit}</b>
                            }
                          </List.Item>
                        </List>
                        )}*/}
                      </Grid.Column>
                      <Grid.Column>
                        <Grid.Row>
                          <Grid.Column>
                            {state.data.groundinstruction.length > 0 &&
                              <Table compact >
                                <Table.Header>
                                  <Table.HeaderCell className="header-table">Ground Training</Table.HeaderCell>
                                  <Table.HeaderCell className="header-table" textAlign='center'>Hour/Time</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                  {state.data.groundinstruction.map(item =>
                                    <Table.Row>
                                      <Table.Cell>{item.name}</Table.Cell>
                                      <Table.Cell textAlign='center'>{item.hours} {item.unit}</Table.Cell>
                                    </Table.Row>
                                  )}
                                </Table.Body>
                              </Table>
                            }
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            {state.data.logbooknontraning.length > 0 &&
                              <Table compact  style={{marginTop: 20}}>
                                <Table.Header>
                                  <Table.HeaderCell className="header-table">Flight Non-Training</Table.HeaderCell>
                                  <Table.HeaderCell className="header-table" textAlign='center'>Hour/Time</Table.HeaderCell>
                                </Table.Header>
                                <Table.Body>
                                  {state.data.logbooknontraning.map(item =>
                                    <Table.Row>
                                      <Table.Cell>{item.name}</Table.Cell>
                                      <Table.Cell textAlign='center'>{item.hours} {item.unit}</Table.Cell>
                                    </Table.Row>
                                  )}
                                </Table.Body>
                              </Table>
                            }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Wrap>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div >
    );
  }
}

export default withRouter(Logbook);
