import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Checkbox,
  Icon,
  Form,
  Breadcrumb,
  Modal,
  Button,
  Dropdown,
} from "semantic-ui-react";
import * as config from "../../config";
//config.options_report_reservation
import { DatesRangeInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import {
  genFlightReservation,
  exportFlightReservation,
  getReservationTypes,
  getAirport,
  getCourse,
  getAircraft,
  getInstructor,
} from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class FlightReservationReport extends Component {
  state = {
    date:
      moment().subtract(1, "days").format("YYYY-MM-DD") +
      " - " +
      moment().format("YYYY-MM-DD"),
    instructor: [],
    course: [],
    status: [],
    reservationType: [],
    aircraft: [],
    departure: [],
    destination: [],
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    log: false,
    logcolumn: [
      {
        Header: "Log",
        accessor: "history",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.history ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.history
                    .replace(/\r\n/g, "<br />")
                    .replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
    ],
    column: [
      {
        Header: "Start",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        width: 180,
        Cell: (cellInfo) => <span className="">{cellInfo.original.start}</span>,
      },
      {
        Header: "End",
        accessor: "end",
        // sortable: 180,
        style: { textAlign: "center" },
        width: 180,
        Cell: (cellInfo) => <span className="">{cellInfo.original.end}</span>,
      },
      {
        Header: "Aircraft",
        accessor: "aircraftName",
        sortable: false,
        style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.aircraftName}</span>
        ),
      },
      {
        Header: "Call Sign",
        accessor: "callSign",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.callSign}</span>
        ),
      },
      {
        Header: "Course",
        accessor: "course",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.course}</span>
        ),
      },
      {
        Header: "Subject",
        accessor: "subject",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.subject}</span>
        ),
      },
      {
        Header: "Instructor",
        accessor: "instructor",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.instructor}</span>
        ),
      },
      {
        Header: "Student",
        accessor: "student",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.student}</span>
        ),
      },
      {
        Header: "Student Observer",
        accessor: "studentObserver",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.studentObserver}</span>
        ),
      },
      {
        Header: "Instructor Observer",
        accessor: "instructorObserver",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.instructorObserver}</span>
        ),
      },
      {
        Header: "Destination",
        accessor: "destination",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.destination}</span>
        ),
      },
      {
        Header: "Departure",
        accessor: "departure",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.departure}</span>
        ),
      },
      {
        Header: "Area",
        accessor: "area",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.area}</span>,
      },
      {
        Header: "Flight Rules",
        accessor: "flightRules",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.flightRules}</span>
        ),
      },
      {
        Header: "Flight Type",
        accessor: "flightType",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.flightType}</span>
        ),
      },
      {
        Header: "Flight Instruction",
        accessor: "flightInstruction",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.flightInstruction}</span>
        ),
      },
      {
        Header: "SingleEngine",
        accessor: "singleEngines",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.singleEngines}</span>
        ),
      },
      {
        Header: "MultiEngine",
        accessor: "multiEngines",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.multiEngines}</span>
        ),
      },
      {
        Header: "Comment",
        accessor: "comments",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.comments}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.status}</span>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.created_at}</span>
        ),
      },
      {
        Header: "Created By",
        accessor: "created_by",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.created_by}</span>
        ),
      },
    ],
    columnFix: [
      {
        Header: "Reservation Type",
        accessor: "reservationtype",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.reservationtype}</span>
        ),
      },
      {
        Header: "Reservation number",
        accessor: "reservationnumber",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.reservationnumber}</span>
        ),
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const {
      date,
      course,
      status,
      reservationType,
      aircraft,
      departure,
      destination,
      log,
      instructor,
    } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (status.length > 0) {
      status.map((tmp) => {
        qString += "&status[]=" + tmp;
      });
    }
    if (reservationType.length > 0) {
      reservationType.map((tmp) => {
        qString += "&reservationType[]=" + tmp;
      });
    }
    if (aircraft.length > 0) {
      aircraft.map((tmp) => {
        qString += "&aircraft[]=" + tmp;
      });
    }
    if (departure.length > 0) {
      departure.map((tmp) => {
        qString += "&departure[]=" + tmp;
      });
    }
    if (destination.length > 0) {
      destination.map((tmp) => {
        qString += "&destination[]=" + tmp;
      });
    }
    if (log) {
      qString += "&log";
    }
    if (instructor.length > 0) {
      instructor.map((tmp) => {
        qString += "&instructor[]=" + tmp;
      });
    }
    exportFlightReservation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "FlightReservation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "FlightReservation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const {
      date,
      course,
      status,
      reservationType,
      aircraft,
      departure,
      destination,
      log,
      instructor,
    } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (status.length > 0) {
      status.map((tmp) => {
        qString += "&status[]=" + tmp;
      });
    }
    if (reservationType.length > 0) {
      reservationType.map((tmp) => {
        qString += "&reservationType[]=" + tmp;
      });
    }
    if (aircraft.length > 0) {
      aircraft.map((tmp) => {
        qString += "&aircraft[]=" + tmp;
      });
    }
    if (departure.length > 0) {
      departure.map((tmp) => {
        qString += "&departure[]=" + tmp;
      });
    }
    if (destination.length > 0) {
      destination.map((tmp) => {
        qString += "&destination[]=" + tmp;
      });
    }
    if (log) {
      qString += "&log";
    }
    if (instructor.length > 0) {
      instructor.map((tmp) => {
        qString += "&instructor[]=" + tmp;
      });
    }
    // console.log('qString',qString);
    this.getData(qString);
  };
  handleChangeDate = (event, { name, value }) => {
    // if (this.state.hasOwnProperty(name)) {
    if (name === "log") {
      this.setState((prevState) => ({ log: !prevState.log }));
    } else {
      this.setState({ [name]: value });
    }
    // }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genFlightReservation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date } = this.state;

    getReservationTypes("?displayType[]=0&displayType[]=1").then((res) => {
      if (res && res.status === 200) {
        let option = [];
        if (res.data.length > 0) {
          res.data.map((type) => {
            option.push({ key: type.name, text: type.name, value: type.id });
          });
        }
        this.setState({ options_reservation_type: option });
      }
    });
    getAirport().then((res) => {
      if (res && res.status === 200) {
        let option = [];
        if (res.data.length > 0) {
          res.data.map((type) => {
            option.push({ key: type.name, text: type.name, value: type.id });
          });
        }
        this.setState({ options_airport: option });
      }
    });
    getCourse("?additionalCourse=false&active=true").then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            options.push({
              key: item.id,
              text: `${item.name} (${item.desc})`,
              value: item.id,
            });
          });
        }
        this.setState({ options_course: options });
      }
    });

    getAircraft("?isActive=true").then((res) => {
      if (res && res.status === 200) {
        let aircraftoption = [];
        res.data.map((item) => {
          aircraftoption.push({
            key: item.id,
            text: `${item.tailNumber} ${item.schedulingGroupName}`,
            value: item.id,
          });
        });
        this.setState({
          options_aircraft: aircraftoption,
        });
      }
    });
    getInstructor("?status=Active").then((res) => {
      if (res && res.status === 200) {
        let instroption = [];
        res.data.map((item) => {
          instroption.push({
            key: item.id,
            text: `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
        });
        this.setState({
          options_inst: instroption,
        });
      }
    });

    let qString =
      "?start=" +
      moment().subtract(1, "days").format("YYYY-MM-DD") +
      "&end=" +
      moment().format("YYYY-MM-DD");
    this.getData(qString);
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Reservation (Flight)">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Reservation (Flight)</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                hideMobileKeyboard
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={this.state.date}
                allowSameEndDate
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="course"
                placeholder="Select Course"
                search
                multiple
                selection
                options={state.options_course}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="status"
                placeholder="Select Status"
                multiple
                selection
                options={config.options_status_reservation}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="reservationType"
                placeholder="Select Reservation Type"
                search
                multiple
                selection
                options={state.options_reservation_type}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={4} style={{ paddingRight: 0 }}>
              <Dropdown
                name="aircraft"
                placeholder="Select Aircraft"
                search
                multiple
                selection
                options={state.options_aircraft}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                name="departure"
                placeholder="Select Departure"
                search
                multiple
                selection
                options={state.options_airport}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={3}>
              <Dropdown
                name="destination"
                placeholder="Select Destination"
                search
                multiple
                selection
                options={state.options_airport}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="instructor"
                placeholder="Select Instructor"
                search
                multiple
                selection
                options={state.options_inst}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={1}>
              <Checkbox
                style={{ paddingTop: "4px" }}
                label="Logs"
                name="log"
                checked={this.state.log}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field>
              <MainButton
                size="small"
                text="Filter"
                loading={state.dataLoading}
                onClick={this.onClickFilterDate}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <TableFixedColumns
          data={state.data}
          // columns={state.column}
          columns={
            state.log
              ? state.columnFix.concat(state.column).concat(state.logcolumn)
              : state.columnFix.concat(state.column)
          }
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(FlightReservationReport);
