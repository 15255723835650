import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Icon,
   Form,
   Breadcrumb,
  Modal,
  Button,
  Dropdown,
  Checkbox,
} from "semantic-ui-react";
import * as config from "../../config";
//config.options_report_reservation
import { DatesRangeInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import {
  genGroundReservation,
  exportGroundReservation,
  getCourse,
} from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class GroundReservationReport extends Component {
  state = {
    date:
      moment().subtract(1, "days").format("YYYY-MM-DD") +
      " - " +
      moment().format("YYYY-MM-DD"),
    course: [],
    status: [],
    attendees: false,
    search_attendees: null,
    filter_subject:['normal'],
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    attendeescolumn: [
      {
        Header: "Attendees",
        accessor: "attendees",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.attendees ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.attendees
                    .replace(/\r\n/g, "<br />")
                    .replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "SP Observer",
        accessor: "SPObserver",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.SPObserver ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.SPObserver.replace(
                    /\r\n/g,
                    "<br />"
                  ).replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "IP Observer",
        accessor: "IPObserver",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.IPObserver ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.IPObserver.replace(
                    /\r\n/g,
                    "<br />"
                  ).replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "Course",
        accessor: "regis_course",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.regis_course ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.regis_course
                    .replace(/\r\n/g, "<br />")
                    .replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        Header: "Ground hour",
        accessor: "groundhour",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
      },
      {
        Header: "Total hour",
        accessor: "totalhour",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
      },
    ],
    logcolumn: [
      {
        Header: "Log",
        accessor: "history",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">
            {cellInfo.original.history ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: cellInfo.original.history
                    .replace(/\r\n/g, "<br />")
                    .replace(/[\r\n]/g, "<br />"),
                }}
              ></span>
            ) : (
              ""
            )}
          </span>
        ),
      },
    ],
    column: [
      {
        Header: "Start",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        width: 180,
        Cell: (cellInfo) => <span className="">{cellInfo.original.start}</span>,
      },
      {
        Header: "End",
        accessor: "end",
        // sortable: 180,
        style: { textAlign: "center" },
        width: 180,
        Cell: (cellInfo) => <span className="">{cellInfo.original.end}</span>,
      },
      {
        Header: "Course",
        accessor: "course",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.course}</span>
        ),
      },
      {
        Header: "Subject",
        accessor: "subject",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.subject}</span>
        ),
      },
      // {
      //   Header: "จำนวน ชม. : hr.",
      //   accessor: "hours",
      //   sortable: false,
      //   style: { textAlign: "center" },
      //   // width: 150,
      //   Cell: (cellInfo) => <span className="">{cellInfo.original.hours}</span>,
      // },
      {
        Header: "Ground Instruction Type",
        accessor: "language",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.language}</span>
        ),
      },
      {
        Header: "Instructor",
        accessor: "instructor",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.instructor}</span>
        ),
      },
      {
        Header: "Student",
        accessor: "student",
        sortable: false,
        // style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.student}</span>
        ),
      },
      {
        Header: "Location",
        accessor: "location",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.location}</span>
        ),
      },
      {
        Header: "Room",
        accessor: "room",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.room}</span>,
      },
      {
        Header: "Comment",
        accessor: "comment",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.comment}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.status}</span>
        ),
      },
      {
        Header: "Created At",
        accessor: "created_at",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.created_at}</span>
        ),
      },
      {
        Header: "Created By",
        accessor: "created_by",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.created_by}</span>
        ),
      },
    ],
    columnFix: [
      {
        Header: "Reservation number",
        accessor: "reservationnumber",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.reservationnumber}</span>
        ),
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const {
      date,
      course,
      status,
      attendees,
      log,
      search_attendees,
      filter_subject
    } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (status.length > 0) {
      status.map((tmp) => {
        qString += "&status[]=" + tmp;
      });
    }
    if (attendees) {
      qString += "&attendees";
    }
    if (log) {
      qString += "&log";
    }
    if(filter_subject){
      if (filter_subject.length > 0) {
        filter_subject.map((tmp) => {
          qString += "&filter_subject[]=" + tmp;
        });
      }
    }

    if (search_attendees) qString += "&name=" + search_attendees;

    exportGroundReservation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "GroundReservation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "GroundReservation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const {
      date,
      course,
      status,
      attendees,
      log,
      search_attendees,
      filter_subject
    } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (status.length > 0) {
      status.map((tmp) => {
        qString += "&status[]=" + tmp;
      });
    }
    if (attendees) {
      qString += "&attendees";
    }
    if (log) {
      qString += "&log";
    }

    if(filter_subject){
      if (filter_subject.length > 0) {
        filter_subject.map((tmp) => {
          qString += "&filter_subject[]=" + tmp;
        });
      }
    }

    if (search_attendees) qString += "&name=" + search_attendees;

    // console.log('qString',qString);
    this.getData(qString);
  };
  handleChangeSearch = (event, { name, value }) => {
    this.setState({ [name]: value });
  };
  handleChangeDate = (event, { name, value }) => {
    // console.log("name,value", name, event);
    // if (this.state.hasOwnProperty(name)) {
    if (name === "attendees") {
      this.setState((prevState) => ({ attendees: !prevState.attendees }));
    } else if (name === "log") {
      this.setState((prevState) => ({ log: !prevState.log }));
    } else {
      this.setState({ [name]: value });
    }
    // }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genGroundReservation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date ,filter_subject } = this.state;

    getCourse("?additionalCourse=false&active=true").then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            options.push({
              key: item.id,
              text: `${item.name} (${item.desc})`,
              value: item.id,
            });
          });
        }
        this.setState({ options_course: options });
      }
    });

    let qString =
      "?start=" +
      moment().subtract(1, "days").format("YYYY-MM-DD") +
      "&end=" +
      moment().format("YYYY-MM-DD");
      
    if(filter_subject){
      if (filter_subject.length > 0) {
        filter_subject.map((tmp) => {
          qString += "&filter_subject[]=" + tmp;
        });
      }
    }
    this.getData(qString);
  }
  render() {
    let state = this.state;
    // console.log(state.attendees);
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Reservation (Ground)">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Reservation (Ground)</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                hideMobileKeyboard
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={this.state.date}
                allowSameEndDate
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="course"
                placeholder="Select Course"
                search
                multiple
                selection
                options={state.options_course}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="status"
                placeholder="Select Status"
                multiple
                selection
                options={config.options_status_reservation}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="filter_subject"
                placeholder="Subject"
                selection
                multiple
                defaultValue={state.filter_subject}
                options={[
                  { key: '1', text: 'Ground', value: 'normal' },
                  { key: '2', text: 'Test', value: 'testSubject' },
                  { key: '3', text: 'Final Project Brief', value: 'Final Project Brief' },
                  { key: '4', text: 'Final Project Present', value: 'Final Project Present' },
                  { key: '5', text: 'Final Project Progressive Check', value: 'Final Project Progressive Check' }                  
                ]}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field>
              <MainButton
                size="small"
                text="Filter"
                loading={state.dataLoading}
                onClick={this.onClickFilterDate}
              />
            </Form.Field>
          </Form.Group>
           <Form.Group>
            <Form.Field width={4}>
              <Form.Input
                name="search_attendees"
                placeholder="Search student"
                onChange={this.handleChangeSearch}
              />
            </Form.Field>
             <Form.Field width={4}>
              <Checkbox
                label="Attendees & Observer"
                name="attendees"
                checked={this.state.attendees}
                onChange={this.handleChangeDate}
              />
              <br />
              <Checkbox
                style={{ paddingTop: "4px" }}
                label="Logs"
                name="log"
                checked={this.state.log}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
           </Form.Group>
        </Form>
        <TableFixedColumns
          data={state.data}
          // columns={state.column}
          columns={
            state.attendees && state.log
              ? state.columnFix
                  .concat(state.column)
                  .concat(state.attendeescolumn)
                  .concat(state.logcolumn)
              : state.attendees
              ? state.columnFix
                  .concat(state.column)
                  .concat(state.attendeescolumn)
              : state.log
              ? state.columnFix.concat(state.column).concat(state.logcolumn)
              : state.columnFix.concat(state.column)
          }
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(GroundReservationReport);
