import React, { Component } from "react";
import { Form, Button, Table, Icon } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderField from "../../components/renderField";

import { connect } from "react-redux";
const validate = values => {
  const errors = {};
  if (!values.moduleStart) errors.moduleStart = true;
  if (!values.moduleEnd) errors.moduleEnd = true;
  return errors;
};
const renderChecklist = ({ fields, data, moduleStart, moduleEnd }) => {
  return (
    <Table compact celled striped style={{ marginBottom: "30px" }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell className="header-table">
            Stage name
          </Table.HeaderCell>
          <Table.HeaderCell
            className="header-table"
            width="3"
            textAlign="center"
          >
            Start
          </Table.HeaderCell>
          <Table.HeaderCell
            className="header-table"
            width="4"
            textAlign="center"
          >
            End
          </Table.HeaderCell>
          <Table.HeaderCell
            className="header-table"
            width="2"
            textAlign="center"
          >
            Hours
          </Table.HeaderCell>
          <Table.HeaderCell className="header-table" width="2" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {fields.map((member, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign="center">
              <Field
                name={`${member}.name`}
                type="text"
                component={renderField}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              {data[index - 1] ? data[index - 1].end : moduleStart}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Field
                name={`${member}.end`}
                minDate={moduleStart}
                maxDate={moduleEnd}
                component={renderDateOnlyField}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Field
                name={`${member}.hours`}
                type="number"
                min={0}
                component={renderField}
              />
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Icon
                link
                color="red"
                name="trash alternate"
                onClick={() => fields.remove(index)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell className="footer-table" />
          <Table.Cell className="footer-table" />
          <Table.Cell className="footer-table" />
          <Table.Cell className="footer-table" />
          <Table.Cell className="footer-table" textAlign="right">
            <Button
              positive
              size="small"
              compact
              type="button"
              onClick={() => fields.push({})}
              content="Add"
            />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
};

class FormTimeline extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      moduleStart,
      moduleEnd,
      courseStage,
      initialValues
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Form.Group>
          <Field
            name="moduleStart"
            label="Start"
            required
            width="6"
            minDate={initialValues && initialValues.minStart}
            component={renderDateOnlyField}
          />
          <Field
            name="moduleEnd"
            label="End"
            required
            minDate={moduleStart}
            width="6"
            maxDate={initialValues && initialValues.maxEnd}
            component={renderDateOnlyField}
          />
        </Form.Group>
        <FieldArray
          name="courseStage"
          component={renderChecklist}
          data={courseStage}
          moduleStart={moduleStart}
          moduleEnd={moduleEnd}
        />
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

FormTimeline = reduxForm({
  // a unique name for the form
  form: "timeline",
  validate,
  enableReinitialize: true
})(FormTimeline);

// Decorate with connect to read form values
const selector = formValueSelector("timeline"); // <-- same as form name
FormTimeline = connect(state => {
  const moduleStart = selector(state, "moduleStart");
  const moduleEnd = selector(state, "moduleEnd");
  const courseStage = selector(state, "courseStage");

  return {
    moduleStart,
    moduleEnd,
    courseStage
  };
})(FormTimeline);
export default FormTimeline;
