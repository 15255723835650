import React from "react";
import { Form, Radio, ItemMeta, Checkbox } from "semantic-ui-react";
import Wrap from "./wrap";
const renderField = ({
  input,
  width,
  label,
  options,
  required,
  inline,
  defaultChecked,
  meta: { touched, error, warning }
}) => (
  <Wrap>
    <Form.Group unstackable inline={inline}>
      {label && (
        <Form.Field width="4">
          <label style={{ textAlign: "left" }}>
            {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
        </Form.Field>
      )}
      <Form.Field
        width={width || 12}
        required={required}
        style={{ marginBottom: "0px", textAlign: "left" }}
      >
        {options
          ? options.map((item, index) => (
              // <Form.Radio
              //   key={item.key}

              //   label={item.label}
              //   // name="radioGroup"
              //   checked={item.value === input.value}
              //   onChange={() => input.onChange(item.value)}
              // />
              <Checkbox
                style={{ marginRight: "5px" }}
                label={`${item.label}  `}
                name={item.name}
                value={item.name}
                checked={input.value === item.name}
                onChange={(event,data) => {
                  // console.log(data,input.value);
                  // console.log(
                  //   "input.value.indexOf(item.name)",
                  //   item.name,
                  //   input.value.indexOf(item.name)
                  // );
                  // const newValue = [...input.value] || [];

                  // if (event.target.checked) {
                  //   newValue.push(item.name);
                  // } else {
                  //   newValue.splice(newValue.indexOf(item.name), 1);
                  // }
                  // console.log("console.log(newValue)", newValue);
                  if (data.checked) return input.onChange(item.name);
                  else return input.onChange(null);
                  //   return input.onChange(item.name);
                  // } else return input.onChange(null);

                }}
              />
              // </Form.Radio>
              // <Form.Radio
              //   label={item.label}
              //   value={item.value}
              //   checked={item.value}
              //   onChange={(param, data) => input.onChange(data.checked)} //input.onChange(data.checked)
              // />
            ))
          : null}
      </Form.Field>
    </Form.Group>
  </Wrap>
);

export default renderField;
