import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import * as config from '../../config'

class RemindersDetail extends Component {

    render() {
        const data = this.props.data
        return (
            <Form>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Aircraft</Form.Field>
                    <Form.Field width='10' className='bold'>{`${data.aircraftTailNumber} ${data.aircraftMake} ${data.aircraftModel}`} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Maint. Reminder Name</Form.Field>
                    <Form.Field width='10' className='bold'>{data.name} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Note</Form.Field>
                    <Form.Field width='10' className='bold'>{data.notes} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Can be Overridden</Form.Field>
                    <Form.Field width='10' className='bold'>{data.canBeOverridden ? 'Yes' : 'No'} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>One-Time Only</Form.Field>
                    <Form.Field width='10' className='bold'>{data.isOneTimeReminder ? 'Yes' : 'No'} </Form.Field>
                </Form.Group>

                <h4 style={{ color: config.colorTheme }}>Time-Based Reminder </h4>

                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Time Based On</Form.Field>
                    <Form.Field width='10' className='bold'>{data.timeBasedOn} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Last Completed At </Form.Field>
                    <Form.Field width='10' className='bold'>{data.timeLastCompleted} Hours</Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Due Every </Form.Field>
                    <Form.Field width='10' className='bold'>{data.dateDueEvery} Hours</Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Next Due at  </Form.Field>
                    <Form.Field width='10' className='bold'>{data.timeDueEvery} Hours</Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Warning Period Begins  </Form.Field>
                    <Form.Field width='10' className='bold'>{data.timeWarningPeriod} hour(s) in advance</Form.Field>
                </Form.Group>
                <br />
                <Button floated='left' type="submit" content='Edit' color='green' />
                <Button floated='right' type="button" content='Cancel' color='green' basic onClick={this.props.onCancel} />
            </Form>

        )
    }
}

export default withRouter(RemindersDetail)
