import React, { Component } from "react";
import { Form, Button, Table, Icon, Header, Statistic, List, Message } from "semantic-ui-react";
import { Field, FieldArray, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import validate from "../../validate";
import renderSelectField from "../../components/renderSelectField";
import renderField from "../../components/renderField";
import renderDateOnlyField from "../../components/renderDateOnlyFieldCustom";
import renderDateRangeField from "../../components/renderDateRangeField";
import renderCheckboxField from "../../components/renderCheckboxFieldCustom";
import Wrap from "../../components/wrap";
import moment from "moment";

const lessThan = (value, previousValue, allValues) =>
  parseFloat(value) ? value : null

class FormcourseModule extends Component {
  state = {
    option_subject: [],
    numOfWeek: null,
    numOfWeek_course: null
  };
  normalizeNumber = value => {
    // console.log('normalizeNumber',value);
    value = value.replace(/[^0-9.]/g, '') // Remove all chars except numbers and .

    return value
  }
  componentWillReceiveProps(nextProps) {
    // console.log('nextProps',nextProps);
    if (nextProps.option_subject !== this.state.option_subject) {
      if (nextProps.option_subject) {
        this.setState({ option_subject: nextProps.option_subject });
      }
    }
  }
  // componentDidMount(){
  //   const { data } = this.props;
  //
  //   var start = moment(data.start, "DD-MM-YYYY");
  //   var end = moment(data.end, "DD-MM-YYYY");
  //
  //   var duration = moment.duration(end.diff(start));
  //   var week = duration.asWeeks();
  //
  //   this.setState({numOfWeek_course: Math.ceil(week)})
  //
  // }
  renderMembers = ({ fields, moduleStartEnd, checklist }) => {
    let moduleStart = null
    let moduleEnd = null
    if (moduleStartEnd) {
      const res = moduleStartEnd.split(' - ');
      moduleStart = res[0]
      moduleEnd = res[1]
    }
    // console.log('renderMembers fields',fields);
    return (
      <Wrap>
        {fields.map((member, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign="center" className="bold">
              <Form.Group>
                <Field
                  name={`${member}.name`}
                  component={renderField}
                  placeholder="Checklist name"
                  label="Name"
                  width="8"
                />
                <Field
                  name={`${member}.startend`}
                  label="Start - End"
                  width="8"
                  minDate={moduleStart && moduleStart}
                  maxDate={moduleEnd && moduleEnd}
                  required
                  component={renderDateRangeField}
                />
              </Form.Group>
              {/*<Field
                name={`${member}.diff`}
                type='number'
                width="4"
                min="1"
                component={renderField}
                label="Diff"
              />*/}
              <Form.Group>
                <Field
                    name={`${member}.subjects`}
                    component={renderSelectField}
                    placeholder="Subject"
                    options={this.state.option_subject}
                    multiple
                    search
                    label="Subject"
                    width="10"
                  />
                  <Field
                    name={`${member}.diff`}
                    width="3"
                    component={renderField}
                    label="Diff"
                    // parse={(val) => parseFloat(val)}
                    normalize={this.normalizeNumber}
                  />
                  <Field
                    name={`${member}.check_checklist`}
                    width="3"
                    component={renderCheckboxField}
                    label="STAGE CHECK"
                  />
              </Form.Group>
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Icon
                link
                // size="small"
                color="red"
                name="trash alternate"
                onClick={() => fields.remove(index)}
              />
            </Table.Cell>
          </Table.Row>
        ))}
        <Table.Row>
          <Table.Cell className="footer-table" textAlign="right"></Table.Cell>
          <Table.Cell className="footer-table" textAlign="center">
            <Button
              color="blue"
              size="small"
              compact
              type="button"
              onClick={() => fields.push({
                "name":null
                // "subject":[]
              })}
              content="Add"
            />
          </Table.Cell>
        </Table.Row>
      </Wrap>
    );
  };

  render() {
    const state = this.state
    const { moduleStartEnd, checklist,
      handleSubmit, pristine, submitting,  loading,initialValues,
      fetching, data , errors} = this.props;
      // console.log('initialValues',initialValues);
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Header as="h2">
          Module: {initialValues && initialValues.name}
        </Header>
        <List className='bold'>
          <List.Item>
            <b><Icon name='clock'/> Course {data.name} : </b> {
              moment(data.start,'DD-MM-YYYY').format("D MMMM YYYY")} - {moment(data.end,'DD-MM-YYYY').format("D MMMM YYYY")
            }
          </List.Item>
        </List>
        <Field
          name="moduleStartEnd"
          label="Start - End"
          required
          width="6"
          minDate={moment(data.start,'DD-MM-YYYY').format("DD/MM/YYYY")}
          maxDate={moment(data.end,'DD-MM-YYYY').format("DD/MM/YYYY")}
          component={renderDateRangeField}
        />
      {/*<Form.Group>
          <Field
            name="moduleStart"
            label="Start"
            required
            minDate={moment(data.start,'DD-MM-YYYY').format("DD/MM/YYYY")}
            maxDate={moment(data.end,'DD-MM-YYYY').format("DD/MM/YYYY")}
            width="6"
            component={renderDateOnlyField}
          />
          <Field
            name="moduleEnd"
            label="End"
            required
            minDate={moduleStart}
            maxDate={moment(data.end,'DD-MM-YYYY').format("DD/MM/YYYY")}
            width="6"
            component={renderDateOnlyField}
          />
       </Form.Group>*/}
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className="header-table"
                width={14}
                textAlign="center"
              >
                Checklist
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={2}
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {fetching ? (
            <Table.Row>
              <Table.Cell textAlign="left" colSpan="2">
                Loading..
              </Table.Cell>
            </Table.Row>
          ) :
            <FieldArray
              name="checklist"
              checklist={checklist}
              moduleStartEnd = {moduleStartEnd}
              component={this.renderMembers} />}
          </Table.Body>
        </Table>
        { errors && (
          <Message negative>
            <Message.Header>Errors</Message.Header>
            <p>{errors}</p>
          </Message>
        )}
        <br />
        <Button
          floated="left"
          compact
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          compact
          type="button"
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

FormcourseModule = reduxForm({
  // a unique name for the form
  form: "coursemodulechecklist",
  validate,
  enableReinitialize: true
})(FormcourseModule);

const selector = formValueSelector("coursemodulechecklist"); // <-- same as form name
FormcourseModule = connect(state => {
  const moduleStartEnd = selector(state, "moduleStartEnd");
  // const moduleEnd = selector(state, "moduleEnd");
  const checklist = selector(state, "checklist");

  return {
    moduleStartEnd,
    checklist
  };
})(FormcourseModule);
export default FormcourseModule;
