import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import Wrap from "./wrap";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";
class renderDateOnlyFieldCustom extends Component {
  state = {
    date: null,
  };
  componentDidMount() {
    if (this.props.meta && this.props.meta.initial) {
      this.setState({ date: moment(this.props.meta.initial, "DD-MM-YYYY") });
    } else if (this.props.input && this.props.input.value) {
      this.setState({ date: moment(this.props.input.value, "DD-MM-YYYY") });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.input && nextProps.input.value !== this.props.input.value) {
      if (moment(nextProps.input.value, "DD-MM-YYYY").isValid()) {
        this.setState({
          date: moment(nextProps.input.value, "DD-MM-YYYY"),
        });
      }
    }
  }
  // onChange = (e, data) => {
  //   const state = this.state;
  //   console.log('onChange',data);
  //   // if (data.name === "date") {
  //     this.setState({ date: data.value });
  //     this.props.input.onChange(data.value);
  //   // }
  // };
  render() {
    const {
      input,
      label,
      placeholder,
      width,
      required,
      minDate,
      maxDate,
      meta: { touched, error, warning },
    } = this.props;
    const state = this.state;
    return (
      <Wrap>
        <Form.Field width={width || 16} style={{ marginBottom: "0px" }}>
          <label style={{ textAlign: "left" }}>
            {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          <DateInput
            name="date"
            placeholder={placeholder}
            {...input}
            error={touched && error}
            // dateTimeFormat="DD/MM/YYYY"
            dateFormat="DD-MM-YYYY"
            iconPosition="right"
            icon="calendar alternate outline"
            divider
            hideMobileKeyboard
            // minDate={minDate}
            minDate={
              moment(minDate, "DD-MM-YYYY").format("DD-MM-YYYY") === minDate
                ? moment(minDate, "DD-MM-YYYY")._d
                : null
            }
            // maxDate={maxDate}
            maxDate={
              moment(maxDate, "DD-MM-YYYY").format("DD-MM-YYYY") === maxDate
                ? moment(maxDate, "DD-MM-YYYY")._d
                : null
            }
            value={state.date}
            // onChange={this.onChange}
            onChange={(e, data) => input.onChange(data.value)}
            // marked={new Date()}
          />
          <p style={{ color: "#9f3a38", textAlign: "left", fontSize: "90%" }}>
            {touched &&
              ((error && <span>{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </p>
        </Form.Field>
      </Wrap>
    );
  }
}

export default renderDateOnlyFieldCustom;
