import React, { Component } from "react";
import { Form, Button, Message, Divider, } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import { roles } from "./data";
import { options_title_eng, options_title_th } from "../../config";
const validate = values => {
  const errors = {};
  if (!values.customerCode) errors.customerCode = true;
  if (!values.studentId) errors.studentId = true;
  if (!values.batchCode) errors.batchCode = true;
  if (!values.firstNameEng) errors.firstNameEng = true;
  if (!values.lastNameEng) errors.lastNameEng = true;
  if (!values.firstNameTh) errors.firstNameTh = true;
  if (!values.lastNameTh) errors.lastNameTh = true;
  if (!values.email) errors.email = true;
  let regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})(\.[a-z]{2,8})?$/;
  if (values.email && !regex.test(values.email)) errors.email = 'Invalid email format';
  if (!values.phone) errors.phone = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.studentType) errors.studentType = true;
  if (!values.username) errors.username = true;
  if (values.studentType && values.studentType.length === 0) errors.studentType = true;
  if (!values.foreignerCountry) errors.foreignerCountry = true;
  return errors;
};

class FormUserStudent extends Component {
  state = {
    optionsRoles: [],
    optionsGender: [
      { key: "gender01", value: "male", text: "Male" },
      { key: "gender02", value: "female", text: "Female" }
    ],
    radioMedicalClass: [
      { key: "m01", value: 1, label: "1" },
      { key: "m02", value: 2, label: "2" }
    ]
  };
  handleChange = (e) => {
    // console.log(e)
    this.props.dispatch(change("userstudentform", "username", e.target.value))
  }
  componentDidMount() {
    let options = [];
    roles.map(item => {
      options.push({
        key: item.id,
        value: item.id,
        text: item.name
      });
    });
    this.setState({ optionsRoles: options });

  }

  render() {
    const state = this.state;
    const props = this.props;
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      loadingUp,
      errorMessage,
      isEdit,
      isAdd,
      activeUser,
      // haveCourse
    } = this.props;
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Field
            name="customerCode"
            type="text"
            required={true}
            component={renderField}
            label="Customer Code"
          />
          <Field
            name="studentId"
            type="text"
            required={true}
            labelDesc="(EN)"
            component={renderField}
            label="Student ID"
          />
        </Form.Group>
        <Form.Group >
          <Field
            name="batchCode"
            width="8"
            type="text"
            required={true}
            component={renderField}
            label="Batch Code"
          />
        </Form.Group>
        <Form.Group widths='3'>
          <Field
            name="titleEng"
            // width='3'
            component={renderSelectField}
            label="Title (EN)"
            options={options_title_eng}
          />
          <Field
            name="firstNameEng"
            type="text"
            // width="6"
            required={true}
            component={renderField}
            label="First name (EN)"
          />
          <Field
            name="lastNameEng"
            type="text"
            // width="6"
            required={true}
            labelDesc="(EN)"
            component={renderField}
            label="Last name (EN)"
          />
        </Form.Group>

        <Form.Group widths='3'>
          <Field
            name="titleTh"
            component={renderSelectField}
            label="Title (TH)"
            options={options_title_th}
          />
          <Field
            name="firstNameTh"
            type="text"
            // width="6"
            required={true}
            component={renderField}
            label="First name (TH)"
          />
          <Field
            name="lastNameTh"
            type="text"
            // width="6"
            required={true}
            component={renderField}
            label="Last name (TH)"
          />
        </Form.Group>
        <Form.Group>
          {/* <Field
            width="4"
            name="foreigner"
            component={renderCheckboxField}
            label="Foreigner"
          />
          {hasForeigner && (
            <Field
              name="foreignerCountry"
              type="text"
              width="6"
              required={true}
              component={renderField}
              label="Country"
            />
          )} */}
          <Field
            name="nickName"
            type="text"
            component={renderField}
            label="Nick name"
          />
          <Field
            name="foreignerCountry"
            type="text"
            required={true}
            component={renderField}
            label="Nationality"
          />

        </Form.Group>

        <Form.Group widths="equal">
          <Field
            name="personnel_email"
            type="text"
            // required={true}
            component={renderField}
            label="Personnel Email"
          />
          <Field
            name="phone"
            type="text"
            required={true}
            component={renderField}
            label="Phone"
          />
        </Form.Group>
        <Field
          name="email"
          type="text"
          required={true}
          component={renderField}
          label="BAC Email"
          onChange={(e => this.handleChange(e))}
        />
        <Field
          name="studentType"
          required={true}
          component={renderSelectField}
          label="Student type"
          options={props.options_studenttype}
        />
        <Form.Group widths="equal">
          {/* <Field
            name="advisor"
            // required={true}
            component={renderSelectField}
            label="Advisor"
            options={props.options_instructors}
          /> */}
          <Field
            name="class"
            // required={true}
            component={renderSelectField}
            label="Class"
            options={props.options_class}
          />
          <Field
            name="company"
            // required={true}
            component={renderSelectField}
            options={props.options_company}
            label="Company Name"
          />
        </Form.Group>
        {/* <Form.Group widths="equal">
          <Field
            name="studentType"
            required={true}
            component={renderSelectField}
            label="Student type"
            options={props.options_studenttype}
          />
          <Field
            name="company"
            // required={true}
            component={renderSelectField}
            options={props.options_company}
            label="Company Name"
          />
        </Form.Group> */}
        {/*<Field
          name="courseID"
          component={renderSelectField}
          label="Course"
          options={props.options_course}
        />*/}
        <Form.Group widths="equal">
          <Field
            name="dateOfBirth"
            required={true}
            component={renderDateOnlyField}
            label="Date of Birth"
          />
          <Field
            name="gender"
            required={true}
            component={renderSelectField}
            label="Gender"
            options={state.optionsGender}
          />
        </Form.Group>
        <br />
        <Divider horizontal>Username</Divider>
        {errorMessage && (
          <Message negative>
            <p>{errorMessage}</p>
          </Message>
        )}
        {/* {วานีแก้  ลบ password พี่ฟิกไว้ จากวันเกิด*/}
        <Form.Group widths="8">
          <Field
            name="username"
            widths="6"
            component={renderField}
            label="Username"
            type="text"
            // readOnly={isEdit ? false : true}
            required={true}
          />
        </Form.Group>
        {isEdit && activeUser ? (
          <Button
            compact
            color="red"
            floated="right"
            type="button"
            content="Inactive User"
            loading={loadingUp}
            onClick={this.props.onDisable}
          />
        ) : (
            isEdit && (
              <Button
                compact
                floated="right"
                color="yellow"
                type="button"
                content="Active User"
                loading={loadingUp}
                onClick={this.props.onEnable}
              />
            )
          )}
        <br />
        <br />
        <br />
        <Button
          compact
          floated="left"
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          type="button"
          content="Cancel"
          color="green"
          basic
          compact
          onClick={props.onCancel}
        />
      </Form>
    );
  }
}

// FormStudent = reduxForm({
//   // a unique name for the form
//   form: "studentform",
//   validate,
//   enableReinitialize: true
// })(FormStudent);

// export default FormStudent;

FormUserStudent = reduxForm({
  // a unique name for the form
  form: "userstudentform",
  validate,
  enableReinitialize: true,
})(FormUserStudent);

const selector = formValueSelector("userstudentform");
FormUserStudent = connect(state => {
  const setUsername = selector(state, "email");
  return {
    setUsername: setUsername
  };
})(FormUserStudent);
export default FormUserStudent;
