import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Breadcrumb,
  Modal,
  Dropdown,
  Button,
  Icon,
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import { genProficiencyCheck, exportProficiencyCheck } from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class ProficiencyCheck extends Component {
  state = {
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    options_worktime: [
      { key: "r01", value: "IP Fulltime", text: "IP Fulltime" },
      { key: "r02", value: "IP Parttime", text: "IP Parttime" },
    ],
    wrktime: [],
    column: [
      {
        Header: "No.",
        accessor: "no",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.no}</span>,
      },
      {
        Header: "Name",
        accessor: "firstName",
        sortable: false,
        style: { textAlign: "left" },
      },
      {
        Header: "Surname",
        accessor: "lastName",
        sortable: false,
        style: { textAlign: "left" },
      },
      {
        Header: "Date of birth",
        accessor: "dateOfBirth",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "Pilot's License",
        accessor: "splnumber",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "Jan",
        accessor: "Jan",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Jan === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Jan === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Feb",
        accessor: "Feb",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Feb === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Feb === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Mar",
        accessor: "Mar",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Mar === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Mar === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Apr",
        accessor: "Apr",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Apr === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Apr === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "May",
        accessor: "May",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.May === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.May === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Jun",
        accessor: "Jun",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Jun === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Jun === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Jul",
        accessor: "Jul",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Jul === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Jul === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Aug",
        accessor: "Aug",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Aug === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Aug === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Oct",
        accessor: "Oct",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Oct === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Oct === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Nov",
        accessor: "Nov",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Nov === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Nov === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
      {
        Header: "Dec",
        accessor: "Dec",
        sortable: false,
        style: { textAlign: "center" },
        getProps: (state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              style: {
                backgroundColor:
                  rowInfo.row.Dec === "INSRUMENT"
                    ? "#CC0000"
                    : rowInfo.row.Dec === "GENERAL HANDLING"
                    ? "#191970"
                    : null,
                color: "#ffffff",
              },
            };
          }
          return {};
        },
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { wrktime } = this.state;
    let qString = "?";
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "worktime[]=" + wrk + "&";
      });
    }
    exportProficiencyCheck(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "ProficiencyCheck_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "ProficiencyCheck_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilter = () => {
    const { wrktime } = this.state;
    let qString = "?";
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "worktime[]=" + wrk + "&";
      });
    }
    this.getData(qString);
  };
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genProficiencyCheck(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    this.getData("");
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Proficiency Check">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Proficiency Check</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field>
              <Dropdown
                name="wrktime"
                placeholder="Select Work Time"
                multiple
                selection
                options={state.options_worktime}
                onChange={this.handleChange}
              />
            </Form.Field>
            <MainButton
              size="small"
              text="Filter"
              loading={state.dataLoading}
              onClick={this.onClickFilter}
            />
          </Form.Group>
        </Form>
        <TableFixedColumns
          data={state.data}
          columns={state.column}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(ProficiencyCheck);
