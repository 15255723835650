import React, { Component } from "react";
import { Loader, Button, Form, Message, Icon } from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import { Field, reduxForm, change, reset } from "redux-form";
import renderFileField from "../../components/renderFileField";

import Wrap from "../../components/wrap";
import {
  getTimeline,
  uploadImageTimeline,
  deleteImageTimeline,
} from "./service";

const validate = (values) => {
  const errors = {};
  if (!values.timelinefile) errors.timelinefile = true;
  return errors;
};

class Timeline extends Component {
  state = {
    id: this.props && this.props.id,
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    data: [],
    open: false,
    loading: true,
  };

  componentDidMount() {
    this.getData(this.state.id);
  }

  getData = (id) => {
    getTimeline(id).then((res) => {
      if (res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (
        userpermissionGroup &&
        userpermissionGroup.permission &&
        userpermissionGroup.permission.indexOf(item) !== -1
      )
        return item;
    });
    if (allowed) return true;
    else return false;
  };

  onClickUpload = () => {
    let { data } = this.state;
    if (data.fileUrl) {
      var fileName = data.fileUrl.split("/");
      fileName = fileName.pop();
      this.setState({ file: data.fileUrl, fileName: fileName });
    }
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false, file: null, fileName: null, FileError: null });
    this.props.dispatch(reset("timeline"));
  };
  onUploadfile = (values) => {
    console.log(values);
    if (values.timelinefile) {
      this.setState({ loading: true, uploadError: null }); // imageURLMed:null
      var formData = new FormData();
      if (values.timelinefile && typeof values.timelinefile === "object")
        formData.append("image", values.timelinefile[0]);
      else if (values.timelinefile) {
        formData.append("image", values.timelinefile);
      } else formData.append("image", "");
      uploadImageTimeline(this.state.id, formData).then((res) => {
        if (res && res.status === 200) {
          if (res.data.image) {
            this.setState({ uploadError: res.data.image && res.data.image[0] });
          } else {
            this.getData(this.state.id);
            this.close();
          }
        } else if (res && res.status === 422) {
          this.setState({ FileError: "Please browse file" });
        }
        this.setState({ loading: false });
      });
    }
  };

  onDeletePhoto = () => {
    deleteImageTimeline(this.state.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ file: null, fileName: null });
        this.props.dispatch(change("timeline", "timelinefile", null));
        this.getData(this.state.id);
        // this.getDataInstructors(data.id);
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting } = this.props;

    if (state.loading)
      return <Loader active inline="centered" content="Loading" />;
    return (
      <div>
        {this.checkPermissionMenu("Personnel", [
          "manage-instructor",
          "manage-student",
          "manage-staff",
        ]) && (
          <MainButton
            size="small"
            onClick={this.onClickUpload}
            text="Upload file"
          />
        )}
        {state.data.fileUrl ? (
          <Wrap>
            <br /> <br />
            <iframe
              src={state.data.fileUrl}
              frameborder="0"
              height="500vh"
              width="100%"
            ></iframe>
          </Wrap>
        ) : (
          <Wrap>
            <p style={{ color: "rgb(115, 115, 115)" }}>
              No description available
            </p>
            <br />
          </Wrap>
        )}
        <ModalForm
          open={state.open}
          icon="file pdf"
          size="small"
          content={`Timeline File`}
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onUploadfile)}>
            {state.file && (
              <Message size="small" style={{ margin: "3px 0px 3px 0px" }}>
                <Message.Content style={{ textAlign: "left" }}>
                  <p>
                    <Icon name="file pdf" />
                    <a
                      href={state.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {state.fileName}
                    </a>
                    <Icon
                      name="x"
                      color="red"
                      link
                      onClick={() => this.onDeletePhoto()}
                    />
                  </p>
                </Message.Content>
              </Message>
            )}
            <br />
            <Field
              name="timelinefile"
              type="file"
              required={true}
              component={renderFileField}
              label="Upload File"
              removeabel={true}
            />
            {state.uploadError && (
              <Message negative>
                <p>{state.uploadError}</p>
              </Message>
            )}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
      </div>
    );
  }
}

// export default withRouter(Timeline);
// export default withRouter(Documents);
export default Timeline = reduxForm({
  // a unique name for the form
  form: "timeline",
  validate,
  enableReinitialize: true,
})(Timeline);
