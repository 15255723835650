import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Grid,
  Table,
  Statistic,
  List,
  Loader,
  Header,
  Icon,
} from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";
import { Line, HorizontalBar } from "react-chartjs-2";
import { getFlighthoursProgress } from "./service";

class FlighthourGraph extends Component {
  state = {
    id: this.props && this.props.id,
    data: [],
    loading: true,
    // monthlist: dataformapi.data
  };

  componentDidMount() {
    getFlighthoursProgress(this.state.id).then((res) => {
      if (res && res.status === 200) {
        // console.log('res.data',res.data);
        this.setState({ data: res.data, loading: false });
      }
    });
  }

  render() {
    const state = this.state;
    if (state.loading)
      return <Loader active inline="centered" content="Loading" />;
    return (
      <div>
        <Wrap>
          <Grid columns="2">
            {state.data && state.data.length > 0 ? (
              state.data.map((tmp) => (
                <Grid.Row>
                  <Grid.Column width="12">
                    <Header as="h2">
                      {tmp.name} : {tmp.desc}
                    </Header>
                    <List className="bold">
                      <List.Item>
                        <b>
                          <Icon name="clock" /> Date :{" "}
                          {`${moment(tmp.start, "DD-MM-YYYY").format(
                            "D MMMM YYYY"
                          )} - ${moment(tmp.end, "DD-MM-YYYY").format(
                            "D MMMM YYYY"
                          )}`}
                        </b>
                      </List.Item>
                    </List>
                    <Line
                      data={{
                        labels: tmp.labels,
                        datasets: tmp.datasets,
                      }}
                      options={{
                        tooltips: {
                          mode: "point",
                          callbacks: {
                            beforeLabel: function (tooltipItem, data) {
                              if (
                                data.datasets[tooltipItem.datasetIndex].data1[
                                  tooltipItem.index
                                ]
                              )
                                return (
                                  "Check List : " +
                                  data.datasets[tooltipItem.datasetIndex].data1[
                                    tooltipItem.index
                                  ]
                                );
                            },
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width="4">
                    <Table celled compact size="small">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Months
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Hours
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {tmp.data.map((m) => (
                          <Table.Row>
                            <Table.Cell>{m.month}</Table.Cell>
                            <Table.Cell textAlign="center">
                              {m.hours}
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                      <Table.Footer>
                        <Table.Row>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            <b>Total</b>
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            <b>{tmp.total}</b>
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              ))
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <p style={{ color: "rgb(115, 115, 115)" }}>
                    No description available
                  </p>
                  <br />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Wrap>
      </div>
    );
  }
}

export default withRouter(FlighthourGraph);
