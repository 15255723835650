import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import {
  Grid,
  Header,
  Segment,
  Breadcrumb,
  Loader,
  Icon,
  Button,
  Tab
} from "semantic-ui-react";
import {
  getCourseDetailById,
  getGroundInstructionType,
  updateCourse,
  getCourseModule,
  getSubjects,
  updateModuleInCourse,
  updateModuleFlightHourCriteria,
  getProgressChecklist,
  getSubjectByModule,
  updateProgressChecklist,
  getSubjectFlightGroupByModule,
  updateSubjectFlightGroupByModule,
  getModuleTimeline,
  updateModuleTimeline
} from "./service";
import Wrap from "../../components/wrap";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import Formcourse from "./courseEditForm";
import FormcourseModule from "./courseModuleForm";
import FormcourseModuleGroup from "./courseModuleGroupForm";
import FormTimeline from "./courseTimelineForm";
import FormcourseModuleProgressChecklist from "./courseModuleProgressChecklist";
import ModuleDetail from "./moduleDetail";
import moment from "moment";

class CourseDetail extends Component {
  state = {
    data: [],
    panes: [],
    loading: true,
    options_module: [],
    option_groundinstructiontype: [
      {
        key: "default",
        id: "default",
        title: "TH / ENG",
        text: "TH / ENG",
        value: "default"
      }
    ],
    errors: null
  };

  componentDidMount() {
    if (!this.props.history.location.state) {
      this.props.history.push("/Course/Course");
    }
    getGroundInstructionType().then(res => {
      let { option_groundinstructiontype } = this.state;
      if (res && res.status === 200) {
        res.data.map(item => {
          option_groundinstructiontype.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item.id
          });
        });
        this.setState({ option_groundinstructiontype });
      }
    });
    getCourseModule("?desc=master").then(res => {
      if (res && res.status === 200) {
        let options_module = [];
        res.data.map(item => {
          options_module.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item
          });
        });
        this.setState({ options_module });
      }
    });

    this.getData(this.props.history.location.state.id);
    this.getOptionGroundSubjects();
    this.getOptionFlightSubjects();
  }

  getData = id => {
    // console.log('getData',id);
    // const { data } = this.state
    getCourseDetailById(id).then(res => {
      if (res && res.status === 200) {
        let panes = [];
        res.data.module.map(item => {
          let panesName = item.name;
          panes.push({
            menuItem: panesName,
            render: () => (
              <Wrap>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Button.Group
                        floated="right"
                        size="small"
                        style={{ marginBottom: "20px" }}
                      >
                        <Button icon onClick={() => this.onClickEditModule(item)}>
                          <Icon name="edit" /> Edit Module
                        </Button>
                        {/*<Button icon onClick={() => this.onClickManageTimeline(item)}>
                          <Icon name="line graph" /> Timeline
                        </Button>*/}
                        {!item.master &&
                            <Button
                              icon
                              onClick={() => this.onClickManageProgressChecklist(item)}
                            >
                              <Icon name="tasks" /> Progress Checklist
                          </Button>
                        }
                        <Button icon onClick={() => this.onClickManageGroup(item)}>
                          <Icon name="object group outline" /> Flight Group
                        </Button>
                      </Button.Group>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <ModuleDetail courseId={id} id={item.id} data={item} onClose={this.onClickCancel}/>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Wrap>
            )
          });
        });
        this.setState({ data: res.data, rawData: res.data, panes });
      }
      this.setState({ loading: false });
    });
  };

  getOptionGroundSubjects = () => {
    let option_subject = [];
    getSubjects("?type=G").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_subject.push({
            key: item.id,
            id: item.id,
            title: `${item.name}`,
            text:
              item.hours && item.reference
                ? `${item.name} (${item.reference}) (${item.hours})`
                : item.hours
                ? `${item.name}  (${item.hours})`
                : item.name,
            value: item.id
          });
        });
        this.setState({ option_subject });
      }
    });
  };

  getOptionFlightSubjects = () => {
    let option_flightSubject = [],
      option_flightAddSubject = [];
    getSubjects("?type=F").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_flightSubject.push({
            key: item.id,
            id: item.id,
            title: `${item.code}`,
            text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`,
            value: item.id
          });
        });
        this.setState({ option_flightSubject });
      }
    });
    getSubjects("?type=FA").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_flightAddSubject.push({
            key: item.id,
            id: item.id,
            title: `${item.code}`,
            text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`,
            value: item.id
          });
        });
        this.setState({ option_flightAddSubject });
      }
    });
  };

  onClickEditModule = value => {
    let groundSubjects = [],
      flightSubjects = [],
      flightAddSubjects = [];
    if (value.master) value.desc = null;
    value.groundSubjects.map(item => groundSubjects.push(item.id));
    value.groundSubjects = groundSubjects;
    value.flightSubjects.map(item => flightSubjects.push(item.id));
    value.flightSubjects = flightSubjects;
    value.flightAddSubjects.map(item => flightAddSubjects.push(item.id));
    value.flightAddSubjects = flightAddSubjects;
    this.setState({
      openmodule: true,
      currentData: value,
      isNew: false,
      content: `Module ${value.name}`
    });
  };

  onClickManageTimeline = value => {
    let { data } = this.state;
    value.moduleStart = value.start ? value.start : data.start;
    value.moduleEnd = value.end ? value.end : data.end;
    value.minStart = data.start;
    value.maxEnd = data.end;
    this.setState({
      opentimeline: true,
      currentData: value,
      content: `Module ${value.name}`
    });
  };

  onClickManageProgressChecklist = value => {
    const { data } = this.state
    // console.log('first',this.state.data,value);
    this.setState({fetching:true,  openChecklist: true})
    let option_subjectByModule = []
    getSubjectByModule(value.id).then(res=>{
      if(res && res.status ===200){
        res.data.flightSubjects.map(item=>{
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            })
        })
        res.data.groundSubjects.map((item) => {
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.name,
                text: item.name,
                value: item.id
            })
        })
        this.setState({ option_subjectByModule })
        // console.log(this.state.data,value);
        let result = {
          courseId: data.id,
          moduleId: res.data.id
        }
        getModuleTimeline(result).then(res=>{
            if(res && res.status === 200){
              // console.log('getModuleTimeline', res.data);
                let currentChecklist = []
                res.data && res.data.checklist.length > 0 && res.data.checklist.map(item=>
                    // currentChecklist.push(item)
                    currentChecklist.push({
                      created_at: item.created_at,
                      diff: item.diff,
                      subjects: item.subjects,
                      id: item.id,
                      module_id: item.module_id,
                      name: item.name,
                      startend: `${item.start} - ${item.end}`, //moment(item.start,"DD-MM-YYYY").format("DD/MM/YYYY")
                      updated_at: item.updated_at,
                      check_checklist: item.check_checklist
                    })
                )
                // console.log('currentChecklist', currentChecklist);
                this.setState({
                    currentData: {
                        id: res.data && res.data.id,
                        name: res.data && res.data.name,
                        moduleStartEnd: res.data && (res.data.moduleStart || res.data.moduleEnd) && `${res.data.moduleStart} - ${res.data.moduleEnd}`,  //&& moment(res.data.moduleStart, "DD-MM-YYYY").format("DD/MM/YYYY")
                        // checklist: currentChecklist.length === 0 ?[{"name":null,"subject":[]}] : currentChecklist
                        checklist: currentChecklist.length === 0 ? [{"name":null}] : currentChecklist
                    },
                    fetching:false
                })
            }
        })
      }
      // this.setState({fetching:false})
    })
  }

  onClickManageGroup = value => {
    this.setState({fetching:true,  openGroup: true})
      getSubjectFlightGroupByModule(value.id).then(res => {
          if (res && res.status === 200) {
              let subject =[]
              res.data.map(elem=>{
                  let selected=[]
                  elem.subject.map(item=>{
                      selected.push(item.id)
                  })
                  subject.push({
                      id: elem.id,
                      name: elem.name ,
                      subject:selected
                  })
              })
              this.setState({
                  currentData: {
                      id: value.id,
                      name: value.name,
                      flightSubjects: subject
                  },rawDataGroup:res.data
              })
              // console.log({
              //     id: value.id,
              //     name: value.name,
              //     flightSubjects: subject
              // },"currentData");
          }
      })
      getSubjectByModule(value.id).then(res=>{
        let option_flightSubject = []
        if(res && res.status === 200){
            res.data.flightSubjects.map(item => option_flightSubject.push({
                key: item.id, id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            }));
            res.data.flightAddSubjects.map(item => option_flightSubject.push({
                key: item.id, id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            }));
        }
        this.setState({ fetching: false, option_flightSubjectByModule: option_flightSubject})
      })
  };

  onClickCancel = () => {
    this.setState({ loading: true });
    this.getData(this.props.history.location.state.id);
    this.setState({
      openmodule: false,
      opentimeline: false,
      openGroup: false,
      openChecklist: false,
      currentData: null,
      open: false,
      initialValues: null,
      errors: null
    });
  };

  onClickEdit = () => {
    let { data } = this.state;
    let initialValues = {
      id: data.id,
      coursemaster: data.coursemaster,
      name: data.name,
      desc: data.desc,
      courseStart: data.start,
      courseEnd: data.end,
      active: data.active,
      additionalCourse: data.additionalCourse,
      courseType:data.courseType,
      groundinstructiontype: data.groundinstructiontype
        ? data.groundinstructiontype.id
        : "default",
      module: data.module
    };

    this.setState({ initialValues, open: true });
  };

  updateModule = value => {
    this.setState({ loading: true });
    updateModuleInCourse(this.props.history.location.state.id, value).then(
      res => {
        if (res && res.status === 200) {
          this.onClickCancel();
        }
      }
    );
  };

  updateProgressChecklist = value =>{
      this.setState({ loading: true, errors: null });
      let { data,currentData } = this.state;
      // console.log('updateProgressChecklist',currentData);
      // console.log('value',value);
      if (value) {
        const mod = value.moduleStartEnd.split(' - ')
        value.moduleStart = mod[0]
        value.moduleEnd = mod[1]

        value.checklist.map(ch => {
          const res = ch.startend.split(' - ')
          ch.start = res[0]
          ch.end = res[1]
          if (!ch.diff) {
            ch.diff = null
          }

          if (!ch.check_checklist) {
            ch.check_checklist = null
          }
        })
      }
      let result = {
        id: currentData.id,
        name: currentData.name,
        ...value
      }
    // console.log('value',result);
    updateModuleTimeline(data.id,result).then(res=>{
        if(res && res.status === 200){
          this.onClickCancel();
        }else if (res && res.status === 422) {
          this.setState({errors : res.data.errors})
        }
        this.setState({ loading: false });
        // this.onClickCancel();
        //   this.setState({ loading: false });
    })
  }

  updateTimeline = value => {
    this.setState({ loading: true });
    let data = {
      module_id: value.id,
      start: value.moduleStart,
      end: value.moduleEnd
    };
    updateModuleFlightHourCriteria(
      this.props.history.location.state.id,
      data
    ).then(res => {
      if (res && res.status === 200) {
        this.onClickCancel();
      }
    });
  };

  updateCourse = value => {
    this.setState({ loading: true });
    let module = [];
    value.module.map(item => {
      if (item.id) module.push(item.id);
    });
    if (value.module_add)
      value.module_add.map(item => {
        if (item.id) module.push(item.id);
      });
    let data = {
      id: value.id,
      desc: value.desc,
      additionalCourse: value.additionalCourse ? "true" : "false",
      groundinstructiontype:
        value.groundinstructiontype === "default"
          ? null
          : value.groundinstructiontype,
      active: value.active ? "true" : "false",
      start: value.courseStart,
      end: value.courseEnd,
      courseType:value.courseType,
      module: module
    };
    updateCourse(data).then(res => {
      if (res && res.status === 200) {
        this.setState({ loading: false });
        this.setState({ open: false, initialValues: null });
        this.getData(this.props.history.location.state.id);
      }
    });
  };

  updateFlightGroup = value => {
      this.setState({loading:true})
      updateSubjectFlightGroupByModule(value).then(res=>{
        if(res && res.status === 200)
          this.onClickCancel();
          this.setState({ loading: false });
      })
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex })

  render() {
    const state = this.state;
    return (
      <div>
        <ModalForm
          open={state.open}
          icon="book"
          content="Edit Course"
          close={this.onClickCancel}
        >
          <Formcourse
            loading={state.loading}
            initialValues={state.initialValues}
            onSubmit={this.updateCourse}
            options_module={state.options_module}
            option_groundinstructiontype={state.option_groundinstructiontype}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <ModalForm
          open={state.opentimeline}
          icon="book"
          size="small"
          content={state.content}
          close={this.onClickCancel}
        >
          <FormTimeline
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={this.updateTimeline}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <ModalForm
          open={state.openmodule}
          icon="book"
          size="small"
          content={state.content}
          close={this.onClickCancel}
        >
          <FormcourseModule
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            option_subject={state.option_subject}
            option_flightSubject={state.option_flightSubject}
            option_flightAddSubject={state.option_flightAddSubject}
            onSubmit={this.updateModule}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <ModalForm
            open={state.openChecklist}
            icon='tasks'
            size='small'
            content='Progress Checklist'
            close={this.onClickCancel}  >
            <FormcourseModuleProgressChecklist
                loading={state.loading}
                fetching={state.fetching}
                data={state.data}
                initialValues={state.currentData ? state.currentData : null}
                // initialValues={state.currentData ? state.currentData : { checklist:[]}}
                option_subject={state.option_subjectByModule}
                onSubmit={this.updateProgressChecklist}
                errors={state.errors}
                onCancel={this.onClickCancel}
            />
        </ModalForm>
        <ModalForm
          open={state.openGroup}
          icon="object group outline"
          size="small"
          content={`Flight Group`}
          close={this.onClickCancel}
        >
          <FormcourseModuleGroup
            loading={state.loading}
            fetching={state.fetching}
            initialValues={state.currentData ? state.currentData : null}
            option_flightSubject={state.option_flightSubjectByModule}
            onSubmit={this.updateFlightGroup}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Course">
            <Button size="small" onClick={this.onClickEdit}>
              Edit Course
            </Button>
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course/Course")}
              style={{ color: "grey" }}
            >
              Course
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>
              {state.data && state.data.name}
            </Breadcrumb.Section>
          </Breadcrumb>
          <br />
          <br />
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            state.data && (
              <Wrap>
                <br />
                <Grid>
                  <Grid.Row divided columns={2} style={{ paddingBottom: 0 }}>
                    <Grid.Column width="4">
                      <Header as="h2" icon textAlign="center">
                        <Icon
                          name="student"
                          circular
                          style={{ color: config.colorTheme }}
                        />
                        {state.data.name}
                        <Header.Subheader>
                          {state.data.desc}{" "}
                          {state.data.additionalCourse &&
                            " (Additional Course) "}
                        </Header.Subheader>
                      </Header>
                    </Grid.Column>
                    <Grid.Column width="12">
                      <Grid columns="2">
                        <Grid.Column width="3">
                          <p className="bold">Active</p>
                        </Grid.Column>
                        <Grid.Column width="13">
                          {state.data.active ? "Active" : "Not Active"}
                        </Grid.Column>
                        <Grid.Column width="3" style={{ paddingTop: "0px" }}>
                          <p className="bold">Start</p>
                        </Grid.Column>
                        <Grid.Column width="13" style={{ paddingTop: "0px" }}>
                          {`${moment(state.data.start, "DD-MM-YYYY").format(
                            "D MMMM YYYY"
                          )} - ${moment(state.data.end, "DD-MM-YYYY").format(
                            "D MMMM YYYY"
                          )}`}
                        </Grid.Column>

                        <Grid.Column width="3" style={{ paddingTop: "0px" }}>
                          <p className="bold">Ground Instruction Type</p>
                        </Grid.Column>
                        <Grid.Column width="13" style={{ paddingTop: "0px" }}>
                          {state.data.groundinstructiontype
                            ? state.data.groundinstructiontype.name
                            : "TH / ENG"}
                        </Grid.Column>
                        <Grid.Column width="3" style={{ paddingTop: "0px" }}>
                          <p className="bold">Course Type</p>
                        </Grid.Column>
                        <Grid.Column width="13" style={{ paddingTop: "0px" }}>
                          {state.data.courseType}
                        </Grid.Column>
                        <Grid.Column width="3" style={{ paddingTop: "0px" }}>
                          <p className="bold">Created</p>
                        </Grid.Column>
                        <Grid.Column width="13" style={{ paddingTop: "0px" }}>
                          {moment(state.data.created_at).format(
                            "D MMMM YYYY HH:ss:mm"
                          )}
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <br />

                <Tab
                  renderActiveOnly
                  activeIndex={state.activeIndex}
                  menu={{ secondary: true, pointing: true }}
                  onTabChange={this.handleTabChange}
                  panes={state.panes}
                />
              </Wrap>
            )
          )}
        </Segment>
      </div>
    );
  }
}

export default withRouter(CourseDetail);
