import React from "react";
import { Statistic, Label } from "semantic-ui-react";
import * as config from "../config";

const StatisticLabel = props => {
  return (
    <Statistic>
      <Statistic.Label style={{ fontSize: 12 }}>{props.label}</Statistic.Label>
      <Statistic.Value>
        <Label color={props.color} size='massive'>{props.value || '0'}</Label>
      </Statistic.Value>
    </Statistic>
  );
};

export default StatisticLabel;
