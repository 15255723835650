import React, { Component } from "react";
import { Form, Button, Message, Divider, Grid } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import { roles } from "../Settings/data";
import {
  options_title_eng,
  options_title_th,
  options_studentstatus,
  options_studentstatus_profile,
  options_religion,
  options_degree,
  options_relationship,
} from "../../config";
const validate = (values) => {
  const errors = {};
  if (!values.customerCode) errors.customerCode = true;
  if (!values.studentId) errors.studentId = true;
  if (!values.batchCode) errors.batchCode = true;
  if (!values.firstNameEng) errors.firstNameEng = true;
  if (!values.lastNameEng) errors.lastNameEng = true;
  if (!values.firstNameTh) errors.firstNameTh = true;
  if (!values.lastNameTh) errors.lastNameTh = true;
  if (!values.email) errors.email = true;
  let regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})(\.[a-z]{2,8})?$/;
  if (values.email && !regex.test(values.email))
    errors.email = "Invalid email format";
  if (!values.phone) errors.phone = true;
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.studentType) errors.studentType = true;
  if (!values.username) errors.username = true;
  if (values.studentType && values.studentType.length === 0)
    errors.studentType = true;
  if (!values.foreignerCountry) errors.foreignerCountry = true;
  if (!values.status) errors.status = true;
  if (!values.gender) errors.gender = true;
  // if (!values.idcard) errors.idcard = true;
  // if (!values.address) errors.address = true;
  // if (!values.degree) errors.degree = true;
  // if (!values.institute) errors.institute = true;
  // if (!values.religion) errors.religion = true;
  // if (!values.relationship) errors.relationship = true;
  // if (!values.motherFirstName) errors.motherFirstName = true;
  // if (!values.motherLastName) errors.motherLastName = true;
  // if (!values.motherPhone) errors.motherPhone = true;
  // if (!values.fatherFirstName) errors.fatherFirstName = true;
  // if (!values.fatherLastName) errors.fatherLastName = true;
  // if (!values.fatherPhone) errors.fatherPhone = true;
  // if (!values.emergencyName) errors.emergencyName = true;
  // if (!values.emergencyPhone) errors.emergencyPhone = true;
  return errors;
};

class FormStudent extends Component {
  state = {
    optionsRoles: [],
    optionsGender: [
      { key: "gender01", value: "male", text: "Male" },
      { key: "gender02", value: "female", text: "Female" },
    ],
    radioMedicalClass: [
      { key: "m01", value: 1, label: "1" },
      { key: "m02", value: 2, label: "2" },
    ],
  };

  componentDidMount() {
    let options = [];
    roles.map((item) => {
      options.push({
        key: item.id,
        value: item.id,
        text: item.name,
      });
    });
    this.setState({ optionsRoles: options });
  }
  render() {
    const state = this.state;
    const props = this.props;
    const {
      handleSubmit,
      pristine,
      submitting,
      hasForeigner,
      loading,
      loadingUp,
      errorMessage,
      isEdit,
      isAdd,
      activeUser,
      status,
      startDrop
      // haveCourse
    } = this.props;
    console.log(startDrop,"startDrop")
    return (
      <Form onSubmit={handleSubmit}>
        <Grid columns={2} divided>
          <Grid.Column>
            <Form.Group widths="equal">
              <Field
                name="customerCode"
                type="text"
                required={true}
                component={renderField}
                label="Customer Code"
              />
              <Field
                name="studentId"
                type="text"
                required={true}
                labelDesc="(EN)"
                component={renderField}
                label="Student ID"
              />
            </Form.Group>
            <Form.Group>
              <Field
                name="batchCode"
                width="8"
                type="text"
                required={true}
                component={renderField}
                label="Batch Code"
              />
            </Form.Group>
            <Form.Group widths="3">
              <Field
                name="titleEng"
                // width='3'
                component={renderSelectField}
                label="Title (EN)"
                options={options_title_eng}
              />
              <Field
                name="firstNameEng"
                type="text"
                // width="6"
                required={true}
                component={renderField}
                label="First name (EN)"
              />
              <Field
                name="lastNameEng"
                type="text"
                // width="6"
                required={true}
                labelDesc="(EN)"
                component={renderField}
                label="Last name (EN)"
              />
            </Form.Group>
            <Form.Group widths="3">
              <Field
                name="titleTh"
                component={renderSelectField}
                label="Title (TH)"
                options={options_title_th}
              />
              <Field
                name="firstNameTh"
                type="text"
                // width="6"
                required={true}
                component={renderField}
                label="First name (TH)"
              />
              <Field
                name="lastNameTh"
                type="text"
                // width="6"
                required={true}
                component={renderField}
                label="Last name (TH)"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="foreignerCountry"
                type="text"
                required={true}
                component={renderField}
                label="Nationality"
              />
              <Field
                name="nickName"
                type="text"
                // required={true}
                component={renderField}
                label="Nick name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="personnel_email"
                type="text"
                // required={true}
                component={renderField}
                label="Personnel Email"
              />
              <Field
                name="phone"
                type="text"
                required={true}
                component={renderField}
                label="Phone"
              />
            </Form.Group>
            <Field
              name="email"
              type="text"
              required={true}
              component={renderField}
              label="BAC Email"
            />
            <Form.Group widths="equal">
              <Field
                name="studentType"
                required={true}
                component={renderSelectField}
                label="Student type"
                options={props.options_studenttype}
              />
              {!(status === "Graduated" || status === "Terminated") && (
                <Field
                  name="status"
                  required={true}
                  component={renderSelectField}
                  label="Student status"
                  options={options_studentstatus_profile}
                />
              )}
            </Form.Group>
            {status === "Drop"  && (
            <Form.Group widths="equal">
              <Field
                name="startDrop"
                // required={true}
                component={renderDateOnlyField}
                label="Start"
              />
              <Field
                name="endDrop"
                // required={true}
                // minDate={startDrop ? startDrop : null}
                component={renderDateOnlyField}
                label="End"
              />
            </Form.Group>
            )}
            <Form.Group widths="equal">
              <Field
                name="class"
                // required={true}
                component={renderSelectField}
                label="Class"
                options={props.options_class}
              />
              <Field
                name="company"
                // required={true}
                component={renderSelectField}
                options={props.options_company}
                label="Company Name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="dateOfBirth"
                required={true}
                component={renderDateOnlyField}
                label="Date of Birth"
              />
              <Field
                name="gender"
                required={true}
                component={renderSelectField}
                label="Gender"
                options={state.optionsGender}
              />
            </Form.Group>
            <Field
              name="idcard"
              type="text"
              // required={true}
              component={renderField}
              label="ID (thai ID/passport)"
            />
            <Field
              name="address"
              type="textarea"
              // required={true}
              component={renderTextAreaField}
              label="Address"
            />
            <Form.Group widths="equal">
              <Field
                name="degree"
                options={options_degree}
                // required={true}
                component={renderSelectField}
                label="Degree"
              />
              <Field
                name="institute"
                type="text"
                // required={true}
                component={renderField}
                label="Institute"
              />
            </Form.Group>
          </Grid.Column>
          <Grid.Column>
            <Form.Group widths="equal">
              <Field
                name="height"
                type="number"
                // required={true}
                component={renderField}
                label="Height (cm)"
              />
              <Field
                name="weight"
                type="number"
                // required={true}
                component={renderField}
                label="Weight (kg)"
              />
            </Form.Group>
            <Form.Group>
              <Field
                name="identifyFeature"
                type="text"
                // required={true}
                component={renderField}
                label="Identifying Features"
              />
              <Field
                name="religion"
                options={options_religion}
                // required={true}
                component={renderSelectField}
                label="Religion"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="relationship"
                options={options_relationship}
                // required={true}
                component={renderSelectField}
                label="Relationship Status"
              />
              <Field
                name="children"
                type="number"
                // required={true}
                component={renderField}
                label="Children"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="motherFirstName"
                type="text"
                // required={true}
                component={renderField}
                label="Mother's First Name"
              />
              <Field
                name="motherLastName"
                type="text"
                // required={true}
                component={renderField}
                label="Mother's Last Name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="motherPhone"
                type="text"
                // required={true}
                component={renderField}
                label="Mother's Contact Number"
              />
              <Field
                name="motherAge"
                type="number"
                // required={true}
                component={renderField}
                label="Mother's Age"
              />
            </Form.Group>
            <Field
              name="motherOccupation"
              type="text"
              // required={true}
              component={renderField}
              label="Mother's Occupation"
            />

            <Form.Group widths="equal">
              <Field
                name="fatherFirstName"
                type="text"
                // required={true}
                component={renderField}
                label="Father's First Name"
              />
              <Field
                name="fatherLastName"
                type="text"
                // required={true}
                component={renderField}
                label="Father's Last Name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="fatherPhone"
                type="text"
                // required={true}
                component={renderField}
                label="Father's Contact Number"
              />
              <Field
                name="fatherAge"
                type="number"
                // required={true}
                component={renderField}
                label="Father's Age"
              />
            </Form.Group>
            <Field
              name="fatherOccupation"
              type="text"
              // required={true}
              component={renderField}
              label="Father's Occupation"
            />
            <Form.Group widths="equal">
              <Field
                name="spouseFirstName"
                type="text"
                // required={true}
                component={renderField}
                label="Spouse's First Name"
              />
              <Field
                name="spouseLastName"
                type="text"
                // required={true}
                component={renderField}
                label="Spouse's Last Name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="spousePhone"
                type="text"
                // required={true}
                component={renderField}
                label="Spouse's Contact Number"
              />
              <Field
                name="spouseAge"
                type="number"
                // required={true}
                component={renderField}
                label="Spouse's Age"
              />
            </Form.Group>
            <Field
              name="spouseOccupation"
              type="text"
              // required={true}
              component={renderField}
              label="Spouse's Occupation"
            />
            <Form.Group widths="equal">
              <Field
                name="emergencyName"
                type="text"
                // required={true}
                component={renderField}
                label="Emergency Contact"
              />
              <Field
                name="emergencyPhone"
                type="text"
                // required={true}
                component={renderField}
                label="Emergency Contact Number"
              />
            </Form.Group>

            {status === "Graduated" && (
              <div>
                <br></br>
                <Form.Group widths="equal">
                  <Field
                    name="licencenumber"
                    type="text"
                    component={renderField}
                    label="License Number"
                  />
                  <Field
                    name="issueddate"
                    type="text"
                    component={renderField}
                    label="Issue Date"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Field
                    name="new_company"
                    type="text"
                    component={renderField}
                    label="New Company"
                  />
                  <Field
                    name="last_company"
                    type="text"
                    component={renderField}
                    label="Last Company"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Field
                    name="job_name"
                    type="text"
                    component={renderField}
                    label="Job"
                  />
                  <Field
                    name="job_position"
                    type="text"
                    component={renderField}
                    label="Position"
                  />
                </Form.Group>
                <Form.Group>
                  <Field
                    name="year_employed"
                    type="text"
                    component={renderField}
                    label="Year employed"
                    width="6"
                  />
                </Form.Group>
              </div>
            )}
          </Grid.Column>
        </Grid>
        <br />
        <Button
          compact
          floated="left"
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          type="button"
          content="Cancel"
          color="green"
          basic
          compact
          onClick={props.onCancel}
        />
      </Form>
    );
  }
}

// FormStudent = reduxForm({
//   // a unique name for the form
//   form: "studentform",
//   validate,
//   enableReinitialize: true
// })(FormStudent);

// export default FormStudent;

FormStudent = reduxForm({
  // a unique name for the form
  form: "studentform",
  validate,
  enableReinitialize: true,
})(FormStudent);

const selector = formValueSelector("studentform");
FormStudent = connect((state) => {
  const hasForeigner = selector(state, "foreigner");
  const status = selector(state, "status");
  const startDrop = selector(state, "startDrop");
  const endDrop = selector(state, "endDrop");
  return {
    hasForeigner,
    status,
    startDrop,
    endDrop
  };
})(FormStudent);
export default FormStudent;
