import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Popup,
  Button
} from "semantic-ui-react";
import ModalForm from "../../components/modal";
import MainButton from "../../components/mainbutton";
import Confirmmodal from "../../components/confirmmodal";

import * as config from "../../config";
import {
  getMake,
  postMake,
  updateMake,
  getModel,
  postModel,
  updateModel,
  getAirport,
  postAirport,
  updateAirport,
  deleteMake,
  deleteModel,
  deleteAirport
} from "./service";
class Settings extends Component {
  state = {
    menu: [
      {
        id: 1,
        icon: "plane",
        description: "Aircraft Make",
        type: "make"
      },
      {
        id: 2,
        icon: "plane",
        description: "Aircraft Model",
        type: "model"
      },
      {
        id: 3,
        icon: "plane",
        description: "Airport",
        type: "airport"
      }
    ],
    data: []
  };

  getData = type => {
    this.setState({ type, open: true });
    if (type === "make") {
      this.setState({ content: "Aircraft Make" });
      this.getMake();
    } else if (type === "model") {
      this.setState({ content: "Aircraft Model" });
      this.getModel();
    } else if (type === "airport") {
      this.setState({ content: "Airport" });
      this.getAirport();
    }
  };
  getMake = () => {
    this.setState({ loading: true });
    getMake().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data
        });
      }
      this.setState({
        loading: false,
        loadingUp: false
      });
    });
  };
  getModel = () => {
    this.setState({ loading: true });
    getModel().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data
        });
      }
      this.setState({
        loading: false,
        loadingUp: false
      });
    });
  };
  getAirport = () => {
    this.setState({ loading: true });
    getAirport().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data
        });
      }
      this.setState({
        loading: false,
        loadingUp: false
      });
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, data: [], editId: null, editName: null });
  };
  saveData = () => {
    let { type, name } = this.state;
    this.setState({ loading: true });
    if (type === "make") {
      postMake({ name: name }).then(res => {
        if (res && res.status === 201) {
          this.getMake();
          this.setState({ name: null });
        }
      });
    } else if (type === "model") {
      postModel({ name: name }).then(res => {
        if (res && res.status === 201) {
          this.getModel();
          this.setState({ name: null });
        }
      });
    } else if (type === "airport") {
      postAirport({ name: name, default: false }).then(res => {
        if (res && res.status === 201) {
          this.getAirport();
          this.setState({ name: null });
        }
      });
    }
  };
  updateData = () => {
    let { type, editId, editName } = this.state;
    this.setState({ loadingUp: true });
    if (type === "make") {
      updateMake({ id: editId, name: editName }).then(res => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getMake();
        }
      });
    } else if (type === "model") {
      updateModel({ id: editId, name: editName }).then(res => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getModel();
        }
      });
    } else if (type === "airport") {
      const result = this.state.data.find(member => {
        return parseInt(member.id) === parseInt(editId);
      });
      updateAirport({
        id: editId,
        name: editName,
        default: result && result.default ? result.default : false
      }).then(res => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getAirport();
        }
      });
    }
  };

  setDefaultairport = value => {
    let { data } = this.state;
    const result = data.find(member => {
      return member.default;
    });
    if (result)
      updateAirport({
        id: result.id,
        name: result.name,
        default: "false"
      }).then(res => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getAirport();
        }
      });
    updateAirport({ id: value.id, name: value.name, default: "true" }).then(
      res => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getAirport();
        }
      }
    );
  };
  deleteData = () => {
    let { type, currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      if (type === "make") {
        deleteMake(currentData.id).then(res => {
          if (res && res.status === 200) {
            this.getMake();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
      } else if (type === "model") {
        deleteModel(currentData.id).then(res => {
          if (res && res.status === 200) {
            this.getModel();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
      } else if (type === "airport") {
        deleteAirport(currentData.id).then(res => {
          if (res && res.status === 200) {
            this.getAirport();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
      }
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleChangeUpdate = (e, { value }) => this.setState({ editName: value });
  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <Header as="h2" style={{ color: config.colorTheme }}>
            Aircraft Setting
          </Header>
          <Card.Group itemsPerRow="5" doubling style={{ padding: "20px" }}>
            {state.menu.map(item => (
              <Card
                raised
                key={item.id}
                style={{ background: "#ededed" }}
                onClick={() => this.getData(item.type)}
              >
                <Card.Content style={{ textAlign: "center" }}>
                  <Header as="h5" icon style={{ marginBottom: "0px" }}>
                    <Icon
                      name={item.icon}
                      style={{ color: config.colorTheme }}
                    />
                    {item.description}
                  </Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <br />
          <br />
        </Segment>

        {/* make */}
        <ModalForm
          open={state.open}
          icon="plane"
          content={state.content}
          close={this.onClickCancel}
        >
          {!state.editId && (
            <Form onSubmit={this.saveData}>
              <Form.Group>
                <Form.Input
                  placeholder="Make Name"
                  name="name"
                  width={13}
                  onChange={this.handleChange}
                />
                <MainButton
                  size="small"
                  width={3}
                  text="Add New"
                  // loading={state.loading}
                  disable={!state.name || state.loading}
                />
              </Form.Group>
            </Form>
          )}
          <Table compact celled striped style={{ marginBottom: "30px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="header-table" width={1} />
                <Table.HeaderCell className="header-table" width={10}>
                  name
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  textAlign="center"
                  width={3}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.loading ? (
                <Table.Row>
                  <Table.Cell textAlign="right"> </Table.Cell>
                  <Table.Cell textAlign="left" colSpan="2">
                    Loading..
                  </Table.Cell>
                </Table.Row>
              ) : (
                state.data.map((item, num) => {
                  return (
                    <Table.Row>
                      <Table.Cell textAlign="right"> {num + 1} </Table.Cell>
                      <Table.Cell>
                        {state.editId === item.id ? (
                          <Form size="small">
                            <Form.Group style={{ marginBottom: "0px" }}>
                              <Form.Input
                                placeholder="Name"
                                name="name"
                                width={10}
                                value={state.editName}
                                onChange={this.handleChangeUpdate}
                              />
                              <Button
                                animated="vertical"
                                size="small"
                                color="green"
                                loading={state.loadingUp}
                                onClick={this.updateData}
                              >
                                <Button.Content hidden>Update</Button.Content>
                                <Button.Content visible>
                                  <Icon name="check" />
                                </Button.Content>
                              </Button>
                              <Button
                                size="small"
                                animated="vertical"
                                onClick={() =>
                                  this.setState({
                                    editId: null,
                                    editName: null
                                  })
                                }
                              >
                                <Button.Content hidden>Cancel</Button.Content>
                                <Button.Content visible>
                                  <Icon name="x" />
                                </Button.Content>
                              </Button>
                            </Form.Group>
                          </Form>
                        ) : (
                          <div>
                            <p
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  editId: item.id,
                                  editName: item.name
                                })
                              }
                            >
                              {item.name}
                            </p>
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {state.type === "airport" && item.default === "true" ? (
                          <Icon name="star" color="yellow" />
                        ) : (
                          state.type === "airport" && (
                            <Popup
                              style={{ filter: "none" }}
                              content="Set default aiport"
                              trigger={
                                <Icon
                                  name="star"
                                  link
                                  color="grey"
                                  inverted
                                  onClick={() => this.setDefaultairport(item)}
                                />
                              }
                            />
                          )
                        )}
                        &nbsp; &nbsp;
                        <Icon
                          link
                          name="edit"
                          onClick={() =>
                            this.setState({
                              editId: item.id,
                              editName: item.name
                            })
                          }
                        />
                        &nbsp; &nbsp;
                        <Icon
                          link
                          color="red"
                          name="trash alternate"
                          onClick={() => this.onClickDelete(item)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={state.type}
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(Settings);
