import React, { Component } from "react";
import {
  Form,
  Button,
  Table,
  Header,
  Icon,
  Statistic,
} from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderRadioField from "../../components/renderRadioField";
import renderSelectField from "../../components/renderSelectField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import * as config from "../../config";
import moment from "moment";

const validate = (values) => {
  const errors = {};
  if (!values.course) errors.course = true;
  if (!values.desc) errors.desc = true;
  if (!values.courseStart) errors.courseStart = true;
  if (!values.courseEnd) errors.courseEnd = true;
  if (!values.groundinstructiontype) errors.groundinstructiontype = true;
  if (!values.courseType) errors.courseType = true;

  return errors;
};

class Formcourse extends Component {
  state = {
    module: this.props.initialValues ? this.props.initialValues.module : [],
    numOfWeek: null,
    module_add: [],
  };

  componentDidMount() {
    this.setState({
      module: this.props.initialValues ? this.props.initialValues.module : [],
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.courseStart !== this.props.courseStart ||
      nextProps.courseEnd !== this.props.courseEnd
    ) {
      var start = moment(nextProps.courseStart, "DD-MM-YYYY");
      var end = moment(nextProps.courseEnd, "DD-MM-YYYY");
      var duration = moment.duration(end.diff(start));
      var days = duration.asWeeks();
      // console.log(moment(nextProps.courseStart, "DD-MM-YYYY").add(4*7,'days').format("DD-MM-YYYY"),"4");
      // console.log(moment(nextProps.courseStart, "DD-MM-YYYY").add(3*7,'days').format("DD-MM-YYYY"),"3");
      // console.log(moment(nextProps.courseStart, "DD-MM-YYYY").add(2*7,'days').format("DD-MM-YYYY"),"4");
      // console.log(moment(nextProps.courseStart, "DD-MM-YYYY").add(1*7,'days').format("DD-MM-YYYY"),"5");
      // console.log(moment(nextProps.courseStart, "DD-MM-YYYY").add(0*7,'days').format("DD-MM-YYYY"),"6");
      this.setState({
        numOfWeek: days,
      });
    }
    if (nextProps.initialValues !== this.props.initialValues) {
      if (nextProps.initialValues && nextProps.initialValues.module)
        this.setState({ module: nextProps.initialValues.module });
    }
  }
  getOptions = () => {
    let { module } = this.state;
    let current = [],
      options = [];
    module.map((item) => current.push(item.id));
    options = this.props.options_module.filter((member) => {
      if (current.indexOf(member.id) < 0) return member;
    });
    return options;
  };
  onChange = (e, data) => {
    let { module_add } = this.state;
    const result = module_add.find((member) => {
      return member === data.value;
    });
    if (result) {
      module_add[data.index].error = true;
      this.setState({ module_add });
    } else {
      module_add[data.index] = data.value;
      this.setState({ module_add });
      this.props.dispatch(change("courseeditform", "module_add", module_add));
    }
  };
  onAddModule = () => {
    let { module_add } = this.state;
    module_add.push({});
    this.setState({ module_add });
  };
  onDeleteAddModule = (index) => {
    let { module_add } = this.state;
    module_add.splice(index, 1);
    this.setState({ module_add });
  };
  onClickDelete = (index) => {
    let { module } = this.state;
    module.splice(index, 1);
    this.setState({ module });
  };
  render() {
    const state = this.state;
    const {
      courseStart,
      handleSubmit,
      pristine,
      submitting,
      option_groundinstructiontype,
      loading,
      initialValues,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Header as="h2" icon textAlign="center">
          <Icon name="student" circular style={{ color: config.colorTheme }} />
          {initialValues.name}
        </Header>
        <Field
          name="desc"
          type="text"
          required
          component={renderField}
          label="Description"
        />
        <Form.Group>
          <Field
            name="courseStart"
            label="Start"
            required
            width="6"
            component={renderDateOnlyField}
          />
          <Field
            name="courseEnd"
            label="End"
            required
            minDate={courseStart}
            width="6"
            component={renderDateOnlyField}
          />
          {/*<Form.Field
         width="4"
         style={{ textAlign: "center", paddingTop: "20px" }}
       >
         <Statistic size="mini">
           <Statistic.Value style={{ color: "#FF5722" }}>
             {state.numOfWeek || 0}
           </Statistic.Value>
           <Statistic.Label style={{ color: "gray" }}>Week</Statistic.Label>
         </Statistic>
       </Form.Field>

          */}
        </Form.Group>
        <Field
          name="groundinstructiontype"
          component={renderSelectField}
          label="Ground Instruction Type"
          required
          options={option_groundinstructiontype}
        />
        <Field
          name="courseType"
          component={renderSelectField}
          label="Course Type"
          required
          options={[
            { key: "f", text: "Full Time", value: "Full Time" },
            { key: "p", text: "Part Time", value: "Part Time" },
          ]}
        />
        <Field
          name="additionalCourse"
          width="4"
          label="Additional Course"
          component={renderRadioField}
        />
        <Field
          name="active"
          width="4"
          label="Active"
          component={renderRadioField}
        />
        <br />
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table">
                Module
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                Ground
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                Flight
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              ></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.module.map((item, index) => (
              <Table.Row>
                <Table.Cell>{item.name}</Table.Cell>
                <Table.Cell textAlign="center">{item.groundHours}</Table.Cell>
                <Table.Cell textAlign="center">{item.flightHours}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Icon
                    link
                    color="red"
                    name="trash alternate"
                    onClick={() => this.onClickDelete(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            {state.module_add.map((item, index) => (
              <Table.Row>
                <Table.Cell>
                  <Form.Select
                    style={{ marginBottom: "0px" }}
                    name="module_add"
                    error={item.error}
                    index={index}
                    onChange={(e, data) => this.onChange(e, data)}
                    options={this.getOptions()}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">{item.groundHours}</Table.Cell>
                <Table.Cell textAlign="center">{item.flightHours}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Icon
                    link
                    color="red"
                    name="trash alternate"
                    onClick={() => this.onDeleteAddModule(index)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell className="footer-table">Total</Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {state.module.reduce((sum, item) => {
                  return sum + item.groundHours;
                }, 0) +
                  state.module_add.reduce((sum, item) => {
                    return sum + item.groundHours;
                  }, 0)}
              </Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {this.totalDurations(state.module)}
              </Table.Cell>

              <Table.Cell className="footer-table" textAlign="center">
                <Button
                  type="button"
                  primary
                  compact
                  size="small"
                  onClick={this.onAddModule}
                >
                  Add
                </Button>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          // disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }

  totalDurations = (data) => {
    let durations = [];
    let { module_add } = this.state;
    module_add.map((item) => durations.push(item.flightHours));
    data.map((item) => durations.push(item.flightHours));
    const totalDurations = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );

    return this.convertMS(totalDurations.asMilliseconds());
    // moment.utc(totalDurations.asMilliseconds()).format("HH:mm");
  };
  convertMS = (ms) => {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return ((h + "").length === 1 ? "0" + h : h) + ":" + ("0" + m).substr(-2);
  };
}

Formcourse = reduxForm({
  // a unique name for the form
  form: "courseeditform",
  validate,
  enableReinitialize: true,
})(Formcourse);

// Decorate with connect to read form values
const selector = formValueSelector("courseeditform"); // <-- same as form name
Formcourse = connect((state) => {
  const courseStart = selector(state, "courseStart");
  const courseEnd = selector(state, "courseEnd");
  const course = selector(state, "course");
  return {
    courseStart,
    courseEnd,
    course,
  };
})(Formcourse);
export default Formcourse;
