import axios from "axios";
import * as config from "../../config";


export function getReportoffenses() {
  return axios
    .get(config.urlAPI + "/reportoffenses", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function approveOffense(id, data) {
  return axios
    .put(config.urlAPI + "/reportoffenses/approve/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function penalizeOffense(id, data) {
  return axios
    .put(config.urlAPI + "/reportoffenses/penalize/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getDisciplinescore() {
  return axios
    .get(config.urlAPI + "/personnel/disciplinescore", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
