import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = true;

  return errors;
};

class FormLesson extends Component {
  render() {
    const { handleSubmit, pristine, submitting, loading } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Field
          name="name"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Lesson name"
        />
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

export default FormLesson = reduxForm({
  // a unique name for the form
  form: "lesson",
  validate,
  enableReinitialize: true
})(FormLesson);
