import axios from "axios";
import * as config from "../../config";

export function getRoomListListBySearch(search) {
  return axios
    .get(config.urlAPI + "/room" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postRoom(data) {
  return axios
    .post(config.urlAPI + "/room", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateRoom(data) {
  return axios
    .put(config.urlAPI + "/room/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function deleteRoom(id) {
  return axios
    .delete(config.urlAPI + "/room/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getLocation() {
  return axios
    .get(config.urlAPI + "/room/location", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
