import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import validate from "./roomValidate";
import renderField from "../../components/renderField";
import renderRadioField from "../../components/renderRadioField";
import renderSelectField from "../../components/renderSelectField";

class FromRoom extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      option_location,
      loading,
      isNew
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Field
          name="type"
          required={true}
          component={renderSelectField}
          search={true}
          placeholder="Please Select"
          width="6"
          clearable={true}
          label="Room Type"
          options={[
            { id: 2, text: "Class Room", value: 2 },
            { id: 1, text: "Meeting Room", value: 1 }
          ]}
        />
        <Field
          name="location"
          required={true}
          component={renderSelectField}
          search={true}
          placeholder="Please Select"
          width="16"
          clearable={true}
          label="Location"
          options={option_location}
        />
        <Field
          name="name"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Room name"
        />
        <Form.Group>
          <Field
            name="capacity"
            type="number"
            width="4"
            component={renderField}
            label="Capacity"
          />
          <Field
            name="itEquipment"
            type="text"
            width="12"
            component={renderField}
            label="IT Equipment"
          />
        </Form.Group>
        <Field
          name="remark"
          type="text"
          width="16"
          component={renderField}
          label="Remark"
        />
      {!isNew &&   <Field
          name="isActive"
          width="4"
          label="Active"
          component={renderRadioField}
        />}

        <br />
        <Button
          floated="left"
          // size="small"
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          compact
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          // size="small"
          type="button"
          content="Cancel"
          color="green"
          basic
          compact
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

FromRoom = reduxForm({
  // a unique name for the form
  form: "room",
  validate,
  enableReinitialize: true
})(FromRoom);

export default FromRoom;
