const validate = values => {
    const errors = {}

    if (!values.name) {
        errors.name = true
    }

    if (!values.type) {
        errors.type = true
    }

    if (!values.location) {
        errors.location = true
    }

    return errors
}

export default validate