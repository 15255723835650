import React, { Component } from "react";
import {
  Message,
  Form,
  Button,
  Icon
} from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import Wrap from "../../components/wrap";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";

import renderField from "../../components/renderField";
import renderFileField from "../../components/renderFileField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderDateField from "../../components/renderDateField";
import renderTextAreaField from "../../components/renderTextAreaField";
import { postNoteStudent, deleteNoteStudent } from '../Student/service'
import { postNoteInstructor, deleteNoteInstructor } from '../Instructors/service'
import { postNoteStaff, deleteNoteStaff } from '../Staff/service'

class Note extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    bac_role: JSON.parse(localStorage.getItem("bac_roles")),
    status: 3,
    search: {
      status: 4
    },
    content: null,
    noteId: null,
    file: null,
    uploadError: null
  };
  componentDidMount() { }
  show = () => {
    this.props.dispatch(change("note", "note", null));
    this.props.dispatch(change("note", "internalnote", null));
    this.props.dispatch(change("note", "file", null));
    this.setState({ open: true, content: 'New Note', uploadError: null });
  }
  close = () => this.setState({ open: false, openConfirm: false, currentData: null, noteId: null });
  edit = (data) => {
    let set_internalnote = ""
    if (data.internal == "true" || data.internal == true) {
      set_internalnote = true
    } else {
      set_internalnote = false
    }
    // console.log('edit', data,set_internalnote)
    this.props.dispatch(change("note", "note", data.note));
    this.props.dispatch(change("note", "internalnote", set_internalnote));
    this.props.dispatch(change("note", "file", data.fileUrl));
    this.setState({
      open: true,
      content: 'Edit Note',
      noteId: data.id,
      file: data.fileUrl,
      uploadError: null
    });

  }
  delete = (data) => {
    this.setState({ openConfirm: true, currentData: data })
  }
  onSubmitNote = (data) => {
    console.log('onSubmitNote', data)
    let { noteId } = this.state
    let set_internalnote = ""
    this.setState({ loading: true, uploadError: null })
    if (data.internalnote == true) {
      set_internalnote = "true"
    } else {
      set_internalnote = "false"
    }

    var formData = new FormData();
    if (noteId) {
      formData.append('note_id', noteId)
    }
    formData.append('note', data.note)
    formData.append('internal', set_internalnote)
    if (data.file) {
      if (typeof data.file === 'object')
          formData.append('file', data.file[0])
      else formData.append('file', data.file)
    }else {
      formData.append('file', null)
    }
    // let dataSave
    // if (noteId) {
    //   dataSave = {
    //     note_id: noteId,
    //     note: data.note,
    //     internal: set_internalnote,
    //     file: data.file
    //   }
    // } else {
    //   dataSave = {
    //     note: data.note,
    //     internal: set_internalnote
    //   }
    // }

    if (this.props.page == 'instructor') {
      postNoteInstructor(this.props.personnelId, formData).then(res => {
        if (res && (res.status === 200 || res.status === 201)) {
          this.props.getNote(this.props.personnelId);
          this.setState({ open: false });
        }else if(res && res.status === 400){
          this.setState({
              uploadError:
                  res.data.errors &&
                  res.data.errors.file &&
                  res.data.errors.file[0]
          });
        }
        this.setState({ loading: false, noteId: null })
      })
    } else if (this.props.page == 'student') {
      postNoteStudent(this.props.personnelId, formData).then(res => {
        if (res && (res.status === 200 || res.status === 201)) {
          this.props.getNote(this.props.personnelId);
          this.setState({ open: false });
        }else if(res && res.status === 400){
          this.setState({
              uploadError:
                  res.data.errors &&
                  res.data.errors.file &&
                  res.data.errors.file[0]
          });
        }
        this.setState({ loading: false, noteId: null })
      })
    } else if (this.props.page == 'staff') {
      postNoteStaff(this.props.personnelId, formData).then(res => {
        if (res && (res.status === 200 || res.status === 201)) {
          this.props.getNote(this.props.personnelId);
          this.setState({ open: false });
        }else if(res && res.status === 400){
          this.setState({
              uploadError:
                  res.data.errors &&
                  res.data.errors.file &&
                  res.data.errors.file[0]
          });
        }
        this.setState({ loading: false, noteId: null })
      })
    }

  }
  onDelete = (type) => {
    if (type === 'file') {
      this.setState({file: null})
      this.props.dispatch(change("note", "file", null));
    }
  }
  onDeleteNote = () => {
    let { currentData } = this.state
    // console.log('onDeleteNote', currentData)
    if (this.props.page == 'instructor') {
      deleteNoteInstructor(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.props.getNote(this.props.personnelId);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false, currentData: null })
      })
    } else if (this.props.page == 'student') {
      deleteNoteStudent(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.props.getNote(this.props.personnelId);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false, currentData: null })
      })
    } else if (this.props.page == 'staff') {
      deleteNoteStaff(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.props.getNote(this.props.personnelId);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false, currentData: null })
      })
    }
  }

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(item => item.group === group);
    let allowed = permission.find(item => {
      if (userpermissionGroup && userpermissionGroup.permission && userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  render() {
    const state = this.state;
    const props = this.props
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div>
        {this.checkPermissionMenu("Personnel", ["manage-instructor", "manage-student", "manage-staff"])
          && (
          <Form>
            <Form.Field>
              <MainButton size="small" onClick={this.show} text="New Note" />
            </Form.Field>
          </Form>
        )}

        {props.listNote &&
          props.listNote.length > 0 ?
          props.listNote.map((n, index) =>
            <Message floating icon color='grey' key={index} >
              {/* <Message.Header>Was this what you wanted?</Message.Header> */}

              <Icon name='user' size='tiny' />
              <Message.Content>
                <Message.Header>Note #{index + 1}</Message.Header>
                <Message.Content style={{ padding: 10 }}>
                  {n.note}
                </Message.Content>
                {n.fileUrl && (
                  <Message.Content>
                    <a href={n.fileUrl+'?time='+ new Date()} target="_blank">Download File</a>
                  </Message.Content>
                )}
                {/*check permission*/}
                {this.checkPermissionMenu("Personnel", ["manage-instructor", "manage-student", "manage-staff"])
                  && (
                  <Message.Content>
                    <Icon link name="edit" onClick={() => this.edit(n)} />
                    <Icon link color="red" name="trash alternate" onClick={() => this.delete(n)} /> &nbsp;
                  </Message.Content>
                  )
                }
              </Message.Content>

            </Message>
          )
          : <p style={{ color: "rgb(115, 115, 115)" }}>No description available</p>
        }
        <ModalForm
          icon="sticky note outline"
          content={state.content}
          open={state.open}
          close={this.close}
        >
          <Form onSubmit={handleSubmit(this.onSubmitNote)}>
            <Field
              name="note"
              type="text"
              // required={true}
              component={renderTextAreaField}
              label="Note"
            />
            <Field
              name="internalnote"
              label="Internal Notes"
              component={renderRadioField}
            />
          {/*{state.file &&
              <Message size='small' style={{ margin: '3px 0px 3px 0px' }}>
                <Message.Content style={{ textAlign: 'left' }}>
                  <p>
                     <Icon name='image' />
                     <a href={state.file+'?time='+ new Date()} target='_blank' rel="noopener noreferrer">Attached file</a>
                     <Icon name='x' color='red' link
                      onClick={() => this.onDelete('file')} />
                  </p>
                </Message.Content>
              </Message>
            }*/}
            <Field
              name="file"
              type="file"
              removeabel
              component={renderFileField}
              label="Attached file" />
            {state.uploadError &&
              <Message negative size='small' style={{ margin: '3px 0px 3px 0px' }}>
                <Message.Content style={{ textAlign: 'left' }}>
                  <p>{state.uploadError}</p>
                </Message.Content>
              </Message>
            }
            <br />
            {/* <Button compact type="submit" content='Save' color='green' disabled={pristine || submitting} /> */}
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Note"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.onDeleteNote}
        />
      </div>
    );
  }
}

// export default withRouter(Note)

export default (Note = reduxForm({
  // a unique name for the form
  form: "note",
  // validate,
  enableReinitialize: true
})(Note));
