import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import { Header, Segment, Breadcrumb, Loader, Table,Icon,Popup,Button } from "semantic-ui-react";
import { getCourseModule ,getSubjects,updateCourseModule,updateModuleInCourse,getSubjectByModule,
getProgressChecklist,updateProgressChecklist,postCourseModule,
getSubjectFlightGroupByModule,updateSubjectFlightGroupByModule,deleteCourseModule} from "./service";
import MainButton from '../../components/mainbutton'
import HeaderWithMenu from '../../components/headerwithmenu'
import Wrap from "../../components/wrap";
import ModalForm from "../../components/modal";
import Formcourse from "./courseEditForm";
import FormcourseModule from "./courseModuleForm";
import FormcourseModuleGroup from "./courseModuleGroupForm";
import FormTimeline from "./courseTimelineForm";
import FormcourseModuleProgressChecklist from "./courseModuleProgressChecklist";
import ModuleDetail from "./moduleDetail";
import Confirmmodal from "../../components/confirmmodal";

const defaultValue = {
    "flightSubjects": [],
    "groundSubjects": [],
    "flightAddSubjects":[]
}

class CourseMaster extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.getData()
    this.getOptionGroundSubjects();
    this.getOptionFlightSubjects();
  }

  getData = () =>{
    getCourseModule('?desc=master').then(res => {
      if (res && res.status === 200) {
        console.log(res.data);
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  }

  getOptionGroundSubjects = () => {
    let option_subject = [];
    getSubjects("?type=G").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_subject.push({
            key: item.id,
            id: item.id,
            title: `${item.name}`,
            text:
              item.hours && item.reference
                ? `${item.name} (${item.reference}) (${item.hours})`
                : item.hours
                ? `${item.name}  (${item.hours})`
                : item.name,
            value: item.id
          });
        });
        this.setState({ option_subject });
      }
    });
  };

  getOptionFlightSubjects = () => {
    let option_flightSubject = [],
      option_flightAddSubject = [];
    getSubjects("?type=F").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_flightSubject.push({
            key: item.id,
            id: item.id,
            title: `${item.code}`,
            text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`,
            value: item.id
          });
        });
        this.setState({ option_flightSubject });
      }
    });
    getSubjects("?type=FA").then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_flightAddSubject.push({
            key: item.id,
            id: item.id,
            title: `${item.code}`,
            text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`,
            value: item.id
          });
        });
        this.setState({ option_flightAddSubject });
      }
    });
  };

  onClickCancel = () => {
    this.setState({
      openmodule: false,
      openChecklist:false,
      openGroup:false,
    });
  };

  onClickNew = () => {
    this.setState({ openmodule: true, currentData: defaultValue, isNew: true, content: 'New Module' })
  }

  onClickEditModule = value => {
    this.setState({fetching:true,isNew: false,  openmodule: true})
    getSubjectByModule(value.id).then(res=>{
      let groundSubjects = [],
        flightSubjects = [],
        flightAddSubjects = [];
      if(res && res.status ===200){
        res.data.groundSubjects.map(item => groundSubjects.push(item.id));
        res.data.groundSubjects = groundSubjects;
        res.data.flightSubjects.map(item => flightSubjects.push(item.id));
        res.data.flightSubjects = flightSubjects;
        res.data.flightAddSubjects.map(item => flightAddSubjects.push(item.id));
        res.data.flightAddSubjects = flightAddSubjects;
        this.setState({
          currentData: res.data,
          content: `Module ${res.data.name}`
        });
      }
      this.setState({fetching:false})
    })
  };

  onClickManageProgressChecklist = value => {
    this.setState({fetching:true,  openChecklist: true})
    let option_subjectByModule = []
    getSubjectByModule(value.id).then(res=>{
      if(res && res.status ===200){
        res.data.flightSubjects.map(item=>{
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            })
        })
        res.data.groundSubjects.map((item) => {
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.name,
                text: item.name,
                value: item.id
            })
        })
        this.setState({ option_subjectByModule})
        getProgressChecklist(value.id).then(res=>{
            if(res && res.status === 200){
                let currentChecklist = []
                res.data && res.data.checklist.map(item=>{
                    currentChecklist.push(item)
                })
                this.setState({
                    currentData: {
                        id: res.data && res.data.id,
                        name: res.data && res.data.name,
                        checklist: currentChecklist.length === 0 ?[{"name":null,"subject":[]}] : currentChecklist
                    }
                })
            }
        })
      }
      this.setState({fetching:false})
    })
  }

  onClickManageGroup = value => {
    this.setState({fetching:true,  openGroup: true})
      getSubjectFlightGroupByModule(value.id).then(res => {
          if (res && res.status === 200) {
              let subject =[]
              res.data.map(elem=>{
                  let selected=[]
                  elem.subject.map(item=>{
                      selected.push(item.id)
                  })
                  subject.push({
                      id: elem.id,
                      name: elem.name ,
                      subject:selected
                  })
              })
              this.setState({
                  currentData: {
                      id: value.id,
                      name: value.name,
                      flightSubjects: subject
                  },rawDataGroup:res.data
              })
          }
      })
      getSubjectByModule(value.id).then(res=>{
        let option_flightSubject = []
        if(res && res.status === 200){
            res.data.flightSubjects.map(item => option_flightSubject.push({
                key: item.id, id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            }));
            res.data.flightAddSubjects.map(item => option_flightSubject.push({
                key: item.id, id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            }));
        }
        this.setState({ fetching: false, option_flightSubjectByModule: option_flightSubject})
      })
  }

  saveModule = value => {
    this.setState({ loading: true });
    value.desc ='master';
    postCourseModule(value).then(
      res => {
        if (res && res.status === 201) {
          this.getData()
          this.onClickCancel();
        }
      }
    );
  }

  updateModule = value => {
    this.setState({ loading: true });
    updateCourseModule(value).then(
      res => {
        if (res && res.status === 200) {
          this.getData()
          this.onClickCancel();
        }
        this.setState({ loading: false });
      }
    );
  };

  updateProgressChecklist = value =>{
    this.setState({ loading: true });
    updateProgressChecklist(value).then(res=>{
        if(res && res.status === 200)
          this.onClickCancel();
          this.setState({ loading: false });
    })
  }

  updateFlightGroup = value => {
    this.setState({loading:true})
    updateSubjectFlightGroupByModule(value).then(res=>{
      if(res && res.status === 200)
        this.onClickCancel();
        this.setState({ loading: false });
    })
  }

  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };

  deleteData = () => {
      let { type, currentData } = this.state;
      this.setState({ loading: true });
      if (currentData && currentData.id)
        deleteCourseModule(currentData.id).then(res => {
          if (res && res.status === 200) {
            this.getData()
            this.onClickCancel();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
  };
  render() {
    const state = this.state;
    return (
      <Wrap>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Module Master Data"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      <ModalForm
        open={state.openmodule}
        icon="book"
        size="small"
        content={state.content}
        close={this.onClickCancel}
      >
        <FormcourseModule
          loading={state.loading}
          fetching={state.fetching}
          masterModule={true}
          isNew={state.isNew}
          initialValues={state.currentData ? state.currentData : null}
          option_subject={state.option_subject}
          option_flightSubject={state.option_flightSubject}
          option_flightAddSubject={state.option_flightAddSubject}
          onSubmit={state.isNew ? this.saveModule : this.updateModule}
          onCancel={this.onClickCancel}
        />
      </ModalForm>
      <ModalForm
          open={state.openChecklist}
          icon='tasks'
          size='small'
          content='Progress Checklist'
          close={this.onClickCancel}  >
          <FormcourseModuleProgressChecklist
              loading={state.loading}
              fetching={state.fetching}
              initialValues={state.currentData ? state.currentData : { checklist:[{}]}}
              option_subject={state.option_subjectByModule}
              onSubmit={this.updateProgressChecklist}
              onCancel={this.onClickCancel}
          />
      </ModalForm>
      <ModalForm
          open={state.openGroup}
          icon='object group outline'
          size='small'
          content={`Flight Group`}
          close={this.onClickCancel}  >
          <FormcourseModuleGroup
              loading={state.loading}
              fetching={state.fetching}
              initialValues={state.currentData ? state.currentData : null}
              option_flightSubject={state.option_flightSubjectByModule}
              onSubmit={this.updateFlightGroup}
              onCancel={this.onClickCancel}
          />
      </ModalForm>
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name='Module Master Data' >
            <MainButton
                size='small'
                onClick={this.onClickNew}
                text='Add Module' />
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Course")}
            style={{ color: "grey" }}
          >
            Academic
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Module Master Data </Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        {state.loading ? (
          <Loader active inline="centered" content="Loading" />
        ) : (
          <Table compact celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="header-table" >
                  Module
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Ground Subjects
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Ground (Hours)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Flight Subjects
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Flight (Hours)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Flight Add. Subjects
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >

                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
            {state.data.map(item=>
              <Table.Row>
                <Table.Cell style={{ paddingLeft: "40px" }}>
                {item.name}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.groundSubjects}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.groundHours}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.flightSubjects}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.flightHours}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {item.flightAddSubjects}
                </Table.Cell>
                <Table.Cell textAlign="center">
                <Popup
                   trigger={<Icon link name='edit' onClick={() => this.onClickEditModule(item)} />}
                   content='Edit Module'
                   position='bottom right'
               /> &nbsp;
               <Popup
                  trigger={<Icon link name='object group outline' onClick={() => this.onClickManageGroup(item)} />}
                  content='Flight Group'
                  position='bottom right'
              />  &nbsp;
              <Popup
                 trigger={<Icon   link
                   color="red"
                   name="trash alternate" onClick={() => this.onClickDelete(item)} />}
                 content='Delete Module'
                 position='bottom right'
             />
                </Table.Cell>
              </Table.Row>
            )}
            </Table.Body>
          </Table>
        )}
      </Segment>
      </Wrap>

    );
  }
}

export default withRouter(CourseMaster);
