import React, { Component } from "react";
import BigCalendar from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Popup, Segment, Header, Icon, List } from "semantic-ui-react";

const localizer = BigCalendar.momentLocalizer(moment);
const Event = ({ event }) => {
  return <span>{event.title}</span>;
};

export const CustomToolbar = () => {
  return <div />;
};

class Calendar extends Component {
  EventWrapper = (props) => {
    let stypeCustom = props.children.props.style;
    stypeCustom["backgroundColor"] =
      props.event.status === 0
        ? "#ff9800"
        : props.event.status === 1
        ? "#96a7af"
        : props.event.status === 2
        ? "#27a8a2"
        : props.event.status === 3
        ? "#ce2921"
        : "blue";
    stypeCustom["border"] = "1px solid #f5f5f5";
    stypeCustom["borderRadius"] = "14px";
    stypeCustom["color"] = props.event.textColor;
    return (
      <Popup
        trigger={
          <div
            className={props.children.props.className}
            style={stypeCustom}
            onClick={() => this.props.eventClicked(props.event.id)}
          >
            {props.children.props.children}
          </div>
        }
      >
        <Popup.Content>
          <div style={{ width: "300px" }}>
            <Segment basic clearing style={{ padding: "0px", margin: "0px" }}>
              <Header as="h4" textAlign="left" floated="left">
                <Icon
                  name="circle"
                  style={{
                    color:
                      props.event.status === 0
                        ? "#ff9800"
                        : props.event.status === 1
                        ? "#96a7af"
                        : props.event.status === 2
                        ? "#27a8a2"
                        : props.event.status === 3
                        ? "#ce2921"
                        : "blue",
                  }}
                />
                <Header.Content>
                  {props.event.title || ""}
                  <Header.Subheader>
                    {props.event.reservationTypeName || "New reservation"}
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Segment>
            <br />
            <Segment basic clearing style={{ padding: "0px", margin: "0px" }}>
              <List>
                <List.Item>
                  <List.Icon name="clock" className="bold" />
                  <List.Content>
                    <b className="bold">Date time : </b>
                    {`${moment(props.event.start).format(
                      "ddd, MMM D HH:mm"
                    )} - `}
                    {moment(props.event.start).format("ddd, MMM D") ===
                    moment(props.event.end).format("ddd, MMM D")
                      ? moment(props.event.end).format("HH:mm")
                      : moment(props.event.end).format("ddd, MMM D HH:mm")}
                  </List.Content>
                </List.Item>
                {props.event.aircraftName && (
                  <List.Item>
                    <List.Icon name="plane" className="bold" />
                    <List.Content>
                      <b className="bold">Aircraft &nbsp; &nbsp;: </b>
                      {props.event.aircraftName}
                    </List.Content>
                  </List.Item>
                )}
                {props.event.roomName && (
                  <List.Item>
                    <List.Icon name="building" className="bold" />
                    <List.Content>
                      <b className="bold">Room &nbsp;: </b>
                      {props.event.roomName}
                    </List.Content>
                  </List.Item>
                )}
                <List.Item>
                  <List.Icon name="user" className="bold" />
                  <List.Content>
                    <b className="bold">Instructor : </b>
                    {props.event.instructorName || "-"}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="user circle" className="bold" />
                  <List.Content>
                    <b className="bold">Student &nbsp; &nbsp;: </b>
                    {props.event.studentName || "-"}
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
          </div>
        </Popup.Content>
      </Popup>
    );
  };

  EventAgenda = (props) => {
    return (
      <span>
        <p
          style={{
            color:
              props.event.status === 0
                ? "#ff9800"
                : props.event.status === 1
                ? "#96a7af"
                : props.event.status === 2
                ? "#27a8a2"
                : props.event.status === 3
                ? "#ce2921"
                : "blue",
            margin: "0px",
            cursor: "pointer",
          }}
          className="bold"
          onClick={() => this.props.eventClicked(props.event.id)}
        >
          {props.title}
        </p>
        <List size="small" horizontal style={{ margin: "0px" }}>
          {props.event.aircraftName && (
            <List.Item>
              <List.Content>
                <b className="bold">Aircraft &nbsp; &nbsp;: </b>
                {props.event.aircraftName}
              </List.Content>
            </List.Item>
          )}
          {props.event.roomName && (
            <List.Item>
              <List.Content>
                <b className="bold">Room &nbsp; &nbsp;: </b>
                {props.event.roomName}
              </List.Content>
            </List.Item>
          )}
          <List.Item>
            {/* <List.Icon name="user" className="bold" /> */}
            <List.Content>
              <b className="bold">Instructor : </b>
              {props.event.instructorName || "-"}
            </List.Content>
          </List.Item>
          <List.Item>
            {/* <List.Icon name="user circle" className="bold" /> */}
            <List.Content>
              <b className="bold">Student &nbsp; &nbsp;: </b>
              {props.event.studentName || "-"}
            </List.Content>
          </List.Item>
        </List>
      </span>
    );
  };

  handleSelect = (props) => {
    this.props.slotClicked(props.resourceId, props.start, props.end);
  };
  render() {
    const props = this.props;
    return (
      <div>
        <BigCalendar
          events={props.events}
          style={{ minHeight: "80vh" }}
          // step={60}
          step={5}
          timeslots={12}
          showMultiDayTimes
          min={moment(props.day).hours(props.start).minutes(0).seconds(0)._d}
          max={moment(props.day).hours(props.stop).minutes(0).seconds(0)._d}
          defaultDate={moment(props.day)._d}
          resources={props.resources}
          resourceIdAccessor="id"
          resourceTitleAccessor="name"
          views={["day", "week", "month", "agenda"]}
          view={props.views}
          localizer={localizer}
          formats={{
            timeGutterFormat: "HH:mm",
          }}
          popup
          // onShowMore={(e, date) => props.showmore(date)}
          selectable="true"
          // onSelectEvent={(e, event) => props.onSelectEvent(event)}
          onSelectSlot={this.handleSelect}
          components={{
            event: Event,
            toolbar: CustomToolbar,
            eventWrapper: this.EventWrapper,
            agenda: {
              event: this.EventAgenda,
            },
          }}
        />
      </div>
    );
  }
}
export default Calendar;
