import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Button, Image, Icon, Form } from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import TableList from "../../components/table";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";
import { getEbook, postEbook, updateEbook, deleteEbook } from "./service";

class Roles extends Component {
  state = {
    loading: true,
    data: [],
    column: [
      {
        Header: "Image",
        accessor: "image",
        sortable: false,
        width: 100,
        style: { textAlign: "center" },
        Cell: cellInfo => (
          <Image
            avatar
            // size="mini"
            verticalAlign="middle"
            src={cellInfo.original.image}
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/img/no-picture-available.jpg";
            }}
          />
        )
      },
      {
        Header: "Description",
        accessor: "description",
        sortable: false,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: cellInfo => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.description}
            </p>
          </span>
        )
      },
      {
        Header: "URL",
        accessor: "url",
        sortable: false
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: cellInfo => (
          <span>
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        )
      }
    ]
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getEbook().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data
        });
      }
      this.setState({ loading: false });
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onClickEdit = value => {
    this.setState({
      open: true,
      currentData: value,
      description: value.description,
      url: value.url
    });
  };
  onClickNew = () => {
    this.setState({
      open: true,
      description: null,
      url: null
    });
  };
  onClickCancel = () => {
    this.setState({
      open: false,
      description: null,
      url: null,
      currentData: null
    });
  };
  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };
  onDelete = () => {
      let { currentData } = this.state
      if (currentData) {
        currentData.image = null
        this.setState({currentData})
      }
      this.setState({fileURL: null})

  }
  onChangeFile = e => {
    this.setState({
      [e.target.name]: e.target.files[0],
      fileURL: URL.createObjectURL(e.target.files[0])
    });
  };
  deleteData = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteEbook(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.onClickCancel();
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
  };
  saveData = () => {
    this.setState({ loading: true });
    let { currentData, description, url, newimage, fileURL } = this.state;
    if (currentData && currentData.id) {
      var formData = new FormData();
      formData.append("id", currentData.id);
      formData.append("description", description);
      formData.append("url", url);
      if (newimage) formData.append("image", newimage);
      else formData.append("image", currentData.image);

      updateEbook(currentData.id, formData).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.onClickCancel();
        }
        this.setState({ loading: false });
      });
    } else {
      var formData = new FormData();
      formData.append("description", description);
      formData.append("url", url);
      if (fileURL) {
        formData.append("image", newimage);
      }else {
        formData.append("image", null);
      }

      postEbook(formData).then(res => {
        if (res && res.status === 201) {
          this.getData();
          this.onClickCancel();
        }
        this.setState({ loading: false });
      });
    }
  };
  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Ebooks">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Ebooks"
            />
          </HeaderWithMenu>
          <TableList
            loading={state.loading}
            data={state.data}
            columns={state.column}
          />
        </Segment>

        <ModalForm
          open={state.open}
          icon="file alternate"
          content="Ebook"
          close={this.onClickCancel}
        >
          <Form style={{ textAlign: "center" }}>
            {state.fileURL ? (
              <Icon.Group size='big'>
                  <Image
                    size="small"
                    verticalAlign="middle"
                    src={state.fileURL}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = "/img/no-picture-available.jpg";
                    }}
                  />
                  <Icon name='x' color='red' link corner='top right'
                   onClick={() => this.onDelete()} />
               </Icon.Group>
            ) : (
              state.currentData &&
              state.currentData.image && (
                <Icon.Group size='big'>
                  <Image
                    size="small"
                    verticalAlign="middle"
                    src={state.currentData && state.currentData.image}
                    onError={e => {
                      e.target.onerror = null;
                      e.target.src = "/img/no-picture-available.jpg";
                    }}
                  />
                  <Icon name='x' color='red' link corner='top right'
                   onClick={() => this.onDelete()} />
                </Icon.Group>
              )
            )}
            <br />
            <Form.Input
              label="Image"
              placeholder="Image"
              type="file"
              name="newimage"
              onChange={this.onChangeFile}
            />
            <Form.Input
              label="Description"
              placeholder="Description"
              type="text"
              name="description"
              value={state.description}
              onChange={this.onChange}
            />
            <Form.Input
              label="URL"
              placeholder="URL"
              type="text"
              name="url"
              value={state.url}
              onChange={this.onChange}
            />
            <br />

            <Button
              floated="left"
              // size="small"
              loading={state.loading}
              type="submit"
              content="Save"
              color="green"
              compact
              onClick={this.saveData}
            />
            <Button
              floated="right"
              // size="small"
              type="button"
              content="Cancel"
              color="green"
              basic
              compact
              onClick={this.onClickCancel}
            />
          </Form>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Ebook"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(Roles);
