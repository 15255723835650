import React, { Component } from "react";
import { Form, Button, Icon } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import validate from "./aircraftValidate";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderTextAreaField from "../../components/renderTextAreaField";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
import { getMake, getModel, getAirClass, getSimClass } from "./service";
import * as config from "../../config";

class FromAircraft extends Component {
  state = {
    loadData: true,
    make_option: [],
    model_option: [],
    category_option: [
      { key: 1, text: "Airplane", value: "airplane" },
      { key: 4, text: "Flight Simulator", value: "sim" },
    ],
    airplaneClass_option: [],
    simClass_option: [],
  };

  componentDidMount() {
    let make_option = [],
      model_option = [],
      airplaneClass_option = [],
      simClass_option = [];

    getMake().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) =>
          make_option.push({ key: item.id, text: item.name, value: item.id })
        );
        this.setState({ make_option });
      }
    });
    getModel().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) =>
          model_option.push({ key: item.id, text: item.name, value: item.id })
        );
        this.setState({ model_option });
      }
    });
    getAirClass().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) =>
          airplaneClass_option.push({
            key: item.id,
            text: item.name,
            value: item,
          })
        );
        if (
          this.props.initialValues &&
          this.props.initialValues.aircraftClassId
        ) {
          let airClass = null;
          airplaneClass_option.find((element) => {
            if (element.value.id === this.props.initialValues.aircraftClassId)
              airClass = element.value;
          });
          this.props.dispatch(change("aircraftprofile", "airClass", airClass));
        }
        this.setState({ airplaneClass_option, loadData: false });
      }
    });

    getSimClass().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) =>
          simClass_option.push({
            key: item.id,
            text: item.name,
            value: item.id,
          })
        );
        this.setState({ simClass_option, loadData: false });
      }
    });
  }

  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      category,
      airClass,
      loading,
    } = this.props;

    if (state.loadData)
      return (
        <div style={{ textAlign: "center" }}>
          <Icon loading name="circle notch" />
          <br />
          Loading..
        </div>
      );
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Form.Group unstackable>
          <Field
            name="tailNumber"
            type="text"
            required={true}
            width="12"
            component={renderField}
            label="Registration / Tail"
          />
          <Field
            name="year"
            type="text"
            width="4"
            component={renderField}
            label="Year"
          />
        </Form.Group>
        <Form.Group widths="equal" unstackable>
          <Field
            name="makeId"
            required={true}
            component={renderSelectField}
            width="8"
            search={true}
            placeholder="Please Select"
            clearable={true}
            label="Make"
            options={state.make_option}
          />
          <Field
            name="modelId"
            required={true}
            component={renderSelectField}
            search={true}
            placeholder="Please Select"
            width="8"
            clearable={true}
            label="Model"
            options={state.model_option}
            onChange={this.handleChangeModel}
          />
        </Form.Group>
        <Form.Group unstackable>
          <Field
            name="category"
            required={true}
            component={renderSelectField}
            width="8"
            placeholder="Please Select"
            label="Category"
            options={state.category_option}
            clearable={true}
          />
          {category === "airplane" ? (
            <Field
              name="airClass"
              required={true}
              component={renderSelectField}
              width="8"
              placeholder="Please Select"
              label="Airplane Class"
              options={state.airplaneClass_option}
              clearable={true}
            />
          ) : category === "sim" ? (
            <Field
              name="simClass"
              required={true}
              component={renderSelectField}
              width="8"
              placeholder="Please Select"
              label="Flight Simulator Class"
              options={state.simClass_option}
              clearable={true}
            />
          ) : null}
        </Form.Group>

        {category && category === "sim" ? (
          <Field
            name="numberOfEngines"
            type="text"
            width="4"
            required={true}
            component={renderField}
            label="# of Engines"
          />
        ) : (
          category === "airplane" &&
          airClass &&
          airClass.multi &&
          airClass.multi !== "false" && (
            <Field
              name="numberOfEngines"
              type="text"
              width="4"
              required={true}
              component={renderField}
              label="# of Engines"
            />
          )
        )}

        {category === "airplane" ? (
          <Wrap>
            <Field
              name="enginesHavePropellers"
              width="11"
              label="Engines have Propellers"
              component={renderRadioField}
            />
            <Field
              name="enginesAreTurbines"
              width="11"
              label="Engines are Turbines"
              component={renderRadioField}
            />
          </Wrap>
        ) : null}
        <Form.Group unstackable>
          <Form.Field width="4">
            <div
              style={{
                color: config.colorTheme,
                marginBottom: "4px",
                fontWeight: 700,
                textAlign: "right",
              }}
              onClick={() => this.setState({ description: !state.description })}
            >
              Description{" "}
              <Icon
                size="small"
                name={
                  state.description ? "angle double down" : "angle double left"
                }
              />{" "}
            </div>
          </Form.Field>
          {state.description ? (
            <Field
              name="description"
              width="12"
              required={true}
              component={renderTextAreaField}
            />
          ) : null}
        </Form.Group>
        <Form.Group unstackable>
          <Form.Field width="4">
            <div
              style={{
                color: config.colorTheme,
                marginBottom: "4px",
                fontWeight: 700,
                textAlign: "right",
              }}
              onClick={() => this.setState({ avionics: !state.avionics })}
            >
              Avionics{" "}
              <Icon
                size="small"
                name={
                  state.avionics ? "angle double down" : "angle double left"
                }
              />{" "}
            </div>
          </Form.Field>
          {state.avionics ? (
            <Field
              name="avionics"
              width="12"
              required={true}
              component={renderTextAreaField}
            />
          ) : null}
        </Form.Group>

        <Form.Group unstackable>
          <Form.Field width="4">
            <div
              style={{
                color: config.colorTheme,
                marginBottom: "4px",
                fontWeight: 700,
                textAlign: "right",
              }}
              onClick={() => this.setState({ accessories: !state.accessories })}
            >
              Accessories{" "}
              <Icon
                size="small"
                name={
                  state.accessories ? "angle double down" : "angle double left"
                }
              />{" "}
            </div>
          </Form.Field>
          {state.accessories ? (
            <Field
              name="accessories"
              width="12"
              required={true}
              component={renderTextAreaField}
            />
          ) : null}
        </Form.Group>

        <Form.Group unstackable>
          <Form.Field width="4">
            <div
              style={{
                color: config.colorTheme,
                marginBottom: "14px",
                fontWeight: 700,
                textAlign: "right",
              }}
              onClick={() => this.setState({ otherspec: !state.otherspec })}
            >
              Other / Specs{" "}
              <Icon
                size="small"
                name={
                  state.otherspec ? "angle double down" : "angle double left"
                }
              />{" "}
            </div>
          </Form.Field>
          <Form.Field width="12">
            {state.otherspec ? (
              <Wrap>
                <Field
                  name="numberOfSeats"
                  type="number"
                  component={renderField}
                  label="Number of Seats"
                />
                <Field
                  name="fuelCapacity"
                  type="text"
                  component={renderField}
                  label="Fuel Capacity"
                />
                <Field
                  name="usefulLoad"
                  type="text"
                  component={renderField}
                  label="Useful Load"
                />
                <Field
                  name="emptyWeight"
                  type="text"
                  component={renderField}
                  label="Empty Weight"
                />
                <Field
                  name="maxGrossWeight"
                  type="text"
                  component={renderField}
                  label="Max Gross Weight"
                />
                <Field
                  name="arm"
                  type="text"
                  component={renderField}
                  label="ARM"
                />
                <Field
                  name="moment"
                  type="text"
                  component={renderField}
                  label="Moment"
                />
                <Field
                  name="hangarTieDown"
                  type="text"
                  component={renderField}
                  label="Hangar / Tie Down"
                />
                <Field
                  name="colorScheme"
                  type="text"
                  component={renderField}
                  label="Color Scheme"
                />
                <Field
                  name="serialNumber"
                  type="text"
                  component={renderField}
                  label="Serial Number"
                />
              </Wrap>
            ) : null}
          </Form.Field>
        </Form.Group>

        <Field
          name="trackOilFuel"
          width="11"
          label="Track Oil and Fuel"
          component={renderRadioField}
        />
        {/* <Field
          name="displayMismatches"
          width="11"
          label="Identify Meter Mismatch"
          component={renderRadioField}
        /> */}
        <Field
          name="remark"
          type="text"
          width="16"
          component={renderField}
          label="Remark"
        />
        <Field
          name="isActive"
          width="11"
          label="Active"
          component={renderRadioField}
        />
        <br />
        <Button
          floated="left"
          type="submit"
          content="Save"
          color="green"
          compact
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          type="button"
          content="Cancel"
          color="green"
          basic
          compact
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

FromAircraft = reduxForm({
  // a unique name for the form
  form: "aircraftprofile",
  validate,
  enableReinitialize: true,
})(FromAircraft);

const selector = formValueSelector("aircraftprofile");
FromAircraft = connect((state) => {
  const { category, airClass } = selector(state, "category", "airClass");
  return {
    category,
    airClass,
  };
})(FromAircraft);

export default FromAircraft;
