import React, { Component } from "react";
import {
  Form,
  Button,
  Icon,
  List,
  Grid,
  Accordion,
  Header,
} from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import validate from "./validate";
import renderField from "../../components/renderField";
import renderRadioField from "../../components/renderRadioField";
import renderSelectField from "../../components/renderSelectField";
import renderDateTimeField from "../../components/renderDateTimeField";
import { connect } from "react-redux";
import FlightForm from "./reservationFormFlight";
import GroundForm from "./reservationFormGround";
import MeettingForm from "./reservationFormMeeting";
import moment from "moment";
import * as config from "../../config";

import {
  getCourseBySearch,
  getStudentBySearch,
  getAirport,
  getInstructorsListBySearch,
  getInstructorsGroupBySubject,
  getReservationTypesBySearch,
  getTypeGroundInstructor,
  getRoomListBySearch,
  getAircraftListBySearch,
  getStudentGroupByGroundSubject,
  getSubjectByCourseId,
  // new
  getFlightSubjectByStudent,
  getFlightAddSubjectByStudent,
  getInstuctorBySubject,
  getStudentClass,
  checkFlightRulesSP,
  checkFlightRulesIP,
  getFlightMonitorType,
  getTestType,
} from "./service";
import Wrap from "../../components/wrap";

class FromReservation extends Component {
  state = {
    loadingData: true,
    resourceTypeId: 0,
    activeType: [],
    instructorAll: [],
    instructorByGSubject: [],
    instructorByFSubject: [],
    studentAll: [],
    studentByGroup: [],
    options_typeGroundInstructor: [],
    options_student: [],
    options_studentall: [],
    options_group: [],
    options_additionalGroup: [],
    options_additionalStudent: [],
    options_instructor: [],
    options_instructorall: [],
    options_aircraft: [],
    options_room: [],
    options_location: [],
    options_course: [],
    options_monitor: [],
    options_testtype: [],
    multiTime: [],
    currentStart: null,
    currentEnd: null,
    canSave: true,
    canSaveIP: true,
  };

  componentDidMount() {
    if (!this.props.isNew && this.props.initialValues) {
      this.setState({
        currentStart: this.props.initialValues.start,
        currentEnd: this.props.initialValues.end,
      });
    }

    // get reservation type
    let qString = "";
    if (this.props.initialValues && this.props.initialValues.resourceTypeId) {
      let resourceTypeId = this.props.initialValues.resourceTypeId;
      if (resourceTypeId === "F") {
        // aircraft
        qString = "?displayType[]=0&displayType[]=1&displayType[]=3";
        // &name_like=Other";
      } else if (resourceTypeId === "R") {
        // room
        qString = "?displayType[]=2&displayType[]=3";
        // &name_like=Other";
      }
    }
    getReservationTypesBySearch(qString).then((res) => {
      if (res && res.status === 200) {
        let option = [];
        res.data.map((item) =>
          option.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item,
          })
        );
        this.setState({
          activeType: option,
          loadingData: false,
        });
      }
    });

    this.getStudent();
    this.getInstructors();
    if (
      this.props &&
      this.props.initialValues &&
      this.props.initialValues.courseType
    )
      this.getCourse(this.props.initialValues.courseType);
  }

  getCourse = (courseType) => {
    let qString = "?active=true";
    if (courseType) qString = qString + "&courseType=" + courseType;

    getCourseBySearch(qString).then((res) => {
      let options_course = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          options_course.push({
            key: item.id,
            text: `${item.name} (${item.desc}) ${moment(
              item.start,
              "DD-MM-YYYY"
            ).format("DD/MM/YYYY")} - ${moment(item.end, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            )}`,
            value: item.id,
          });
        });
      }
      this.setState({ options_course });
    });
  };

  getGroundSubjects = (id) => {
    getSubjectByCourseId(id).then((res) => {
      if (res && res.status === 200) {
        let groundSubjects = [];
        res.data.groundSubjects.map((subject) => {
          groundSubjects.push({
            key: subject.id,
            text: `${subject.code ? subject.code + " : " : ""} ${subject.name
              } ${subject.hours && "(" + subject.hours + ")"}`,
            value: {
              id: subject.id,
              hours: subject.hours,
              material: subject.material,
            },
          });
        });
        this.setState({ groundSubjects: groundSubjects });
      }
    });
  };
  getInstructors = () => {
    getInstructorsListBySearch("?status=Active").then((res) => {
      if (res && res.status === 200) {
        let optionAll = [];
        res.data.map((item) => {
          // if (
          //   this.props.initialValues.instructorSVId &&
          //   item.id === this.props.initialValues.instructorSVId
          // ) {
          //   this.props.dispatch(
          //     change("newreservation", "instructorSVId", item.id)
          //   );
          // }
          optionAll.push({
            key: item.id,
            id: item.id,
            text: item.employmenttype
              ? `${item.firstName} ${item.lastName} (${item.employmenttype})`
              : `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
        });
        this.setState({
          options_instructorall: optionAll,
          options_instructorSV: optionAll,
          instructorAll: res.data,
        });
      }
    });
  };
  getInstructorsGroup = () => {
    getInstructorsGroupBySubject().then((res) => {
      if (res && res.status === 200) {
        this.setState({
          instructorByGSubject: res.data.groundSubjects,
          instructorByFSubject: res.data.flightSubject,
        });
      }
    });
  };
  getAircraft = () => {
    getAircraftListBySearch("?isActive=true").then((res) => {
      let option = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            id: item.id,
            title: `${item.tailNumber} ${item.schedulingGroupName}`,
            text: `${item.tailNumber} ${item.schedulingGroupName}`,
            value: item.id,
          });
        });
      }
      const result = res.data.find((member) => {
        return (
          parseInt(member.id) === parseInt(this.props.initialValues.aircraft)
        );
      });
      if (result && result.category === "sim")
        this.setState({ simCategory: true });
      else this.setState({ simCategory: false });
      this.setState({ options_aircraft: option, rawDataAircraft: res.data });
    });
  };
  getRoom = () => {
    getRoomListBySearch("?isActive=true").then((res) => {
      let option = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            id: item.id,
            title: `${item.locationName} - ${item.name}`,
            text: `${item.locationName} - ${item.name}`,
            value: item.id,
          });

          if (
            this.props.initialValues.roomId &&
            item.id === this.props.initialValues.roomId
          ) {
            this.props.dispatch(change("newreservation", "room", item.id));
          }
        });

        this.setState({
          options_room: option,
        });
      }
    });
  };

  getStudent = () => {
    getStudentClass().then((res) => {
      let option = [
        {
          key: "All",
          text: "All Class",
          value: "All",
        },
      ];
      let options_class = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          options_class.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
          option.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        });
      }
      if (this.props.initialValues.studentClass)
        this.props.dispatch(
          change(
            "newreservation",
            "studentClass",
            this.props.initialValues.studentClass
          )
        );
      else this.props.dispatch(change("newreservation", "studentClass", "All")); // default select all
      this.setState({
        options_filterclass: option,
        options_class: options_class,
      });
    });

    getStudentBySearch("?status=Active").then((res) => {
      let student = [];
      if (res && res.status === 200) {
        res.data.map((item) => {
          student.push({
            key: item.id,
            text: `${item.studentId} ${item.firstName} ${item.lastName} ${item.class ? item.class.name : ""
              }`,
            classId: item.class && item.class.id,
            value: item.id,
          });
        });

        if (this.props.initialValues.studentId) {
          let attendees = [];
          this.props.dispatch(
            change(
              "newreservation",
              "studentId",
              this.props.initialValues.studentId
            )
          );
          const result = student.filter((member) => {
            return (
              parseInt(member.value) !==
              parseInt(this.props.initialValues.studentId)
            );
          });
          this.setState({ options_studentobserv: result });

          if (typeof this.props.initialValues.studentId === "object") {
            this.props.initialValues.studentId.map((item) => {
              const result = res.data.find((member) => {
                return parseInt(member.id) === parseInt(item);
              });
              if (result) {
                attendees.push({
                  id: result.id,
                  name: `${result.studentId && result.studentId} ${result.firstName
                    } ${result.lastName} ${result.class && result.class.name} `,
                });
              }
            });
            this.setState({ attendees: this.removeDuplicates(attendees) });
            this.props.dispatch(
              change(
                "newreservation",
                "attendees",
                this.removeDuplicates(attendees)
              )
            );
          }
        } else {
          this.props.dispatch(change("newreservation", "studentId", null));
          this.setState({ options_studentobserv: student });
        }
        if (this.props.initialValues.studentId) {
          let attendees = [];
          this.props.dispatch(
            change(
              "newreservation",
              "studentId",
              this.props.initialValues.studentId
            )
          );
          const result = student.filter((member) => {
            return (
              parseInt(member.value) !==
              parseInt(this.props.initialValues.studentId)
            );
          });
          this.setState({ options_studentobserv: result });

          if (typeof this.props.initialValues.studentId === "object") {
            this.props.initialValues.studentId.map((item) => {
              const result = res.data.find((member) => {
                return parseInt(member.id) === parseInt(item);
              });
              if (result) {
                attendees.push({
                  id: result.id,
                  name: `${result.studentId && result.studentId} ${result.firstName
                    } ${result.lastName} ${result.class && result.class.name} `,
                });
              }
            });
            this.setState({ attendees: this.removeDuplicates(attendees) });
            this.props.dispatch(
              change(
                "newreservation",
                "attendees",
                this.removeDuplicates(attendees)
              )
            );
          }
        } else {
          this.props.dispatch(change("newreservation", "studentId", null));
          this.setState({ options_studentobserv: student });
        }
        this.setState({
          options_studentall: student,
          options_student: student,
          studentAll: res.data,
        });
      }
    });
  };
  getFlightLocation = () => {
    getAirport().then((res) => {
      let options = [];
      if (res && res.status === 200) {
        res.data.map((item) =>
          options.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item.id,
          })
        );
        this.setState({ options_location: options });
      }
    });
  };
  getTesttype = () => {
    // ไม่ใช้แล้ว
    // getFlightMonitorType().then(res=>{
    //   let option = [];
    //   res.data.map(item => {
    //     option.push({
    //       key: item.id,
    //       id: item.id,
    //       title: item.name,
    //       text: item.name,
    //       value: item.id
    //     });
    //   });
    //   this.setState({ options_monitor: option });
    // })
    getTestType().then((res) => {
      let option = [];
      res.data.map((item) => {
        option.push({
          key: item.id,
          id: item.id,
          title: item.name,
          text: item.name,
          value: item.id,
        });
      });
      this.setState({ options_testtype: option });
    });
  };
  getTypeGround = () => {
    getTypeGroundInstructor().then((res) => {
      let options = [];
      if (res && res.status === 200) {
        res.data.map((item) =>
          options.push({ key: item.id, text: item.name, value: item.id })
        );
      }
      this.setState({ options_typeGroundInstructor: options });
    });
  };

  getStudentGroupByGroundSubject = (courseId, subjectId) => {
    getStudentGroupByGroundSubject(courseId, subjectId).then((res) => {
      let currentGroup = [],
        group = [],
        additionalGroup = [];
      let studentByGroup = [],
        additionalStudentByGroup = [];
      let student = [];
      if (res && res.status === 200 && res.data) {
        studentByGroup = res.data.student.reduce(function (result, current) {
          result[current.class && current.class.id] =
            result[current.class && current.class.id] || [];
          result[current.class && current.class.id].push(current);
          if (current.class && currentGroup.indexOf(current.class.id) < 0) {
            currentGroup.push(current.class.id);
            group.push({
              key: current.class.id,
              text: current.class.name,
              value: current.class.id,
            });
          }
          return result;
        }, {});
        additionalStudentByGroup = res.data.additional.reduce(function (
          result,
          current
        ) {
          result[current.class && current.class.id] =
            result[current.class && current.class.id] || [];
          result[current.class && current.class.id].push(current);
          if (current.class && currentGroup.indexOf(current.class.id) < 0) {
            currentGroup.push(current.class.id);
            additionalGroup.push({
              key: current.class.id,
              text: current.class.name,
              value: current.class.id,
            });
          }
          return result;
        },
          {});
        res.data.additional.map((item) => {
          student.push({
            key: item.id,
            text: `${item.studentId} ${item.firstName} ${item.lastName} ${item.class ? item.class.name : ""
              }`,
            classId: item.class && item.class.id,
            value: item.id,
          });
        });
      }
      if (this.props.initialValues.additionalStudentId) {
        if (typeof this.props.initialValues.additionalStudentId === "object") {
          let additionalAttendees = [];
          this.props.initialValues.additionalStudentId.map((item) => {
            const result = res.data.additional.find((member) => {
              return parseInt(member.id) === parseInt(item);
            });
            if (result) {
              additionalAttendees.push({
                id: result.id,
                name: `${result.studentId && result.studentId} ${result.firstName
                  } ${result.lastName} ${result.class && result.class.name} `,
              });
            }
          });
          this.setState({
            additionalAttendees: this.removeDuplicates(additionalAttendees),
          });
          this.props.dispatch(
            change(
              "newreservation",
              "additionalAttendees",
              this.removeDuplicates(additionalAttendees)
            )
          );
        } else {
          this.props.dispatch(
            change("newreservation", "additionalAttendees", null)
          );
        }
      }
      this.setState({
        studentByGroup: studentByGroup,
        additionalStudentByGroup: additionalStudentByGroup,
        options_group: group,
        options_additionalGroup: additionalGroup,
        options_additionalStudent: student,
        options_student: student,
      });
    });
  };

  removeAttendees = (id) => {
    const { attendees } = this.props;
    let result = [];
    result = attendees.filter((item) => {
      return item.id !== id;
    });
    this.props.dispatch(change("newreservation", "attendees", result));
    this.setState({ attendees: result });
  };
  removeAdditionalAttendees = (id) => {
    const { additionalAttendees } = this.props;
    let result = [];
    result = additionalAttendees.filter((item) => {
      return item.id !== id;
    });
    this.props.dispatch(
      change("newreservation", "additionalAttendees", result)
    );
    this.setState({ additionalAttendees: result });
  };

  removeDuplicates(arr) {
    const result = [];
    const duplicatesIndices = [];

    // Loop through each item in the original array
    arr.forEach((current, index) => {
      if (duplicatesIndices.includes(index)) return;

      result.push(current);

      // Loop through each other item on array after the current one
      for (
        let comparisonIndex = index + 1;
        comparisonIndex < arr.length;
        comparisonIndex++
      ) {
        const comparison = arr[comparisonIndex];
        const currentKeys = Object.keys(current);
        const comparisonKeys = Object.keys(comparison);

        // Check number of keys in objects
        if (currentKeys.length !== comparisonKeys.length) continue;

        // Check key names
        const currentKeysString = currentKeys.sort().join("").toLowerCase();
        const comparisonKeysString = comparisonKeys
          .sort()
          .join("")
          .toLowerCase();
        if (currentKeysString !== comparisonKeysString) continue;

        // Check values
        let valuesEqual = true;
        for (let i = 0; i < currentKeys.length; i++) {
          const key = currentKeys[i];
          if (current[key] !== comparison[key]) {
            valuesEqual = false;
            break;
          }
        }
        if (valuesEqual) duplicatesIndices.push(comparisonIndex);
      } // end for loop
    }); // end arr.forEach()

    return result;
  }
  addTime = () => {
    let { start, end } = this.props;
    let multiTime = this.props.recurring || [];
    multiTime.push({
      start: moment(start, "DD-MM-YYYY HH:mm")
        .add(multiTime.length + 1, "days")
        .format("DD-MM-YYYY HH:mm"),
      end: moment(end, "DD-MM-YYYY HH:mm")
        .add(multiTime.length + 1, "days")
        .format("DD-MM-YYYY HH:mm"),
    });
    this.props.dispatch(change("newreservation", "recurring", multiTime));
    this.setState({ multiTime });
  };
  removeTime = (index) => {
    let { multiTime } = this.state;
    multiTime.splice(index, 1);
    this.setState({ multiTime });
  };
  render() {
    const state = this.state;
    const {
      handleSubmit,
      start,
      reservationType,
      studentId,
      instructor,
      studentGroup,
      departureId,
      destinationId,
      attendees,
      additionalAttendees,
      recurring,
      flightType,
      flightTypeIPSV,
      flightTypeMonitor,
      pristine,
      submitting,
      loading,
      isNew,
    } = this.props;

    if (state.loadingData)
      return (
        <div style={{ textAlign: "center" }}>
          <Icon loading name="circle notch" />
          <br />
          Loading..
        </div>
      );
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        {isNew ? (
          <Field
            // width="14"
            name="reservationType"
            label="Activity Type"
            required={true}
            component={renderSelectField}
            placeholder="Please Select"
            options={state.activeType}
            clearable={true}
          />
        ) : (
          <Header
            textAlign="center"
            as="h1"
            color="grey"
            style={{
              paddingBottom: "20px",
              paddingTop: "20px",
            }}
          >
            {reservationType && reservationType.name}
          </Header>
        )}
        <Form.Group>
          <Field name="start" width="8" component={renderDateTimeField} />
          <Field
            name="end"
            minDate={start}
            width="8"
            component={renderDateTimeField}
          />
        </Form.Group>
        {recurring &&
          recurring.map((item, index) => (
            <Form.Group>
              <Field
                name={`recurring.${index}.start`}
                width="8"
                component={renderDateTimeField}
              />
              <Field
                name={`recurring.${index}.end`}
                minDate={start}
                width="8"
                component={renderDateTimeField}
              />
              <Icon
                name="trash alternate outline"
                color="red"
                onClick={() => this.removeTime(index)}
              />
            </Form.Group>
          ))}
        {isNew && (
          <Button
            color="blue"
            compact
            size="mini"
            floated="right"
            content="Add"
            type="button"
            onClick={() => this.addTime()}
          />
        )}
        <br />
        {reservationType && reservationType.name.indexOf("Maintenance") >= 0 ? (
          <Field
            name="aircraft"
            component={renderSelectField}
            label="Aircraft"
            placeholder="Aircraft"
            options={state.options_aircraft}
            search
            required
          />
        ) : reservationType && reservationType.name.indexOf("Flight") >= 0 ? (
          <FlightForm
            reservationType={reservationType}
            student={state.options_student}
            studentAll={state.options_studentobserv}
            flightSubjectsByStudent={state.options_flightSubjectsByStudent}
            flightAddSubjectsByStudent={
              state.options_flightAddSubjectsByStudent
            }
            instuctorBySubject={state.options_instuctorBySubject}
            studentClass={state.options_filterclass}
            aircraft={state.options_aircraft}
            instructorAll={state.options_instructorSV}
            instructors={state.options_instructorall}
            locationBac={state.options_location}
            departureId={departureId}
            destinationId={destinationId}
            flightType={flightType}
            flightTypeIPSV={flightTypeIPSV}
            flightTypeMonitor={flightTypeMonitor}
            monitortype={state.options_monitor}
            testtype={state.options_testtype}
            simCategory={state.simCategory}
            flightRulesSP={state.flightRulesSP}
            flightRulesIP={state.flightRulesIP}
            expiredIP={state.expiredIP}
            expiredSP={state.expiredSP}
            studentId={studentId}
            instructor={instructor}
          />
        ) : reservationType && reservationType.name.indexOf("Ground") >= 0 ? (
          <GroundForm
            course={state.options_course}
            reservationType={reservationType}
            student={state.options_studentall}
            studentId={studentId}
            studentGroup={studentGroup}
            room={state.options_room}
            groundSubjects={state.groundSubjects}
            instructor={state.options_instuctorBySubject}
            // options_instructor
            typeGroundInstructor={state.options_typeGroundInstructor}
            group={state.options_group}
            additionalGroup={state.options_additionalGroup}
            additionalStudent={state.options_additionalStudent}
            // material={state.material}
            attendees={attendees}
            additionalAttendees={additionalAttendees}
            removeAttendees={this.removeAttendees}
            removeAdditionalAttendees={this.removeAdditionalAttendees}
            instructorAll={state.options_instructorSV}
          />
        ) : reservationType && reservationType.name.indexOf("Meeting") >= 0 ? (
          <MeettingForm
            reservationType={reservationType}
            student={state.student}
            studentId={studentId}
            room={state.options_room}
            groundSubjects={state.groundSubjects}
            instructor={state.options_instructor}
          />
        ) : (
          reservationType &&
          reservationType.name.indexOf("Others") >= 0 && (
            <Wrap>
              <Field
                name="reservationName"
                component={renderField}
                placeholder="Reservation Name"
                label="Reservation Name"
                required
              />
              {/*
                  <Form.Group>
                <Form.Field width="5">
                  <label style={{ textAlign: "left" }}>
                    Add to progress checklist
                  </label>
                </Form.Field>
                <Field
                  width="6"
                  name="asChecklist"
                  component={renderRadioField}
                  // label="Holiday"
                />
              </Form.Group>
              <br />*/}
              <Form.Group unstackable>
                <Field
                  width="8"
                  name="studentGroupAll"
                  component={renderSelectField}
                  placeholder="Student Class"
                  label="Student Class"
                  options={state.options_class}
                  multiple
                  search
                />

                <Field
                  width="8"
                  name="studentId"
                  component={renderSelectField}
                  placeholder="Student"
                  label="Student"
                  options={state.options_studentall}
                  multiple
                  search
                />
              </Form.Group>
              {/*

                */}
              <div>
                {attendees && attendees.length > 0 ? (
                  <Accordion
                    panels={[
                      {
                        key: "attendees",
                        title: {
                          content: (
                            <span
                              style={{
                                fontWeight: "bold",
                                color: config.colorTheme,
                              }}
                            >
                              Attendees {attendees && `(${attendees.length})`}
                            </span>
                          ),
                          icon: "users",
                        },
                        content: {
                          content: (
                            <Grid>
                              <Grid.Row
                                columns="1"
                                style={{ paddingBottom: "20px" }}
                              >
                                <Grid.Column>
                                  <List size="small">
                                    {attendees.map((item) => (
                                      <List.Item>
                                        <List.Icon
                                          link
                                          name="x"
                                          color="red"
                                          onClick={() =>
                                            this.removeAttendees(item.id)
                                          }
                                        />
                                        <List.Content>{item.name}</List.Content>
                                      </List.Item>
                                    ))}
                                  </List>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          ),
                        },
                      },
                    ]}
                  />
                ) : (
                  ""
                )}
              </div>
              <br />
              <Field
                // width="14"
                name="instructor"
                label="Instructor"
                component={renderSelectField}
                placeholder="Please Select"
                options={state.options_instructorall}
                search
                clearable={true}
              />
              <Field
                name="room"
                component={renderSelectField}
                placeholder="Room"
                label="Room"
                options={state.options_room}
                search
                required
              />
            </Wrap>
          )
        )}
        <Field
          name="comments"
          component={renderField}
          placeholder="Comments"
          label="Comments"
        />
        <br />
        <Button
          floated="left"
          type="submit"
          content="Save"
          compact
          color="green"
          loading={loading}
          disabled={!state.canSave || !state.canSaveIP}
        />
        <Button
          floated="right"
          type="button"
          content="Cancel"
          compact
          color="green"
          basic
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.reservationType &&
      nextProps.reservationType !== this.props.reservationType
    ) {
      switch (nextProps.reservationType.name) {
        case "Flight Training":
          this.getFlightLocation();
          this.getTesttype();
          this.getAircraft();
          break;
        case "Flight Non-Training":
          this.getFlightLocation();
          this.getTesttype();
          this.getAircraft();
          break;
        case "Flight Additional Training":
          this.getFlightLocation();
          this.getTesttype();
          this.getAircraft();
          break;
        case "Flight Maintenance":
          this.getAircraft();
          break;
        case "Ground training":
          this.getTypeGround();
          this.getRoom();
          break;
        case "Test / Others":
          this.getRoom();
          break;
        default:
      }

      this.props.dispatch(change("newreservation", "studentGroup", null));
      this.props.dispatch(change("newreservation", "studentGroupAll", null));
      this.props.dispatch(change("newreservation", "studentId", null));
      this.props.dispatch(change("newreservation", "groundSubjects", null));
      this.props.dispatch(change("newreservation", "flightSubjects", null));
      this.props.dispatch(change("newreservation", "flightTypeCAAT", null));
      this.props.dispatch(change("newreservation", "flightTypeTest", null));
      this.props.dispatch(change("newreservation", "instructorObserver", null));
      this.props.dispatch(
        change(
          "newreservation",
          "instructor",
          nextProps.initialValues && nextProps.initialValues.instructor
        )
      );
      // this.props.dispatch(change("newreservation", "instructor",null));
      this.props.dispatch(change("newreservation", "attendees", null));
    }

    if (nextProps.studentGroupAll !== this.props.studentGroupAll) {
      let { studentAll } = this.state;
      let attendees = [];
      if (nextProps.studentGroupAll) {
        nextProps.studentGroupAll.map((item) => {
          const result = studentAll.filter((member) => {
            if (member.class) return member.class.id === item;
          });
          if (result) {
            result.map((item) =>
              attendees.push({
                id: item.id,
                name: `${item.studentId && item.studentId} ${item.firstName} ${item.lastName
                  } ${item.class && item.class.name} `,
              })
            );
          }
        });
        this.props.dispatch(
          change(
            "newreservation",
            "attendees",
            this.removeDuplicates(attendees)
          )
        );
      }
      if (nextProps.studentId) {
        nextProps.studentId.map((item) => {
          const result = studentAll.find((member) => {
            return parseInt(member.id) === parseInt(item);
          });
          if (result) {
            attendees.push({
              id: result.id,
              name: `${result.studentId && result.studentId} ${result.firstName
                } ${result.lastName} ${result.class && result.class.name} `,
            });
          }
        });
        this.setState({ attendees: this.removeDuplicates(attendees) });
        this.props.dispatch(
          change(
            "newreservation",
            "attendees",
            this.removeDuplicates(attendees)
          )
        );
      }
    }
    if (nextProps.studentGroup !== this.props.studentGroup) {
      let { studentAll } = this.state;
      let attendees = [];
      if (nextProps.studentGroup) {
        let { studentByGroup } = this.state;
        nextProps.studentGroup.map((item) => {
          if (studentByGroup[item]) {
            studentByGroup[item].map((item) => {
              attendees.push({
                id: item.id,
                name: `${item.studentId && item.studentId} ${item.firstName} ${item.lastName
                  } ${item.class && item.class.name} `,
              });
            });
          }
        });
        this.props.dispatch(
          change(
            "newreservation",
            "attendees",
            this.removeDuplicates(attendees)
          )
        );
      }
      if (nextProps.studentId) {
        nextProps.studentId.map((item) => {
          const result = studentAll.find((member) => {
            return parseInt(member.id) === parseInt(item);
          });
          if (result) {
            attendees.push({
              id: result.id,
              name: `${result.studentId && result.studentId} ${result.firstName
                } ${result.lastName} ${result.class && result.class.name} `,
            });
          }
        });
        this.setState({ attendees: this.removeDuplicates(attendees) });
        this.props.dispatch(
          change(
            "newreservation",
            "attendees",
            this.removeDuplicates(attendees)
          )
        );
      }
    }
    if (nextProps.studentId && nextProps.studentId !== this.props.studentId) {
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        this.props.dispatch(change("newreservation", "studentObserver", null));
        const result = this.state.options_studentall.filter((member) => {
          return parseInt(member.value) !== parseInt(nextProps.studentId);
        });
        this.setState({ options_studentobserv: result });
        if (nextProps.initialValues.studentObserver)
          this.props.dispatch(
            change(
              "newreservation",
              "studentObserver",
              nextProps.initialValues.studentObserver
            )
          );

        this.props.dispatch(change("newreservation", "flightSubjects", null));
        this.props.dispatch(
          change("newreservation", "flightAddSubjects", null)
        );
        this.setState({
          options_flightSubjectsByStudent: [],
          options_flightAddSubjectsByStudent: [],
          flightRulesSP: null,
        });

        var startTime = moment(nextProps.start, "DD-MM-YYYY HH:mm");
        var endTime = moment(nextProps.end, "DD-MM-YYYY HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;
        if (nextProps.recurring && nextProps.recurring.length > 0) {
          nextProps.recurring.map((item) => {
            var start = moment(item.start, "DD-MM-YYYY HH:mm");
            var end = moment(item.end, "DD-MM-YYYY HH:mm");
            var itemduration = moment.duration(end.diff(start));
            var itemhours = parseInt(itemduration.asHours());
            var itemminutes = parseInt(itemduration.asMinutes()) % 60;
            hours += itemhours;
            minutes += itemminutes;
          });
        }
        let qString =
          "?start=" +
          startTime.format("YYYY-MM-DD") +
          "&time=" +
          startTime.format("HH:mm") +
          "&hours=" +
          hours +
          "&minutes=" +
          minutes;

        if (
          !nextProps.isNew &&
          nextProps.initialValues &&
          nextProps.initialValues.id
        ) {
          qString = qString + "&reservation=" + nextProps.initialValues.id;
        }
        if (
          nextProps.reservationType &&
          (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
            nextProps.reservationType.name.indexOf(
              "Flight Additional Training"
            ) >= 0)
        ) {
          qString = qString + "&expired";
        }
        checkFlightRulesSP(nextProps.studentId, qString).then((res) => {
          if (res && res.status === 200 && res.data && res.data.flightRules) {
            if (res.data.result === true) {
              // true
              this.setState({ canSave: true });
            } else this.setState({ canSave: false });
            this.setState({
              flightRulesSP: res.data.flightRules,
              expiredSP: res.data.expired,
            });
          }
        });
        if (
          nextProps.reservationType &&
          nextProps.reservationType.name === "Flight Training"
        ) {
          getFlightSubjectByStudent(nextProps.studentId).then((res) => {
            if (res && res.status === 200) {
              let option = [];
              res.data.map((subject) => {
                option.push({
                  key: subject.id,
                  text: `${subject.course_name} : ${subject.code} ${subject.hours && "(" + subject.hours + ")"
                    }`,
                  value: {
                    id: subject.id,
                    hours: subject.hours,
                    course: subject.course_id,
                  },
                });
              });
              if (this.props.initialValues.flightSubjects)
                this.props.dispatch(
                  change(
                    "newreservation",
                    "flightSubjects",
                    this.props.initialValues.flightSubjects
                  )
                );
              this.setState({ options_flightSubjectsByStudent: option });
            }
          });
        }
        if (
          nextProps.reservationType &&
          nextProps.reservationType.name === "Flight Additional Training"
        ) {
          getFlightAddSubjectByStudent(nextProps.studentId).then((res) => {
            if (res && res.status === 200) {
              let option = [];
              res.data.map((subject) => {
                option.push({
                  key: subject.id,
                  text: `${subject.course_name} : ${subject.code} ${subject.hours && "(" + subject.hours + ")"
                    }`,
                  value: {
                    id: subject.id,
                    hours: subject.hours,
                    course: subject.course_id,
                  },
                });
              });
              this.setState({ options_flightAddSubjectsByStudent: option });
            }
          });
        }
      } else {
        let { studentAll } = this.state;
        let attendees = [];
        if (nextProps.studentId) {
          nextProps.studentId.map((item) => {
            const result = studentAll.find((member) => {
              return parseInt(member.id) === parseInt(item);
            });
            if (result) {
              attendees.push({
                id: result.id,
                name: `${result.studentId && result.studentId} ${result.firstName
                  } ${result.lastName} ${result.class && result.class.name} `,
              });
            }
          });
          this.setState({ attendees: this.removeDuplicates(attendees) });
          this.props.dispatch(
            change(
              "newreservation",
              "attendees",
              this.removeDuplicates(attendees)
            )
          );
        }
        // if (nextProps.studentGroup) {
        //   let { studentByGroup } = this.state;
        //   nextProps.studentGroup.map((item) => {
        //     if (studentByGroup[item]) {
        //       studentByGroup[item].map((item) => {
        //         attendees.push({
        //           id: item.id,
        //           name: `${item.studentId && item.studentId} ${
        //             item.firstName
        //           } ${item.lastName} ${item.class && item.class.name} `,
        //         });
        //       });
        //     }
        //   });
        //   this.props.dispatch(
        //     change(
        //       "newreservation",
        //       "attendees",
        //       this.removeDuplicates(attendees)
        //     )
        //   );
        // }
        // if (nextProps.studentGroupAll) {
        //   nextProps.studentGroupAll.map((item) => {
        //     const result = studentAll.filter((member) => {
        //       if (member.class) return member.class.id === item;
        //     });
        //     if (result) {
        //       result.map((item) =>
        //         attendees.push({
        //           id: item.id,
        //           name: `${item.studentId && item.studentId} ${
        //             item.firstName
        //           } ${item.lastName} ${item.class && item.class.name} `,
        //         })
        //       );
        //     }
        //   });
        //   this.props.dispatch(
        //     change(
        //       "newreservation",
        //       "attendees",
        //       this.removeDuplicates(attendees)
        //     )
        //   );
        // }
      }
    }

    if (
      nextProps.additionalStudentGroup &&
      nextProps.additionalStudentGroup !== this.props.additionalStudentGroup
    ) {
      let additionalAttendees = [];
      if (nextProps.additionalStudentGroup) {
        let { additionalStudentByGroup } = this.state;
        nextProps.additionalStudentGroup.map((item) => {
          if (additionalStudentByGroup[item]) {
            additionalStudentByGroup[item].map((item) => {
              additionalAttendees.push({
                id: item.id,
                name: `${item.studentId && item.studentId} ${item.firstName} ${item.lastName
                  } ${item.class && item.class.name} `,
              });
            });
          }
        });
        this.setState({
          additionalAttendees: this.removeDuplicates(additionalAttendees),
        });
        this.props.dispatch(
          change(
            "newreservation",
            "additionalAttendees",
            this.removeDuplicates(additionalAttendees)
          )
        );
      }

      if (nextProps.additionalStudentId) {
        let { studentAll } = this.state;
        nextProps.additionalStudentId.map((item) => {
          const result = studentAll.find((member) => {
            return parseInt(member.id) === parseInt(item);
          });
          if (result) {
            additionalAttendees.push({
              id: result.id,
              name: `${result.studentId && result.studentId} ${result.firstName
                } ${result.lastName} ${result.class && result.class.name} `,
            });
          }
        });
        this.setState({
          additionalAttendees: this.removeDuplicates(additionalAttendees),
        });
        this.props.dispatch(
          change(
            "newreservation",
            "additionalAttendees",
            this.removeDuplicates(additionalAttendees)
          )
        );
      }
    }

    if (
      nextProps.additionalStudentId &&
      nextProps.additionalStudentId !== this.props.additionalStudentId
    ) {
      let { studentAll } = this.state;
      let additionalAttendees = [];
      if (nextProps.additionalStudentId) {
        nextProps.additionalStudentId.map((item) => {
          const result = studentAll.find((member) => {
            return parseInt(member.id) === parseInt(item);
          });
          if (result) {
            additionalAttendees.push({
              id: result.id,
              name: `${result.studentId && result.studentId} ${result.firstName
                } ${result.lastName} ${result.class && result.class.name} `,
            });
          }
        });
        this.setState({
          additionalAttendees: this.removeDuplicates(additionalAttendees),
        });
        this.props.dispatch(
          change(
            "newreservation",
            "additionalAttendees",
            this.removeDuplicates(additionalAttendees)
          )
        );
      }
      if (nextProps.additionalStudentGroup) {
        let { additionalStudentByGroup } = this.state;
        nextProps.additionalStudentGroup.map((item) => {
          if (additionalStudentByGroup[item]) {
            additionalStudentByGroup[item].map((item) => {
              additionalAttendees.push({
                id: item.id,
                name: `${item.studentId && item.studentId} ${item.firstName} ${item.lastName
                  } ${item.class && item.class.name} `,
              });
            });
          }
        });
        this.setState({
          additionalAttendees: this.removeDuplicates(additionalAttendees),
        });
        this.props.dispatch(
          change(
            "newreservation",
            "additionalAttendees",
            this.removeDuplicates(additionalAttendees)
          )
        );
      }
    }

    if (
      nextProps.instructor &&
      nextProps.instructor !== this.props.instructor
    ) {
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        this.setState({
          flightRulesIP: null,
        });
        // this.props.dispatch(change("newreservation", "flightTypeIPSV", null));
        this.props.dispatch(change("newreservation", "instructorSVId", null));
        this.props.dispatch(change("newreservation", "instructorIPId", null));
        this.props.dispatch(change("newreservation", "instructorProId", null));
        const result = this.state.options_instructorall.filter((member) => {
          return parseInt(member.value) !== parseInt(nextProps.instructor);
        });
        this.setState({ options_instructorSV: result });
        if (nextProps.initialValues.instructorSVId)
          this.props.dispatch(
            change(
              "newreservation",
              "instructorSVId",
              nextProps.initialValues.instructorSVId
            )
          );
        if (nextProps.initialValues.instructorIPId)
          this.props.dispatch(
            change(
              "newreservation",
              "instructorIPId",
              nextProps.initialValues.instructorIPId
            )
          );
        if (nextProps.initialValues.instructorProId)
          this.props.dispatch(
            change(
              "newreservation",
              "instructorProId",
              nextProps.initialValues.instructorProId
            )
          );
        if (nextProps.initialValues.instructorObserver)
          this.props.dispatch(
            change(
              "newreservation",
              "instructorObserver",
              nextProps.initialValues.instructorObserver
            )
          );

        var startTime = moment(nextProps.start, "DD-MM-YYYY HH:mm");
        var endTime = moment(nextProps.end, "DD-MM-YYYY HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;

        if (nextProps.recurring && nextProps.recurring.length > 0) {
          nextProps.recurring.map((item) => {
            var start = moment(item.start, "DD-MM-YYYY HH:mm");
            var end = moment(item.end, "DD-MM-YYYY HH:mm");
            var itemduration = moment.duration(end.diff(start));
            var itemhours = parseInt(itemduration.asHours());
            var itemminutes = parseInt(itemduration.asMinutes()) % 60;
            hours += itemhours;
            minutes += itemminutes;
          });
        }

        let qString =
          "?start=" +
          startTime.format("YYYY-MM-DD") +
          "&time=" +
          startTime.format("HH:mm") +
          "&hours=" +
          hours +
          "&minutes=" +
          minutes;

        if (
          !nextProps.isNew &&
          nextProps.initialValues &&
          nextProps.initialValues.id
        ) {
          qString = qString + "&reservation=" + nextProps.initialValues.id;
        }
        if (
          nextProps.reservationType &&
          (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
            nextProps.reservationType.name.indexOf(
              "Flight Additional Training"
            ) >= 0)
        ) {
          qString = qString + "&expired";
        }
        if (nextProps.flightSubjects && nextProps.flightSubjects.id) {
          qString = qString + "&subject=" + nextProps.flightSubjects.id;
        }
        checkFlightRulesIP(nextProps.instructor, qString).then((res) => {
          if (res && res.status === 200 && res.data && res.data.flightRules)
            if (res.data.result === true) {
              // true
              this.setState({ canSave: true });
            } else this.setState({ canSave: false });
          this.setState({
            flightRulesIP: res.data.flightRules,
            expiredIP: res.data.expired,
          });
        });
      }
    }
    if (
      nextProps.flightSubjects &&
      nextProps.flightSubjects !== this.props.flightSubjects
    ) {
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        this.props.dispatch(
          change(
            "newreservation",
            "instructor",
            nextProps.initialValues.instructor
          )
        );
        this.setState({ options_instuctorBySubject: [] });
        if (nextProps.flightSubjects && nextProps.flightSubjects.id)
          getInstuctorBySubject(nextProps.flightSubjects.id).then((res) => {
            if (res && res.status === 200) {
              let option = [];
              res.data.map((item) => {
                option.push({
                  key: item.id,
                  text: item.employmenttype
                    ? `${item.firstName} ${item.lastName} (${item.employmenttype})`
                    : `${item.firstName} ${item.lastName}`,
                  value: item.id,
                });
              });
              this.setState({ options_instuctorBySubject: option });
            }
          });
      }
    }

    if (nextProps.recurring && nextProps.recurring !== this.props.recurring) {
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        var startTime = moment(nextProps.start, "DD-MM-YYYY HH:mm");
        var endTime = moment(nextProps.end, "DD-MM-YYYY HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;
        if (nextProps.recurring && nextProps.recurring.length > 0) {
          nextProps.recurring.map((item) => {
            var start = moment(item.start, "DD-MM-YYYY HH:mm");
            var end = moment(item.end, "DD-MM-YYYY HH:mm");
            var itemduration = moment.duration(end.diff(start));
            var itemhours = parseInt(itemduration.asHours());
            var itemminutes = parseInt(itemduration.asMinutes()) % 60;
            hours += itemhours;
            minutes += itemminutes;
          });
        }
        let qString =
          "?start=" +
          startTime.format("YYYY-MM-DD") +
          "&time=" +
          startTime.format("HH:mm") +
          "&hours=" +
          hours +
          "&minutes=" +
          minutes;
        if (nextProps.studentId) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          checkFlightRulesSP(nextProps.studentId, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules) {
              if (res.data.result === true) {
                // true
                this.setState({ canSave: true });
              } else this.setState({ canSave: false });
              this.setState({
                flightRulesSP: res.data.flightRules,
                expiredSP: res.data.expired,
              });
            }
          });
        }
        if (nextProps.instructor) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          if (nextProps.flightSubjects && nextProps.flightSubjects.id) {
            qString = qString + "&subject=" + nextProps.flightSubjects.id;
          }

          checkFlightRulesIP(nextProps.instructor, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules)
              if (res.data.result === true) {
                // true
                this.setState({ canSaveIP: true });
              } else this.setState({ canSaveIP: false });
            this.setState({
              flightRulesIP: res.data.flightRules,
              expiredIP: res.data.expired,
            });
          });
        }
      }
    }

    if (nextProps.start && nextProps.start !== this.props.start) {
      if (
        nextProps.isNew &&
        this.props.groundSubjects &&
        this.props.groundSubjects.hours
      ) {
        this.props.dispatch(
          change(
            "newreservation",
            "end",
            moment(nextProps.start, "DD-MM-YYYY HH:mm")
              .add(this.props.groundSubjects.hours, "hours")
              .format("DD-MM-YYYY HH:mm")
          )
        );
      }
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        var startTime = moment(nextProps.start, "DD-MM-YYYY HH:mm");
        var endTime = moment(nextProps.end, "DD-MM-YYYY HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;

        if (nextProps.recurring && nextProps.recurring.length > 0) {
          nextProps.recurring.map((item) => {
            var start = moment(item.start, "DD-MM-YYYY HH:mm");
            var end = moment(item.end, "DD-MM-YYYY HH:mm");
            var itemduration = moment.duration(end.diff(start));
            var itemhours = parseInt(itemduration.asHours());
            var itemminutes = parseInt(itemduration.asMinutes()) % 60;
            hours += itemhours;
            minutes += itemminutes;
          });
        }

        let qString =
          "?start=" +
          startTime.format("YYYY-MM-DD") +
          "&time=" +
          startTime.format("HH:mm") +
          "&hours=" +
          hours +
          "&minutes=" +
          minutes;

        if (
          !nextProps.isNew &&
          nextProps.initialValues &&
          nextProps.initialValues.id
        ) {
          qString = qString + "&reservation=" + nextProps.initialValues.id;
        }
        if (nextProps.studentId) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          checkFlightRulesSP(nextProps.studentId, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules) {
              if (res.data.result === true) {
                // true
                this.setState({ canSave: true });
              } else this.setState({ canSave: false });
              this.setState({
                flightRulesSP: res.data.flightRules,
                expiredSP: res.data.expired,
              });
            }
          });
        }
        if (nextProps.instructor) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          if (nextProps.flightSubjects && nextProps.flightSubjects.id) {
            qString = qString + "&subject=" + nextProps.flightSubjects.id;
          }
          checkFlightRulesIP(nextProps.instructor, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules)
              if (res.data.result === true) {
                // true
                this.setState({ canSaveIP: true });
              } else this.setState({ canSaveIP: false });
            this.setState({
              flightRulesIP: res.data.flightRules,
              expiredIP: res.data.expired,
            });
          });
        }
      }
      // comment form bac
      {
        /*
      if (nextProps.isNew && this.props.flightSubjects && this.props.flightSubjects.hours) {
        let time = this.props.flightSubjects.hours.split(":");
        this.props.dispatch(
          change(
            "newreservation",
            "end",
            moment(this.props.start, "DD-MM-YYYY HH:mm")
              .add(time[0], "hours")
              .add(time[1], "minutes")
              .format("DD-MM-YYYY HH:mm")
          )
        );
      }*/
      }
    }
    if (nextProps.end && nextProps.end !== this.props.end) {
      if (
        nextProps.reservationType &&
        nextProps.reservationType.name.indexOf("Flight") >= 0
      ) {
        var startTime = moment(nextProps.start, "DD-MM-YYYY HH:mm");
        var endTime = moment(nextProps.end, "DD-MM-YYYY HH:mm");
        var duration = moment.duration(endTime.diff(startTime));
        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;

        if (nextProps.recurring && nextProps.recurring.length > 0) {
          nextProps.recurring.map((item) => {
            var start = moment(item.start, "DD-MM-YYYY HH:mm");
            var end = moment(item.end, "DD-MM-YYYY HH:mm");
            var itemduration = moment.duration(end.diff(start));
            var itemhours = parseInt(itemduration.asHours());
            var itemminutes = parseInt(itemduration.asMinutes()) % 60;
            hours += itemhours;
            minutes += itemminutes;
          });
        }

        let qString =
          "?start=" +
          startTime.format("YYYY-MM-DD") +
          "&time=" +
          startTime.format("HH:mm") +
          "&hours=" +
          hours +
          "&minutes=" +
          minutes;

        if (
          !nextProps.isNew &&
          nextProps.initialValues &&
          nextProps.initialValues.id
        ) {
          qString = qString + "&reservation=" + nextProps.initialValues.id;
        }
        if (nextProps.studentId) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          checkFlightRulesSP(nextProps.studentId, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules) {
              if (res.data.result === true) {
                // true
                this.setState({ canSave: true });
              } else this.setState({ canSave: false });
              this.setState({
                flightRulesSP: res.data.flightRules,
                expiredSP: res.data.expired,
              });
            }
          });
        }
        if (nextProps.instructor) {
          if (
            nextProps.reservationType &&
            (nextProps.reservationType.name.indexOf("Flight Training") >= 0 ||
              nextProps.reservationType.name.indexOf(
                "Flight Additional Training"
              ) >= 0)
          ) {
            qString = qString + "&expired";
          }
          if (nextProps.flightSubjects && nextProps.flightSubjects.id) {
            qString = qString + "&subject=" + nextProps.flightSubjects.id;
          }
          checkFlightRulesIP(nextProps.instructor, qString).then((res) => {
            if (res && res.status === 200 && res.data && res.data.flightRules)
              if (res.data.result === true) {
                // true
                this.setState({ canSaveIP: true });
              } else this.setState({ canSaveIP: false });
            this.setState({
              flightRulesIP: res.data.flightRules,
              expiredIP: res.data.expired,
            });
          });
        }
      }
    }

    if (
      nextProps.groundSubjects &&
      nextProps.groundSubjects !== this.props.groundSubjects
    ) {
      this.props.dispatch(
        change(
          "newreservation",
          "instructor",
          nextProps.initialValues.instructor
        )
      );
      this.setState({ options_instuctorBySubject: [] });
      // , material: null
      if (nextProps.groundSubjects && nextProps.groundSubjects.id) {
        this.props.dispatch(
          change(
            "newreservation",
            "material",
            nextProps.groundSubjects.material
          )
        );
        // this.setState({ material: nextProps.groundSubjects.material });
        getInstuctorBySubject(nextProps.groundSubjects.id).then((res) => {
          if (res && res.status === 200) {
            let option = [];
            res.data.map((item) => {
              option.push({
                key: item.id,
                text: item.employmenttype
                  ? `${item.firstName} ${item.lastName} (${item.employmenttype})`
                  : `${item.firstName} ${item.lastName}`,
                value: item.id,
              });
            });
            this.setState({ options_instuctorBySubject: option });
            const result = this.state.options_instructorall.filter((member) => {
              return parseInt(member.value) !== parseInt(nextProps.instructor);
            });
            this.setState({ options_instructorSV: result });
          }
        });

        this.getStudentGroupByGroundSubject(
          nextProps.courseId,
          nextProps.groundSubjects.id
        );
      }

      if (nextProps.isNew) {
        this.props.dispatch(
          change(
            "newreservation",
            "end",
            moment(this.props.start, "DD-MM-YYYY HH:mm")
              .add(nextProps.groundSubjects.hours, "hours")
              .format("DD-MM-YYYY HH:mm")
          )
        );
      }
      // this.setState({ options_instructor });
    }

    if (
      nextProps.flightSubjects &&
      nextProps.flightSubjects !== this.props.flightSubjects
    ) {
      const { instructorByFSubject } = this.state;
      let options_instructor = [];
      if (instructorByFSubject[nextProps.flightSubjects.id]) {
        instructorByFSubject[nextProps.flightSubjects.id].map((item) => {
          options_instructor.push({
            key: item.id,
            text: item.employmenttype
              ? `${item.firstName} ${item.lastName} (${item.employmenttype})`
              : `${item.firstName} ${item.lastName}`,
            value: {
              instructorId: item.id,
              name: `${item.firstName} ${item.lastName}`,
            },
          });
          if (
            this.props.initialValues.instructorId &&
            item.id === this.props.initialValues.instructorId
          ) {
            this.props.dispatch(
              change("newreservation", "instructor", {
                instructorId: item.id,
                name: `${item.firstName} ${item.lastName}`,
              })
            );
          } else {
            this.props.dispatch(change("newreservation", "instructor", null));
          }
        });
      }

      {
        /*
        // commment form bac
        let time =
        nextProps.flightSubjects.hours &&
        nextProps.flightSubjects.hours.split(":");
      // รอเปลี่ยนเวลา
      if (time && nextProps.isNew) {
        this.props.dispatch(
          change(
            "newreservation",
            "end",
            moment(this.props.start, "DD-MM-YYYY HH:mm")
              .add(time[0], "hours")
              .add(time[1], "minutes")
              .format("DD-MM-YYYY HH:mm")
          )
        );
      }
      */
      }
      this.setState({ options_instructor });
    }

    if (nextProps.aircraft && nextProps.aircraft !== this.props.aircraft) {
      let { rawDataAircraft } = this.state;
      if (rawDataAircraft) {
        const result = rawDataAircraft.find((member) => {
          return parseInt(member.id) === parseInt(nextProps.aircraft);
        });
        if (result && result.category === "sim")
          this.setState({ simCategory: true });
        else this.setState({ simCategory: false });
      }
    }

    if (nextProps.courseId && nextProps.courseId !== this.props.courseId) {
      this.props.dispatch(change("newreservation", "studentGroup", null));
      this.props.dispatch(change("newreservation", "studentId", null));
      this.props.dispatch(change("newreservation", "attendees", null));
      this.getGroundSubjects(nextProps.courseId);
      if (nextProps.groundSubjects && nextProps.groundSubjects.id) {
        this.getStudentGroupByGroundSubject(
          nextProps.courseId,
          nextProps.groundSubjects.id
        );
      }
      this.props.dispatch(
        change("newreservation", "groundSubjects", nextProps.groundSubjects)
      );

      this.setState({
        studentByGroup: [],
        additionalStudentByGroup: [],
        options_group: [],
        options_additionalGroup: [],
        options_additionalStudent: [],
        options_student: [],
      });
    }

    if (
      nextProps.studentClass &&
      nextProps.studentClass !== this.props.studentClass
    ) {
      this.props.dispatch(change("newreservation", "studentId", null));
      const { options_studentall } = this.state;
      if (nextProps.studentClass === "All") {
        this.setState({ options_student: options_studentall });
      } else {
        const result = options_studentall.filter((member) => {
          return parseInt(member.classId) === parseInt(nextProps.studentClass);
        });
        this.setState({ options_student: result });
      }
    }

    if (
      nextProps.courseType &&
      nextProps.courseType !== this.props.courseType
    ) {
      this.getCourse(nextProps.courseType);
      // /this.props.dispatch(change("newreservation", "courseId", null));
      // this.props.dispatch(change("newreservation", "studentId", null));
      // this.props.dispatch(change("newreservation", "groundSubjects", null));
      // this.props.dispatch(change("newreservation", "instructor", null));
      // this.props.dispatch(change("newreservation", "studentGroup", null));
      // this.props.dispatch(change("newreservation", "attendees", null));
      // this.setState({ material: null });
    }
  }
}

FromReservation = reduxForm({
  // a unique name for the form
  form: "newreservation",
  validate,
  enableReinitialize: true,
})(FromReservation);

// Decorate with connect to read form values
const selector = formValueSelector("newreservation"); // <-- same as form name
FromReservation = connect((state) => {
  // can select values individually
  const start = selector(state, "start");
  const reservationType = selector(state, "reservationType");
  const studentId = selector(state, "studentId");
  const course = selector(state, "course");
  const {
    end,
    departureId,
    destinationId,
    studentGroup,
    groundSubjects,
    flightSubjects,
    attendees,
    additionalAttendees,
    additionalStudentGroup,
    additionalStudentId,
    studentGroupAll,
    studentClass,
    instructor,
    aircraft,
    courseId,
    recurring,
    flightType,
    flightTypeIPSV,
    flightTypeMonitor,
    courseType,
  } = selector(
    state,
    "end",
    "departureId",
    "destinationId",
    "studentGroup",
    "groundSubjects",
    "flightSubjects",
    "attendees",
    "additionalAttendees",
    "additionalStudentGroup",
    "additionalStudentId",
    "studentGroupAll",
    "studentClass",
    "instructor",
    "aircraft",
    "courseId",
    "recurring",
    "flightType",
    "flightTypeIPSV",
    "flightTypeMonitor",
    "courseType"
  );

  return {
    start,
    end,
    reservationType,
    course,
    studentId,
    studentGroup,
    departureId,
    destinationId,
    groundSubjects,
    flightSubjects,
    attendees,
    additionalAttendees,
    additionalStudentGroup,
    additionalStudentId,
    studentGroupAll,
    studentClass,
    instructor,
    aircraft,
    courseId,
    recurring,
    flightType,
    flightTypeIPSV,
    flightTypeMonitor,
    courseType,
  };
})(FromReservation);
export default FromReservation;
