import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import { connect } from 'react-redux'
import { Segment, Header, Grid, Label, Table, Icon, Button, Form, Statistic } from 'semantic-ui-react'
import moment from 'moment'
import * as config from '../../config'
import TableList from '../../components/table'
import { getEventByIdBySearch, getLogbookByReservation, getLogbookIP, getLogbookSP } from './service';
import Wrap from '../../components/wrap'
import ModalForm from '../../components/modal'
import FormFlightHoursRecord from './flightHourRecordForm'
import renderTimeField from "../../components/renderTimeField";


class FlightHourRecord extends Component {
    state = {
        locationState: this.props.location.state,
        loading: false,
        data: [],
        open: false,
        logbookIP: [],
        logbookSP: [],
        defaultTime: null,
        currentData: null,
        defaultIP: null,
        defaultSP: null,
        moduleIP: [],
        moduleSP: [],
        addIP: [],
        addSP: [],
        instructor: [],
        student: []

    }
    onSave = (ip, sp) => {
        const { currentData } = this.state
        console.log('currentData', currentData)
        console.log('ip', ip)
        console.log('sp', sp)
    }

    // onClickRow = () => {
    //     let { locationState } = this.state
    //     this.setState({ open: true, loading: true, currentData: locationState.data, defaultTime: locationState.data.subject.subjectHours })
    //     this.getLogbook(locationState.data.id)
    // }

    onClose = () => this.setState({ open: false })

    getLogbook = data => {
        getLogbookByReservation(data).then(res => {
            if (res && res.status === 200) {
                // console.log(res)
                this.setState({ student: res.data.student, instructor: res.data.instuctor });
            }
        })
    }
    getData = () => {
        getLogbookIP().then(res => {
            if (res && res.status === 200) {
                let arr = []
                res.data.map(v => {
                    if (v.group) {
                        v.group.map(g => {
                            let value = {
                                editable: v.editable,
                                group: [g],
                                id: v.id,
                                name: v.name
                            }
                            arr.push({ key: g.id, text: g.name, value: value })
                        })
                    } else {
                        arr.push({ key: v.id, text: v.name, value: v })
                    }
                })
                this.setState({ options_logbookIP: arr });
            }
        });
        getLogbookSP().then(res => {
            if (res && res.status === 200) {
                let arr = []
                res.data.map(v => {
                    if (v.group) {
                        v.group.map(g => {
                            let value = {
                                editable: v.editable,
                                group: [g],
                                id: v.id,
                                name: v.name
                            }
                            arr.push({ key: g.id, text: g.name, value: value })
                        })
                    } else {
                        arr.push({ key: v.id, text: v.name, value: v })
                    }
                })
                this.setState({ options_logbookSP: arr });
            }
        });
    };
    onChangeDefaultIP = (e, value) => {
        // console.log(value)
        this.setState({ defaultIP: value.value })
    }
    onChangeDefaultSP = (e, value) => {
        // console.log(value)
        this.setState({ defaultSP: value.value })
    }
    handleAddItemClickDelete = (type, index) => {
        if (type == 'IP') {
            let addIP = this.state.addIP
            addIP.splice(index, 1)
            this.setState({ addIP, })
        } else {
            let addSP = this.state.addSP
            addSP.splice(index, 1)
            this.setState({ addSP, })
        }
    }
    handleItemClickDelete = (type, num, index, hasSub, subindex) => {
        let { instructor, student } = this.state;
        if (type == 'IP') {
            if (hasSub == 'sub') {
                instructor[num].logbook[index].group.splice(subindex, 1)
                if (instructor[num].logbook[index].group.length == 0) {
                    instructor[num].logbook.splice(index, 1)
                }
                this.setState({ instructor, })
            } else {
                instructor[num].logbook.splice(index, 1)
                this.setState({ instructor, })
            }

        } else {
            if (hasSub == 'sub') {
                student[num].logbook[index].group.splice(subindex, 1)
                if (student[num].logbook[index].group.length == 0) {
                    student[num].logbook.splice(index, 1)
                }
                this.setState({ student, })
            } else {
                student[num].logbook.splice(index, 1)
                this.setState({ student, })
            }


        }
    }
    onChange = (type, e, data, key) => {
        let { addIP, addSP } = this.state;
        if (type == 'IP') {
            addIP[key] = data.value;
            this.setState({ addIP });
        } else {
            addSP[key] = data.value;
            this.setState({ addSP });
        }

    };
    pushData = (type, key) => {
        if (type == 'IP') {
            let { addIP, instructor } = this.state;
            // console.log(addIP[0].name)
            if (addIP[0].name) {
                const result = instructor[key].logbook.filter((logbook) => {
                    return logbook.name == addIP[0].name
                })
                // console.log('result', result)
                if (result.length > 0) {
                    result.map(res =>
                        res.group = res.group.concat(addIP[0].group)
                    )
                } else {
                    instructor[key].logbook = instructor[key].logbook.concat(addIP)
                }
                this.setState({ instructor, addIP: [] });
            } else {
                alert('กรุณาเลือก Logbook IP')
            }
        } else {
            let { addSP, student } = this.state;
            if (addSP[0].name) {
                const result = student[key].logbook.filter((logbook) => {
                    return logbook.name == addSP[0].name
                })
                if (result.length > 0) {
                    result.map(res =>
                        res.group = res.group.concat(addSP[0].group)
                    )
                } else {
                    student[key].logbook = student[key].logbook.concat(addSP)
                }
                this.setState({ student, addSP: [] });
            } else {
                alert('กรุณาเลือก Logbook SP')
            }
        }
    }
    handleItemClickAddIP = () => {
        let addIP = this.state.addIP;
        addIP.push({
            id: null,
            name: null,
            group: null,
            editable: true
        });
        this.setState({ addIP });
    };
    handleItemClickAddSP = () => {
        let addSP = this.state.addSP;
        addSP.push({
            id: null,
            name: null,
            group: null,
            editable: true
        });
        this.setState({ addSP });
    };
    componentDidMount() {
        let { locationState } = this.state
        this.setState({ defaultIP: locationState.data.subject.subjectHours, defaultSP: locationState.data.subject.subjectHours, })
        this.getLogbook(locationState.data.id)
        this.getData();
        this.props.dispatch(change("flighthourrecord", "defaultHours", locationState.data.subject.subjectHours));
    }
    render() {
        const state = this.state
        const { defaultHours } = this.props
        // console.log(state.instructor)
        return (
            <Segment className="contentSegment" textAlign='left'>
                <Header as='h2' style={{ color: config.colorTheme }}>Flight Hour Record</Header>
                <br /><br />
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as="h2" textAlign='center'>
                                <Header.Content >
                                    {state.locationState.data.title}

                                    <Header.Subheader>
                                        {state.locationState.data.aircraftName} <br />
                                        {moment(state.locationState.data.start).format("dddd, MMMM Do YYYY")} ({moment(state.locationState.data.start).format("HH:mm")} - {moment(state.locationState.data.start).format("HH:mm")})

                                    </Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column textAlign='center'>

                            {/* <Form.Input width={3} name='defaultIP' label='Flight Instruction' value={state.defaultIP}
                                    onChange={(e, value) => this.onChangeDefaultIP(e, value)} /> */}
                            <Statistic color="orange" size="mini">
                                <Statistic.Value>Flight Instruction</Statistic.Value>
                                <Statistic.Label>
                                    <Form>
                                        <Field
                                            name="defaultHours"
                                            width="8"
                                            // label="Flight (Hours)"
                                            component={renderTimeField}
                                        />
                                    </Form>
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns='2'>
                        <Grid.Column>
                            <Header as='h3'>Instructors</Header>
                            {state.instructor && state.instructor.length > 0 &&
                                state.instructor.map((item, n) =>
                                    <Grid.Row style={{ marginBottom: 15 }}>
                                        <Grid.Column>
                                            {/* <Segment secondary textAlign="left"> */}
                                            <Header as='h4'>{item.firstName} {item.lastName}</Header>
                                            <Form size='mini'>
                                                {/* <Form.Group widths='equal' style={{ fontSize: '110%' }}>
                                                            <Form.Input name={`IPTO.${n}`} label='T/O'
                                                                onChange={(e, value) => console.log(e, value)} />
                                                            <Form.Input name={`IPLDG.${n}`} label='LDG'
                                                                onChange={(e, value) => console.log(e, value)} />
                                                        </Form.Group> */}
                                                <Table
                                                    compact
                                                    celled
                                                    striped
                                                    style={{ marginBottom: "30px" }}
                                                    unstackable
                                                >
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell className="header-table" width={1} />
                                                            <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={11}>
                                                                Logbook IP
                                                                </Table.HeaderCell>
                                                            <Table.HeaderCell
                                                                className="header-table"
                                                                textAlign="center"
                                                                width={3}
                                                            >
                                                                {/* <Form.Input width={16} name='defaultIP' value={state.defaultIP}
                                                                        onChange={(e, value) => this.onChangeDefaultIP(e, value)} /> */}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={1} >
                                                                Action
                                                    </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {item.logbook.map((item, num) =>
                                                            item.group ? (
                                                                <Wrap>
                                                                    <Table.Row>
                                                                        <Table.Cell textAlign="right">
                                                                            {num + 1}
                                                                        </Table.Cell>
                                                                        <Table.Cell
                                                                            className="bold"
                                                                        // style={{ fontSize: "90%" }}
                                                                        >
                                                                            <p>{item.name}</p>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center"></Table.Cell>
                                                                        <Table.Cell>
                                                                            {/* <Icon
                                                                                        link
                                                                                        color="red"
                                                                                        name="trash alternate"
                                                                                        onClick={() => this.handleItemClickDelete('IP', n, num)}
                                                                                    /> */}
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                    {item.group.map((sub, subindex) => (
                                                                        <Table.Row>
                                                                            <Table.Cell textAlign="right" />
                                                                            <Table.Cell
                                                                                className="bold"
                                                                                style={{
                                                                                    // fontSize: "90%",
                                                                                    paddingLeft: "15px"
                                                                                }}
                                                                            >
                                                                                <li>{sub.name}</li>
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign="center">
                                                                                <Form.Input width={16} name={`logbookIP.${sub.id}`}
                                                                                    value={defaultHours} />
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign="center">
                                                                                <Icon
                                                                                    link
                                                                                    color="red"
                                                                                    name="trash alternate"
                                                                                    onClick={() => this.handleItemClickDelete('IP', n, num, 'sub', subindex)}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))}
                                                                </Wrap>
                                                            ) : (
                                                                    <Table.Row>
                                                                        <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                                                                        <Table.Cell
                                                                            className="bold"
                                                                        // style={{ fontSize: "90%" }}
                                                                        >
                                                                            <p>{item.name}</p>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <Form.Input width={16} name={`logbookIP.${item.id}`}
                                                                                value={defaultHours} readOnly />
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <Icon
                                                                                link
                                                                                color="red"
                                                                                name="trash alternate"
                                                                                onClick={() => this.handleItemClickDelete('IP', n, num)}
                                                                            />
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                        )}
                                                        {state.addIP.map((item, index) => (
                                                            <Table.Row>
                                                                <Table.Cell textAlign="right"></Table.Cell>
                                                                <Table.Cell >
                                                                    <Form.Group style={{ marginBottom: "0px" }}>
                                                                        <Form.Select
                                                                            onChange={(e, value) => this.onChange('IP', e, value, index)}
                                                                            options={state.options_logbookIP}></Form.Select>
                                                                        <Button
                                                                            animated="vertical"
                                                                            size="mini"
                                                                            color="green"
                                                                            // loading={state.loadingUp}
                                                                            onClick={() => this.pushData('IP', n)}
                                                                        >
                                                                            <Button.Content hidden>Update</Button.Content>
                                                                            <Button.Content visible>
                                                                                <Icon name="check" />
                                                                            </Button.Content>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign="right">
                                                                    <Form.Input width={16} name={`logbookIPAdd.${index.id}`}
                                                                        value={defaultHours} readOnly />
                                                                </Table.Cell>
                                                                <Table.Cell textAlign="center">
                                                                    <Icon
                                                                        link
                                                                        color="red"
                                                                        name="trash alternate"
                                                                        onClick={() => this.handleAddItemClickDelete('IP', index)}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                        <Table.Row>
                                                            <Table.Cell className="footer-table" textAlign="right"></Table.Cell>
                                                            <Table.Cell className="footer-table"></Table.Cell>
                                                            <Table.Cell className="footer-table"></Table.Cell>
                                                            <Table.Cell className="footer-table" textAlign="right">
                                                                <Button
                                                                    color="blue"
                                                                    size="small"
                                                                    compact
                                                                    type="button"
                                                                    content="Add"
                                                                    onClick={() => this.handleItemClickAddIP('logbookIP')}
                                                                />
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Form>
                                            {/* </Segment> */}
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            }
                        </Grid.Column>
                        <Grid.Column>
                            <Header as='h3'>Students</Header>
                            {state.student && state.student.length > 0 &&
                                state.student.map((item, n) =>
                                    <Grid.Row style={{ marginBottom: 15 }}>
                                        <Grid.Column>
                                            {/* <Segment secondary textAlign="left"> */}
                                            <Header as='h4'>{item.firstName} {item.lastName}</Header>
                                            <Form size='mini'>
                                                <Form.Group widths='equal' style={{ fontSize: '110%' }}>
                                                    <Form.Input name={`SPTO.${n}`} label='T/O'
                                                        onChange={(e, value) => console.log(e, value)} />
                                                    <Form.Input name={`SPLDG.${n}`} label='LDG'
                                                        onChange={(e, value) => console.log(e, value)} />
                                                </Form.Group>

                                                <Table
                                                    compact
                                                    celled
                                                    striped
                                                    style={{ marginBottom: "30px" }}
                                                    unstackable
                                                >
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell className="header-table" width={1} />
                                                            <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={11}>
                                                                Logbook SP
                                                                </Table.HeaderCell>
                                                            <Table.HeaderCell
                                                                className="header-table"
                                                                textAlign="center"
                                                                width={3}
                                                            >
                                                                {/* <Form.Input width={16} name='defaultSP' value={state.defaultIP}
                                                                        onChange={(e, value) => this.onChangeDefaultSP(e, value)} /> */}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={1}>
                                                                Action
                                                                </Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {item.logbook.map((item, num) =>
                                                            item.group ? (
                                                                <Wrap>
                                                                    <Table.Row>
                                                                        <Table.Cell textAlign="right">
                                                                            {num + 1}
                                                                        </Table.Cell>
                                                                        <Table.Cell
                                                                            className="bold"
                                                                        // style={{ fontSize: "110%" }}
                                                                        >
                                                                            <p>{item.name}</p>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center"></Table.Cell>
                                                                        <Table.Cell>
                                                                            {/* <Icon
                                                                                    link
                                                                                    color="red"
                                                                                    name="trash alternate"
                                                                                    onClick={() => this.handleItemClickDelete('SP', n, num)}
                                                                                /> */}
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                    {item.group.map((sub, subindex) => (
                                                                        <Table.Row>
                                                                            <Table.Cell textAlign="right" />
                                                                            <Table.Cell
                                                                                className="bold"
                                                                                style={{
                                                                                    // fontSize: "110%",
                                                                                    paddingLeft: "15px"
                                                                                }}
                                                                            >
                                                                                <li>{sub.name}</li>
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign="center">
                                                                                <Form.Input width={16} name={`logbookSP.${sub.id}`}
                                                                                    value={defaultHours} readOnly />
                                                                            </Table.Cell>
                                                                            <Table.Cell textAlign="center">
                                                                                <Icon
                                                                                    link
                                                                                    color="red"
                                                                                    name="trash alternate"
                                                                                    onClick={() => this.handleItemClickDelete('SP', n, num, 'sub', subindex)}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                    ))}
                                                                </Wrap>
                                                            ) : (
                                                                    <Table.Row>
                                                                        <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                                                                        <Table.Cell
                                                                            className="bold"
                                                                        // style={{ fontSize: "110%" }}
                                                                        >
                                                                            <p>{item.name}</p>
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <Form.Input width={16} name={`logbookSP.${item.id}`}
                                                                                value={defaultHours} readOnly />
                                                                        </Table.Cell>
                                                                        <Table.Cell textAlign="center">
                                                                            <Icon
                                                                                link
                                                                                color="red"
                                                                                name="trash alternate"
                                                                                onClick={() => this.handleItemClickDelete('SP', n, num)}
                                                                            />
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                        )}
                                                        {state.addSP.map((item, index) => (
                                                            <Table.Row>
                                                                <Table.Cell textAlign="right"></Table.Cell>
                                                                <Table.Cell >
                                                                    <Form.Group style={{ marginBottom: "0px" }}>
                                                                        <Form.Select
                                                                            onChange={(e, value) => this.onChange('SP', e, value, index)}
                                                                            options={state.options_logbookSP}></Form.Select>
                                                                        <Button
                                                                            animated="vertical"
                                                                            size="mini"
                                                                            color="green"
                                                                            // loading={state.loadingUp}
                                                                            onClick={() => this.pushData('SP', n)}
                                                                        >
                                                                            <Button.Content hidden>Update</Button.Content>
                                                                            <Button.Content visible>
                                                                                <Icon name="check" />
                                                                            </Button.Content>
                                                                        </Button>
                                                                    </Form.Group>
                                                                </Table.Cell>
                                                                <Table.Cell textAlign="right">
                                                                    <Form.Input width={16} name={`logbookSPAdd.${index.id}`}
                                                                        value={defaultHours} readOnly />
                                                                </Table.Cell>
                                                                <Table.Cell textAlign="center">
                                                                    <Icon
                                                                        link
                                                                        color="red"
                                                                        name="trash alternate"
                                                                        onClick={() => this.handleAddItemClickDelete('SP', index)}
                                                                    />
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                        <Table.Row>
                                                            <Table.Cell className="footer-table" textAlign="right"></Table.Cell>
                                                            <Table.Cell className="footer-table"></Table.Cell>
                                                            <Table.Cell className="footer-table"></Table.Cell>
                                                            <Table.Cell className="footer-table" textAlign="right">
                                                                <Button
                                                                    color="blue"
                                                                    size="small"
                                                                    compact
                                                                    type="button"
                                                                    content="Add"
                                                                    onClick={() => this.handleItemClickAddSP('logbookSP')}
                                                                />
                                                            </Table.Cell>

                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Form>
                                            {/* </Segment> */}
                                        </Grid.Column>
                                    </Grid.Row>
                                )
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button
                                compact
                                floated="left"
                                type="submit"
                                content="Save"
                                color="green"
                                onClick={() => this.onSave(state.instructor, state.student)}
                            />
                            <Button
                                floated="right"
                                type="button"
                                content="Cancel"
                                color="green"
                                basic
                                compact
                                onClick={() => this.props.history.push({
                                    pathname: '/FlightHourRecord'
                                })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {/* <ModalForm
                    // icon='add user'
                    content='Logbook'
                    open={state.open}
                    close={this.onClose}  >
                    <FormFlightHoursRecord
                        loading={state.loading}
                        defaultTime={state.defaultTime}
                        logbookIP={state.logbookIP}
                        logbookSP={state.logbookSP}
                        onSubmit={(ip, sp) => this.onSave(ip, sp)}
                        onCancel={this.onClose}
                    />
                </ModalForm> */}

            </Segment>
        )
    }
}
// export default withRouter(FlightHourRecord)



FlightHourRecord = reduxForm({
    // a unique name for the form
    form: 'flighthourrecord',
    // validate,
    // enableReinitialize: true
})(FlightHourRecord)

const selector = formValueSelector('flighthourrecord')
FlightHourRecord = connect(
    state => {
        const defaultHours = selector(state, 'defaultHours')
        return {
            defaultHours: defaultHours,
        }
    }
)(FlightHourRecord)
export default FlightHourRecord