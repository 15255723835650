import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Table, Checkbox } from "semantic-ui-react";
import Wrap from "../../components/wrap";

class PerformanceReport2 extends Component {
  componentDidMount() { }

  render() {
    let { data, checked, locationText } = this.props;
    return (
      <Table celled structured compact textAlign="center">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              rowSpan="2"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              A/C Reg.
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="2"
              width="1"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              Location <br />
              {locationText}
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="2"
              width="1"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              A/C status for schedule
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="8"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              On Schedule
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="2"
              width="1"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              Daily
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="2"
              width="1"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              กลางคืน
            </Table.HeaderCell>
            <Table.HeaderCell
              rowSpan="2"
              width="4"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              Remark
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell
              colSpan="4"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              เช้า
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="4"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              บ่าย
            </Table.HeaderCell>
            <Table.HeaderCell style={{ backgroundColor: "rgb(46,117,181)" }}>
              FLIGHT
            </Table.HeaderCell>
            <Table.HeaderCell style={{ backgroundColor: "rgb(46,117,181)" }}>
              LAND
            </Table.HeaderCell>
            <Table.HeaderCell style={{ backgroundColor: "rgb(46,117,181)" }}>
              FLIGHT
            </Table.HeaderCell>
            <Table.HeaderCell style={{ backgroundColor: "rgb(46,117,181)" }}>
              LAND
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell
              colSpan="16"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              <h5>C-172</h5>
            </Table.Cell>
          </Table.Row>
          {data &&
            data.singleEngines.map((item, index) => (
              <Table.Row>
                {item.isActive === "false" ? (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "singleEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                ) : item.location ? (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "singleEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell className="bold">
                      {" "}
                      {item.isActive === "true" ? (
                        <p>OK</p>
                      ) : item.isActive === "half" ? (
                        <p
                          style={{
                            // color: "rgb(46 117 181)",
                            fontWeight: "bold",
                          }}
                        >
                          HALF
                        </p>
                      ) : (
                        item.isActive === "disable" && (
                          <p
                            style={{
                              color: "rgb(224, 102, 102)",
                              fontWeight: "bold",
                            }}
                          >
                            GND
                          </p>
                        )
                      )}
                    </Table.Cell>
                    {item.morning.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "singleEngines",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "singleEngines",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.morning[indexTime] === true}
                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "singleEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}
                    {item.evening.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "singleEngines",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "singleEngines",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.evening[indexTime] === true}

                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "singleEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}

                    <Table.Cell>
                      {item.hours === "00:00" ? "" : item.hours}
                    </Table.Cell>
                    <Table.Cell>{item.land === 0 ? "" : item.land}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                ) : (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "singleEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{
                        backgroundColor: "rgb(214, 220, 228)",
                      }}
                    >
                      {item.remark}
                    </Table.Cell>
                  </Wrap>
                )}
                {/* {item.isActive === "true" ? (
                  item.location ? (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "singleEngines",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell className="bold">OK</Table.Cell>
                      {item.morning.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "singleEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "singleEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "singleEngines",
                                  index,
                                  "morning",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}
                      {item.evening.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "singleEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "singleEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "singleEngines",
                                  index,
                                  "evening",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}

                      <Table.Cell>
                        {item.hours === "00:00" ? "" : item.hours}
                      </Table.Cell>
                      <Table.Cell>
                        {item.land === 0 ? "" : item.land}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{item.remark}</Table.Cell>
                    </Wrap>
                  ) : (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "singleEngines",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{
                          backgroundColor: "rgb(214, 220, 228)",
                        }}
                      >
                        {item.remark}
                      </Table.Cell>
                    </Wrap>
                  )
                ) : (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "singleEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                )} */}
              </Table.Row>
            ))}
          <Table.Row>
            <Table.Cell className="bold" textAlign="left">
              Total
            </Table.Cell>
            <Table.Cell className="bold">
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalLocation}
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalCheck}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.singleEngines
                ? data.summary.totalHourSingle
                : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.singleEngines
                ? data.summary.totalLandSingle
                : "0"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.singleEngines
                ? data.summary.totalHourSingle
                : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.singleEngines
                ? data.summary.totalLandSingle
                : "0"}
            </Table.Cell>
            <Table.Cell> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2" textAlign="left" className="bold">
              Period available
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data && data.summary && data.summary.singleEngines
                ? data.summary.singleEngines.totalPeriod
                : 0}
            </Table.Cell>
            <Table.Cell colSpan="2" className="bold">
              Period
            </Table.Cell>
            <Table.Cell colSpan="2" className="bold">
              GND
            </Table.Cell>
            <Table.Cell className="bold">ผู้อนุมัติ</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">OK</Table.Cell>
            <Table.Cell>
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalOK}
            </Table.Cell>
            <Table.Cell className="bold">
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalOK}
            </Table.Cell>
            <Table.Cell
              colspan="4"
              className="bold"
              style={{ backgroundColor: "rgb(244, 176, 131)" }}
            >
              FMC C-172
            </Table.Cell>
            <Table.Cell
              colspan="4"
              className="bold"
              style={{
                backgroundColor: "rgb(244, 176, 131)",
              }}
            >
              Performance Sch.
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalLocation * 8}
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalGND * 8}
            </Table.Cell>
            <Table.Cell rowSpan="2"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">GND</Table.Cell>
            <Table.Cell>
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalGND}
            </Table.Cell>
            <Table.Cell className="bold">
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalGND}
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.summary &&
                  data.summary.singleEngines &&
                  data.summary.singleEngines.totalLocation &&
                  data.summary.singleEngines.totalLocation !== 0
                  ? (
                    (data.summary.singleEngines.totalPeriod /
                      (data.summary.singleEngines.totalLocation * 8)) *
                    100
                  ).toFixed(2)
                  : "0"}
                %
              </h1>
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.summary &&
                  data.summary.singleEngines &&
                  data.summary.singleEngines.totalCheck &&
                  data.summary.singleEngines.totalCheck !== 0
                  ? (
                    100 -
                    ((data.summary.singleEngines.totalPeriod -
                      data.summary.singleEngines.totalCheck) /
                      data.summary.singleEngines.totalPeriod) *
                    100
                  ).toFixed(2)
                  : "0"}
                %
              </h1>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">HALF</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.singleEngines
                ? data.summary.singleEngines.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {data && data.summary && data.summary.singleEngines
                ? data.summary.singleEngines.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              {" "}
              100%{" "}
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              {data &&
                data.summary &&
                data.summary.singleEngines &&
                data.summary.singleEngines.totalLocation &&
                data.summary.singleEngines.totalLocation !== 0
                ? (
                  100 -
                  (data.summary.singleEngines.totalPeriod /
                    (data.summary.singleEngines.totalLocation * 8)) *
                  100
                ).toFixed(2)
                : "0"}
              %
            </Table.Cell>
            <Table.Cell>(ตำแหน่ง) หัวหน้าส่วน/ผู้จัดการ </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell
              colSpan="16"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              <h5>DA-42</h5>
            </Table.Cell>
          </Table.Row>
          {data &&
            data.multiEngines.map((item, index) => (
              <Table.Row>
                {item.isActive === "false" ? (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "multiEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> {item.remark}</Table.Cell>
                  </Wrap>
                ) : item.location ? (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "multiEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell className="bold">
                      {" "}
                      {item.isActive === "true" ? (
                        <p>OK</p>
                      ) : item.isActive === "half" ? (
                        <p
                          style={{
                            // color: "rgb(46 117 181)",
                            fontWeight: "bold",
                          }}
                        >
                          HALF
                        </p>
                      ) : (
                        item.isActive === "disable" && (
                          <p
                            style={{
                              color: "rgb(224, 102, 102)",
                              fontWeight: "bold",
                            }}
                          >
                            GND
                          </p>
                        )
                      )}
                    </Table.Cell>
                    {item.morning.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "multiEngines",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "multiEngines",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.morning[indexTime] === true}

                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "multiEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}
                    {item.evening.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "multiEngines",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "multiEngines",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.evening[indexTime] === true}

                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "multiEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}
                    <Table.Cell>
                      {item.hours === "00:00" ? "" : item.hours}
                    </Table.Cell>
                    <Table.Cell>{item.land === 0 ? "" : item.land}</Table.Cell>{" "}
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                ) : (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "multiEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{
                        backgroundColor: "rgb(214, 220, 228)",
                      }}
                    >
                      {item.remark}
                    </Table.Cell>
                  </Wrap>
                )}
                {/* {item.isActive === "true" ? (
                  item.location ? (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "multiEngines",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell className="bold">OK</Table.Cell>
                      {item.morning.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "multiEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "multiEngines",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "multiEngines",
                                  index,
                                  "morning",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}
                      {item.evening.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "multiEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "multiEngines",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "multiEngines",
                                  index,
                                  "evening",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}
                      <Table.Cell>
                        {item.hours === "00:00" ? "" : item.hours}
                      </Table.Cell>
                      <Table.Cell>
                        {item.land === 0 ? "" : item.land}
                      </Table.Cell>{" "}
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{item.remark}</Table.Cell>
                    </Wrap>
                  ) : (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "multiEngines",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{
                          backgroundColor: "rgb(214, 220, 228)",
                        }}
                      >
                        {item.remark}
                      </Table.Cell>
                    </Wrap>
                  )
                ) : (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "multiEngines",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> {item.remark}</Table.Cell>
                  </Wrap>
                )} */}
              </Table.Row>
            ))}
          <Table.Row>
            <Table.Cell className="bold" textAlign="left">
              Total
            </Table.Cell>
            <Table.Cell className="bold">
              {data &&
                data.summary &&
                data.summary.multiEngines &&
                data.summary.multiEngines &&
                data.summary.multiEngines.totalLocation}
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data &&
                data.summary &&
                data.summary.multiEngines &&
                data.summary.multiEngines.totalCheck}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalHourMulti : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalLandMulti : "0"}
            </Table.Cell>{" "}
            <Table.Cell>
              {data && data.summary ? data.summary.totalHourMulti : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalLandMulti : "0"}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2" textAlign="left" className="bold">
              Period available
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalPeriod
                : 0}
            </Table.Cell>
            <Table.Cell className="bold" colSpan="2">
              Period
            </Table.Cell>
            <Table.Cell className="bold" colSpan="2">
              GND
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">OK</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalOK
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalOK
                : 0}
            </Table.Cell>
            <Table.Cell
              colSpan="4"
              className="bold"
              style={{ backgroundColor: "rgb(244, 176, 131)" }}
            >
              FMC C-172
            </Table.Cell>
            <Table.Cell
              colSpan="4"
              className="bold"
              style={{ backgroundColor: "rgb(244, 176, 131)" }}
            >
              Performance Sch.
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.multiEngines &&
                data.summary.multiEngines.totalLocation * 8}
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.multiEngines &&
                data.summary.multiEngines.totalGND * 8}
            </Table.Cell>
            <Table.Cell />
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">GND</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalGND
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {" "}
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalGND
                : 0}
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.summary &&
                  data.summary.multiEngines &&
                  data.summary.multiEngines.totalLocation &&
                  data.summary.multiEngines.totalLocation !== 0
                  ? (
                    (data.summary.multiEngines.totalPeriod /
                      (data.summary.multiEngines.totalLocation * 8)) *
                    100
                  ).toFixed(2)
                  : "0"}
                %
              </h1>
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.summary &&
                  data.summary.multiEngines &&
                  data.summary.multiEngines.totalCheck &&
                  (
                    100 -
                    ((data.summary.multiEngines.totalPeriod -
                      data.summary.multiEngines.totalCheck) /
                      data.summary.multiEngines.totalPeriod) *
                    100
                  ).toFixed(2)}
                %
              </h1>
            </Table.Cell>
            <Table.Cell rowSpan="2"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">HALF</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {data && data.summary && data.summary.multiEngines
                ? data.summary.multiEngines.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              100%
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              {data &&
                data.summary &&
                data.summary.multiEngines &&
                data.summary.multiEngines.totalLocation &&
                data.summary.multiEngines.totalLocation !== 0
                ? (
                  100 -
                  (data.summary.multiEngines.totalPeriod /
                    (data.summary.multiEngines.totalLocation * 8)) *
                  100
                ).toFixed(2)
                : "0"}
              %
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell
              colSpan="16"
              style={{ backgroundColor: "rgb(46,117,181)" }}
            >
              <h5>A320</h5>
            </Table.Cell>
          </Table.Row>
          {data &&
            data.a320 &&
            data.a320.map((item, index) => (
              <Table.Row>
                {item.isActive === "false" ? (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "a320",
                            index
                          )
                        }
                      />{" "}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell> <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                ) : item.location ? (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "a320",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell className="bold">
                      {" "}
                      {item.isActive === "true" ? (
                        <p>OK</p>
                      ) : item.isActive === "half" ? (
                        <p
                          style={{
                            // color: "rgb(46 117 181)",
                            fontWeight: "bold",
                          }}
                        >
                          HALF
                        </p>
                      ) : (
                        item.isActive === "disable" && (
                          <p
                            style={{
                              color: "rgb(224, 102, 102)",
                              fontWeight: "bold",
                            }}
                          >
                            GND
                          </p>
                        )
                      )}
                    </Table.Cell>
                    {item.morning.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "a320",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "a320",
                              index,
                              "morning",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.morning[indexTime] === true}
                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "a320",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}
                    {item.evening.map((time, indexTime) =>
                      time === "disable" ? (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "a320",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                          style={{ backgroundColor: "rgb(224, 102, 102)" }}
                        />
                      ) : (
                        <Table.Cell
                          onDoubleClick={() =>
                            this.props.handleCloseThisTime(
                              "a320",
                              index,
                              "evening",
                              indexTime
                            )
                          }
                        >
                          <Checkbox
                            checked={item.evening[indexTime] === true}

                            onChange={(e, data) =>
                              this.props.handleSelectTime(
                                data,
                                "a320",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          />
                        </Table.Cell>
                      )
                    )}
                    <Table.Cell>
                      {item.hours === "00:00" ? "" : item.hours}
                    </Table.Cell>
                    <Table.Cell>{item.land === 0 ? "" : item.land}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                ) : (
                  <Wrap>
                    <Table.Cell textAlign="left" className="bold">
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "a320",
                            index
                          )
                        }
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{ backgroundColor: "rgb(214, 220, 228)" }}
                    />
                    <Table.Cell
                      style={{
                        backgroundColor: "rgb(214, 220, 228)",
                      }}
                    >
                      {item.remark}
                    </Table.Cell>
                  </Wrap>
                )}
                {/* {item.isActive === "true" ? (
                  item.location ? (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "a320",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell className="bold">OK</Table.Cell>
                      {item.morning.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "a320",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "a320",
                                index,
                                "morning",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "a320",
                                  index,
                                  "morning",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}
                      {item.evening.map((time, indexTime) =>
                        time === "disable" ? (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "a320",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                            style={{ backgroundColor: "rgb(224, 102, 102)" }}
                          />
                        ) : (
                          <Table.Cell
                            onDoubleClick={() =>
                              this.props.handleCloseThisTime(
                                "a320",
                                index,
                                "evening",
                                indexTime
                              )
                            }
                          >
                            <Checkbox
                              onChange={(e, data) =>
                                this.props.handleSelectTime(
                                  data,
                                  "a320",
                                  index,
                                  "evening",
                                  indexTime
                                )
                              }
                            />
                          </Table.Cell>
                        )
                      )}
                      <Table.Cell>
                        {item.hours === "00:00" ? "" : item.hours}
                      </Table.Cell>
                      <Table.Cell>
                        {item.land === 0 ? "" : item.land}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{item.remark}</Table.Cell>
                    </Wrap>
                  ) : (
                    <Wrap>
                      <Table.Cell textAlign="left" className="bold">
                        {item.tailNumber}
                      </Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          checked={item.location}
                          onChange={(e, data) =>
                            this.props.handleChangeAircraftLocation(
                              data,
                              "a320",
                              index
                            )
                          }
                        />
                      </Table.Cell>
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{ backgroundColor: "rgb(214, 220, 228)" }}
                      />
                      <Table.Cell
                        style={{
                          backgroundColor: "rgb(214, 220, 228)",
                        }}
                      >
                        {item.remark}
                      </Table.Cell>
                    </Wrap>
                  )
                ) : (
                  <Wrap>
                    <Table.Cell
                      textAlign="left"
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      {item.tailNumber}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        checked={item.location}
                        onChange={(e, data) =>
                          this.props.handleChangeAircraftLocation(
                            data,
                            "a320",
                            index
                          )
                        }
                      />{" "}
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        color: "rgb(224, 102, 102)",
                        fontWeight: "bold",
                      }}
                    >
                      GND
                    </Table.Cell>
                    <Table.Cell
                      colSpan="8"
                      style={{ backgroundColor: "rgb(224, 102, 102)" }}
                    />
                    <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell> <Table.Cell> </Table.Cell>
                    <Table.Cell> </Table.Cell>
                    <Table.Cell>{item.remark}</Table.Cell>
                  </Wrap>
                )} */}
              </Table.Row>
            ))}
          <Table.Row>
            <Table.Cell className="bold" textAlign="left">
              Total
            </Table.Cell>
            <Table.Cell className="bold">
              {data &&
                data.summary &&
                data.summary.a320 &&
                data.summary.a320 &&
                data.summary.a320.totalLocation}
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data &&
                data.summary &&
                data.summary.a320 &&
                data.summary.a320.totalCheck}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalHourA320 : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalLandA320 : "0"}
            </Table.Cell>{" "}
            <Table.Cell>
              {data && data.summary ? data.summary.totalHourA320 : "00:00"}
            </Table.Cell>
            <Table.Cell>
              {data && data.summary ? data.summary.totalLandA320 : "0"}
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan="2" textAlign="left" className="bold">
              Period available
            </Table.Cell>
            <Table.Cell
              colspan="9"
              className="bold"
              style={{
                backgroundColor: "rgb(214, 220, 228)",
              }}
            >
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalPeriod
                : 0}
            </Table.Cell>
            <Table.Cell className="bold" colSpan="2">
              Period
            </Table.Cell>
            <Table.Cell className="bold" colSpan="2">
              GND
            </Table.Cell>
            <Table.Cell></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">OK</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalOK
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalOK
                : 0}
            </Table.Cell>
            <Table.Cell
              colspan="4"
              className="bold"
              style={{ backgroundColor: "rgb(244, 176, 131)" }}
            >
              FMC C-172
            </Table.Cell>
            <Table.Cell
              colspan="4"
              className="bold"
              style={{ backgroundColor: "rgb(244, 176, 131)" }}
            >
              Performance Sch.
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.a320 &&
                data.summary.a320.totalLocation * 8}
            </Table.Cell>
            <Table.Cell rowSpan="2" colSpan="2">
              {data &&
                data.summary &&
                data.summary.a320 &&
                data.summary.a320.totalGND * 8}
            </Table.Cell>
            <Table.Cell className="bold">เครื่องที่ใช้ได้พรุ่งนี้</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">GND</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalGND
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {" "}
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalGND
                : 0}
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.summary &&
                  data.summary.a320 &&
                  data.summary.a320.totalLocation &&
                  data.summary.a320.totalLocation !== 0
                  ? (
                    (data.summary.a320.totalPeriod /
                      (data.summary.a320.totalLocation * 8)) *
                    100
                  ).toFixed(2)
                  : "0"}
                %
              </h1>
            </Table.Cell>
            <Table.Cell
              colspan="4"
              rowSpan="2"
              className="bold"
              style={{ backgroundColor: "rgb(255, 242, 204)" }}
            >
              <h1>
                {data &&
                  data.a320 &&
                  data.summary &&
                  data.summary.a320 &&
                  data.summary.a320.totalCheck &&
                  data.summary.a320.totalCheck !== 0
                  ? (
                    100 -
                    ((data.summary.a320.totalPeriod -
                      data.summary.a320.totalCheck) /
                      data.summary.a320.totalPeriod) *
                    100
                  ).toFixed(2)
                  : "0"}
                %
              </h1>
            </Table.Cell>
            <Table.Cell rowSpan="2"> </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="bold">HALF</Table.Cell>
            <Table.Cell>
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell className="bold">
              {data && data.summary && data.summary.a320
                ? data.summary.a320.totalHALF
                : 0}
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              100%
            </Table.Cell>
            <Table.Cell colSpan="2" style={{ fontSize: "95%" }}>
              {data &&
                data.summary &&
                data.summary.a320 &&
                data.summary.a320.totalLocation &&
                data.summary.a320.totalLocation !== 0
                ? (
                  100 -
                  (data.summary.a320.totalPeriod /
                    (data.summary.a320.totalLocation * 8)) *
                  100
                ).toFixed(2)
                : "0"}
              %
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default withRouter(PerformanceReport2);
