import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
class TableData extends Component {

    render() {
        return (
            <ReactTable
                style={this.props.styleTB}
                // style={{ fontSize: '95%', backgroundColor: '#fff' }}
                manual
                defaultPageSize={20}
                pageSize={this.props.pageSize}
                page={this.props.page}
                pages={this.props.pages}
                loading={this.props.loading}
                className="-highlight"
                data={this.props.data}
                columns={this.props.columns}                // loading={true}
                onPageChange={(pageIndex) => this.props.onPageChange(pageIndex)}
                onPageSizeChange={(pageSize, pageIndex) => this.props.onPageSizeChange(pageSize, pageIndex)}
            // getTdProps={(state, rowInfo, column, instance) => {
            //     return {
            //         onClick: e => {
            //             if (rowInfo) {
            //                 this.props.rowClick(rowInfo.original)
            //             }
            //             // else console.log("no")
            //         }
            //     };
            // }}
            />
        )
    }
}
export default TableData
