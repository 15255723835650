import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Breadcrumb,
  Modal,
  Button,
} from "semantic-ui-react";
import * as config from "../../config";
import { DateInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import { genFlightplan, exportFlightplan } from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class FlightPlanReport extends Component {
  state = {
    date: moment().format("dddd, MMMM DD, YYYY"),
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    departureText: "KL15",
    options_departure: [
      {
        key: "1",
        text: "KL15",
        value: "1",
        icon: "plane",
        description: "",
      },
      {
        key: "2",
        text: "VTUQ",
        value: "2",
        icon: "plane",
        description: "",
      },
    ],
    column: [
      {
        Header: "CALLSIGN",
        accessor: "callSign",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.callSign}</span>
        ),
      },
      {
        Header: "SQUAWK",
        accessor: "squawk",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.squawk}</span>
        ),
      },
      {
        Header: "REG.",
        accessor: "reg",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.reg}</span>,
      },
      {
        Header: "TYPE",
        accessor: "type",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.type}</span>,
      },
      {
        Header: "FLIGHT RULE",
        accessor: "flightRules",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.flightRules}</span>
        ),
      },
      {
        Header: "DESTINATION",
        accessor: "destination",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.destination}</span>
        ),
      },
      {
        Header: "ETD*",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.start}</span>,
      },
      {
        Header: "ETA*",
        accessor: "end",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.end}</span>,
      },
      {
        Header: "FIRST",
        accessor: "first",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.first}</span>,
      },
      {
        Header: "BAC",
        accessor: "bac",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.bac}</span>,
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { date } = this.state;
    let qString =
      "?date=" + moment(date, "dddd, MMMM DD, YYYY").format("YYYY-MM-DD");
    exportFlightplan(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "FlightPlan_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "FlightPlan_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const { date, departureText } = this.state;
    let qString =
      "?date=" +
      moment(date, "dddd, MMMM DD, YYYY").format("YYYY-MM-DD") +
      "&departure=" +
      departureText;
    this.getData(qString);
  };
  handleChangeDate = (event, { name, value }) => {
    console.log(name, value);
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genFlightplan(qString).then((res) => {
      if (res && res.status === 200) {
        console.log("genFlightPlan", res.data);
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date, departureText } = this.state;
    let qString =
      "?date=" +
      moment(date, "dddd, MMMM DD, YYYY").format("YYYY-MM-DD") +
      "&departure=" +
      departureText;
    this.getData(qString);
  }

  handleChangeDeparture = (e, data) => {
    let { departure } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    departure = result;

    this.setState({
      departure,
      departureText: `${result.text}`,
    });
  };
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Flight Plan">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Flight Plan</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
              <DateInput
                name="date"
                placeholder="Select Date"
                hideMobileKeyboard
                dateFormat="dddd, MMMM DD, YYYY"
                iconPosition="right"
                value={state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Dropdown
              icon={null}
              clearable
              // width="3"
              style={{ width: "100%", marginRight: "30px", height: "100%" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Departure : <b>{state.departureText || "-"}</b>
                </Button>
              }
              options={state.options_departure}
              selectOnBlur={false}
              onChange={this.handleChangeDeparture}
            />
            <MainButton
              size="small"
              text="Filter"
              loading={state.dataLoading}
              onClick={this.onClickFilterDate}
            />
          </Form.Group>
        </Form>
        {/*<Table compact celled striped >
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell className='header-table'>CALLSIGN</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>SQUAWK</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>REG.</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>TYPE</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>FLIGHT RULE</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>DESTINATION</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>ETD*</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>ETA*</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>FIRST</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>BAC</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {state.dataLoading ? (
              <Table.Body>
                <Table.Cell colSpan={10}>
                  <div style={{ textAlign: "center" }}>
                    <Icon loading name="circle notch" />
                    <br />
                    Loading..
                  </div>
                </Table.Cell>
              </Table.Body>
            ): (
              <Table.Body>
                {state.data.map(tmp =>
                  <Table.Row textAlign='center'>
                    <Table.Cell>{tmp.callSign}</Table.Cell>
                    <Table.Cell>{tmp.squawk}</Table.Cell>
                    <Table.Cell>{tmp.reg}</Table.Cell>
                    <Table.Cell>{tmp.type}</Table.Cell>
                    <Table.Cell>{tmp.flightRules}</Table.Cell>
                    <Table.Cell>{tmp.destination}</Table.Cell>
                    <Table.Cell>{tmp.start}</Table.Cell>
                    <Table.Cell>{tmp.end}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            )
          }
          </Table>*/}
        <TableFixedColumns
          data={state.data}
          columns={state.column}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(FlightPlanReport);
