import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { change } from "redux-form";
import {
  Form,
  Segment,
  Button,
  Icon,
  Image,
  List,
  Loader,
  Header,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import TableList from "../../components/table";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";
import {
  getNews,
  updateNews,
  postNews,
  deleteNews,
  getAlert,
  postAlert,
  updateAlert,
  deleteAlert,
  getClass,
  getRoles,
  getListAlertRead,
} from "./service";
import Formnews from "./newsForm";
import Wrap from "../../components/wrap";

class News extends Component {
  state = {
    type: "Alert",
    sort : "desc",
    sortText:"Newest first",
    items: null,
    data: [],
    dataAlertRead: null,
    loading: true,
    openAdd: false,
    openAlertRead: false,
    currentData: null,
    options_class: [],
    options_roles: [],
    column: [],
    columnAlert: [
      {
        Header: "Priority",
        accessor: "priority",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Icon
            color={
              cellInfo.original.priority === 1
                ? "red"
                : cellInfo.original.priority === 2
                ? "yellow"
                : "green"
            }
            name="alarm "
          />
        ),
        width: 60,
      },
      {
        Header: "Topic",
        accessor: "topic",
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <span
              className="bold"
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickEdit(cellInfo.original)}
            >
              {cellInfo.original.topic}
            </span>
            <br />
          </Wrap>
        ),
      },
      {
        Header: "Detail",
        accessor: "detail",
        // style: { textAlign: "center" },
        sortable: false,
        // Cell: cellInfo => <p>xxxxx</p>,
        // width: 60
      },
      {
        Header: "Image",
        accessor: "imageUrl",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.imageUrl && (
            <Image
              centered
              src={cellInfo.original.imageUrl + "?time=" + new Date()}
              avatar
              size="mini"
            />
          ),
        // width: 60
      },
      {
        Header: "Attached file",
        accessor: "fileUrl",
        // style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.fileUrl &&
          cellInfo.original.fileUrl.map((item) => {
            return (
              <div>
                <a href={item + "?time=" + new Date()} target="_blank">
                  Attached file
                </a>
                <br />
              </div>
            );
          }),
        // width: 60
      },
      {
        Header: "Link",
        accessor: "link",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.link && (
            <a href={cellInfo.original.link} target="_blank">
              Link
            </a>
          ),
        width: 60,
      },
      {
        Header: "Display For",
        accessor: "displayFor",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.displayFor &&
          cellInfo.original.displayFor.map((item) => <div>{item}</div>),
        width: 150,
      },
      {
        Header: "Created at",
        accessor: "created_at",
        style: { textAlign: "center" },
        sortable: false,
        // Cell: cellInfo => <p>xxxxx</p>,
        // width: 100
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <Wrap>
            <Icon
              link
              name="list alternate"
              onClick={() => this.onClickAlertRead(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </Wrap>
        ),
      },
    ],
    columnNews: [
      {
        Header: "Topic",
        accessor: "topic",
        // style: { textAlign: "center" },
        sortable: false,
        // Cell: cellInfo => <p>xxxxx</p>,
        // width: 60
        Cell: (cellInfo) => (
          <Wrap>
            <span
              className="bold"
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickEdit(cellInfo.original)}
            >
              {cellInfo.original.topic}
            </span>
            <br />
          </Wrap>
        ),
      },
      {
        Header: "Detail",
        accessor: "detail",
        // style: { textAlign: "center" },
        sortable: false,
        // Cell: cellInfo => <p>xxxxx</p>,
        // width: 60
      },
      {
        Header: "Image",
        accessor: "imageUrl",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.imageUrl && (
            <Image
              centered
              src={cellInfo.original.imageUrl + "?time=" + new Date()}
              avatar
              size="mini"
            />
          ),
        // width: 60
      },
      {
        Header: "Attached file",
        accessor: "fileUrl",
        // style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.fileUrl && (
            <a
              href={cellInfo.original.fileUrl + "?time=" + new Date()}
              target="_blank"
            >
              Attached file
            </a>
          ),
        // width: 60
      },
      {
        Header: "Link",
        accessor: "link",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) =>
          cellInfo.original.link && (
            <a href={cellInfo.original.link} target="_blank">
              Link
            </a>
          ),
        width: 60,
      },
      {
        Header: "Created at",
        accessor: "created_at",
        style: { textAlign: "center" },
        sortable: false,
        // Cell: cellInfo => <p>xxxxx</p>,
        // width: 60
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <Wrap>
            <Wrap>
              <Icon
                link
                name="edit"
                onClick={() => this.onClickEdit(cellInfo.original)}
              />
              &nbsp; &nbsp;
              <Icon
                link
                color="red"
                name="trash alternate"
                onClick={() => this.onClickDelete(cellInfo.original)}
              />
            </Wrap>
          </Wrap>
        ),
      },
    ],
  };
  getNews = () => {
    this.setState({ loading: true, column: this.state.columnNews });
    getNews('?_sort=created_at&_order='+ this.state.sort).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };
  getAlert = () => {
    this.setState({ loading: true, column: this.state.columnAlert });
    getAlert('?_sort=created_at&_order='+ this.state.sort).then((res) => {
      // console.log(res);
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };

  getClass = () => {
    getClass().then((res) => {
      if (res && res.status === 200) {
        let options_class = [];
        res.data.map((item) =>
          options_class.push({ key: item.id, value: item.id, text: item.name })
        );
        this.setState({ options_class });
      }
    });
  };
  getRoles = () => {
    getRoles().then((res) => {
      if (res && res.status === 200) {
        let options_roles = [];
        res.data.map((item) =>
          options_roles.push({ key: item.id, value: item.id, text: item.name })
        );
        this.setState({ options_roles });
      }
    });
  };
  onClickAlertRead = (data) => {
    // console.log(data);
    getListAlertRead(data.id).then((res) => {
      if (res && res.status === 200) {
        // console.log(res.data);
        this.setState(
          {
            dataAlertRead: res.data,
          },
          () => {
            this.setState({ openAlertRead: true });
          }
        );
      }
    });
  };
  onClickEdit = (data) => {
    // console.log('onClickEdit',data);
    if (data.type === "Alert") this.setState({ content: "Edit Alert" });
    else this.setState({ content: "Edit News" });
    data.imageCurrentUrl = data.imageUrl;
    data.fileCurrentUrl = data.fileUrl;
    data.image = data.imageUrl;
    data.file = data.fileUrl;
    data.link = data.link;
    this.setState(
      {
        currentData: data,
      },
      () => {
        this.setState({ openAdd: true });
      }
    );
  };
  onClickDelete = (value) => {
    this.setState({
      openConfirm: true,
      currentData: value,
    });
  };
  onClickAdd = () => {
    this.setState({ openAdd: true, content: "Add Alert & News" });
  };
  onClickCancel = () => {
    this.setState({
      openAdd: false,
      currentData: null,
      content: null,
      openAlertRead: false,
      dataAlertRead: null,
      errormsg: null,
    });
  };
  saveData = (data) => {
    // console.log('saveData',data);
    this.setState({ loading: true, errormsg: null });
    if (data.type === "News") {
      var formData = new FormData();
      formData.append("topic", data.topic);
      formData.append("detail", data.detail || "");
      formData.append("link", data.link || "");
      if (typeof data.image === "object")
        formData.append("image", data.image[0]);
      else formData.append("image", data.image);

      if (data.file) {
        if (typeof data.file === "object") {
          for (let i = 0; i < data.file.length; i++) {
            formData.append("file[]", data.file[i]);
          }
        } else formData.append("file", data.file);
      } else {
        formData.append("file", null);
      }

      // if (typeof data.file === "object") formData.append("file", data.file[0]);
      // else formData.append("file", data.file);

      postNews(formData).then((res) => {
        if (res && res.status === 201) {
          this.onClickCancel();
          this.getNews();
        } else if (res && res.status === 400) {
          this.setState({ errormsg: res.data.errors, loading: false });
        }
      });
    } else if (data.type === "Alert") {
      var formData = new FormData();
      formData.append("topic", data.topic);
      formData.append("detail", data.detail || "");
      formData.append("link", data.link || "");
      formData.append("priority", data.priority);
      formData.append("roles", data.roles);
      formData.append("class", data.class);
      if (typeof data.image === "object")
        formData.append("image", data.image[0]);
      else formData.append("image", data.image);

      if (data.file) {
        if (typeof data.file === "object") {
          for (let i = 0; i < data.file.length; i++) {
            formData.append("file[]", data.file[i]);
          }
        } else formData.append("file", data.file);
      } else {
        formData.append("file", null);
      }

      // if (typeof data.file === "object") formData.append("file", data.file[0]);
      // else formData.append("file", data.file);
      postAlert(formData).then((res) => {
        if (res && res.status === 201) {
          this.onClickCancel();
          this.getAlert();
        } else if (res && res.status === 400) {
          this.setState({ errormsg: res.data.errors, loading: false });
        }
      });
    }
  };
  saveEditData = (data) => {
    // console.log("saveEditData", data);
    this.setState({ loading: true, errormsg: null });
    if (data.type === "News") {
      var formData = new FormData();
      formData.append("id", data.id);
      formData.append("topic", data.topic);
      formData.append("detail", data.detail || "");
      formData.append("link", data.link || "");

      if (data.image) {
        if (typeof data.image === "object")
          formData.append("image", data.image[0]);
        else formData.append("image", data.image);
      } else {
        formData.append("image", null);
      }
      // if (data.file) {
      //   if (typeof data.file === "object")
      //     formData.append("file", data.file[0]);
      //   else formData.append("file", data.file);
      // } else {
      //   formData.append("file", null);
      // }
      formData.append("file", null);
      if (data.file) {
        if (typeof data.file === "object") {
          for (let i = 0; i < data.file.length; i++) {
            formData.append("file[]", data.file[i]);
          }
        } else formData.append("file", data.file);
      }

      updateNews(data.id, formData).then((res) => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getNews();
        } else if (res && res.status === 400) {
          this.setState({ errormsg: res.data.errors, loading: false });
        }
      });
    } else if (data.type === "Alert") {
      var formData = new FormData();
      formData.append("id", data.id);
      formData.append("topic", data.topic);
      formData.append("detail", data.detail || "");
      formData.append("link", data.link || "");

      formData.append("priority", data.priority);
      formData.append("roles", data.roles);
      formData.append("class", data.class);
      if (data.image) {
        if (typeof data.image === "object")
          formData.append("image", data.image[0]);
        else formData.append("image", data.image);
      } else {
        formData.append("image", null);
      }

      formData.append("file", null);
      if (data.file) {
        if (typeof data.file === "object") {
          for (let i = 0; i < data.file.length; i++) {
            formData.append("file[]", data.file[i]);
          }
        } else formData.append("file", data.file);
      }

      // if (data.file) {
      //   if (typeof data.file === "object")
      //     formData.append("file", data.file[0]);
      //   else formData.append("file", data.file);
      // } else {
      //   formData.append("file", null);
      // }

      updateAlert(data.id, formData).then((res) => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getAlert();
        } else if (res && res.status === 400) {
          this.setState({ errormsg: res.data.errors, loading: false });
        }
      });
    }
  };
  deleteData = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    if (currentData.type === "Alert") {
      deleteAlert(currentData.id).then((res) => {
        if (res && res.status === 200) {
          this.getAlert();
          this.setState({ openConfirm: false, currentData: null });
        }
        this.setState({ loading: false });
      });
    } else {
      deleteNews(currentData.id).then((res) => {
        if (res && res.status === 200) {
          this.getNews();
          this.setState({ openConfirm: false, currentData: null });
        }
        this.setState({ loading: false });
      });
    }
  };
  handleChangeType = (e, data) => {
    this.setState({ type: data.value, data: [] });
    if (data.value === "Alert") {
      this.getAlert();
    } else this.getNews();
  };
  handleChangeSort = (e, data) => {
    this.setState({
        sort : data.value === 'Newest first' ? 'desc' : 'asc', sortText: data.value, data: []
    }, () => {
      if (this.state.type === "Alert") {
        this.getAlert();
      } else this.getNews();
    });
  };
  componentDidMount() {
    this.getAlert();
    this.getClass();
    this.getRoles();
  }

  render() {
    const state = this.state;
    // console.log('state.currentData',state.currentData);
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Alert & News">
            <MainButton
              size="small"
              onClick={this.onClickAdd}
              text="Add Alert & News"
            />
          </HeaderWithMenu>
          <Form>
            <Form.Group>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='3'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Filter by : <b>{state.type}</b>
                  </Button>
                }
                options={[
                  { key: "Alert", value: "Alert", text: "Alert" },
                  { key: "Notice", value: "News", text: "News" },
                ]}
                defaultValue={"Alert"}
                selectOnBlur={false}
                onChange={this.handleChangeType}
              />
            <Form.Dropdown
              icon={null}
              fluid
              floating
              // width='3'
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Sort by : <b>{state.sortText}</b>
                </Button>
              }
              options={[
                { key: "asc", value: "Oldest first", text: "Oldest first" },
                { key: "desc", value: "Newest first", text: "Newest first" },
              ]}
              defaultValue={"Newest first"}
              selectOnBlur={false}
              onChange={this.handleChangeSort}
            />
          </Form.Group>
          </Form>
          <TableList
            data={state.data}
            columns={state.column}
            loading={state.loading}
            // rowClick={this.onClickProfile}
          />
          <ModalForm
            open={state.openAdd}
            icon="newspaper outline"
            size="mini"
            content={state.content}
            close={this.onClickCancel}
          >
            <Formnews
              // style={{ marginBottom: 0 }}
              loading={state.loading}
              errormsg={state.errormsg}
              options_roles={state.options_roles}
              options_class={state.options_class}
              initialValues={state.currentData ? state.currentData : null}
              addNew={state.currentData ? false : true}
              onSubmit={state.currentData ? this.saveEditData : this.saveData}
              onCancel={this.onClickCancel}
            />
          </ModalForm>
          <ModalForm
            open={state.openAlertRead}
            icon="list alternate"
            content="Read Alert"
            close={this.onClickCancel}
          >
            {state.dataAlertRead ? (
              <Wrap>
                <Header as="h4">
                  Display For {state.dataAlertRead.displayFor.toString()}
                </Header>
                {/*<List className='bold'>
                  <List.Item>Topic: {state.dataAlertRead.topic || '-'}</List.Item>
                  <List.Item>Detail: {state.dataAlertRead.detail || '-'}</List.Item>
                  <List.Item>Priority: {state.dataAlertRead.priority || '-'}</List.Item>
                  <List.Item>File:
                    {state.dataAlertRead.fileUrl ?
                      <span>
                        <Icon name='paperclip'/>
                        <a href={state.dataAlertRead.fileUrl} download target="_blank">Download File</a>
                      </span>
                      :'-'
                    }
                  </List.Item>
                  <List.Item>Image:
                    {state.dataAlertRead.imageUrl ?
                      <span>
                        <Image  src={state.dataAlertRead.imageUrl+'?time='+ new Date()} size='small'/>
                      </span>
                      :'-'
                    }
                  </List.Item>

                </List>*/}
                <List className="bold">
                  {state.dataAlertRead.role.length > 0 &&
                    state.dataAlertRead.role.map((role) => (
                      <List.Item style={{ marginLeft: 15 }}>
                        {role.result ? (
                          <List.Icon name="check" color="green" />
                        ) : (
                          <List.Icon name="times" color="red" />
                        )}
                        <List.Content>{role.name}</List.Content>
                      </List.Item>
                    ))}
                  {state.dataAlertRead.class.length > 0 &&
                    state.dataAlertRead.class.map((cls) => (
                      <List.Item style={{ marginLeft: 15 }}>
                        {cls.result ? (
                          <List.Icon name="check" color="green" />
                        ) : (
                          <List.Icon name="times" color="red" />
                        )}
                        <List.Content>{cls.name}</List.Content>
                      </List.Item>
                    ))}
                </List>
              </Wrap>
            ) : (
              <Loader active inline="centered" content="Loading" />
            )}
          </ModalForm>
        </Segment>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={state.type}
          onClose={() =>
            this.setState({ openConfirm: false, currentData: null })
          }
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(News);
