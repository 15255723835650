import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Form, Button, Icon, Image } from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import * as config from "../../config";
import ImageCard from "../../components/imagecard";
import MainButton from "../../components/mainbutton";
import Wrap from "../../components/wrap";
// import TableList from "../../components/table";
import TableList from "../../components/tablePerPage";
import { getStudentListBySearch, getAllClass,
          getStudentType, getAllCourse } from "../Student/service";
import DropdownCheckbox from '../../components/dropdownCheckbox'

class Portfolio extends Component {
  state = {
    current_page: 0,
    per_page: 10,
    last_page: 1,
    data: [],
    rawData: [],
    loading: true,
    options_class: [],
    options_course: [],
    options_studenttype: [],
    filterClassByText: 'All Class',
    filterStdtypeByText: 'All Student type',
    filterCourseByText: 'All Course',
    search: {
        status: 'Active',
        group: '',
        sort: { key: 'firstNameEng', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' },
    },
    sortText: 'Name [ASC]',
    options_sortby: [
        { key: 'firstNameEng', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC' },
        { key: 'firstNameEng', text: 'Name', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
    ],
    column: [
      {
        Header: "Image",
        accessor: "image",
        style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <Image
            avatar
            size="mini"
            verticalAlign="middle"
            src={cellInfo.original.image}
            onError={e => {
              e.target.onerror = null;
              e.target.src =
                "https://app.flightschedulepro.com/images/instructor-default.gif";
            }}
          />
        ),
        width: 60
      },
      {
        Header: "Name",
        accessor: "description",
        sortable: false,
        width: 280,
        Cell: cellInfo => (
          <Wrap>
            <span
              className="bold"
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickProfile(cellInfo.original)}
            >
              {cellInfo.original.description}
            </span>
            <br/>
            <p>{cellInfo.original.nameTH || '-'}</p>
          </Wrap>
        )
      },
      // {
      //   Header: 'Advisor',
      //   accessor: 'soloStudent',
      //   sortable: false,
      //   Cell: cellInfo => (
      //     <Wrap>
      //       <span className="" >
      //         {
      //           cellInfo.original.soloStudent &&
      //           <p>{cellInfo.original.soloStudent.firstName} {cellInfo.original.soloStudent.lastName}</p>
      //
      //         }
      //       </span>
      //     </Wrap>
      //   ),
      //   // width: 150
      // },
      {
        Header: "Student type",
        accessor: "description",
        sortable: false,
        Cell: cellInfo => (
            <Wrap>
                <span className="" >
                    {
                        cellInfo.original
                            ? cellInfo.original.studentType
                                ? cellInfo.original.studentType.name
                                : null
                            : null
                    }
                </span>
            </Wrap>
        ),
        width: 100
      },
      {
        Header: "Course",
        accessor: "course",
        style: { textAlign: "left" },
        sortable: false,
        Cell: cellInfo => (
          // console.log(cellInfo)
          <span>
            {
                cellInfo.original
                    ? cellInfo.original.course
                        ? <span>{cellInfo.original.course.name} ({cellInfo.original.course.desc})</span>
                        : null
                    : null
            }
          </span>
        ),
        width: 150
      }, {
          Header: 'Additional Course',
          accessor: 'additionalCourse',
          style: { textAlign: 'left' },
          sortable: false,
          Cell: cellInfo => (
              <span>
                  {
                      cellInfo.original
                          ? cellInfo.original.additionalCourse && cellInfo.original.additionalCourse.length > 0
                              ? cellInfo.original.additionalCourse.map(add =>
                                <span>{add.name} ({add.desc}) <br/></span>
                              )
                              : null
                          : null
                  }
              </span>
          ),
          width: 150,
      },
      {
        Header: "Class",
        accessor: "class",
        style: { textAlign: "left" },
        sortable: false,
        Cell: cellInfo => (
          // console.log(cellInfo)
          <span>
            {
              cellInfo.original
                ? cellInfo.original.class
                  ? cellInfo.original.class.name
                  : null
                : null
            }
          </span>
        ),
        width: 100
      },
      {
        Header: "Phone",
        accessor: "phone",
        sortable: false,
        Cell: cellInfo => (
          <Wrap>
            <p>{cellInfo.original.phone}</p>
          </Wrap>
        ),
        width: 100
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <Wrap>
            <Icon
              link
              circular
              color="orange"
              name="address card"
              onClick={() => this.onClickProfile(cellInfo.original)}
            />
          </Wrap>
        ),
        width: 100
      }
    ]
  };
  onClickProfile = data => {
    this.props.history.push({
      pathname: "/Portfolio/Profile",
      state: { id: data.id }
    });
  };
  getClass = () => {
      getAllClass().then(res => {
          let checked_class = []
          if (res && res.status === 200) {
              let options = []
              res.data.map(item => {
                  options.push({ key: item.id, text: item.name, value: item.id })
                  checked_class.push(item.id)
              })
              this.setState({ options_class: options, rawDataclass: res.data, checked_class })
          }
      })
  }
  getAllStudentType = () => {
      getStudentType().then(res => {
        let checked_stdtype = []
          if (res && res.status === 200) {
              let options = []
              res.data.map(item => {
                  options.push({ key: item.id, text: item.name, value: item.id })
                  checked_stdtype.push(item.id)
              })
              this.setState({ options_studenttype: options, rawDataStudenttype: res.data, checked_stdtype })
          }
      })
  }

  getOptionCourse = (qString) => {
      getAllCourse(qString).then(res => {
        let checked_course = []
          if (res && res.status === 200) {
              let options = []
              res.data.map(item => {
                  options.push({
                      key: item.id,
                      text: `${item.name} (${item.desc})`,
                      value: item.id
                  })
                  // text: `${item.name} ${moment(item.start, 'DD-MM-YYYY').format("DD/MM/YYYY")} - ${moment(item.end, 'DD-MM-YYYY').format("DD/MM/YYYY")}`,
                  checked_course.push(item.id)
              })
              this.setState({ options_course: options, rawDataCourse: res.data, checked_course })
          }
      })
  }
  handleChangeSearch = (e) => {
      let { rawData, search, options_class, checked_class,
      checked_course, options_course ,checked_stdtype, options_studenttype } = this.state;
      let active;
      this.setState({loading:true})
      if(this._timeout){ //if there is already a timeout in process cancel it
        clearTimeout(this._timeout);
      }
      const val = e.target.value;
      const name = e.target.name
      this._timeout = setTimeout(()=>{
         this._timeout = null;
         search[name] = val
         let qString = "?name="+ val
         qString += '&status=' + search.status

         if (checked_class && checked_class.length != options_class.length)
             checked_class.map(item => {
                 qString += `&class[]=${item}`
             })

         if (checked_course && checked_course.length != options_course.length)
             checked_course.map(item => {
                 qString += `&course[]=${item}`
             })

         if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
             checked_stdtype.map(item => {
                 qString += `&stdtype[]=${item}`
             })

         if (search['sort'])
             qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
         this.getData(qString)

      },2000);
  }
  handleChangeStatus = (e, data) => {
      let { search, options_class, checked_class,
      checked_course, options_course ,checked_stdtype, options_studenttype } = this.state
      let qString

      search['status'] = data.value // "Active/Inactive"
      this.setState({ search })

      qString = '?status=' + data.value
      if (search['name']) {
        qString += '&name=' + search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })
      this.getData(qString)
  }
  handleChangeSort = (e, data) => {
      let { search, checked_class, options_class,
      checked_course, options_course ,checked_stdtype, options_studenttype } = this.state
      let  qString

      const result = data.options.find((member) => {
          return member.value === data.value
      })
      search['sort'] = result
      this.setState({ search, sortText: `${result.text} (${result.description})` })

      qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
      if (search['name']) {
        qString += '&name='+ search.name
      }
      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })

      this.getData(qString)
  }

  filterByClass = (data) => {
      // console.log(data)
      let { search, options_class,
      checked_course, options_course ,checked_stdtype, options_studenttype } = this.state

      let checked_class = []

      let qString = '?status=' + search.status

      if (data && data.length != options_class.length)
          data.map(item => {
              qString += `&class[]=${item}`
              checked_class.push(item)
          })
      else
          data.map(item => {
              checked_class.push(item)
          })

      if (data.length === options_class.length) {
          this.setState({ filterClassByText: 'All Class' })
      }
      else {
          this.setState({ filterClassByText: `Class (${data.length})` })
      }
      this.setState({ checked_class, })
      if (search['name']) {
        qString += '&name='+ search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })
      // console.log(qString)
      this.getData(qString)
  }

  filterByStdtype = (data) => {
      // console.log(data)
      let { search, options_studenttype,
      checked_class, options_class, checked_course, options_course } = this.state

      let checked_stdtype = []

      let qString = '?status=' + search.status

      if (data && data.length != options_studenttype.length)
          data.map(item => {
              qString += `&stdtype[]=${item}`
              checked_stdtype.push(item)
          })
      else
          data.map(item => {
              checked_stdtype.push(item)
          })

      if (data.length === options_studenttype.length) {
          this.setState({ filterStdtypeByText: 'All Student type' })
      }
      else {
          this.setState({ filterStdtypeByText: `Student type (${data.length})` })
      }
      this.setState({ checked_stdtype, })
      if (search['name']) {
        qString += '&name='+ search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })
      // console.log(qString)
      this.getData(qString)
  }

  filterByCourse = (data) => {
      // console.log(data)
      let { search, options_course,
      checked_class, options_class, checked_stdtype, options_studenttype } = this.state

      let checked_course = []

      let qString = '?status=' + search.status

      if (data && data.length != options_course.length)
          data.map(item => {
              qString += `&course[]=${item}`
              checked_course.push(item)
          })
      else
          data.map(item => {
              checked_course.push(item)
          })

      if (data.length === options_course.length) {
          this.setState({ filterCourseByText: 'All Course' })
      }
      else {
          this.setState({ filterCourseByText: `Course (${data.length})` })
      }
      this.setState({ checked_course, })
      if (search['name']) {
        qString += '&name='+ search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })
      // console.log(qString)
      this.getData(qString)
  }
  getData = qString => {
    this.setState({ loading: true });
    let { per_page, current_page, search } = this.state;
    current_page = current_page + 1;
    qString = qString + "&_perpage=" + per_page + "&page=" + current_page;
    getStudentListBySearch(qString).then(res => {
      // console.log("student", res);
      if (res && res.status === 200) {
        let newdata = [];
        res.data.data.map(item => {
          let description = `${item.studentId || ''} ${item.titleEng || ''} ${item.firstNameEng} ${item.lastNameEng} ${item.customerCode || ''}`
          let nameTH = ` ${item.title || ''} ${item.firstName} ${item.lastName}`
          newdata.push({
            id: item.id,
            image: item.imageURL || [],
            description: description,
            nameTH: nameTH,
            email: item.emailAddress,
            phone: item.phoneNumber,
            roles: item.roles || [],
            class: item.class,
            course: item.course,
            soloStudent: item.soloStudent,
            studentType: item.studentType,
            additionalCourse: item.additionalCourse || []
          });
        });
        // console.log("newdata", newdata);
        this.setState({ data: newdata, rawData: res.data.data, current_page:res.data.current_page -1 ,last_page:res.data.last_page });
      }
      this.setState({ loading: false });
    });
  };

  componentDidMount() {
    let qString = "?isActive=true";
    this.getData(qString);
    this.getClass();
    this.getAllStudentType();
    let qString2 = '?additionalCourse=false&active=true'
    this.getOptionCourse(qString2)
  }

  render() {
    let state = this.state;

    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Portfolio" />
          <Form>
              <Form.Group>
                  <Form.Input
                      width='3'
                      icon='search'
                      size='small'
                      placeholder='Search'
                      name='name'
                      onChange={this.handleChangeSearch} />
                  <Form.Field width='2'>
                      <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_class} options={state.options_class} onClick={this.filterByClass}>
                          <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                              {state.filterClassByText}
                              <Icon name='angle down' />
                          </Button>
                      </DropdownCheckbox>
                  </Form.Field>
                  <Form.Field width='2'>
                      <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_stdtype} options={state.options_studenttype} onClick={this.filterByStdtype}>
                          <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                              {state.filterStdtypeByText}
                              <Icon name='angle down' />
                          </Button>
                      </DropdownCheckbox>
                  </Form.Field>
                  <Form.Field width='2'>
                      <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_course} options={state.options_course} onClick={this.filterByCourse}>
                          <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                              {state.filterCourseByText}
                              <Icon name='angle down' />
                          </Button>
                      </DropdownCheckbox>
                  </Form.Field>
                  <Form.Dropdown
                      icon={null}
                      fluid
                      floating
                      style={{ width: '100%', marginRight: '30px' }}
                      trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                          <Icon name='angle down' />
                          Sort by : <b>{state.sortText}</b>
                      </Button>}
                      options={state.options_sortby}
                      defaultValue={state.search.sort}
                      selectOnBlur={false}
                      onChange={this.handleChangeSort}
                  />
                  <Form.Dropdown
                      icon={null}
                      fluid
                      floating
                      style={{ width: '100%', marginRight: '30px' }}
                      trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                          <Icon name='angle down' />
                          Status : <b>{state.search.status}</b>
                      </Button>}
                      options={config.options_studentstatus}
                      defaultValue={state.search.status}
                      selectOnBlur={false}
                      onChange={this.handleChangeStatus}
                  />
                  {/* <Form.Field width='9' style={{ textAlign: 'right' }}>
                      <MainButton
                          size='small'
                          onClick={this.show}
                          text='Add Student' />
                  </Form.Field> */}
              </Form.Group>
          </Form>
          <TableList
            loading={state.loading}
            data={state.data}
            columns={state.column}
            pageSize={state.per_page}
            page={state.current_page}
            pages={state.last_page}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
          />
      </Segment>
    );
  }

  onPageSizeChange = (pageSize, pageIndex) => {
    let { search, options_class, checked_class,
    checked_course, options_course ,checked_stdtype, options_studenttype } = this.state
    this.setState({
      per_page: pageSize,current_page : pageIndex
    }, () => {
      let qString = '?status=Active'
      if (search['name']) {
        qString += '&name=' + search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })
      this.getData(qString)
    });
  };

  onPageChange = pageIndex => {
    let { search, options_class, checked_class,
    checked_course, options_course ,checked_stdtype, options_studenttype } = this.state
    this.setState({
    current_page : pageIndex
    }, () => {
      let qString = '?status=Active'
      if (search['name']) {
        qString += '&name=' + search.name
      }
      if (search['sort'])
          qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

      if (checked_class && checked_class.length != options_class.length)
          checked_class.map(item => {
              qString += `&class[]=${item}`
          })

      if (checked_course && checked_course.length != options_course.length)
          checked_course.map(item => {
              qString += `&course[]=${item}`
          })

      if (checked_stdtype && checked_stdtype.length != options_studenttype.length)
          checked_stdtype.map(item => {
              qString += `&stdtype[]=${item}`
          })
      this.getData(qString)
    });

  };
}

export default withRouter(Portfolio);
