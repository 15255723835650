import React, { Component } from 'react';
import { Form, Icon, Message } from 'semantic-ui-react'
import Wrap from './wrap'

class renderField extends Component {

  state = {
    fileURL: null,
    fileName: null
  }

  componentDidMount() {
    // console.log('componentDidMount',this.props.input.value)
    if (typeof this.props.input.value === 'string') {
      var fileName = this.props.input.value.split('/')
      fileName = fileName.pop()
      this.setState({ fileURL: this.props.input.value, fileName, })
    }
  }
  render() {
    const { input, required, width, label, labelDesc, multiple, removeabel, meta: { touched, error, warning } } = this.props
    const { inputProps } = input
    const state = this.state
    // console.log('state.fileURL',state.fileURL);
    return (
      <Wrap>
        <Form.Field width={width} required={required} >
          <label style={{ marginBottom: '0px', textAlign: 'left', color: '#00000099' }}>{label} {labelDesc ? <span style={{ fontSize: '90%' }}>( {labelDesc} )</span> : null}</label>
          {
            state.fileURL
              ? <Message size='small' style={{ margin: '3px 0px 3px 0px' }}>
                <Message.Content style={{ textAlign: 'left' }}>
                  <p>
                    <Icon name='image' />
                    <a href={state.fileURL} target='_blank' rel="noopener noreferrer">{state.fileName}</a>
                     {removeabel ?
                       <Icon name='x' color='red'
                        onClick={() => {
                          input.onChange(null)
                          this.setState({ fileURL: null })
                        }} /> : null}
                  </p>
                </Message.Content>
              </Message>
              : null
          }
          <Form.Input
            {...inputProps}
            type="file"
            onChange={(e) => input.onChange(e.target.files)}
            multiple={multiple}
            error={touched && error}
          />
          <p style={{ color: 'red', textAlign: 'left' }}> {((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}</p>
        </Form.Field>
      </Wrap>
    )
  }
}
export default renderField
