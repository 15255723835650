import React, { Component } from "react";
import {
  Button,
  Icon,
  Form,
  Header,
  Divider,
  Segment,
  Grid,
  List,
} from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, FieldArray } from "redux-form";
import { connect } from "react-redux";
import validate from "../../validate";
import renderSelectField from "../../components/renderSelectField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import Wrap from "../../components/wrap";
import { getCourseSubjectDetail, getCheckSubject } from "./service";

class Formsubject extends Component {
  state = {
    option_ground: this.props.option_ground,
    option_checkcourse: this.props.option_checkcourse,
    option_flightcourse: this.props.option_flightcourse,
    type: {
      ground: false,
      flight: false,
    },
    rawData: this.props.rawData,
    option_checked_bac: [
      // { id: 1, name: "CPL" },
      { id: 9, name: "CPPL" },
      { id: 10, name: "CIR" },
      { id: 11, name: "CADV" },
      { id: 12, name: "CME" },
      { id: 2, name: "PPL" },
      { id: 3, name: "IR" },
      { id: 4, name: "IP" },
      { id: 5, name: "ME" },
      { id: 6, name: "ADV" },
      { id: 7, name: "BP" },
      { id: 8, name: "BI" },
    ],
    option_checked: [
      { id: 1, name: "CPL" },
      { id: 2, name: "PPL" },
      { id: 3, name: "IR" },
      { id: 4, name: "IP" },
      { id: 5, name: "ME" },
    ],
  };

  componentDidMount() {
    let { info } = this.props;
    let { type } = this.state;
    if (info.instructorType) {
      info.instructorType.map((item) => {
        if (item.name === "Ground Instructor") type.ground = true;
        else if (item.name === "SIM Instructor") type.flight = true;
        else if (item.name === "Flight Instructor") type.flight = true;
      });
      this.setState({ type });
    }
    // this.getData();
    let option = [];
    // getCheckSubject().then(res => {
    //   if (res && res.status === 200) {
    //     res.data.map(item => {
    //       option.push({
    //         key: item.id,
    //         value: item.id,
    //         text: item.code ? item.code : item.name
    //       });
    //     });
    //     this.setState({ option_checkcourse: option });
    //   }
    // });
  }
  // getData = () => {
  //   getCourseSubjectDetail().then(res => {
  //     if (res && res.status === 200) {
  //       let option = [];
  //       res.data.map(item =>
  //         option.push({
  //           key: item.id,
  //           value: item.id,
  //           text: item.name
  //         })
  //       );
  //       this.setState({
  //         option_course: option,
  //         rawData: res.data
  //       });
  //     }
  //   });
  // };

  getGroundModuleByCourse = (index) => {
    let { trainingCourseGround } = this.props;
    let { rawData } = this.state;
    let option = [];
    if (
      trainingCourseGround &&
      trainingCourseGround[index] &&
      trainingCourseGround[index].course
    ) {
      const result = rawData.find((member) => {
        return (
          parseInt(member.id) === parseInt(trainingCourseGround[index].course)
        );
      });
      if (result && result.module)
        result.module.map((item) => {
          option.push({
            key: item.id,
            value: item.id,
            text: item.name,
          });
        });
    }
    return option;
  };
  getModuleByCourse = (index) => {
    let { trainingCourseFlight } = this.props;
    let { rawData } = this.state;
    let option = [];
    if (
      trainingCourseFlight &&
      trainingCourseFlight[index] &&
      trainingCourseFlight[index].course
    ) {
      const result = rawData.find((member) => {
        return (
          parseInt(member.id) === parseInt(trainingCourseFlight[index].course)
        );
      });
      if (result && result.module)
        result.module.map((item) => {
          option.push({
            key: item.id,
            value: item.id,
            text: item.name,
          });
        });
    }
    return option;
  };
  getSubjectByModule = (index) => {
    let { trainingCourseFlight } = this.props;
    let { rawData } = this.state;
    let option = [];
    if (
      trainingCourseFlight &&
      trainingCourseFlight[index] &&
      trainingCourseFlight[index].course &&
      trainingCourseFlight[index].module
    ) {
      const result = rawData.find((member) => {
        return (
          parseInt(member.id) === parseInt(trainingCourseFlight[index].course)
        );
      });
      if (result && result.module) {
        const resultM = result.module.find((member) => {
          return (
            parseInt(member.id) === parseInt(trainingCourseFlight[index].module)
          );
        });
        if (resultM && resultM.flightSubjects)
          resultM.flightSubjects.map((item) => {
            option.push({
              key: item.id,
              value: item.id,
              text: item.code,
            });
          });
      }
    }
    return option;
  };
  getGroundSubjectByModule = (index) => {
    let { trainingCourseGround } = this.props;
    let { rawData } = this.state;
    let option = [];

    if (
      trainingCourseGround &&
      trainingCourseGround[index] &&
      trainingCourseGround[index].course &&
      trainingCourseGround[index].module
    ) {
      const result = rawData.find((member) => {
        return (
          parseInt(member.id) === parseInt(trainingCourseGround[index].course)
        );
      });
      if (result && result.module) {
        const resultM = result.module.find((member) => {
          return (
            parseInt(member.id) === parseInt(trainingCourseGround[index].module)
          );
        });
        if (resultM && resultM.groundSubjects)
          resultM.groundSubjects.map((item) => {
            option.push({
              key: item.id,
              value: item.id,
              text: item.name,
            });
          });
      }
    }
    return option;
  };
  // renderGround = ({ fields, meta: { touched, error } }) => (
  //   <Wrap>
  //     {fields.map((member, index) => (
  //       <Segment textAlign="right">
  //         <Icon
  //           link
  //           color="red"
  //           name="trash alternate"
  //           onClick={() => fields.remove(index)}
  //         />
  //         <Form.Group>
  //           <Field
  //             name={`${member}.course`}
  //             component={renderSelectField}
  //             label="Course"
  //             options={this.state.option_course}
  //           />
  //           <Field
  //             name={`${member}.module`}
  //             component={renderSelectField}
  //             label="Module"
  //             options={this.getGroundModuleByCourse(index)}
  //           />
  //         </Form.Group>
  //         <Field
  //           name={`${member}.subject`}
  //           multiple={true}
  //           search
  //           component={renderSelectField}
  //           label="Training Course"
  //           options={this.getGroundSubjectByModule(index)}
  //         />
  //       </Segment>
  //     ))}
  //     <Button
  //       type="button"
  //       compact
  //       floated="right"
  //       color="orange"
  //       onClick={() => fields.push({})}
  //       content="Add Course"
  //     />
  //     <br />
  //     <br />
  //   </Wrap>
  // );
  // renderFlight = ({ fields, meta: { touched, error } }) => (
  //   <Wrap>
  //     {fields.map((member, index) => (
  //       <Segment textAlign="right">
  //         <Icon
  //           link
  //           color="red"
  //           name="trash alternate"
  //           onClick={() => fields.remove(index)}
  //         />
  //         <Form.Group>
  //           <Field
  //             name={`${member}.course`}
  //             component={renderSelectField}
  //             label="Course"
  //             options={this.state.option_course}
  //           />
  //           <Field
  //             name={`${member}.module`}
  //             component={renderSelectField}
  //             label="Module"
  //             options={this.getModuleByCourse(index)}
  //           />
  //         </Form.Group>
  //         <Field
  //           name={`${member}.subject`}
  //           multiple={true}
  //           search
  //           component={renderSelectField}
  //           label="Training Course"
  //           options={this.getSubjectByModule(index)}
  //         />
  //       </Segment>
  //     ))}
  //     <Button
  //       type="button"
  //       compact
  //       floated="right"
  //       color="orange"
  //       onClick={() => fields.push({})}
  //       content="Add Course"
  //     />
  //     <br />
  //     <br />
  //   </Wrap>
  // );

  componentDidUpdate(prevProps) {
    // console.log(prevProps, "prevProps");
    // if (prevProps.variant.name !== this.props.variant.name) {
    //   this.props.change("name", this.props.variant.name);
    // }
    // if (prevProps.variant.description !== this.props.variant.description) {
    //   this.props.change("description", this.props.variant.description);
    // }
  }
  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      reset,
      submitting,
      fetching,
      bacCheck,
      caatCheck,
    } = this.props;
    // console.log('fetching',fetching)
    // console.log('state',state)
    // console.log('props',this.props)
    // if(state.option_ground.length === 0 || state.option_checkcourse.length === 0 || state.option_flightcourse.length === 0)
    // return <div>Loading..</div>
    if (fetching) {
      return <div>Loading..</div>;
    }
    return (
      <Form onSubmit={handleSubmit}>
        {state.type.ground && (
          <Wrap>
            <Divider horizontal>
              <Header as="h4">Ground Subjects</Header>
            </Divider>
            <Segment secondary textAlign="left">
              {/* <FieldArray
                name="trainingCourseGround"
                component={this.renderGround}
              />*/}
              <Field
                name="groundCourse"
                multiple={true}
                component={renderSelectField}
                label="Subjects"
                search
                options={this.props.option_ground}
              />
              {/* <Grid columns={3}>
                <Grid.Row>
                  {
                    this.props.option_ground.map((item, index) =>
                      <Grid.Column>
                        <Field
                          name={`groundCourse.${item.id}`}
                          component={renderCheckboxField}
                          label={item.name}
                        />
                      </Grid.Column>
                    )
                  }
                </Grid.Row>
              </Grid> */}
            </Segment>
          </Wrap>
        )}
        {state.type.flight && (
          <Wrap>
            <Divider horizontal>
              <Header as="h4">Flight Subjects</Header>
            </Divider>
            <Segment secondary textAlign="left">
              {/* <Field
                name="trainingCourseFlight"
                multiple={true}
                component={renderSelectField}
                label="Flight Subjects"
                options={state.option_flightcourse}
              /> */}
              <Grid columns={3}>
                <Grid.Row>
                  {this.props.option_flightcourse.map((item, index) => (
                    <Grid.Column>
                      <Field
                        name={`flightGroup.${item.id}`}
                        component={renderCheckboxField}
                        label={item.name}
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </Grid>
              {/* <Field
                name="flightGroup"
                component={renderCheckboxGroupField}
                inline={true}
                width={16}
                // label="Flight Subject"
                options={state.option_flightcourse}
              /> */}
              {/* <Field name="flightGroup" component={renderCheckboxGroupField} options={state.option_flightcourse} /> */}

              {/* <FieldArray
                name="trainingCourseFlight"
                component={this.renderFlight}
              />
              <Form.Group>
                <Field
                  width="2"
                  name="night"
                  component={renderCheckboxField}
                  label="Night"
                />
                <Field
                  width="2"
                  name="safety"
                  component={renderCheckboxField}
                  label="Safety"
                />
              </Form.Group> */}
            </Segment>
          </Wrap>
        )}
        <Wrap>
          <Divider horizontal>
            <Header as="h4">Check Subjects</Header>
          </Divider>
          <Segment secondary textAlign="left">
            {/*<Field
              name="checkCourse"
              multiple={true}
              search
              component={renderSelectField}
              label="Check Subjects"
              options={this.props.option_checkcourse}
            />*/}
            <Header as="h5" dividing>
              BAC Check
            </Header>
            <List as="ul" style={{ marginBottom: -25 }}>
              {state.type.flight && (
                <List.Item as="li" style={{ color: "black" }}>
                  Flight & SIM Check
                  <List.List as="ul">
                    <List.Item>
                      <Grid columns={5}>
                        <Grid.Row>
                          {state.option_checked_bac.map((item, index) => (
                            <Grid.Column>
                              <Field
                                name={`bacCheck.${item.name}`}
                                component={renderCheckboxField}
                                label={item.name}
                              />
                            </Grid.Column>
                          ))}
                        </Grid.Row>
                      </Grid>
                    </List.Item>
                  </List.List>
                </List.Item>
              )}
              {state.type.ground && (
                <List.Item as="li" style={{ color: "black" }}>
                  Ground Check
                  <List.List as="ul">
                    <List.Item>
                      <Form.Group>
                        <Field
                          width="4"
                          name="groundCheck"
                          component={renderCheckboxField}
                          label="Ground Check"
                        />
                      </Form.Group>
                    </List.Item>
                  </List.List>
                </List.Item>
              )}
            </List>
            {state.type.flight && (
              <Wrap>
                <Header as="h5" dividing>
                  CAAT Check
                </Header>
                <Grid columns={5}>
                  <Grid.Row style={{ marginBottom: 15, marginLeft: 30 }}>
                    {state.option_checked.map((item, index) => (
                      <Grid.Column>
                        <Field
                          name={`caatCheck.${item.name}`}
                          component={renderCheckboxField}
                          label={item.name}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </Grid>
              </Wrap>
            )}
            {/*<Form.Group>
              <Field
                width="2"
                name="DCP"
                component={renderCheckboxField}
                label="DCP"
              />
            </Form.Group>*/}
          </Segment>
        </Wrap>
        <br />
        <div>
          <Button
            type="submit"
            floated="left"
            content="Save"
            color="green"
            loading={this.props.loading}
            compact
            disabled={pristine || submitting}
          />
          <Button
            type="button"
            floated="right"
            content="Clear Values"
            color="green"
            basic
            compact
            disabled={pristine || submitting}
            onClick={reset}
          />
        </div>
      </Form>
    );
  }
}

// export default withRouter(Subject);
Formsubject = reduxForm({
  // a unique name for the form
  form: "FormTraingCourse",
  validate,
  enableReinitialize: true,
})(Formsubject);
// Decorate with connect to read form values
const selector = formValueSelector("FormTraingCourse"); // <-- same as form name
Formsubject = connect((state) => {
  // can select values individually
  const trainingCourseFlight = selector(state, "trainingCourseFlight");
  const trainingCourseGround = selector(state, "trainingCourseGround");
  const caatCheck = selector(state, "caatCheck");
  const bacCheck = selector(state, "bacCheck");
  return {
    trainingCourseFlight,
    trainingCourseGround,
    caatCheck,
    bacCheck,
  };
})(Formsubject);
export default Formsubject;
