import React, { Component } from "react";
import { Form, Button, Divider, Message } from "semantic-ui-react";
import { Field, reduxForm ,formValueSelector,change} from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { options_title_eng, options_title_th } from "../../config";
import { connect } from "react-redux";
// วานีเพิ่ม validate
const validate = values => {
  const errors = {};
  if (!values.firstNameEng) errors.firstNameEng = true;
  if (!values.lastNameEng) errors.lastNameEng = true;
  if (!values.firstNameTh) errors.firstNameTh = true;
  if (!values.lastNameTh) errors.lastNameTh = true;
  if (!values.usertype) errors.usertype = true;
  if (!values.roles) errors.roles = true;
  if (values.roles && values.roles.length === 0) errors.roles = true;
  if (!values.email) errors.email = true;
  let regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})(\.[a-z]{2,8})?$/;
  if (values.email && !regex.test(values.email)) errors.email = 'Invalid email format';
  if (values.email && !regex.test(values.email)) errors.email = 'Invalid email format';
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  if (!values.username) errors.username = true;
  return errors;
};

class FromUser extends Component {
  state = {
    optionsRoles: [],
    radioWrktime: [
      { key: "r01", value: "IP Fulltime", label: "IP Fulltime" },
      { key: "r02", value: "IP Parttime", label: "IP Parttime" }
    ],
    radioCategories: [
      { key: "c01", value: "CAT A", label: "CAT A" },
      { key: "c02", value: "CAT B", label: "CAT B" },
      { key: "c03", value: "CAT C", label: "CAT C" },
      { key: "c04", value: "CAT D", label: "CAT D" }
    ],
    optionsGender: [
      { key: "gender01", value: "Male", text: "Male" },
      { key: "gender02", value: "Female", text: "Female" }
    ],
    optionsUserType:[
      { key: "r", text: "Instructor", value: "Instructor" },
      { key: "u", text: "Staff", value: "Staff" }
    ]
  };

  handleChange = (e) => {
    // console.log(e)
    this.props.dispatch(change("rolesuser", "username", e.target.value))
  }
  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      loadingUp,
      options_roles,
      errorMessage,
      isEdit,
      activeUser
    } = this.props;
    return (
      <div>
        <Form onSubmit={handleSubmit}>
          {/* <Form.Group>
            <Field
              name="titleEng"
              type="text"
              width="4"
              component={renderField}
              label="Title (EN)"
            />
            <Field
              name="firstNameEng"
              type="text"
              width="6"
              required={true}
              component={renderField}
              label="First name (EN)"
            />
            <Field
              name="lastNameEng"
              type="text"
              width="6"
              required={true}
              labelDesc="(EN)"
              component={renderField}
              label="Last name (EN)"
            />
          </Form.Group>

          <Form.Group>
            <Field
              name="titleTh"
              type="text"
              width="4"
              component={renderField}
              label="Title (TH)"
            />
            <Field
              name="firstNameTh"
              type="text"
              width="6"
              required={true}
              component={renderField}
              label="First name (TH)"
            />
            <Field
              name="lastNameTh"
              type="text"
              width="6"
              required={true}
              component={renderField}
              label="Last name (TH)"
            />
          </Form.Group>
           */}
           <Form.Group widths='3'>
                <Field
                  name="titleEng"
                  // width='3'
                  component={renderSelectField}
                  label="Title (EN)"
                  options={options_title_eng}
                />
                <Field
                  name="firstNameEng"
                  type="text"
                  // width="6"
                  required={true}
                  component={renderField}
                  label="First name (EN)"
                />
                <Field
                  name="lastNameEng"
                  type="text"
                  // width="6"
                  required={true}
                  labelDesc="(EN)"
                  component={renderField}
                  label="Last name (EN)"
                />
              </Form.Group>

              <Form.Group widths='3'>
                <Field
                  name="titleTh"
                  component={renderSelectField}
                  label="Title (TH)"
                  options={options_title_th}
                />
                <Field
                  name="firstNameTh"
                  type="text"
                  // width="6"
                  required={true}
                  component={renderField}
                  label="First name (TH)"
                />
                <Field
                  name="lastNameTh"
                  type="text"
                  // width="6"
                  required={true}
                  component={renderField}
                  label="Last name (TH)"
                />
              </Form.Group>
              <Field
                name="nickName"
                type="text"
                width="8"
                component={renderField}
                label="Nick name"
              />

          <Form.Group widths="equal">
            <Field
              name="email"
              type="text"
              required={true}
              component={renderField}
              label="Email"
                onChange={(e => this.handleChange(e))}
            />
            <Field
              name="phone"
              type="text"
              component={renderField}
              label="Phone"
            />
          </Form.Group>
          {!isEdit &&
            <Field
              name="usertype"
              required={true}
              component={renderSelectField}
              label="User Type"
              options={state.optionsUserType}
            />
          }

          <Field
            name="roles"
            required={true}
            multiple={true}
            component={renderSelectField}
            label="Role"
            options={options_roles}
          />
          <Form.Group widths="equal">
            <Field
              name="dateOfBirth"
              required={true}
              component={renderDateOnlyField}
              label="Date of Birth"
            />
            <Field
              name="gender"
              component={renderSelectField}
              label="Gender"
              options={state.optionsGender}
            />
          </Form.Group>
          <br />
          <Divider horizontal>Username</Divider>
          {errorMessage && (
            <Message negative>
              <p>{errorMessage}</p>
            </Message>
          )}
          {/* {วานีแก้  ลบ password พี่ฟิกไว้ จากวันเกิด*/}
          <Form.Group widths="8">
            <Field
              name="username"
              widths="6"
              component={renderField}
              label="Username"
              type="text"
              // readOnly={isEdit ? false : true}
              required={true}
            />
          </Form.Group>
          {isEdit && activeUser ? (
            <Button
              compact
              color="red"
              floated="right"
              type="button"
              content="Inactive User"
              loading={loadingUp}
              onClick={this.props.onDisable}
            />
          ) : (
              isEdit && (
                <Button
                  compact
                  floated="right"
                  color="yellow"
                  type="button"
                  content="Active User"
                  loading={loadingUp}
                  onClick={this.props.onEnable}
                />
              )
            )}
          <br />
          <br />
          <br />
          <Button
            floated="left"
            type="submit"
            content="Save"
            color="green"
            compact
            loading={loading}
            disabled={pristine || submitting}
          />
          <Button
            floated="right"
            type="button"
            content="Cancel"
            color="green"
            compact
            basic
            onClick={this.props.onCancel}
          />
        </Form>
      </div>
    );
  }
}


FromUser = reduxForm({
  // a unique name for the form
  form: "rolesuser",
  validate,
  enableReinitialize: true,
})(FromUser);

const selector = formValueSelector("rolesuser");
FromUser = connect(state => {
  const setUsername = selector(state, "email");
  return {
    setUsername: setUsername
  };
})(FromUser);

export default FromUser;
