import React, { Component } from "react";
import { Button, Image, Grid, Form, Icon } from "semantic-ui-react";

class UploadImage extends Component {
  upload = () => {
    document.getElementById("file").click();
  };
  render() {
    const { imageUrl, loading } = this.props;
    return (
      <Grid columns="2">
        <Grid.Column textAlign="center">
          {!loading && (
            <Image
              src={
                imageUrl ? imageUrl+'?time='+ new Date()  :
                "https://react.semantic-ui.com/images/wireframe/square-image.png"
              }
              centered
            />
          )}
          <br />
          {imageUrl && (
            <Button onClick={this.props.onClickDelete}>Delete</Button>
          )}
        </Grid.Column>
        <Grid.Column textAlign="center">
          <div class="choose_file">
            <Button
              fluid
              color="blue"
              disabled={loading}
              onClick={() => this.refs.fileUploader.click()}
            >
              {loading ? (
                <div>
                  <Icon loading name="spinner" /> Uploading
                </div>
              ) : (
                "Choose Image"
              )}
            </Button>
            <input
              type="file"
              id="file"
              ref="fileUploader"
              style={{ display: "none" }}
              onChange={e => this.props.onChange(e.target.files)}
            />
          </div>
        </Grid.Column>
      </Grid>
    );
  }
}

export default UploadImage;
