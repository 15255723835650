import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Image,
  Label,
  Table,
  Input,
  Checkbox,
  List,
  Grid,
  Loader,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import * as config from "../../config";
import { getReservation, getAttendance, updateAttendance } from "./service";
// import TableList from "../../components/table";
import TableList from "../../components/tableClickRow";
import Wrap from "../../components/wrap";
import moment from "moment";
import ModalForm from "../../components/modal";
import DayPicker from "../../components/dayPicker";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seletedDate: new Date(),
      rawData: [],
      data: [],
      loading: true,
      detailReservation: null,
      studentAttendance: [],
      allChecked: true,
      column: [
        {
          Header: "Title",
          accessor: "title",
          // style: { textAlign: "center" },
          sortable: false,
          Cell: (cellInfo) => (
            <Wrap>
              <span
                className="bold"
                style={{ cursor: "pointer" }}
                // onClick={() => this.onClickRow(cellInfo.original)}
              >
                {cellInfo.original.title}
              </span>
            </Wrap>
          ),
        },
        // {
        //   Header: "Course",
        //   width: 100,
        //   accessor: "courseName",
        //   sortable: false
        // },
        {
          Header: "Instructor",
          accessor: "instructorName",
          sortable: false,
        },
        {
          Header: "Room",
          accessor: "roomName",
          width: 150,
          sortable: false,
        },
        {
          Header: "Time",
          accessor: "start",
          style: { textAlign: "center" },
          width: 250,
          sortable: false,
          Cell: (cellInfo) => (
            <p>
              <p>
                {moment(cellInfo.original.start).format("MMMM Do YYYY") ===
                moment(cellInfo.original.end).format("MMMM Do YYYY")
                  ? moment(cellInfo.original.start).format(
                      "ddd, MMMM Do YYYY HH:mm"
                    ) +
                    " - " +
                    moment(cellInfo.original.end).format("HH:mm")
                  : moment(cellInfo.original.start).format(
                      "MMMM Do YYYY, HH:mm"
                    ) +
                    " - " +
                    moment(cellInfo.original.end).format("MMMM Do YYYY, HH:mm")}
              </p>
            </p>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          sortable: false,
          style: { textAlign: "center" },
          width: 150,
          Cell: (cellInfo) => (
            <span>
              {cellInfo.original.status === 0 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#FF9800",
                    color: "#FFFFFF",
                    border: "1px solid #FF9800",
                    borderRadius: "14px",
                  }}
                >
                  Reserved
                </Label>
              ) : cellInfo.original.status === 1 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  Dispatched
                </Label>
              ) : cellInfo.original.status === 2 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#27A8A2",
                    color: "#FFFFFF",
                    border: "1px solid #27A8A2",
                    borderRadius: "14px",
                  }}
                >
                  Completed
                </Label>
              ) : cellInfo.original.status === 3 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#CE2921",
                    color: "#FFFFFF",
                    border: "1px solid #CE2921",
                    borderRadius: "14px",
                  }}
                >
                  Cancelled
                </Label>
              ) : cellInfo.original.status === 4 ? (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  Landing
                </Label>
              ) : (
                <Label
                  size="small"
                  className="statusLabel"
                  style={{
                    backgroundColor: "#56B9F4",
                    color: "#FFFFFF",
                    border: "1px solid #56B9F4",
                    borderRadius: "14px",
                  }}
                >
                  No Status
                </Label>
              )}
            </span>
          ),
        },
      ],
    };
  }
  close = () =>
    this.setState({
      open: false,
      detailReservation: null,
      studentAttendance: [],
    });
  async onClickRow(data) {
    // let { studentAttendance, allChecked } = this.state
    console.log("data", data);
    // let qString = "/" + data.id + "?_embed=attendance";
    let qString = "/" + data.id;
    await this.getDetailReservation(qString);
    // await this.setState(prevState => {
    //   let { studentAttendance, allChecked } = prevState;
    //   if (allChecked) {
    //     studentAttendance = studentAttendance.map(item => ({ ...item, attendees: true }));
    //   }
    //   return { studentAttendance,}
    // })
    await this.setState({ open: true });
    // console.log('1',allChecked);

    // allChecked = studentAttendance.every(item => item.attendees);
    // await allChecked = studentAttendance.every((member) => {
    //   return member.attendees === false
    // });
    // await this.setState(prevState =>{
    //   allChecked = studentAttendance.every((member) => {
    //     console.log('studentAttendance member',member);
    //     console.log('member',member);
    //     return member.attendees === true
    //   });
    //   console.log('2',allChecked);
    //   return { allChecked }
    // })
    // await this.setState({ allChecked })
  }

  getDetailReservation = (qString) => {
    getReservation(qString).then((res) => {
      this.setState({ loading: false });
      if (res && res.status === 200) {
        let options = [];
        res.data.attendees.map((members) => {
          options.push({ ...members, attendees: true });
        });
        // console.log('options studentAttendance',options)
        this.setState({
          detailReservation: res.data,
          studentAttendance: options,
          allChecked: true,
        });
        // console.log('Reservation', res)
      }
    });
  };
  getReservationAll = (qString) => {
    this.setState({ loading: true });
    // let qString  = moment(seletedDate).format('YYYY-MM-DD')
    getAttendance(qString).then((res) => {
      this.setState({ loading: false });
      if (res && res.status === 200) {
        this.setState({ data: res.data });
        console.log("Reservation", res.data);
      }
    });
  };

  handleChange = (e) => {
    // console.log('e',e.target.name,e.target.checked)
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState((prevState) => {
      let { studentAttendance, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        studentAttendance = studentAttendance.map((item) => ({
          ...item,
          attendees: checked,
        }));
      } else {
        studentAttendance = studentAttendance.map((item) =>
          parseInt(item.id) === parseInt(itemName)
            ? { ...item, attendees: checked }
            : item
        );
        allChecked = studentAttendance.every((item) => item.attendees);
      }
      return { studentAttendance, allChecked };
    });
  };

  save = () => {
    this.setState({ loading: true });
    let { studentAttendance, detailReservation, seletedDate } = this.state;
    let option = [];
    // console.log('detailReservation',detailReservation)
    studentAttendance.map((item) => {
      if (item.attendees === true || item.attendees === 1) option.push(item.id);
    });
    // console.log('save',option)
    let dataSent = {
      attendees: option,
    };
    updateAttendance(detailReservation.id, dataSent).then((res) => {
      if (res && res.status === 200) {
        this.close();
        // let qString = "?displayType=2";
        this.getReservationAll("");
      }
      this.setState({ loading: false });
    });
  };
  handleChangeDate = (seletedDate) => {
    this.setState({ seletedDate, loading: true });
    this.getReservationAll("");
  };
  componentDidMount() {
    // let qString = "?displayType=2";
    this.getReservationAll("");
  }

  render() {
    let state = this.state;
    // console.log("studentAttendance", state.studentAttendance);
    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Attendance"></HeaderWithMenu>
        {/*<Form>
            <Form.Group style={{ marginBottom: "0px" }}>
              <Form.Field width="5" style={{ paddingTop: "10px" }}>
                <DayPicker
                  day={state.seletedDate}
                  onChange={this.handleChangeDate}
                />
              </Form.Field>
              <Form.Field width="8"></Form.Field>
              <Form.Field
                width="5"
                style={{ paddingTop: "4px", textAlign: "right" }}
              >
              </Form.Field>
            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <Table celled padded compact unstackable style={{ fontSize: "95%" }}>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell >Title</Table.HeaderCell>
                  <Table.HeaderCell >Instructor</Table.HeaderCell>
                  <Table.HeaderCell >Room</Table.HeaderCell>
                  <Table.HeaderCell >Time</Table.HeaderCell>
                  <Table.HeaderCell >Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.data.map((item, index) => (
                  <Table.Row>
                    <Table.Cell className="bold" style={{ cursor: 'pointer' }} onClick={() => this.onClickRow(item)}>
                      {`${item.title}`}
                    </Table.Cell>
                    <Table.Cell>
                      {item.instructorName && `${item.instructorName}`}
                    </Table.Cell>
                    <Table.Cell>
                      {item.roomName && `${item.roomName}`}
                    </Table.Cell>
                    <Table.Cell>
                      <p>
                        {moment(item.start).format("MMMM Do YYYY") ===
                          moment(item.end).format("MMMM Do YYYY")
                          ? moment(item.start).format(
                            "ddd, MMMM Do YYYY HH:mm"
                          ) +
                          " - " +
                          moment(item.end).format("HH:mm")
                          : moment(item.start).format("MMMM Do YYYY, HH:mm") +
                          " - " +
                          moment(item.end).format("MMMM Do YYYY, HH:mm")}
                      </p>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <span>
                        {item.status === 0 ? (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#FF9800",
                              color: "#FFFFFF",
                              border: "1px solid #FF9800",
                              borderRadius: "14px"
                            }}
                          >
                            Reserved
                          </Label>
                        ) : item.status === 1 ? (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#56B9F4",
                              color: "#FFFFFF",
                              border: "1px solid #56B9F4",
                              borderRadius: "14px"
                            }}
                          >
                            Dispatched
                          </Label>
                        ) : item.status === 2 ? (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#27A8A2",
                              color: "#FFFFFF",
                              border: "1px solid #27A8A2",
                              borderRadius: "14px"
                            }}
                          >
                            Completed
                          </Label>
                        ) : item.status === 3 ? (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#CE2921",
                              color: "#FFFFFF",
                              border: "1px solid #CE2921",
                              borderRadius: "14px"
                            }}
                          >
                            Cancelled
                          </Label>
                        ) : item.status === 4 ? (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#56B9F4",
                              color: "#FFFFFF",
                              border: "1px solid #56B9F4",
                              borderRadius: "14px"
                            }}
                          >
                            Landing
                          </Label>
                        ) : (
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#56B9F4",
                              color: "#FFFFFF",
                              border: "1px solid #56B9F4",
                              borderRadius: "14px"
                            }}
                          >
                            No Status
                          </Label>
                        )}
                      </span>
                  </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}*/}
        <TableList
          loading={this.state.loading}
          data={this.state.data}
          columns={this.state.column}
          rowClick={(data) => this.onClickRow(data)}
        />

        <ModalForm
          icon="check square outline"
          content="Attendance"
          open={this.state.open}
          close={this.close}
          size="small"
        >
          <Wrap>
            {state.detailReservation && (
              <Wrap>
                <Header as="h2" textAlign="center">
                  {state.detailReservation.title}
                </Header>
                <Grid centered>
                  <Grid.Column width={8}>
                    <List className="bold">
                      <List.Item>
                        <b>
                          <Icon name="clock" /> Date Time :{" "}
                        </b>{" "}
                        {moment(state.detailReservation.start).format(
                          "ddd, MMM D HH:mm"
                        )}{" "}
                        -{" "}
                        {moment(state.detailReservation.start).format(
                          "ddd, MMM D"
                        ) ===
                        moment(state.detailReservation.end).format("ddd, MMM D")
                          ? moment(state.detailReservation.end).format("HH:mm")
                          : moment(state.detailReservation.end).format(
                              "ddd, MMM D HH:mm"
                            )}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="building" /> Room &nbsp; &nbsp; &nbsp;
                          &nbsp;:{" "}
                        </b>
                        {state.detailReservation.roomName}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="user circle" /> Instructor :{" "}
                        </b>{" "}
                        {state.detailReservation.instructorName}
                      </List.Item>
                      <List.Item>
                        <b>
                          <Icon name="user circle" /> SP &nbsp; &nbsp; &nbsp; :{" "}
                        </b>{" "}
                        {state.detailReservation.studentName}
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid>
              </Wrap>
            )}

            <Table compact celled style={{ marginBottom: "30px" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    className="header-table"
                    width={1}
                    textAlign="center"
                  >
                    <Input
                      type="checkbox"
                      name="checkAll"
                      checked={this.state.allChecked}
                      onChange={this.handleChange}
                    />
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" textAlign="center">
                    Name
                  </Table.HeaderCell>
                  <Table.HeaderCell className="header-table" textAlign="center">
                    Class
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.state.studentAttendance.length > 0 &&
                  this.state.studentAttendance.map((item, index) => {
                    return (
                      <Table.Row key={item.id}>
                        <Table.Cell textAlign="center" verticalAlign="top">
                          <Input
                            key={item.id}
                            type="checkbox"
                            name={item.id}
                            value={item.id}
                            checked={item.attendees}
                            onChange={this.handleChange}
                          />
                        </Table.Cell>
                        <Table.Cell verticalAlign="top">
                          {item.studentId} {item.firstName} {item.lastName}
                        </Table.Cell>
                        <Table.Cell textAlign="center" verticalAlign="top">
                          {item.class}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              size="small"
              loading={this.state.loading}
              onClick={this.save}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              size="small"
              onClick={this.close}
            />
          </Wrap>
        </ModalForm>
      </Segment>
    );
  }
}

export default Attendance;
