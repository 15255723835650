import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, List, Icon } from "semantic-ui-react";
import * as config from "../../config";
import {
  getRoles,
  postRole,
  updateRole,
  deleteRole,
  postRolesWithPermission,
  getRolesWithPermission
} from "./service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ModalForm from "../../components/modal";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import Confirmmodal from "../../components/confirmmodal";
import FormRoles from "./rolesForm";
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 10;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "antiquewhite" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid
});

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      rolesWithPermission: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.getRolesData();
    this.getPermissionData();
  }

  getRolesData = () => {
    getRoles().then(res => {
      if (res && res.status === 200) {
        this.setState({ items: res.data });
      }
      this.setState({ loading: false });
    });
  };
  getPermissionData = () => {
    getRolesWithPermission().then(res => {
      if (res && res.status === 200) {
        this.setState({ rolesWithPermission: res.data });
      }
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onEdit = data => {
    if (data.editable === "false") this.setState({ readOnly: true });
    else this.setState({ readOnly: false });
    let { rolesWithPermission } = this.state;
    const group = rolesWithPermission.find(item => {
      return item.id === data.id;
    });
    const currentPermission = {};
    group.permission.map(item => {
      currentPermission[item.group] = {};
      item.permission.map(per => {
        currentPermission[item.group][per] = true;
      });
    });
    data.permission = currentPermission;
    this.setState({
      open: true,
      currentData: data
    });
  };

  onClickCancel = () => {
    this.setState({ currentData: null, open: false,readOnly:false });
  };
  updateData = value => {
    this.setState({ loading: true });
    let data = {
      id: value.id,
      name: value.name,
      description: value.description,
      editable: value.editable
    };
    updateRole(data).then(res => {
      if (res && res.status === 200) {
        this.getRolesData();
      }
    });

    let permission = [];
    Object.keys(value.permission).map(group => {
      let tmp = [];
      Object.keys(value.permission[group]).map(key => {
        if (value.permission[group][key]) tmp.push(key);
      });
      permission.push({
        group: group,
        permission: tmp
      });
    });
    let permissionData = {
      id: value.id,
      name: value.name,
      permission: permission
    };
    postRolesWithPermission(permissionData).then(res => {
      if (res && res.status === 200) {
        this.getPermissionData();
        this.setState({ loading: false });
        this.onClickCancel();
      }
    });
  };
  deleteData = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteRole(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.getRolesData();
          this.setState({ openConfirm: false });
        }
        this.onClickCancel();
        this.setState({ loading: false });
      });
  };
  saveData = value => {
    this.setState({ loading: true });
    let data = {
      name: value.name,
      description: value.description,
      editable: "true"
    };
    postRole(data).then(res => {
      if (res && res.status === 201) {
        this.getRolesData();
        let permission = [];
        if(value.permission)
        Object.keys(value.permission).map(group => {
          let tmp = [];
          Object.keys(value.permission[group]).map(key => {
            if (value.permission[group][key]) tmp.push(key);
          });
          permission.push({
            group: group,
            permission: tmp
          });
        });
        let permissionData = {
          id: res.data.id,
          name: value.name,
          permission: permission
        };
        postRolesWithPermission(permissionData).then(res => {
          if (res && res.status === 200) {
            this.getPermissionData();
            this.setState({ loading: false });
            this.onClickCancel();
          }
        });
      } else {
        this.setState({ loading: false });
        this.onClickCancel();
      }
    });
  };
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // dropped first the list
    if (result.destination.index === 0) {
      return;
    }
    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items
    });
  }

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Role & Permission">
            <MainButton
              size="small"
              onClick={() => this.setState({ open: true })}
              text="Add Role"
            />
          </HeaderWithMenu>
          {/* <Header
            as="h5"
            style={{ color: config.colorTheme, marginTop: "0px" }}
          >
            <Icon name="info circle" fitted style={{ fontSize: "100%" }} />{" "}
            Order roles based on authority - highest authority at the top.
          </Header> */}
          {state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Icon loading name="circle notch" />
              <br />
              Loading..
            </div>
          ) :
            <List verticalAlign="middle" divided relaxed style={{padding: '20px'}}>
              {state.items.map((item, index) =>
            <List.Item style={{padding: '14px'}}>
              <List.Content floated="left">
              </List.Content>
              <List.Content floated="right" textAlign="left">
                <Icon
                  link
                  name="edit"
                  onClick={() => this.onEdit(item)}
                />
                &nbsp; &nbsp;
                {item.editable === "true" && (
                  <Icon
                    link
                    color="red"
                    name="trash alternate"
                    onClick={() => this.onClickDelete(item)}
                  />
                )}
              </List.Content>
              <List.Content>
                <span
                  className="bold"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.onEdit(item)}
                >
                  {item.name}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {item.description && <span>({item.description})</span>}
                </span>
              </List.Content>
            </List.Item>
            )}
          </List>}
        {/*
        (

            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {state.items.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                        isDragDisabled={
                          item.name === "Administrator" ? true : false
                        }
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <List verticalAlign="middle">
                              <List.Item>
                                <List.Content floated="left">

                                </List.Content>
                                <List.Content floated="right" textAlign="left">
                                  <Icon
                                    link
                                    name="edit"
                                    onClick={() => this.onEdit(item)}
                                  />
                                  &nbsp; &nbsp;
                                  {item.editable === "true" && (
                                    <Icon
                                      link
                                      color="red"
                                      name="trash alternate"
                                      onClick={() => this.onClickDelete(item)}
                                    />
                                  )}
                                </List.Content>
                                <List.Content>
                                  <span
                                    className="bold"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.onEdit(item)}
                                  >
                                    {item.name}
                                  </span>
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  {item.description}
                                </List.Content>
                              </List.Item>
                            </List>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )*/}

        </Segment>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Role & Permission"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
        <ModalForm
          open={state.open}
          icon="key"
          content="Role & Permission"
          close={this.onClickCancel}
        >
          <FormRoles
            loading={state.loading}
            readOnly={state.readOnly}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.currentData ? this.updateData : this.saveData}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
      </div>
    );
  }
}

export default withRouter(Roles);
