import React, { Component } from "react";
import {
  Form,
  Button,
  Icon,
  Grid,
  Item,
  Table,
  Divider
} from "semantic-ui-react";
import { Field, reduxForm, FieldArray, change } from "redux-form";
import renderField from "../../components/renderField";
import renderTimeField from "../../components/renderTimeField";
import renderMonthField from "../../components/renderMonthField";
import renderYearField from "../../components/renderYearField";
import moment from "moment";

const validate = values => {
  const errors = {};
  if (values.criteria) {
    const membersArrayErrors = [];
    values.criteria.map((item, index) => {
      const memberErrors = {};
      if (parseInt(item.hours) < 0) {
        memberErrors.hours = true;
      }
      membersArrayErrors[index] = memberErrors;
    });
    if (membersArrayErrors.length) {
      errors.criteria = membersArrayErrors;
    }
  }
  return errors;
};

class Formcourse extends Component {
  state = {
    options: [],
    monthDisplay: []
  };
  componentDidMount() {
    let options = [];
    for (let index = 1; index <= 12; index++) {
      options.push({
        key: index,
        text: index,
        value: index
      });
    }
    this.setState({ options });
    this.setMonth(
      this.props.initialValues &&
        this.props.initialValues.criteria &&
        this.props.initialValues.criteria.length
    );
  }

  onChange = (e, data) => {
    this.setMonth(data.value);
  };

  setMonth = num => {
    const { course, initialValues } = this.props;
    if (num === 0) num = 2;
    this.setState({ period: num });
    if (course.start) {
      let fields = [],
        monthDisplay = [],
        month = course.start;
      for (let index = 0; index < num; index++) {
        fields.push({
          month:
            initialValues &&
            initialValues.criteria[index] &&
            initialValues.criteria[index].month
              ? moment(initialValues.criteria[index].month, "YYYY-MM").format(
                  "YYYY-MM"
                )
              : moment(month, "YYYY-MM").format("YYYY-MM"),
          hours:
            initialValues &&
            initialValues.criteria[index] &&
            initialValues.criteria[index].hours
              ? initialValues.criteria[index].hours
              : 0
        });
        monthDisplay.push(
          initialValues &&
            initialValues.criteria[index] &&
            initialValues.criteria[index].month
            ? moment(initialValues.criteria[index].month, "YYYY-MM").format(
                "YYYY-MM"
              )
            : moment(month, "YYYY-MM").format("MMM YYYY")
        );
        month = moment(month, "YYYY-MM").add(1, "months");
      }
      console.log(fields, "fields");
      this.setState({ monthDisplay });
      this.props.dispatch(change("coursehours", "criteria", fields));
    }
  };

  renderMembers = ({ fields }) => {
    return fields.map((member, index) => (
      <Grid.Row columns="3" style={{ paddingBottom: 0, paddingTop: "4px" }}>
        <Grid.Column
          width="6"
          textAlign="right"
          // className="bold"
          verticalAlign="middle"
        >
          {this.state.monthDisplay && this.state.monthDisplay[index]}
        </Grid.Column>
        <Grid.Column>
          <Field
            name={`${member}.hours`}
            component={renderField}
            type="number"
            placeholder="Hours"
          />
        </Grid.Column>
        <Grid.Column width="2" verticalAlign="middle">
          HR.
        </Grid.Column>
      </Grid.Row>
    ));
  };

  render() {
    const state = this.state;
    const { course, handleSubmit, pristine, submitting } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Grid columns={2}>
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column width="6" textAlign="right" className="bold">
              Course
            </Grid.Column>
            <Grid.Column>{course && course.name}</Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column width="6" textAlign="right" className="bold">
              Course start
            </Grid.Column>
            <Grid.Column>
              {course && moment(course.start, "YYYY-MM").format("MMM YYYY")}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column width="6" textAlign="right" className="bold">
              Course period
            </Grid.Column>
            <Grid.Column>
              <Form.Select
                value={state.period}
                options={state.options}
                onChange={(e, data) => this.onChange(e, data)}
              />
            </Grid.Column>
          </Grid.Row>
          <FieldArray name="criteria" component={this.renderMembers} />
        </Grid>

        <br />
        {/* <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          disabled={pristine || submitting}
        /> */}
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

export default Formcourse = reduxForm({
  // a unique name for the form
  form: "coursehours",
  validate,
  enableReinitialize: true
})(Formcourse);
