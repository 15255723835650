import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Button } from "semantic-ui-react";
import * as config from "../../config";
import Iframe from "react-iframe";

class Diamond extends Component {
  printIframe() {
    window.print();
    // window.frames[0].focus();
    // window.frames[0].print();
  }
  render() {
    return (
      <Segment className="contentSegment" textAlign="left">
        <Header as="h2" style={{ color: config.colorTheme }}>
          Weight & Balance
        </Header>
        <Button
          size="small"
          content="Print"
          fluid
          icon="print"
          compact
          onClick={this.printIframe}
          // disabled
        />
        <div
          id="section-to-print"
          style={{ textAlign: "center", marginTop: "25px" }}
        >
          {/* <div style={{ pageBreakAfter: "always" }}></div> */}
          <Iframe
            width="1000"
            height="1000"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            url="https://member.ba.ac.th/da_bacpro"
            // url="https://dek-d.com"
            // id="print_to_png"
            display="initial"
            position="relative"
          />
          {/* <div style={{ pageBreakAfter: "always" }}></div> */}
        </div>

        {/* <iframe
          src="https://member.ba.ac.th/c172_bacpro"
          height="1000vh"
          width="100%"
          frameborder="0"
          allowfullscreen
        /> */}
      </Segment>
    );
  }
}

export default withRouter(Diamond);
