import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Tab,
  Form,
  Label,
  Icon,
  Button
} from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import moment from "moment";

import * as config from "../../config";
import validate from "../../validate";
import Table from "../../components/table";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import FormReservation from "./reservationForm";
import HeaderWithMenu from "../../components/headerwithmenu";
import EventDetailForm from "./eventDetail";
import Wrap from "../../components/wrap";
import {
  getEventDetail,
  getEventDetailBySearch,
  getReservationTypes
} from "./service";
// status 1 = checked out
// 0 = reserved

class MainReservations extends Component {
  state = {
    loading: false,
    events: [],
    reservationtypes: [],
    options_reservationtypes: [],
    seletedDate: new Date("2019-05-16"),
    column: [
      {
        Header: "Date",
        accessor: "date",
        sortable: false,
        Cell: cellInfo =>
          moment(cellInfo.original.start).format("dddd, MMMM Do YYYY"),
        width: 180
      },
      {
        Header: "Time",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        Cell: cellInfo => (
          <span>
            {moment(cellInfo.original.start).format("HH:mm")}
            <br />
            {moment(cellInfo.original.end).format("HH:mm")}
          </span>
        ),
        width: 100
      },
      {
        Header: "Aircraft",
        accessor: "resourceName",
        sortable: false,
        Cell: cellInfo => (
          <span
            className="bold"
            style={{ cursor: "pointer" }}
            onClick={() => this.handleShowDetailEvent(cellInfo.original)}
          >
            {cellInfo.original.aircraftSummary.tailNumber} <br />{" "}
            {cellInfo.original.aircraftSummary.make}{" "}
            {cellInfo.original.aircraftSummary.model}
          </span>
        )
      },
      {
        Header: "Crew",
        accessor: "orFor",
        sortable: false,
        Cell: cellInfo => (
          <span>
            {cellInfo.original.orFor} <br />
            <br />
            <p>
              <span className="header-reservation">Instructor: </span>
              {cellInfo.original.instructor.name}
            </p>
          </span>
        ),
        width: 280
      },
      {
        Header: "Status",
        accessor: "reservationTypeName",
        sortable: false,
        Cell: cellInfo => (
          <span>
            <span>{cellInfo.original.reservationType.reservationTypeName}</span>{" "}
            <br />
            <Label
              size="small"
              className="statusLabel"
              style={{
                backgroundColor: cellInfo.original.backGroundColor,
                color: cellInfo.original.foreground,
                border: "1px solid #f5f5f5",
                borderRadius: "14px"
              }}
            >
              {cellInfo.original.status === 0
                ? "Reserved"
                : cellInfo.original.status === 1
                ? "Checked Out"
                : "Completed"}
            </Label>
          </span>
        )
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <Wrap>
            <Icon
              link
              circular
              color="orange"
              name="search"
              onClick={() => this.handleShowDetailEvent(cellInfo.original)}
            />
          </Wrap>
        ),
        width: 100
      }
    ],
    dropdownItem: [
      {
        key: 1,
        text: "Book Now",
        description: "",
        type: "book"
      },
      {
        key: 2,
        text: "Aircraft Profile",
        description: "",
        type: "profile"
      }
    ],
    search: {
      status: "Active"
    }
  };
  componentDidMount() {
    this.getEvents();
  }
  getEvents = () => {
    const { seletedDate } = this.state;
    let filterItem = "?",
      filterDate = moment(seletedDate).format("YYYY-MM-DD");

    getEventDetailBySearch(
      filterItem + "reservationType.displayType=0&reservationType.displayType=1"
    ).then(res => {
      if (res && res.status === 200) {
        // console.log('getEventDetailBySearch',res)
        this.setState({ events: res.data });
      }
    });
  };
  handleShowDetailEvent = data => {
    this.getDetail(data.reservationId);
  };

  getDetail = id => {
    getEventDetail(id).then(res => {
      if (res && res.status === 200) {
        if (res.data[0])
          this.setState({ eventDetail: res.data[0], openDetail: true });
      }
    });
  };
  // onClickDetail = () => this.setState({ openDetail: true })
  onClickNew = () => {
    this.setState({ open: true });
  };
  onClickSave = data => {
    this.setState({ open: false });
    this.props.reset("reminder");
  };
  onClickCancel = () => {
    this.setState({ open: false, openDetail: false, eventDetail: null });
    this.props.reset("reminder");
  };

  render() {
    const state = this.state;
    // console.log(state.events)
    const { handleSubmit, pristine, submitting } = this.props;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Reservations">
            <MainButton
              size="small"
              text="Add Reservations"
              // onClick={this.onClickNew}
            />
          </HeaderWithMenu>
          <Table
            data={state.events}
            columns={state.column}
            // defaultSorted={[
            //     {
            //         id: "start",
            //         desc: true
            //     },
            // ]}
          />
        </Segment>
        <ModalForm
          content="Add Reservation"
          icon="add"
          open={state.open}
          close={this.onClickCancel}
          save={this.onClickCancel}
           
        >
          <FormReservation />
        </ModalForm>
        <ModalForm
          open={state.openDetail}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em",
            padding: "5px"
          }}
          size="small"
          content="Reservation"
          close={this.onClickCancel}
           
        >
          <EventDetailForm data={state.eventDetail} />
        </ModalForm>
      </div>
    );
  }
}

export default withRouter(MainReservations);
