import React from "react";
import { Form } from "semantic-ui-react";
import Wrap from "./wrap";
import { DatesRangeInput } from "semantic-ui-calendar-react";

const renderDateField = ({
  input,
  label,
  width,
  required,
  minDate,
  maxDate,
  meta: { touched, error, warning },
}) => (
  <Wrap>
    <Form.Field width={width || 16} style={{ marginBottom: "0px" }}>
      <label style={{ textAlign: "left" }}>
        {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
      </label>
      <DatesRangeInput
        name="date"
        placeholder="From - To"
        {...input}
        // preserveViewMode={false}
        hideMobileKeyboard
        dateFormat="DD/MM/YYYY"
        minDate={minDate}
        maxDate={maxDate}
        iconPosition="right"
        onChange={(e, data) => input.onChange(data.value)}
      />
      <p style={{ color: "#9f3a38", textAlign: "left", fontSize: "90%" }}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </p>
    </Form.Field>
  </Wrap>
);

export default renderDateField;
