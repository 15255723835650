import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import { Grid, Icon, List, Header } from "semantic-ui-react";
import EventDetailForm from "./eventDetail";
import ModalForm from "../../components/modal";
import { getUpcoming, getEventDetail } from "./service";
import moment from "moment";

class ScheduleList extends Component {
  state = {
    role: JSON.parse(localStorage.getItem("bac_roles")),
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    upcomingData: {},
  };
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    let { bacUserData, role } = this.state;
    let qString = "?";
    if (role.toString() === "Student")
      qString = qString + "studentId=" + bacUserData.id;
    else qString = qString + "instructorId=" + bacUserData.id;
    getUpcoming(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ upcomingData: res.data });
      }
    });
  };

  getDetail = (id) => {
    getEventDetail(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ eventDetail: res.data, open: true });
      }
    });
  };

  onClickCancel = () => {
    this.setState({ open: false, eventDetail: null });
  };

  render() {
    const state = this.state;
    if (Object.keys(state.upcomingData).length === 0)
      return <div style={{ paddingTop: "15px" }}>No data visible.</div>;
    return (
      <div
        style={{
          paddingTop: "10px",
          maxHeight: "50vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Grid style={{ padding: "10px" }}>
          {Object.keys(state.upcomingData).map((item, key) => (
            <Grid.Row style={{ padding: "4px" }}>
              <Grid.Column
                floated="left"
                textAlign="center"
                mobile={0}
                tablet={4}
                computer={4}
              >
                <Header as="h2">
                  <Header.Content style={{ color: "#5c5c5c" }}>
                    {moment(item, "YYYY-MM-DD").format("ddd,D")}
                    <Header.Subheader className="bold">
                      {moment(item, "YYYY-MM-DD").format("MMMM ")}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                mobile={16}
                tablet={12}
                computer={12}
              >
                <List selection verticalAlign="middle">
                  {state.upcomingData[item].map((row) => (
                    <List.Item onClick={() => this.getDetail(row.id)}>
                      <Icon
                        name="circle"
                        style={
                          row.status === 0
                            ? { color: "#ff9800" }
                            : row.status === 1
                            ? { color: "#96a7af" }
                            : row.status === 2
                            ? { color: "#27a8a2" }
                            : row.status === 3
                            ? { color: "#ce2921" }
                            : { color: "blue" }
                        }
                      />
                      <List.Content className="bold">
                        <List.Header>{row.title}</List.Header>
                        {moment(row.start).format("HH:mm")} -{" "}
                        {moment(row.end).format("HH:mm")}
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        <ModalForm
          open={state.open}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em",
            padding: "5px",
          }}
          size="small"
          content="Reservation"
          close={this.onClickCancel}
        >
          <EventDetailForm disableClick={true} data={state.eventDetail} />
        </ModalForm>
      </div>
    );
  }
}

export default withRouter(ScheduleList);
