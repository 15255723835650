import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Image } from "semantic-ui-react";
import * as config from "../../config";

class QRScan extends Component {

    render() {
        return (
            <Segment className="contentSegment" textAlign="left">
                <Header as="h2" style={{ color: config.colorTheme }}>
                    Preflight Risk Assessment
                </Header>
                <Image src="../img/qr.png" centered style={{ maxHeight: '90vh' }} />
            </Segment>
        );
    }
}

export default withRouter(QRScan);
