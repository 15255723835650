import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import { Header, Segment, Breadcrumb, Loader, Table,Icon,Popup,Button } from "semantic-ui-react";
import { getCourseMaster,getCourseModule,postCoursemaster,updateCoursemaster,deleteCoursemaster} from "./service";
import Wrap from "../../components/wrap";
import MainButton from '../../components/mainbutton'
import HeaderWithMenu from '../../components/headerwithmenu'
import ModalForm from "../../components/modal";
import Formcourse from "./coursemasterForm";
import Confirmmodal from "../../components/confirmmodal";

class CourseMaster extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.getData()
    getCourseModule("?desc=master").then(res => {
      if (res && res.status === 200) {
        let options_module = [];
        res.data.map(item => {
          options_module.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item
          });
        });
        this.setState({ options_module });
      }
    });
  }
  getData = () =>{

    getCourseMaster().then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  }
  onClickNew = () => {
    this.setState({
      open: true,
      isNew:true,
      content: "New Course"
    });
  };
  onClickCancel = () => {
    this.setState({
      open: false,
      initialValues:null
    });
  };

 onClickEdit = value =>{
   let initialValues = {
     id: value.id,
     name: value.name,
     desc: value.desc,
     module: value.module
   };
   this.setState({ initialValues, open: true ,content: "Edit Course",isNew:false});
 }
  saveData = (value) =>{
    this.setState({ loading: true });
    let module = [];
    value.module_add.map(item => {
      if (item.id) module.push(item.id);
    });
    let data = {
      name: value.name,
      desc: value.desc,
      module: module
    };
    postCoursemaster(data).then(res => {
      if (res && res.status === 201) {
        this.setState({ loading: false });
        this.onClickCancel();
        this.getData();
      }
    });
  }
  updateData = (value) =>{
    this.setState({ loading: true });
    let module = [];
    value.module && value.module.map(item => {
      if (item.id) module.push(item.id);
    });
    value.module_add && value.module_add.map(item => {
      if (item.id) module.push(item.id);
    });
    let data = {
      id:value.id,
      name: value.name,
      desc: value.desc,
      module: module
    };
    updateCoursemaster(data).then(res => {
      if (res && res.status === 200) {
        this.setState({ loading: false });
        this.onClickCancel();
        this.getData();
      }
    });
  }
  onClickDelete = value => {
    console.log(value);
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };

  deleteData = () => {
      let { type, currentData } = this.state;
      this.setState({ loading: true });
      if (currentData && currentData.id)
        deleteCoursemaster(currentData.id).then(res => {
          if (res && res.status === 200) {
            this.getData()
            this.onClickCancel();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
  };
  render() {
    const state = this.state;
    return (
      <Wrap>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Course Master Data"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      <ModalForm
        open={state.open}
        icon="book"
        // size='small'
        content={state.content}
        close={this.onClickCancel}
      >
        <Formcourse
          loading={state.loading}
          initialValues={state.initialValues}
          onSubmit={state.isNew ? this.saveData : this.updateData}
          options_module={state.options_module}
          onCancel={this.onClickCancel}
        />
      </ModalForm>
      <Segment className="contentSegment" textAlign="left">
      <HeaderWithMenu name='  Course Master Data' >
          <MainButton
              size='small'
              onClick={this.onClickNew}
              text='Add Course' />
              </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Course")}
            style={{ color: "grey" }}
          >
            Academic
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Course Master Data </Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        {state.loading ? (
          <Loader active inline="centered" content="Loading" />
        ) : (
          <Table compact celled unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="header-table" >
                  Module
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Ground Subjects
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Ground (Hours)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Flight Subjects
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >
                  Flight (Hours)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={2}
                  textAlign="center"
                >

                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.data.map(course => {
                return (
                  <Wrap>
                    <Table.Row style={{ backgroundColor: "#00003205" }}>
                      <Table.Cell className="bold">
                        {course.name} {course.desc && `(${course.desc})`}
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="bold">
                        {course.groundSubjects}
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="bold">
                        {course.groundHours}
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="bold">
                        {course.flightSubjects}
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="bold">
                        {course.flightHours}
                      </Table.Cell>
                      <Table.Cell textAlign="center" className="bold">
                      <Popup
                         trigger={<Icon link name='edit' onClick={() => this.onClickEdit(course)} />}
                         content='Edit Course'
                         position='bottom right'
                     /> &nbsp;
                     <Popup
                        trigger={<Icon   link
                          color="red"
                          name="trash alternate" onClick={() => this.onClickDelete(course)} />}
                        content='Delete Course'
                        position='bottom right'
                    />
                      </Table.Cell>
                    </Table.Row>
                    {course.module.map(module => {
                      return (
                        <Table.Row>
                          <Table.Cell style={{ paddingLeft: "40px" }}>
                            {module.name}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {module.groundSubjects}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {module.groundHours}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {module.flightSubjects}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {module.flightHours}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Wrap>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </Segment>
      </Wrap>

    );
  }
}

export default withRouter(CourseMaster);
