import axios from "axios";
import * as config from "../../config";

export function getEbook() {
  return axios
    .get(config.urlAPI + "/ebook",config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
