import React, { Component } from "react";
import moment from "moment";
// import 'moment/locale/zh-cn';
import "react-big-scheduler/lib/css/style.css";
import Scheduler, {
  SchedulerData,
  ViewTypes,
  DATE_FORMAT
} from "react-big-scheduler";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

class horiCalendar extends Component {
  constructor(props) {
    super(props);
    let schedulerData = new SchedulerData(
      new moment(props.day).format(DATE_FORMAT),
      ViewTypes.Day,
      false,
      false,
      {
        // schedulerWidth: localStorage.getItem("expand") === 'true' ? '40%' : '98%',
        schedulerWidth: "78.5%",
        schedulerMaxHeight: 550,
        dayCellWidth: 40,

        // dayMaxEvents: 100,
        // dayResourceTableWidth: 500,
        dayStartFrom: props.start,
        dayStopTo: props.stop,
        eventItemPopoverEnabled: false,
        calendarPopoverEnabled: false,
        nonAgendaDayCellHeaderFormat: "HH:mm",
        nonWorkingTimeBodyBgColor: "#f5f5f5",
        nonWorkingTimeHeadBgColor: "#f5f5f5",
        startResizable: false,
        endResizable: false,
        creatable: true,
        headerEnabled: false,
        resourceName: "Date"
      },
      {
        isNonWorkingTimeFunc: this.isNonWorkingTime
      }
    );
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources(props.resources);
    schedulerData.setEvents(props.events);
    this.state = {
      viewModel: schedulerData
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.resources !== this.props.resources) {
      let { viewModel } = this.state;
      viewModel.setResources(nextProps.resources);
      this.setState({ viewModel: viewModel });
      // this.state = {
      //   viewModel: viewModel
      // };
    }
    if (nextProps.events !== this.props.events) {
      let { viewModel } = this.state;
      viewModel.setEvents(nextProps.events);
      this.setState({ viewModel: viewModel });
    }
  }

  isNonWorkingTime = (schedulerData, time) => {
    let datetime = schedulerData.localeMoment(time).format("HH");
    if (datetime === "12") return true;
    return false;
  };
  eventItemTemplateResolver = (
    schedulerData,
    event,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );
    let divStyle = {
      borderRadius: "0px",
      backgroundColor: bgColor,
      color: event.textColor,
      height: mustBeHeight,
      // height: mustBeHeight + 19,
      textAlign: "center"
    };
    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };
    return (
      <div key={event.id} className={mustAddCssClass} style={divStyle}>
        <span style={{ marginLeft: "14px", lineHeight: `${mustBeHeight}px` }}>
          {titleText}
        </span>
      </div>
    );
  };
  render() {
    const { viewModel } = this.state;
    return (
      <div
      // style={{
      //   height: "80vh",
      //   overflowY: "auto",
      //   overflowX: "hidden"
      // }}
      >
        <Scheduler
          schedulerData={viewModel}
          eventItemClick={(e, event) => this.props.eventClicked(event)}
          eventItemTemplateResolver={this.eventItemTemplateResolver}
        />
      </div>
    );
  }

  onViewChange = e => {
    console.log(e);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    this.props.slotClicked(slotId, start, end);
  };
}

export default DragDropContext(HTML5Backend)(horiCalendar);
