import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Segment, Header, Button, Icon } from "semantic-ui-react";
import * as config from "../../config";
import MainButton from "../../components/mainbutton";
import Table from "../../components/table";
import ModalForm from "../../components/modal";
import FormSnags from "./snagsForm";
import SnagsDetail from "./snagsDetail";
import HeaderWithMenu from "../../components/headerwithmenu";
import { snagsColumn } from "./tbconfig";
import {
  getSnagsBySearch,
  getSnags,
  getAircraftList,
  getSnagsDetail
} from "./service";
import moment from "moment";

class Snags extends Component {
  state = {
    open: false,
    loading: false,
    rawdata: [],
    data: [],
    snagsDetail: [],
    column: [
      {
        Header: "Created",
        accessor: "created",
        style: { textAlign: "left" },
        sortable: false,
        Cell: cellInfo =>
          moment(cellInfo.original.created).format("DD/MM/YYYY"),
        width: 150
      },
      {
        Header: "Aircraft",
        accessor: "name",
        sortable: false,
        Cell: cellInfo => (
          <span
            className="bold"
            onClick={() => this.detailSnags(cellInfo.original)}
          >
            {cellInfo.original.tailNumber} {cellInfo.original.make}{" "}
            {cellInfo.original.model}
          </span>
        ),
        width: 250
      },
      {
        Header: "SNAG",
        sortable: false,
        accessor: "discrepancy"
      },
      {
        Header: "Status",
        accessor: "cyclesRemainingMessage",
        sortable: false,
        Cell: cellInfo => (
          <span>
            {cellInfo.original.isClosed ? "(Closed)" : null}{" "}
            {cellInfo.original.resolution}
          </span>
        )
      },
      {
        Header: "Action",
        accessor: "datail",
        width: 100,
        sortable: false,
        style: { textAlign: "center", paddingTop: "10px" },
        Cell: cellInfo => (
          <Icon
            link
            circular
            color="orange"
            name="exclamation triangle"
            onClick={() => this.detailSnags(cellInfo.original)}
          />
        )
      }
    ],
    search: {
      status: 2
    },
    statusText: "Open Snags",
    options_snags: [
      { key: "1", text: "All Status", value: 1 },
      { key: "2", text: "Open Snags", value: 2 },
      { key: "3", text: "Closed Snags", value: 3 }
    ],
    option_aircraft: []
  };

  componentDidMount() {
    let qString = "?isClosed=false";
    this.getDataBySearch(qString);
    this.getOptionAircraft();
  }

  getData = () => {
    getSnags().then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data, rawdata: res.data });
      }
    });
  };

  getDataBySearch = qString => {
    getSnagsBySearch(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data, rawdata: res.data });
      }
    });
  };

  getOptionAircraft = () => {
    let option_aircraft = [];
    getAircraftList().then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option_aircraft.push({
            key: item.id,
            id: item.id,
            title: `${item.tailNumber} ${item.make} ${item.model}`,
            text: `${item.tailNumber} ${item.make} ${item.model}`,
            value: item
          });
        });
        this.setState({ option_aircraft });
      }
    });
  };

  filterDataByStatus = condition => {
    if (condition == "all") {
      this.getData();
    } else {
      let { search } = this.state;
      let qString = "?status=" + search.status;
      this.getDataBySearch(qString);
    }
  };

  handleChangeStatus = (e, data) => {
    let { search } = this.state;
    search[data.name] = data.value;
    this.setState({ search });

    if (data.value === 1) {
      this.setState({ statusText: "All Status" });
      this.getData();
    } else if (data.value === 2) {
      this.setState({ statusText: "Open Snags" });
      let qString = "?isClosed=false";
      this.getDataBySearch(qString);
    } else if (data.value === 3) {
      this.setState({ statusText: "Closed Snags" });
      let qString = "?isClosed=true";
      this.getDataBySearch(qString);
    }
  };

  handleChangeSearch = e => {
    let { rawdata, data, search } = this.state;
    search[e.target.name] = e.target.value;
    this.setState({ search });
    if (e.target.value) {
      let newdata = [];
      data.map(item => {
        let description = `${item.tailNumber} ${item.make} ${item.model}`;
        console.log(
          description.toLowerCase().indexOf(e.target.value.toLowerCase())
        );
        if (
          description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      console.log(e.target.value, newdata);
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawdata });
    }
  };

  handleChangeFormOnetime = (e, data) => {
    this.setState({ aircraftOneTime: data });
  };
  handleChangeFormRemindtype = (e, data) => {
    this.setState({ aircraftRemindtype: data });
  };
  onClickNew = () => this.setState({ open: true });
  onClickSave = () => this.setState({ open: false });
  onClickCancel = () => this.setState({ open: false, openDetail: false });

  detailSnags = data => {
    getSnagsDetail(data.id).then(res => {
      if (res && res.status === 200) {
        this.setState({ snagsDetail: res.data, openDetail: true });
      }
    });
  };

  render() {
    const state = this.state;
    return (
      <div>
        <ModalForm
          open={state.open}
          icon="exclamation triangle"
          content=" Add Snag"
          close={this.onClickCancel}
          save={this.onClickCancel}
           
        >
          <FormSnags option_aircraft={state.option_aircraft} />
        </ModalForm>
        <ModalForm
          open={state.openDetail}
          icon="warning sign"
          content="Snags"
          close={this.onClickCancel}
          save={this.onClickCancel}
           
        >
          <SnagsDetail data={state.snagsDetail} />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="SNAGS">
            <MainButton
              size="small"
              text="Add Snag"
              onClick={this.onClickNew}
            />
          </HeaderWithMenu>
          <Form>
            <Form.Group>
              <Form.Input
                width="3"
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                width="3"
                style={{ width: "100%" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{state.statusText}</b>
                  </Button>
                }
                options={state.options_snags}
                defaultValue={state.search.status}
                onChange={this.handleChangeStatus}
              />
              {/* <Form.Field width='10' style={{ textAlign: 'right' }}>
                                <MainButton
                                    size='small'
                                    text='Add Snag'
                                    onClick={this.onClickNew} />
                            </Form.Field> */}
            </Form.Group>
          </Form>
          <Table
            data={state.data}
            columns={state.column}
            defaultSorted={[
              {
                id: "status",
                desc: true
              }
            ]}
          />
        </Segment>
      </div>
    );
  }
}

export default withRouter(Snags);
