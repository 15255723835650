import React, { Component } from "react";
import { Form, Button, Message } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
// import validate from "./validate";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderDateField from "../../components/renderDateField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import { roles } from "../Settings/data";
import { options_title_eng, options_title_th,options_active } from "../../config";
// วานีเพิ่ม validate
const validate = values => {
  const errors = {};
  if (!values.staffID) errors.staffID = true;
  if (!values.firstNameEng) errors.firstNameEng = true;
  if (!values.lastNameEng) errors.lastNameEng = true;
  if (!values.firstNameTh) errors.firstNameTh = true;
  if (!values.lastNameTh) errors.lastNameTh = true;
  if (!values.email) errors.email = true;
  let regex = /^([a-z\d\.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})(\.[a-z]{2,8})?$/;
  if (values.email && !regex.test(values.email)) errors.email = 'Invalid email format';
  if (!values.dateOfBirth) errors.dateOfBirth = true;
  // if (!values.status) errors.status = true;
  return errors;
};

class FromStaff extends Component {
  state = {
    optionsRoles: [],
    radioWrktime: [
      { key: "r01", value: "IP Fulltime", label: "IP Fulltime" },
      { key: "r02", value: "IP Parttime", label: "IP Parttime" }
    ],
    radioCategories: [
      { key: "c01", value: "CAT A", label: "CAT A" },
      { key: "c02", value: "CAT B", label: "CAT B" },
      { key: "c03", value: "CAT C", label: "CAT C" },
      { key: "c04", value: "CAT D", label: "CAT D" }
    ],
    optionsGender: [
      { key: "gender01", value: "Male", text: "Male" },
      { key: "gender02", value: "Female", text: "Female" }
    ],

  };

  componentDidMount() {
    let options = [];
    roles.map(item => {
      options.push({
        key: item.id,
        value: item.name,
        text: item.name
      });
    });
    this.setState({ optionsRoles: options });
  }

  render() {
    const state = this.state;
    const props = this.props;
    const isAdd = this.props.isAdd;
    const { handleSubmit, pristine, submitting, loading, } = this.props;

    return (
      <div>
        <Form onSubmit={handleSubmit}>
          {/* {!isAdd &&
            <Field
              name="isActive"
              label="Active"
              component={renderRadioField}
            />
          }*/}
          <Form.Group widths="equal">
            <Field
              name="staffID"
              type="text"
              required={true}
              component={renderField}
              label="Staff ID"
            />
            <Field
              name="position"
              type="text"
              // required={true}
              component={renderField}
              label="Position"
            />
          </Form.Group>
          <Form.Group widths='3'>
            <Field
              name="titleEng"
              // width='3'
              component={renderSelectField}
              label="Title (EN)"
              options={options_title_eng}
            />
            <Field
              name="firstNameEng"
              type="text"
              // width="6"
              required={true}
              component={renderField}
              label="First name (EN)"
            />
            <Field
              name="lastNameEng"
              type="text"
              // width="6"
              required={true}
              labelDesc="(EN)"
              component={renderField}
              label="Last name (EN)"
            />
          </Form.Group>

          <Form.Group widths='3'>
            <Field
              name="titleTh"
              component={renderSelectField}
              label="Title (TH)"
              options={options_title_th}
            />
            <Field
              name="firstNameTh"
              type="text"
              // width="6"
              required={true}
              component={renderField}
              label="First name (TH)"
            />
            <Field
              name="lastNameTh"
              type="text"
              // width="6"
              required={true}
              component={renderField}
              label="Last name (TH)"
            />
          </Form.Group>

          <Field
            name="nickName"
            type="text"
            width="8"
            // required={true}
            component={renderField}
            label="Nick name"
          />
          <Form.Group widths="equal">
            <Field
              name="personnel_email"
              type="text"
              // required={true}
              component={renderField}
              label="Personnel Email"
            />
            <Field
              name="phone"
              type="text"
              component={renderField}
              label="Phone"
            />
          </Form.Group>
          <Field
            name="email"
            type="text"
            required={true}
            component={renderField}
            label="BAC Email"
          />
        <Field
            name="status"
            required={true}
            component={renderSelectField}
            label="Staff status"
            width="8"
            options={options_active}
          />

          <Form.Group widths="equal">
            <Field
              name="dateOfBirth"
              required={true}
              component={renderDateOnlyField}
              label="Date of Birth"
            />
            <Field
              name="gender"
              component={renderSelectField}
              label="Gender"
              options={state.optionsGender}
            />
          </Form.Group>
          <br />
          <Button
            floated="left"
            type="submit"
            content="Save"
            color="green"
            loading={loading}
            disabled={pristine || submitting}
          />
          <Button
            floated="right"
            type="button"
            content="Cancel"
            color="green"
            basic
            onClick={this.props.onCancel}
          />
        </Form>
      </div>
    );
  }
}

FromStaff = reduxForm({
  // a unique name for the form
  form: "staffform",
  validate,
  enableReinitialize: true
})(FromStaff);
export default FromStaff;
