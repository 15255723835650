import React, { Component } from "react";
// import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Switch, Route } from "react-router-dom";
import Home from "./containers/home";
import Instructors from "./containers/Instructors/instructors";
import ProfileInstructors from "./containers/Instructors/profile";
import Aircraft from "./containers/Aircraft/aircraft";
import MaintReminders from "./containers/Aircraft/reminder";
import ProfileAircraft from "./containers/Aircraft/profile";
import Reservations from "./containers/Schedule/reservations";
import Schedule from "./containers/Schedule/schedule";
import People from "./containers/People/people";
import Mainpeople from "./containers/People/main";
import ProfilePeople from "./containers/People/profile";
import RoleSettings from "./containers/Settings/roles";
import EbookSetting from "./containers/Settings/ebook";
// import SettingsGroup from "./containers/Settings/groups";
import SettingNews from "./containers/Settings/news";
import Room from "./containers/Room/room";
import Staff from "./containers/Staff/staff";
import ProfileStaff from "./containers/Staff/profile";
import Student from "./containers/Student/student";
import Registration from "./containers/Registration/regis";
import ProfileStudent from "./containers/Student/profile";
import Snags from "./containers/Aircraft/snags";
import ActivityType from "./containers/ActivityType/activityType";
import MainCourse from "./containers/Course/main";
import Course from "./containers/Course/course";
import CourseDetail from "./containers/Course/courseDetail";
import CourseMaster from "./containers/Course/coursemaster";
import CourseMasterModule from "./containers/Course/coursemasterModule";
import CourseModule from "./containers/Course/courseModule";
import CourseSubjects from "./containers/Course/courseSubjects";
import CourseFlightSubjects from "./containers/Course/courseFlightSubjects";
import CourseLesson from "./containers/Course/lesson";
import AircraftSetting from "./containers/Settings/aircarftmain";
import PersonnelSetting from "./containers/Settings/personnelmain";
import RoomSetting from "./containers/Settings/roommain";
import AcademicSetting from "./containers/Settings/academicmain";
import UserSetting from "./containers/Settings/user";
import UserStudentSetting from "./containers/Settings/userStudent";
import FlightRulesSetting from "./containers/Settings/flightrulesmain";

import DisciplineScoreSetting from "./containers/Settings/disciplineScore";
import Dashboard from "./containers/Dashboard/dashboard";
import Ebook from "./containers/Ebook/ebook";
import Portfolio from "./containers/Portfolio/portfolio";
// import Score from './containers/Score/score'
import AdminAttendance from "./containers/Attendance/attendance_admin";
import InstructorAttendance from "./containers/Attendance/attendance";
import PageNotFound from "./containers/404";
import FlightTable from "./containers/Schedule/flightTable";
import FlightHourRecord from "./containers/Schedule/flightHourRecord";
import FlightHourRecordDetail from "./containers/Schedule/flightHourRecordDetail";
import AdminTrainRecord from "./containers/TrainingRecord/Admin/flightTrainingRecord";
import StudentTrainRecord from "./containers/TrainingRecord/Student/flightTrainingRecord";
import InstructorTrainRecord from "./containers/TrainingRecord/Instructor/flightTrainingRecord";
import EditSnagInstructor from "./containers/TrainingRecord/Instructor/snag_edit";
import EditSnagStudent from "./containers/TrainingRecord/Student/snag_edit";
import AdminScore from "./containers/TrainingRecord/Admin/score";
import StudentScore from "./containers/TrainingRecord/Student/score";
import InstructorScore from "./containers/TrainingRecord/Instructor/score";
import ScheduleTrack from "./containers/ScheduleTrack/scheduleTrack";
import PortfolioProfile from "./containers/Portfolio/profile";
import ScheduleGround from "./containers/Schedule/scheduleGround";

import Logbook from "./containers/Course/logbook";

import SettingGoogle from "./containers/Settings/googlesetting";
import MainReport from "./containers/Report/main";
import FlightPlanReport from "./containers/Report/flightplan";
import CrewVoyageReport from "./containers/Report/crewvoyage";
import IPFlightHourReport from "./containers/Report/ipflighthour";
import GroundCancelReport from "./containers/Report/groundcancel";
import GroundCompensationReport from "./containers/Report/groundcompensation";
import FlightReservationReport from "./containers/Report/reservation_flight";
import GroundReservationReport from "./containers/Report/reservation_ground";
import StudentDatabaseReport from "./containers/Report/studentdatabase";
import PerformanceReport from "./containers/Report/performance";
import ProficiencyCheck from "./containers/Report/proficiencycheck";
import StudentSummary from "./containers/Report/studentsummary";
import ReportOffense from "./containers/Discipline/discipline_instuctor";

import QRScan from "./containers/WeightBalance/qr";
import Cessna from "./containers/WeightBalance/cessna";
import Diamond from "./containers/WeightBalance/diamond";
import Performancedatae from "./containers/WeightBalance/performancedata";

// import LogbookIP from "./containers/Logbook/logbookIP";
// import LogbookSP from "./containers/Logbook/logbookSP";
import { Grid, Header, Icon } from "semantic-ui-react";

const PagePermission = () => (
  <Grid>
    <Grid.Column width={16} textAlign="center">
      <br />
      <Header as="h3" icon color="grey">
        <Icon name="warning" />
        Not Allowed to Access this Page
      </Header>
    </Grid.Column>
  </Grid>
);

const PrivateRoute = ({
  component: Component,
  group,
  permission,
  path,
  ...rest
}) => {
  let bacUserData = JSON.parse(localStorage.getItem("bac_permission"));
  let userpermissionGroup = bacUserData.find((item) => item.group === group);
  let allowed = permission.find((item) => {
    if (
      userpermissionGroup &&
      userpermissionGroup.permission.indexOf(item) !== -1
    )
      return item;
  });

  if (allowed)
    return (
      <Route exact {...rest} render={(props) => <Component {...props} />} />
    );
  else return <Route exact {...rest} render={(props) => <PagePermission />} />;
};

class Routes extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/settinggoogle" component={SettingGoogle} />
          <Route exact path="/" component={Home} />
          <Route exact path="/Home" component={Home} />
          <PrivateRoute
            exact
            path="/Aircrafts"
            component={Aircraft}
            group="Resource"
            permission={["manage-aircraftsimulator"]}
          />
          <PrivateRoute
            exact
            path="/Aircraft/Profile"
            component={ProfileAircraft}
            group="Resource"
            permission={["manage-aircraftsimulator"]}
          />

          <Route
            exact
            path="/MaintReminders"
            component={(props) => <MaintReminders {...props} />}
          />

          <PrivateRoute
            exact
            path="/Reservations"
            component={Reservations}
            group="Schedule"
            permission={["manage-schedule", "view-schedule"]}
          />
          <PrivateRoute
            exact
            path="/Schedule"
            component={Schedule}
            group="Schedule"
            permission={["manage-schedule", "view-schedule"]}
          />
          <PrivateRoute
            exact
            path="/FlightTable"
            component={FlightTable}
            group="Schedule"
            permission={["manage-flighttable", "view-flighttable"]}
          />
          <PrivateRoute
            exact
            path="/FlightHourRecord"
            component={FlightHourRecord}
            group="Schedule"
            permission={["manage-flighthourrecord", "save-flighthourrecord"]}
          />
          {/*}<Route
            exact
            path="/FlightHourRecord/Detail"
            component={props => <FlightHourRecordDetail {...props} />}
          />*/}
          <PrivateRoute
            exact
            path="/Track"
            component={ScheduleTrack}
            group="Schedule"
            permission={["view-schedule-tracking"]}
          />

          <PrivateRoute
            exact
            path="/GroundTrainingSchedule"
            component={ScheduleGround}
            group="Academic"
            permission={["manage-groundtraining-admin"]}
          />

          <PrivateRoute
            exact
            path="/Registration"
            component={Registration}
            group="Academic"
            permission={["manage-registration"]}
          />
          <PrivateRoute
            exact
            path="/Course/Course"
            component={Course}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/CourseDetail"
            component={CourseDetail}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/CourseMaster"
            component={CourseMaster}
            group="Resource"
            permission={["manage-academic"]}
          />

          <PrivateRoute
            exact
            path="/Course/CourseMasterModule"
            component={CourseMasterModule}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/Module"
            component={CourseModule}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/GroundSubjects"
            component={CourseSubjects}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/FlightSubjects"
            component={CourseFlightSubjects}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/Lesson"
            component={CourseLesson}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course/Logbook"
            component={Logbook}
            group="Resource"
            permission={["manage-academic"]}
          />
          <PrivateRoute
            exact
            path="/Course"
            component={MainCourse}
            group="Resource"
            permission={["manage-academic"]}
          />

          <PrivateRoute
            exact
            path="/Instructors"
            component={Instructors}
            group="Personnel"
            permission={["manage-instructor", "view-instructor"]}
          />
          <PrivateRoute
            exact
            path="/Instructors/Profile"
            component={ProfileInstructors}
            group="Personnel"
            permission={["manage-instructor", "view-instructor"]}
          />
          <Route
            exact
            path="/InstructorProfile"
            component={(props) => <ProfileInstructors {...props} />}
          />
          <Route
            exact
            path="/StudentProfile"
            component={(props) => <ProfileStudent {...props} />}
          />
          <Route
            exact
            path="/StaffsProfile"
            component={(props) => <ProfileStaff {...props} />}
          />
          <Route
            exact
            path="/People"
            component={(props) => <People {...props} />}
          />
          <Route
            exact
            path="/ActivityType"
            component={(props) => <ActivityType {...props} />}
          />
          <Route
            exact
            path="/MyPortfolio"
            component={(props) => <PortfolioProfile {...props} />}
          />
          <Route
            exact
            path="/Room"
            component={(props) => <Room {...props} />}
          />
          {/* <Route exact path="/MeetingRoom" component={props => <MeetingRoom {...props} />} /> */}
          <Route
            exact
            path="/People/Profile"
            component={(props) => <Mainpeople {...props} />}
          />
          <PrivateRoute
            exact
            path="/Staffs"
            component={Staff}
            group="Personnel"
            permission={["manage-staff", "view-staff"]}
          />
          <PrivateRoute
            exact
            path="/Staffs/Profile"
            component={ProfileStaff}
            group="Personnel"
            permission={["manage-staff", "view-staff"]}
          />
          <PrivateRoute
            exact
            path="/Students"
            component={Student}
            group="Personnel"
            permission={["manage-student", "view-student"]}
          />
          <PrivateRoute
            exact
            path="/Students/Profile"
            component={ProfileStudent}
            group="Personnel"
            permission={["manage-student", "view-student"]}
          />

          <PrivateRoute
            exact
            path="/User"
            component={UserSetting}
            group="Management"
            permission={["manage-user"]}
          />
          <PrivateRoute
            exact
            path="/SettingUserStudent"
            component={UserStudentSetting}
            group="Management"
            permission={["manage-user-student"]}
          />
          <Route
            exact
            path="/Snags"
            component={(props) => <Snags {...props} />}
          />
          <PrivateRoute
            exact
            path="/NewsSetting"
            component={SettingNews}
            group="Management"
            permission={["manage-alertnews"]}
          />

          <PrivateRoute
            exact
            path="/Offense"
            component={ReportOffense}
            group="Management"
            permission={["manage-disciplinescore"]}
          />
          {/* <Route
            exact
            path="/NewsSetting"
            component={props => <SettingNews {...props} />}
          /> */}
          {/* <Route
            exact
            path="/RolesSetting"
            component={props => <RoleSettings {...props} />}
          /> */}

          <PrivateRoute
            exact
            path="/RolesSetting"
            component={RoleSettings}
            group="Management"
            permission={["manage-role"]}
          />
          {/* <Route
            exact
            path="/AircraftSetting"
            component={props => <AircraftSetting {...props} />}
          /> */}
          <PrivateRoute
            exact
            path="/AircraftSetting"
            component={AircraftSetting}
            group="Management"
            permission={["manage-aircrafts"]}
          />

          <PrivateRoute
            exact
            path="/PersonnelSetting"
            component={PersonnelSetting}
            group="Management"
            permission={["manage-personnel"]}
          />

          <PrivateRoute
            exact
            path="/FlightRulesSetting"
            component={FlightRulesSetting}
            group="Management"
            permission={["manage-flighttimerule"]}
          />

          <PrivateRoute
            exact
            path="/DisciplineScoreSetting"
            component={DisciplineScoreSetting}
            group="Management"
            permission={["manage-disciplinescore"]}
          />

          <PrivateRoute
            exact
            path="/LocationSetting"
            component={RoomSetting}
            group="Management"
            permission={["manage-roomlocation"]}
          />

          <PrivateRoute
            exact
            path="/AcademicSetting"
            component={AcademicSetting}
            group="Management"
            permission={["manage-groundinstructiontype"]}
          />

          <PrivateRoute
            exact
            path="/RolesSetting"
            component={RoleSettings}
            group="Management"
            permission={["manage-role"]}
          />
          <PrivateRoute
            exact
            path="/EbookSetting"
            component={EbookSetting}
            group="Management"
            permission={["manage-ebook"]}
          />
          <Route
            exact
            path="/Dashboard"
            component={(props) => <Dashboard {...props} />}
          />
          <PrivateRoute
            exact
            path="/Ebook"
            component={Ebook}
            group="Academic"
            permission={["manage-ebook", "view-ebook"]}
          />
          <PrivateRoute
            exact
            path="/Portfolio"
            component={Portfolio}
            group="Academic"
            permission={["manage-portfolio", "view-portfolio"]}
          />
          <PrivateRoute
            exact
            path="/Portfolio/Profile"
            component={PortfolioProfile}
            group="Academic"
            permission={["manage-portfolio", "view-portfolio"]}
          />

          {/* <Route exact path="/Score" component={props => <Score {...props} />} /> */}
          <PrivateRoute
            exact
            path="/AdminAttendance"
            component={AdminAttendance}
            group="Academic"
            permission={["manage-groundtraining-admin"]}
          />
          <PrivateRoute
            exact
            path="/InstructorAttendance"
            component={InstructorAttendance}
            group="Academic"
            permission={["manage-groundtraining-instructor"]}
          />
          <PrivateRoute
            exact
            path="/AdminTrainRecord"
            component={AdminTrainRecord}
            group="Academic"
            permission={["manage-flighttraining-admin"]}
          />
          <PrivateRoute
            exact
            path="/InstructorTrainRecord"
            component={InstructorTrainRecord}
            group="Academic"
            permission={["manage-flighttraining-instructor"]}
          />
          <PrivateRoute
            exact
            path="/TrainRecord"
            component={StudentTrainRecord}
            group="Academic"
            permission={["manage-flighttraining-student"]}
          />
          {/*<PrivateRoute
            exact
            path="/AdminTrainRecord/Score"
            component={AdminScore}
            group="Academic"
            permission={["manage-flighttraining-admin"]}
          />*/}
          <Route
            exact
            path="/AdminTrainRecord/Score"
            component={(props) => <AdminScore {...props} />}
          />
          <PrivateRoute
            exact
            path="/InstructorTrainRecord/Score"
            component={InstructorScore}
            group="Academic"
            permission={["manage-flighttraining-instructor"]}
          />
          <PrivateRoute
            exact
            path="/TrainRecord/Score"
            component={StudentScore}
            group="Academic"
            permission={["manage-flighttraining-student"]}
          />
          <PrivateRoute
            exact
            path="/InstructorTrainRecord/Snag"
            component={EditSnagInstructor}
            group="Academic"
            permission={["manage-flighttraining-instructor"]}
          />
          <PrivateRoute
            exact
            path="/TrainRecord/Snag"
            component={EditSnagStudent}
            group="Academic"
            permission={["manage-flighttraining-student"]}
          />
          <PrivateRoute
            exact
            path="/Report"
            component={MainReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/FlightPlan"
            component={FlightPlanReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/CrewVoyage"
            component={CrewVoyageReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/IPFlightHour"
            component={IPFlightHourReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/GoundCancel"
            component={GroundCancelReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/GoundCompensation"
            component={GroundCompensationReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/FlightReservation"
            component={FlightReservationReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/GroundReservation"
            component={GroundReservationReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/StudentDatabase"
            component={StudentDatabaseReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/Performance"
            component={PerformanceReport}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/ProficiencyCheck"
            component={ProficiencyCheck}
            group="Report"
            permission={["view-report"]}
          />
          <PrivateRoute
            exact
            path="/Report/StudentSummary"
            component={StudentSummary}
            group="Report"
            permission={["view-report"]}
          />
          <Route
            exact
            path="/qr"
            component={(props) => <QRScan {...props} />}
          />
          <Route
            exact
            path="/Cessna"
            component={(props) => <Cessna {...props} />}
          />
          <Route
            exact
            path="/Diamond"
            component={(props) => <Diamond {...props} />}
          />
          <Route
            exact
            path="/file"
            component={(props) => <Performancedatae {...props} />}
          />

          <Route component={PageNotFound} />
        </Switch>
      </div>
    );
  }
}

export default Routes;
