import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Segment, Header, List, Table, Image, Button, Icon } from 'semantic-ui-react'
import * as config from '../../config'
import MainButton from '../../components/mainbutton'



class Dashboard extends Component {


    render() {
        const state = this.state
        return (
            <div>
                <Segment className="contentSegment" textAlign='left'>
                    <Header as='h2' style={{ color: config.colorTheme }}>Dashboard</Header>
                    <Header as='h4' dividing>People</Header>
                    <List verticalAlign='middle' relaxed >
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Document Expiration</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Contact Information</List.Content>
                        </List.Item>
                    </List>
                    <Header as='h4' dividing>Scheduling</Header>
                    <List verticalAlign='middle' relaxed >
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Reservation Detail</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Cancellation Detail</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Class/Meeting Detail</List.Content>
                        </List.Item>
                    </List>
                    <Header as='h4' dividing>Aircraft</Header>
                    <List verticalAlign='middle' relaxed >
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Flight Detail</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Flight Hours by Aircraft</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Maintenance Status</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Maintenance Reminders</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Aircraft Snags</List.Content>
                        </List.Item>
                        <List.Item style={{ padding: '8px' }}>
                            <List.Content>Time to Next Service</List.Content>
                        </List.Item>
                    </List>
                    <br /><br />
                </Segment>
            </div>
        )
    }
}

export default withRouter(Dashboard)
