import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Image } from "semantic-ui-react";
import AppRoutes from "../routes";
import Menuexpand from "./menu";
import HeaderMenu from "./headmenu";
import ErrorBoundary from "../components/errorBoundary";
class Layout extends Component {
  state = {
    menuSize: 3,
    contentSize: 13,
    visible: false,
  };

  handleButtonClick = () => {
    const { visible } = this.state;
    if (visible) {
      this.setState({ visible: false });
    } else {
      this.setState({ visible: true });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ visible: false });
  }
  render() {
    const state = this.state;
    return (
      <div>
        <div
          id="myNav"
          className="overlay"
          style={{ width: state.visible ? "100%" : "0%", zIndex: 200 }}
        >
          <div className="overlay-content">
            <Menuexpand />
          </div>
        </div>
        <Grid style={{ margin: 0 }}>
          <Grid.Row
            style={{ padding: 0 }}
            className="FIXHEADER"
            id="myFIXHEADER"
          >
            <Grid.Column
              only="computer tablet"
              width={state.menuSize}
              style={{ backgroundColor: "#fff", height: "45px" }}
            >
              <Image
                id="myLogo"
                centered
                src="../img/logo-bac.png"
                style={{
                  height: "100%",
                  width: "auto",
                }}
              />
            </Grid.Column>
            <Grid.Column
              computer={state.contentSize}
              tablet={state.contentSize}
              mobile={16}
              style={{ padding: 0, height: "45px" }}
            >
              <HeaderMenu
                showMenuForMobile={this.handleButtonClick}
                visible={state.visible}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ padding: 0, minHeight: "100vh" }}>
            <Grid.Column
              id="mySideMenu"
              only="computer tablet"
              width={state.menuSize}
              style={{
                padding: 0,
                marginTop: "40px",
                boxShadow: "1px 2px 15px 0px #9e9e9e70",
              }}
            >
              <Menuexpand />
            </Grid.Column>
            <Grid.Column
              computer={state.contentSize}
              tablet={state.contentSize}
              mobile={16}
              style={{
                padding: 0,
                marginTop: "40px",
                backgroundColor: "#f3f4f6",
              }}
            >
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(Layout);
