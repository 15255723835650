import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Table, List, Statistic } from "semantic-ui-react";
import moment from "moment";

class FlightData extends Component {
  render() {
    const { data, flightData,logbook } = this.props;
    if (data)
      return (
        <div style={{ padding: "5px", textAlign: "center" }}>
          {flightData.flightInstruction && (
            <Statistic color="orange" size="tiny">
              <Statistic.Label>Flight Instruction</Statistic.Label>
              <Statistic.Value>{flightData.flightInstruction} hr(s)</Statistic.Value>
            </Statistic>
          )}
          {flightData.flightInstruction && (
            <Grid columns="2" style={{ textAlign: "left" }}>
              <Grid.Column>
                {flightData.logbookIP && (
                  <List size="tiny" bulleted>
                    <p
                      className="bold"
                      style={{ margin: "2px", textAlign: "center" }}
                    >
                      Logbook - IP
                    </p>
                    {flightData.logbookIP && flightData.logbookIP.map(item =>
                        <List.Item className="bold">
                          {item.name}{" "}
                          <List.Content floated="right">
                            {
                              item.name === 'TEST NEW AIRCRAFT' ||
                              item.name === 'MONITOR' ||
                              item.name === 'CAAT CHECK' ||
                              item.name === 'TEST' ||
                              item.name === 'TEST ENGINE' ? 1 : <p>{flightData.flightInstruction} hr(s)</p>
                            }

                          </List.Content>
                        </List.Item>
                    )}
                  </List>
                )}
                <br/>
                  {flightData.instructorSV && (
                    <List size="tiny" bulleted>
                      <p
                        className="bold"
                        style={{ margin: "2px", textAlign: "center" }}
                      >
                        Logbook - IP (SV)
                      </p>
                      {flightData.logbookSV && flightData.logbookSV.map(item =>
                          <List.Item className="bold">
                            {item.name}{" "}
                            <List.Content floated="right">
                              {
                                item.name === 'TEST NEW AIRCRAFT' ||
                                item.name === 'MONITOR' ||
                                item.name === 'TEST ENGINE' ? 1 : <p>{flightData.flightInstruction} hr(s)</p>
                              }
                            </List.Content>
                          </List.Item>
                      )}
                    </List>
                  )}
              </Grid.Column>
              <Grid.Column>
                {flightData.logbookSP && (
                  <List size="tiny" bulleted>
                    <p
                      className="bold"
                      style={{ margin: "2px", textAlign: "center" }}
                    >
                      Logbook - SP
                    </p>
                    {flightData.logbookSP.map(item =>
                        <List.Item className="bold">
                          {item.name}{" "}
                          <List.Content floated="right">
                            {flightData.flightInstruction} hr(s)
                          </List.Content>
                        </List.Item>
                    )}
                  </List>
                )}
              </Grid.Column>
            </Grid>
          )}

          {/* {  <Table compact>
            <Table.Body>
              <Table.Row cells="6">
                <Table.Cell content="Hobbs Out" className="bold" />
                <Table.Cell content={data.hobbsOut || "-"} />
                <Table.Cell content="Tach 1 Out" className="bold" />
                <Table.Cell content={data.tach1Out || "-"} />

                                    flightType === 2
                                        && <div>
                                            <Table.Cell content='Tach 2 Out' className='bold' />
                                            <Table.Cell content={data.tach2Out || '-'} />
                                        </div>

                                }
              </Table.Row>
              <Table.Row>
                <Table.Cell content="Hobbs In" className="bold" />
                <Table.Cell content={data.hobbsIn || "-"} />
                <Table.Cell content="Tach 1 In" className="bold" />
                <Table.Cell content={data.tach1In || "-"} />

                                  {  flightType === 2
                                        && <div>
                                            <Table.Cell content='Tach 2 In' className='bold' />
                                            <Table.Cell content={data.tach2In || '-'} />
                                        </div>
                                }
              </Table.Row>
              <Table.Row>
                <Table.Cell content="Hobbs Total" className="bold" />
                <Table.Cell content={data.hobbsTotalTime || "-"} />
                <Table.Cell content="Tach 1 Total" className="bold" />
                <Table.Cell content={data.tach1TotalTime || "-"} />
              {
                                    flightType === 2
                                        && <div>
                                            <Table.Cell content='Tach 2 Total' className='bold' />
                                            <Table.Cell content={data.tach2TotalTime || '-'} />
                                        </div>
                                } *
              </Table.Row>
               {data.customInstructionTypes.map(item => {
                if (item.value !== 0)
                  return (
                    <Table.Row>
                      <Table.Cell
                        content={<b>{item.name}</b>}
                        className="bold"
                      />
                      <Table.Cell content={item.value + " hr(s)"} />
                      <Table.Cell />
                      <Table.Cell />
                    </Table.Row>
                  );
              })}
            </Table.Body>
          </Table>*/}
          <Grid columns="2" style={{ textAlign: "left" }}>
            <Grid.Column>
              <small>
                {data.checkedOutBy && (
                  <div>
                    <b>Checked Out By</b> : {data.checkedOutBy}{" "}
                  </div>
                )}
                {data.checkedOutAt && (
                  <div>
                    <b>Checked Out At</b> :{" "}
                    {moment(data.checkedOutAt).format("YYYY-MM-DD HH:mm:ss")}{" "}
                  </div>
                )}
              </small>
            </Grid.Column>
            <Grid.Column>
              <small>
                {data.checkedInBy && (
                  <div>
                    <b>Checked In By</b> : {data.checkedOutBy}{" "}
                  </div>
                )}
                {data.checkedInAt && (
                  <div>
                    <b>Checked In At</b> :{" "}
                    {moment(data.checkedInAt).format("YYYY-MM-DD HH:mm:ss")}{" "}
                  </div>
                )}
              </small>
            </Grid.Column>
          </Grid>
          <br />
        </div>
      );
  }
}

export default withRouter(FlightData);
