import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Menu,
  Icon,
  Responsive,
  Button,
  Popup,
  Dropdown,
  Label,
  List,
  Form,
  Message,
  Image,
  Modal,
  Header,
} from "semantic-ui-react";
import * as config from "../config";
import moment from "moment";
import {
  // updateNews,
  logout,
  getNotisList,
  getNotisListNextPage,
  resetPassword,
  updateReadNews,
  updateReadAlert,
} from "../containers/service";
import ModalForm from "../components/modal";
import Wrap from "../components/wrap";

class MenuHeader extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_role: JSON.parse(localStorage.getItem("bac_roles")),
    data: [],
    count: 0,
    currentNews: [],
    openNews: false,
    openCPassword: false,
    resetpass: {
      confirm_password: {
        value: "",
        validation: {
          required: true,
          minLength: 6,
          maxLength: 32,
        },
        valid: false,
      },
      password: {
        value: "",
        validation: {
          required: true,
          minLength: 6,
          maxLength: 32,
        },
        valid: false,
      },
    },
    errormsg: null,
  };

  componentDidMount() {
    this.getNotis();
  }

  componentWillUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getNotis();
    }
  }
  getNotis = () => {
    let { bacUserData } = this.state;
    getNotisList(bacUserData && bacUserData.id).then((res) => {
      if (res && res.status === 200) {
        // console.log('getNotisList',res)
        this.setState({
          data: res.data.res.data,
          nextUrl:
            res.data.res.current_page === res.data.res.last_page
              ? null
              : res.data.res.next_page_url,
          count: res.data.total,
        });
        const dataPopup = res.data.res.data.find((member) => {
          return (
            member.type === "Alert" &&
            member.priority === 1 &&
            member.unRead === true
          );
        });
        if (dataPopup) {
          this.setState({ openNews: true, currentNews: dataPopup });
          // console.log('alertPopup',dataPopup);
        }
      }
    });
  };

  confirmPriority = (data) => {
    this.close();
    updateReadAlert(data.id).then((res) => {
      if (res && res.status === 200) this.props.history.push("/Home");
    });
  };

  onClickLoadMore = () => {
    let { data, nextUrl } = this.state;
    this.setState({ loadmore: true });
    getNotisListNextPage(nextUrl).then((res) => {
      if (res && res.status === 200) {
        data.push(...res.data.res.data);

        this.setState({
          data: data,
          nextUrl:
            res.data.res.current_page === res.data.res.last_page
              ? null
              : res.data.res.next_page_url,
          count: res.data.total,
        });
      }
      this.setState({ loadmore: false });
    });
  };
  updateData = (data) => {
    // console.log(data);
    data["unRead"] = false;
    if (data.type === "News") {
      updateReadNews(data.id).then((res) => {
        if (res && res.status === 200) {
          this.getNotis();
          this.setState({ currentNews: data, openNews: true });
        }
      });
    } else if (data.type === "Alert") {
      updateReadAlert(data.id).then((res) => {
        if (res && res.status === 200) {
          this.getNotis();
          this.setState({ currentNews: data, openNews: true });
        }
      });
    }
  };
  close = () =>
    this.setState({ openNews: false, openCPassword: false, errormsg: null });

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  onClickProfile = () => {
    let { bac_role, bacUserData } = this.state;
    console.log("bac_role", bac_role, bacUserData);
    // Ground Instructor,Flight Instructor,
    if (bac_role.indexOf("Instructor") !== -1) {
      this.props.history.push({
        pathname: "/InstructorProfile",
        state: { id: bacUserData.id, status: "self" },
      });
    } else if (bac_role.indexOf("Student") !== -1) {
      this.props.history.push({
        pathname: "/StudentProfile",
        state: { id: bacUserData.id, status: "self" },
      });
    } else {
      this.props.history.push({
        pathname: "/StaffsProfile",
        state: { id: bacUserData.id, status: "self" },
      });
    }
  };
  onClickChangePassword = () => {
    this.setState({ openCPassword: true });
  };
  onClickLogout = () => {
    logout();
    localStorage.clear();
    this.props.history.push("/Home");
  };

  onChangeFormPassword = (e) => {
    var target = e.target;
    const { resetpass } = this.state;
    resetpass[target.name].value = target.value;
    this.setState({
      resetpass: resetpass,
    });
  };
  resetpassword = () => {
    const { resetpass } = this.state;
    this.setState({ errormsg: null, loading: true });
    var x, error;
    for (x in resetpass) {
      resetpass[x].valid = this.checkValidate(
        resetpass[x].value,
        resetpass[x].validation
      );
      if (resetpass[x].valid) error = true;
      this.setState({
        errormsg: "Please check your password (6-32 characters.)",
        loading: false,
      });
    }
    if (!error) {
      if (resetpass.password.value !== resetpass.confirm_password.value) {
        this.setState({ errormsg: "Password do not match", loading: false });
      } else {
        resetPassword({
          password: resetpass.password.value,
          password_confirmation: resetpass.confirm_password.value,
        }).then((res) => {
          if (res.status === 200) {
            // alert('Your password has been changed! Please login again')
            this.setState({ loading: false });
            this.onClickLogout();
          } else {
            this.setState({ loading: false });
          }
        });
        this.close();
      }
    } else {
      this.setState({ loading: false });
    }
    this.setState({ resetpass });
  };
  checkValidate = (value, rule) => {
    let isValid = false;
    if (rule.required) {
      isValid = value.trim() === "";
    }
    if (rule.minLength) {
      isValid = value.length < rule.minLength;
    }

    if (rule.maxLength) {
      isValid = value.length > rule.maxLength;
    }
    return isValid;
  };

  render() {
    const state = this.state;
    // console.log("state.bac_role", state.bac_role);
    return (
      <Menu
        id="myHeadMenu"
        text
        style={{
          backgroundColor: config.colorTheme,
          margin: 0,
          padding: "5px",
          height: "100%",
        }}
      >
        <Modal
          open={state.openNews}
          closeIcon={
            state.currentNews.type === "Alert"
              ? state.currentNews.priority === 1
                ? false
                : true
              : true
          }
          centered={false}
          dimmer="blurring"
          size={"tiny"}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Header
            icon={state.currentNews.type === "Alert" ? "alarm" : "thumbtack"}
            content={state.currentNews.type}
            style={
              state.currentNews.type === "Alert"
                ? { backgroundColor: "red", color: "#ffffff", padding: "10px" }
                : { padding: "10px" }
            }
          />
          <Modal.Content>
            <h2 style={{ textAlign: "center", paddingTop: "20px" }}>
              {state.currentNews.topic}
            </h2>
            {state.currentNews.imageUrl && (
              <Image
                style={{ padding: 10, maxHeight: "400px" }}
                centered
                src={state.currentNews.imageUrl}
              />
            )}
            <p style={{ textAlign: "center" }}>{state.currentNews.detail}</p>
            {state.currentNews.fileUrl &&
              state.currentNews.fileUrl.map((item) => (
                <Wrap>
                  <Icon name="file pdf outline" />
                  <a href={item} download target="_blank">
                    Download file
                  </a>
                  <br />
                </Wrap>
              ))}
            <br />
            <br />
            {state.currentNews.link && (
              <Wrap>
                <Icon name="linkify" />
                <a href={state.currentNews.link} download target="_blank">
                  Open link
                </a>
              </Wrap>
            )}
            <br />
            <br />
            <p style={{ textAlign: "right", color: "grey" }}>
              created at{" "}
              {moment(state.currentNews.created_at).format(
                "DD MMM YYYY HH:mm:ss"
              )}
            </p>
            {state.currentNews.type === "Alert" &&
              state.currentNews.priority === 1 && (
                <Button
                  inverted
                  color="red"
                  onClick={() => this.confirmPriority(state.currentNews)}
                >
                  Confirm
                </Button>
              )}
          </Modal.Content>
        </Modal>
        <ModalForm
          icon="key"
          content="Change Password"
          size="mini"
          open={state.openCPassword}
          close={this.close}
        >
          <Form onSubmit={this.resetpassword}>
            <Form.Input
              name="password"
              label="Password"
              type="password"
              placeholder="Password"
              onChange={this.onChangeFormPassword}
              error={state.resetpass.password.valid}
            />
            <Form.Input
              name="confirm_password"
              label="Confirm Password"
              type="password"
              placeholder="Confirm Password"
              onChange={this.onChangeFormPassword}
              error={state.resetpass.confirm_password.valid}
            />
            {state.errormsg && (
              <Message negative>
                <p>{state.errormsg}</p>
              </Message>
            )}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={state.loading}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        </ModalForm>
        <Responsive
          {...Responsive.onlyMobile}
          as={Menu.Item}
          onClick={() => this.props.showMenuForMobile()}
        >
          <Icon
            name={this.props.visible ? "close" : "bars"}
            style={{
              color: "#fff",
              fontSize: this.props.visible ? "1.5em" : "",
            }}
          />
        </Responsive>
        <Menu.Item position="right">
          <Popup
            on="click"
            basic
            trigger={
              <Menu.Item>
                <Icon
                  name="bell outline"
                  style={{ margin: 0, color: "#fff" }}
                />
                {state.count !== 0 && (
                  <Label
                    className="shake-bottom"
                    circular
                    size="mini"
                    color="pink"
                    style={{ margin: 0, marginBottom: "10px" }}
                  >
                    {state.count}
                  </Label>
                )}
              </Menu.Item>
            }
            style={{
              padding: 0,
              fontSize: "99%",
              maxHeight: "250px",
              overflow: "auto",
            }}
            hoverable
          >
            <List divided>
              {state.data.map((data) => (
                <List.Item
                  as="a"
                  key={data.id}
                  style={
                    data.unRead
                      ? { padding: 10, backgroundColor: "#fff6c4" }
                      : { padding: 10 }
                  }
                  onClick={() => this.updateData(data)}
                >
                  {data.type === "Alert" ? (
                    <Icon
                      color={
                        data.priority === 1
                          ? "red"
                          : data.priority === 2
                          ? "yellow"
                          : "green"
                      }
                      name="alarm"
                    />
                  ) : (
                    <Icon color="green" name="thumbtack" />
                  )}
                  <List.Content>
                    <List.Header>{data.topic}</List.Header>
                    <List.Description>
                      {moment(data.created_at).fromNow()}{" "}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
              {state.nextUrl && (
                <List.Item
                  as="a"
                  key="more"
                  onClick={this.onClickLoadMore}
                  textAlign="center"
                  style={{ textAlign: "center", padding: "10px" }}
                >
                  {state.loadmore ? (
                    <List.Content>
                      <Icon loading name="spinner" /> Loading{" "}
                    </List.Content>
                  ) : (
                    <List.Content>
                      <List.Header>Load More</List.Header>{" "}
                    </List.Content>
                  )}
                </List.Item>
              )}
            </List>
          </Popup>

          {/* <Menu.Item >
                        <Icon name="mail outline" style={{ margin: 0 }} style={{ color: '#fff' }} />
                        <Label className='shake-bottom' circular size='mini' color='green' style={{ margin: 0, marginBottom: '10px' }}>4</Label>
                    </Menu.Item> */}
          <Dropdown
            icon="angle down"
            item
            text={`${state.bacUserData.firstName} ${state.bacUserData.lastName}`}
            style={{ color: "#fff" }}
          >
            <Dropdown.Menu>
              {state.bac_role.toString() != "SuperAdmin" && (
                <Dropdown.Item
                  style={{ color: " rgba(0,0,0,.68)" }}
                  onClick={this.onClickProfile}
                >
                  <Icon name="user" style={{ color: " rgba(0,0,0,.68)" }} />
                  Profile
                </Dropdown.Item>
              )}
              {/* <Dropdown.Item style={{ color: " rgba(0,0,0,.68)" }}>
                <Icon name="settings" style={{ color: " rgba(0,0,0,.68)" }} />{" "}
                Settings
              </Dropdown.Item> */}
              <Dropdown.Item
                style={{ color: " rgba(0,0,0,.68)" }}
                onClick={this.onClickChangePassword}
              >
                <Icon name="key" style={{ color: " rgba(0,0,0,.68)" }} />
                Change Password
              </Dropdown.Item>
              <Dropdown.Item
                style={{ color: " rgba(0,0,0,.68)" }}
                onClick={this.onClickLogout}
              >
                <Icon name="sign-out" style={{ color: " rgba(0,0,0,.68)" }} />
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu>
    );
  }
}

export default withRouter(MenuHeader);
