import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Dropdown,
  Form,
  Button,
  Image,
  Icon,
  Checkbox,
  List,
  Table,
  Grid,
  Divider,
} from "semantic-ui-react";
import * as config from "../../config";
import {
  getAllClass,
  getAllCompany,
  getStudentType,
  getAllCourse,
  importStudent,
  getStudentById,
} from "../Student/service";
import { getStudentListByRegis } from "./service";
import {
  checkRegis,
  postRegis,
  checkAddRegis,
  postAddRegis,
  postUnRegis,
  postGraduate,
  postTerminate,
} from "./service";
import HeaderWithMenu from "../../components/headerwithmenucustom";
import TableList from "../../components/table";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import DropdownCheckbox from "../../components/dropdownCheckbox";
import Wrap from "../../components/wrap";
// import FormStudent from './userStudentForm';
import moment from "moment";
import Confirmmodal from "../../components/customconfirmmodal";
import { DateInput } from "semantic-ui-calendar-react";

class Registration extends Component {
  state = {
    openConfirm: false,
    issuedate: moment().format("dddd, MMMM DD, YYYY"),
    licenseNumber: null,
    open: false,
    type: null,
    btnRegisCourse: true,
    btnRegisAddCourse: true,
    btnGraduate: true,
    openRegisCourse: false,
    loading: true,
    importFile: false,
    errorsRow: 0,
    errorInfo: null,
    dataEdit: null,
    rawData: [],
    data: [],
    options_class: [],
    options_classform: [],
    options_company: [],
    options_course: [],
    options_addcourse: [],
    options_studenttype: [],
    options_choice: [
      { key: 1, text: "Unregister", value: "unregister" },
      { key: 2, text: "Graduate", value: "graduate" },
      { key: 3, text: "Terminate", value: "terminate" },
    ],
    select_choice: "unregister",
    select_course: null,
    select_addcourse: null,
    select_class: null,
    select_company: null,
    select_stdType: null,
    select_student: [],
    checked: false,
    filterClassByText: "",
    search: {
      status: "Registered",
      class: "",
      sort: {
        key: "firstName",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
    },
    sortText: "Name [ASC]",
    options_sortby: [
      {
        key: "firstName",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC",
      },
      {
        key: "firstName",
        text: "Name",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
    ],
    column: [
      {
        Header: (props) => (
          <Checkbox
            id="all"
            onChange={this.checkedAll}
            checked={this.state.checked}
          />
        ),
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Checkbox
            id={cellInfo.original.id}
            onChange={this.toggle}
            checked={this.state.select_student.indexOf(cellInfo.original) != -1}
          />
        ),
        width: 60,
      },
      {
        Header: "Name",
        accessor: "description",
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <span className="bold">{cellInfo.original.description}</span>
          </Wrap>
        ),
      },
      {
        Header: "Course",
        accessor: "course",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original ? (
              cellInfo.original.course ? (
                <span>
                  {cellInfo.original.course.name} (
                  {cellInfo.original.course.desc})
                </span>
              ) : null
            ) : null}
          </span>
        ),
      },
      {
        Header: "Additional Course",
        accessor: "additionalCourse",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original
              ? cellInfo.original.additionalCourse &&
                cellInfo.original.additionalCourse.length > 0
                ? cellInfo.original.additionalCourse.map((add) => (
                    <span>
                      {add.name} ({add.desc}) <br />
                    </span>
                  ))
                : null
              : null}
          </span>
        ),
      },
      {
        Header: "Student type",
        accessor: "description",
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <span className="">
              {cellInfo.original
                ? cellInfo.original.studentType
                  ? cellInfo.original.studentType.name
                  : null
                : null}
            </span>
          </Wrap>
        ),
        minWidth: 100,
      },
      {
        Header: "Class",
        accessor: "class",
        style: { textAlign: "left" },
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original
              ? cellInfo.original.class
                ? cellInfo.original.class.name
                : null
              : null}
          </span>
        ),
        minWidth: 80,
      },
      // {
      //     Header: 'Action',
      //     accessor: 'id',
      //     style: { textAlign: 'center' },
      //     sortable: false,
      //     Cell: cellInfo => (
      //         <Wrap>
      //             <Icon link circular color='orange' name='edit' onClick={() => this.onClickEdit(cellInfo.original)} />
      //         </Wrap>
      //     ),
      //     width: 100,
      // }
    ],
  };

  checkedAll = (e) => {
    let { data } = this.state;
    let select_student = [];
    // console.log(e.target.checked,"e.target.checked");
    if (e.target.checked) {
      // console.log("if");
      data.map((item) => {
        // select_student.push(item.id)
        select_student.push(item);
      });
    }

    if (select_student.length > 0) {
      this.setState({
        btnRegisCourse: false,
        btnRegisAddCourse: false,
        btnGraduate: false,
      });
    } else {
      this.setState({
        btnRegisCourse: true,
        btnRegisAddCourse: true,
        btnGraduate: true,
      });
    }
    // console.log(select_student);
    this.setState({ select_student });
    this.setState((prevState) => ({ checked: !prevState.checked }));
  };

  toggle = (e) => {
    // console.log('toggle',e.target);
    let { select_student, data } = this.state;
    // console.log('data',data)
    if (e.target.checked) {
      const list = data.find((value) => {
        return value.id == e.target.id;
      });
      // console.log(list)
      // select_student.push(parseInt(e.target.id))
      select_student.push(list);
    } else {
      select_student = select_student.filter(function (value, index, arr) {
        return value.id != e.target.id;
      });
    }

    if (select_student.length > 0) {
      this.setState({
        btnRegisCourse: false,
        btnRegisAddCourse: false,
        btnGraduate: false,
      });
    } else {
      this.setState({
        btnRegisCourse: true,
        btnRegisAddCourse: true,
        btnGraduate: true,
      });
    }

    this.setState({ select_student });
  };
  show = (type) => {
    // console.log(type);
    let qString2 = "?additionalCourse=false&active=true";
    this.getOptionCourse(qString2);
    let qString3 = "?additionalCourse=true&active=true";
    this.getOptionAddCourse(qString3);

    const { select_student } = this.state;
    let option = [];
    if (select_student && select_student.length > 0) {
      select_student.map((select) => option.push(select.id));
      let data = {
        student: option,
      };
      if (type === "course") {
        checkRegis(data).then((res) => {
          if (res && res.status == 200) {
            // console.log('checkRegis', res.data);
            this.setState({
              available: res.data.available,
              registered: res.data.registered,
              rawCheckstudent: res.data,
              openRegisCourse: true,
              type: type,
              content: "Register Course",
            });
          }
        });
      } else if (type === "addcourse") {
        checkAddRegis(data).then((res) => {
          if (res && res.status == 200) {
            // console.log('checkAddRegis', res.data);
            this.setState({
              available: res.data.available,
              registered: res.data.registered,
              unregistered: res.data.unregistered,
              rawCheckstudent: res.data,
              openRegisCourse: true,
              type: type,
              content: "Register Additional Course",
            });
          }
        });
      } else if (type === "graduate") {
        checkRegis(data).then((res) => {
          if (res && res.status == 200) {
            // console.log('graduate checkRegis', res.data);
            this.setState({
              available: res.data.available,
              registered: res.data.registered,
              rawCheckstudent: res.data,
              openRegisCourse: true,
              type: type,
              content: "Unregister / Graduate / Terminate",
            });
          }
        });
      }
    }
  };
  close = () => {
    this.setState({
      openRegisCourse: false,
      type: null,
      available: null,
      registered: null,
      rawCheckstudent: null,
      select_course: null,
      select_student: [],
      content: null,
      btnRegisCourse: true,
      btnRegisAddCourse: true,
      btnGraduate: true,
    });
  };
  saveTerminate = () => {
    this.setState({ loading: true });
    const { rawCheckstudent, search } = this.state;
    let data = {
      ...rawCheckstudent,
    };

    postTerminate(data).then((res) => {
      if (res && res.status == 200) {
        this.setState({
          openConfirm: false,
          openRegisCourse: false,
          available: null,
          registered: null,
          rawCheckstudent: null,
          select_student: [],
          select_course: null,
          select_choice: "unregister",
          btnRegisCourse: true,
          btnRegisAddCourse: true,
          btnGraduate: true,
        });
        let qString =
          "?regis=" +
          search.status +
          "&class[]=" +
          search.class +
          "&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
      }
      this.setState({ activeLoader: false, loading: false });
    });
  };
  saveUnregis = () => {
    this.setState({ loading: true });
    const { rawCheckstudent, search } = this.state;
    let data = {
      ...rawCheckstudent,
    };

    postUnRegis(data).then((res) => {
      if (res && res.status == 200) {
        this.setState({
          openConfirm: false,
          openRegisCourse: false,
          available: null,
          registered: null,
          rawCheckstudent: null,
          select_student: [],
          select_course: null,
          select_choice: "unregister",
          btnRegisCourse: true,
          btnRegisAddCourse: true,
          btnGraduate: true,
        });
        let qString =
          "?regis=" +
          search.status +
          "&class[]=" +
          search.class +
          "&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
      }
      this.setState({ activeLoader: false, loading: false });
    });
  };

  saveGraduate = () => {
    this.setState({ loading: true });
    const { rawCheckstudent, search } = this.state;
    rawCheckstudent.registered.map((res) => {
      if (res.issueddate) {
        res.issueddate = moment(res.issueddate).format("YYYY-MM-DD");
      } else {
        res.issueddate = moment().format("YYYY-MM-DD");
      }
    });
    let data = {
      ...rawCheckstudent,
    };
    // console.log('data',data);
    postGraduate(data).then((res) => {
      if (res && res.status == 200) {
        this.setState({
          openConfirm: false,
          openRegisCourse: false,
          available: null,
          registered: null,
          rawCheckstudent: null,
          select_student: [],
          select_course: null,
          select_choice: "unregister",
          btnRegisCourse: true,
          btnRegisAddCourse: true,
          btnGraduate: true,
        });
        let qString =
          "?regis=" +
          search.status +
          "&class[]=" +
          search.class +
          "&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
      }
      this.setState({ activeLoader: false, loading: false });
    });
  };
  saveCourse = () => {
    this.setState({ activeLoader: true });
    const { rawCheckstudent, select_course, search } = this.state;
    let data = {
      ...rawCheckstudent,
      course: select_course,
    };
    if (!select_course) {
      this.setState({ errorCourse: true, activeLoader: false });
      return;
    } else {
      this.setState({ errorCourse: false });
    }
    // console.log('savedata',data)
    postRegis(data).then((res) => {
      if (res && res.status == 200) {
        this.setState({
          openRegisCourse: false,
          available: null,
          registered: null,
          rawCheckstudent: null,
          select_student: [],
          select_course: null,
          btnRegisCourse: true,
          btnRegisAddCourse: true,
          btnGraduate: true,
        });
        let qString =
          "?regis=" +
          search.status +
          "&class[]=" +
          search.class +
          "&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
      }
      this.setState({ activeLoader: false });
    });
  };
  saveAddCourse = () => {
    this.setState({ activeLoader: true });
    const { rawCheckstudent, select_addcourse, search } = this.state;
    let data = {
      ...rawCheckstudent,
      course: select_addcourse || [],
    };
    // if (!select_addcourse) {
    //     this.setState({ errorCourse: true, activeLoader: false })
    //     return;
    // } else {
    //     this.setState({ errorCourse: false })
    // }
    // console.log('savedata addcourse',data)
    postAddRegis(data).then((res) => {
      if (res && res.status == 200) {
        this.setState({
          openRegisCourse: false,
          available: null,
          registered: null,
          unregistered: null,
          rawCheckstudent: null,
          select_student: [],
          select_addcourse: null,
          btnRegisCourse: true,
          btnRegisAddCourse: true,
          btnGraduate: true,
        });
        let qString =
          "?regis=" +
          search.status +
          "&class[]=" +
          search.class +
          "&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
      }
      this.setState({ activeLoader: false });
    });
  };
  closeError = () => {
    this.setState({ openError: false });
  };

  closeImportFile = () => {
    document.getElementById("myForm").reset();
    this.setState({
      importFile: false,
      select_course: null,
      select_class: null,
      select_company: null,
      select_stdType: null,
    });
  };

  handleChangeStatus = (e, data) => {
    // console.log('handleChangeStatus',data);
    let { search } = this.state;
    let active,
      qString = "";

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search });

    qString = "?regis=" + data.value + "&class[]=" + search.class;
    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let { rawData, search, options_class } = this.state;
    let active;
    search[e.target.name] = e.target.value;
    this.setState({ search });

    if (e.target.value) {
      let newdata = [];
      rawData.map((item) => {
        let description = `${item.studentId || ""} ${item.firstName} ${
          item.lastName
        } ${item.customerCode || ""}`;
        if (
          description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push({
            id: item.id,
            image: item.imageURL || [],
            description: description,
            email: item.emailAddress,
            phone: item.phoneNumber,
            roles: item.roles || [],
            class: item.class,
            course: item.course,
            additionalCourse: item.additionalCourse || [],
            studentType: item.studentType,
            advisor: item.advisor,
          });
        }
      });
      // console.log('newdata',newdata)
      this.setState({ data: newdata });
    } else {
      let qString = "?regis=" + search.status;
      if (search["class"]) qString += "&class[]=" + search.class;
      if (search["sort"])
        qString +=
          "&_sort=" + search.sort.key + "&_order=" + search.sort.description;
      this.getData(qString);
    }
  };

  handleChangeSort = (e, data) => {
    let { search, options_class } = this.state;
    let active, qString;

    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
    });

    qString =
      "?regis=" +
      search.status +
      "&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    if (search["class"]) qString += "&class[]=" + search.class;

    this.getData(qString);
  };

  handleChangeFilterClass = (e, data) => {
    // console.log('handleChangeFilterClass',data)
    let { search, options_class } = this.state;
    const findClass = options_class.find((cls) => {
      return cls.value == data.value;
    });
    search["class"] = data.value;
    this.setState({ search, filterClassByText: findClass.text });

    let qString = "?regis=" + search.status + "&class[]=" + data.value;

    if (search["sort"])
      qString +=
        "&_sort=" + search.sort.key + "&_order=" + search.sort.description;

    // console.log(qString)
    this.getData(qString);
  };

  getClass = () => {
    let { search } = this.state;
    getAllClass().then((res) => {
      if (res && res.status === 200) {
        let options = [],
          options_classform = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
          options_classform.push({
            key: item.id,
            text: item.name,
            value: item.id,
          });
        });
        options.push({ key: "null", text: "No Class", value: "null" });
        if (options && options.length > 0) {
          search.class = options[0].value;
          this.setState({
            filterClassByText: options[0].text,
            search,
          });
          let qString =
            "?regis=" + search.status + "&class[]=" + options[0].value;
          this.getData(qString);
        } else {
          let qString = "?regis=" + search.status;
          this.getData(qString);
        }
        this.setState({
          options_class: options,
          options_classform: options_classform,
        });
      }
    });
  };

  getCompany = () => {
    getAllCompany().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({ options_company: options, rawDataCompany: res.data });
      }
    });
  };

  getAllStudentType = () => {
    getStudentType().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
        });
        this.setState({
          options_studenttype: options,
          rawDataStudenttype: res.data,
        });
      }
    });
  };

  getOptionCourse = (qString) => {
    getAllCourse(qString).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.name} (${item.desc}) ${moment(
              item.start,
              "DD-MM-YYYY"
            ).format("DD/MM/YYYY")} - ${moment(item.end, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            )}`,
            value: item.id,
          });
        });
        this.setState({ options_course: options, rawDataCourse: res.data });
      }
    });
  };

  getOptionAddCourse = (qString) => {
    getAllCourse(qString).then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          options.push({
            key: item.id,
            text: `${item.name} (${item.desc}) ${moment(
              item.start,
              "DD-MM-YYYY"
            ).format("DD/MM/YYYY")} - ${moment(item.end, "DD-MM-YYYY").format(
              "DD/MM/YYYY"
            )}`,
            value: item.id,
          });
        });
        this.setState({
          options_addcourse: options,
          rawDataAddCourse: res.data,
        });
      }
    });
  };

  getData = (qString) => {
    this.setState({ loading: true });
    getStudentListByRegis(qString).then((res) => {
      // console.log('student', res)
      if (res && res.status === 200) {
        let newdata = [];
        res.data.map((item) => {
          let description = `${item.studentId || ""} ${item.firstName} ${
            item.lastName
          } ${item.customerCode || ""}`;
          newdata.push({
            id: item.id,
            image: item.imageURL || [],
            description: description,
            email: item.emailAddress,
            phone: item.phoneNumber,
            roles: item.roles || [],
            class: item.class,
            course: item.course,
            studentType: item.studentType,
            additionalCourse: item.additionalCourse || [],
            advisor: item.advisor,
          });
        });
        // console.log('newdata', newdata)
        this.setState({ data: newdata, rawData: res.data });
      }
      this.setState({ loading: false });
    });
  };
  onConfirm = (type) => {
    this.setState({ openConfirm: true });
  };

  onClickEdit = (data) => {
    // this.getDataStudentById(data.id)
  };
  onRemoveStudent = (index) => {
    // console.log('index',index);
    let { registered, rawCheckstudent } = this.state;
    const newlist = [...registered];
    newlist.splice(index, 1);
    let data = {
      ...rawCheckstudent,
      registered: newlist,
    };
    this.setState({ registered: newlist, rawCheckstudent: data });
  };
  onRemove = (reg, regindex, course, cindex) => {
    // console.log('cindex',cindex);
    let { registered } = this.state;
    const newlist = [...registered];
    newlist[regindex].additionalCourse.splice(cindex, 1);
    // console.log('newnewlist',newlist)
    this.setState({ registered: newlist });
  };
  dowloadTemplate() {
    var link = document.createElement("a");
    link.href = "./storage/template/data_student_template.xlsx";
    link.download = "data_student_template.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  onChangeFile = (e, data) => {
    if (e.target.files[0] && e.target.files[0].size > 5120000)
      this.setState({
        FileError:
          "The fils is too large and cannot be uploaded. (Limit size : 5MB)",
      });
    else this.setState({ FileError: false });
    this.setState({ [data.name]: e.target.files[0] });
  };
  handleChangeDate = (e, { name, value }) => {
    // console.log('handleChangeDate',name, value);
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id == name) {
        res.issueddate = value;
      }
    });
    this.setState({
      registered,
    });
  };
  handleChangeLicense = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.licencenumber = value;
      }
    });
    this.setState({
      registered,
    });
  };

  handleChangeNewCompany = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.new_company = value;
      }
    });
    this.setState({
      registered,
    });
  };
  handleChangeLastCompany = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.last_company = value;
      }
    });
    this.setState({
      registered,
    });
  };
  handleChangeJob = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.job_name = value;
      }
    });
    this.setState({
      registered,
    });
  };
  handleChangePosition = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.job_position = value;
      }
    });
    this.setState({
      registered,
    });
  };
  handleChangeYear = (e, { name, value }) => {
    let { registered } = this.state;
    registered.map((res) => {
      if (res.student_id === name) {
        res.year_employed = value;
      }
    });
    this.setState({
      registered,
    });
  };
  onChange = (e, data) => {
    this.setState({ [data.name]: data.value });
  };
  onChangeGraduate = (e, data) => {};
  openImportExcel = () => {
    let qString2 = "?additionalCourse=false&active=true";
    this.getOptionCourse(qString2);
    this.setState({ importFile: true });
  };
  importData = () => {
    const {
      excel,
      FileError,
      select_class,
      select_company,
      select_course,
      select_stdType,
      search,
    } = this.state;
    // console.log('importData', excel)
    this.setState({ activeLoader: true });
    if (FileError) {
      this.setState({ activeLoader: false });
      return;
    }

    if (!select_course) {
      this.setState({ errorCourse: true, activeLoader: false });
      return;
    } else {
      this.setState({ errorCourse: false });
    }

    if (excel) {
      var formData = new FormData();
      formData.append("classId", select_class);
      formData.append("companyId", select_company);
      formData.append("courseId", select_course);
      formData.append("stdType", select_stdType);

      formData.append("excel", excel);
      // console.log(...formData)
      importStudent(formData).then((res) => {
        if (res && res.status === 200) {
          // console.log('importStudent', res.data)
          if (res.data.error.errors > 0) {
            this.setState({
              openError: true,
              activeLoader: false,
              errorsRow: res.data.error.errors,
              errorInfo: Object.values(res.data.error.errorsmessage).toString(),
            });
          } else {
            this.setState({
              activeLoader: false,
              errorsRow: 0,
              errorInfo: null,
            });
            let qString =
              "?regis=" +
              search.status +
              "&class[]=" +
              search.class +
              "&_sort=" +
              search.sort.key +
              "&_order=" +
              search.sort.description;
            this.getData(qString);
            this.closeImportFile();
          }
          // let qString = '?isActive=true'
          // this.getData(qString)
          // this.closeImportFile()
        }
      });
    } else {
      this.setState({ activeLoader: false });
    }
  };

  componentDidMount() {
    this.getClass();
    this.getCompany();
    this.getAllStudentType();
  }

  render() {
    const state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={
            state.select_choice === "unregister"
              ? "Unregister"
              : state.select_choice === "graduate"
              ? "Graduate"
              : state.select_choice === "terminate"
              ? "Terminate"
              : console.log("no title")
          }
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={
            state.select_choice === "unregister"
              ? this.saveUnregis
              : state.select_choice === "graduate"
              ? this.saveGraduate
              : state.select_choice === "terminate"
              ? this.saveTerminate
              : console.log("no choice")
          }
        />
        <HeaderWithMenu name="Registration" widthButton={10}>
          <Dropdown
            icon={<Icon circular name="align justify" color="black" />}
            direction="left"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                icon="external share"
                text="Import Data"
                onClick={this.openImportExcel}
              />
              <Dropdown.Item
                icon="download"
                text="Dowload template"
                onClick={this.dowloadTemplate}
              />
            </Dropdown.Menu>
          </Dropdown>
          <MainButton
            size="small"
            onClick={() => this.show("course")}
            text="Register Course"
            disable={state.btnRegisCourse}
          />
          <MainButton
            size="small"
            onClick={() => this.show("graduate")}
            text="Unregister / Graduate / Terminate"
            disable={state.btnGraduate}
          />
          <MainButton
            size="small"
            onClick={() => this.show("addcourse")}
            text="Register Additional Course"
            disable={state.btnRegisAddCourse}
          />
        </HeaderWithMenu>
        <Form>
          <Form.Group>
            <Form.Input
              width="3"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={this.handleChangeSearch}
            />

            <Form.Dropdown
              icon={null}
              fluid
              floating
              scrolling
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Class : <b>{state.filterClassByText}</b>
                </Button>
              }
              options={state.options_class}
              defaultValue={state.search.class}
              selectOnBlur={false}
              onChange={this.handleChangeFilterClass}
            />
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Sort by : <b>{state.sortText}</b>
                </Button>
              }
              options={state.options_sortby}
              defaultValue={state.search.sort}
              selectOnBlur={false}
              onChange={this.handleChangeSort}
            />
            <Form.Dropdown
              icon={null}
              fluid
              floating
              style={{ width: "100%", marginRight: "30px" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  size="small"
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Status : <b>{state.search.status}</b>
                </Button>
              }
              options={config.options_regisstatus}
              defaultValue={state.search.status}
              selectOnBlur={false}
              onChange={this.handleChangeStatus}
            />
            {/* <Form.Field width='9' style={{ textAlign: 'right' }}>
                            <MainButton
                                size='small'
                                onClick={this.show}
                                text='Add Student' />
                        </Form.Field> */}
          </Form.Group>
        </Form>
        <ModalForm
          icon="exclamation circle"
          content="นำเข้าข้อมูลไม่สำเร็จ"
          size="large"
          open={state.openError}
          close={this.closeError}
        >
          <Wrap>
            {state.errorsRow && (
              <h3>จำนวนข้อผิดพลาดทั้งหมด {state.errorsRow} จุด</h3>
            )}
            <br />
            {state.errorInfo && <p>{state.errorInfo}</p>}
          </Wrap>
        </ModalForm>

        <ModalForm
          icon="file excel"
          content="Import Student data"
          open={state.importFile}
          close={this.closeImportFile}
        >
          <Wrap>
            <Form id="myForm" onSubmit={this.importData}>
              <Form.Select
                label="Course"
                search
                clearable
                required
                name="select_course"
                onChange={(e, value) => this.onChange(e, value)}
                options={state.options_course}
                error={state.errorCourse}
              ></Form.Select>
              <Form.Group widths="equal">
                <Form.Select
                  label="Class"
                  search
                  clearable
                  name="select_class"
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_classform}
                ></Form.Select>
                <Form.Select
                  label="Company"
                  search
                  clearable
                  name="select_company"
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_company}
                ></Form.Select>
              </Form.Group>
              <Form.Select
                label="Student type"
                search
                clearable
                name="select_stdType"
                onChange={(e, value) => this.onChange(e, value)}
                options={state.options_studenttype}
              ></Form.Select>
              <Form.Input
                label="Excel file"
                type="file"
                name="excel"
                onChange={this.onChangeFile}
              />
              <p style={{ color: "red", textAlign: "left" }}>
                {state.FileError ? state.FileError : ""}
              </p>
              <br />
              <Button
                floated="left"
                type="submit"
                content="Import"
                color="green"
                loading={state.activeLoader}
              />
              <Button
                floated="right"
                type="button"
                content="Cancel"
                color="green"
                basic
                onClick={this.closeImportFile}
              />
            </Form>
          </Wrap>
        </ModalForm>
        <ModalForm
          size="large"
          icon="user"
          content={state.content && state.content}
          open={state.openRegisCourse}
          close={this.close}
        >
          <Wrap>
            {state.type === "course" ? (
              <Form onSubmit={this.saveCourse}>
                <Form.Select
                  label="Course"
                  search
                  clearable
                  name="select_course"
                  error={state.errorCourse}
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_course}
                ></Form.Select>
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Course
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Date
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Status
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {state.registered &&
                      state.registered.length > 0 &&
                      state.registered.map((reg) => (
                        <Table.Row>
                          <Table.Cell>{`${reg.studentId || ""} ${
                            reg.title || ""
                          } ${reg.firstName} ${reg.lastName} ${
                            reg.customerCode || ""
                          }`}</Table.Cell>
                          <Table.Cell error>
                            <Icon name="attention" />
                            {` ${reg.course.name}(${reg.course.desc})`}
                          </Table.Cell>
                          <Table.Cell textAlign="center">{`${reg.course.regisdate}`}</Table.Cell>
                          <Table.Cell textAlign="center">{`${reg.course.status}`}</Table.Cell>
                        </Table.Row>
                      ))}
                    {state.available &&
                      state.available.length > 0 &&
                      state.available.map((ava) => (
                        <Table.Row>
                          <Table.Cell>{`${ava.studentId || ""} ${
                            ava.title || ""
                          } ${ava.firstName} ${ava.lastName} ${
                            ava.customerCode || ""
                          }`}</Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                <br />
                <Button
                  floated="left"
                  type="submit"
                  content="save"
                  color="green"
                  loading={state.activeLoader}
                />
                <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  color="green"
                  basic
                  onClick={this.close}
                />
              </Form>
            ) : state.type === "addcourse" ? (
              <Form onSubmit={this.saveAddCourse}>
                <Form.Select
                  label="Additional Course"
                  search
                  clearable
                  multiple
                  name="select_addcourse"
                  error={state.errorCourse}
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.options_addcourse}
                ></Form.Select>
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                      >
                        Course
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        className="header-table"
                        textAlign="center"
                        colSpan="3"
                      >
                        Additional Course
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {state.registered &&
                      state.registered.length > 0 &&
                      state.registered.map((reg, regindex) => (
                        <Table.Row>
                          <Table.Cell verticalAlign="top">{`${
                            reg.studentId || ""
                          } ${reg.title || ""} ${reg.firstName} ${
                            reg.lastName
                          } ${reg.customerCode || ""}`}</Table.Cell>
                          <Table.Cell verticalAlign="top">
                            {reg.course &&
                              `${reg.course.name} (${reg.course.desc})`}
                          </Table.Cell>
                          <Table.Cell error colSpan="3">
                            {reg.additionalCourse &&
                              reg.additionalCourse.length > 0 &&
                              reg.additionalCourse.map((c, index) => (
                                <Wrap>
                                  <Icon
                                    name="cancel"
                                    color="red"
                                    link
                                    onClick={() =>
                                      this.onRemove(reg, regindex, c, index)
                                    }
                                  />
                                  {` ${c.name}(${c.desc}) ${c.regisdate} ${c.status}`}
                                  <br />
                                </Wrap>
                              ))}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    {state.available &&
                      state.available.length > 0 &&
                      state.available.map((ava) => (
                        <Table.Row>
                          <Table.Cell>{`${ava.studentId || ""} ${
                            ava.title || ""
                          } ${ava.firstName} ${ava.lastName} ${
                            ava.customerCode || ""
                          }`}</Table.Cell>
                          <Table.Cell verticalAlign="top">
                            {ava.course &&
                              `${ava.course.name} (${ava.course.desc})`}
                          </Table.Cell>
                          <Table.Cell colSpan="3"></Table.Cell>
                        </Table.Row>
                      ))}
                    {state.unregistered &&
                      state.unregistered.length > 0 &&
                      state.unregistered.map((un) => (
                        <Table.Row disabled>
                          <Table.Cell style={{ color: "#696969" }}>{`${
                            un.studentId || ""
                          } ${un.title || ""} ${un.firstName} ${un.lastName} ${
                            un.customerCode || ""
                          }`}</Table.Cell>
                          <Table.Cell
                            textAlign="center"
                            colSpan={4}
                            style={{ color: "#696969" }}
                          >
                            Cannot add Additional Course!! Please add main
                            Course first.
                          </Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
                <br />
                <Button
                  floated="left"
                  type="submit"
                  content="save"
                  color="green"
                  loading={state.activeLoader}
                />
                <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  color="green"
                  basic
                  onClick={this.close}
                />
              </Form>
            ) : state.type === "graduate" ? (
              <Wrap>
                <Grid>
                  <Grid.Row style={{ marginBottom: 20 }}>
                    <Grid.Column>
                      <Form>
                        <Form.Select
                          clearable
                          label="Unregister / Graduate / Terminate"
                          name="select_choice"
                          value={state.select_choice}
                          onChange={(e, value) => this.onChange(e, value)}
                          selectOnBlur={false}
                          options={state.options_choice}
                        ></Form.Select>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {state.select_choice === "graduate" ? (
                  <Form onSubmit={this.onConfirm}>
                    <Table compact celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Name
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            License Number
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Issue Date
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Company
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Job
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Action
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.state.registered &&
                          this.state.registered.length > 0 &&
                          this.state.registered.map((reg, rindex) => (
                            <Table.Row>
                              <Table.Cell style={{ verticalAlign: "top" }}>{`${
                                reg.studentId || ""
                              } ${reg.title || ""} ${reg.firstName} ${
                                reg.lastName
                              } ${reg.customerCode || ""}`}</Table.Cell>
                              <Table.Cell
                                verticalAlign="top"
                                textAlign="center"
                              >
                                <Form.Field>
                                  <Form.Input
                                    name={reg.student_id}
                                    placeholder="License Number"
                                    value={reg.licencenumber || null}
                                    onChange={this.handleChangeLicense}
                                  />
                                </Form.Field>
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign="top"
                                textAlign="center"
                              >
                                <Form.Field>
                                  <DateInput
                                    name={reg.student_id}
                                    placeholder="Select Date"
                                    hideMobileKeyboard
                                    dateFormat="dddd, MMMM DD, YYYY"
                                    iconPosition="right"
                                    value={
                                      reg.issueddate ||
                                      moment().format("dddd, MMMM DD, YYYY")
                                    }
                                    // defaultValue={state.issuedate}
                                    onChange={this.handleChangeDate}
                                    clearable
                                  />
                                </Form.Field>
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign="top"
                                textAlign="center"
                              >
                                <Form.Input
                                  name={reg.student_id}
                                  label="New Company"
                                  placeholder="New Company"
                                  value={reg.new_company || null}
                                  onChange={this.handleChangeNewCompany}
                                />
                                <Form.Input
                                  name={reg.student_id}
                                  label="Last Company"
                                  placeholder="Last Company"
                                  value={reg.last_company || null}
                                  onChange={this.handleChangeLastCompany}
                                />
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign="top"
                                textAlign="center"
                              >
                                <Form.Input
                                  name={reg.student_id}
                                  label="Job"
                                  placeholder="Job"
                                  value={reg.job_name || null}
                                  onChange={this.handleChangeJob}
                                />
                                <Form.Input
                                  name={reg.student_id}
                                  label="Position"
                                  placeholder="Position"
                                  value={reg.job_position || null}
                                  onChange={this.handleChangePosition}
                                />
                                <Form.Input
                                  name={reg.student_id}
                                  label="Year employed"
                                  placeholder="Year employed"
                                  value={reg.year_employed || null}
                                  onChange={this.handleChangeYear}
                                />
                              </Table.Cell>
                              <Table.Cell
                                verticalAlign="top"
                                textAlign="center"
                              >
                                <Icon
                                  name="cancel"
                                  color="red"
                                  link
                                  onClick={() => this.onRemoveStudent(rindex)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                    <br />
                    <Button
                      floated="left"
                      type="submit"
                      content="save"
                      color="green"
                      loading={state.activeLoader}
                    />
                    <Button
                      floated="right"
                      type="button"
                      content="Cancel"
                      color="green"
                      basic
                      onClick={this.close}
                    />
                  </Form>
                ) : state.select_choice == "unregister" ||
                  state.select_choice == "terminate" ? (
                  <Form onSubmit={this.onConfirm}>
                    <Table compact celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Name
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Course
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Additional Course
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            textAlign="center"
                          >
                            Action
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {state.registered &&
                          state.registered.length > 0 &&
                          state.registered.map((reg, rindex) => (
                            <Table.Row>
                              <Table.Cell>{`${reg.studentId || ""} ${
                                reg.title || ""
                              } ${reg.firstName} ${reg.lastName} ${
                                reg.customerCode || ""
                              }`}</Table.Cell>
                              <Table.Cell error>
                                <Icon name="attention" />
                                {` ${reg.course.name}(${reg.course.desc})`}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                {reg.additionalCourse &&
                                  reg.additionalCourse.length > 0 &&
                                  reg.additionalCourse.map((c, index) => (
                                    <Wrap>
                                      {` ${c.name}(${c.desc}) ${c.regisdate} ${c.status}`}
                                      <br />
                                    </Wrap>
                                  ))}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon
                                  name="cancel"
                                  color="red"
                                  link
                                  onClick={() => this.onRemoveStudent(rindex)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                    <br />
                    <Button
                      floated="left"
                      type="submit"
                      content="save"
                      color="green"
                      loading={state.activeLoader}
                    />
                    <Button
                      floated="right"
                      type="button"
                      content="Cancel"
                      color="green"
                      basic
                      onClick={this.close}
                    />
                  </Form>
                ) : null}
              </Wrap>
            ) : (
              <p>No type</p>
            )}
          </Wrap>
        </ModalForm>
        <TableList
          loading={state.loading}
          data={state.data}
          columns={state.column}
        />
      </Segment>
    );
  }
}

export default withRouter(Registration);
