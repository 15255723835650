import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Grid,
  Table,
  Statistic,
  List,
  Loader,
  Header,
  Icon,
} from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";
import { Line, HorizontalBar } from "react-chartjs-2";
import { getFlighttrainigProgress } from "./service";

class FlightrecordGraph extends Component {
  state = {
    id: this.props && this.props.id,
    data: [],
    loading: true,
    // monthlist: dataformapi.data
  };

  componentDidMount() {
    getFlighttrainigProgress(this.state.id).then((res) => {
      if (res && res.status === 200) {
        // console.log('res.data',res.data);
        this.setState({ data: res.data, loading: false });
      }
    });
  }

  render() {
    const state = this.state;
    if (state.loading)
      return <Loader active inline="centered" content="Loading" />;
    return (
      <div>
        <Wrap>
          <Grid columns="1">
            {state.data && state.data.length > 0 ? (
              state.data.map((tmp) => (
                <Grid.Row>
                  <Grid.Column width="16">
                    <Header as="h2">
                      {tmp.name} : {tmp.desc}
                    </Header>
                    <Line
                      data={{
                        labels: tmp.labels,
                        datasets: tmp.datasets,
                      }}
                      options={{
                        scales: {
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              ))
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <p style={{ color: "rgb(115, 115, 115)" }}>
                    No description available
                  </p>
                  <br />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Wrap>
      </div>
    );
  }
}

export default withRouter(FlightrecordGraph);
