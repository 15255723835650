import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Card,
  Grid,
  Table,
  Statistic,
  List,
  Loader,
  Header,
  Tab,
} from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";
import { Pie, Doughnut, Chart } from "react-chartjs-2";
import { getOverallProgress, getModuleProcess } from "./service";
import OverAllDetail from "./overall_detail";

class OverAll extends Component {
  state = {
    id: this.props && this.props.id,
    data: [],
    dataPie: null,
    loading: true,
    panes: [],
  };
  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });
  componentDidMount() {
    getOverallProgress(this.state.id).then((res) => {
      if (res && res.status === 200) {
        let panes = [];

        res.data.map((item) => {
          let panesName = item.name;
          panes.push({
            menuItem: panesName,
            render: () => (
              <Wrap>
                <OverAllDetail id={this.state.id} course={item} />
              </Wrap>
            ),
          });
        });
        this.setState({ data: res.data, panes: panes, loading: false });
      }
    });
  }

  render() {
    const state = this.state;
    // console.log(state.dataPie)
    if (state.loading)
      return <Loader active inline="centered" content="Loading" />;
    return (
      <div>
        {state.panes && state.panes.length > 0 ? (
          <Grid columns="equal">
            <Grid.Row style={{ paddingBottom: 0 }}>
              <Grid.Column key={2} computer={16} mobile={16}>
                <Tab
                  renderActiveOnly
                  activeIndex={state.activeIndex}
                  menu={{ secondary: true, pointing: true }}
                  onTabChange={this.handleTabChange}
                  panes={state.panes}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Wrap>
            <p style={{ color: "rgb(115, 115, 115)" }}>
              No description available
            </p>
            <br />
          </Wrap>
        )}
        {state.dataPie && (
          <Grid>
            <Grid.Row columns="2">
              <Grid.Column textAlign="center">
                <Header
                  as="h2"
                  content="Ground"
                  // subheader='Manage your account settings and set email preferences'
                />
                <Doughnut
                  data={
                    state.dataPie.groundSubjects && {
                      labels: state.dataPie.groundSubjects.label,
                      datasets: [
                        {
                          labels: false,
                          data: state.dataPie.groundSubjects.value,
                          backgroundColor: state.dataPie.groundSubjects.color,
                        },
                      ],
                      text: state.dataPie.groundSubjects.percent
                        ? state.dataPie.groundSubjects.percent + "%"
                        : "0%",
                    }
                  }
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Header
                  as="h2"
                  content="Flight"
                  // subheader='Manage your account settings and set email preferences'
                />
                <Doughnut
                  data={
                    state.dataPie.flightSubjects && {
                      labels: state.dataPie.flightSubjects.label,
                      datasets: [
                        {
                          data: state.dataPie.flightSubjects.value,
                          backgroundColor: state.dataPie.flightSubjects.color,
                        },
                      ],
                      text: state.dataPie.flightSubjects.percent
                        ? state.dataPie.flightSubjects.percent + "%"
                        : "0%",
                    }
                  }
                  options={{
                    legend: {
                      display: false,
                    },
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
}

export default withRouter(OverAll);
