import React from "react";
import { Form } from "semantic-ui-react";
import Wrap from "./wrap";
import { DateTimeInput } from "semantic-ui-calendar-react";
import moment from "moment";

const renderDateField = ({
  input,
  label,
  placeholder,
  minDate,
  maxDate,
  width,
  required,
  meta: { touched, error, warning },
}) => (
  <Wrap>
    {/* <Form.Group unstackable>
            <Form.Field width='4'>
                <label style={{ textAlign: 'right' }}>{label} {required ? <span style={{ color: 'red' }}>*</span> : ''}</label>
            </Form.Field> */}
    <Form.Field width={width || 16} style={{ marginBottom: "0px" }}>
      <label style={{ textAlign: "left" }}>
        {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
      </label>
      <DateTimeInput
        name="date"
        placeholder={placeholder}
        {...input}
        // preserveViewMode={false}
        dateTimeFormat="DD/MM/YYYY HH:mm"
        iconPosition="right"
        icon="calendar alternate outline"
        minDate={moment(minDate, "DD/MM/YYYY HH:mm").add(10, "minutes")}
        maxDate={moment(maxDate, "DD/MM/YYYY HH:mm").add(-10, "minutes")}
        divider
        clearable
        hideMobileKeyboard
        onChange={(e, data) => input.onChange(data.value)}
      />
      <p style={{ color: "#9f3a38", textAlign: "left", fontSize: "90%" }}>
        {touched &&
          ((error && <span>{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </p>
    </Form.Field>
    {/* </Form.Group> */}
  </Wrap>
);

export default renderDateField;
