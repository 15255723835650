import axios from "axios";
import * as config from "../../config";

export function getImage(url) {
  return axios
    .get(
      "https://pbs.twimg.com/profile_images/1288475083044864001/3jSMqUIN_400x400.jpg",
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => Buffer.from(response.data, "base64"))
    .catch((err) => {
      return err.response;
    });
}

export function getResource() {
  return axios
    .get(config.urlAPI + "/resource")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getResourceByType(search) {
  return axios
    .get(config.urlAPI + "/resource" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAircraft(id) {
  return axios
    .get(config.urlAPI + "/aircraftList/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightTable(search) {
  return axios
    .get(config.urlAPI + "/flightTable" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateDepart(id) {
  return axios
    .put(config.urlAPI + "/depart/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateArrive(id) {
  return axios
    .put(config.urlAPI + "/arrive/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateLanded(id) {
  return axios
    .put(config.urlAPI + "/landed/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function cancelDepart(id) {
  return axios
    .put(config.urlAPI + "/canceldepart/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function cancelArrive(id) {
  return axios
    .put(config.urlAPI + "/cancelarrive/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function cancelLanded(id) {
  return axios
    .put(config.urlAPI + "/cancellanded/" + id, {}, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getGroup() {
  return axios
    .get(config.urlAPI + "/group")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getCourse() {
  return axios
    .get(config.urlAPI + "/course", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseBySearch(qString) {
  return axios
    .get(config.urlAPI + "/course" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseById(id) {
  return axios
    .get(config.urlAPI + "/course/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getEventByIdBySearch(search) {
  return axios
    .get(config.urlAPI + "/reservation" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getEventDetail(id) {
  return (
    axios
      // .get(config.urlAPI + "/reservation/" + id + "?_embed=attendance")
      .get(config.urlAPI + "/reservation/" + id, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

export function getEventBySearch(search) {
  return axios
    .get(config.urlAPI + "/reservationlist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postEvent(data) {
  return axios
    .post(config.urlAPI + "/reservation", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateEvent(data) {
  return axios
    .put(config.urlAPI + "/reservation/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateGroundDetail(data) {
  return axios
    .put(
      config.urlAPI + "/instructorupdatereservation/" + data.id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function deleteEvent(id, data) {
  return axios
    .put(config.urlAPI + "/deletereservation/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function cancelEvent(id, data) {
  return axios
    .put(config.urlAPI + "/cancelreservation/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// export function postEventDetail(data) {
//   return axios
//     .post(config.urlAPI + "/reservation", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
export function postEventAttendant(data) {
  return axios
    .post(config.urlAPI + "/attendance", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getEventDetailBySearch(search) {
  return axios
    .get(config.urlAPI + "/reservation" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getEventByAttendanceId(id) {
  return axios
    .get(
      config.urlAPI + "/reservationAttendance?studentId=" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getEventByAttendanceSearch(search) {
  return axios
    .get(config.urlAPI + "/attendance" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAircraftGroup() {
  return axios
    .get(config.urlAPI + "/aircraftGroup")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAircraftList() {
  return axios
    .get(config.urlAPI + "/aircraftlist", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAircraftListBySearch(qString) {
  return axios
    .get(config.urlAPI + "/aircraftlist" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getInstructorsList() {
  return axios
    .get(config.urlAPI + "/instructorslist", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getInstructorsListBySearch(qString) {
  return axios
    .get(config.urlAPI + "/instructorslist" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getInstructorsDetail() {
  return axios
    .get(config.urlAPI + "/instructors")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getInstructorsGroupBySubject() {
  return axios
    .get(config.urlAPI + "/instructorsBySubject")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudentList() {
  return axios
    .get(config.urlAPI + "/studentslist", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRoomList() {
  return axios
    .get(config.urlAPI + "/room", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getRoomListBySearch(qString) {
  return axios
    .get(config.urlAPI + "/room" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getReservationTypes() {
  return axios
    .get(config.urlAPI + "/reservation/reservationtypes", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getReservationTypesBySearch(search) {
  return axios
    .get(
      config.urlAPI + "/reservation/reservationtypes" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getUpcoming(search) {
  return axios
    .get(config.urlAPI + "/reservationUpcoming" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentById(id) {
  return axios
    .get(config.urlAPI + "/studentList/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentPerpageBySearch(search) {
  return axios
    .get(config.urlAPI + "/students/perpage" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentBySearch(search) {
  return axios
    .get(config.urlAPI + "/studentslist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getSubjectByCourse(search) {
  return axios
    .get(config.urlAPI + "/course" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSubjectByCourseId(id) {
  return axios
    .get(
      config.urlAPI + "/reservation/groundsubjects/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightSubjects() {
  return axios
    .get(config.urlAPI + "/flightSubjects")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getGroundSubjects() {
  return axios
    .get(config.urlAPI + "/groundSubjects ")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentNotPassedGroundSubjects(id) {
  return axios
    .get(config.urlAPI + "/studentListNotPassedGroundSubjects/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudentGroupByGroundSubject(courseId, id) {
  return axios
    .get(
      config.urlAPI + "/reservation/allstudents/" + courseId + "/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTypeGroundInstructor() {
  return axios
    .get(config.urlAPI + "/groundinstructiontype", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postTypeGroundInstructor(data) {
  return axios
    .post(config.urlAPI + "/groundinstructiontype", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//  ===================================================
export function getFlightSubjectByStudent(id) {
  return axios
    .get(
      config.urlAPI + "/reservation/flightsubjects/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightAddSubjectByStudent(id) {
  return axios
    .get(
      config.urlAPI + "/reservation/flightaddsubjects/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getInstuctorBySubject(id) {
  return axios
    .get(config.urlAPI + "/reservation/instructors/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function checkFlightRulesSP(id, qString) {
  return axios
    .get(
      config.urlAPI + "/checkFlightRulesSP/" + id + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function checkFlightRulesIP(id, qString) {
  return axios
    .get(
      config.urlAPI + "/checkFlightRulesIP/" + id + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//  ====================Flight Hour Record====================
export function getStudentClass() {
  return axios
    .get(config.urlAPI + "/personnel/studentclass", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//  รอลบ
export function getLocation() {
  return axios
    .get(config.urlAPI + "/location")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getLogbookIP() {
  return axios
    .get(config.urlAPI + "/logbookIP", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getLogbookSP() {
  return axios
    .get(config.urlAPI + "/logbookSP", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getLogbookSPBySearch(qString) {
  return axios
    .get(config.urlAPI + "/logbookSP" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getLogbookIPBySearch(qString) {
  return axios
    .get(config.urlAPI + "/logbookIP" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAirport() {
  return axios
    .get(config.urlAPI + "/airport", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getLogbookByReservation(id) {
  return axios
    .get(config.urlAPI + "/logbookByReservation/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightHourRecord(qString) {
  return axios
    .get(config.urlAPI + "/flightHourRecord" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateAllFlightHourRecord(data) {
  return axios
    .put(config.urlAPI + "/flightHourRecord", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateFlightHourRecord(data) {
  return axios
    .put(
      config.urlAPI + "/flightHourRecord/" + data.id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightMonitorType() {
  return axios
    .get(config.urlAPI + "/monitortype", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTestType() {
  return axios
    .get(config.urlAPI + "/testtype", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportFlightHour(search) {
  return axios
    .get(config.urlAPI + "/flightHourRecord/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
