import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Card, Icon, Table, Form, Breadcrumb, Modal, Button } from "semantic-ui-react";
import * as config from "../../config";
import { DateInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from '../../components/headerwithmenu'
import moment from "moment"
import { genCrewVoyage, exportCrewVoyage } from './service'
import Wrap from '../../components/wrap'
import TableFixedColumns from "../../components/tableFixedColumns";

class CrewVoyageReport extends Component {
  state = {
    date: moment().format('dddd, MMMM DD, YYYY'),
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    sortText: '',
    options_sortby: [
      { key: 'aircraft', text: 'Aircraft', value: '1', icon: 'sort alphabet ascending', description: 'ASC' },
      { key: 'aircraft', text: 'Aircraft', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
      { key: 'callSign', text: 'Call sign', value: '3', icon: 'sort alphabet ascending', description: 'ASC' },
      { key: 'callSign', text: 'Call sign', value: '4', icon: 'sort alphabet descending', description: 'DESC' },
      { key: 'start', text: 'Start', value: '5', icon: 'sort alphabet ascending', description: 'ASC' },
      { key: 'start', text: 'Start', value: '6', icon: 'sort alphabet descending', description: 'DESC' },
    ],
    sortBy: null,
    column: [
      {
        Header: "Call sign",
        accessor: "callSign",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.callSign}
          </span>
        )
      }, {
        Header: "Aircraft",
        accessor: "aircraft",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.aircraft}
          </span>
        )
      }, {
        Header: "Time",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.start}
          </span>
        )
      }, {
        Header: "IP",
        accessor: "instructor",
        sortable: false,
        // style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.instructor}
          </span>
        )
      }, {
        Header: "STD",
        accessor: "student",
        sortable: false,
        // style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.student}
          </span>
        )
      }, {
        Header: "MS.",
        accessor: "subject",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.subject}
          </span>
        )
      }, {
        Header: "DEST",
        accessor: "destination",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.destination}
          </span>
        )
      }, {
        Header: "Area",
        accessor: "area",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.area}
          </span>
        )
      }, {
        Header: "ST",
        accessor: "ST",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.ST}
          </span>
        )
      }, {
        Header: "TO",
        accessor: "TO",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.TO}
          </span>
        )
      }, {
        Header: "LD",
        accessor: "LD",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.LD}
          </span>
        )
      }, {
        Header: "SD",
        accessor: "SD",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.SD}
          </span>
        )
      }, {
        Header: "Total",
        accessor: "flightInstruction",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: cellInfo => (
          <span className="">
            {cellInfo.original.flightInstruction}
          </span>
        )
      }
    ]
  }
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null })
    const { date, sortBy } = this.state
    let qString = '?date=' + moment(date, 'dddd, MMMM DD, YYYY').format('YYYY-MM-DD')
    if (sortBy) {
      qString += '&_sort=' + sortBy.key + '&_order=' + sortBy.description
    }
    exportCrewVoyage(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false })
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          return navigator.msSaveOrOpenBlob(newBlobIE, 'TechnicalCrewVoyage_' + moment().format('YYYYMMDDHHmmss') + '.xlsx');
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href })
        link.download = 'TechnicalCrewVoyage_' + moment().format('YYYYMMDDHHmmss') + '.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);

        }, 100);
      } else {
        this.setState({ errorMessage: 'The request is taking too long. Please try again' })
      }
    })
  }

  handleChangeSort = (e, data) => {
    let { sortBy } = this.state
    const result = data.options.find((member) => {
      return member.value === data.value
    })
    sortBy = result
    this.setState({ sortBy, sortText: `${result.text} (${result.description})` })
  }
  onClickFilterDate = () => {
    const { date, sortBy } = this.state
    let qString = '?date=' + moment(date, 'dddd, MMMM DD, YYYY').format('YYYY-MM-DD')
    if (sortBy) {
      qString += '&_sort=' + sortBy.key + '&_order=' + sortBy.description
    }

    this.getData(qString)
  }
  handleChangeDate = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] })
    genCrewVoyage(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data })
      }
      this.setState({ dataLoading: false })
    })
  }
  componentDidMount() {
    const { date } = this.state
    let qString = '?date=' + moment(date, 'dddd, MMMM DD, YYYY').format('YYYY-MM-DD')
    this.getData(qString)
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? 'tiny' : 'mini'}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => this.setState({ loadPopup: false, errorMessage: null })}>
          <Wrap>
            {state.errorMessage ?
              <Wrap>
                <Modal.Header><Icon name='warning sign' /> Warning</Modal.Header>
                <Modal.Content>
                  {state.errorMessage}
                </Modal.Content>
              </Wrap>
              :
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                    Waiting Download File
                  </div>
              </Modal.Content>
            }
          </Wrap>
        </Modal>
        <HeaderWithMenu name='Report - Technical Crew Voyage' >
          <Button
            size='small'
            onClick={this.onClickExport}
            color='green'>Export Excel</Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            Technical Crew Voyage
          </Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
              <DateInput
                name="date"
                placeholder="Select Date"
                hideMobileKeyboard
                dateFormat="dddd, MMMM DD, YYYY"
                iconPosition="right"
                value={state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Dropdown
              icon={null}
              // fluid
              // floating
              clearable
              width='4'
              style={{ width: '100%', marginRight: '30px', height: '100%' }}
              trigger={<Button basic icon fluid labelPosition='right' style={{ textAlign: 'left' }}>
                <Icon name='angle down' />
                Sort by : <b>{state.sortText || '-'}</b>
              </Button>}
              options={state.options_sortby}
              selectOnBlur={false}
              onChange={this.handleChangeSort}
            />
            <MainButton
              size='small'
              text="Filter"
              loading={state.dataLoading}
              onClick={this.onClickFilterDate} />
          </Form.Group>
        </Form>
        {/*<Table compact celled striped >
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell className='header-table'>Call sing</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Aircraft</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Time</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>IP</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>STD</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>MS.</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>DEST</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Area</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>
                      <div style={{ textAlign: "left" }}>ST</div>
                      <div style={{ textAlign: "right" }}>TO</div>
                    </Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>
                      <div style={{ textAlign: "left" }}>LD</div>
                      <div style={{ textAlign: "right" }}>SD</div>
                    </Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Total</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {state.dataLoading ? (
              <Table.Body>
                <Table.Cell colSpan={10}>
                  <div style={{ textAlign: "center" }}>
                    <Icon loading name="circle notch" />
                    <br />
                    Loading..
                  </div>
                </Table.Cell>
              </Table.Body>
            ): (
              <Table.Body>
                {state.data.map(tmp =>
                  <Table.Row textAlign='center'>
                    <Table.Cell>{tmp.callSign}</Table.Cell>
                    <Table.Cell>{tmp.aircraft}</Table.Cell>
                    <Table.Cell>{tmp.start}</Table.Cell>
                    <Table.Cell>{tmp.instructor}</Table.Cell>
                    <Table.Cell>{tmp.student}</Table.Cell>
                    <Table.Cell>{tmp.subject}</Table.Cell>
                    <Table.Cell>{tmp.destination}</Table.Cell>
                    <Table.Cell>{tmp.area}</Table.Cell>
                    <Table.Cell>
                      <div style={{ textAlign: "left" }}>{tmp.ST}</div>
                      <div style={{ textAlign: "right" }}>{tmp.TO}</div>
                    </Table.Cell>
                    <Table.Cell>
                      <div style={{ textAlign: "left" }}>{tmp.LD}</div>
                      <div style={{ textAlign: "right" }}>{tmp.SD}</div>
                    </Table.Cell>
                    <Table.Cell>{tmp.flightInstruction}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            )
          }
        </Table>*/}
        <TableFixedColumns
          data={state.data}
          columns={state.column}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    )
  }
}

export default withRouter(CrewVoyageReport);
