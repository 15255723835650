import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Grid,
  Tab,
  Image,
  Button,
  Dimmer,
  Loader,
  Header,
} from "semantic-ui-react";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import UploadImage from "../../components/uploadImage";
import ScheduleById from "../Schedule/scheduleById";
import FormReservation from "../Schedule/reservationForm";
import FormProfile from "./profileForm";
import ModalForm from "../../components/modal";
import {
  getAircraftById,
  updateFlight,
  uploadImageProfile,
  deleteImageProfile,
} from "./service";

class ProfileAircraft extends Component {
  state = {
    locationState: this.props.location.state,
    data: [],
    loading: true,
    rawdataRemind: [],
    dataRemind: [],
    rawdataSchedule: [], // รอลบ
    dataSchedule: [],
    search: {
      status: 4,
    },
    statusText: "Warning & Expired",
  };

  componentDidMount() {
    let { locationState } = this.state;
    this.getData(locationState.id);
  }

  getData = (id) => {
    this.setState({ loading: true });
    getAircraftById(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };

  onClickEdit = () => {
    let data = this.state.data;
    if (data.displayMismatches === "displayMismatches")
      data.displayMismatches = true;
    else data.displayMismatches = false;
    if (data.trackOilFuel === "true") data.trackOilFuel = true;
    else data.trackOilFuel = false;
    if (data.isActive === "true" || data.isActive === true)
      data.isActive = true;
    else data.isActive = false;
    data.airClass = data.aircraftClassId;
    data.simClass = data.simulatorClassId;
    this.setState({ openProfile: true, currentData: data });
  };
  onSave = (value) => {
    this.setState({ loading: true });
    let { data } = this.state;

    if (value.displayMismatches) value.displayMismatches = "true";
    else value.displayMismatches = "false";
    if (value.trackOilFuel) value.trackOilFuel = "true";
    else value.trackOilFuel = "false";
    if (value.isActive) value.isActive = "true";
    else value.isActive = "false";
    value.imageUrl = data.imageUrl;
    updateFlight(value).then((res) => {
      if (res && res.status === 200) this.getData(data.id);
      this.onClickCancel();
    });
  };
  onUploadPhoto = (file) => {
    if (file) {
      this.setState({ loading: true, uploadError: null });
      let { data } = this.state;
      var formData = new FormData();
      if (typeof file === "object") formData.append("image", file[0]);
      else formData.append("image", file);
      uploadImageProfile(data.id, formData).then((res) => {
        if (res && res.status === 200) {
          this.setState({
            imageUrl: res.data.imageUrl,
          });
          this.getData(data.id);
        } else if (res && res.status === 422) {
          this.setState({
            uploadError:
              res.data.errors &&
              res.data.errors.image &&
              res.data.errors.image[0],
          });
        } else {
          this.setState({
            uploadError: "Whoops, looks like something went wrong.",
          });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhoto = () => {
    let { data } = this.state;
    deleteImageProfile(data.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          imageUrl: null,
        });
        this.getData(data.id);
      }
      this.setState({ loading: false });
    });
  };
  onClickCancel = () =>
    this.setState({
      openRemind: false,
      openBook: false,
      openProfile: false,
      openUploadPhoto: false,
      currentData: null,
    });
  onClickBook = () => this.setState({ openBook: true });
  onClickUploadPhoto = () => {
    let { data } = this.state;
    this.setState({
      openUploadPhoto: true,
      imageUrl: data.imageUrl,
    });
  };

  render() {
    const state = this.state;
    if (state.data.length === 0)
      return (
        <Segment className="contentSegment" style={{ minHeight: "50vh" }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );

    return (
      <div>
        <ModalForm
          open={state.openBook}
          icon="plane"
          content={`${state.data.tailNumber} ${state.data.schedulingGroupName}`}
          close={this.onClickCancel}
          save={this.onSave}
        >
          <FormReservation />
        </ModalForm>
        <ModalForm
          open={state.openUploadPhoto}
          icon="image outline"
          size="small"
          content={`${state.data.tailNumber} ${state.data.schedulingGroupName}`}
          close={this.onClickCancel}
          save={this.onUploadPhoto}
        >
          <UploadImage
            uploadError={state.uploadError}
            loading={state.loading}
            imageUrl={state.imageUrl}
            onChange={this.onUploadPhoto}
            onClickDelete={this.onDeletePhoto}
          />
        </ModalForm>
        {/* <Form onSubmit={handleSubmit}>
          <Image src={this.props.data.imageUrl || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} size='small' circular centered />
          <Field name="file" type="file"
            required={true}
            component={renderFileField} label="Upload Picture" />
          <br />
          <Button floated='left' type="submit" content='Save' color='green' disabled={pristine || submitting} />
          <Button floated='right' type="button" content='Cancel' color='green' basic onClick={this.props.onCancel} />
        </Form> */}
        <ModalForm
          open={state.openProfile}
          icon="plane"
          content={`${state.data.tailNumber} ${state.data.schedulingGroupName}`}
          close={this.onClickCancel}
        >
          <FormProfile
            loading={state.loading}
            initialValues={state.currentData}
            onSubmit={this.onSave}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Aircraft / Simulator">
            <Button size="small" onClick={this.onClickEdit}>
              Edit Profile
            </Button>
            <Button size="small" onClick={this.onClickUploadPhoto}>
              Upload Photo
            </Button>
            {/* <Button color='green' size='small' onClick={this.onClickBook} > Book now</Button> */}
            {/* <Button color='green' size='small' onClick={() => console.log("d")} > Book now</Button> */}
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Aircrafts")}
              style={{ color: "grey" }}
            >
              Aircraft / Simulator
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Profile</Breadcrumb.Section>
          </Breadcrumb>
          <br /> <br />
          <Grid>
            <Grid.Row columns={2} divided style={{ paddingBottom: 0 }}>
              <Grid.Column width={4}>
                <Image
                  src={
                    state.data.imageUrl
                      ? state.data.imageUrl + "?time=" + new Date()
                      : "https://app.flightschedulepro.com/images/instructor-default.gif"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://app.flightschedulepro.com/images/instructor-default.gif";
                  }}
                />
                <br />
                <div style={{ paddingBottom: "14px", paddingRight: "14px" }}>
                  <p className="bold" style={{ marginBottom: "4px" }}>
                    DESCRIPTION
                  </p>
                  {state.data.description ? (
                    <p>{state.data.description}</p>
                  ) : (
                    <small>No description available</small>
                  )}
                  <p className="bold" style={{ marginBottom: "4px" }}>
                    AVIONICS
                  </p>
                  {state.data.avionics ? (
                    <p>{state.data.avionics}</p>
                  ) : (
                    <small>No avionics description is available</small>
                  )}
                  <p className="bold" style={{ marginBottom: "4px" }}>
                    EQUIPMENT
                  </p>
                  {state.data.accessories ? (
                    <p>{state.data.accessories}</p>
                  ) : (
                    <small>No equipment description is available</small>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={12}>
                <Header as="h2" style={{ color: config.colorTheme }}>
                  <Header.Content>
                    {state.data.tailNumber} {state.data.schedulingGroupName}
                  </Header.Content>
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="3">
                      <p className="bold">Category</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.category === "airplane"
                        ? `Airplane (${state.data.classname})`
                        : `Flight Simulator (${state.data.classname})`}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Status</p>{" "}
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.isActive === "true" ||
                      state.data.isActive === true
                        ? "Active"
                        : "Inactive"}
                    </Grid.Column>
                  </Grid.Row>
                  {state.data.category === "airplane" && (
                    <Grid.Row style={{ paddingTop: 0 }}>
                      <Grid.Column width="3">
                        <p className="bold">Engines have Propellers</p>
                      </Grid.Column>
                      <Grid.Column width="5">
                        {state.data.enginesHavePropellers ? "Yes " : "-"}
                      </Grid.Column>
                      <Grid.Column width="3">
                        <p className="bold">Engines are Turbines</p>
                      </Grid.Column>
                      <Grid.Column width="5">
                        {state.data.enginesAreTurbines ? "Yes " : "-"}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">Year</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.year || "-"}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Number of Seats</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.numberOfSeats || "-"}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">Fuel Capacity</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.fuelCapacity || "-"}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Useful Load</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.usefulLoad || "-"}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">Empty Weight</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.emptyWeight || "-"}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Max Gross Weight</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.maxGrossWeight || "-"}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">ARM</p>
                    </Grid.Column>
                    <Grid.Column width="5">{state.data.arm || "-"}</Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Moment</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.moment || "-"}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">Hangar / Tie Down</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.hangarTieDown || "-"}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Color Scheme</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.colorScheme || "-"}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column width="3">
                      <p className="bold">Serial Number</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.serialNumber || "-"}
                    </Grid.Column>
                    <Grid.Column width="3">
                      <p className="bold">Remark</p>
                    </Grid.Column>
                    <Grid.Column width="5">
                      {state.data.remark || "-"}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <br />
          <Tab
            renderActiveOnly
            menu={{ secondary: true, pointing: true }}
            panes={[
              // {
              //     menuItem: 'Maint. Reminders', render: () => (
              //         <div>
              //             <Reminder id={state.locationState.id} />
              //         </div>
              //     )
              // }, {
              //     menuItem: 'Snags', render: () => (
              //         <div>
              //             Snags list
              //         </div>
              //     )
              // },
              {
                menuItem: "Schedule",
                render: () => (
                  <div>
                    <ScheduleById id={state.data.id} disableClick />
                  </div>
                ),
              },
              {
                menuItem: "History",
                render: () => <div className="bold">No history available</div>,
              },
            ]}
          />
        </Segment>
      </div>
    );
  }
}

export default withRouter(ProfileAircraft);
