import axios from "axios";
import * as config from "../../config";

export function getCheckListByCourseID(id) {
  return axios
    .get(config.urlAPI + "/courseChecklist/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getProgressCheckListDetail(id) {
  return axios
    .get(config.urlAPI + "/progressChecklist/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSnagById(id) {
  return axios
    .get(
      config.urlAPI + "/flightTraningRecord/snag/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAttendanceById(id) {
  return axios
    .get(config.urlAPI + "/attendance/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
