import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import * as config from '../../config'
import validate from '../../validate'
// import { aircraft } from './data'

import renderField from '../../components/renderField'
import renderSelectField from '../../components/renderSelectField'
import renderTextAreaField from '../../components/renderTextAreaField'
import renderRadioField from '../../components/renderRadioField'

class FromRemind extends Component {

    state = {
        aircraft: this.props.aircraft,
        selected: false,
        aircraft_option: [],
        OneTimeReminder: false
    }

    componentDidMount() {
        if (this.props.aircraft) {
            this.setState({ selected: true })
        } else {
            let aircraft_option = []
            // aircraft.map(item => aircraft_option.push({ key: item.id, text: `${item.tailNumber} ${item.make} ${item.model}`, value: item.id }))
            this.setState({ aircraft_option, })
        }
    }


    handleChangeisOneTimeReminder = (OneTimeReminder) => {
        this.setState({ OneTimeReminder, })
    }

    render() {
        const state = this.state
        const { handleSubmit, pristine, submitting } = this.props
        return (
            <Form  >
                {/* <Form onSubmit={handleSubmit(this.onClickSave)}> */}
                {
                    state.selected
                        ? null
                        : <Field
                            name="aircraft" width="16"
                            required={true} component={renderSelectField}
                            search={true} placeholder='Please Select'
                            clearable={true}
                            label="Aircraft" options={state.aircraft_option} />
                }
                <Field
                    name="aircraftx" width="16"
                    required={true} component={renderField}
                    placeholder='Please Select'
                    label="Maintenance reminder name" />

                <Field
                    name="aircraftx" width="16"
                    label='Note'
                    required={true} component={renderTextAreaField}
                    placeholder='Please Select' />

                <Field name="canBeOverridden"
                    width="11"
                    label='canBeOverridden'
                    component={renderRadioField} />
                <Field name="isOneTimeReminder"
                    width="11"
                    label='One-Time Only'
                    component={renderRadioField}
                    onChange={this.handleChangeisOneTimeReminder} />
                {state.OneTimeReminder
                    ?
                    <Field name="isPermanentlyComplied"
                        width="11"
                        label='Permanently Complied'
                        component={renderRadioField}
                    />
                    : null}
                <h4 style={{ color: config.colorTheme }}>Time-Based Reminder </h4>
                <Form.Group widths='equal'>
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Last Completed At (Hours)" />
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Due Every (Hours)" />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Next Due at (Hours)" />
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Warning Period Begins (Hours)" />
                </Form.Group>

                <br />
                <Button floated='left' type="submit" content='Save' color='green' disabled={pristine || submitting} />
                <Button floated='right' type="button" content='Cancel' color='green' basic onClick={this.props.onCancel} />
            </Form>

        )
    }
}

export default FromRemind = reduxForm({
    // a unique name for the form
    form: 'reminder',
    validate,
    enableReinitialize: true
})(FromRemind)
