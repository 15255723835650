import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Breadcrumb,
  Modal,
  Button,
} from "semantic-ui-react";
import * as config from "../../config";
import { DateInput, MonthInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import { genGroundCancel, exportGroundCancel } from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class GroundScheduleReport extends Component {
  state = {
    date: moment().format("MMMM, YYYY"),
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    column: [
      {
        Header: "DATE",
        accessor: "day",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
      },
      {
        Header: "",
        accessor: "date",
        sortable: false,
        style: { textAlign: "center" },
        width: 70,
      },
      {
        Header: "NUMBER OF CLASSES",
        accessor: "all",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "NUMBER OF CANCELED CLASSES",
        accessor: "cancel",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "SCHEDULE PERFORMANCE",
        accessor: "percentage",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "CANCELLATION OR UNSCHEDULE PERSON",
        accessor: "instructor",
        sortable: false,
        style: { textAlign: "left" },
      },
      {
        Header: "REASON",
        accessor: "reason",
        sortable: false,
        style: { textAlign: "left" },
      },
      {
        Header: "ACTION BY",
        accessor: "actionBy",
        sortable: false,
        style: { textAlign: "left" },
      },
      {
        Header: "ACTION TIME",
        accessor: "actionAt",
        sortable: false,
        style: { textAlign: "left" },
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { date } = this.state;
    let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    exportGroundCancel(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "GroundScheduleCancel_" +
              moment().format("YYYYMMDDHHmmss") +
              ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "GroundScheduleCancel_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const { date } = this.state;
    let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    this.getData(qString);
  };
  handleChangeDate = (event, { name, value }) => {
    console.log(name, value);
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genGroundCancel(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date } = this.state;
    let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    this.getData(qString);
  }
  render() {
    let state = this.state;
    console.log(state);
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Ground Schedule's Cancellation Monthly">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            Ground Schedule's Cancellation Monthly
          </Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={3}>
              {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
              <MonthInput
                name="date"
                placeholder="Select Month"
                hideMobileKeyboard
                dateFormat="MMMM, YYYY"
                iconPosition="right"
                value={state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <MainButton
              size="small"
              text="Filter"
              loading={state.dataLoading}
              onClick={this.onClickFilterDate}
            />
          </Form.Group>
        </Form>
        {/*<Table compact celled striped >
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell className='header-table'>DATE</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>NUMBER OF CLASSES</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>NUMBER OF CANCELED CLASSES</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>SCHEDULE PERFORMANCE</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>CANCELLATION OR UNSCHEDULE PERSON</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>REASON</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {state.dataLoading ? (
              <Wrap>
                <Table.Body>
                  <Table.Cell colSpan={7}>
                    <div style={{ textAlign: "center" }}>
                      <Icon loading name="circle notch" />
                      <br />
                      Loading..
                    </div>
                  </Table.Cell>
                </Table.Body>
                <Table.Footer>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell colSpan='2' className='header-table'>TOTAL</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell colSpan='2' className='header-table'></Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Wrap>
            ): (
              <Wrap>
                <Table.Body>
                  {state.data.map(tmp =>
                    <Table.Row textAlign='center'>
                      <Table.Cell>{tmp.day}</Table.Cell>
                      <Table.Cell>{tmp.date}</Table.Cell>
                      <Table.Cell>{tmp.all}</Table.Cell>
                      <Table.Cell>{tmp.cancel}</Table.Cell>
                      <Table.Cell>{tmp.percentage}</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell className='header-table' colSpan='2'>TOTAL</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table'></Table.HeaderCell>
                    <Table.HeaderCell className='header-table' colSpan='2'></Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Wrap>
            )
          }
          </Table>*/}
        <TableFixedColumns
          data={state.data}
          columns={state.column}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(GroundScheduleReport);
