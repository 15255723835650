import React from "react";
import { Card, Placeholder, Image, Button } from "semantic-ui-react";

const CardImage = (props) => (
  <div>
    {props.loading ? (
      <Card raised>
        <Placeholder>
          <Placeholder.Image rectangular />
        </Placeholder>
      </Card>
    ) : !Object.keys(props.data).length ? (
      "Data not found"
    ) : (
      <Card.Group itemsPerRow={props.itemsPerRow} doubling>
        {props.data.map((item) => (
          <Card raised key={item.id} onClick={() => props.onClick(item)}>
            <Image
              style={{
                height: props.imageHeight || "125px",
                maxHeight: props.imageHeight || "125px",
                width: "auto",
              }}
              src={item.image}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = props.imageonerror;
              }}
            />
            <Card.Content style={{ padding: "5px" }}>
              <h5 style={{ margin: "5px" }}>{item.description}</h5>
            </Card.Content>
            {item.linkContent ? (
              <Card.Content extra style={{ padding: "5px" }}>
                <Button.Group size="mini" fluid>
                  <Button
                    size="small"
                    color="green"
                    onClick={() => props.mainClick(item)}
                  >
                    {props.maintext}
                  </Button>
                  <Button
                    size="small"
                    style={{ backgroundColor: "#80808017" }}
                    onClick={() => props.secondClick(item)}
                  >
                    {props.secondtext}
                  </Button>
                </Button.Group>
              </Card.Content>
            ) : null}
          </Card>
        ))}
      </Card.Group>
    )}
  </div>
);

export default CardImage;
