import React, { Component } from "react";
import {
  Segment,
  Container,
  Header,
  Form,
  Message,
  Input,
  Button,
  Icon,
  Image,
  Grid,
  Divider,
  List,
  Label,
  Table,
  Radio,
  TextArea,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import HeaderWithMenu from "../../../components/headerwithmenu";
import * as config from "../../../config";
import {
  postLogin,
  getFlightTraningRecordById,
  postFlightTraningRecord,
  getSnagById,
} from "../service";
import moment from "moment";
import Wrap from "../../../components/wrap";
import ModalForm from "../../../components/modal";
import Confirmmodal from "../../../components/customconfirmmodal";
import { withRouter } from "react-router-dom";

class ScoreView extends Component {
  state = {
    open: false,
    dataInstructor: {
      username: {
        value: "",
        validation: {
          required: true,
        },
        valid: false,
      },
      password: {
        value: "",
        validation: {
          required: true,
          minLength: 6,
        },
        valid: false,
      },
    },
    errormsg: null,
    locationState: this.props.locationState,
    loading: true,

    data: this.props.data,
    lesson: this.props.data.lesson,
    lessonRaw: this.props.data.lesson,
    criteria: this.props.data.criteria,
    totalthisflight: this.props.data.totalthisflight,
    comment: this.props.data.comment,
    repeatItem: this.props.data.repeatItem,
    rawRepeatItem: this.props.data.repeatItem,
    accumulate: this.props.data.student.accumulate,
  };
  getSnag = (id) => {
    getSnagById(id).then((res) => {
      if (res && res.status === 200) {
        this.setState({ snag: res.data });
      }
    });
  };

  getById = (id) => {
    getFlightTraningRecordById(id).then((res) => {
      if (res && res.status === 200) {
        // console.log('getFlightTraningRecordById',res);
        this.setState({
          data: res.data,
          lesson: res.data.lesson,
          lessonRaw: res.data.lesson,
          criteria: res.data.criteria,
          totalthisflight: res.data.totalthisflight,
          comment: res.data.comment,
          repeatItem: res.data.repeatItem,
        });
      }
      this.setState({ loading: false });
    });
  };
  editSnag = (data) => {
    // console.log('editSnag',data)
    const { locationState } = this.state;
    this.props.history.push({
      pathname: "/TrainRecord/Snag",
      state: { id: data.reservationId },
    });
  };
  componentDidMount() {
    // let qString = "/1"; //Flight Training
    const { locationState, data } = this.state;
    // console.log(locationState)
    this.getSnag(data.student.studentId);
  }
  printIframe() {
    window.print();
    // window.frames[0].focus();
    // window.frames[0].print();
  }
  render() {
    const state = this.state;
    const { locationState } = this.state;

    return (
      <div>
        <Grid>
          <Grid.Row columns={3} textAlign="center">
            <Grid.Column width={5}>
              <Image
                centered
                src="https://ba.ac.th/web-assets/img/logo-bac.png"
                style={{
                  height: "auto",
                  width: "auto",
                }}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <Header as="h1">
                <Header.Content>
                  FLIGHT TRAINING RECORD
                  <Header.Subheader>
                    Bangkok Aviation Center Public Company Limited
                  </Header.Subheader>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column width={5}>
              <h1>{state.data.subject.code || "-"}</h1>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns="equal">
            <Grid.Column>
              Student's name:{" "}
              {state.data.student ? (
                <b>{`${state.data.student.studentName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Date:{" "}
              {state.data.start ? (
                <b>{`${moment(state.data.start).format("YYYY-MM-DD")}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Reg HS -{" "}
              {state.data.aircraftName ? (
                <b>{`${state.data.aircraftName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              Instructor's name:{" "}
              {state.data.instructorName ? (
                <b>{`${state.data.instructorName}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Subject:{" "}
              {state.data.name ? (
                <b>{`${state.data.name} (${state.data.hours})`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
            <Grid.Column>
              Route/Legs:{" "}
              {state.data.departure ? (
                <b>{`${state.data.departure} - ${state.data.destination}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              START: {state.data.ST ? <b>{`${state.data.ST}`}</b> : "-"}<br />
              TAKEOFF: {state.data.ST ? <b>{`${moment(state.data.ST, "HH:mm")
                .add(10, "minutes")
                .format("HH:mm")}`}</b> : "-"}
            </Grid.Column>
            <Grid.Column>
              LANDING: {state.data.SD ? <b>{`${moment(state.data.SD, "HH:mm")
                .add(-10, "minutes")
                .format("HH:mm")}`}</b> : "-"}<br />
              SHUTDOWN: {state.data.SD ? <b>{`${state.data.SD}`}</b> : "-"}
              {/* SHUTDOWN: {state.data.SD ? <b>{`${state.data.SD}`}</b> : "-"} */}
            </Grid.Column>
            <Grid.Column>
              ACTUAL:{" "}
              {state.data.flightInstruction ? (
                <b>{`${state.data.flightInstruction}`}</b>
              ) : (
                "-"
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <br />
        <Container>
          <p>
            <b>Objective:</b>{" "}
            {state.data.objective ? state.data.objective : "-"}
          </p>
          <p style={{ color: "red" }}>
            <b>Snag: &nbsp;&nbsp;</b>
            {state.snag && (
              <Wrap>
                {state.snag.flightSubjects.length > 0 &&
                  state.snag.flightSubjects.map(
                    (fight) =>
                      fight.subject.length > 0 &&
                      fight.subject.map((sub) =>
                        this.props.portfolio ? (
                          <Label color="red">
                            {sub.code} (
                            {sub.lesson.map((les, index) => {
                              if (index == 0) {
                                return <span>{les.name}</span>;
                              } else {
                                return <span>, {les.name}</span>;
                              }
                            })}
                            )
                          </Label>
                        ) : (
                          <Label
                            as="a"
                            color="red"
                            onClick={() => this.editSnag(sub)}
                          >
                            {sub.code} (
                            {sub.lesson.map((les, index) => {
                              if (index == 0) {
                                return <span>{les.name}</span>;
                              } else {
                                return <span>, {les.name}</span>;
                              }
                            })}
                            )
                          </Label>
                        )
                      )
                  )}
              </Wrap>
            )}
            {/* groundSubjects */}
          </p>
        </Container>
        <br />
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table" width={1}>
                No.
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table" width={5}>
                Lesson
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table" width={3}>
                Reference
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={1}
              >
                MR
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={1}
              >
                0
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={1}
              >
                1
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={1}
              >
                2
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={1}
              >
                3
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                textAlign="center"
                width={2}
              >
                Remark
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.lesson &&
              state.lesson.map((les, index) => {
                return (
                  <Table.Row>
                    <Table.Cell width={1}>{index + 1}</Table.Cell>
                    <Table.Cell width={5}>{les.name}</Table.Cell>
                    <Table.Cell width={3}>
                      {les.url ? (
                        <a href={les.url} target="_blank">
                          {les.reference}
                        </a>
                      ) : (
                        les.reference
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {les.MR}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {les.value === "0" && <Icon size="small" name="circle" />}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {les.value === "1" && <Icon size="small" name="circle" />}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {les.value === "2" && <Icon size="small" name="circle" />}
                    </Table.Cell>
                    <Table.Cell textAlign="center" width={1}>
                      {les.value === "3" && <Icon size="small" name="circle" />}
                    </Table.Cell>
                    <Table.Cell width={2}>{les.remark}</Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <br />
        <Grid>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Divider horizontal>
                <b>COMMENT</b>
              </Divider>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <b>Comment:</b> {state.comment.student && <div dangerouslySetInnerHTML={{ __html: state.comment.student }} />}
            </Grid.Column>
            {/* <Grid.Column>
              <b>Instructor:</b> {state.comment.instructor}
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Grid>
                <Divider horizontal>Repeat item</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    {state.repeatItem && state.repeatItem.length > 0 && (
                      <List as="ul">
                        {state.repeatItem.map((rep) =>
                          rep.status === "pass" ? (
                            <List.Item as="li" style={{ color: "green" }}>
                              {rep.name} : Failed Score {rep.value} (Passed on{" "}
                              {moment(rep.passedAt).format("MMM DD, YYYY")})
                            </List.Item>
                          ) : (
                            <List.Item as="li" style={{ color: "red" }}>
                              {rep.name}
                            </List.Item>
                          )
                        )}
                      </List>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Divider horizontal>No. of landing</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <p>
                      <b>
                        Total this flight:{" "}
                        {state.totalthisflight ? state.totalthisflight : 0}
                      </b>
                    </p>
                  </Grid.Column>
                  <Grid.Column>
                    <p>
                      <b>
                        Total to Date: {state.accumulate ? state.accumulate : 0}
                      </b>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Divider horizontal>Accumulate hour</Divider>
                <Grid.Row columns="equal">
                  <Grid.Column>
                    <p>
                      <b>
                        Accumulate hour:{" "}
                        {state.data.student
                          ? state.data.student.accumulate_hour
                          : "-"}{" "}
                      </b>
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="header-table" width={12}>
                      Evaluation criteria
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      1
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      2
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      3
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={1}
                    >
                      4
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.criteria &&
                    state.criteria.map((criteria) => {
                      return (
                        <Table.Row>
                          <Table.Cell width={12}>{criteria.name}</Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            {criteria.value === "1" && (
                              <Icon size="small" name="circle" />
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            {criteria.value === "2" && (
                              <Icon size="small" name="circle" />
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            {criteria.value === "3" && (
                              <Icon size="small" name="circle" />
                            )}
                          </Table.Cell>
                          <Table.Cell textAlign="center" width={1}>
                            {criteria.value === "4" && (
                              <Icon size="small" name="circle" />
                            )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column textAlign="center">
              {!this.props.portfolio && (
                <Button onClick={() => this.props.history.push("/TrainRecord")}>
                  Back
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default withRouter(ScoreView);
