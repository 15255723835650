import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Loader,
  Table,
  Icon,
  Grid
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import FormLogbook from "./logbookForm";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";
import {
  getLogbookIP,
  getLogbookSP,
  postLogbook,
  updateLogbook,
  deleteLogbook
} from "./service";
import Wrap from "../../components/wrap";

class Logbook extends Component {
  state = {
    loading: true,
    logbookIP: [],
    logbookSP: []
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    getLogbookIP("?editable=true").then(res => {
      if (res && res.status === 200) {
        this.setState({ logbookIP: res.data, loading: false });
      }
    });
    getLogbookSP("?editable=true").then(res => {
      if (res && res.status === 200) {
        this.setState({ logbookSP: res.data, loading: false });
      }
    });
  };
  onClickNew = () => {
    this.setState({ open: true, isNew: true, content: "Add New Logbook" });
  };
  onClickEdit = (data, type) => {
    data.type = type;
    this.setState({
      open: true,
      content: "Edit Logbook",
      currentData: data,
      isNew: false
    });
  };

  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };

  onClickCancel = () => {
    this.setState({
      open: false,
      content: "Add New Logbook",
      currentData: null,
      isNew: false
    });
  };

  saveData = value => {
    this.setState({ loading: true });
    if (value.group && value.group.length !== 0) {
      value.group.map(item => {
        let data = {
          name: item.name,
          group: value.name,
          editable: "true",
          type: value.type
        };
        postLogbook(data).then(res => {
          if (res && res.status === 201) {
            this.getData();
          }
        });
      });
      this.getData();
      this.onClickCancel();
    } else {
      let data = {
        name: value.name,
        group: null,
        editable: "true",
        type: value.type
      };
      postLogbook(data).then(res => {
        if (res && res.status === 201) {
          this.getData();
          this.onClickCancel();
        }
      });
    }
  };

  updateData = value => {
    this.setState({ loading: true });
    if (value.group && value.group.length !== 0) {
      let { currentData } = this.state;
      currentData.group.map(item => {
        const result = value.group.find(member => {
          return member.id === item.id;
        });
        if (result && result.name) {
          let data = {
            id: result.id,
            name: result.name,
            group: value.name,
            editable: "true",
            type: value.type
          };
          updateLogbook(data).then(res => {
            if (res && res.status === 200) {
              this.getData();
            }
          });
        } else {
          deleteLogbook(item.id);
        }
      });
      value.group.map(item => {
        if (!item.id && item.name) {
          let data = {
            name: item.name,
            group: value.name,
            editable: "true",
            type: value.type
          };
          postLogbook(data).then(res => {
            if (res && res.status === 201) {
              this.getData();
            }
          });
        }
      });
      this.onClickCancel();
    } else {
      let data = {
        id: value.id,
        name: value.name,
        group: null,
        editable: "true",
        type: value.type
      };
      updateLogbook(data).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.onClickCancel();
        }
      });
    }
  };

  deleteData = () => {
    this.setState({ loading: true });
    let { currentData } = this.state;
    if (currentData.group && currentData.group.length !== 0) {
      currentData.group.map(item => {
        deleteLogbook(item.id).then(res => {
          if (res && res.status === 200) {
            this.getData();
            this.onClickCancel();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
      });
    } else if (currentData) {
      deleteLogbook(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.onClickCancel();
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
    }
  };

  render() {
    const state = this.state;
    return (
      <div>
        <ModalForm
          open={state.open}
          icon="address book"
          content={state.content}
          close={this.onClickCancel}
        >
          <FormLogbook
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.isNew ? this.saveData : this.updateData}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Logbook">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Logbook"
            />
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Logbook</Breadcrumb.Section>
          </Breadcrumb>
          <br />
          <br />
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <Grid columns={2}>
              <Grid.Row>
                <Grid.Column>
                  <Table
                    compact
                    celled
                    striped
                    style={{ marginBottom: "30px" }}
                    unstackable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={1} />
                        <Table.HeaderCell className="header-table" width={10}>
                          Logbook IP
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          textAlign="center"
                          width={3}
                        />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.logbookIP.map((item, num) =>
                        item.group ? (
                          <Wrap>
                            <Table.Row>
                              <Table.Cell textAlign="right">
                                {num + 1}
                              </Table.Cell>
                              <Table.Cell
                                className="bold"
                                style={{ fontSize: "90%" }}
                              >
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => this.onClickEdit(item, "IP")}
                                >
                                  {item.name}
                                </p>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon
                                  link
                                  name="edit"
                                  onClick={() => this.onClickEdit(item, "IP")}
                                />
                                &nbsp; &nbsp;
                                <Icon
                                  link
                                  color="red"
                                  name="trash alternate"
                                  onClick={() => this.onClickDelete(item)}
                                />
                              </Table.Cell>
                            </Table.Row>
                            {item.group.map(sub => (
                              <Table.Row>
                                <Table.Cell textAlign="right" />
                                <Table.Cell
                                  className="bold"
                                  style={{
                                    fontSize: "90%",
                                    paddingLeft: "15px"
                                  }}
                                >
                                  <li>{sub.name}</li>
                                </Table.Cell>
                                <Table.Cell textAlign="center"></Table.Cell>
                              </Table.Row>
                            ))}
                          </Wrap>
                        ) : (
                          <Table.Row>
                            <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                            <Table.Cell
                              className="bold"
                              style={{ fontSize: "90%" }}
                            >
                              <p
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer"
                                }}
                                onClick={() => this.onClickEdit(item, "IP")}
                              >
                                {item.name}
                              </p>
                            </Table.Cell>

                            <Table.Cell textAlign="center">
                              <Icon
                                link
                                name="edit"
                                onClick={() => this.onClickEdit(item, "SP")}
                              />
                              &nbsp; &nbsp;
                              <Icon
                                link
                                color="red"
                                name="trash alternate"
                                onClick={() => this.onClickDelete(item)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column>
                  <Table
                    compact
                    celled
                    striped
                    style={{ marginBottom: "30px" }}
                    unstackable
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={1} />
                        <Table.HeaderCell className="header-table" width={10}>
                          Logbook SP
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          textAlign="center"
                          width={3}
                        />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.logbookSP.map((item, num) =>
                        item.group ? (
                          <Wrap>
                            <Table.Row>
                              <Table.Cell textAlign="right">
                                {num + 1}
                              </Table.Cell>
                              <Table.Cell
                                className="bold"
                                style={{ fontSize: "90%" }}
                              >
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => this.onClickEdit(item, "IP")}
                                >
                                  {item.name}
                                </p>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon
                                  link
                                  name="edit"
                                  onClick={() => this.onClickEdit(item, "SP")}
                                />
                                &nbsp; &nbsp;
                                <Icon
                                  link
                                  color="red"
                                  name="trash alternate"
                                  onClick={() => this.onClickDelete(item)}
                                />
                              </Table.Cell>
                            </Table.Row>
                            {item.group.map(sub => (
                              <Table.Row>
                                <Table.Cell textAlign="right" />
                                <Table.Cell
                                  className="bold"
                                  style={{
                                    fontSize: "90%",
                                    paddingLeft: "15px"
                                  }}
                                >
                                  <li>{sub.name}</li>
                                </Table.Cell>
                                <Table.Cell textAlign="center"></Table.Cell>
                              </Table.Row>
                            ))}
                          </Wrap>
                        ) : (
                          <Table.Row>
                            <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                            <Table.Cell
                              className="bold"
                              style={{ fontSize: "90%" }}
                            >
                              <p
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer"
                                }}
                                onClick={() => this.onClickEdit(item, "SP")}
                              >
                                {item.name}
                              </p>
                            </Table.Cell>

                            <Table.Cell textAlign="center">
                              <Icon
                                link
                                name="edit"
                                onClick={() => this.onClickEdit(item, "SP")}
                              />
                              &nbsp; &nbsp;
                              <Icon
                                link
                                color="red"
                                name="trash alternate"
                                onClick={() => this.onClickDelete(item)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Segment>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="logbook"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }

  handleChangeSort = (e, data) => {
    let { search } = this.state;
    let qString;

    const result = data.options.find(member => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
      loading: true
    });

    qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    this.getData(qString);
  };

  handleChangeSearch = e => {
    let { rawData, search } = this.state;
    search[e.target.name] = e.target.value;
    this.setState({ search });
    if (e.target.value) {
      let newdata = [];
      rawData.map(item => {
        if (
          item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawData });
    }
  };
}

export default withRouter(Logbook);
