import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Segment, Dropdown, Form, Button, Image, Icon } from 'semantic-ui-react'
import * as config from '../../config'
import {
    postStudent,
    getAllClass, getAllCompany, getStudentType,
    getAllCourse, importStudent, getAllInstructors,
    getStudentById, updateStudent
} from '../Student/service'
import {
    deleteUser,
    getUserStudent,
    resetpassUser
} from "./service";
import HeaderWithMenu from '../../components/headerwithmenu'
// import TableList from '../../components/table'
import TableList from "../../components/tablePerPage";
import MainButton from '../../components/mainbutton'
import ModalForm from '../../components/modal'
import DropdownCheckbox from '../../components/dropdownCheckbox'
import Wrap from '../../components/wrap'
import ScheduleById from '../Schedule/scheduleById'
import FormStudent from './userStudentForm';
import moment from "moment"
import Confirmmodal from "../../components/confirmmodal";


class userStudent extends Component {
    state = {
        current_page: 0,
        per_page: 10,
        last_page: 1,
        open: false,
        loading: false,
        btnLoading: false,
        importFile: false,
        errorsRow: 0,
        errorInfo: null,
        dataEdit: null,
        rawData: [],
        data: [],
        typeConfirm: null,
        dataEvent: [],
        options_class: [],
        options_company: [],
        options_course: [],
        options_instructors: [],
        options_studenttype: [],
        select_course: null,
        select_class: null,
        select_company: null,
        select_stdType: null,
        filterClassByText: 'All Class',
        search: {
            status: 'Active',
            group: '',
            sort: { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' },
        },
        sortText: 'Name [ASC]',
        options_sortby: [
            { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'firstName', text: 'Name', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
        ],
        column: [{
            Header: 'Image',
            accessor: 'image',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Image avatar
                    size='mini' verticalAlign='middle'
                    src={cellInfo.original.image}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://app.flightschedulepro.com/images/instructor-default.gif' }}
                />
            ),
            width: 60,
        }, {
            Header: 'Name',
            accessor: 'description',
            sortable: false,
            width: 280,
            Cell: cellInfo => (
                <Wrap>
                    <span className="bold" style={{ cursor: 'pointer' }} onClick={() => this.onClickEdit(cellInfo.original)}>{cellInfo.original.description}</span>
                </Wrap>
            )
        },
        // {
        //     Header: 'Advisor',
        //     accessor: 'advisor',
        //     sortable: false,
        //     Cell: cellInfo => (
        //         <Wrap>
        //             <span>{cellInfo.original.advisor}</span>
        //         </Wrap>
        //     )
        // },
        {
            Header: 'Student type',
            accessor: 'description',
            sortable: false,
            Cell: cellInfo => (
                <Wrap>
                    <span className="" >
                        {
                            cellInfo.original
                                ? cellInfo.original.studentType
                                    ? cellInfo.original.studentType.name
                                    : null
                                : null
                        }
                    </span>
                </Wrap>
            ),
            width: 100
        }, {
            Header: 'Course',
            accessor: 'course',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.course
                                ? <span>{cellInfo.original.course.name} ({cellInfo.original.course.desc})</span>
                                : null
                            : null
                    }
                </span>
            ),
            width: 150,
        }, {
            Header: 'Additional Course',
            accessor: 'additionalCourse',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.additionalCourse && cellInfo.original.additionalCourse.length > 0
                                ? cellInfo.original.additionalCourse.map(add =>
                                    <span>{add.name} ({add.desc}) <br /></span>
                                )
                                : null
                            : null
                    }
                </span>
            ),
            width: 150,
        },
        {
            Header: 'Class',
            accessor: 'class',
            style: { textAlign: 'left' },
            sortable: false,
            Cell: cellInfo => (
                // console.log(cellInfo)
                <span>
                    {
                        cellInfo.original
                            ? cellInfo.original.class
                                ? cellInfo.original.class.name
                                : null
                            : null
                    }
                </span>
            ),
            width: 80,
        }, {
            Header: 'Phone',
            accessor: 'phone',
            sortable: false,
            Cell: cellInfo => (
                <Wrap>
                    <p>{cellInfo.original.phone}</p>
                </Wrap>
            ),
            width: 100
        }, {
            Header: 'Email',
            accessor: 'email',
            sortable: false,
            style: { textAlign: 'center' },
            Cell: cellInfo => (
                <span>
                    {
                        cellInfo.original.email
                            ? <a href={`mailto:${cellInfo.original.email}`}>
                                <Icon link circular color="red" name="mail" />
                            </a>
                            : <Icon circular color='grey' name='mail' />
                    }

                </span>
            ),
            width: 70,
        }, {
            Header: 'Action',
            accessor: 'id',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Wrap>
                    {/* <Icon link circular color='grey' name='calendar alternate' onClick={() => this.onClickWorkShd(cellInfo.original)} /> */}
                    <Icon
                        link
                        name="key"
                        onClick={() => this.onClickResetPassword(cellInfo.original)}
                    />
                    &nbsp; &nbsp;
                    <Icon
                        link
                        // circular color='orange'
                        name='edit'
                        onClick={() => this.onClickEdit(cellInfo.original)}
                    />
                    &nbsp; &nbsp;
                    <Icon
                        link
                        color="red"
                        name="trash alternate"
                        onClick={() => this.onClickDelete(cellInfo.original)}
                    />
                    {/* <Button size='mini' circular color='grey' icon='calendar alternate' onClick={this.onClickWorkShd} /> */}
                    {/* <Button size='mini' circular color='orange'  icon='user' onClick={() => this.onClickProfile(cellInfo.original)} /> */}
                </Wrap>
            ),
            width: 100,
        }
        ],
    }
    // onClickWorkShd = (value) => {
    //     this.setState({ currentData: value, openWorkShd: true })
    // }

    show = () => {
        this.setState({ open: true })
        this.getInstructors('?isActive=true')
    }

    close = () => {
        this.setState({ open: false, openWorkShd: false, importFile: false, dataEdit: null })
    }
    closeError = () => {
        this.setState({ openError: false })
    }
    closeImportFile = () => {
        document.getElementById("myForm").reset();
        this.setState({
            importFile: false,
            select_course: null,
            select_class: null,
            select_company: null,
            select_stdType: null
        })
    }

    onSubmitAddStudent = (data) => {
        this.setState({ loading: true, btnLoading: true })
        // console.log('onSubmitAddStudent', data)
        // console.log(dataPersonnel, dataStudent)

        postStudent(data).then(res => {
            if (res && res.status === 201) {
                // console.log('postPersonnel', res.data)
                let qString = '?isActive=true'
                this.getData(qString)
                this.close()
            } else if (res && res.status === 409) {
                this.setState({ errorMessage: "Username or Email already exists." });
            }
            this.setState({ loading: false, btnLoading: false })
        });
    }
    onSubmitEditStudent = (data) => {
        const { dataEdit } = this.state
        this.setState({ loading: true, btnLoading: true });
        if (data.isActive) data.isActive = 'true'
        else data.isActive = 'false'
        let dataStudent = {
            id: dataEdit.id,
            ...data
        }
        updateStudent(dataStudent).then(res => {
            if (res && res.status === 200) {
                let qString = '?isActive=true'
                this.getData(qString)
                this.close()
            } else if (res && res.status === 409) {
                this.setState({ errorMessage: "Username or Email already exists." });
            }
            this.setState({ loading: false, btnLoading: false })
        });
    }
    // onClickProfile = (data) => {
    //     // console.log(data)
    //     this.props.history.push({
    //         pathname: '/Students/Profile',
    //         state: { id: data.id }
    //     })
    // }
    onClickResetPassword = value => {
        this.setState({
            openConfirmReset: true,
            currentData: value
        });
    }
    resetPassword = () => {
        let { currentData } = this.state;
        this.setState({ loading: true });
        if (currentData && currentData.id)
            resetpassUser(currentData.id).then(res => {
                if (res && res.status === 200) {
                    let qString = '?isActive=true'
                    this.getData(qString)
                    this.setState({ openConfirmReset: false });
                }
                this.setState({ loading: false });
            });
    }
    onClickDelete = value => {
        this.setState({
            openConfirmDelete: true,
            currentData: value
        });
    };
    deleteData = () => {
        let { currentData } = this.state;
        this.setState({ loading: true });
        if (currentData && currentData.id)
            deleteUser(currentData.id).then(res => {
                if (res && res.status === 200) {
                    let qString = '?isActive=true'
                    this.getData(qString)
                    this.setState({ openConfirmDelete: false });
                }
                this.setState({ loading: false });
            });
    };
    onConfirm = (type) => { this.setState({ openConfirm: true, typeConfirm: type }) }
    onDisable = () => {
        let { dataEdit } = this.state;
        this.setState({ loadingUp: true });
        dataEdit.isActive = "false";
        let dataUpdate = {
            id: dataEdit.id,
            ...dataEdit
        }
        updateStudent(dataUpdate).then(res => {
            if (res && res.status === 200) {
                this.setState({ activeUser: false, openConfirm: false });
                let qString = '?isActive=true'
                this.getData(qString)
            }
            this.setState({ loadingUp: false });
        });
    };
    onEnable = () => {
        let { dataEdit } = this.state;
        this.setState({ loadingUp: true });
        dataEdit.isActive = "true";
        let dataUpdate = {
            id: dataEdit.id,
            ...dataEdit
        }
        updateStudent(dataUpdate).then(res => {
            if (res && res.status === 200) {
                this.setState({ activeUser: true, openConfirm: false });
                let qString = '?isActive=true'
                this.getData(qString)
            }
            this.setState({ loadingUp: false });
        });
    };
    handleChangeStatus = (e, data) => {
        let { search, options_class, checked_class } = this.state
        let active, qString

        if (data.value === 'Active')
            active = true
        else active = false

        search['status'] = data.value // "Active/Inactive"
        this.setState({ search })

        qString = '?isActive=' + active

        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (checked_class && checked_class.length != options_class.length)
            checked_class.map(item => {
                qString += `&class[]=${item}`
            })
        this.getData(qString)
    }

    handleChangeSearch = (e) => {
        let { rawData, search, options_class, checked_class } = this.state;
        let active;
        this.setState({ loading: true })
        if (this._timeout) { //if there is already a timeout in process cancel it
            clearTimeout(this._timeout);
        }
        const val = e.target.value;
        const name = e.target.name
        this._timeout = setTimeout(() => {
            this._timeout = null;
            search[name] = val
            let qString = "?name=" + val
            if (search.status === "Active") active = true;
            else active = false;
            qString += "&isActive=" + active;

            if (checked_class && checked_class.length != options_class.length)
                checked_class.map(item => {
                    qString += `&class[]=${item}`
                })

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

            this.getData(qString);
        }, 2000);
    }

    handleChangeSort = (e, data) => {
        let { search, checked_class, options_class } = this.state
        let active, qString

        if (search.status === 'Active')
            active = 'true'
        else active = 'false'

        const result = data.options.find((member) => {
            return member.value === data.value
        })
        search['sort'] = result
        this.setState({ search, sortText: `${result.text} (${result.description})` })

        qString += '?isActive=' + active + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (checked_class && checked_class.length != options_class.length)
            checked_class.map(item => {
                qString += `&class[]=${item}`
            })

        this.getData(qString)
    }

    filterByClass = (data) => {
        // console.log(data)
        let { search, options_class } = this.state
        let active

        if (search.status === 'Active')
            active = 'true'
        else active = 'false'
        let checked_class = []

        let qString = '?isActive=' + active

        if (data && data.length != options_class.length)
            data.map(item => {
                qString += `&class[]=${item}`
                checked_class.push(item)
            })
        else
            data.map(item => {
                checked_class.push(item)
            })

        if (data.length === options_class.length) {
            this.setState({ filterClassByText: 'All Class' })
        }
        else {
            this.setState({ filterClassByText: `Class (${data.length})` })
        }
        this.setState({ checked_class, })
        if (search['name']) {
            qString += '&name=' + search.name
        }
        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        // console.log(qString)
        this.getData(qString)
    }

    getClass = () => {
        getAllClass().then(res => {
            let checked_class = []
            if (res && res.status === 200) {
                let options = []
                res.data.map(item => {
                    options.push({ key: item.id, text: item.name, value: item.id })
                    checked_class.push(item.id)
                })
                this.setState({ options_class: options, rawDataclass: res.data, checked_class })
            }
        })
    }

    getCompany = () => {
        getAllCompany().then(res => {
            if (res && res.status === 200) {
                let options = []
                res.data.map(item => {
                    options.push({ key: item.id, text: item.name, value: item.id })
                })
                this.setState({ options_company: options, rawDataCompany: res.data })
            }
        })
    }

    getAllStudentType = () => {
        getStudentType().then(res => {
            if (res && res.status === 200) {
                let options = []
                res.data.map(item => {
                    options.push({ key: item.id, text: item.name, value: item.id })
                })
                this.setState({ options_studenttype: options, rawDataStudenttype: res.data })
            }
        })
    }

    getOptionCourse = (qString) => {
        getAllCourse(qString).then(res => {
            if (res && res.status === 200) {
                let options = []
                res.data.map(item => {
                    options.push({
                        key: item.id,
                        text: `${item.name} (${item.desc}) ${moment(item.start, 'DD-MM-YYYY').format("DD/MM/YYYY")} - ${moment(item.end, 'DD-MM-YYYY').format("DD/MM/YYYY")}`,
                        value: item.id
                    })
                })
                this.setState({ options_course: options, rawDataCourse: res.data })
            }
        })
    }

    getData = (qString) => {
        this.setState({ loading: true })
        let { per_page, current_page, search } = this.state;
        current_page = current_page + 1;
        qString = qString + "&_perpage=" + per_page + "&page=" + current_page;
        getUserStudent(qString).then(res => {
            // console.log('student', res)
            if (res && res.status === 200) {
                let newdata = []
                res.data.data.map(item => {
                    let description = `${item.studentId || ''} ${item.firstName} ${item.lastName} ${item.customerCode || ''}`
                    newdata.push({
                        id: item.id,
                        image: item.imageURL || [],
                        description: description,
                        email: item.emailAddress,
                        phone: item.phoneNumber,
                        roles: item.roles || [],
                        class: item.class,
                        course: item.course,
                        studentType: item.studentType,
                        additionalCourse: item.additionalCourse || [],
                        advisor: item.advisor,
                        batchCode: item.batchCode
                    })
                })
                // console.log('newdata', newdata)
                this.setState({ data: newdata, rawData: res.data.data, current_page: res.data.current_page - 1, last_page: res.data.last_page })
            }
            this.setState({ loading: false })
        })
    }
    onClickEdit = (data) => {
        // this.getDataStudentById(data.id)
        getStudentById(data.id).then(res => {
            if (res && res.status === 200) {
                // console.log(res.data)
                let dataSetEdit = {
                    id: data.id,
                    customerCode: res.data.customerCode,
                    studentId: res.data.studentId,
                    titleEng: res.data.titleEng,
                    titleTh: res.data.titleTh,
                    firstNameEng: res.data.firstNameEng,
                    lastNameEng: res.data.lastNameEng,
                    firstNameTh: res.data.firstNameTh,
                    lastNameTh: res.data.lastNameTh,
                    foreigner: res.data.foreigner,
                    foreignerCountry: res.data.foreignerCountry,
                    email: res.data.emailAddress,
                    personnel_email: res.data.personnel_email,
                    phone: res.data.phoneNumber,
                    class: res.data.class && res.data.class.id,
                    studentType: res.data.studentType && res.data.studentType.id,
                    company: res.data.company && res.data.company.id,
                    courseID: res.data.course && res.data.course.id,
                    course: res.data.course,
                    dateOfBirth: res.data.birthDate,
                    gender: res.data.gender,
                    username: res.data.username,
                    isActive: res.data.isActive,
                    nickName: res.data.nickName,
                    advisor: res.data.advisor && res.data.advisor.id,
                    batchCode: res.data.batchCode
                }
                if (dataSetEdit.isActive === "true" || dataSetEdit.isActive === true) this.setState({ activeUser: true });
                else this.setState({ activeUser: false });

                this.setState({ open: true, dataEdit: dataSetEdit })
                this.getInstructors('?isActive=true')
            }
        })
    }

    dowloadTemplate() {
        var link = document.createElement("a");
        link.href = './storage/template/data_student_template.xlsx';
        link.download = 'data_student_template.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    onChangeFile = (e, data) => {
        if (e.target.files[0] && e.target.files[0].size > 5120000)
            this.setState({ FileError: 'The fils is too large and cannot be uploaded. (Limit size : 5MB)' })
        else this.setState({ FileError: false })
        this.setState({ [data.name]: e.target.files[0] })
    }
    onChange = (e, data) => {
        // console.log(e, data)
        this.setState({ [data.name]: data.value })

    }
    openImportExcel = () => this.setState({ importFile: true })

    importData = () => {
        const { excel, FileError, select_class, select_company, select_course, select_stdType } = this.state
        // console.log('importData', select_class, select_company, select_course, select_stdType)
        this.setState({ activeLoader: true })
        if (FileError) {
            this.setState({ activeLoader: false })
            return;
        }
        // if (!select_course) {
        //     this.setState({ errorCourse: true, activeLoader: false })
        //     return;
        // } else {
        //     this.setState({ errorCourse: false })
        // }

        if (excel) {
            var formData = new FormData();
            formData.append('classId', select_class)
            formData.append('companyId', select_company)
            formData.append('courseId', select_course)
            formData.append('stdType', select_stdType)

            formData.append('excel', excel)
            // console.log(...formData)
            importStudent(formData).then(res => {
                if (res && res.status === 200) {
                    // console.log('importStudent', res.data)
                    if (res.data.error.errors > 0) {
                        this.setState({
                            openError: true,
                            activeLoader: false,
                            errorsRow: res.data.error.errors,
                            errorInfo: Object.values(res.data.error.errorsmessage).toString()
                        })
                    } else {
                        this.setState({
                            activeLoader: false, errorsRow: 0,
                            errorInfo: null
                        })
                        let qString = '?isActive=true'
                        this.getData(qString)
                        this.closeImportFile()
                    }
                    // let qString = '?isActive=true'
                    // this.getData(qString)
                    // this.closeImportFile()
                }
            })
        }
    }
    getInstructors = (qString) => {
        getAllInstructors(qString).then(res => {
            if (res && res.status === 200) {
                let options = []
                res.data.map(item => {
                    options.push({
                        key: item.id,
                        text: `${item.firstName} ${item.lastName} (${item.nickName || "-"})`,
                        value: item.id
                    })
                })
                this.setState({ options_instructors: options, rawDataInstructors: res.data })
            }
        })
    }

    componentDidMount() {
        let qString = '?isActive=true'
        this.getData(qString)
        this.getClass()
        this.getCompany()
        this.getAllStudentType()
        let qString2 = '?additionalCourse=false&active=true'
        this.getOptionCourse(qString2)

    }

    render() {
        const state = this.state
        return (
            <Segment className="contentSegment" textAlign='left'>
                <HeaderWithMenu name='User (Students)' >
                    <Dropdown icon={<Icon circular name='align justify' color="black" />} direction='left'>
                        <Dropdown.Menu>
                            <Dropdown.Item icon='external share' text='Import Data' onClick={this.openImportExcel} />
                            <Dropdown.Item icon='download' text='Dowload template' onClick={this.dowloadTemplate} />
                        </Dropdown.Menu>
                    </Dropdown>
                    <MainButton
                        size='small'
                        onClick={this.show}
                        text='Add User Student' />
                </HeaderWithMenu>
                <Confirmmodal
                    loading={state.loading}
                    open={state.openConfirmDelete}
                    title="User"
                    onClose={() => this.setState({ openConfirmDelete: false })}
                    onComfirm={this.deleteData}
                />
                <Confirmmodal
                    loading={state.loading}
                    open={state.openConfirmReset}
                    actionText='Reset password'
                    title="User"
                    onClose={() => this.setState({ openConfirmReset: false })}
                    onComfirm={this.resetPassword}
                />
                <Form>
                    <Form.Group>
                        <Form.Input
                            width='3'
                            icon='search'
                            size='small'
                            placeholder='Search'
                            name='name'
                            onChange={this.handleChangeSearch} />
                        <Form.Field width='3'>
                            <DropdownCheckbox style={{ textAlign: 'left' }} checked={state.checked_class} options={state.options_class} onClick={this.filterByClass}>
                                <Button fluid size='small' icon basic labelPosition='right' style={{ textAlign: 'left' }}>
                                    {state.filterClassByText}
                                    <Icon name='angle down' />
                                </Button>
                            </DropdownCheckbox>
                        </Form.Field>
                        <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Sort by : <b>{state.sortText}</b>
                            </Button>}
                            options={state.options_sortby}
                            defaultValue={state.search.sort}
                            selectOnBlur={false}
                            onChange={this.handleChangeSort}
                        />
                        <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Status : <b>{state.search.status}</b>
                            </Button>}
                            options={config.options_active}
                            defaultValue={state.search.status}
                            selectOnBlur={false}
                            onChange={this.handleChangeStatus}
                        />
                        {/* <Form.Field width='9' style={{ textAlign: 'right' }}>
                            <MainButton
                                size='small'
                                onClick={this.show}
                                text='Add Student' />
                        </Form.Field> */}

                    </Form.Group>
                </Form>
                <ModalForm
                    icon='exclamation circle'
                    content='นำเข้าข้อมูลไม่สำเร็จ'
                    size='large'
                    open={state.openError}
                    close={this.closeError}>
                    <Wrap>
                        {state.errorsRow && <h3>จำนวนข้อผิดพลาดทั้งหมด {state.errorsRow} จุด</h3>}
                        <br />
                        {state.errorInfo && <p>{state.errorInfo}</p>}
                    </Wrap>
                </ModalForm>
                <ModalForm
                    icon='add user'
                    content={state.dataEdit ? 'Edit User Student' : 'Add User Student'}
                    open={state.open}
                    close={this.close}  >
                    <FormStudent
                        isAdd={true}
                        isEdit={state.dataEdit ? true : false}
                        loading={state.btnLoading}
                        onSubmit={state.dataEdit ? this.onSubmitEditStudent : this.onSubmitAddStudent}
                        onCancel={this.close}
                        onDisable={() => this.onConfirm('Disable')}
                        onEnable={() => this.onConfirm('Enable')}
                        activeUser={state.activeUser}
                        errorMessage={state.errorMessage}
                        loadingUp={state.loadingUp}
                        haveCourse={state.dataEdit ? state.dataEdit.course ? true : false : false}
                        initialValues={state.dataEdit ? state.dataEdit : null}
                        options_class={state.options_class}
                        options_company={state.options_company}
                        options_course={state.options_course}
                        options_studenttype={state.options_studenttype}
                        options_instructors={state.options_instructors}
                    />
                </ModalForm>
                <ModalForm
                    icon='file excel'
                    content='Import Student data'
                    open={state.importFile}
                    close={this.closeImportFile}  >
                    <Wrap>
                        <Form id='myForm' onSubmit={this.importData}>
                            <Form.Select label='Course' search clearable
                                name="select_course"
                                onChange={(e, value) => this.onChange(e, value)}
                                options={state.options_course}>
                            </Form.Select>
                            <Form.Group widths='equal'>
                                <Form.Select label='Class' search clearable
                                    name="select_class"
                                    onChange={(e, value) => this.onChange(e, value)}
                                    options={state.options_class}></Form.Select>
                                <Form.Select label='Company' search clearable
                                    name="select_company"
                                    onChange={(e, value) => this.onChange(e, value)}
                                    options={state.options_company}></Form.Select>
                            </Form.Group>
                            <Form.Select label='Student type' search clearable
                                name="select_stdType"
                                onChange={(e, value) => this.onChange(e, value)}
                                options={state.options_studenttype}>
                            </Form.Select>
                            <Form.Input
                                label="Excel file"
                                type="file"
                                name="excel"
                                onChange={this.onChangeFile}
                            />
                            <p style={{ color: 'red', textAlign: 'left' }}>{state.FileError ? state.FileError : ''}</p>
                            <br />
                            <Button
                                floated="left"
                                type="submit"
                                content="Import"
                                color="green"
                                loading={state.activeLoader}
                            />
                            <Button
                                floated="right"
                                type="button"
                                content="Cancel"
                                color="green"
                                basic
                                onClick={this.closeImportFile}
                            />
                        </Form>
                    </Wrap>
                </ModalForm>
                <TableList
                    loading={state.loading}
                    data={state.data}
                    columns={state.column}
                    pageSize={state.per_page}
                    page={state.current_page}
                    pages={state.last_page}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                />
                <Confirmmodal
                    loading={state.loading}
                    open={state.openConfirm}
                    title={state.typeConfirm}
                    onClose={() => this.setState({ openConfirm: false })}
                    onComfirm={state.typeConfirm === 'Disable' ? this.onDisable : this.onEnable}
                />
            </Segment>
        )
    }

    onPageSizeChange = (pageSize, pageIndex) => {
        let { search, options_class, checked_class } = this.state
        let active = '';
        this.setState({
            per_page: pageSize, current_page: pageIndex
        }, () => {
            if (search.status === 'Active')
                active = 'true'
            else active = 'false'
            let qString = '?isActive=' + active

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
            if (search['name']) {
                qString += '&name=' + search.name
            }
            if (checked_class && checked_class.length != options_class.length)
                checked_class.map(item => {
                    qString += `&class[]=${item}`
                })
            this.getData(qString)
        });
    };

    onPageChange = pageIndex => {
        let { search, options_class, checked_class } = this.state
        let active = '';
        this.setState({
            current_page: pageIndex
        }, () => {

            if (search.status === 'Active')
                active = 'true'
            else active = 'false'
            let qString = '?isActive=' + active

            if (search['sort'])
                qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description
            if (search['name']) {
                qString += '&name=' + search.name
            }
            if (checked_class && checked_class.length != options_class.length)
                checked_class.map(item => {
                    qString += `&class[]=${item}`
                })
            this.getData(qString)
        });

    };
}

export default withRouter(userStudent)
// export default Student = reduxForm({
//     // a unique name for the form
//     form: 'Student',
//     validate,
//     enableReinitialize: true
// })(Student)
