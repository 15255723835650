import axios from "axios";
import * as config from "../../config";

export function getStaffListBySearch(search) {
  return axios
    .get(config.urlAPI + "/staffs/perpage" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function updateStaff(data) {
  return axios
    // .put(config.urlAPI + "/staff/" + data.id, data, config.headerCallAPI)
    // ลอง user/update/id
    .put(config.urlAPI + "/staff/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}


export function getStaffById(id) {
  return axios
    .get(config.urlAPI + "/staff/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getAllNotes(id) {
  return axios
    .get(config.urlAPI + "/staffnote/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postNoteStaff(id, data) {
  return axios
    .post(
      config.urlAPI + "/staffnote/" + id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteNoteStaff(id) {
  return axios
    .delete(
      config.urlAPI + "/staffnote/" + id,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function uploadImageProfile(id, data) {
  return axios
    .post(
      config.urlAPI + "/user/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteImageProfile(id) {
  return axios
    .delete(
      config.urlAPI + "/user/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
