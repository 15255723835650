import axios from "axios";
import * as config from "../../config";

export function exportAttendance(id, qSring) {
  return axios
    .get(config.urlAPI + "/attendance/export/" + id + qSring, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getSnagById(id) {
  return axios
    .get(
      config.urlAPI + "/flightTraningRecord/snag/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getSnagPassedById(id) {
  return axios
    .get(
      config.urlAPI + "/flightTraningRecord/passedsnag/" + id,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAttendanceById(id) {
  return axios
    .get(config.urlAPI + "/attendance/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentListBySearch(search) {
  return (
    axios
      // .get(config.urlAPI + "/studentslist" + search, config.headerCallAPI)
      .get(config.urlAPI + "/students/perpage" + search, config.headerCallAPI)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      })
  );
}

export function postStudent(data) {
  return axios
    .post(config.urlAPI + "/student", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postNoteStudent(id, data) {
  return axios
    .post(config.urlAPI + "/studentnote/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteNoteStudent(id) {
  return axios
    .delete(config.urlAPI + "/studentnote/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateStudent(data) {
  return axios
    .put(config.urlAPI + "/student/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getStudentById(id) {
  return axios
    .get(config.urlAPI + "/student/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudentType() {
  return axios
    .get(config.urlAPI + "/personnel/studenttype", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAllCourse(search) {
  return axios
    .get(config.urlAPI + "/course" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getCourseById(search) {
  return axios
    .get(config.urlAPI + "/course" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAllClass() {
  return axios
    .get(config.urlAPI + "/personnel/studentclass", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAllCompany() {
  return axios
    .get(config.urlAPI + "/personnel/company", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAllHospital() {
  return axios
    .get(config.urlAPI + "/personnel/hospital", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAllNotes(id) {
  return axios
    .get(config.urlAPI + "/studentnote/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAllInstructors(search) {
  return axios
    .get(config.urlAPI + "/instructorslist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getReservationById(search) {
  return axios
    .get(
      config.urlAPI + "/reservationAttendance" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getLogbookByReservation(id) {
  return axios
    .get(config.urlAPI + "/logbookSP")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function uploadImageProfile(id, data) {
  return axios
    .post(config.urlAPI + "/user/" + id + "/upload", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageProfile(id) {
  return axios
    .delete(
      config.urlAPI + "/user/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function importStudent(data) {
  return axios
    .post(config.urlAPI + "/student/import", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
