import React from "react";
import { Form, Label } from "semantic-ui-react";
import Wrap from "./wrap";

class renderField extends React.Component {
  state = {
    inputValue: this.props.input,
    time: "00:00",
    hh: 0,
    mm: 0
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.input !== this.state.inputValue) {
      if (nextProps.input.value) {
        let time = nextProps.input.value.split(":");
        this.setState({
          time,
          hh: parseInt(time[0]),
          mm: parseInt(time[1])
        });
      }
    }
  }

  onChangeTime = (e, data) => {
    if (data.name === "mm") {
      if (data.value > 59 || data.value.length > 2) {
        this.setState({ mm: 0, time: this.state.hh + ":" + data.value });
        this.props.input.onChange(this.state.hh + ":" + data.value);
      } else {
        this.setState({
          mm: data.value,
          time: this.state.hh + ":" + data.value
        });
        this.props.input.onChange(this.state.hh + ":" + data.value);
      }
    } else {
      if (data.value.length > 2) {
        this.setState({ hh: 0, time: data.value + ":" + this.state.mm });
        this.props.input.onChange(data.value + ":" + this.state.mm);
      } else {
        this.setState({
          hh: data.value,
          time: data.value + ":" + this.state.mm
        });
        this.props.input.onChange(data.value + ":" + this.state.mm);
      }
    }
  };

  render() {
    const state = this.state;
    const {
      label,

      width,
      required,
      readOnly,
      meta: { touched, error, warning }
    } = this.props;
    return (
      <Wrap>
        <Form.Field style={{ marginBottom: "0px" }}>
          <label style={{ textAlign: "left" }}>
            {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
        </Form.Field>
        <Form.Group unstackable>
          <Form.Field style={{ marginBottom: "0px" }} width={width || 4}>
            <Form.Input
              placeholder="00"
              type="number"
              min="0"
              name="hh"
              value={state.hh}
              readOnly={readOnly}
              onChange={this.onChangeTime}
            />
          </Form.Field>
          <label style={{ textAlign: "left", paddingTop: "6px" }}>:</label>
          <Form.Field inline style={{ marginBottom: "0px" }} width={width || 3}>
            <Form.Input
              placeholder="00"
              type="number"
              min="0"
              max="59"
              name="mm"
              pattern="[0-9]{2}"
              value={state.mm}
              readOnly={readOnly}
              onChange={this.onChangeTime}
            />
          </Form.Field>
          <Form.Field
            inline
            style={{ marginBottom: "0px", paddingLeft: "0px" }}
            width="2"
          >
            <label style={{ textAlign: "left", paddingTop: "6px" }}> HR</label>
          </Form.Field>
        </Form.Group>
      </Wrap>
    );
  }
}

export default renderField;
