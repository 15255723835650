import React, { Component } from "react";
import { Form, Button, List, Icon } from "semantic-ui-react";
import { Field, reduxForm, FieldArray } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = true;
  if (!values.type) errors.name = true;
  return errors;
};

const renderGroup = ({ fields }) => {
  // if (fields && fields.length === 0) fieldxs.push({});
  return (
    <ul>
      {fields.map((member, index) => (
        <li>
          <Form.Group>
            <Field
              name={`${member}.name`}
              type="text"
              width="15"
              component={renderField}
            />
            <Form.Field>
              <Icon
                link
                size="small"
                color="red"
                name="trash alternate"
                onClick={() => fields.remove(index)}
              />
            </Form.Field>
          </Form.Group>
        </li>
      ))}
      <Button
        color="blue"
        size="small"
        compact
        type="button"
        onClick={() => fields.push({})}
        content="Add sub item"
        floated="right"
      />
    </ul>
  );
};

class FormLesson extends Component {
  render() {
    const { handleSubmit, pristine, submitting, loading } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Field
          name="type"
          component={renderSelectField}
          label="Logbook Type"
          options={[
            {
              key: 1,
              id: 1,
              title: "Logbook IP",
              text: "Logbook IP",
              value: "IP"
            },
            {
              key: 2,
              id: 2,
              title: "Logbook SP",
              text: "Logbook SP",
              value: "SP"
            }
          ]}
          required
        />
        <Field
          name="name"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Logbook name"
        />
        <FieldArray name="group" component={renderGroup} />
        <br />
        <br />
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

export default FormLesson = reduxForm({
  // a unique name for the form
  form: "logbook",
  validate,
  enableReinitialize: true
})(FormLesson);
