import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Breadcrumb,
  Modal,
  Dropdown,
  Button,
  Icon,
  Grid,
  Card,
  Statistic,
  Label,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import TableList from "../../components/table";
import moment from "moment";
import {
  getCourseMaster,
  genSummary,
  genSummaryByRegister,
  genSummaryByGraduate,
  genSummaryByCompany,
  genSummaryByGender,
  genSummaryByGenderAndStatus,
  genSummaryByStdType,
  genSummaryByClass,
  genSummaryByCourseRegister,
} from "./service";
import Wrap from "../../components/wrap";
import StatisticWithLabel from "../../components/statisticWithLabel";
import { HorizontalBar, Line, Bar, Doughnut } from "react-chartjs-2";

const data = {
  labels: [],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const data2 = {
  labels: ["Male", "Female"],
  datasets: [
    {
      label: "My First dataset",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [65, 19],
    },
  ],
};

class StudentSummary extends Component {
  state = {
    dataByRegister: {
      labels: [],
      datasets: [
        {
          label: "Start",
          backgroundColor: "rgba(242,113,28)",
          borderColor: "rgba(242,113,28)",
          borderWidth: 2,
          fill: false,
          data: [],
        },
      ],
    },
    dataByGraduate: {
      labels: [],
      datasets: [
        {
          label: "End",
          fill: false,
          borderWidth: 2,
          backgroundColor: "rgba(242,113,28)",
          borderColor: "rgba(242,113,28)",
          data: [],
        },
      ],
    },
    dataByCompany: {
      labels: [],
      datasets: [
        {
          label: "สังกัดเดิม",
          backgroundColor: "rgba(217,101,25)",
          borderColor: "rgba(217,101,25)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(217,101,25,0.4)",
          hoverBorderColor: "rgba(217,101,25,0.1)",
          hoverBorderWidth: 1,
          data: [],
        },
      ],
    },
    dataByGender: {
      labels: [],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: [],
        },
      ],
      text: "",
    },
    dataByGenderAndStatusR: {
      labels: [],
      datasets: [
        {
          label: "All Student CPL Register",
          backgroundColor: "rgba(217,101,25)",
          borderColor: "rgba(217,101,25)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(217,101,25,0.4)",
          hoverBorderColor: "rgba(217,101,25,0.1)",
          hoverBorderWidth: 1,
          data: [],
        },
      ],
    },
    dataByGenderAndStatusG: {
      labels: [],
      datasets: [
        {
          label: "All Student CPL Graduate",
          backgroundColor: "rgba(217,101,25)",
          borderColor: "rgba(217,101,25)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(217,101,25,0.4)",
          hoverBorderColor: "rgba(217,101,25,0.1)",
          hoverBorderWidth: 1,
          data: [],
        },
      ],
    },
    dataByStdType: {
      labels: [],
      datasets: [
        {
          label: "Type of all Students CPL",
          backgroundColor: "rgba(220,116,47)",
          borderColor: "rgba(220,116,47)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(220,116,47,0.4)",
          hoverBorderColor: "rgba(220,116,47,0.4)",
          hoverBorderWidth: 1,
          data: [],
        },
      ],
    },
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    options_worktime: [
      { key: "r01", value: "IP Fulltime", text: "IP Fulltime" },
      { key: "r02", value: "IP Parttime", text: "IP Parttime" },
    ],
    wrktime: [],
    dataAllClass: [],
    columnsAllClass: [
      {
        Header: "Class",
        accessor: "class",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.class}</span>,
      },
      {
        Header: "สังกัดเดิม",
        accessor: "company",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.company}</span>
        ),
      },
      {
        Header: "START",
        accessor: "regisyear",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.regisyear}</span>
        ),
      },
      {
        Header: "Class",
        accessor: "total",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.total}</span>,
      },
    ],
    columnsCourseRegis: [
      {
        Header: "Start",
        accessor: "year",
        sortable: false,
        style: { textAlign: "center" },
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { wrktime } = this.state;
    let qString = "?";
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "worktime[]=" + wrk + "&";
      });
    }
    // exportProficiencyCheck(qString).then((res) => {
    //   if (res && res.status === 200) {
    //     this.setState({ loadPopup: false });
    //     var newBlob = new Blob([res.data]);
    //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //       var newBlobIE = new Blob([res.data], {
    //         type:
    //           "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //       });
    //       return navigator.msSaveOrOpenBlob(
    //         newBlobIE,
    //         "ProficiencyCheck_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
    //       );
    //     }
    //     const data = window.URL.createObjectURL(newBlob);
    //     var link = document.createElement("a");
    //     link.href = data;
    //     this.setState({ link: link.href });
    //     link.download =
    //       "ProficiencyCheck_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     setTimeout(function () {
    //       // For Firefox it is necessary to delay revoking the ObjectURL

    //       window.URL.revokeObjectURL(data);
    //     }, 100);
    //   } else {
    //     this.setState({
    //       errorMessage: "The request is taking too long. Please try again",
    //     });
    //   }
    // });
  };
  onClickFilter = () => {
    const { wrktime } = this.state;
    let qString = "?";
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "worktime[]=" + wrk + "&";
      });
    }
    this.getData(qString);
  };
  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = () => {
    this.setState({ dataLoading: true });
    let { columnsCourseRegis } = this.state;

    getCourseMaster().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          columnsCourseRegis.push({
            Header: item.name,
            accessor: [item.id].toString(),
            sortable: false,
            style: { textAlign: "center" },
            width: 100,
          });
        });
        columnsCourseRegis.push({
          Header: "Grand total",
          accessor: "total",
          sortable: false,
          style: { textAlign: "center" },
        });
        this.setState({ columnsCourseRegis });
      }
    });
    genSummaryByClass().then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataAllClass: res.data });
      }
    });

    genSummaryByCourseRegister().then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataByCourseRegister: res.data });
      }
    });
    genSummary().then((res) => {
      if (res && res.status === 200) {
        this.setState({ dataSummary: res.data });
      }
    });
  };
  getDataByCourse1 = (qString) => {
    genSummaryByRegister(qString).then((res) => {
      if (res && res.status === 200) {
        let data = {
          labels: res.data.labels,
          datasets: [
            {
              label: "Start",
              backgroundColor: "rgba(242,113,28)",
              borderColor: "rgba(242,113,28)",
              borderWidth: 2,
              fill: false,
              data: res.data.values,
            },
          ],
        };
        this.setState({ dataByRegister: data });
      }
    });
    genSummaryByGraduate(qString).then((res) => {
      if (res && res.status === 200) {
        let data = {
          labels: res.data.labels,
          datasets: [
            {
              label: "End",
              fill: false,
              borderWidth: 2,
              backgroundColor: "rgba(242,113,28)",
              borderColor: "rgba(242,113,28)",
              data: res.data.values,
            },
          ],
        };
        this.setState({ dataByGraduate: data });
      }
    });
    genSummaryByCompany(qString).then((res) => {
      if (res && res.status === 200) {
        let data = {
          labels: res.data.labels,
          datasets: [
            {
              label: "สังกัดเดิม",
              backgroundColor: "rgba(217,101,25)",
              borderColor: "rgba(217,101,25)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(217,101,25,0.4)",
              hoverBorderColor: "rgba(217,101,25,0.1)",
              hoverBorderWidth: 1,
              data: res.data.values,
            },
          ],
        };
        this.setState({ dataByCompany: data });
      }
    });
    genSummaryByGender(qString).then((res) => {
      if (res && res.status === 200) {
        let data = {
          labels: res.data.labels,
          datasets: [
            {
              label: "My First dataset",
              backgroundColor: ["#f2711c", "#f59b60"],
              borderColor: ["#f2711c", "#f59b60"],
              borderWidth: 0,
              hoverBackgroundColor: [
                "rgba(242,113,28,0.4)",
                "rgba(245,155,96,0.4)",
              ],
              hoverBorderWidth: 0,
              data: res.data.values,
            },
          ],
          text: "",
        };
        this.setState({ dataByGender: data });
      }
    });
    genSummaryByGenderAndStatus(qString).then((res) => {
      if (res && res.status === 200) {
        let dataRegis = {
          labels: res.data.registered.labels,
          datasets: [
            {
              label: "All Student CPL Register",
              backgroundColor: "rgba(217,101,25)",
              borderColor: "rgba(217,101,25)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(217,101,25,0.4)",
              hoverBorderColor: "rgba(217,101,25,0.1)",
              hoverBorderWidth: 1,
              data: res.data.registered.values,
            },
          ],
        };
        let dataGrad = {
          labels: res.data.graduated.labels,
          datasets: [
            {
              label: "All Student CPL Graduate",
              backgroundColor: "rgba(217,101,25)",
              borderColor: "rgba(217,101,25)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(217,101,25,0.4)",
              hoverBorderColor: "rgba(217,101,25,0.1)",
              hoverBorderWidth: 1,
              data: res.data.graduated.values,
            },
          ],
        };
        this.setState({
          dataByGenderAndStatusR: dataRegis,
          dataByGenderAndStatusG: dataGrad,
        });
      }
    });
    genSummaryByStdType(qString).then((res) => {
      if (res && res.status === 200) {
        let data = {
          labels: res.data.labels,
          datasets: [
            {
              label: "Type of all Students CPL",
              backgroundColor: "rgba(220,116,47)",
              borderColor: "rgba(220,116,47)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(220,116,47,0.4)",
              hoverBorderColor: "rgba(220,116,47,0.4)",
              hoverBorderWidth: 1,
              data: res.data.values,
            },
          ],
        };
        this.setState({ dataByStdType: data });
      }
    });
  };
  componentDidMount() {
    this.getData();
    this.getDataByCourse1("?course=1");
  }
  render() {
    let state = this.state;
    console.log("state summary", state);

    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Student Data Summary">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Student Data Summary</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Grid columns="equal" textAlign="center">
          <Grid.Row>
            <Grid.Column>
              <StatisticWithLabel
                label="All Course Entry"
                value={
                  state.dataSummary && state.dataSummary.total
                    ? state.dataSummary.total
                    : "0"
                }
                // {state.dataSummary}
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="All Course Graduated"
                value={
                  state.dataSummary && state.dataSummary.graduated
                    ? state.dataSummary.graduated
                    : "0"
                }
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="All Course Active Training"
                value={
                  state.dataSummary && state.dataSummary.active
                    ? state.dataSummary.active
                    : "0"
                }
              />
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column>
              <StatisticWithLabel label="All Student CPL Entry" value="303" />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="All Student CPL Graduated"
                value="1,631"
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel label="CPL Entry in 2563" value="16" />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel label="CPL From Last Year" value="235" />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel label="CPL All Student Active" value="251" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <StatisticWithLabel
                label="All Student CPL on pilot duty"
                value="1,110"
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="All Student IND CPL Graduated"
                value="544"
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="All Student IND CPL on pilot duty"
                value="241"
              />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel label="CPL University Gruated" value="345" />
            </Grid.Column>
            <Grid.Column>
              <StatisticWithLabel
                label="CPL University on pilot duty"
                value="130"
              />
            </Grid.Column>
          </Grid.Row>
        */}
        </Grid>
        <Card.Group
          doubling
          itemsPerRow="2"
          style={{ padding: 10, textAlign: "center" }}
        >
          <Card fluid>
            <Card.Content>
              <Card.Header>All Student work in Airline</Card.Header>
              <HorizontalBar data={data} />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>All Student position</Card.Header>
              <Bar data={data} />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Student Input</Card.Header>
              <Line
                data={state.dataByRegister}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                        },
                      },
                    ],
                  },
                }}
              />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Student Output</Card.Header>
              <Line
                data={state.dataByGraduate}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 100,
                        },
                      },
                    ],
                  },
                }}
              />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Type Of Students</Card.Header>
              <Bar
                data={state.dataByCompany}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 200,
                        },
                      },
                    ],
                  },
                }}
              />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Grid columns={3} style={{ height: "250px", paddingTop: "20px" }}>
                <Grid.Column>
                  <Bar
                    data={state.dataByGenderAndStatusR}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              stepSize: 250,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Bar
                    data={state.dataByGenderAndStatusG}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              stepSize: 200,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Doughnut
                    data={state.dataByGender}
                    options={{ maintainAspectRatio: false }}
                  />
                </Grid.Column>
              </Grid>

              {/**/}
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Type Of Students</Card.Header>
              <Bar
                data={state.dataByCompany}
                options={{
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: 200,
                        },
                      },
                    ],
                  },
                }}
              />
            </Card.Content>
          </Card>
          <Card fluid>
            <Card.Content>
              <Card.Header>Student All Batch</Card.Header>
              <TableList
                defaultPageSize={5}
                loading={state.loading}
                data={state.dataAllClass}
                columns={state.columnsAllClass}
              />
            </Card.Content>
          </Card>
        </Card.Group>
        <Grid>
          <Grid.Row>
            <Grid.Column style={{ padding: 10, textAlign: "center" }}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    All Student and All Course Register Status
                  </Card.Header>
                  <TableList
                    loading={state.loading}
                    data={state.dataByCourseRegister}
                    columns={state.columnsCourseRegis}
                  />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default withRouter(StudentSummary);
