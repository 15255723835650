import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Image, Menu, Icon, Accordion, Grid } from "semantic-ui-react";
const isActive = (path, match, location) =>
  !!(match || path === location.pathname || location.pathname.includes(path));
class MenuExpand extends Component {
  state = {
    activeIndex: 2,
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    bac_role: JSON.parse(sessionStorage.getItem("bac_roles")),
  };
  componentDidMount() {
    const { pathname } = this.props.history.location;
    if (
      pathname.indexOf("Instructors") > 0 ||
      pathname.indexOf("Students") > 0 ||
      pathname.indexOf("Staffs") > 0
    ) {
      this.setState({ activeIndex: 0 });
    } else if (
      pathname === "Ebook" ||
      pathname.indexOf("Portfolio") > 0 ||
      pathname.indexOf("AdminTrainRecord") > 0 ||
      pathname.indexOf("InstructorTrainRecord") > 0 ||
      pathname.indexOf("TrainRecord") > 0 ||
      pathname.indexOf("AdminAttendance") > 0 ||
      pathname.indexOf("InstructorAttendance") > 0 ||
      pathname.indexOf("Registration") > 0 ||
      pathname.indexOf("Offense") > 0
    ) {
      this.setState({ activeIndex: 1 });
    } else if (
      pathname.indexOf("Schedule") > 0 ||
      pathname.indexOf("Track") > 0 ||
      pathname.indexOf("FlightTable") > 0 ||
      pathname.indexOf("FlightHourRecord") > 0
    ) {
      this.setState({ activeIndex: 2 });
    } else if (
      pathname.indexOf("Course") > 0 ||
      pathname.indexOf("Aircrafts") > 0 ||
      pathname.indexOf("Room") > 0 ||
      pathname.indexOf("FlightHourRecord") > 0
    ) {
      this.setState({ activeIndex: 4 });
    } else if (pathname.indexOf("Dashboard") > 0) {
      this.setState({ activeIndex: 5 });
    } else if (pathname.indexOf("Report") > 0) {
      this.setState({ activeIndex: 6 });
    } else if (
      pathname.indexOf("qr") > 0 ||
      pathname.indexOf("file") > 0 ||
      pathname.indexOf("Cessna") > 0 ||
      pathname.indexOf("Diamond") > 0
    ) {
      this.setState({ activeIndex: 8 });
    } else if (
      pathname.indexOf("Setting") > 0 ||
      pathname.indexOf("User") > 0 ||
      pathname.indexOf("SettingUserStudent") > 0
    ) {
      this.setState({ activeIndex: 7 });
    } else this.setState({ activeIndex: 2 });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  checkPermissionGroup = (group) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission && bac_permission.find(
      (item) => item.group === group
    );
    if (userpermissionGroup) return true;
    else return false;
  };

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission && bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission && permission.find((item) => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  render() {
    const { activeIndex } = this.state;
    const state = this.state;
    // console.log(state.bac_role,state.bacUserData)
    return (
      <div id="myMenu">
        <br />
        {state.bacUserData &&
          <Grid style={{ margin: 0 }}>

            <Grid.Column textAlign="center">
              <Image
                centered
                src={
                  state.bacUserData.imageUrl ||
                  "https://react.semantic-ui.com/images/avatar/large/matthew.png"
                }
                size="tiny"
                circular
              />
              <h4
                style={{ marginTop: "10px", marginBottom: "10px" }}
                className="bold"
              >
                {state.bacUserData.firstName} {state.bacUserData.lastName}{" "}
              </h4>
              <h5
                style={{ marginTop: "0px", marginBottom: "0px" }}
                className="bold"
              >
                {state.bac_role && state.bac_role.toString()}
              </h5>
            </Grid.Column>
          </Grid>
        }
        <Menu
          secondary
          fluid
          vertical
          style={{ textAlign: "left", fontSize: "99%" }}
        >
          <Menu.Item
            className="subMenu"
            as={NavLink}
            to="/Home"
            activeClassName="active"
            exact
            isActive={isActive.bind(this, "/Home")}
          >
            Home
          </Menu.Item>
          {state.bac_role && state.bac_role.toString() === "Student" && (
            <Menu.Item
              className="subMenu"
              as={NavLink}
              // to="/MyPortfolio"
              to={{
                pathname: "/StudentProfile",
                state: { id: state.bacUserData.id, status: "self" },
              }}
              activeClassName="active"
              exact
              isActive={isActive.bind(this, "/StudentProfile")}
            >
              Profile
            </Menu.Item>
          )}
          {this.checkPermissionGroup("Personnel") && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 0 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Personnel <Icon name="user" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                {this.checkPermissionMenu("Personnel", [
                  "manage-instructor",
                  "view-instructor",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Instructors"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Instructors")}
                    >
                      Instructors
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Personnel", [
                  "manage-student",
                  "view-student",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Students"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Students")}
                    >
                      Student
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Personnel", [
                  "manage-staff",
                  "view-staff",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Staffs"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Staffs")}
                    >
                      Staff
                    </Menu.Item>
                  )}
                {/* <Menu.Item className='subMenu' as={NavLink} to='/Staffs' activeClassName="active" exact isActive={isActive.bind(this, '/Staffs')}>
                      Staff
                </Menu.Item> */}
              </Accordion.Content>
            </Accordion>
          )}
          {(this.checkPermissionGroup("Academic") || this.checkPermissionGroup("Management")) && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 1 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Academic <Icon name="book" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 1}>
                {this.checkPermissionMenu("Academic", [
                  "manage-registration",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Registration"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Registration")}
                    >
                      Registration
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Academic", [
                  "manage-ebook",
                  "view-ebook",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Ebook"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Ebook")}
                    >
                      Ebook
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Academic", [
                  "manage-portfolio",
                  "view-portfolio",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Portfolio"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Portfolio")}
                    >
                      Portfolio
                    </Menu.Item>
                  )}
                {/* <Menu.Item className='subMenu' as={NavLink} to='/Portfolio' activeClassName="active" exact isActive={isActive.bind(this, '/Portfolio')}>
                Portfolio
                </Menu.Item> */}
                {this.checkPermissionMenu("Academic", [
                  "manage-flighttraining-admin",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/AdminTrainRecord"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/AdminTrainRecord")}
                    >
                      Flight Training Record (Admin)
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Academic", [
                  "manage-flighttraining-instructor",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/InstructorTrainRecord"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/InstructorTrainRecord")}
                    >
                      Flight Training Record (Instructor)
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Academic", [
                  "manage-flighttraining-student",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/TrainRecord"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/TrainRecord")}
                    >
                      Flight Training Record (Student)
                    </Menu.Item>
                  )}

                {this.checkPermissionMenu("Academic", [
                  "manage-groundtraining-admin",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/AdminAttendance"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/AdminAttendance")}
                    >
                      Attendance (Admin)
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Academic", [
                  "manage-groundtraining-instructor",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/InstructorAttendance"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/InstructorAttendance")}
                    >
                      Attendance (Instructor)
                    </Menu.Item>
                  )}
                {this.checkPermissionGroup("Management") && this.checkPermissionMenu("Management", [
                  "manage-disciplinescore",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Offense"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Offense")}
                    >
                      Report Offense
                    </Menu.Item>
                  )}
              </Accordion.Content>
            </Accordion>
          )}
          {this.checkPermissionGroup("Schedule") && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 2 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Schedule <Icon name="calendar alternate" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 2}>
                {this.checkPermissionMenu("Schedule", [
                  "manage-schedule",
                  "view-schedule",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Schedule"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Schedule")}
                    >
                      Flight / Instructors / Rooms
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Schedule", [
                  "manage-flighttable",
                  "view-flighttable",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/FlightTable"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/FlightTable")}
                    >
                      Flight Table
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Schedule", [
                  "manage-flighthourrecord",
                  "save-flighthourrecord",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/FlightHourRecord"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/FlightHourRecord")}
                    >
                      Flight Hour Record
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Schedule", [
                  "view-schedule-tracking",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/Track"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/Track")}
                    >
                      Schedule Track
                    </Menu.Item>
                  )}

                {/*
                  <Menu.Item className='subMenu' as={NavLink} to='/GroundTrainingSchedule' activeClassName="active" exact isActive={isActive.bind(this, '/GroundTrainingSchedule')}>
                  Ground Training Subjects
                </Menu.Item> */}
              </Accordion.Content>
            </Accordion>
          )}
          {/* <Accordion>
                  <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
                    <Menu.Item className={activeIndex !== 3 ? 'disableMenu' : 'disableActiveMenu'} >
                  Flight <Icon name='plane' />
                </Menu.Item>
                </Accordion.Title>
                  <Accordion.Content active={activeIndex === 3} >
                    <Menu.Item className='subMenu' as={NavLink} to='/MaintReminders' activeClassName="active" exact isActive={isActive.bind(this, '/MaintReminders')}>
                      Maintenance Reminders
                    </Menu.Item>
                    <Menu.Item className='subMenu' as={NavLink} to='/Snags' activeClassName="active" exact isActive={isActive.bind(this, '/Snags')}>
                      SNAGS
                    </Menu.Item>
                </Accordion.Content>
                </Accordion> */}
          {/* {this.checkPermissionGroup("WeightBalance") && ( */}

          {/* )} */}

          {this.checkPermissionGroup("Resource") && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 4}
                index={4}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 4 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Resource <Icon name="computer" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 4}>
                <Menu.Item
                  className="subMenu"
                  as={NavLink}
                  to="/Course"
                  activeClassName="active"
                  exact
                  isActive={isActive.bind(this, "/Course")}
                >
                  Academic
                </Menu.Item>
                <Menu.Item
                  className="subMenu"
                  as={NavLink}
                  to="/Aircrafts"
                  activeClassName="active"
                  exact
                  isActive={isActive.bind(this, "/Aircrafts")}
                >
                  Aircrafts / Simulators
                </Menu.Item>
                <Menu.Item
                  className="subMenu"
                  as={NavLink}
                  to="/Room"
                  activeClassName="active"
                  exact
                  isActive={isActive.bind(this, "/Room")}
                >
                  Room
                </Menu.Item>
              </Accordion.Content>
            </Accordion>
          )}
          {/*    <Accordion>
                  <Accordion.Title active={activeIndex === 5} index={5} onClick={this.handleClick}>
                    <Menu.Item className={activeIndex !== 5 ? 'disableMenu' : 'disableActiveMenu'} >
                      Dashboard <Icon name='chart bar' />
                    </Menu.Item>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 5}>
                    <Menu.Item className='subMenu' as={NavLink} to='/Dashboard' activeClassName="active" exact isActive={isActive.bind(this, '/Dashboard')}>
                      Dashboard
                </Menu.Item>
                  </Accordion.Content>
                </Accordion>
                */}

          <Accordion>
            <Accordion.Title
              active={activeIndex === 8}
              index={8}
              onClick={this.handleClick}
            >
              <Menu.Item
                className={
                  activeIndex !== 8 ? "disableMenu" : "disableActiveMenu"
                }
              >
                Weight & Balance <Icon name="area graph" />
              </Menu.Item>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              <Menu.Item
                className="subMenu"
                as={NavLink}
                to="/qr"
                activeClassName="active"
                exact
                isActive={isActive.bind(this, "/qr")}
              >
                Preflight Risk Assessment

              </Menu.Item>
              <Menu.Item
                className="subMenu"
                as={NavLink}
                to="/file"
                activeClassName="active"
                exact
                isActive={isActive.bind(this, "/file")}
              >
                Aircraft Performance Data
              </Menu.Item>
              <Menu.Item
                className="subMenu"
                as={NavLink}
                to="/Cessna"
                activeClassName="active"
                exact
                isActive={isActive.bind(this, "/Cessna")}
              >
                Cessna
              </Menu.Item>
              <Menu.Item
                className="subMenu"
                as={NavLink}
                to="/Diamond"
                activeClassName="active"
                exact
                isActive={isActive.bind(this, "/Diamond")}
              >
                Diamond
              </Menu.Item>
            </Accordion.Content>
          </Accordion>
          {this.checkPermissionGroup("Report") && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 6}
                index={6}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 6 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Report <Icon name="file excel" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === 6}
                style={{ padding: 0 }}
              >
                {this.checkPermissionMenu("Report", ["view-report"]) && (
                  <Menu.Item
                    className="subMenu"
                    as={NavLink}
                    to="/Report"
                    activeClassName="active"
                    exact
                    isActive={isActive.bind(this, "/Report")}
                  >
                    Report
                  </Menu.Item>
                )}
              </Accordion.Content>
            </Accordion>
          )}
          {this.checkPermissionGroup("Management") && (
            <Accordion>
              <Accordion.Title
                active={activeIndex === 7}
                index={7}
                onClick={this.handleClick}
              >
                <Menu.Item
                  className={
                    activeIndex !== 7 ? "disableMenu" : "disableActiveMenu"
                  }
                >
                  Management <Icon name="configure" />
                </Menu.Item>
              </Accordion.Title>
              <Accordion.Content
                active={activeIndex === 7}
                style={{ padding: 0 }}
              >
                {this.checkPermissionMenu("Management", [
                  "manage-alertnews",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/NewsSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/NewsSetting")}
                    >
                      Alert & News
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", [
                  "manage-aircrafts",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/AircraftSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/AircraftSetting")}
                    >
                      Aircraft
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", [
                  "manage-flighttimerule",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/FlightRulesSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/FlightRulesSetting")}
                    >
                      Flight Time Rule
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", [
                  "manage-personnel",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/PersonnelSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/PersonnelSetting")}
                    >
                      Personnel
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", [
                  "manage-roomlocation",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/LocationSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/LocationSetting")}
                    >
                      Room
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", [
                  "manage-groundinstructiontype",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/AcademicSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/AcademicSetting")}
                    >
                      Ground Instruction Type
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", ["manage-role"]) && (
                  <Menu.Item
                    className="subMenu"
                    as={NavLink}
                    to="/RolesSetting"
                    activeClassName="active"
                    exact
                    isActive={isActive.bind(this, "/RolesSetting")}
                  >
                    Role & Permission
                  </Menu.Item>
                )}

                {this.checkPermissionMenu("Management", ["manage-ebook"]) && (
                  <Menu.Item
                    className="subMenu"
                    as={NavLink}
                    to="/EbookSetting"
                    activeClassName="active"
                    exact
                    isActive={isActive.bind(this, "/EbookSetting")}
                  >
                    E-Book
                  </Menu.Item>
                )}
                {this.checkPermissionMenu("Management", [
                  "manage-disciplinescore",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/DisciplineScoreSetting"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/DisciplineScoreSetting")}
                    >
                      Discipline Score
                    </Menu.Item>
                  )}
                {this.checkPermissionMenu("Management", ["manage-user"]) && (
                  <Menu.Item
                    className="subMenu"
                    as={NavLink}
                    to="/User"
                    activeClassName="active"
                    exact
                    isActive={isActive.bind(this, "/User")}
                  >
                    User (Instructors / Staff)
                  </Menu.Item>
                )}
                {this.checkPermissionMenu("Management", [
                  "manage-user-student",
                ]) && (
                    <Menu.Item
                      className="subMenu"
                      as={NavLink}
                      to="/SettingUserStudent"
                      activeClassName="active"
                      exact
                      isActive={isActive.bind(this, "/SettingUserStudent")}
                    >
                      User (Students)
                    </Menu.Item>
                  )}
              </Accordion.Content>
            </Accordion>
          )}
        </Menu>
      </div>
    );
  }
}

export default withRouter(MenuExpand);