import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Segment, Header, Button, Icon, Label } from 'semantic-ui-react'
import * as config from '../../config'
import MainButton from '../../components/mainbutton'
import Table from '../../components/table'
import ModalForm from '../../components/modal'
import FormReminder from './reminderForm'
import ReminderDetail from './reminderDetail'
import { getRemidBySearch, getRemid, getRemidDetail } from './service'
import HeaderWithMenu from '../../components/headerwithmenu'

class Reminders extends Component {

    state = {
        open: false,
        loading: false,
        data: [],
        remindId: this.props.id,
        remindDetail: [],
        search: {
            status: 4
        },
        statusText: 'Warning & Expired',
        loading: true,
        column: [{
            Header: 'Aircraft',
            accessor: 'tailNumber',
            sortable: false,
            Cell: cellInfo => (
                <span className="bold" onClick={() => this.detailRemind(cellInfo.original)}>{cellInfo.original.tailNumber} {cellInfo.original.make} {cellInfo.original.model}</span>
            ),
            width: 250,
        }, {
            Header: 'Status',
            accessor: 'status',
            sortable: false,
            style: { textAlign: 'center' },
            Cell: cellInfo => (
                cellInfo.original.status === 1
                    ? <Label size='mini' color='green' key='green' className='statusLabel'>OK</Label>
                    : cellInfo.original.status === 2
                        ? <Label size='mini' name="warning circle" color='yellow' className='statusLabel'>Warning</Label>
                        : <Label size='mini' name="warning circle" color='red' className='statusLabel'>Expired</Label>
            ),
            width: 100
        }, {
            Header: 'Type of Maint.',
            accessor: 'name',
            sortable: false,
            Cell: cellInfo => (
                <span>{cellInfo.original.name}<br /> {cellInfo.original.isOneTimeReminder ? '(One-Time Only)' : null}</span>
            ),
            width: 200,
        }, {
            Header: 'Hours Remaining',
            accessor: 'timeRemainingMessage',
            sortable: false,
            Cell: cellInfo => (
                cellInfo.original.status === 1
                    ? cellInfo.original.timeRemainingMessage
                    : <span style={{ color: cellInfo.original.status === 2 ? 'orange' : 'red' }} >{cellInfo.original.timeRemainingMessage}</span>
            ),
        }, {
            Header: 'Action',
            accessor: 'datail',
            width: 100,
            sortable: false,
            style: { textAlign: 'center', paddingTop: '10px' },
            Cell: cellInfo => (
                <Icon link circular color='orange' name='alarm' onClick={() => this.onClickDetail(cellInfo.original)} />
            ),
        }]
    }

    componentDidMount() {
        let { remindId, column } = this.state
        if (remindId) {
            this.getDataBySearch('?status=2&status=3')
            let newcolumn = column.shift()
            this.setState({ column, })
        }
        else this.getDataBySearch('?status=2&status=3')
    }

    getData = () => {
        this.setState({ loading: true })
        if (this.state.remindId) {
            getRemidBySearch('?ResourceId=' + this.state.remindId).then(res => {
                if (res && res.status === 200) {
                    this.setState({ data: res.data })
                }
                this.setState({ loading: false })
            })
        } else {
            getRemid().then(res => {
                if (res && res.status === 200) {
                    this.setState({ data: res.data })
                }
                this.setState({ loading: false })
            })
        }
    }
    getDataBySearch = (search) => {
        this.setState({ loading: true })

        if (this.state.remindId)
            search = search + '&ResourceId=' + this.state.remindId

        getRemidBySearch(search).then(res => {
            if (res && res.status === 200) {
                this.setState({ data: res.data })
            }
            this.setState({ loading: false })
        })
    }




    handleChangeStatus = (e, data) => {
        let { search } = this.state
        search[data.name] = data.value
        let qString = '?status=' + data.value

        if (data.value === 1)
            this.getData()
        else if (data.value === 4)
            this.getDataBySearch('?status=2&status=3')
        else
            this.getDataBySearch(qString)

        const result = data.options.find((member) => {
            return member.value === data.value
        })
        this.setState({ search, statusText: `${result.text}` })
    }

    handleChangeSearch = (e) => {
        let { data, search } = this.state
        search[e.target.name] = e.target.value
        this.setState({ search, })

        let condition = search.status
        let qString = '?status=' + condition

        if (e.target.value) {
            let newdata = []
            if (condition == 'all') {
                data.map(item => {
                    let description = `${item.tailNumber} ${item.make} ${item.model}`
                    if (description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
                        newdata.push(item)
                    }
                })
            } else if (condition == '4') {
                data.map(item => {
                    let description = `${item.tailNumber} ${item.make} ${item.model}`
                    if (description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 && (item.status === 2 || item.status === 3)) {
                        newdata.push(item)
                    }
                })
            } else {
                data.map(item => {
                    let description = `${item.tailNumber} ${item.make} ${item.model}`
                    if (description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0 && item.status === condition) {
                        newdata.push(item)
                    }
                })
            }
            this.setState({ data: newdata })
        } else {
            if (condition === 'all')
                this.getData()
            else if (condition === 4)
                this.getDataBySearch('?status=2&status=3')
            else
                this.getDataBySearch(qString)
        }
    }

    handleChangeFormOnetime = (e, data) => {
        this.setState({ aircraftOneTime: data })
    }
    handleChangeFormRemindtype = (e, data) => {
        this.setState({ aircraftRemindtype: data })
    }
    onClickNew = () => this.setState({ open: true })
    onClickSave = () => this.setState({ open: false })
    onClickCancel = () => this.setState({ open: false, openDetail: false })
    onClickDetail = (data) => {
        console.log("dee")
        getRemidDetail(data.id).then(res => {
            if (res && res.status === 200) {
                this.setState({ remindDetail: res.data, openDetail: true })
            }
        })
    }


    render() {
        const state = this.state
        return (
            <div>
                <ModalForm
                    open={state.open}
                    icon='alarm'
                    content=' Add Reminder'
                    close={this.onClickCancel}
                    save={this.onClickCancel}
                     >
                    <FormReminder />
                </ModalForm>
                <ModalForm
                    open={state.openDetail}
                    icon='alarm'
                    content='Maint. Reminder'
                    close={this.onClickCancel}
                    save={this.onClickCancel}
                     >
                    <ReminderDetail data={state.remindDetail} />
                </ModalForm>
                {
                    state.remindId
                        ? <div>
                            <Form >
                                <Form.Group >
                                    <Form.Dropdown
                                        icon={null}
                                        fluid
                                        floating
                                        width='6'
                                        style={{ width: '100%' }}
                                        trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                            <Icon name='angle down' />
                                            Status : <b>{state.statusText}</b>
                                        </Button>}
                                        options={config.options_statusremind}
                                        defaultValue={state.search.status}
                                        onChange={this.handleChangeStatus}
                                    />
                                    <Form.Field>
                                        <MainButton
                                            size='small'
                                            text='Add Reminder'
                                            onClick={this.onClickNew} />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                            <Table
                                data={state.data}
                                columns={state.column}
                                loading={state.loading}
                                defaultSorted={[
                                    {
                                        id: "status",
                                        desc: true
                                    },
                                ]}
                            />
                        </div>
                        : <Segment className="contentSegment" textAlign='left'>
                            <HeaderWithMenu name='Maintenance Reminders' >
                                <MainButton
                                    size='small'
                                    text='Add Reminder'
                                    onClick={this.onClickNew} />
                            </HeaderWithMenu>
                            <Form >
                                <Form.Group >
                                    <Form.Input
                                        width='3'
                                        icon='search'
                                        size='small'
                                        placeholder='Search'
                                        name='name'
                                        onChange={this.handleChangeSearch} />
                                    <Form.Dropdown
                                        icon={null}
                                        fluid
                                        floating
                                        width='4'
                                        style={{ width: '100%' }}
                                        trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                            <Icon name='angle down' />
                                            Status : <b>{state.statusText}</b>
                                        </Button>}
                                        options={config.options_statusremind}
                                        defaultValue={state.search.status}
                                        onChange={this.handleChangeStatus}
                                    />

                                </Form.Group>
                            </Form>
                            <Table
                                data={state.data}
                                columns={state.column}
                                loading={state.loading}
                                defaultSorted={[
                                    {
                                        id: "status",
                                        desc: true
                                    },
                                ]}
                            />
                        </Segment>
                }


            </div>
        )
    }
}

export default withRouter(Reminders)
