import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Icon,
  Form,
  Breadcrumb,
  Button,
  Grid,
  Message,
  Table,
  Checkbox,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import { DateInput } from "semantic-ui-calendar-react";

import moment from "moment";
import { getPerformance, exportPerformance, savePerformance } from "./service";
import Wrap from "../../components/wrap";
import TemplateHeader1 from "./performance_header1";
import TemplateBody1 from "./performance_tb1";
import TemplateHeader2 from "./performance_header2";
import TemplateBody2 from "./performance_tb2";
import TemplateHeader3 from "./performance_header3";
import TemplateBody3 from "./performance_tb3";

class PerformanceReport extends Component {
  state = {
    date: moment().format("dddd, MMMM DD, YYYY"),
    templateText: "Aircraft",
    template: {
      key: "1",
      text: "Aircraft",
      value: "1",
      icon: "table",
      description: "",
    },
    locationText: "KL.15",
    location: {
      key: "1",
      text: "KL.15",
      value: "KL.15",
      icon: "sort alphabet ascending",
      description: "",
    },
    options_location: [
      {
        key: "1",
        text: "KL.15",
        value: "KL.15",
        icon: "plane",
        description: "",
      },
      {
        key: "2",
        text: "VTUQ",
        value: "VTUQ",
        icon: "plane",
        description: "",
      },
    ],
    options_template: [
      {
        key: "1",
        text: "Aircraft",
        value: "1",
        icon: "table",
        description: "",
      },
      {
        key: "2",
        text: "Simulator",
        value: "2",
        icon: "table",
        description: "",
      },
      {
        key: "3",
        text: "Aircraft (Night)",
        value: "3",
        icon: "table",
        description: "",
      },
    ],
    data: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    let { template, locationText, date } = this.state;
    let qSting =
      "?location=" +
      locationText +
      "&date=" +
      moment(date).format("YYYY-MM-DD") +
      "&";

    this.setState({ loading: true });

    if (template.value === "2") qSting = qSting + "template=sim";
    else if (template.value === "3") qSting = qSting + "template=night";

    getPerformance(qSting).then((res) => {
      if (res && res.data) {
        res.data.singleEngines.map((item) => {
          if (item.lastLocation === locationText) {
            item.location = true;
            // if (!item.remark) {
            //   if (locationText === "KL.15") item.remark = "VTUQ";
            //   else item.remark = "KL.15";
            // }
          } else {
            item.location = null;
            if (!item.remark) {
              if (locationText === "KL.15") item.remark = "VTUQ";
              else item.remark = "KL.15";
            }
          }
          if (item.isActive === "true") {
            if (template.value === "2") {
              if (item.schedule_log) {
                let log = JSON.parse(item.schedule_log);
                item.morning = log['morning'];
                item.evening = log['evening'];
              } else {
                item.morning = [false, false, false, false];
                item.evening = [false, false, false, false];
              }
            } else {
              if (item.schedule_log) {
                let log = JSON.parse(item.schedule_log);
                item.morning = log['morning'];
                item.evening = log['evening'];
              } else {
                item.morning = [false, false, false];
                item.evening = [false, false, false];
              }
            }
          } else {
            if (template.value === "2") {
              item.morning = ["disable", "disable", "disable", "disable"];
              item.evening = ["disable", "disable", "disable", "disable"];
            } else {
              item.morning = ["disable", "disable", "disable"];
              item.evening = ["disable", "disable", "disable"];
            }
          }
        });
        res.data.multiEngines.map((item) => {
          if (item.lastLocation === locationText) {
            item.location = true;
            // if (!item.remark) {
            //   if (locationText === "KL.15") item.remark = "VTUQ";
            //   else item.remark = "KL.15";
            // }
          } else {
            item.location = null;
            if (!item.remark) {
              if (locationText === "KL.15") item.remark = "VTUQ";
              else item.remark = "KL.15";
            }
          }
          if (item.isActive === "true") {
            if (template.value === "2") {
              if (item.schedule_log) {
                let log = JSON.parse(item.schedule_log);
                item.morning = log['morning'];
                item.evening = log['evening'];
              } else {
                item.morning = [false, false, false, false];
                item.evening = [false, false, false, false];
              }
            } else {
              if (item.schedule_log) {
                let log = JSON.parse(item.schedule_log);
                item.morning = log['morning'];
                item.evening = log['evening'];
              } else {
                item.morning = [false, false, false];
                item.evening = [false, false, false];
              }
            }
          } else {
            if (template.value === "2") {
              item.morning = ["disable", "disable", "disable", "disable"];
              item.evening = ["disable", "disable", "disable", "disable"];
            } else {
              item.morning = ["disable", "disable", "disable"];
              item.evening = ["disable", "disable", "disable"];
            }
          }
        });
        res.data &&
          res.data.a320 &&
          res.data.a320.map((item) => {
            if (item.lastLocation === locationText) {
              item.location = true;
              // if (!item.remark) {
              //   if (locationText === "KL.15") item.remark = "VTUQ";
              //   else item.remark = "KL.15";
              // }
            } else {
              item.location = null;
              if (!item.remark) {
                if (locationText === "KL.15") item.remark = "VTUQ";
                else item.remark = "KL.15";
              }
            }
            if (item.isActive === "true") {
              if (template.value === "2") {
                if (item.schedule_log) {
                  let log = JSON.parse(item.schedule_log);
                  item.morning = log['morning'];
                  item.evening = log['evening'];
                } else {
                  item.morning = [false, false, false, false];
                  item.evening = [false, false, false, false];
                }
              } else {
                if (item.schedule_log) {
                  let log = JSON.parse(item.schedule_log);
                  item.morning = log['morning'];
                  item.evening = log['evening'];
                } else {
                  item.morning = [false, false, false];
                  item.evening = [false, false, false];
                }
              }
            } else {
              if (template.value === "2") {
                item.morning = ["disable", "disable", "disable", "disable"];
                item.evening = ["disable", "disable", "disable", "disable"];
              } else {
                item.morning = ["disable", "disable", "disable"];
                item.evening = ["disable", "disable", "disable"];
              }
            }
          });
        res.data &&
          res.data.nightflight &&
          res.data.nightflight.map((item) => {
            if (item.lastLocation === locationText) {
              item.location = true;
              // if (!item.remark) {
              //   if (locationText === "KL.15") item.remark = "VTUQ";
              //   else item.remark = "KL.15";
              // }
            } else {
              item.location = null;
              if (!item.remark) {
                if (locationText === "KL.15") item.remark = "VTUQ";
                else item.remark = "KL.15";
              }
            }
            if (item.isActive === "true") {
              if (template.value === "2") {
                if (item.schedule_log) {
                  let log = JSON.parse(item.schedule_log);
                  item.morning = log['morning'];
                  item.evening = log['evening'];
                } else {
                  item.morning = [false, false, false, false];
                  item.evening = [false, false, false, false];
                }
              } else {
                if (item.schedule_log) {
                  let log = JSON.parse(item.schedule_log);
                  item.morning = log['morning'];
                  item.evening = log['evening'];
                } else {
                  item.morning = [false, false, false];
                  item.evening = [false, false, false];
                }
              }
            } else {
              if (template.value === "2") {
                item.morning = ["disable", "disable", "disable", "disable"];
                item.evening = ["disable", "disable", "disable", "disable"];
              } else {
                item.morning = ["disable", "disable", "disable"];
                item.evening = ["disable", "disable", "disable"];
              }
            }
          });

        this.calculatePerformance(res.data);
      }
    });
  };
  calculatePerformance = (data) => {
    if (data && data.singleEngines) {
      data.summary["singleEngines"] = {
        totalCheck: 0,
        totalLocation: 0,
        totalReady: 0,
      };
      // data.summary["singleEngines"]["totalCheck"] = 0;
      // data.summary["singleEngines"]["totalLocation"] = 0;

      let durations = [];
      data.summary.totalLandSingle = data.singleEngines
        .filter((item) => {
          if (item.location === true) {
            data.summary["singleEngines"]["totalLocation"] += 1;
            if (item.isActive === "true" || item.isActive === "half")
              data.summary["singleEngines"]["totalReady"] += 1;

            durations.push(item.hours);
            return item;
          }
        })
        .reduce((sum, record) => {
          return sum + record.land;
        }, 0);
      const totalDurations = durations
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(durations[0])
        );
      var ms = totalDurations._milliseconds;
      var ticks = ms / 1000;
      var hh = Math.floor(ticks / 3600);
      var mm = Math.floor((ticks % 3600) / 60);
      if (hh.toString().length === 1) hh = "0" + hh;
      if (mm.toString().length === 1) mm = "0" + mm;
      data.summary.totalHourSingle = hh + ":" + mm;

      data.singleEngines.map((element) => {
        data.summary["singleEngines"]["totalCheck"] += element.morning.filter(
          (x) => x === true
        ).length;
        data.summary["singleEngines"]["totalCheck"] += element.evening.filter(
          (x) => x === true
        ).length;
      });
      let totalPeriod = data.singleEngines.filter(
        (element) => element.location === true
        // element.isActive === "true" &&
      );
      data.summary["singleEngines"]["totalPeriod"] = 0;
      totalPeriod.map((element) => {
        data.summary["singleEngines"]["totalPeriod"] += element.morning.filter(
          (x) => x !== "disable"
        ).length;
        data.summary["singleEngines"]["totalPeriod"] += element.evening.filter(
          (x) => x !== "disable"
        ).length;
      });

      data.summary["singleEngines"]["totalOK"] = data.singleEngines.filter(
        (element) => element.isActive === "true" && element.location === true
      ).length;
      data.summary["singleEngines"]["totalGND"] = data.singleEngines.filter(
        (element) =>
          element.isActive === "false" || element.isActive === "disable"
      ).length;
      data.summary["singleEngines"]["totalHALF"] = data.singleEngines.filter(
        (element) => element.isActive === "half"
      ).length;
    }
    if (data && data.multiEngines) {
      data.summary["multiEngines"] = {
        totalCheck: 0,
        totalLocation: 0,
        totalReady: 0,
      };
      // data.summary["multiEngines"]["totalCheck"] = 0;
      // data.summary["multiEngines"]["totalLocation"] = 0;
      let durations = [];
      data.summary.totalLandMulti = data.multiEngines
        .filter((item) => {
          if (item.location === true) {
            data.summary["multiEngines"]["totalLocation"] += 1;
            if (item.isActive === "true" || item.isActive === "half")
              data.summary["multiEngines"]["totalReady"] += 1;
            durations.push(item.hours);
            return item;
          }
        })
        .reduce((sum, record) => {
          return sum + record.land;
        }, 0);
      const totalDurations = durations
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(durations[0])
        );

      var ms = totalDurations._milliseconds;
      var ticks = ms / 1000;
      var hh = Math.floor(ticks / 3600);
      var mm = Math.floor((ticks % 3600) / 60);
      if (hh.toString().length === 1) hh = "0" + hh;
      if (mm.toString().length === 1) mm = "0" + mm;
      data.summary.totalHourMulti = hh + ":" + mm;

      data.multiEngines.map((element) => {
        data.summary["multiEngines"]["totalCheck"] += element.morning.filter(
          (x) => x === true
        ).length;
        data.summary["multiEngines"]["totalCheck"] += element.evening.filter(
          (x) => x === true
        ).length;
      });
      let totalPeriod = data.multiEngines.filter(
        (element) => element.location === true
        // element.isActive === "true" &&
      );
      data.summary["multiEngines"]["totalPeriod"] = 0;
      totalPeriod.map((element) => {
        data.summary["multiEngines"]["totalPeriod"] += element.morning.filter(
          (x) => x !== "disable"
        ).length;
        data.summary["multiEngines"]["totalPeriod"] += element.evening.filter(
          (x) => x !== "disable"
        ).length;
      });

      data.summary["multiEngines"]["totalOK"] = data.multiEngines.filter(
        (element) => element.isActive === "true" && element.location === true
      ).length;
      data.summary["multiEngines"]["totalGND"] = data.multiEngines.filter(
        (element) =>
          element.isActive === "false" || element.isActive === "disable"
      ).length;
      data.summary["multiEngines"]["totalHALF"] = data.multiEngines.filter(
        (element) => element.isActive === "half"
      ).length;
    }
    if (data && data.a320) {
      data.summary["a320"] = {
        totalCheck: 0,
        totalLocation: 0,
        totalReady: 0,
      };
      // data.summary["multiEngines"]["totalCheck"] = 0;
      // data.summary["multiEngines"]["totalLocation"] = 0;
      let durations = [];
      data.summary.totalLandA320 = data.a320
        .filter((item) => {
          if (item.location === true) {
            data.summary["a320"]["totalLocation"] += 1;
            if (item.isActive === "true" || item.isActive === "half")
              data.summary["a320"]["totalReady"] += 1;
            durations.push(item.hours);
            return item;
          }
        })
        .reduce((sum, record) => {
          return sum + record.land;
        }, 0);
      const totalDurations = durations
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(durations[0])
        );

      var ms = totalDurations._milliseconds;
      var ticks = ms / 1000;
      var hh = Math.floor(ticks / 3600);
      var mm = Math.floor((ticks % 3600) / 60);
      if (hh.toString().length === 1) hh = "0" + hh;
      if (mm.toString().length === 1) mm = "0" + mm;
      data.summary.totalHourA320 = hh + ":" + mm;

      data.a320.map((element) => {
        data.summary["a320"]["totalCheck"] += element.morning.filter(
          (x) => x === true
        ).length;
        data.summary["a320"]["totalCheck"] += element.evening.filter(
          (x) => x === true
        ).length;
      });
      let totalPeriod = data.a320.filter(
        (element) => element.location === true
        // element.isActive === "true" &&
      );
      data.summary["a320"]["totalPeriod"] = 0;
      totalPeriod.map((element) => {
        data.summary["a320"]["totalPeriod"] += element.morning.filter(
          (x) => x !== "disable"
        ).length;
        data.summary["a320"]["totalPeriod"] += element.evening.filter(
          (x) => x !== "disable"
        ).length;
      });
      data.summary["a320"]["totalOK"] = data.a320.filter(
        (element) => element.isActive === "true" && element.location === true
      ).length;
      data.summary["a320"]["totalGND"] = data.a320.filter(
        (element) =>
          element.isActive === "false" || element.isActive === "disable"
      ).length;
      data.summary["a320"]["totalHALF"] = data.a320.filter(
        (element) => element.isActive === "half"
      ).length;
    }
    if (data && data.nightflight) {
      data.summary["nightflight"] = {
        totalCheck: 0,
        totalLocation: 0,
        totalReady: 0,
      };
      // data.summary["multiEngines"]["totalCheck"] = 0;
      // data.summary["multiEngines"]["totalLocation"] = 0;
      let durations = [];
      data.summary.totalLandNight = data.nightflight
        .filter((item) => {
          if (item.location === true) {
            data.summary["nightflight"]["totalLocation"] += 1;
            if (item.isActive === "true" || item.isActive === "half")
              data.summary["nightflight"]["totalReady"] += 1;
            durations.push(item.hours);
            return item;
          }
        })
        .reduce((sum, record) => {
          return sum + record.land;
        }, 0);
      const totalDurations = durations
        .slice(1)
        .reduce(
          (prev, cur) => moment.duration(cur).add(prev),
          moment.duration(durations[0])
        );

      var ms = totalDurations._milliseconds;
      var ticks = ms / 1000;
      var hh = Math.floor(ticks / 3600);
      var mm = Math.floor((ticks % 3600) / 60);
      if (hh.toString().length === 1) hh = "0" + hh;
      if (mm.toString().length === 1) mm = "0" + mm;
      data.summary.totalHourNight = hh + ":" + mm;

      data.nightflight.map((element) => {
        data.summary["nightflight"]["totalCheck"] += element.morning.filter(
          (x) => x === true
        ).length;
        data.summary["nightflight"]["totalCheck"] += element.evening.filter(
          (x) => x === true
        ).length;
      });
      let totalPeriod = data.nightflight.filter(
        (element) => element.location === true
        // element.isActive === "true" &&
      );
      data.summary["nightflight"]["totalPeriod"] = 0;
      totalPeriod.map((element) => {
        data.summary["nightflight"]["totalPeriod"] += element.morning.filter(
          (x) => x !== "disable"
        ).length;
        data.summary["nightflight"]["totalPeriod"] += element.evening.filter(
          (x) => x !== "disable"
        ).length;
      });
      data.summary["nightflight"]["totalOK"] = data.nightflight.filter(
        (element) => element.isActive === "true" && element.location === true
      ).length;
      data.summary["nightflight"]["totalGND"] = data.nightflight.filter(
        (element) =>
          element.isActive === "false" || element.isActive === "disable"
      ).length;
      data.summary["nightflight"]["totalHALF"] = data.nightflight.filter(
        (element) => element.isActive === "half"
      ).length;
    }
    let durations = [];
    if (data.nightflight) {
      data.summary.totalLand =
        data.summary.totalLandSingle +
        data.summary.totalLandMulti +
        data.summary.totalLandNight;
      data.summary.totalHour = this.calculatetime([
        data.summary.totalHourSingle,
        data.summary.totalHourMulti,
        data.summary.totalHourNight,
      ]);
    } else {
      data.summary.totalLand =
        data.summary.totalLandSingle + data.summary.totalLandMulti;
      data.summary.totalHour = this.calculatetime([
        data.summary.totalHourSingle,
        data.summary.totalHourMulti,
      ]);
    }
    data.summary.totalLandDay =
      data.summary.totalLandSingle + data.summary.totalLandMulti;
    data.summary.totalHourDay = this.calculatetime([
      data.summary.totalHourSingle,
      data.summary.totalHourMulti,
    ]);

    this.setState({ data, loading: false });
  };

  calculatetime = (durations) => {
    const totalDurations = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );

    var ms = totalDurations._milliseconds;
    var ticks = ms / 1000;
    var hh = Math.floor(ticks / 3600);
    var mm = Math.floor((ticks % 3600) / 60);
    if (hh.toString().length === 1) hh = "0" + hh;
    if (mm.toString().length === 1) mm = "0" + mm;
    return hh + ":" + mm;
  };

  handleChangeDate = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.getData();
        }
      );
    }
  };
  handleChangeTemplate = (e, data) => {
    let { template } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    template = result;

    this.setState(
      {
        template,
        templateText: `${result.text}`,
      },
      () => {
        this.getData();
      }
    );
  };
  handleChangeLocation = (e, data) => {
    let { location } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    location = result;

    this.setState(
      {
        location,
        locationText: `${result.text}`,
      },
      () => {
        this.getData();
      }
    );
  };

  handleCloseThisTime = (engine, index, time, indexTime) => {
    let { data } = this.state;
    if (data[engine][index][time][indexTime] === "disable") {
      data[engine][index][time][indexTime] = null;
    } else data[engine][index][time][indexTime] = "disable";

    let disable = 0;
    disable += data[engine][index]["morning"].filter(
      (element) => element === "disable"
    ).length;
    disable += data[engine][index]["evening"].filter(
      (element) => element === "disable"
    ).length;
    if (
      disable ===
      data[engine][index]["morning"].length +
      data[engine][index]["evening"].length
    )
      data[engine][index].isActive = "disable";
    else if (disable >= data[engine][index]["morning"].length)
      data[engine][index].isActive = "half";
    else data[engine][index].isActive = "true";

    this.calculatePerformance(data);
  };
  handleSelectTime = (value, engine, index, time, indexTime) => {
    let { data } = this.state;
    data[engine][index][time][indexTime] = value.checked;
    this.calculatePerformance(data);
  };
  handleChangeAircraftLocation = (value, engine, index) => {
    let { data, template } = this.state;
    data[engine][index].location = value.checked;
    data[engine][index].remark = null;
    if (data[engine][index].isActive !== "false") {
      data[engine][index].isActive = "true";
      if (template.value === "2") {
        data[engine][index]["morning"] = [false, false, false, false];
        data[engine][index]["evening"] = [false, false, false, false];
      } else {
        data[engine][index]["morning"] = [false, false, false];
        data[engine][index]["evening"] = [false, false, false];
      }
    }
    this.calculatePerformance(data);
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    let endpoint = "/performance/export";
    let { data, template, locationText } = this.state;
    if (template.value === "2") endpoint = "/performanceSim/export";
    else if (template.value === "3") endpoint = "/performanceNight/export";
    data.location = locationText;
    exportPerformance(endpoint, data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "Performance_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "Performance_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickSave = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    let { data, template, locationText } = this.state;
    // if (template.value === "2") endpoint = "/performanceSim/export";
    // else if (template.value === "3") endpoint = "/performanceNight/export";
    data.location = locationText;
    savePerformance(data).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Report - Performance Report">
          <Button size="small" onClick={this.onClickSave} color="blue">
            Save
          </Button>
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Performance Report</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
              <DateInput
                name="date"
                placeholder="Select Date"
                hideMobileKeyboard
                dateFormat="dddd, MMMM DD, YYYY"
                iconPosition="right"
                value={state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Dropdown
              icon={null}
              clearable
              // width="3"
              style={{ width: "100%", marginRight: "30px", height: "100%" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Location : <b>{state.locationText || "-"}</b>
                </Button>
              }
              options={state.options_location}
              selectOnBlur={false}
              onChange={this.handleChangeLocation}
            />
            <Form.Dropdown
              icon={null}
              clearable
              // width="3"
              style={{ width: "100%", marginRight: "30px", height: "100%" }}
              trigger={
                <Button
                  basic
                  icon
                  fluid
                  labelPosition="right"
                  style={{ textAlign: "left" }}
                >
                  <Icon name="angle down" />
                  Template : <b>{state.templateText || "-"}</b>
                </Button>
              }
              options={state.options_template}
              selectOnBlur={false}
              onChange={this.handleChangeTemplate}
            />
          </Form.Group>
        </Form>

        {state.loading ? (
          ""
        ) : state.template.value === "1" ? (
          <Wrap>
            <TemplateHeader1
              name={state.locationText}
              data={state.data && state.data.summary}
            />
            <br />
            <TemplateBody1
              checked={state.checked}
              locationText={state.locationText}
              data={state.data && state.data}
              handleChangeAircraftLocation={this.handleChangeAircraftLocation}
              handleCloseThisTime={this.handleCloseThisTime}
              handleSelectTime={this.handleSelectTime}
            />
          </Wrap>
        ) : state.template.value === "2" ? (
          <Wrap>
            <TemplateHeader2
              name={state.locationText}
              data={state.data && state.data.summary}
            />
            <br />
            <TemplateBody2
              checked={state.checked}
              locationText={state.locationText}
              data={state.data && state.data}
              handleChangeAircraftLocation={this.handleChangeAircraftLocation}
              handleCloseThisTime={this.handleCloseThisTime}
              handleSelectTime={this.handleSelectTime}
            />
          </Wrap>
        ) : (
          state.template.value === "3" && (
            <Wrap>
              <TemplateHeader3
                name={state.locationText}
                data={state.data && state.data.summary}
              />
              <br />
              <TemplateBody3
                checked={state.checked}
                locationText={state.locationText}
                data={state.data && state.data}
                handleChangeAircraftLocation={this.handleChangeAircraftLocation}
                handleCloseThisTime={this.handleCloseThisTime}
                handleSelectTime={this.handleSelectTime}
              />
            </Wrap>
          )
        )}
        <br />
      </Segment>
    );
  }
}

export default withRouter(PerformanceReport);
