import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Accordion, Grid, List, Message } from "semantic-ui-react";
import { Field } from "redux-form";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import Wrap from "../../components/wrap";
import * as config from "../../config";

class FromReservation extends Component {
  render() {
    const {
      course,
      student,
      studentId,
      studentGroup,
      attendees,
      additionalAttendees,
      room,
      groundSubjects,
      material,
      instructor,
      group,
      additionalGroup,
      additionalStudent,
      typeGroundInstructor,
      onAddtypeGroundInstructor,
      instructorAll,
    } = this.props;

    const attendeesGroup = attendees;
    console.log(additionalAttendees, "|additionalAttendees");
    console.log(attendeesGroup, "attendeesGroup")
    return (
      <Wrap>
        <Field
          name="courseType"
          component={renderSelectField}
          placeholder="Course Type"
          label="Filter by Course Type"
          options={[
            { key: "f", text: "Full Time", value: "Full Time" },
            { key: "p", text: "Part Time", value: "Part Time" },
          ]}
          width="6"
          search
          disableclear
        />
        <Field
          name="courseId"
          component={renderSelectField}
          placeholder="Course"
          label="Course"
          options={course}
          search
          clearable={true}
          required
        />
        <Field
          name="groundSubjects"
          component={renderSelectField}
          placeholder="Ground Subjects"
          label="Ground Subjects"
          options={groundSubjects}
          search
          clearable={true}
          required
        />
        <Field
          name="testSubject"
          component={renderCheckboxGroupField}
          inline={true}
          label="Test"
          options={[
            {
              key: "testSubject",
              name: "testSubject",
              label: "Test",
              value: "testSubject",
            },
          ]}
        />
        <Field
          name="material"
          component={renderTextAreaField}
          placeholder="Material Requirement"
          label="Material Requirement"
        />
        {/* <Message size="small">
          <p>
          <b>Material Requirement :</b> {material
              ? material
              : '-'
             }
          </p>
        </Message> */}

        {/* <Form.Group>
          <Form.Field width="3">
            <label style={{ textAlign: "left" }}>Holiday</label>
          </Form.Field>
          <Field
            width="4"
            name="holiday"
            component={renderRadioField}
            // label="Holiday"
          />
        </Form.Group> */}
        <Form.Group unstackable>
          <Field
            // width="8"
            name="studentGroup"
            component={renderSelectField}
            placeholder="Student Class"
            label="Student Class"
            options={group}
            multiple
            search
          />
          {/*
          เอาออก เพราะแค่ class ก็พอแล้ว
          <Field
            width="8"
            name="studentId"
            component={renderSelectField}
            placeholder="Student"
            label="Student"
            options={student}
            multiple
            search
          />
          */}
        </Form.Group>
        <div>
          {attendees && attendees.length > 0 ? (
            <Accordion
              panels={[
                {
                  key: "attendees",
                  title: {
                    content: (
                      <span
                        style={{ fontWeight: "bold", color: config.colorTheme }}
                      >
                        Attendees {attendees && `(${attendees.length})`}
                      </span>
                    ),
                    icon: "users",
                  },
                  content: {
                    content: (
                      <Grid>
                        <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                          <Grid.Column>
                            <List size="small">
                              {attendees.map((item) => (
                                <List.Item>
                                  <List.Icon
                                    link
                                    name="x"
                                    color="red"
                                    onClick={() =>
                                      this.props.removeAttendees(item.id)
                                    }
                                  />
                                  <List.Content>{item.name}</List.Content>
                                </List.Item>
                              ))}
                            </List>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    ),
                  },
                },
              ]}
            />
          ) : (
            ""
          )}
        </div>
        <br />
        <Form.Group unstackable>
          <Field
            width="8"
            name="additionalStudentGroup"
            component={renderSelectField}
            placeholder="Additional Student Class"
            label="Additional Student Class"
            options={additionalGroup}
            multiple
            search
          />
          <Field
            width="8"
            name="additionalStudentId"
            component={renderSelectField}
            placeholder="Additional Student"
            label="Additional Student"
            options={additionalStudent}
            multiple
            search
          />
        </Form.Group>
        <div>
          {additionalAttendees && additionalAttendees.length > 0 ? (
            <Accordion
              panels={[
                {
                  key: "attendees",
                  title: {
                    content: (
                      <span
                        style={{ fontWeight: "bold", color: config.colorTheme }}
                      >
                        Additional attendees{" "}
                        {additionalAttendees &&
                          `(${additionalAttendees.length})`}
                      </span>
                    ),
                    icon: "users",
                  },
                  content: {
                    content: (
                      <Grid>
                        <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                          <Grid.Column>
                            <List size="small">
                              {additionalAttendees.map((item) => (
                                <List.Item>
                                  <List.Icon
                                    link
                                    name="x"
                                    color="red"
                                    onClick={() =>
                                      this.props.removeAdditionalAttendees(
                                        item.id
                                      )
                                    }
                                  />
                                  <List.Content>{item.name}</List.Content>
                                </List.Item>
                              ))}
                            </List>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    ),
                  },
                },
              ]}
            />
          ) : (
            ""
          )}
        </div>
        <br />
        <Field
          name="instructor"
          component={renderSelectField}
          placeholder="Instructor"
          label="Instructor"
          options={instructor}
          search
          required
        />
        {/* 11/4/19 รอกำหนด typeGroundInstructor ที่วิชา */}
        {/* <Form.Group unstackable>
          <Field
            name="instructor"
            width="8"
            component={renderSelectField}
            placeholder="Instructor"
            label="Instructor"
            options={instructor}
            search
            required
          />
          <Field
            name="typeGroundInstructor"
            width="8"
            component={renderSelectField}
            placeholder="Type Ground Instructor"
            label="Type Ground Instructor"
            options={typeGroundInstructor}
            search
            required
            // onAddItem={(e, data) => onAddtypeGroundInstructor(data.value)}
          />
        </Form.Group> */}
        <Field
          name="room"
          component={renderSelectField}
          placeholder="Room"
          label="Room"
          options={room}
          search
          required
        />
        <Form.Group>
          <Form.Field width="4">
            <label style={{ textAlign: "left" }}>Student Observer</label>
          </Form.Field>
          <Field
            name="SPObserver"
            width="12"
            multiple
            component={renderSelectField}
            placeholder="Student Multiple Observer"
            options={student}
            search
          />
        </Form.Group>

        <Form.Group>
          <Form.Field width="4">
            <label style={{ textAlign: "left" }}>Instructor Observer</label>
          </Form.Field>
          <Field
            name="IPObserver"
            width="12"
            multiple
            component={renderSelectField}
            placeholder="Instructor Multiple Observer"
            options={instructorAll}
            search
          />
        </Form.Group>
        {/* (studentId && studentId.length > 0)) ||
                 (studentGroup && studentGroup.length > 0)  */}

        <br />
      </Wrap>
    );
  }
}

export default withRouter(FromReservation);
