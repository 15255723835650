import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as config from "../../config";
import ModalForm from "../../components/modal";
import BigCalendar from "../../components/bigcalendar";
import {
  getEventByIdBySearch,
  getEventDetail,
  getEventByAttendanceId,
  getStudentPerpageBySearch,
} from "./service";
import { Form } from "semantic-ui-react";
import moment from "moment";
import EventDetailForm from "./eventDetail";

class scheduleById extends Component {
  state = {
    events: [],
    option_student: [],
    searchText: localStorage.getItem("bac_filterStudent") || null,
    filterStudentId: localStorage.getItem("bac_filterStudentId")
      ? parseInt(localStorage.getItem("bac_filterStudentId"))
      : null,
    canSearch: false,
  };

  componentDidMount() {
    this.getEvent();
    if (this.state.searchText)
      getStudentPerpageBySearch(
        "?status=Active&name=" + this.state.searchText
      ).then((res) => {
        if (res && res.data) {
          let option_student = [];
          res.data.data.map((item) => {
            option_student.push({
              key: item.id,
              value: item.id,
              text: `${item.studentId || ""} ${item.titleEng || ""} ${item.firstNameEng
                } ${item.lastNameEng} ${item.customerCode || ""}`,
            });
          });
          this.setState({ option_student: option_student });
        }
      });
  }

  searchStudent = (e, { searchQuery }) => {
    let option_student = [];
    if (this._timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
      localStorage.setItem("bac_filterStudent", searchQuery);
      getStudentPerpageBySearch("?status=Active&name=" + searchQuery).then(
        (res) => {
          if (res && res.data) {
            res.data.data.map((item) => {
              option_student.push({
                key: item.id,
                value: item.id,
                text: `${item.studentId || ""} ${item.titleEng || ""} ${item.firstNameEng
                  } ${item.lastNameEng} ${item.customerCode || ""}`,
              });
            });
            this.setState({ option_student: option_student });
          }
        }
      );
    }, 2000);
  };

  getEvent = () => {
    if (
      this.props.location.pathname === "/" ||
      this.props.location.pathname === "/Home"
    ) {
      let role = JSON.parse(localStorage.getItem("bac_roles"));
      if (role && role.toString() === "Student") {
        getEventByAttendanceId(this.props.id).then((res) => {
          if (res && res.status === 200) {
            let events = [];
            res.data.map((item) =>
              events.push({
                id: item.id,
                title: item.title,
                data: item,
                start: moment(item.start)._d,
                end: moment(item.end)._d,
              })
            );
            this.setState({ events: events });
          }
        });
      } else {
        this.setState({ canSearch: true });
        let events = [];
        getEventByIdBySearch("?instructorId=" + this.props.id)
          .then((res) => {
            if (res && res.status === 200) {
              res.data.map((item) =>
                events.push({
                  id: item.id,
                  title: item.title,
                  data: item,
                  start: moment(item.start)._d,
                  end: moment(item.end)._d,
                })
              );
              this.setState({ events: events });
            }
          })
          .then(() => {
            if (this.state.filterStudentId)
              getEventByAttendanceId(this.state.filterStudentId).then((res) => {
                if (res && res.status === 200) {
                  res.data.map((item) =>
                    events.push({
                      id: item.id,
                      title: item.title,
                      data: item,
                      start: moment(item.start)._d,
                      end: moment(item.end)._d,
                    })
                  );
                  this.setState({ events: events });
                }
              });
          });
      }
    } else if (
      this.props.location.pathname === "/Instructors/Profile" ||
      this.props.location.pathname === "/InstructorProfile" ||
      this.props.location.pathname === "/Instructors" ||
      this.props.location.pathname === "/Staffs/Profile" ||
      this.props.location.pathname === "/Staffs"
    ) {
      getEventByIdBySearch("?instructorId=" + this.props.id).then((res) => {
        if (res && res.status === 200) {
          let events = [];
          res.data.map((item) =>
            events.push({
              id: item.id,
              title: item.title,
              data: item,
              start: moment(item.start)._d,
              end: moment(item.end)._d,
            })
          );
          this.setState({ events: events });
        }
      });
    } else if (
      this.props.location.pathname === "/Students/Profile" ||
      this.props.location.pathname === "/StudentProfile" ||
      this.props.location.pathname === "/Students"
    ) {
      getEventByAttendanceId(this.props.id).then((res) => {
        if (res && res.status === 200) {
          let events = [];
          res.data.map((item) =>
            events.push({
              id: item.id,
              title: item.title,
              data: item,
              start: moment(item.start)._d,
              end: moment(item.end)._d,
            })
          );
          this.setState({ events: events });
        }
      });
    } else if (this.props.location.pathname === "/Aircraft/Profile") {
      getEventByIdBySearch("?aircraftId=" + this.props.id).then((res) => {
        if (res && res.status === 200) {
          let events = [];
          res.data.map((item) =>
            events.push({
              id: item.id,
              title: item.title,
              data: item,
              start: moment(item.start)._d,
              end: moment(item.end)._d,
            })
          );
          this.setState({ events: events });
        }
      });
    }
  };

  selectStudent = (e, data) => {
    let filterStudentId = null;
    if (data.value) filterStudentId = data.value;

    localStorage.setItem("bac_filterStudentId", data.value);

    this.setState(
      {
        filterStudentId: filterStudentId,
      },
      () => {
        this.getEvent();
      }
    );
  };
  getDetail = (id) => {
    getEventDetail(id).then((res) => {
      if (res && res.status === 200) {
        if (res.data) this.setState({ eventDetail: res.data, open: true });
      }
    });
  };

  onClickCancel = () => {
    this.setState({ open: false, eventDetail: null });
  };

  render() {
    const state = this.state;
    return (
      <div style={{ padding: "10px" }}>
        {state.canSearch && (
          <Form>
            <Form.Select
              search
              width="4"
              placeholder="Search..."
              value={state.filterStudentId}
              options={state.option_student}
              onSearchChange={this.searchStudent}
              onChange={this.selectStudent}
              selectOnBlur={false}
              clearable
            />
            <br />
          </Form>
        )}

        <BigCalendar
          events={state.events}
          eventClicked={this.handleShowDetailEvent}
          disableClick={this.props.disableClick}
        />
        <ModalForm
          open={state.open}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em",
            padding: "5px",
          }}
          size="small"
          content="Reservation"
          close={this.onClickCancel}
        >
          <EventDetailForm
            disableClick={this.props.disableClick}
            data={state.eventDetail}
          />
        </ModalForm>
      </div>
    );
  }
  handleShowDetailEvent = (data) => {
    this.getDetail(data.id);
  };
}

export default withRouter(scheduleById);
