import axios from "axios";
import * as config from "../../config";


export function getReportoffenses(id) {
  return axios
    .get(config.urlAPI + "/reportoffenses/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postReportoffenses(data) {
  return axios
    .post(config.urlAPI + "/reportoffenses" ,data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}



export function updateReportoffenses(id,data) {
    return axios
    .put(
      config.urlAPI + "/reportoffenses/" + id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportLogbookSP(id) {
  return axios
    .get(config.urlAPI + "/totallogbookSP/export/" + id, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getOverallProgress(id) {
  return axios
    .get(config.urlAPI + "/overallProgress/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getFlighthoursProgress(id) {
  return axios
    .get(config.urlAPI + "/flighthoursProgress/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getFlighttrainigProgress(id) {
  return axios
    .get(config.urlAPI + "/flighttrainigProgress/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getModuleProcess(data) {
  return axios
    .get(
      config.urlAPI +
        "/subjectProgress/" +
        data.id +
        "/" +
        data.courseId +
        "/" +
        data.moduleId,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getModuleChecklist(data) {
  return axios
    .get(
      config.urlAPI +
        "/checklistProgress/" +
        data.id +
        "/" +
        data.courseId +
        "/" +
        data.moduleId,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTotalLogbookSP(id, qString) {
  return axios
    .get(
      config.urlAPI + "/gettotallogbookSP/" + id + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getHospital() {
  return axios
    .get(config.urlAPI + "/personnel/hospital", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseSubjectDetail() {
  return axios
    .get(config.urlAPI + "/subjectByCourseModule", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getGroundSubject() {
  return axios
    .get(config.urlAPI + "/subject?type=G", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
// export function getGroundSubject() {
//   return axios
//     .get(config.urlAPI + "/coursemaster", config.headerCallAPI)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
export function getCheckSubject() {
  return axios
    .get(config.urlAPI + "/subject?checkSubject=true", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightGroup() {
  return axios
    .get(config.urlAPI + "/flightgroup", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTrainingCourse(id) {
  return axios
    .get(config.urlAPI + "/instuctorTrainingCourse/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postTrainingCourse(id, data) {
  return axios
    .post(
      config.urlAPI + "/instuctorTrainingCourse/" + id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateTrainingCourse(id, data) {
  return axios
    .put(
      config.urlAPI + "/instuctorTrainingCourse/" + id,
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function deleteCheckCourse(id) {
  return axios
    .delete(config.urlAPI + "/instuctorTrainingCheckSubject/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postCheckCourse(data) {
  return axios
    .post(config.urlAPI + "/instuctorTrainingCheckSubject", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function deleteTrainingSubject(id) {
  return axios
    .delete(config.urlAPI + "/instuctorTrainingSubject/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postTrainingSubject(data) {
  return axios
    .post(config.urlAPI + "/instuctorTrainingSubject", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postFlightGroup(data) {
  return axios
    .post(config.urlAPI + "/instuctorTrainingCheckFlightGroup", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteFlightGroup(id) {
  return axios
    .delete(config.urlAPI + "/instuctorTrainingCheckFlightGroup/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//UPLOAD IMAGE MED SPL
export function getSpl(id) {
  return axios
    .get(config.urlAPI + "/personnelspl/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postSpl(id, data) {
  return axios
    .post(config.urlAPI + "/personnelspl/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function uploadImageSpl(id, data) {
  return axios
    .post(
      config.urlAPI + "/personnelspl/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageSpl(id) {
  return axios
    .delete(
      config.urlAPI + "/personnelspl/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getMed(id) {
  return axios
    .get(config.urlAPI + "/personnelmedical/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postMed(id, data) {
  return axios
    .post(config.urlAPI + "/personnelmedical/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function uploadImageMed(id, data) {
  return axios
    .post(
      config.urlAPI + "/personnelmedical/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageMed(id) {
  return axios
    .delete(
      config.urlAPI + "/personnelmedical/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//docs
export function getPersonnelDoc(id) {
  return axios
    .get(config.urlAPI + "/personneldoc/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function uploadDoc(id, data) {
  return axios
    .post(
      config.urlAPI + "/personneldoc/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateDoc(id, data) {
  return axios
    .post(config.urlAPI + "/personneldoc/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteDoc(id) {
  return axios
    .delete(
      config.urlAPI + "/personneldoc/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//dcp
export function getDcp(id) {
  return axios
    .get(config.urlAPI + "/personneldcp/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postDcp(id, data) {
  return axios
    .post(config.urlAPI + "/personneldcp/" + id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function uploadImageDCP(id, data) {
  return axios
    .post(
      config.urlAPI + "/personneldcp/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageDCP(id) {
  return axios
    .delete(
      config.urlAPI + "/personneldcp/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
//timeline
export function getTimeline(id) {
  return axios
    .get(config.urlAPI + "/personneltimeline/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function uploadImageTimeline(id, data) {
  return axios
    .post(
      config.urlAPI + "/personneltimeline/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageTimeline(id) {
  return axios
    .delete(
      config.urlAPI + "/personneltimeline/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
