import React, { Component } from "react";
import { Form, Button, Grid, Icon, Divider, Header } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderField from "../../components/renderField";
import renderTextAreaField from "../../components/renderTextAreaField";
import * as config from "../../config";
import Wrap from "../../components/wrap";

const validate = values => {
  const errors = {};
    if (!values.name) errors.name = true;
  return errors;
};

class Formrole extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      readOnly,
      initialValues
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        {readOnly ? (
          <Header as="h2" icon textAlign="center">
            <Icon name="users" circular />
            <Header.Content>
              {initialValues && initialValues.name}
            </Header.Content>
          </Header>
        ) : (
          <Field
            name="name"
            width="16"
            label="Name"
            component={renderField}
            required={true}
            placeholder="Name"
          />
        )}

        <Field
          name="description"
          width="16"
          readOnly={
            initialValues && initialValues.name === "Administrator"
              ? true
              : false
          }
          label="Description"
          component={renderTextAreaField}
          placeholder="Description"
        />
        <br />
        {/* <Grid> */}
        {config.permission.map(group => (
          <div>
            <Divider horizontal>
              <Header as="h4">
                {/* <Icon name="bar chart" /> */}
                {group.group}
              </Header>
            </Divider>
            <Grid>
              <Grid.Row columns="2">
                {group.permission.map(item => (
                  <Grid.Column style={{ paddingBottom: "10px" }}>
                    <Field
                      readOnly={
                        initialValues && initialValues.name === "Administrator"
                          ? true
                          : false
                      }
                      name={`permission.${group.group}.${item.name}`}
                      width="16"
                      label={<label>{item.label}</label>}
                      component={renderCheckboxField}
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
            </Grid>
          </div>
        ))}
        <br />
        {initialValues && initialValues.name === "Administrator" ? (
          <Button
            floated="right"
            type="button"
            content="Cancel"
            compact
            color="green"
            basic
            onClick={this.props.onCancel}
          />
        ) : (
          <Wrap>
            <Button
              floated="left"
              type="submit"
              content="Save"
              compact
              loading={loading}
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              compact
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Wrap>
        )}
      </Form>
    );
  }
}

export default Formrole = reduxForm({
  // a unique name for the form
  form: "roles",
  validate,
  enableReinitialize: true
})(Formrole);
