import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Loader,
  Table,
  Icon,
  Form,
  Button,
  Grid,
  List,
  Card,
  Header
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import ModalForm from "../../components/modal";
import { getModuleChecklist,getModuleTimeline, getSubjectByModule } from "./service";
import moment from 'moment'
class ModuleDetail extends Component {
  state = {
    id: null,
    progress_checklist: null,
    openChecklist: false
  }
  onClickChecklist = (data) => {
    console.log('daa',data);
    let option_subjectByModule = []
    getSubjectByModule(this.state.id).then(res=>{
      if(res && res.status === 200){
        res.data.flightSubjects.map(item=>{
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            })
        })
        res.data.groundSubjects.map((item) => {
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.name,
                text: item.name,
                value: item.id
            })
        })
        this.setState({ option_subjectByModule, openChecklist: true })
      }
    })
  }
  onChange = (e, data) => {
      this.setState({ [data.name]: data.value })
  }
  saveAddSubjectChecklist = () =>{
    const { select_subject } = this.state
    console.log('saveAddSubjectChecklist',select_subject);
    if (!select_subject) {
        this.setState({ errorSubject: true })
        return;
    } else {
        this.setState({ errorSubject: false })
    }
  }
  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps',nextProps);
    // this.setState({progress_checklist: null})
    if (nextProps.id !== this.state.id) {
      if (nextProps.id) {
        let result = {
          courseId: this.props.courseId,
          moduleId: nextProps.id
        }
        getModuleChecklist(result).then(res=>{
            if(res && res.status === 200){
              console.log('componentWillReceiveProps',res.data);
              this.setState({progress_checklist: res.data, id: nextProps.id})
            }
        })
      }
    }
  }
  componentDidMount(){
    let result = {
      courseId: this.props.courseId,
      moduleId: this.props.id
    }
    getModuleChecklist(result).then(res=>{
        if(res && res.status === 200){
          console.log('componentDidMount',res.data);
          this.setState({progress_checklist: res.data, id: res.data.id})
        }
    })
    // getModuleChecklist()
  }
  render() {
    const props = this.props;
    const state = this.state;
    console.log('module Detail',this.state.progress_checklist);
    return (
      <Wrap>
          <ModalForm
            open={state.openChecklist}
            icon="book"
            content="Subject"
            close={() => this.setState({openChecklist: false})}
          >
          <Form onSubmit={this.saveAddSubjectChecklist}>
              <Form.Select label='Subject' search clearable multiple
                  name="select_subject"
                  error={state.errorSubject}
                  onChange={(e, value) => this.onChange(e, value)}
                  options={state.option_subjectByModule}
                  >
              </Form.Select>
              <br />
              <Button
                  floated="left"
                  type="submit"
                  content="save"
                  color="green"
                  loading={state.activeLoader}
              />
              <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  color="green"
                  basic
                  onClick={this.close}
              />
          </Form>
          </ModalForm>
          { state.progress_checklist && (
          <Wrap>
            <Header as='h2' textAlign='center'>
             {state.progress_checklist.name} {state.progress_checklist.desc && <span>({state.progress_checklist.desc})</span>}
            <Header.Subheader>
             {state.progress_checklist.moduleStart || state.progress_checklist.moduleEnd ?
               (`${moment(state.progress_checklist.moduleStart,'DD/MM/YYYY').format(
                 "D MMMM YYYY"
               )} - ${moment(state.progress_checklist.moduleEnd,'DD/MM/YYYY').format(
                 "D MMMM YYYY"
               )}`)
               : null
             }
             </Header.Subheader>
            </Header>
            <br/>
            <Grid>
              <Grid.Row columns={this.state.progress_checklist ? this.state.progress_checklist.checklist.length : 'equal'}>
                { this.state.progress_checklist &&
                  this.state.progress_checklist.checklist.length > 0 &&
                  this.state.progress_checklist.checklist.map(chk =>
                    <Grid.Column>
                      <Card fluid
                        style={{backgroundColor: chk.check_checklist ? '#E32F21':'#F0F2EB'}}
                        // onClick={() => this.onClickChecklist(chk)}
                        >
                        <Card.Content>
                          <Card.Header style={{color: chk.check_checklist ? '#FFFFFF':'#000000'}}>{chk.name}</Card.Header>
                          <p style={{color: chk.check_checklist ? '#FFFFFF':'#000000'}}>
                            {`${moment(chk.start,'DD/MM/YYYY').format(
                              "D MMMM YYYY"
                            )} - ${moment(chk.end,'DD/MM/YYYY').format(
                              "D MMMM YYYY"
                            )}`}
                          </p>
                          <p style={{color: chk.check_checklist ? '#FFFFFF':'#000000' }}>
                            Subject: {chk.subject}  Diff: {chk.diff}
                          </p>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  )
                }
              </Grid.Row>
            </Grid>
          </Wrap>)
        }
          <br/>
        {/*<Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4" textAlign="center">Progrss Checklist</Table.HeaderCell>
              <Table.HeaderCell width="2" textAlign="center">
                Start - End
              </Table.HeaderCell>
              <Table.HeaderCell width="2" textAlign="center">
                Diff
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Subject</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            { this.state.progress_checklist ?
              this.state.progress_checklist.checklist.length > 0 &&
              this.state.progress_checklist.checklist.map(chk =>
                <Table.Row>
                  <Table.Cell className="bold">
                   {chk.name}
                  </Table.Cell>
                  <Table.Cell >
                   {chk.start} - {chk.end}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                   {chk.diff}
                  </Table.Cell>
                  <Table.Cell>
                  </Table.Cell>
                </Table.Row>
              ) : <Table.Row>
                <Table.Cell textAlign="left" colSpan="2">
                  Loading..
                </Table.Cell>
              </Table.Row>
            }
          </Table.Body>
        </Table>*/}
        <Table celled compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4"></Table.HeaderCell>
              <Table.HeaderCell width="2" textAlign="center">
                Hours
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Subject</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell
                className="bold"
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              >
                Ground Subject
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              >
                {props.data.groundHours}
              </Table.Cell>
              <Table.Cell>
                <Grid columns="2" style={{ padding: "20px", fontSize: "95%" }}>
                  {props.data.groundSubjects.map(item => (
                    <Grid.Column
                      style={{ paddingBottom: "4px", paddingTop: "4px" }}
                    >
                      {item.name} {item.hours && `  (${item.hours})`}
                    </Grid.Column>
                  ))}
                </Grid>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                className="bold"
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              >
                Flight Subject
              </Table.Cell>
              <Table.Cell
                textAlign="center"
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              >
                {props.data.flightHours}
              </Table.Cell>
              <Table.Cell>
                <Grid columns="2" style={{ padding: "20px", fontSize: "95%" }}>
                  {props.data.flightSubjects.map(item => (
                    <Grid.Column
                      style={{ paddingBottom: "4px", paddingTop: "4px" }}
                    >
                      {item.code} {item.name} {item.hours && `  (${item.hours})`}
                    </Grid.Column>
                  ))}
                </Grid>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell
                className="bold"
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              >
                Flight Additional Subject
              </Table.Cell>

              <Table.Cell
                verticalAlign="top"
                style={{ paddingTop: "20px" }}
              ></Table.Cell>
              <Table.Cell>
                <Grid columns="2" style={{ padding: "20px", fontSize: "95%" }}>
                  {props.data.flightAddSubjects.map(item => (
                    <Grid.Column
                      style={{ paddingBottom: "4px", paddingTop: "4px" }}
                    >
                      {item.code} {item.name} {item.hours && `  (${item.hours})`}
                    </Grid.Column>
                  ))}
                </Grid>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Wrap>
    );
  }
}

export default withRouter(ModuleDetail);
