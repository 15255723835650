import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Image,
  Grid,
  Breadcrumb
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import * as config from "../../config";
import ImageCard from "../../components/imagecard";
import MainButton from "../../components/mainbutton";
import Wrap from "../../components/wrap";
import TableList from "../../components/table";
import { getStudentListBySearch } from "../Student/service";
import Portfolio from "../People/portfolio";
import moment from "moment";
import { getStudentById, getCourseById } from "../Student/service";
import { getCheckListByCourseID, getProgressCheckListDetail, getSnagById,getAttendanceById } from "./service"

class PortfolioProfile extends Component {
  state = {
    data: null,
    rawData: null,
    loading: true,
    dataCourse: [],
    checklist: null,
    courseDetail: null,
    snag: null
  };
  getSnag = qString => {
    getSnagById(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ snag: res.data });
      }
    });
  };
  getAttendance = qString => {
    getAttendanceById(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ attendance: res.data });
      }
    });
  };
  getCourse = qString => {
    getCourseById(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ dataCourse: res.data });
      }
    });
  };
  getCheckListDetail = id => {
    getProgressCheckListDetail(id).then(res => {
      if (res && res.status === 200) {
        this.setState({ courseDetail: res.data });
      }
    });
  };
  getDataStudentById = (id) => {
    getStudentById(id).then(res => {
      if (res && res.status === 200) {
        console.log(res.data);
        this.setState({ data: res.data})
        if (res.data.course) {
          let qString = "?id=" + res.data.course.id;
          this.getCourse(qString);
        }
        // let qString = "?id=" + res.data.course.id;
        // res.data.course.map(item => (qString += "?id=" + item.id + "&"));
        // this.getCourse(qString);
        // this.getCheckListDetail(id)
        // this.getCheckList(res.data.course.id);
        // this.getSnag(id);
      }
    });
  };

  getCheckList = id => {
    getCheckListByCourseID(id).then(res => {
      if (res && res.status === 200) {
        this.setState({ checklist: res.data });
      }
    });
  }
  componentDidMount() {
    this.getDataStudentById(this.props.location.state.id);
    this.getSnag(this.props.location.state.id)
    this.getAttendance(this.props.location.state.id)
  }

  render() {
    let state = this.state;
    if (state.data === null) return "Loading";
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name='Portfolio' >
            {/* <Button basic size='small' content='Edit Profile' onClick={this.onClickEdit} /> */}
          </HeaderWithMenu>
          {!this.props.location.state.status  &&
            <Breadcrumb>
              <Breadcrumb.Section
                onClick={() => this.props.history.push('/Portfolio')}
                style={{ color: 'grey' }}>
                Portfolio
                      </Breadcrumb.Section>
              <Breadcrumb.Divider icon='right angle' />
              <Breadcrumb.Section active>Portfolio Profile</Breadcrumb.Section>
            </Breadcrumb>
          }
          <br /> <br />
          {/* <Segment> */}
          <Grid>
            <Grid.Row columns={2} divided style={{ paddingBottom: 0 }}>
                <Grid.Column width={4} >
                    <Image
                        centered
                        src={
                          state.data.imageURL ? state.data.imageURL+'?time='+ new Date() :
                          'https://app.flightschedulepro.com/images/img-01.png'
                        }
                        onError={(e) => { e.target.onerror = null; e.target.src = 'https://app.flightschedulepro.com/images/img-01.png' }}
                    />
                </Grid.Column>
                <Grid.Column width={12}>
                    {/* <Header as='h2' style={{ color: config.colorTheme }}>{state.data.studentId + ' ' + state.data.firstName + ' ' + state.data.lastName + ' ' + state.data.customerCode}</Header> */}
                    <Header as="h2" style={{ color: config.colorTheme }}>
                        <Header.Content>
                            {state.data.studentId && state.data.studentId}&nbsp;&nbsp;
                            {state.data.firstNameEng && state.data.firstNameEng}&nbsp;&nbsp;
                            {state.data.lastNameEng && state.data.lastNameEng}&nbsp;&nbsp;
                            {state.data.customerCode && state.data.customerCode}
                            <Header.Subheader style={{ color: config.colorTheme }}>
                                {state.data.firstNameTh && state.data.firstNameTh} &nbsp;&nbsp;
                                {state.data.lastNameTh && state.data.lastNameTh} &nbsp;&nbsp;
                                {state.data.nickName
                                  ? "( " + state.data.nickName + " )"
                                  : ""}
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>

                                {/* <br /> */}
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width='5'> <p className="bold">Nationality</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.foreignerCountry ? state.data.foreignerCountry : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Phone</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.phoneNumber ? state.data.phoneNumber : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">BAC Email</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.emailAddress ? state.data.emailAddress : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Personnel Email</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.personnel_email ? state.data.personnel_email : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Username</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.username ? state.data.username : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Course</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.course ?  `${state.data.course.name } (${state.data.course.desc})` : '-'}
                                          </Grid.Column>
                                    </Grid.Row>
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Groups</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.groups ? state.data.groups.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Class</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.class ? state.data.class.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Student Type</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.studentType ? state.data.studentType.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Company</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.company ? state.data.company.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Discipline Score</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.disciplineScore ? state.data.disciplineScore + "/100" : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Categories</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {
                                                state.data.categories.length > 0 ?
                                                state.data.categories.map(item =>
                                                    <span key={item.name}> {item.name} <br /> </span>
                                                ) : '-'
                                            }
                                        </Grid.Column>
                                    </Grid.Row> */}
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Grid>
                                    {/* <Grid.Row>
                                        <Grid.Column width='5'> <p className="bold">Advisor</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.soloStudent ? state.data.soloStudent.firstName + " " + state.data.soloStudent.lastName : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    <Grid.Row >
                                        <Grid.Column width='5'> <p className="bold">Company</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.company ? state.data.company.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Discipline Score</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.disciplineScore ? state.data.disciplineScore + "/100" : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Hospital</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.hospital ? state.data.hospital.name : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Medical class</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.medicalClass ? state.data.medicalClass : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Date of Birth</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.birthDate ? moment(state.data.birthDate, 'DD/MM/YYYY').format(
                                                "DD MMMM YYYY"
                                            ) : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Gender</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.gender ? state.data.gender : '-'}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Status</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.isActive == true || state.data.isActive == "true" ? (
                                                <p>Active</p>
                                            ) : (
                                                    <p>Inactive</p> || "-"
                                                )}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Last Login</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.lastLogin ?
                                                state.data.lastLogin : '-'
                                                // moment(state.data.lastLogin).format("DD MMMM YYYY HH:mm") : '-'
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Created</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.createdAt ?
                                                state.data.createdAt : '-'
                                                // moment(state.data.createdAt).format("DD MMMM YYYY HH:mm") : '-'
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                    {/* <Grid.Row style={{ paddingTop: 0 }}>
                                        <Grid.Column width='5'> <p className="bold">Discipline Score</p> </Grid.Column >
                                        <Grid.Column width='11'>
                                            {state.data.disciplineScore ? state.data.disciplineScore + "/100" : '-'}
                                        </Grid.Column>
                                    </Grid.Row> */}
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Portfolio
                  course={state.dataCourse}
                  coursedetail={state.courseDetail}
                  checklist={state.checklist}
                  snag={state.snag}
                  attendance={state.attendance}
                  personnelId={this.props.location.state.id}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {/* </Segment> */}
        </Segment>
      </div >
    );
  }
}

export default PortfolioProfile;
