import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Grid, Header, Button } from 'semantic-ui-react'

class Page404 extends Component {


    render() {
        return (
            <Grid>
                <Grid.Column width={16} textAlign="center">
                    <br /><br />
                    <Header color="grey" style={{ fontSize: '6em' }}>404</Header>
                    <Header color="grey" as='h3'>
                        Something is wrong<br />
                        The page you are looking for was moved, renamed or might neber exicted.
                    </Header>
                    <br />
                    <br />
                    <Button onClick={() => this.props.history.push('/')}>GO HOME</Button>
                </Grid.Column>
            </Grid>
        )
    }
}

export default withRouter(Page404)

