import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderCheckboxField from "../../components/renderCheckboxField";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = true;

  return errors;
};

class FormgroundSubjects extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      loading,
      options_course,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Form.Group unstackable>
          <Field
            name="code"
            // required={true}
            width="4"
            label="Code"
            component={renderField}
          />
          <Field
            name="name"
            type="text"
            required={true}
            width="12"
            component={renderField}
            label="Ground Subjects name"
          />
        </Form.Group>
        {/* <Field
          name="name"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Ground Subjects name"
        /> */}
        <Field
          name="hours"
          width="4"
          type="number"
          mix={0}
          label="Ground (Hours)"
          component={renderField}
        />
        <Field
          name="course"
          multiple
          options={options_course}
          component={renderSelectField}
          label="Reference Course"
        />
        <Field
          name="material"
          label="Material Requirement"
          component={renderTextAreaField}
        />
        <Field
          name="checkSubject"
          // width='3'
          label={
            <label>
              {" "}
              <b>GROUND CHECK</b>
            </label>
          }
          component={renderCheckboxField}
        />
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }
}

export default FormgroundSubjects = reduxForm({
  // a unique name for the form
  form: "groundsubjects",
  validate,
  enableReinitialize: true,
})(FormgroundSubjects);
