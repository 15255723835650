import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import * as config from '../../config'
import validate from '../../validate'
import renderField from '../../components/renderField'
import renderSelectField from '../../components/renderSelectField'
import renderTextAreaField from '../../components/renderTextAreaField'
import renderRadioField from '../../components/renderRadioField'
import renderDateField from '../../components/renderDateField'


class FormSnags extends Component {

    state = {
        aircraft: this.props.aircraft,
        selected: false,
        option_aircraft: [],
    }

    componentDidMount() {
        if (this.props.aircraft) {
            this.setState({ selected: true })
        }
    }


    render() {
        const state = this.state
        const { pristine, submitting, option_aircraft } = this.props
        return (
            <Form  >
                {/* <Form onSubmit={handleSubmit(this.onClickSave)}> */}
                {
                    state.selected
                        ? null
                        : <Field
                            name="aircraft" width="16"
                            required={true} component={renderSelectField}
                            search={true} placeholder='Please Select'
                            clearable={true}
                            label="Aircraft" options={option_aircraft} />
                }

                <Field
                    label='Describe the issue'
                    name="aircraftx" width="16"
                    required={true} component={renderTextAreaField}
                />

                <Field name="canBeOverridden"
                    width="11"
                    label='Ground the Aircraft'
                    component={renderRadioField} />

                <h4 style={{ color: config.colorTheme }}>Status & Resolution </h4>
                <Field
                    name="aircraft" width="16"
                    required={true} component={renderSelectField}
                    search={true} placeholder='Please Select'
                    clearable={true}
                    label="Status" options={state.option_aircraft} />

                <Field
                    label='Action Taken'
                    name="aircraftx" width="16"
                    required={true} component={renderTextAreaField}
                />
                <Form.Group widths='equal'>
                    <Field name="start" maxDate={new Date()}
                        component={renderDateField} label="Date Corrected" />
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Corrected By" />
                </Form.Group>
                <Field
                    name="aircraftx"
                    required={true} component={renderField}
                    label="Corrected By Certificate #" />

                <Form.Group widths='equal'>
                    <Field name="start2" maxDate={new Date()}
                        component={renderDateField} label="Date Inspected" />
                    <Field
                        name="aircraftx"
                        required={true} component={renderField}
                        label="Inspected By" />

                </Form.Group>
                <Field
                    name="aircraftx"
                    required={true} component={renderField}
                    label="Inspected By Certificate #" />
                <br />
                <Button floated='left' type="submit" compact content='Save' color='green' disabled={pristine || submitting} />
                <Button floated='right' type="button" compact content='Cancel' color='green' basic onClick={this.props.onCancel} />
            </Form>

        )
    }
}

export default FormSnags = reduxForm({
    // a unique name for the form
    form: 'snags',
    validate,
    enableReinitialize: true
})(FormSnags)
