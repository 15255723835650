import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Button } from 'semantic-ui-react'
import * as config from '../../config'
import moment from 'moment'
class RemindersDetail extends Component {

    render() {
        const data = this.props.data
        console.log(data)
        return (
            <Form>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Aircraft</Form.Field>
                    <Form.Field width='10' className='bold'>{`${data.aircraftTailNumber} ${data.aircraftMake} ${data.aircraftModel}`} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Create By</Form.Field>
                    <Form.Field width='10' className='bold'>{data.createdBy} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Snag</Form.Field>
                    <Form.Field width='10' className='bold'>{data.discrepancy} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Ground the Aircraft</Form.Field>
                    <Form.Field width='10' className='bold'>{data.isGroundable ? 'Yes' : 'No'} </Form.Field>
                </Form.Group>

                <h4 style={{ color: config.colorTheme }}>Status & Resolution</h4>

                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Status</Form.Field>
                    <Form.Field width='10' className='bold'>{data.isClosed ? '(Closed)' : null} {data.resolution} </Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Date Corrected</Form.Field>
                    <Form.Field width='10' className='bold'>{moment(data.createdAt).format('DD/MM/YYYY')}</Form.Field>
                </Form.Group>
                <Form.Group unstackable>
                    <Form.Field width='6' style={{ textAlign: 'right' }}>Date Inspected</Form.Field>
                    <Form.Field width='10' className='bold'>{moment(data.inspectedAt).format('DD/MM/YYYY')}</Form.Field>
                </Form.Group>
                <br />
                <Button floated='left' type="submit" content='Edit' color='green' />
                <Button floated='right' type="button" content='Cancel' color='green' basic onClick={this.props.onCancel} />
            </Form>

        )
    }
}

export default withRouter(RemindersDetail)
