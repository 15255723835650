import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import * as config from '../../config'
import validate from '../../validate'
import Selectcolor from '../../components/selectcolor'

import renderField from '../../components/renderField'
import renderSelectField from '../../components/renderSelectField'
import renderRadioField from '../../components/renderRadioField'
import Wrap from '../../components/wrap';

class ActivitypeForm extends Component {

    state = {
        compbtn: {
            background: '#000000',
            color: '#FFFFFF'
        },
        chkbtn: {
            background: '#000000',
            color: '#FFFFFF'
        },
        resvbtn: {
            background: '#000000',
            color: '#FFFFFF'
        },
        optionsLayout: [
            { key: 'af0', value: '0', text: 'Rental / Instruction' },
            { key: 'af1', value: '1', text: 'Maintenance' },
            { key: 'af2', value: '2', text: 'Class' },
            { key: 'af3', value: '3', text: 'Meeting' },
        ],
    }
    colorChange = (row, name, value) => {
        if (row == 'comp') {
            this.setState(prevState => {
                prevState = JSON.parse(JSON.stringify(this.state.compbtn));
                prevState[name] = value;
                return { compbtn: prevState }
            })
        }
        if (row == 'chk') {
            this.setState(prevState => {
                prevState = JSON.parse(JSON.stringify(this.state.chkbtn));
                prevState[name] = value;
                return { chkbtn: prevState }
            })
        }
        if (row == 'resv') {
            this.setState(prevState => {
                prevState = JSON.parse(JSON.stringify(this.state.resvbtn));
                prevState[name] = value;
                return { resvbtn: prevState }
            })
        }

    }
    render() {
        const state = this.state
        const { handleSubmit, pristine, submitting, layout } = this.props
        console.log('layout select: ', layout)
        return (
            // <Form>
            <Form onSubmit={handleSubmit}>
                <Field name="name" type="text" required={true}
                    component={renderField} label="Activity Name" />
                <Field name="Active"
                    component={renderRadioField} label="Active" />
                <Field
                    name="templateType" label="Layout"
                    required={true} component={renderSelectField}
                    options={state.optionsLayout} />
                <Form.Group>
                    <Form.Field width='4'>
                        <label style={{ textAlign: 'right' }}>Reserved Color</label>
                    </Form.Field>
                    <Field name="reservationBackground"
                        component={Selectcolor} onChange={(e) => this.colorChange('resv', 'background', e)} />
                    <Form.Field>
                        <label>Font:</label>
                    </Form.Field> &nbsp;&nbsp;
                    <Field name="reservationForeground"
                        component={Selectcolor} onChange={(e) => this.colorChange('resv', 'color', e)} />
                    <Button style={state.resvbtn} size='mini' >Sample</Button>
                </Form.Group>
                {
                    layout === '0' || layout === '1'
                        ? <Form.Group>
                            <Form.Field width='4'>
                                <label style={{ textAlign: 'right' }}>Checked-Out Color</label>
                            </Form.Field>
                            <Field name="dispatchedBackground"
                                component={Selectcolor} onChange={(e) => this.colorChange('chk', 'background', e)} />
                            <Form.Field>
                                <label>Font:</label>
                            </Form.Field> &nbsp;&nbsp;
                        <Field name="dispatchedForeground"
                                component={Selectcolor} onChange={(e) => this.colorChange('chk', 'color', e)} />
                            <Button style={state.chkbtn} size='mini'>Sample</Button>
                        </Form.Group>
                        : null
                }
                {
                    layout === '2'
                        ? <Field name="trackInstructionEnabled" 
                            component={renderRadioField} label="Track Instruction Time" />
                        : null
                }
                {
                    layout === '0' || layout === '2' || layout === '1'
                        ? <Form.Group>
                            <Form.Field width='4'>
                                <label style={{ textAlign: 'right' }}>Completed Color</label>
                            </Form.Field>
                            <Field name="completedBackground"
                                component={Selectcolor} onChange={(e) => this.colorChange('comp', 'background', e)} />
                            <Form.Field>
                                <label>Font:</label>
                            </Form.Field> &nbsp;&nbsp;
                        <Field name="completedForeground"
                                component={Selectcolor} onChange={(e) => this.colorChange('comp', 'color', e)} />
                            <Button style={state.compbtn} size='mini'>Sample</Button>
                        </Form.Group>
                        : null
                }
                <Field
                    name="roleallowed" label="Roles Allowed"
                    required={true} component={renderSelectField}
                    options={state.optionsLayout} placeholder="Please Select" />
                {
                    layout === '0' || layout === '2'
                        ? <Field
                            name="instructionTypes" label="Instruction Types"
                            required={true} component={renderSelectField}
                            options={state.optionsLayout} placeholder="Please Select" />
                        : null
                }
                {
                    layout === '0' || layout === '1'
                        ? <Wrap>
                            <Field name="minimumLength" type="number" label="Minimum Length"
                                width="5" required={true}
                                component={renderField} labelDesc="Minutes" />
                            <Field name="maximumLength" type="number" label="Max Length"
                                width="5" required={true}
                                component={renderField} labelDesc="Minutes" />
                            <Field name="defaultLength" type="number" label="Default Length"
                                width="5" required={true}
                                component={renderField} labelDesc="Minutes" />
                        </Wrap> : null
                }
                <br />
                <Button floated='left' type="submit" content='Save' color='green' disabled={pristine || submitting} />
                <Button floated='right' type="button" content='Cancel' color='green' basic onClick={this.props.onCancel} />
            </Form>
        )
    }
}
ActivitypeForm = reduxForm({
    // a unique name for the form
    form: 'activitype',
    validate,
    enableReinitialize: true
})(ActivitypeForm)

const selector = formValueSelector('activitype')
ActivitypeForm = connect(
    state => {
        const layout = selector(state, 'templateType')
        return {
            layout: layout || '0',
        }
    }
)(ActivitypeForm)
export default ActivitypeForm