import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Icon, Form, Button } from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import TableList from "../../components/table";
import ModalForm from "../../components/modal";
import MainButton from "../../components/mainbutton";
import Confirmmodal from "../../components/confirmmodal";
import {
  getDisciplineScore,
  postDisciplineScore,
  updateDisciplineScore,
  deleteDisciplineScore
} from "./service";
class Settings extends Component {
  state = {
    loading: true,
    data: [],
    column: [
      {
        Header: "Score",
        accessor: "level",
        style: { fontWeight: "bold", textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <p>
            {cellInfo.original.level === 'light' 
            ? 'ความผิดสถานเบา' 
            : cellInfo.original.level === 'medium'
            ?   'ความผิดสถานกลาง' :
            cellInfo.original.level === 'heavy' 
            ? 'ความผิดสถานหนัก' :'-'
            }
          </p>
        ),
        minWidth: 40
      },
      {
        Header: "Behavior",
        accessor: "name",
        style: { fontWeight: "bold", cursor: "pointer" },
        // style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.name_th}
            </p>
          </span>
        )
      },
       {
        Header: "",
        accessor: "name",
        style: { fontWeight: "bold", cursor: "pointer" },
        // style: { textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
               {cellInfo.original.name} 
            </p>
          </span>
        )
      },
      {
        Header: "Score",
        accessor: "score",
        style: { fontWeight: "bold", textAlign: "center" },
        sortable: false,
        Cell: cellInfo => (
          <p style={{ color:  "red"   }}>
            {cellInfo.original.score}
          </p>
        ),
        minWidth: 40
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: cellInfo => (
          <span>
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        )
      }
    ]
  };

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    getDisciplineScore().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data,
          loading: false
        });
      }
    });
  };

  onClickNew = () => {
    this.setState({
      open: true,
      currentData: null,
      name: null,
      name_th:null,
      score: null,
      level:null
    });
  };

  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, currentData: null, name_th:null,name: null, score: null,level : null });
  };
  onClickEdit = data => {
    this.setState({
      open: true,
      currentData: data,
      name: data.name,
      name_th: data.name_th,
      score: data.score,
      level:data.level
    });
  };
  deleteData = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });
    deleteDisciplineScore(currentData.id).then(res => {
      if (res && res.status === 200) {
        this.getData();
        this.setState({ openConfirm: false });
      }
      this.setState({ loading: false });
    });
  };
  saveData = () => {
    let { currentData, name,name_th, score,level } = this.state;
    if (currentData && currentData.id) {
      let data = {
        id: currentData.id,
        name: name,
        name_th:name_th,
        score: score,
        level:level
      };
      updateDisciplineScore(data).then(res => {
        if (res && res.status === 200) {
          this.getData();
          this.onClickCancel();
        }
      });
    } else {
      let data = {
        image: null,
        name: name,
        name_th:name_th,
        score: score,
        level:level
      };
      postDisciplineScore(data).then(res => {
        if (res && res.status === 201) {
          this.getData();
          this.onClickCancel();
        }
      });
    }
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });  
  } 

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Discipline Score">
            <MainButton size="small" onClick={this.onClickNew} text="Add New" />
          </HeaderWithMenu>
          <TableList
            data={state.data}
            columns={state.column}
            loading={state.loading}
            // rowClick={this.onClickProfile}
          />
          <br />
          <br />
        </Segment>

        {/* Group */}
        <ModalForm
          open={state.open}
          icon="clipboard check"
          content="Discipline Score"
          close={this.onClickCancel}
        >
          <Form>
           <Form.Select
            fluid
            name='level'
            label='Level'
            options={ [
              { key: 'm', text: 'ความผิดสถานเบา', value: 'light' },
              { key: 'f', text: 'ความผิดสถานกลาง', value: 'medium' },
              { key: 'o', text: 'ความผิดสถานหนัก', value: 'heavy' },
            ]}
            value={state.level}
            placeholder='Level'
             onChange={this.handleChange}
          />
            <Form.Input
              placeholder="ความประพฤติ"
              name="name_th"
              // width={13}
              value={state.name_th}
              label="ความประพฤติ"
              onChange={this.handleChange}
            />
             <Form.Input
              placeholder="Behavior"
              name="name"
              // width={13}
              value={state.name}
              label="Behavior"
              onChange={this.handleChange}
            />
            <Form.Input
              placeholder="Score"
              name="score"
              width={6}
              value={state.score}
              label="Score"
              type="number"
              onChange={this.handleChange}
            />
            <br />
            <Button
              floated="left"
              // size="small"
              type="submit"
              content="Save"
              color="green"
              compact
              onClick={this.saveData}
            />
            <Button
              floated="right"
              // size="small"
              type="button"
              content="Cancel"
              color="green"
              basic
              compact
              onClick={this.onClickCancel}
            />
          </Form>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={state.type}
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(Settings);
