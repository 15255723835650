import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Image } from "semantic-ui-react";
import * as config from "../../config";

class QRScan extends Component {

    render() {
        return (
            <Segment className="contentSegment" textAlign="left">
                <Header as="h2" style={{ color: config.colorTheme }}>
                    Aircraft Performance Data
                </Header>
                <object style={{
                    width: '100%',
                    height: '100vh'
                }}
                    data='../storage/template/AIRCRAFT%20PERFORMANCE%20DATA%20Update%2012-05-2021.pdf' type="application/pdf">

                    <iframe
                        src='../storage/template/AIRCRAFT%20PERFORMANCE%20DATA%20Update%2012-05-2021.pdf'
                        frameborder="0"
                    ></iframe>
                </object>

            </Segment>
        );
    }
}

export default withRouter(QRScan);
