import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import {
    Segment, Header, Grid, Label, Dimmer
    , Loader, Form, Table, Button, Icon
} from 'semantic-ui-react'
import moment from 'moment'
import * as config from '../../config'
import TableList from '../../components/table'
import { getLogbookIP, getLogbookSP } from './service';
import Wrap from '../../components/wrap'
import renderField from '../../components/renderField'

class flightHourRecordForm extends Component {
    state = {
        defaultIP: null,
        defaultSP: null,
        moduleIP: [],
        moduleSP: [],
        addIP: [],
        addSP: []
    }
    componentDidMount() {
        this.getData();
    }
    getData = () => {
        getLogbookIP().then(res => {
            if (res && res.status === 200) {
                let arr = []
                res.data.map(v =>
                    arr.push({ key: v.id, text: v.name, value: v })
                )
                this.setState({ options_logbookIP: arr });
            }
        });
        getLogbookSP().then(res => {
            if (res && res.status === 200) {
                let arr = []
                res.data.map(v =>
                    arr.push({ key: v.id, text: v.name, value: v })
                )
                this.setState({ options_logbookSP: arr });
            }
        });
    };
    onChangeDefaultIP = (e, value) => {
        console.log(value)
        this.setState({ defaultIP: value.value })
    }
    onChangeDefaultSP = (e, value) => {
        console.log(value)
        this.setState({ defaultSP: value.value })
    }
    handleAddItemClickDelete = (type, index) => {
        if (type == 'IP') {
            let addIP = this.state.addIP
            addIP.splice(index, 1)
            this.setState({ addIP, })
        } else {
            let addSP = this.state.addSP
            addSP.splice(index, 1)
            this.setState({ addSP, })
        }
    }
    handleItemClickDelete = (type, index) => {
        let { moduleIP, moduleSP } = this.state;
        if (type == 'IP') {
            moduleIP.splice(index, 1)
            this.setState({ moduleIP, })
        } else {
            moduleSP.splice(index, 1)
            this.setState({ moduleSP, })
        }
    }
    onChange = (type, e, data, key) => {
        let { addIP, addSP } = this.state;
        if (type == 'IP') {

            addIP[key] = data.value;
            this.setState({ addIP });
        } else {
            addSP[key] = data.value;
            this.setState({ addSP });
        }

    };
    pushData = (type) => {
        if (type == 'IP') {
            let { addIP, moduleIP } = this.state;
            console.log('pushData', addIP)
            moduleIP = moduleIP.concat(addIP)
            this.setState({ moduleIP, addIP: [] });
        } else {
            let { addSP, moduleSP } = this.state;
            console.log('pushData', addSP)
            moduleSP = moduleSP.concat(addSP)
            this.setState({ moduleSP, addSP: [] });
        }
    }
    handleItemClickAddIP = () => {
        let addIP = this.state.addIP;
        addIP.push({
            id: null,
            name: null,
            group: null,
            editable: true
        });
        this.setState({ addIP });
    };
    handleItemClickAddSP = () => {
        let addSP = this.state.addSP;
        addSP.push({
            id: null,
            name: null,
            group: null,
            editable: true
        });
        this.setState({ addSP });
    };
    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps)
        if (nextProps.logbookIP !== this.state.moduleIP) {
            this.setState({ moduleIP: nextProps.logbookIP })
        }

        if (nextProps.logbookSP !== this.state.moduleSP) {
            this.setState({ moduleSP: nextProps.logbookSP })
        }

        if (nextProps.defaultTime) {
            this.setState({ defaultIP: nextProps.defaultTime, defaultSP: nextProps.defaultTime })
        }

        // if (nextProps.options_lesson !== this.state.options_lesson) {
        //     if (nextProps.options_lesson) {
        //         this.setState({ options_lesson: nextProps.options_lesson })
        //     }
        // }
    }
    render() {
        const state = this.state
        const props = this.props
        // console.log('props', props)
        return (
            <div>
                <Dimmer active={props.loading} inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
                <Form size='mini'>
                    {/* <Grid columns={1}>
                        <Grid.Row>
                            <Grid.Column> */}
                    <Table
                        compact
                        celled
                        striped
                        style={{ marginBottom: "30px" }}
                        unstackable
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="header-table" width={1} />
                                <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={11}>
                                    Logbook IP
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="header-table"
                                    textAlign="center"
                                    width={3}
                                >
                                    <Form.Input width={16} name='defaultIP' value={state.defaultIP}
                                        onChange={(e, value) => this.onChangeDefaultIP(e, value)} />
                                </Table.HeaderCell>
                                <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={1} >
                                    Action
                                            </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {state.moduleIP.map((item, num) =>
                                item.group ? (
                                    <Wrap>
                                        <Table.Row>
                                            <Table.Cell textAlign="right">
                                                {num + 1}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="bold"
                                            // style={{ fontSize: "90%" }}
                                            >
                                                <p>{item.name}</p>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center"></Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    link
                                                    color="red"
                                                    name="trash alternate"
                                                    onClick={() => this.handleItemClickDelete('IP', num)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        {item.group.map((sub, index) => (
                                            <Table.Row>
                                                <Table.Cell textAlign="right" />
                                                <Table.Cell
                                                    className="bold"
                                                    style={{
                                                        // fontSize: "90%",
                                                        paddingLeft: "15px"
                                                    }}
                                                >
                                                    <li>{sub.name}</li>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <Form.Input width={16} name={`logbookIP.${sub.id}`}
                                                        value={state.defaultIP} />
                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Wrap>
                                ) : (
                                        <Table.Row>
                                            <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                                            <Table.Cell
                                                className="bold"
                                            // style={{ fontSize: "90%" }}
                                            >
                                                <p>{item.name}</p>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Form.Input width={16} name={`logbookIP.${item.id}`}
                                                    value={state.defaultIP} readOnly />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    link
                                                    color="red"
                                                    name="trash alternate"
                                                    onClick={() => this.handleItemClickDelete('IP', num)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            )}
                            {state.addIP.map((item, index) => (
                                <Table.Row>
                                    <Table.Cell textAlign="right"></Table.Cell>
                                    <Table.Cell >
                                        <Form.Group style={{ marginBottom: "0px" }}>
                                            <Form.Select
                                                onChange={(e, value) => this.onChange('IP', e, value, index)}
                                                options={state.options_logbookIP}></Form.Select>
                                            <Button
                                                animated="vertical"
                                                size="mini"
                                                color="green"
                                                // loading={state.loadingUp}
                                                onClick={() => this.pushData('IP')}
                                            >
                                                <Button.Content hidden>Update</Button.Content>
                                                <Button.Content visible>
                                                    <Icon name="check" />
                                                </Button.Content>
                                            </Button>
                                        </Form.Group>
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        <Form.Input width={16} name={`logbookIPAdd.${index.id}`}
                                            value={state.defaultIP} readOnly />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            link
                                            color="red"
                                            name="trash alternate"
                                            onClick={() => this.handleAddItemClickDelete('IP', index)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                            <Table.Row>
                                <Table.Cell className="footer-table" textAlign="right"></Table.Cell>
                                <Table.Cell className="footer-table"></Table.Cell>
                                <Table.Cell className="footer-table"></Table.Cell>
                                <Table.Cell className="footer-table" textAlign="right">
                                    <Button
                                        color="blue"
                                        size="small"
                                        compact
                                        type="button"
                                        content="Add"
                                        onClick={() => this.handleItemClickAddIP('logbookIP')}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {/* </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                            <Grid.Column> */}
                    <Table
                        compact
                        celled
                        striped
                        style={{ marginBottom: "30px" }}
                        unstackable
                    >
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="header-table" width={1} />
                                <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={11}>
                                    Logbook SP
                                            </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="header-table"
                                    textAlign="center"
                                    width={3}
                                >
                                    <Form.Input width={16} name='defaultSP' value={state.defaultIP}
                                        onChange={(e, value) => this.onChangeDefaultSP(e, value)} />
                                </Table.HeaderCell>
                                <Table.HeaderCell className="header-table" style={{ fontSize: "120%" }} width={1}>
                                    Action
                                            </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {state.moduleSP.map((item, num) =>
                                item.group ? (
                                    <Wrap>
                                        <Table.Row>
                                            <Table.Cell textAlign="right">
                                                {num + 1}
                                            </Table.Cell>
                                            <Table.Cell
                                                className="bold"
                                            // style={{ fontSize: "110%" }}
                                            >
                                                <p>{item.name}</p>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center"></Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    link
                                                    color="red"
                                                    name="trash alternate"
                                                    onClick={() => this.handleItemClickDelete('SP', num)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                        {item.group.map(sub => (
                                            <Table.Row>
                                                <Table.Cell textAlign="right" />
                                                <Table.Cell
                                                    className="bold"
                                                    style={{
                                                        // fontSize: "110%",
                                                        paddingLeft: "15px"
                                                    }}
                                                >
                                                    <li>{sub.name}</li>
                                                </Table.Cell>
                                                <Table.Cell textAlign="center">
                                                    <Form.Input width={16} name={`logbookSP.${sub.id}`}
                                                        value={state.defaultSP} readOnly />
                                                </Table.Cell>
                                                <Table.Cell textAlign="center"></Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Wrap>
                                ) : (
                                        <Table.Row>
                                            <Table.Cell textAlign="right">{num + 1}</Table.Cell>
                                            <Table.Cell
                                                className="bold"
                                            // style={{ fontSize: "110%" }}
                                            >
                                                <p>{item.name}</p>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center">
                                                <Form.Input width={16} name={`logbookSP.${item.id}`}
                                                    value={state.defaultSP} readOnly />
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    link
                                                    color="red"
                                                    name="trash alternate"
                                                    onClick={() => this.handleItemClickDelete('SP', num)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                            )}
                            {state.addSP.map((item, index) => (
                                <Table.Row>
                                    <Table.Cell textAlign="right"></Table.Cell>
                                    <Table.Cell >
                                        <Form.Group style={{ marginBottom: "0px" }}>
                                            <Form.Select
                                                onChange={(e, value) => this.onChange('SP', e, value, index)}
                                                options={state.options_logbookSP}></Form.Select>
                                            <Button
                                                animated="vertical"
                                                size="mini"
                                                color="green"
                                                // loading={state.loadingUp}
                                                onClick={() => this.pushData('SP')}
                                            >
                                                <Button.Content hidden>Update</Button.Content>
                                                <Button.Content visible>
                                                    <Icon name="check" />
                                                </Button.Content>
                                            </Button>
                                        </Form.Group>
                                    </Table.Cell>
                                    <Table.Cell textAlign="right">
                                        <Form.Input width={16} name={`logbookSPAdd.${index.id}`}
                                            value={state.defaultSP} readOnly />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Icon
                                            link
                                            color="red"
                                            name="trash alternate"
                                            onClick={() => this.handleAddItemClickDelete('SP', index)}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                            <Table.Row>
                                <Table.Cell className="footer-table" textAlign="right"></Table.Cell>
                                <Table.Cell className="footer-table"></Table.Cell>
                                <Table.Cell className="footer-table"></Table.Cell>
                                <Table.Cell className="footer-table" textAlign="right">
                                    <Button
                                        color="blue"
                                        size="small"
                                        compact
                                        type="button"
                                        content="Add"
                                        onClick={() => this.handleItemClickAddSP('logbookSP')}
                                    />
                                </Table.Cell>

                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {/* </Grid.Column>
                        </Grid.Row>
                    </Grid> */}


                </Form>
                {/* <br /> */}
                <Button
                    compact
                    floated="left"
                    type="submit"
                    content="Save"
                    color="green"
                    onClick={() => props.onSubmit(state.moduleIP, state.moduleSP)}
                />
                <Button
                    floated="right"
                    type="button"
                    content="Cancel"
                    color="green"
                    basic
                    compact
                    onClick={() => props.onClose}
                />
            </div>

        )
    }
}

export default flightHourRecordForm = reduxForm({
    // a unique name for the form
    form: 'flighthourrecordform',
    // validate,
    enableReinitialize: true
})(flightHourRecordForm)

// export default withRouter(flightHourRecordForm)
