import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Message } from "semantic-ui-react";
import Wrap from "../../components/wrap";

class PerformanceReport3 extends Component {
  state = {};

  componentDidMount() {}

  render() {
    let state = this.state;
    let { data, name } = this.props;
    return (
      <Wrap>
        <Grid columns={8} doubling>
          <Grid.Row>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              ACC TT.{name}:
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              <Message
                size="small"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>
                  {data && data.multiEngines && data.singleEngines
                    ? data.multiEngines.totalLocation +
                      data.singleEngines.totalLocation
                    : 0}
                </h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              Ready :
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              <Message
                size="small"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
                textAlign="center"
              >
                <h5>
                  {data && data.multiEngines && data.singleEngines
                    ? data.multiEngines.totalReady +
                      data.singleEngines.totalReady
                    : 0}
                </h5>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={16} doubling>
          <Grid.Row style={{ padding: 0 }}>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              Total Flight:
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              FLIGHTS
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>
                  {data &&
                  data.multiEngines &&
                  data.singleEngines &&
                  data.nightflight
                    ? data.multiEngines.totalCheck +
                      data.singleEngines.totalCheck +
                      data.nightflight.totalCheck
                    : 0}
                </h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              HRS.
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>{data && data.totalHour ? data.totalHour : "00:00"}</h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              LAND
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>{data && data.totalLand ? data.totalLand : "00:00"}</h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              DAY:
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              HRS.
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>
                  {data && data.totalHourDay ? data.totalHourDay : "00:00"}
                </h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              LAND
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>{data && data.totalLandDay ? data.totalLandDay : 0}</h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              Night
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              HRS.
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>
                  {data && data.totalHourNight ? data.totalHourNight : "00:00"}
                </h5>{" "}
              </Message>
            </Grid.Column>
            <Grid.Column className="bold" textAlign="center">
              LAND
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>{data && data.totalLandNight ? data.totalLandNight : 0}</h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="right"
              verticalAlign="middle"
              style={{ paddingRight: 0 }}
            >
              DA-42:
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              HRS.
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>
                  {data && data.totalHourMulti ? data.totalHourMulti : "00:00"}
                </h5>
              </Message>
            </Grid.Column>
            <Grid.Column
              className="bold"
              textAlign="center"
              style={{ paddingRight: 0 }}
            >
              LAND
              <Message
                size="mini"
                style={{
                  padding: "6px",
                  backgroundColor: "rgb(255, 242, 204)",
                }}
                verticalAlign="middle"
              >
                <h5>{data && data.totalLandMulti ? data.totalLandMulti : 0}</h5>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrap>
    );
  }
}

export default withRouter(PerformanceReport3);
