import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Image,
  List,
  Table,
  Icon,
  Tab,
  Button,
  Segment,
  Header,
} from "semantic-ui-react";
import { reduxForm } from "redux-form";
import Wrap from "../../components/wrap";
import LogbookSP from "./logbookSP";
import OverallProgress from "./overall";
import FlighthourGraph from "./flighthour";
import FlightrecordGraph from "./flightrecordgraph";
import Timeline from "./timeline";
import moment from "moment";
import FlightTrainingRecord from "./flightrecord";
import Discipline from "./discipline";
import ReactToPrint from "react-to-print";

class PortflioProfile extends Component {
  state = {
    status: 3,
    search: {
      status: 4,
    },
  };

  render() {
    const state = this.state;
    const props = this.props;
    return (
      <div>
        <Grid columns="equal">
          <Grid.Row style={{ paddingBottom: 0 }}>
            <Grid.Column key={2} computer={16} mobile={16}>
              <Tab
                renderActiveOnly
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: "Overall Progress",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <OverallProgress
                                id={props && props.personnelId}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },

                  {
                    menuItem: "Flight Hour (Graph)",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <FlighthourGraph
                                id={props && props.personnelId}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Snag",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row columns="equal">
                            <Grid.Column>
                              <Header
                                as="h2"
                                content="NOT PASSED"
                              // subheader='Manage your account settings and set email preferences'
                              />
                              {props.snag &&
                                props.snag.flightSubjects.length > 0 ? (
                                props.snag.flightSubjects.map((item) => (
                                  <List as="ol">
                                    <List.Item as="li" value="">
                                      <b>{item.name}</b>
                                      <List.Item as="ol">
                                        {item.subject.length > 0 &&
                                          item.subject.map((sub) => (
                                            <List.Item as="li" value="-">
                                              {sub.code} {sub.name}
                                              <List.Item as="ol">
                                                {sub.lesson.length > 0 &&
                                                  sub.lesson.map((les) => (
                                                    <List.Item
                                                      as="li"
                                                      value="*"
                                                    >
                                                      {les.name}
                                                    </List.Item>
                                                  ))}
                                              </List.Item>
                                            </List.Item>
                                          ))}
                                      </List.Item>
                                    </List.Item>
                                  </List>
                                ))
                              ) : (
                                <p style={{ color: "rgb(115, 115, 115)" }}>
                                  No description available
                                </p>
                              )}
                            </Grid.Column>
                            <Grid.Column>
                              <Header
                                as="h2"
                                content="PASSED"
                              // subheader='Manage your account settings and set email preferences'
                              />
                              {props.snagPassed &&
                                props.snagPassed.flightSubjects.length > 0 ? (
                                props.snagPassed.flightSubjects.map((item) => (
                                  <List as="ol">
                                    <List.Item as="li" value="">
                                      <b>{item.name}</b>
                                      <List.Item as="ol">
                                        {item.subject.length > 0 &&
                                          item.subject.map((sub) => (
                                            <List.Item as="li" value="-">
                                              {sub.code} {sub.name}
                                              <List.Item as="ol">
                                                {sub.lesson.length > 0 &&
                                                  sub.lesson.map((les) => (
                                                    <List.Item
                                                      as="li"
                                                      value="*"
                                                    >
                                                      {/* {les.name} */}
                                                      {les.name} : Failed Score{" "}
                                                      {les.value} (Passed on{" "}
                                                      {moment(
                                                        les.passedAt
                                                      ).format("MMM DD, YYYY")}
                                                      )
                                                    </List.Item>
                                                  ))}
                                              </List.Item>
                                            </List.Item>
                                          ))}
                                      </List.Item>
                                    </List.Item>
                                  </List>
                                ))
                              ) : (
                                <p style={{ color: "rgb(115, 115, 115)" }}>
                                  No description available
                                </p>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <br />
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Logbook",
                    render: () => <LogbookSP id={props && props.personnelId} />,
                  },
                  props.manage && {
                    menuItem: "Attendance (Admin)",
                    render: () => (
                      <Wrap style={{ height: "100px" }}>
                        <br />
                        {/* <Button
                          size="small"
                          content="Export Excel"
                          onClick={() => props.exportAttendance("?admin")}
                          color="green"
                        /> */}
                        <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <Button
                                floated="right"
                                icon="print"
                                compact
                                content="Print"
                              />
                            );
                          }}
                          content={() => this.componentRef}
                        />
                        <div
                          ref={(el) => (this.componentRef = el)}
                          style={{ textAlign: "center", margin: "10px" }}
                          id="page-to-print"
                        >
                          <br /> <br />
                          {props.attendance && props.attendance.length > 0 ? (
                            <Wrap>
                              <b style={{ fontSize: "1.3em" }}>
                                {props.studentData.studentId &&
                                  props.studentData.studentId}
                                &nbsp;&nbsp;
                                {props.studentData.firstNameEng &&
                                  props.studentData.firstNameEng}
                                &nbsp;&nbsp;
                                {props.studentData.lastNameEng &&
                                  props.studentData.lastNameEng}
                                &nbsp;&nbsp;
                                {props.studentData.customerCode &&
                                  props.studentData.customerCode}
                                <br /> <br />
                              </b>
                              {props.attendance.map((att) => (
                                <Wrap>
                                  <b>
                                    {att.course.name} ({att.course.desc})
                                  </b>
                                  <Table
                                    celled
                                    padded
                                    compact
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Table.Header>
                                      <Table.Row textAlign="center">
                                        <Table.HeaderCell>
                                          Date time
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Subject
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Instructor
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Place
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Room
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Action By
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Action At
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {att.subjects.map((sub) => (
                                        <Table.Row>
                                          <Table.Cell>
                                            {moment(sub.start).format("D MMMM YYYY")} <br />
                                            {moment(sub.start).format("HH:mm")} - {moment(sub.end).format("HH:mm")}
                                          </Table.Cell>
                                          <Table.Cell className="bold">
                                            <List>
                                              <List.Item
                                                style={{ marginLeft: 15 }}
                                              >
                                                {sub.attendees ? (
                                                  <List.Icon
                                                    name="check"
                                                    color="green"
                                                  />
                                                ) : (
                                                  <List.Icon
                                                    name="times"
                                                    color="red"
                                                  />
                                                )}

                                                <List.Content>
                                                  <List.Header>
                                                    {sub.name} (
                                                    {sub.time || "-"} Hours)
                                                  </List.Header>
                                                  <List.Description>
                                                    Total {sub.total || "-"}/
                                                    {sub.hours || "-"}
                                                    {sub.testSubject ? (
                                                      sub.passed ? (
                                                        <small
                                                          style={{
                                                            color: "green",
                                                          }}
                                                        >
                                                          {" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                          PASSED
                                                        </small>
                                                      ) : (
                                                        <small
                                                          style={{
                                                            color: "red",
                                                          }}
                                                        >
                                                          {" "}
                                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                                          NOT PASSED
                                                        </small>
                                                      )
                                                    ) : (
                                                      ""
                                                    )}
                                                  </List.Description>
                                                </List.Content>
                                              </List.Item>
                                            </List>
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.instructorName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.locationName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.roomName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.actionBy}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.actionAt}
                                          </Table.Cell>
                                        </Table.Row>
                                      ))}
                                    </Table.Body>
                                  </Table>
                                </Wrap>
                              ))}
                            </Wrap>
                          ) : (
                            <Wrap>
                              <p style={{ color: "rgb(115, 115, 115)" }}>
                                No description available
                              </p>
                              <br />
                            </Wrap>
                          )}
                        </div>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Attendance",
                    render: () => (
                      <Wrap style={{ backgroundColor: "red" }}>
                        <br />
                        {/* <Button
                          size="small"
                          content="Export Excel"
                          onClick={() => props.exportAttendance("")}
                          color="green"
                        /> */}
                        <ReactToPrint
                          trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                              <Button
                                floated="right"
                                icon="print"
                                compact
                                content="Print"
                              />
                            );
                          }}
                          content={() => this.componentRef}
                        />
                        <div
                          ref={(el) => (this.componentRef = el)}
                          style={{
                            textAlign: "center",
                            margin: "10px",
                          }}
                          id="page-to-print"
                        >
                          <br /> <br />
                          {props.attendance && props.attendance.length > 0 ? (
                            <Wrap>
                              <b style={{ fontSize: "1.3em" }}>
                                {props.studentData.studentId &&
                                  props.studentData.studentId}
                                &nbsp;&nbsp;
                                {props.studentData.firstNameEng &&
                                  props.studentData.firstNameEng}
                                &nbsp;&nbsp;
                                {props.studentData.lastNameEng &&
                                  props.studentData.lastNameEng}
                                &nbsp;&nbsp;
                                {props.studentData.customerCode &&
                                  props.studentData.customerCode}
                                <br /> <br />
                              </b>
                              {props.attendance.map((att) => (
                                <Wrap>
                                  <b>
                                    {att.course.name} ({att.course.desc})
                                  </b>
                                  <Table
                                    celled
                                    padded
                                    compact
                                    style={{ marginBottom: 10 }}
                                  >
                                    <Table.Header>
                                      <Table.Row textAlign="center">
                                        <Table.HeaderCell>
                                          Date time
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Subject
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Instructor
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Place
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Room
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Action By
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                          Action At
                                        </Table.HeaderCell>
                                      </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                      {att.subjects.map((sub) => (
                                        <Table.Row>
                                          <Table.Cell>
                                            {moment(sub.start).format("D MMMM YYYY")} <br />
                                            {moment(sub.start).format("HH:mm")} - {moment(sub.end).format("HH:mm")}
                                          </Table.Cell>
                                          <Table.Cell className="bold">
                                            {sub.name} ({sub.time || "-"} Hours)
                                            <div className='bold'>
                                              Total {sub.total || "-"}/
                                              {sub.hours || "-"}
                                            </div>

                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.instructorName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.locationName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.roomName}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.actionBy}
                                          </Table.Cell>
                                          <Table.Cell>
                                            {sub.actionAt}
                                          </Table.Cell>
                                        </Table.Row>
                                      ))}
                                    </Table.Body>
                                  </Table>
                                </Wrap>
                              ))}{" "}
                            </Wrap>
                          ) : (
                            <Wrap>
                              <p style={{ color: "rgb(115, 115, 115)" }}>
                                No description available
                              </p>
                              <br />
                            </Wrap>
                          )}
                        </div>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Flight Training Record (Graph)",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <FlightrecordGraph
                                id={props && props.personnelId}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Flight Training Record",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <FlightTrainingRecord
                                id={props && props.personnelId}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Timeline",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column>
                              <Timeline id={props && props.personnelId} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },
                  {
                    menuItem: "Discipline ",
                    render: () => (
                      <Wrap>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column >
                              <Discipline id={props && props.personnelId} studentData={props && props.studentData} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Wrap>
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

// export default withRouter(Note)

export default PortflioProfile = reduxForm({
  // a unique name for the form
  form: "portfolio",
  // validate,
  // enableReinitialize: true
})(PortflioProfile);
