import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Card, Icon } from "semantic-ui-react";
import * as config from "../../config";

class MainReport extends Component {
  state = {
    flight_report: [
      {
        id: 1,
        icon: "plane",
        topic: "IP Flight Hour",
        description: "สรุปชั่วโมงครูการบิน",
        linkto: "/Report/IPFlightHour",
      },
      {
        id: 2,
        icon: "plane",
        topic: "Flight Plan",
        description: "แผนการบิน",
        linkto: "/Report/FlightPlan",
      },
      {
        id: 3,
        icon: "plane",
        topic: "Technical Crew Voyage",
        description: "รายงานตารางบิน",
        linkto: "/Report/CrewVoyage",
      },
      {
        id: 4,
        icon: "plane",
        topic: "Reservation (Flight)",
        description: "",
        linkto: "/Report/FlightReservation",
      },
      {
        id: 5,
        icon: "plane",
        topic: "Performance Report",
        description: "Reservation & Flight Hour Record",
        linkto: "/Report/Performance",
      },
    ],
    ground_report: [
      {
        id: 1,
        icon: "computer",
        topic: "Ground Schedule's Cancellation Monthly",
        description: "",
        linkto: "/Report/GoundCancel",
      },
      {
        id: 2,
        icon: "computer",
        topic: "Ground Instructor Compensation List",
        description: "รายการเบิกค่าตอบแทนครูผู้สอนวิชาการภาคพื้น",
        linkto: "/Report/GoundCompensation",
      },
      {
        id: 3,
        icon: "computer",
        topic: "Reservation (Ground)",
        description: "",
        linkto: "/Report/GroundReservation",
      },
    ],
    regis_report: [
      {
        id: 1,
        icon: "user",
        topic: "Student Database",
        description: "ทำเนียบรุ่น",
        linkto: "/Report/StudentDatabase",
      },
      {
        id: 2,
        icon: "user",
        topic: "Student Data Summary",
        description: "",
        linkto: "/Report/StudentSummary",
      },
    ],
    instructor_report: [
      {
        id: 1,
        icon: "user",
        topic: "Proficiency Check",
        description: "",
        linkto: "/Report/ProficiencyCheck",
      },
    ],
    student_report: [
      // {
      //   id: 1,
      //   icon: "user",
      //   topic: "Student Data Summary",
      //   description: "",
      //   linkto: "/Report/StudentSummary",
      // },
    ],
  };

  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Header as="h2" style={{ color: config.colorTheme }}>
          Report
        </Header>
        <Header as="h3" style={{ color: config.colorTheme }}>
          Flight
        </Header>
        <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
          {state.flight_report.map((item) => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name={item.icon}
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  {item.topic}
                </Header>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Header as="h3" style={{ color: config.colorTheme }}>
          Ground
        </Header>
        <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
          {state.ground_report.map((item) => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name={item.icon}
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  {item.topic}
                </Header>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Header as="h3" style={{ color: config.colorTheme }}>
          Registration
        </Header>
        <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
          {state.regis_report.map((item) => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name={item.icon}
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  {item.topic}
                </Header>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <Header as="h3" style={{ color: config.colorTheme }}>
          Instructor
        </Header>
        <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
          {state.instructor_report.map((item) => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name={item.icon}
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  {item.topic}
                </Header>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        {/* <Header as="h3" style={{ color: config.colorTheme }}>
          Student
        </Header>
        <Card.Group itemsPerRow="4" doubling style={{ padding: "20px" }}>
          {state.student_report.map((item) => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon
                    name={item.icon}
                    style={{ color: config.colorTheme, textAlign: "center" }}
                  />
                  {item.topic}
                </Header>
                <Card.Description>{item.description}</Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group> */}
        <br />
        <br />
      </Segment>
    );
  }
}
export default withRouter(MainReport);
