import React, { Component } from "react";
import { Form, Button, Table, Statistic } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderRadioField from "../../components/renderRadioField";
import renderSelectField from "../../components/renderSelectField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import moment from "moment";

const validate = (values) => {
  const errors = {};
  if (!values.course) errors.course = true;
  if (!values.desc) errors.desc = true;
  if (!values.courseStart) errors.courseStart = true;
  if (!values.courseEnd) errors.courseEnd = true;
  if (!values.groundinstructiontype) errors.groundinstructiontype = true;
  if (!values.courseType) errors.courseType = true;

  return errors;
};

class Formcourse extends Component {
  state = {
    module: [],
    numOfDays: null,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.courseStart !== this.props.courseStart ||
      nextProps.courseEnd !== this.props.courseEnd
    ) {
      var start = moment(nextProps.courseStart, "DD-MM-YYYY");
      var end = moment(nextProps.courseEnd, "DD-MM-YYYY");
      var duration = moment.duration(end.diff(start));
      var days = duration.asDays();
      this.setState({
        numOfDays: days,
      });
    }

    if (nextProps.course !== this.props.course) {
      if (nextProps.course && nextProps.course.module)
        this.setState({ module: nextProps.course.module });
    }
  }

  render() {
    const state = this.state;
    const {
      courseStart,
      handleSubmit,
      pristine,
      submitting,
      option_course,
      option_groundinstructiontype,
      loading,
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Field
          name="course"
          component={renderSelectField}
          label="Course"
          required
          options={option_course}
        />
        <Field
          name="desc"
          type="text"
          required
          component={renderField}
          label="Description"
        />
        <Form.Group>
          <Field
            name="courseStart"
            label="Start"
            required
            width="6"
            component={renderDateOnlyField}
          />
          <Field
            name="courseEnd"
            label="End"
            required
            minDate={courseStart}
            width="6"
            component={renderDateOnlyField}
          />
          {/* <Form.Field
            width="4"
            style={{ textAlign: "center", paddingTop: "20px" }}
          >
            <Statistic size="mini">
              <Statistic.Value style={{ color: "#FF5722" }}>
                {state.numOfDays || 0}
              </Statistic.Value>
              <Statistic.Label style={{ color: "gray" }}>Day</Statistic.Label>
            </Statistic>
          </Form.Field> */}
        </Form.Group>
        <Field
          name="groundinstructiontype"
          component={renderSelectField}
          label="Ground Instruction Type"
          required
          options={option_groundinstructiontype}
        />
        <Field
          name="courseType"
          component={renderSelectField}
          label="Course Type"
          required
          options={[
            { key: "f", text: "Full Time", value: "Full Time" },
            { key: "p", text: "Part Time", value: "Part Time" },
          ]}
        />
        <Field
          name="additionalCourse"
          width="4"
          label="Additional Course"
          component={renderRadioField}
        />
        {/* <br />
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="header-table">
                Module
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                Ground
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                G.subject
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                Flight
              </Table.HeaderCell>
              <Table.HeaderCell
                className="header-table"
                width={1}
                textAlign="center"
              >
                F.subject
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {state.module.map((item, index) => (
              <Table.Row>
                <Table.Cell>
                  {item.name}
                </Table.Cell>
                <Table.Cell textAlign="center">{item.groundHours}</Table.Cell>
                <Table.Cell textAlign="center">
                  {item.groundSubjects}
                </Table.Cell>
                <Table.Cell textAlign="center">{item.flightHours}</Table.Cell>
                <Table.Cell textAlign="center">
                  {item.flightSubjects}
                </Table.Cell>
              </Table.Row>
            ))}
            <Table.Row>
              <Table.Cell className="footer-table">Total</Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {state.module.reduce((sum, item) => {
                  return sum + item.groundHours;
                }, 0)}
              </Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {state.module.reduce((sum, item) => {
                  return sum + item.groundSubjects;
                }, 0)}
              </Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {this.totalDurations(state.module)}
              </Table.Cell>
              <Table.Cell className="footer-table" textAlign="center">
                {state.module.reduce((sum, item) => {
                  return sum + item.flightSubjects;
                }, 0)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table> */}
        <br />
        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
        <br />
      </Form>
    );
  }

  totalDurations = (data) => {
    let durations = [];
    data.map((item) => durations.push(item.flightHours));
    const totalDurations = durations
      .slice(1)
      .reduce(
        (prev, cur) => moment.duration(cur).add(prev),
        moment.duration(durations[0])
      );

    return this.convertMS(totalDurations.asMilliseconds());
    // moment.utc(totalDurations.asMilliseconds()).format("HH:mm");
  };
  convertMS = (ms) => {
    var d, h, m, s;
    s = Math.floor(ms / 1000);
    m = Math.floor(s / 60);
    s = s % 60;
    h = Math.floor(m / 60);
    m = m % 60;
    d = Math.floor(h / 24);
    h = h % 24;
    h += d * 24;
    return ((h + "").length === 1 ? "0" + h : h) + ":" + ("0" + m).substr(-2);
  };
}

Formcourse = reduxForm({
  // a unique name for the form
  form: "courseform",
  validate,
  enableReinitialize: true,
})(Formcourse);

// Decorate with connect to read form values
const selector = formValueSelector("courseform"); // <-- same as form name
Formcourse = connect((state) => {
  const courseStart = selector(state, "courseStart");
  const courseEnd = selector(state, "courseEnd");
  const course = selector(state, "course");
  return {
    courseStart,
    courseEnd,
    course,
  };
})(Formcourse);
export default Formcourse;
