import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Segment, Form, Breadcrumb, Grid, Card, Tab, Image,Table, Button } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import * as config from '../../config'
import validate from '../../validate'
import TablePageSize from '../../components/table'
import HeaderWithMenu from '../../components/headerwithmenu'
import MainButton from '../../components/mainbutton'
import ModalForm from '../../components/modal'
import renderField from '../../components/renderField'
import renderSelectField from '../../components/renderSelectField'
import renderRadioField from '../../components/renderRadioField'

class Profile extends Component {

    state = {
        data: this.props.data,
        status: 3,
        rawdataRemind: [{ "id": "e1d7b734-8bfb-45fa-8bca-3fa2025fc77f", "name": "OPS#3", "tailNumber": "HS-BAK [CA160]", "make": "Cessna", "model": "172P", "status": 3, "daysRemainingStatus": 0, "daysRemainingMessage": null, "timeRemainingStatus": 3, "timeRemainingMessage": "4.80 Hours Expired", "cyclesRemainingStatus": 0, "cyclesRemainingMessage": null, "isOneTimeReminder": true }],
        dataRemind: [],
        search: {
            status: 4
        },
        dataReserv: [{
                "reservationId":"e8881cda-cbfe-4368-8026-2a655a902c36",
                "organizationId":116817,
                "displayType":0,
                "start":"2019-02-08T13:30:00",
                "startTimeZone":"",
                "end":"2019-02-08T16:00:00",
                "endTimeZone":"",
                "instructor":"IP01 Kwanchai Arnuphab",
                "resource":"HS-BAY [IA160]",
                "aircraftMake":"Cessna",
                "aircraftModel":"172R",
                "orFor":"R11 Piyoungkul N.",
                "pilotFirstName":"2308 Piyoungkul",
                "pilotLastName":"Netsuwan RSU11 DL150038",
                "pilot2FirstName":"",
                "pilot2LastName":"",
                "isPilot":false,
                "isInstructor":true,"status":0,
                "background":"#3399ff",
                "foreground":"#ffffff",
                "reservationTypeName":"(Single) Dual Flight Training"
            },{"reservationId":"7bd52d64-a249-4360-b2fe-979a8f9d8f77","organizationId":116817,"displayType":0,"start":"2019-02-08T16:00:00","startTimeZone":"","end":"2019-02-08T18:30:00","endTimeZone":"","instructor":"IP01 Kwanchai Arnuphab","resource":"HS-BAY [IA160]","aircraftMake":"Cessna","aircraftModel":"172R      ","orFor":"R11 Andamun P.","pilotFirstName":"2303 Andamun","pilotLastName":"Pornsuriyasri RSU11 DL150038","pilot2FirstName":"","pilot2LastName":"","isPilot":false,"isInstructor":true,"status":0,"background":"#3399ff","foreground":"#ffffff","reservationTypeName":"(Single) Dual Flight Training"},
            {"reservationId":"3a694d9d-b009-4231-a1ad-7c551a16145e","organizationId":116817,"displayType":0,"start":"2019-02-09T08:00:00","startTimeZone":"","end":"2019-02-09T10:00:00","endTimeZone":"","instructor":"IP01 Kwanchai Arnuphab","resource":"HS-BCY [IA180]","aircraftMake":"Cessna","aircraftModel":"172S      ","orFor":"R11 Nut M.","pilotFirstName":"2319 Nut","pilotLastName":"Munying RSU11 DL150038","pilot2FirstName":"","pilot2LastName":"","isPilot":false,"isInstructor":true,"status":0,"background":"#3399ff","foreground":"#ffffff","reservationTypeName":"(Single) Dual Flight Training"},
            {"reservationId":"25386850-cb4e-4766-945a-b61215ca96c2","organizationId":116817,"displayType":0,"start":"2019-02-09T10:00:00","startTimeZone":"","end":"2019-02-09T12:00:00","endTimeZone":"","instructor":"IP01 Kwanchai Arnuphab","resource":"HS-BCY [IA180]","aircraftMake":"Cessna","aircraftModel":"172S      ","orFor":"R11 Piyoungkul N.","pilotFirstName":"2308 Piyoungkul","pilotLastName":"Netsuwan RSU11 DL150038","pilot2FirstName":"","pilot2LastName":"","isPilot":false,"isInstructor":true,"status":0,"background":"#3399ff","foreground":"#ffffff","reservationTypeName":"(Single) Dual Flight Training"}
        ],
        columnReserv: [{
            Header: 'instructor',
            accessor: 'instructor'
        }, {
            Header: 'reservationId',
            accessor: 'reservationId'
        }],
        dataDocument: [{"documentId":null,"documentationTypeId":0,"id":null,"userDocumentId":null,"name":"Complete User Profile","status":0,"statusMessages":[],"canModify":true},{"documentId":null,"documentationTypeId":2,"id":null,"userDocumentId":null,"name":"Emergency Contact Info","status":0,"statusMessages":[],"canModify":true},{"documentId":null,"documentationTypeId":1,"id":null,"userDocumentId":null,"name":"Profile Photo","status":1,"statusMessages":[],"canModify":true},{"documentId":null,"documentationTypeId":3,"id":null,"userDocumentId":null,"name":"Photo ID","status":0,"statusMessages":[],"canModify":true},{"documentId":"08206259-d545-4cf8-90e0-549148f0a095","documentationTypeId":1000,"id":null,"userDocumentId":null,"name":"Medical Certificate","status":0,"statusMessages":[],"canModify":true},{"documentId":"93c740f8-c546-46df-9374-53c636c5dc89","documentationTypeId":1000,"id":null,"userDocumentId":null,"name":"Student Pilot License","status":0,"statusMessages":[],"canModify":true}],
        columnDocument: [{
            Header: 'NAME',
            accessor: 'name'
        }, {
            Header: 'STATUS',
            accessor: 'status'
        }, {
            Header: 'Action',
            accessor: 'status'
        }],
    }

    render() {
        const data = this.props.data
        const page = this.props.page
        return (
            <div>
                <Table basic='very'>
                    <Table.Body>
                        <Table.Row verticalAlign='top'>
                            <Table.Cell width='5' content='Name' className='bold' />
                            <Table.Cell width='11'>
                                <p>{data.firstName} {data.lastName}</p>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5' content='Phone' className='bold' />
                            <Table.Cell width='11' content={data.phone || '-'} />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5' content='Username' className='bold' />
                            <Table.Cell width='11' content={data.accountingUserName || '-'} />
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell width='5' content='Email' className='bold' />
                            <Table.Cell width='11' content={data.email || '-'} />
                        </Table.Row>
                        <Table.Row verticalAlign='top'>
                            <Table.Cell width='5' content={ page === 'student' ? 'Programs' : page === 'staff' ? 'Groups' : 'Instructor Type'} className='bold' />
                            <Table.Cell width='11'>
                                {  data.instructorType 
                                    ?   data.instructorType.map(item => 
                                            <p>{item.name}</p>
                                        )
                                    :   data.roles.map(item => 
                                            <p>{item.name}</p>
                                        )
                                }
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </div>
        )
    }
}

// export default withRouter(ProfileAircraft)

export default Profile = reduxForm({
    // a unique name for the form
    form: 'people',
    validate,
    enableReinitialize: true
})(Profile)
