import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Form, Segment, Breadcrumb, Icon, Loader, Button, Table, Popup} from 'semantic-ui-react'
import MainButton from '../../components/mainbutton'
import HeaderWithMenu from '../../components/headerwithmenu'
import ModalForm from '../../components/modal'
import FormcourseModule from './courseModuleForm'
import FormcourseModuleGroup from './courseModuleGroupForm'
import FormcourseModuleProgressChecklist from './courseModuleProgressChecklist'
import Confirmmodal from "../../components/confirmmodal";

import {
    getSubjectFlightGroupByModule, postSubjectFlightGroup, deleteSubjectFlightGroup,
    getFlightGroup, getCourseModule, getSubjects, getProgressChecklist,
    postCourseModule, updateCourseModule, deleteCourseModule, updateProgressChecklist} from './service'

const defaultValue = {
    "flightSubjects": [],
    "groundSubjects": [],
    "flightAddSubjects":[]
}

class CourseModule extends Component {
    state = {
        search: {},
        rawData: [],
        activeIndex: 0,
        data: [],
        loading: true,
        search: {
            sort: { key: 'name', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' }
        },
        sortText: 'Subjects (ASC)',
        options_sortby: [
            { key: 'name', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' },
            { key: 'name', text: 'Name', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
            { key: 'groundHours', text: 'Ground Hours', value: '3', icon: 'sort numeric ascending', description: 'ASC ' },
            { key: 'groundHours', text: 'Ground Hours', value: '4', icon: 'sort numeric descending', description: 'DESC' },
            { key: 'flightHours', text: 'Flight Hours', value: '5', icon: 'sort numeric ascending', description: 'ASC ' },
            { key: 'flightHours', text: 'Flight Hours', value: '6', icon: 'sort numeric descending', description: 'DESC' }
        ],
        flightGroup : []
    }

    componentDidMount() {
        let { search } = this.state
        let qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        this.getData(qString)
        this.getOptionGroundSubjects()
        this.getOptionFlightSubjects()
        getFlightGroup().then(res=>{
            if(res&& res.status ===200){
                this.setState({ flightGroup: res.data })
            }
        })
    }

    getData = (qString) => {
        getCourseModule(qString).then(res => {
            if (res && res.status === 200) {
                this.setState({ data: res.data, rawData: res.data })
            }
            this.setState({ loading: false })
        })
    }

    getOptionGroundSubjects = () => {
        let option_subject = []
        getSubjects('?type=G').then(res => {
            if (res && res.status === 200) {
                res.data.map(item => {
                    option_subject.push({
                        key: item.id, id: item.id,
                        title: `${item.name}` ,
                        text: item.hours && item.reference ? `${item.name} (${item.reference}) (${item.hours})` 
                            : item.hours ? `${item.name}  (${item.hours})` 
                         : item.name,
                        value: item
                    })
                })
                this.setState({ option_subject, })
            }
        })
    }

    getOptionFlightSubjects = () => {
        let option_flightSubject = [], option_flightAddSubject=[]
        getSubjects('?type=F').then(res => {
            if (res && res.status === 200) {
                res.data.map(item => {
                    option_flightSubject.push({
                        key: item.id, id: item.id,
                        title: `${item.code}`,
                        text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`,
                        value: item
                    })
                })
                this.setState({ option_flightSubject, })
            }
        })
        getSubjects('?type=FA').then(res => {
            if (res && res.status === 200) {
                res.data.map(item => {
                    option_flightAddSubject.push({
                        key: item.id, id: item.id,
                        title: `${item.code}`,
                        text: item.hours ? `${item.code} (${item.hours})` : `${item.code}`, 
                        value: item
                    })
                })
                this.setState({ option_flightAddSubject, })
            }
        })
    }
 

    onClickNew = () => { this.setState({ open: true, currentData: defaultValue, isNew: true, content: 'New Module' }) }
    onClickCancel = () => { 
        let { search } = this.state
        let qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        this.getData(qString)
        this.setState({ open: false, openGroup: false, openChecklist:false,currentData: null, isNew: false })
     }
    onClickEdit = (value) => { 
        // let flightSubjects = [],  flightAddSubjects = [], groundSubjects = []
        // value.groundSubjects.map((item) => { groundSubjects.push(item) })
        // value.flightSubjects.map((item) => { 
        //     flightSubjects.push(item)      
        // })
        // value.flightAddSubjects.map((item) => {
        //     flightAddSubjects.push(item)
        // })
        // let data = {
        //     id: value.id,
        //     name:value.name,
        //     flightSubjects: flightSubjects,
        //     groundSubjects: groundSubjects,
        //     flightAddSubjects: flightAddSubjects
        // }
        // value.flightSubjects = flightSubjects
        // value.groundSubjects = groundSubjects
        // value.flightAddSubjects = flightAddSubjects 
        // console.log("currentData",value)
        this.setState({ open: true, currentData: value, isNew: false, content: 'Module' }) 
    }

    onClickManageProgressChecklist = value => {
        let option_subjectByModule = []
        value.flightSubjects.map(item=>{
            option_subjectByModule.push({
                key: item.id, 
                id: item.id,
                title: item.code,
                text: item.code,
                value: item.id
            })
        })
        value.groundSubjects.map((item) => {
            option_subjectByModule.push({
                key: item.id,
                id: item.id,
                title: item.name,
                text: item.name,
                value: item.id 
            }) 
        })
        this.setState({ option_subjectByModule})
        getProgressChecklist(value.id).then(res=>{
            if(res && res.status === 200){
                let currentChecklist = []
                res.data && res.data.checklist.map(item=>{
                    let subj =[]
                    item.subject.map(sub => subj.push(sub.subjectId))
                    currentChecklist.push({
                        "id": item.id,
                        "name": item.name,
                        "subject": subj
                    })
                })
                this.setState({
                    openChecklist:true,
                    currentData: {
                        id: res.data && res.data.id,
                        name: res.data && res.data.name,
                        checklist: currentChecklist
                    }
                })
            }else{
                this.setState({
                    openChecklist: true 
                })
            }
        })
    }
    onClickManageGroup = value => {
        getSubjectFlightGroupByModule(value.id).then(res => {
            if (res && res.status === 200) {
                let subject =[]
                res.data.map(elem=>{
                    let selected=[]
                    elem.subject.map(item=>{
                        selected.push(item.id)
                    })
                    subject.push({
                        id: elem.id,
                        name: elem.name ,
                        subject:selected
                    })
                })
                this.setState({
                    currentData: {
                        id: value.id,
                        name: value.name,
                        flightSubjects: subject
                    },rawDataGroup:res.data
                })
            } else {
                let flightSubjects = []
                flightGroup.map(item => {
                    flightSubjects.push(item)
                })
                this.setState({
                    currentData: {
                        id: value.id,
                        name: value.name,
                        flightSubjects: flightSubjects
                    },rawDataGroup:null
                })
            }
        })
        
        let { flightGroup} =  this.state
        let option_flightSubject =[]
        value.flightSubjects && value.flightSubjects.map(item=>{
            option_flightSubject.push({
            key: item.id, id: item.id,
            title: item.code,
            text: item.code, 
            value: item.id
        }) 
        })
        this.setState({ openGroup: true, option_flightSubjectByModule: option_flightSubject})
    }
    onClickDelete = value => {
        this.setState({
            openConfirm: true,
            currentData: value
        });
    };
    deleteData = () => {
        let { search, currentData } = this.state;
        let qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        this.setState({ loading: true });
        if (currentData && currentData.id) {
            deleteCourseModule(currentData.id).then(res => {
                if (res && res.status === 200) {
                    this.onClickCancel();
                    this.getData(qString);
                    this.setState({ openConfirm: false });
                }
                this.setState({ loading: false });
            });
        }
    };
    saveData = (value) => {
         this.setState({loading:true})
        let flightAddSubjects = [], flightSubjects = [], groundSubjects=[]
        if (value.flightAddSubjects)
            value.flightAddSubjects.map(item => { if (item.id) flightAddSubjects.push(item.id) })
        if (value.flightSubjects)
            value.flightSubjects.map(item => { if (item.id) flightSubjects.push(item.id) })
        if (value.groundSubjects)
            value.groundSubjects.map(item => { if (item.id) groundSubjects.push(item.id) })
        let data ={
            flightSubjects: flightSubjects,
            groundSubjects: groundSubjects,
            flightAddSubjects: flightAddSubjects,
            name:value.name
        }
        let { search } = this.state
        let qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        postCourseModule(data).then(res => {
            if(res && res.status === 201){
                this.onClickCancel()
                this.getData(qString)
            }
            this.setState({ loading: false })
        })
    }


    updateData = (value) => {
 
        this.setState({ loading: true })
        let flightAddSubjects = [], flightSubjects = [], groundSubjects = []
        if (value.flightAddSubjects)
            value.flightAddSubjects.map(item => { if (item.id) flightAddSubjects.push(item.id) } )
        if (value.flightSubjects)
            value.flightSubjects.map(item => { if (item.id) flightSubjects.push(item.id) }  )
        if (value.groundSubjects)
            value.groundSubjects.map(item => { if (item.id) groundSubjects.push(item.id) }  )
        let data = {
            id:value.id,
            flightSubjects: flightSubjects,
            groundSubjects: groundSubjects,
            flightAddSubjects: flightAddSubjects,
            name: value.name
        }
        let { search } = this.state
        let qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        updateCourseModule(data).then(res => {
            if (res && res.status === 200) {
                this.onClickCancel()
                this.getData(qString)
            }
            this.setState({ loading: false })
        })
       
    }
    updateProgressChecklist = value =>{
        // console.log(value,"vaupdateProgressChecklist")
        updateProgressChecklist(value).then(res=>{
            if(res && res.status === 200)
            console.log("rrr",res.data)
        })
    }
    updateFlightGroup = value => {
        let { rawDataGroup} =this.state
        rawDataGroup.map((item,key)=>{
            let initialValuesSubject = []
            item.subject.map(sub=>{
                initialValuesSubject.push(sub.id)
                if (value.flightSubjects[key].subject.indexOf(sub.id) < 0)
                    deleteSubjectFlightGroup(sub.flightGroupId)
            })
            value.flightSubjects[key].subject.map(sub=>{
                if (initialValuesSubject.indexOf(sub) < 0){
                    postSubjectFlightGroup({
                        "flightGroupId": item.id,
                        "subjectId": sub
                    })
                }  
            })
        })    
        this.onClickCancel()    
    }

    render() {
        const state = this.state
        return (
            <div>
                <Segment className="contentSegment" textAlign='left'>
                    <HeaderWithMenu name='Module' >
                        <MainButton
                            size='small'
                            onClick={this.onClickNew}
                            text='Add Module' />
                    </HeaderWithMenu>
                    <Breadcrumb>
                        <Breadcrumb.Section
                            onClick={() => this.props.history.push('/Course')}
                            style={{ color: 'grey' }}>
                            Academic
                    </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section
                            onClick={() => this.props.history.push('/Course/Course')}
                            style={{ color: 'grey' }}>
                            Course
                    </Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Module</Breadcrumb.Section>
                    </Breadcrumb>
                    <br /><br />
                    <Form>
                        <Form.Group>
                            <Form.Input
                                // width='4'
                                icon='search'
                                size='small'
                                placeholder='Search'
                                name='name'
                                onChange={this.handleChangeSearch} />
                            <Form.Dropdown
                                icon={null}
                                fluid
                                floating
                                // width='4'
                                style={{ width: '100%', marginRight: '30px' }}
                                trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                    <Icon name='angle down' />
                                    Sort by : <b>{state.sortText}</b>
                                </Button>}
                                options={state.options_sortby}
                                defaultValue={state.search.sort}
                                onChange={this.handleChangeSort}
                                selectOnBlur={false}
                            />
                        </Form.Group>
                    </Form>
                    {state.loading
                        ? <Loader active inline='centered' content='Loading' />
                        :  <Table compact celled striped style={{ marginBottom: '15px' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell className='header-table' width={1} textAlign='center'></Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={4}>Module (เนื้อหาย่อยของหลักสูตร)</Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={2} textAlign='center'>Ground Subjects</Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={2} textAlign='center'>Ground (Hours)</Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={2} textAlign='center'>Flight Subjects</Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={2} textAlign='center'>Flight (Hours)</Table.HeaderCell>
                                    <Table.HeaderCell className='header-table' width={3} textAlign='center'>Manage</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                                    <Table.Body>
                                        {state.data.map((module,index) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell textAlign='right'>
                                                        {index+1}
                                                    </Table.Cell>
                                                    <Table.Cell> 
                                                        <p
                                                        style={{
                                                            fontWeight: "bold",
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => this.onClickEdit(module)}
                                                    >
                                                    {module.name}
                                                    </p></Table.Cell>
                                                    <Table.Cell textAlign='center'>{module.groundSubjects && module.groundSubjects.length}</Table.Cell>
                                                    <Table.Cell textAlign='center' className='bold'>{module.groundHours}</Table.Cell>
                                                    <Table.Cell textAlign='center'>{module.flightSubjects && module.flightSubjects.length}</Table.Cell>
                                                    <Table.Cell textAlign='center' className='bold'>{module.flightHours}</Table.Cell>
                                                    <Table.Cell textAlign='center'> <Popup
                                                        trigger={<Icon link name='edit' onClick={() => this.onClickEdit(module)} />}
                                                        content='Edit Module'
                                                       
                                                        position='bottom right'
                                                    /> &nbsp; 
                                                    <Popup
                                                            trigger={<Icon link name='tasks' onClick={() => this.onClickManageProgressChecklist(module)} />}
                                                            content='Progress Checklist'
                                                           
                                                            position='bottom right'
                                                        /> &nbsp; 
                                                        <Popup
                                                            trigger={<Icon link name='list' disabled onClick={() => console.log(module)} />}
                                                            content='CAAT Checklist'
                                                            position='bottom right'
                                                            
                                                        /> &nbsp;
                                                         <Popup
                                                            trigger={<Icon link name='object group outline' onClick={() => this.onClickManageGroup(module)} />}
                                                            content='Flight Group'
                                                            position='bottom right'
                                                        />  &nbsp;
                                <Icon
                                                            link
                                                            color="red"
                                                            name="trash alternate"
                                                            onClick={() => this.onClickDelete(module)}
                                                        /></Table.Cell>

                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                      
                    }
                </Segment>
                <ModalForm
                    open={state.open}
                    icon='book'
                    size='small'
                    content={state.content}
                    close={this.onClickCancel}  >
                    <FormcourseModule
                        loading={state.loading}
                        initialValues={state.currentData ? state.currentData : null}
                        option_subject={state.option_subject}
                        option_flightSubject={state.option_flightSubject}
                        option_flightAddSubject={state.option_flightAddSubject}
                        onSubmit={state.isNew ? this.saveData : this.updateData}
                        onCancel={this.onClickCancel}
                    />
                </ModalForm>
                <ModalForm
                    open={state.openGroup}
                    icon='object group outline'
                    size='small'
                    content={`Flight Group`}
                    close={this.onClickCancel}  >
                    <FormcourseModuleGroup
                        initialValues={state.currentData ? state.currentData : null}
                        option_flightSubject={state.option_flightSubjectByModule}
                        onSubmit={this.updateFlightGroup}
                        onCancel={this.onClickCancel}
                    />
                </ModalForm>
                <ModalForm
                    open={state.openChecklist}
                    icon='tasks'
                    size='small'
                    content='Progress Checklist'
                    close={this.onClickCancel}  >
                    <FormcourseModuleProgressChecklist
                    // { checklist:[{}]} default value 
                        initialValues={state.currentData ? state.currentData : { checklist:[{}]}}
                        option_subject={state.option_subjectByModule}
                        onSubmit={this.updateProgressChecklist}
                        onCancel={this.onClickCancel}
                    />
                </ModalForm>
                <Confirmmodal
                    loading={state.loading}
                    open={state.openConfirm}
                    title="Module"
                    onClose={() => this.setState({ openConfirm: false })}
                    onComfirm={this.deleteData}
                />
            </div>
        )
    }

    handleChangeSort = (e, data) => {
        let { search, } = this.state
        let qString

        const result = data.options.find((member) => {
            return member.value === data.value
        })
        search['sort'] = result
        this.setState({ search, sortText: `${result.text} (${result.description})`, loading: true })

        qString = '?_sort=' + search.sort.key + '&_order=' + search.sort.description
        this.getData(qString)
    }

    handleChangeSearch = (e) => {
        let { rawData, search } = this.state
        search[e.target.name] = e.target.value
        this.setState({ search, })
        if (e.target.value) {
            let newdata = []
            rawData.map(item => {
                if (item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
                    newdata.push(item)
                }
            })
            this.setState({ data: newdata })
        } else {
            this.setState({ data: rawData })
        }
    }
}

export default withRouter(CourseModule)
