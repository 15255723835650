import axios from "axios";
import * as config from "../../config";

export function getCourse() {
  return axios
    .get(config.urlAPI + "/course?active=true", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseMaster() {
  return axios
    .get(config.urlAPI + "/coursemaster", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSubjectByCourse(id) {
  return axios
    .get(config.urlAPI + "/subjectByCourseModule/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudentByCourse(id) {
  return axios
    .get(config.urlAPI + "/registrationCourse/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseBySearch(search) {
  return axios
    .get(config.urlAPI + "/course" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTrainingCourse(qString) {
  return axios
    .get(
      config.urlAPI + "/instuctorTrainingCourse" + qString,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getInstructorList(qString) {
  return axios
    .get(config.urlAPI + "/instructorslist" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getScheduletrackIP(search) {
  return axios
    .get(config.urlAPI + "/scheduletrackIP" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getScheduletrackSP(search) {
  return axios
    .get(config.urlAPI + "/scheduletrackSP" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSubjecttrack(qString) {
  return axios
    .get(config.urlAPI + "/subjecttrack" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlightGroup() {
  return axios
    .get(config.urlAPI + "/flightgroup", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlighthrstrackSP(qString) {
  return axios
    .get(config.urlAPI + "/flighthrstrackSP" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getFlighthrstrackIP(qString) {
  return axios
    .get(config.urlAPI + "/flighthrstrackIP" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
