import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Loader,
  Icon,
  Form,
  Button,
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import FromFlightSubjects from "./courseFlightSubjectsForm";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";
import {
  getSubjects,
  postSubjects,
  updateSubjects,
  getLesson,
  deleteSubject,
} from "./service";
import TableList from "../../components/tablePerPage";

const defaultValue = {
  lesson: [],
};

class FlightSubjects extends Component {
  state = {
    current_page: 0,
    per_page: 10,
    last_page: 1,
    rawData: [],
    data: [],
    loading: true,
    search: {
      sort: {
        key: "code",
        text: "Subjects",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
    },
    sortText: "Subjects (ASC)",
    options_sortby: [
      {
        key: "code",
        text: "Subjects",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
      {
        key: "code",
        text: "Subjects",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "hours",
        text: "Hours",
        value: "3",
        icon: "sort numeric ascending",
        description: "ASC ",
      },
      {
        key: "hours",
        text: "Hours",
        value: "4",
        icon: "sort numeric descending",
        description: "DESC",
      },
    ],
    options_lesson: [],
    column: [
      {
        Header: "Code",
        accessor: "name",
        sortable: false,
        maxWidth: 100,
        style: { fontWeight: "bold", cursor: "pointer", textAlign: "center" },
        Cell: (cellInfo) => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.code}
            </p>
          </span>
        ),
      },
      {
        Header: "Subject",
        accessor: "name",
        sortable: false,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: (cellInfo) => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.name}
            </p>
          </span>
        ),
      },
      {
        Header: "",
        accessor: "id",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <span>
            <p className="bold">
              {cellInfo.original.checkSubject &&
                cellInfo.original.checkSubjectType &&
                (cellInfo.original.checkSubjectType === "bacCheck" ? (
                  <p>{`BAC Check (${cellInfo.original.checkSubjectGroup})`}</p>
                ) : (
                  <p>{`CAAT Chcek (${cellInfo.original.checkSubjectGroup})`}</p>
                ))}
              {cellInfo.original.type === "FA" && "Additional"}
            </p>
          </span>
        ),
      },
      {
        Header: "Hours",
        accessor: "hours",
        sortable: false,
        style: { textAlign: "center" },
        width: 250,
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <span>
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        ),
      },
    ],
  };

  componentDidMount() {
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    this.getData(qString);
    this.getOptionLesson();
  }

  getData = (qString) => {
    getSubjects(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data.data,
          current_page: res.data.current_page - 1,
          last_page: res.data.last_page,
        });
      }
      this.setState({ loading: false });
    });
  };

  getOptionLesson = () => {
    let options_lesson = [];
    getLesson("").then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          options_lesson.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item.id,
          });
        });
        this.setState({ options_lesson });
      }
    });
  };
  onClickNew = () => {
    this.setState({
      open: true,
      currentData: defaultValue,
      isNew: true,
      content: "New Flight Subjects",
    });
  };
  onClose = () =>
    this.setState({ open: false, currentData: null, isNew: false });
  onClickCancel = () => {
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    this.onClose();
    this.getData(qString);
  };
  onClickEdit = (value) => {
    this.setState({ open: true, isNew: false, content: "Flight Subjects" });
    let syllabusIP = [],
      syllabusSP = [];
    value.syllabusIP.map((index) => (syllabusIP[index] = true));
    value.syllabusSP.map((index) => (syllabusSP[index] = true));
    value.syllabusIP = syllabusIP;
    value.syllabusSP = syllabusSP;
    if (value.type === "FA") value.additional = true;
    this.setState({ currentData: value });
  };
  onClickDelete = (value) => {
    this.setState({
      openConfirm: true,
      currentData: value,
    });
  };
  deleteData = () => {
    let { search, per_page, current_page, currentData } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    this.setState({ loading: true });
    if (currentData && currentData.id) {
      deleteSubject(currentData.id).then((res) => {
        if (res && res.status === 200) {
          this.onClose();
          this.getData(qString);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
    }
  };
  saveData = (value) => {
    this.setState({ loading: true });
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    let syllabusIP = [],
      syllabusSP = [],
      lesson = [],
      checkSubject = false,
      checkSubjectType = null,
      checkSubjectGroup = null;
    if (value.syllabusIP)
      value.syllabusIP.map((item, index) => {
        if (item) syllabusIP.push(index);
      });
    if (value.syllabusSP)
      value.syllabusSP.map((item, index) => {
        if (item) syllabusSP.push(index);
      });
    if (value.lesson)
      value.lesson.map((item, index) => {
        if (item && item.id) lesson.push(item);
      });
    if (value.checkSubjectType) {
      checkSubject = true;
      checkSubjectType = value.checkSubjectType;
      checkSubjectGroup = value.checkSubjectGroup;
    }
    let data = {
      checkSubject: checkSubject,
      checkSubjectType: checkSubjectType,
      checkSubjectGroup: checkSubjectGroup,
      code: value.code,
      hours: value.hours,
      lesson: lesson,
      name: value.name,
      objective: value.objective,
      syllabusIP: syllabusIP,
      syllabusSP: syllabusSP,
      type: value.additional ? "FA" : "F",
    };
    postSubjects(data).then((res) => {
      if (res && res.status === 201) {
        this.onClose();
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };

  updateData = (value) => {
    this.setState({ loading: true });
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=" +
      current_page;
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    const { currentData } = this.state;
    let syllabusIP = [],
      syllabusSP = [],
      lesson = [],
      checkSubject = false,
      checkSubjectType = null,
      checkSubjectGroup = null;
    if (value.syllabusIP)
      value.syllabusIP.map((item, index) => {
        if (item) syllabusIP.push(index);
      });
    if (value.syllabusSP)
      value.syllabusSP.map((item, index) => {
        if (item) syllabusSP.push(index);
      });
    if (value.lesson)
      value.lesson.map((item, index) => {
        if (item && item.id) lesson.push(item);
      });
    if (value.checkSubjectType) {
      checkSubject = true;
      checkSubjectType = value.checkSubjectType;
      checkSubjectGroup = value.checkSubjectGroup;
    }
    let data = {
      id: currentData.id,
      checkSubject: checkSubject,
      checkSubjectType: checkSubjectType,
      checkSubjectGroup: checkSubjectGroup,
      code: value.code,
      hours: value.hours,
      lesson: lesson,
      name: value.name,
      objective: value.objective,
      syllabusIP: syllabusIP,
      syllabusSP: syllabusSP,
      type: value.additional ? "FA" : "F",
    };
    updateSubjects(data).then((res) => {
      if (res && res.status === 200) {
        this.onClose();
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Flight Subjects">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Subjects"
            />
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Flight Subjects</Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course/Lesson")}
              style={{ color: "grey" }}
            >
              Lesson
            </Breadcrumb.Section>
          </Breadcrumb>
          <br />
          <br />
          <Form>
            <Form.Group>
              <Form.Input
                // width='4'
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                onChange={this.handleChangeSort}
                selectOnBlur={false}
              />
            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <TableList
              loading={state.loading}
              data={state.data}
              defaultPageSize={20}
              columns={state.column}
              pageSize={state.per_page}
              page={state.current_page}
              pages={state.last_page}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
            />
          )}
        </Segment>
        <ModalForm
          open={state.open}
          icon="plane"
          size="small"
          content={state.content}
          close={this.onClickCancel}
        >
          <FromFlightSubjects
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.isNew ? this.saveData : this.updateData}
            options_lesson={state.options_lesson}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Flight Subjects"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState(
      {
        per_page: pageSize,
        current_page: pageIndex,
      },
      () => {
        let { search, per_page, current_page } = this.state;
        current_page = current_page + 1;

        let qString =
          "/perpage?type[]=F&type[]=FA&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description +
          "&_perpage=" +
          per_page +
          "&page=" +
          current_page;
        if (search.name) {
          qString = qString + "&name=" + search.name;
        }
        this.getData(qString);
      }
    );
  };

  onPageChange = (pageIndex) => {
    this.setState(
      {
        current_page: pageIndex,
      },
      () => {
        let { search, per_page, current_page } = this.state;
        current_page = current_page + 1;
        let qString =
          "/perpage?type[]=F&type[]=FA&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description +
          "&_perpage=" +
          per_page +
          "&page=" +
          current_page;
        if (search.name) {
          qString = qString + "&name=" + search.name;
        }
        this.getData(qString);
      }
    );
  };

  handleChangeSort = (e, data) => {
    let { search, per_page } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
      loading: true,
      current_page: 0,
    });
    let qString =
      "/perpage?type[]=F&type[]=FA&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&_perpage=" +
      per_page +
      "&page=1";
    if (search.name) {
      qString = qString + "&name=" + search.name;
    }
    if (search.filter && search.filter !== "All") {
      qString = qString + "&course=" + search.filter;
    }
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let { search, per_page, current_page } = this.state;
    current_page = current_page + 1;

    this.setState({ loading: true });
    if (this._timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(this._timeout);
    }
    const val = e.target.value;
    const name = e.target.name;
    search[name] = val;
    this.setState({ search });
    this._timeout = setTimeout(() => {
      this._timeout = null;
      let qString =
        "/perpage?type[]=F&type[]=FA&_sort=" +
        search.sort.key +
        "&_order=" +
        search.sort.description +
        "&_perpage=" +
        per_page +
        "&page=" +
        current_page +
        "&name=" +
        val;
      if (search.filter && search.filter !== "All") {
        qString = qString + "&course=" + search.filter;
      }
      this.getData(qString);
    }, 2000);
  };
}

export default withRouter(FlightSubjects);
