import React, { Component } from "react";
import { Button, Modal, Header, Icon } from "semantic-ui-react";

class ModalExampleSize extends Component {
  render() {
    const { open, title, loading ,actionText} = this.props;
    return (
      <div>
        <Modal
          size="mini"
          open={open}
          //   centered={false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={this.props.onClose}
        >
          <Modal.Header
            style={{
              background: actionText ? '#03A9F4': "red",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
          >
            Confirm
          </Modal.Header>
          <Modal.Content>
            <Header as="h5">
              <Icon name="warning sign" />
              <Header.Content>
                Are you sure you want to {actionText ? actionText : 'delete'} this {title}.? <br />
                You can't undo this action.
              </Header.Content>
            </Header>
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                loading={loading}
                onClick={this.props.onComfirm}
              />
              <Button
                size="small"
                negative
                floated="right"
                onClick={this.props.onClose}
              >
                No
              </Button>
            </div>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalExampleSize;
