import axios from "axios";
import * as config from "../../config";

export function updateFlight(data) {
  return axios
    .put(config.urlAPI + "/aircraft/" + data.id, data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function postFlight(data) {
  return axios
    .post(config.urlAPI + "/aircraft", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function uploadImageProfile(id, data) {
  return axios
    .post(
      config.urlAPI + "/aircraft/" + id + "/upload",
      data,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteAircraft(id) {
  return axios
    .delete(config.urlAPI + "/aircraft/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function deleteImageProfile(id) {
  return axios
    .delete(
      config.urlAPI + "/aircraft/" + id + "/image/delete",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

// export function postAircraft(data) {
//   return axios
//     .post(config.urlAPI + "/aircraft", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

// export function postSim(data) {
//   return axios
//     .post(config.urlAPI + "/simulator", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }

export function getAircraftList() {
  return axios
    .get(config.urlAPI + "/aircraftlist", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAircraftListBySearch(search) {
  return axios
    .get(config.urlAPI + "/aircraftlist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAircraftById(id) {
  return axios
    .get(config.urlAPI + "/aircraft/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAirClass() {
  return axios
    .get(config.urlAPI + "/class/airclass", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSimClass() {
  return axios
    .get(config.urlAPI + "/class/simclass", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getMake() {
  return axios
    .get(config.urlAPI + "/aircraftmake", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getModel() {
  return axios
    .get(config.urlAPI + "/aircraftmodel", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRemid() {
  return axios
    .get(config.urlAPI + "/remindList")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRemidBySearch(search) {
  return axios
    .get(config.urlAPI + "/remindList" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getRemidDetail(id) {
  return axios
    .get(config.urlAPI + "/remind/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSnags() {
  return axios
    .get(config.urlAPI + "/snagsList")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getSnagsDetail(id) {
  return axios
    .get(config.urlAPI + "/snagsList/" + id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getSnagsBySearch(search) {
  return axios
    .get(config.urlAPI + "/snagsList/" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAircraftEvent(search) {
  return axios
    .get(config.urlAPI + "/aircraftEvent" + search)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
