import React, { Component } from "react";
import { Segment, Form, Header } from "semantic-ui-react";
import * as config from "../../config";
import ImageCard from "../../components/imagecard";
import { getEbook } from "./service";
class Ebook extends Component {
  state = {
    search: {},
    rawData: [],
    data: []
  };

  handleChangeSearch = e => {
    let { rawData, search } = this.state;

    search[e.target.name] = e.target.value;
    this.setState({ search });

    if (e.target.value) {
      let newdata = [];
      rawData.map(item => {
        let description = `${item.description}`;
        if (
          description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push({
            id: item.id,
            image: item.image || [],
            description: description
          });
        }
      });
      this.setState({ data: newdata });
    } else this.setState({ data: rawData });
  };

  componentDidMount() {
    getEbook().then(res => {
      if (res && res.status === 200) {
        this.setState({
          rawData: res.data,
          data: res.data
        });
      }
    });
  }

  render() {
    let state = this.state;

    return (
      <Segment className="contentSegment" textAlign="left">
        <Header as="h2" style={{ color: config.colorTheme }}>
          Ebooks
        </Header>

        <Form>
          <Form.Group>
            <Form.Input
              width="3"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={this.handleChangeSearch}
            />
          </Form.Group>
        </Form>
        <ImageCard
          data={state.data}
          itemsPerRow={5}
          imageHeight={170}
          onClick={item => window.open(item.url, "_blank")}
        />
      </Segment>
    );
  }
}

export default Ebook;
