import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Form,
  Breadcrumb,
  Grid,
  Card,
  Tab,
  Image,
  Table,
  Button
} from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import * as config from "../../config";
import validate from "../../validate";
import ModalForm from "../../components/modal";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderCheckboxField from "../../components/renderCheckboxField";
import HeaderWithMenu from "../../components/headerwithmenu";

class Mainpeople extends Component {
  state = {
    data: this.props.data,
    status: 3,
    search: {
      status: 4
    },
    rawdataSchedule: [],
    dataSchedule: [],
    optionsLayout: [
      { key: "g0", value: "group1", text: "Group 1" },
      { key: "g1", value: "group2", text: "Group 2" }
    ]
  };

  componentDidMount() {
    const { search } = this.state;
  }

  handleChangeDataRemind = data => {
    console.log(data);
  };
  onClickEdit = () => {
    this.setState({ open: true });
  };
  onClickSave = data => {
    this.setState({ open: false });
    this.props.reset("mainpeople");
  };
  onClickCancel = () => {
    this.setState({ open: false });
    this.props.reset("mainpeople");
  };

  onUserProfile = (id, type) => {
    this.props.history.push({
      pathname: "/Instructors/Profile",
      state: { id: id }
    });
  };
  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting } = this.props;

    return (
      <div>
        <ModalForm
          icon="edit"
          content="Edit Profile"
          open={state.open}
          close={this.onClickCancel}
          save={this.onClickCancel}
           
        >
          <Form onSubmit={handleSubmit(this.onClickSave)}>
            <Field
              name="firstname"
              type="text"
              width="16"
              required={true}
              component={renderField}
              label="First Name*"
            />
            <Field
              name="lastname"
              type="text"
              component={renderField}
              label="Last Name*"
            />
            <Field
              name="email"
              type="text"
              width="16"
              required={true}
              component={renderField}
              label="Email"
            />
            <Field
              name="phone"
              type="text"
              component={renderField}
              label="Phone"
            />
            <Field
              name="recmessage"
              width="16"
              required={true}
              component={renderCheckboxField}
              label="Can receive text messages?"
            />
            <Field
              name="layout"
              width="16"
              label="Add to group"
              required={true}
              component={renderSelectField}
              options={state.optionsLayout}
            />
            <br />
            {/* <Button compact type="submit" content='Save' color='green' disabled={pristine || submitting} /> */}
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.onClickCancel}
            />
          </Form>
        </ModalForm>
        <Segment className="contentSegment" textAlign="left" vertical>
          <Grid>
            <Grid.Row>
              <Grid.Column key={2} computer={16} mobile={16}>
                <Tab
                  renderActiveOnly
                  menu={{ secondary: true, pointing: true }}
                  panes={this.props.tabs}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

// export default withRouter(ProfileAircraft)

export default (Mainpeople = reduxForm({
  // a unique name for the form
  form: "mainpeople",
  validate,
  enableReinitialize: true
})(Mainpeople));
