import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Divider, Header, Table, Grid, Icon } from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import Formsubject from "./subjectForm";
import {
  getFlightGroup,
  getCheckSubject,
  updateTrainingCourse,
  postTrainingCourse,
  getTrainingCourse,
  getCourseSubjectDetail,
  deleteCheckCourse,
  postCheckCourse,
  deleteTrainingSubject,
  postTrainingSubject,
  postFlightGroup,
  deleteFlightGroup,
  getGroundSubject,
} from "./service";
import Wrap from "../../components/wrap";

class Subject extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    data: null,
    option_ground: [],
    option_checkcourse: [],
    option_flightcourse: [],
    rawData: [],
    type: {
      ground: false,
      flight: false,
    },
  };
  componentDidMount() {
    let { info } = this.props;
    let { type } = this.state;
    if (info.id) {
      this.getData(info.id);
    }
    if (info.instructorType) {
      info.instructorType.map((item) => {
        if (item.name === "Ground Instructor") type.ground = true;
        else if (item.name === "SIM Instructor") type.flight = true;
        else if (item.name === "Flight Instructor") type.flight = true;
      });
      this.setState({ type });
    }
    // getGroundSubject().then(res => {
    //   if (res && res.status === 200) {
    //     console.log('getGroundSubject', res)
    //     let option = [];
    //     res.data.map(item =>
    //       option.push({
    //         key: item.id,
    //         value: item.id,
    //         // text: item.name item.reference item.hours
    //         text: item.hours ?
    //           item.reference ?
    //             `${item.name} ${item.reference} (${item.hours})`
    //             : `${item.name} (${item.hours})`
    //           : `${item.name}`,
    //       })
    //     );
    //     this.setState({
    //       option_ground: option,
    //       rawData: res.data
    //     });
    //   }
    // });
    // let option = [];
    // getCheckSubject().then(res => {
    //   if (res && res.status === 200) {
    //     res.data.map(item => {
    //       option.push({
    //         key: item.id,
    //         value: item.id,
    //         text: item.code ? item.code : item.name
    //       });
    //     });
    //     this.setState({ option_checkcourse: option });
    //   }
    // });
    // getFlightGroup().then(res => {
    //   // console.log('getFlightGroup', res)
    //   if (res && res.status === 200) {
    //     this.setState({ option_flightcourse: res.data });
    //   }
    // })
  }

  getData = (id) => {
    getTrainingCourse(id).then((res) => {
      if (res && res.status === 200) {
        // console.log('getTrainingCourse', res)
        this.setState({ data: res.data });
      }
    });
  };

  show = () => {
    let { info } = this.props;
    this.setState({ fetching: true, open: true });
    let currentValue = null,
      checkCourse = [],
      trainingCourseFlight = [],
      trainingCourseGround = [];
    getTrainingCourse(info.id).then((res) => {
      if (res && res.status === 200) {
        if (res.data) {
          // console.log('getTrainingCourse', res.data);
          let data = res.data;
          let arrflightGroup = [];
          data.flightGroup.map((element, index) => {
            arrflightGroup[element.id] = true;
            // objflightGroup[element.id] = element.value
          });
          // let arrGroundGroup = [];
          data.trainingCourseGround.map((element, index) => {
            // arrGroundGroup[element.id] = true
            element.groundSubject.map((ele, index) => {
              trainingCourseGround.push(ele.id);
            })
          });
          currentValue = {
            // DCP: data.DCP ? true : false,
            // checkCourse: checkCourse,
            // trainingCourseFlight: trainingCourseFlight,
            groundCheck: data.groundCheck ? true : false,
            bacCheck: data.bacCheck ? data.bacCheck : false,
            caatCheck: data.caatCheck ? data.caatCheck : false,
            flightGroup: arrflightGroup,
            groundCourse: trainingCourseGround, //arrGroundGroup
          };
        }
        //load data
        getGroundSubject().then((res) => {
          if (res && res.status === 200) {
            console.log("getGroundSubject", res);
            let option = [];
            res.data.map((item) =>
              option.push({
                key: item.id,
                value: item.id,
                // text: item.name item.reference item.hours
                text: item.hours
                  ? item.reference
                    ? `${item.name} ${item.reference} (${item.hours})`
                    : `${item.name} (${item.hours})`
                  : `${item.name}`,
              })
            );
            this.setState({ option_ground: option, rawData: res.data });

            // this.setState({ option_ground: res.data, rawData: res.data });
            getCheckSubject().then((res) => {
              if (res && res.status === 200) {
                // console.log('getCheckSubject', res)
                let option = [];
                res.data.map((item) => {
                  option.push({
                    key: item.id,
                    value: item.id,
                    text: item.code ? item.code : item.name,
                  });
                });
                this.setState({ option_checkcourse: option });
                getFlightGroup().then((res) => {
                  // console.log('getFlightGroup', res)
                  if (res && res.status === 200) {
                    // console.log('getFlightGroup', res)
                    this.setState({ option_flightcourse: res.data });
                    this.setState({
                      fetching: false,
                      currentValue: currentValue,
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  close = () => this.setState({ open: false, currentValue: null });

  onClickSave = (value) => {
    this.setState({ loading: true });
    let { info } = this.props;
    let { currentValue, data } = this.state;
    // console.log('click save', value)
    let fg = [];
    // let gg = []
    // if (value.groundCourse) {
    //   value.groundCourse.map((tmp, index) => {
    //     // console.log('tmp', tmp)
    //     if (tmp === true) {
    //       gg.push({ [index]: tmp })
    //     }
    //   })
    // }
    if (value.flightGroup) {
      value.flightGroup.map((tmp, index) => {
        // console.log('tmp', tmp)
        if (tmp === true) {
          fg.push({ [index]: tmp });
        }
      });
    }
    let dataSave = {
      // DCP: value.DCP,
      // checkCourse: value.checkCourse,
      flightGroup: fg,
      groundCourse: value.groundCourse, // gg,
      groundCheck: value.groundCheck,
      bacCheck: value.bacCheck,
      caatCheck: value.caatCheck,
    };
    // console.log('dataSave', dataSave)
    if (info.id) {
      updateTrainingCourse(info.id, dataSave).then((res) => {
        if (res && res.status === 200) {
          // console.log('put', res)
          this.getData(info.id);
          this.close();
        }
        this.setState({ loading: false });
      });
    }
  };

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  render() {
    const state = this.state;
    const props = this.props;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log('state.data.trainingCourseFlight', state.data.trainingCourseFlight)
    return (
      <div>
        {this.checkPermissionMenu("Personnel", ["manage-instructor"]) && (
          <Form>
            <Form.Field>
              <MainButton
                size="small"
                onClick={this.show}
                text="Edit Training Course"
              />
            </Form.Field>
          </Form>
        )}
        <Wrap>
          <Divider horizontal>
            <Header as="h4">Check Subjects</Header>
          </Divider>
          <Grid>
            <Grid.Row columns="3">
              {state.type.flight && (
                <Wrap>
                  <Grid.Column>
                    <Table compact celled style={{ marginBottom: "30px" }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell className="header-table" width={10}>
                            Flight & SIM Check
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            width={3}
                            textAlign="center"
                          >
                            hours
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {state.data &&
                          state.data.trainingCourseCheck &&
                          state.data.trainingCourseCheck.bacCheck.map(
                            (bac, index) => (
                              <Wrap>
                                <Table.Row key={index}>
                                  <Table.Cell
                                    textAlign="center"
                                    colSpan="2"
                                    active
                                  >
                                    <b>{bac.name}</b>
                                  </Table.Cell>
                                </Table.Row>
                                {bac.subject &&
                                  bac.subject.length > 0 &&
                                  bac.subject.map((sub, index) => (
                                    <Wrap>
                                      <Table.Row key={sub.id}>
                                        <Table.Cell>
                                          {sub.code} {sub.name}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                          {sub.hours}
                                        </Table.Cell>
                                      </Table.Row>
                                    </Wrap>
                                  ))}
                              </Wrap>
                            )
                          )}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column>
                    <Table compact celled style={{ marginBottom: "30px" }}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell className="header-table" width={10}>
                            CAAT Check
                          </Table.HeaderCell>
                          <Table.HeaderCell
                            className="header-table"
                            width={3}
                            textAlign="center"
                          >
                            hours
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {state.data &&
                          state.data.trainingCourseCheck &&
                          state.data.trainingCourseCheck.caatCheck.map(
                            (caat, index) => (
                              <Wrap>
                                <Table.Row key={index}>
                                  <Table.Cell
                                    textAlign="center"
                                    colSpan="2"
                                    active
                                  >
                                    <b>{caat.name}</b>
                                  </Table.Cell>
                                </Table.Row>
                                {caat.subject &&
                                  caat.subject.length > 0 &&
                                  caat.subject.map((sub, index) => (
                                    <Wrap>
                                      <Table.Row key={sub.id}>
                                        <Table.Cell>
                                          {sub.code} {sub.name}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                          {sub.hours}
                                        </Table.Cell>
                                      </Table.Row>
                                    </Wrap>
                                  ))}
                              </Wrap>
                            )
                          )}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Wrap>
              )}
              {state.type.ground && (
                <Grid.Column>
                  <Table compact celled style={{ marginBottom: "30px" }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={10}>
                          Ground Check
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          width={3}
                          textAlign="center"
                        >
                          hours
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.data &&
                        state.data.trainingCourseCheck &&
                        state.data.trainingCourseCheck.groundCheck.map(
                          (grd, index) => (
                            <Wrap>
                              <Table.Row key={grd.id}>
                                <Table.Cell>
                                  {grd.code} {grd.name}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {grd.hours}
                                </Table.Cell>
                              </Table.Row>
                            </Wrap>
                          )
                        )}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Wrap>
        <Wrap>
          <Divider horizontal>
            <Header as="h4">Course</Header>
          </Divider>
          <Grid columns="2">
            <Grid.Row>
              {state.type.ground && (
                <Grid.Column>
                  <Table
                    compact
                    celled
                    // striped
                    style={{ marginBottom: "30px" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={10}>
                          Ground Subjects
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          width={3}
                          textAlign="center"
                        >
                          hours
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.data &&
                        state.data.trainingCourseGround &&
                        state.data.trainingCourseGround.length > 0 &&
                        state.data.trainingCourseGround.map((grp, index) => (
                          <Wrap>
                            <Table.Row key={grp.index}>
                              <Table.Cell textAlign="center" colSpan="2" active>
                                <b>{grp.groundGroup}</b>
                              </Table.Cell>
                            </Table.Row>
                            {grp.groundSubject &&
                              grp.groundSubject.length > 0 &&
                              grp.groundSubject.map((sub, index) => (
                                <Wrap>
                                  <Table.Row key={sub.id}>
                                    <Table.Cell>
                                      {sub.code} {sub.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {sub.hours}
                                    </Table.Cell>
                                  </Table.Row>
                                </Wrap>
                              ))}
                          </Wrap>
                        ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
              {state.type.flight && (
                <Grid.Column>
                  <Table
                    compact
                    celled
                    // striped
                    style={{ marginBottom: "30px" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={10}>
                          Flight Subjects
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          className="header-table"
                          width={3}
                          textAlign="center"
                        >
                          hours
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.data &&
                        state.data.trainingCourseFlight &&
                        state.data.trainingCourseFlight.length > 0 &&
                        state.data.trainingCourseFlight.map((grp, index) => (
                          <Wrap>
                            <Table.Row key={grp.index}>
                              <Table.Cell textAlign="center" colSpan="2" active>
                                <b>{grp.flightGroup}</b>
                              </Table.Cell>
                            </Table.Row>
                            {grp.flightSubject &&
                              grp.flightSubject.length > 0 &&
                              grp.flightSubject.map((sub, index) => (
                                <Wrap>
                                  <Table.Row key={sub.id}>
                                    <Table.Cell>
                                      {sub.code} {sub.name}
                                    </Table.Cell>
                                    <Table.Cell textAlign="center">
                                      {sub.hours}
                                    </Table.Cell>
                                  </Table.Row>
                                </Wrap>
                              ))}
                          </Wrap>
                        ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Wrap>
        <ModalForm
          size="small"
          icon="sticky note outline"
          content="Training Course"
          open={state.open}
          close={this.close}
        >
          <Formsubject
            loading={state.loading}
            onSubmit={this.onClickSave}
            initialValues={state.currentValue ? state.currentValue : null}
            info={props.info}
            rawData={state.rawData}
            fetching={state.fetching}
            option_ground={state.option_ground}
            option_checkcourse={state.option_checkcourse}
            option_flightcourse={state.option_flightcourse}
          />
        </ModalForm>
      </div>
    );
  }
}

export default withRouter(Subject);
// export default (Subject = reduxForm({
//   // a unique name for the form
//   form: "Subject"
//   // validate,
//   // enableReinitialize: true
// })(Subject));
