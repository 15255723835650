import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Loader,
  Segment,
  Breadcrumb,
  Icon,
  Form,
  Header,
  Button,
  Grid,
  Popup,
  Table,
  Modal,
} from "semantic-ui-react";
import * as config from "../../config";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import Formcourse from "./courseForm";
import FormcourseFlightHour from "./courseFlightHourForm";
import TableList from "../../components/table";

import {
  getFlightHourCriteria,
  getCourse,
  getCourseMaster,
  getGroundInstructionType,
  postCourse,
  deleteCourse,
  getCourseListBySearch,
  getStudentRegistered,
} from "./service";
import moment from "moment";

class Course extends Component {
  state = {
    rawData: [],
    data: [],
    loading: true,
    dataMaster: [],
    option_course: [],
    option_groundinstructiontype: [],
    search: {
      status: "Active",
      sort: {
        key: "additionalCourse",
        text: "Default",
        value: "3",
        icon: "sort",
        description: "",
      },
    },
    sortText: "Default",
    options_sortby: [
      {
        key: "additionalCourse",
        text: "Default",
        value: "3",
        icon: "sort",
        description: "",
      },
      {
        key: "name",
        text: "Course",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
      {
        key: "name",
        text: "Course",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "desc",
        text: "Description",
        value: "4",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
      {
        key: "desc",
        text: "Description",
        value: "5",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "created_at",
        text: "Newest",
        value: "6",
        icon: "sort",
        description: "DESC",
      },
    ],
    column: [
      {
        Header: "Course",
        accessor: "name",
        sortable: false,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: (cellInfo) => (
          <span>
            <p
              className="bold"
              style={{
                cursor: "pointer",
              }}
              onClick={() => this.onClickProfile(cellInfo.original)}
            >
              {cellInfo.original.name}{" "}
              {cellInfo.original.active && (
                <small
                  style={{
                    paddingLeft: "10px",
                    color: "grey",
                  }}
                >
                  <Icon name="check circle" fitted color="green" /> Active
                </small>
              )}
            </p>
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "desc",
        sortable: false,
        Cell: (cellInfo) => (
          <span>
            <p
              className="bold"
              style={{
                cursor: "pointer",
              }}
              onClick={() => this.onClickProfile(cellInfo.original)}
            >
              {cellInfo.original.desc}
            </p>
          </span>
        ),
      },
      {
        Header: "",
        accessor: "additionalCourse",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <p> {cellInfo.original.additionalCourse && "Additional Course"}</p>
        ),
      },
      {
        Header: "Ground Instruction Type",
        accessor: "language",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <p>
            {" "}
            {cellInfo.original.groundinstructiontype &&
              cellInfo.original.groundinstructiontype.name}{" "}
          </p>
        ),
      },
      {
        Header: "Course Type",
        accessor: "courseType",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "Start",
        accessor: "start",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <p>
            {moment(cellInfo.original.start, "DD-MM-YYYY").format(
              "D MMMM YYYY"
            )}{" "}
          </p>
        ),
      },
      {
        Header: "End",
        accessor: "end",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <p>
            {" "}
            {moment(cellInfo.original.end, "DD-MM-YYYY").format(
              "D MMMM YYYY"
            )}{" "}
          </p>
        ),
      },
      {
        Header: "Created",
        accessor: "created_at",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) => (
          <p>{moment(cellInfo.original.created_at).format("D MMMM YYYY")} </p>
        ),
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <span>
            <Icon
              link
              // circular
              color="orange"
              name="student"
              onClick={() => this.onClickProfile(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        ),
      },
    ],
  };

  componentDidMount() {
    let { search } = this.state;
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (search.status === "Active") qString = qString + "&active=true";
    else qString = qString + "&active=false";

    this.getData(qString);
    getCourseMaster().then((res) => {
      if (res && res.status === 200) {
        let option_course = [];
        res.data.map((item) => {
          option_course.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item,
          });
        });
        this.setState({ dataMaster: res.data, option_course });
      }
    });
    getGroundInstructionType().then((res) => {
      let { option_groundinstructiontype } = this.state;
      if (res && res.status === 200) {
        res.data.map((item) => {
          option_groundinstructiontype.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item.id,
          });
        });
        this.setState({ option_groundinstructiontype });
      }
    });
  }

  getData = (qString) => {
    getCourseListBySearch(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data, rawData: res.data });
      }
      this.setState({ loading: false });
    });
  };

  onClickNew = () => {
    this.setState({
      open: true,
      content: "New Course",
    });
  };
  onClickCancel = () => {
    this.setState({
      open: false,
      openConfirmDelete: false,
    });
  };

  onClickDelete = (value) => {
    getStudentRegistered(value.id).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          openConfirmDelete: true,
          currentData: value,
          currentStudent: res.data,
        });
      }
    });
  };

  onComfirmDeleteCourse = () => {
    let { currentData } = this.state;
    this.setState({ loading: true });

    if (currentData && currentData.id)
      deleteCourse(currentData.id).then((res) => {
        if (res && res.status === 200) {
          this.setState({ loading: false });
          let { search } = this.state;
          let qString =
            "?_sort=" + search.sort.key + "&_order=" + search.sort.description;

          if (search.status === "Active") qString = qString + "&active=true";
          else qString = qString + "&active=false";

          this.getData(qString);
          this.onClickCancel();
        }
      });
  };

  onClickProfile = (value) => {
    this.props.history.push({
      pathname: "/Course/CourseDetail",
      state: { id: value.id },
    });
  };

  saveData = (value) => {
    let { search } = this.state;
    this.setState({ loading: true });
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    let module = [];
    value.course.module.map((item) => {
      if (item.id) module.push(item.id);
    });
    let data = {
      coursemaster: value.course.id,
      name: value.course.name,
      desc: value.desc,
      additionalCourse: value.additionalCourse ? "true" : "false",
      groundinstructiontype:
        value.groundinstructiontype === "default"
          ? null
          : value.groundinstructiontype,
      active: "true",
      start: value.courseStart,
      end: value.courseEnd,
      courseType: value.courseType,
      module: module,
    };

    postCourse(data).then((res) => {
      if (res && res.status === 201) {
        this.setState({ loading: false });
        this.onClickProfile(res.data);
        // this.onClickCancel();
        // this.getData(qString);
      }
    });
  };

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Course">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Course"
            />
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Course </Breadcrumb.Section>
            {/* <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course/Module")}
              style={{ color: "grey" }}
            >
              Module
            </Breadcrumb.Section> */}
          </Breadcrumb>
          <br />
          <br />
          <Form>
            <Form.Group>
              <Form.Input
                // width='4'
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                onChange={this.handleChangeSort}
                selectOnBlur={false}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='3'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{state.search.status}</b>
                  </Button>
                }
                options={config.options_active}
                defaultValue={state.search.status}
                selectOnBlur={false}
                onChange={this.handleChangeStatus}
              />
            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <TableList
              loading={state.loading}
              data={state.data}
              defaultPageSize={20}
              columns={state.column}
            />
          )}
        </Segment>
        <ModalForm
          open={state.open}
          icon="book"
          // size='small'
          content={state.content}
          close={this.onClickCancel}
        >
          <Formcourse
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={this.saveData}
            option_course={state.option_course}
            option_groundinstructiontype={state.option_groundinstructiontype}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <ModalForm
          open={state.openProgress}
          icon="line graph"
          content={`Flight Hour Progress : ${
            state.currentData && state.currentData.name
          }`}
          close={this.onClickCancel}
        >
          <FormcourseFlightHour
            course={state.currentData}
            initialValues={state.currentHourProgress}
            onSubmit={this.saveProgress}
            onCancel={this.onClickCancel}
          />
        </ModalForm>

        <Modal
          size="small"
          open={state.openConfirmDelete}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={this.onClickCancel}
        >
          <Modal.Header
            style={{
              background: "red",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Delete Course
          </Modal.Header>
          <Modal.Content>
            <Header as="h5">
              <Icon name="warning sign" />
              <Header.Content>
                Are you sure you want to delete this Course.? You can't undo
                this action.
              </Header.Content>
            </Header>
            {state.currentStudent && state.currentStudent.length > 0 && (
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      Date
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      Status
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.currentStudent.map((reg) => (
                    <Table.Row>
                      <Table.Cell>{`${reg.studentId || ""} ${reg.title || ""} ${
                        reg.firstName
                      } ${reg.lastName} ${reg.customerCode || ""}`}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {" "}
                        {reg.regisdate}
                      </Table.Cell>
                      <Table.Cell textAlign="center">{reg.status} </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            <br />
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                loading={state.loading}
                onClick={this.onComfirmDeleteCourse}
              />
              <Button
                size="small"
                negative
                floated="right"
                onClick={this.onClickCancel}
              >
                No
              </Button>
            </div>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }

  handleChangeSort = (e, data) => {
    let { search } = this.state;
    let qString;

    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText:
        result.text +
        " " +
        (result.description ? "(" + result.description + ")" : ""),
      loading: true,
    });

    qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;

    if (search.status === "Active") qString = qString + "&active=true";
    else qString = qString + "&active=false";
    this.getData(qString);
  };

  handleChangeStatus = (e, data) => {
    let { search } = this.state;
    let active, qString;

    if (data.value === "Active") active = true;
    else active = false;

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search, loading: true });
    qString =
      "?_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description +
      "&active=" +
      active;
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let { rawData, search } = this.state;
    search[e.target.name] = e.target.value;
    this.setState({ search });
    if (e.target.value) {
      let newdata = [];
      rawData.map((item) => {
        let name = item.name + " " + item.desc;
        if (name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawData });
    }
  };
}

export default withRouter(Course);
