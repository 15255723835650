import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Icon,
  Header,
  Image,
  Label,
  Button,
  Table,
  Modal,
  Form,
  Message
} from "semantic-ui-react";
import html2canvas from "html2canvas";
import moment from "moment";
import * as config from "../../config";
import Wrap from "../../components/wrap";
import FlightData from "./flightData";
import ReactToPrint from "react-to-print";
import { updateGroundDetail, getImage } from "./service";

class eventDetail extends Component {
  state = {
    open: false,
    data: this.props && this.props.data,
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_roles: JSON.parse(localStorage.getItem("bacUserData"))
  };
  printToPng = () => {
    window.scrollTo(0, 0);
    html2canvas(document.getElementById("print_to_png"), {
      logging: true,
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      scale: 2,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      var dataURL = canvas.toDataURL("image/png");
      var a = document.createElement("a");
      a.href = dataURL;
      if (this.props && this.props.data && this.props.data.reservationNumber)
        a.download = this.props.data.reservationNumber + ".png";
      else a.download = "my-reservation.png";
      document.body.appendChild(a);
      a.click();
    });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  onInstructorUpdate = (type, value) => {
    this.setState({ open: true, type: type, detail: value });
  };
  onClose = () => {
    this.setState({ open: false, type: null, detail: null });
  };
  onComfirm = () => {
    let { data, type, detail } = this.state;
    data[type] = detail;
    this.setState({ loading: true });
    updateGroundDetail({ id: data.id, [type]: detail }).then((res) => {
      if (res && res.status === 200) {
        this.setState({ open: false, type: null, detail: null, data: data });
      } else {
        this.onClose();
      }
      this.setState({
        loading: false,
      });
    });
  };

  // imageToBase64 = (img) => {
  //   // console.log(
  //   let c = this.toDataURL(
  //     "https://pbs.twimg.com/profile_images/1288475083044864001/3jSMqUIN_400x400.jpg",
  //     function (dataUrl) {
  //       return  dataUrl;
  //     }
  //   );
  //   console.log(c);
  //   // );
  //   // return this.toDataURL(
  //   //   "https://pbs.twimg.com/profile_images/1288475083044864001/3jSMqUIN_400x400.jpg"
  //   // );
  //   // imageToBase64(img)
  //   //   .then((response) => {
  //   //     console.log(response); // "iVBORw0KGgoAAAANSwCAIA..."
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error); // Logs an error if there was one
  //   //   });
  // };
  // toDataURL(url, callback) {
  //   let xhRequest = new XMLHttpRequest();
  //  return xhRequest.onload = function () {
  //     let reader = new FileReader();
  //     reader.onloadend = function () {
  //       callback(reader.result);
  //     };
  //    return reader.readAsDataURL(xhRequest.response);
  //   };
  //   xhRequest.open("GET", url);
  //   xhRequest.responseType = "blob";
  //   xhRequest.send();
  // }

  render() {
    const { disableClick, canManage } = this.props;
    const { data } = this.state;
    const state = this.state;
    console.log(disableClick, canManage);
    // (state.bac_roles.indexOf("Admin") !== -1 || state.bac_roles.indexOf("SuperAdmin") !== -1 ) &&
    return (
      <Wrap>
        <Modal
          size="mini"
          open={state.open}
          dimmer="blurring"
          closeOnDimmerClick={false}
        >
          <Modal.Header
            style={{
              background: "#03A9F4",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Update {state.type}
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Form.TextArea
                label="Detail"
                name="detail"
                value={state.detail}
                onChange={this.handleChange}
              />
            </Form>
            <br />
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                loading={state.loading}
                onClick={this.onComfirm}
              />
              <Button
                size="small"
                negative
                floated="right"
                onClick={this.onClose}
              >
                No
              </Button>
            </div>
            <br />
          </Modal.Content>
        </Modal>
        <Grid style={{ padding: "10px" }}>
          <Grid.Row>
            <Grid.Column width="13" id="print_to_png">
              <span ref={(el) => (this.componentRef = el)}>
                <Grid>
                  <Grid.Row
                    columns="3"
                    style={{
                      background: "rgba(167, 167, 167, 0.1)",
                      borderRadius: "10px",
                      // boxShadow: "0 2px 3px 0 hsla(0,0%,0%,0.15)",
                    }}
                  >
                    <Grid.Column width="11">
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="7" textAlign="center">
                            <Header
                              as="h2"
                              style={{ marginTop: "7px" }}
                              content={moment(data.start).format("ddd,MMM  DD")}
                              subheader={moment(data.start).format("HH:mm")}
                            />
                          </Grid.Column>
                          <Grid.Column
                            width="2"
                            textAlign="center"
                            verticalAlign="middle"
                          >
                            <Header
                              as="h4"
                              style={{ color: config.colorTheme }}
                            >
                              <Icon name="arrow right" />
                            </Header>
                          </Grid.Column>
                          <Grid.Column width="7" textAlign="center">
                            <Header
                              as="h2"
                              style={{ marginTop: "7px" }}
                              content={moment(data.end).format("ddd,MMM  DD")}
                              subheader={moment(data.end).format("HH:mm")}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width="5" style={{ marginTop: "7px" }}>
                      {data.reservationTypeName}
                      <br />
                      {data.status === 0 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#FF9800",
                            color: "#ffffff",
                            border: "1px solid #FF9800",
                            borderRadius: "14px",
                          }}
                          content="Reserved"
                        />
                      ) : data.status === 2 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#27A8A2",
                            color: "#ffffff",
                            border: "1px solid #27A8A2",
                            borderRadius: "14px",
                          }}
                          content="Completed"
                        />
                      ) : data.status === 3 ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#CE2921",
                            color: "#ffffff",
                            border: "1px solid #CE2921",
                            borderRadius: "14px",
                          }}
                          content="Cancelled"
                        />
                      ) : (
                        // 1
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#96a7af",
                            color: "#ffffff",
                            border: "1px solid #96a7af",
                            borderRadius: "14px",
                          }}
                          content="Unsuccess"
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  {data.displayType === 2 ? (
                    //  G
                    <Wrap>
                      <h3
                        style={{
                          marginbottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {`${data.title}`}
                        <br />
                      </h3>
                      <Grid.Row
                        columns="3"
                        style={{ paddingBottom: "0px", paddingTop: "20px" }}
                      >
                        <Grid.Column>
                          <p className="header-reservation">Class Instructor</p>

                          <Image
                            style={{ marginBottom: "10px", height: "100px" }}
                            src={
                              (data.instructor &&
                                'data:image/png;base64,' + data.instructor.imageURL64) ||
                              "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg"
                            }
                            // src={this.imageToBase64(data.instructor.imageURL)}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg";
                            }}
                            size="tiny"
                            circular
                          />
                          <div id="base64"></div>

                          <b>
                            {data.instructor
                              ? `${data.instructor.firstName} ${data.instructor.lastName}`
                              : "-"}
                          </b>
                          <p>
                            {data.instructor &&
                              data.instructor.phone &&
                              data.instructor.phone}
                          </p>
                        </Grid.Column>
                        <Grid.Column>
                          <p className="header-reservation">Room</p>
                          <b>{data.roomName}</b>
                          <br />
                          <br />
                          {data.hangoutLink && (
                            <p>
                              <Image
                                src="/img/ho-icon.png"
                                avatar
                                style={{ width: "1.5em", height: "1.5em" }}
                              />
                              <span>
                                <a
                                  style={{ color: "#058477" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={data.hangoutLink}
                                >
                                  Google Meet
                                </a>
                              </span>
                            </p>
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          <p className="header-reservation">Reservation #</p>
                          <b>{data.reservationNumber}</b>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                        <Grid.Column>
                          <p className="header-reservation">Attendees</p>
                          {data.attendees.map((item) => (
                            <b>
                              <div>
                                {" "}
                                {data.status === 2 && item.attendees ? (
                                  <Icon color="green" name="check square" />
                                ) : (
                                  data.status === 2 && (
                                    <Icon color="red" name="x" />
                                  )
                                )}
                                {`${item.studentId} ${item.firstName} ${item.lastName
                                  } ${item.class ? item.class : ""}`}
                                {data.status === 2 && data.testSubject === "true" && item.passed ? (
                                  <small style={{ color: "green" }}>
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; PASSED
                                  </small>
                                ) : data.status === 2 && data.testSubject === "true" && (
                                  <small style={{ color: "red" }}>
                                    {" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp; NOT PASSED
                                  </small>
                                )}
                              </div>
                            </b>
                          ))}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                        {data.SPObserver && (
                          <Grid.Column>
                            <p className="header-reservation">
                              Student Observer{" "}
                            </p>
                            <p>
                              {data.SPObserver.map((item) => (
                                <div>
                                  <b>
                                    {`${item.studentId} ${item.firstName} ${item.lastName} `}
                                  </b>
                                  {item.phone && item.phone}
                                </div>
                              ))}{" "}
                            </p>
                          </Grid.Column>
                        )}

                        {data.IPObserver && (
                          <Grid.Column>
                            <br />
                            <p className="header-reservation">
                              Instructor Observer
                            </p>
                            <p>
                              {data.IPObserver.map((item) => (
                                <div>
                                  <b>{`${item.firstName} ${item.lastName} `}</b>
                                  {item.phone && item.phone}
                                </div>
                              ))}{" "}
                            </p>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    </Wrap>
                  ) : data.displayType === 3 ? (
                    // Other
                    <Wrap>
                      <h3
                        style={{
                          marginbottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {data.title}
                      </h3>
                      <Grid.Row
                        columns="3"
                        style={{ paddingBottom: "0px", paddingTop: "20px" }}
                      >
                        <Grid.Column>
                          <p className="header-reservation">Instructor</p>
                          {data.instructor && (
                            <Image
                              style={{ marginBottom: "10px", height: "100px" }}
                              src={data.instructor &&
                                'data:image/png;base64,' + data.instructor.imageURL64 ||
                                "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg";
                              }}
                              size="tiny"
                              circular
                            />
                          )}
                          <b>
                            {data.instructor
                              ? `${data.instructor.firstName} ${data.instructor.lastName}`
                              : "-"}
                          </b>
                          <p>
                            {data.instructor &&
                              data.instructor.phone &&
                              data.instructor.phone}
                          </p>
                        </Grid.Column>
                        {data.roomName && (
                          <Grid.Column>
                            <p className="header-reservation">Room</p>
                            <b>{data.roomName}</b>
                            <br />
                            <br />
                            {data.hangoutLink && (
                              <p>
                                <Image
                                  src="/img/ho-icon.png"
                                  avatar
                                  style={{ width: "1.5em", height: "1.5em" }}
                                />
                                <span>
                                  <a
                                    style={{ color: "#058477" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={data.hangoutLink}
                                  >
                                    Google Meet
                                  </a>
                                </span>
                              </p>
                            )}
                          </Grid.Column>
                        )}
                        <Grid.Column>
                          <p className="header-reservation">Reservation #</p>
                          <b>{data.reservationNumber}</b>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                        <Grid.Column>
                          <p className="header-reservation">Attendees</p>
                          {data.attendees.length > 0 ? (
                            data.attendees.map((item) => (
                              <b>
                                {`${item.studentId} ${item.firstName} ${item.lastName
                                  } ${item.class ? item.class : ""}`}
                                <br />
                              </b>
                            ))
                          ) : (
                            <b>-</b>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Wrap>
                  ) : data.displayType === 4 ? (
                    <Wrap>
                      <h3
                        style={{
                          marginbottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {data.title}
                      </h3>
                      <Grid.Row
                        columns="2"
                        style={{ paddingBottom: "0px", paddingTop: "20px" }}
                      >
                        <Grid.Column>
                          <p className="header-reservation">Aircraft</p>
                          <b>{data.aircraft.name}</b>
                        </Grid.Column>
                      </Grid.Row>
                    </Wrap>
                  ) : data.displayType === 4 ? (
                    <Wrap>
                      <h3
                        style={{
                          marginbottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        {data.title}
                      </h3>
                      <Grid.Row
                        columns="2"
                        style={{ paddingBottom: "0px", paddingTop: "20px" }}
                      >
                        <Grid.Column>
                          <p className="header-reservation">Aircraft</p>
                          <b>{data.aircraft.name}</b>
                        </Grid.Column>
                      </Grid.Row>
                    </Wrap>
                  ) : (
                    data.displayType === 0 && (
                      <Wrap>
                        <h3
                          style={{
                            marginbottom: "0px",
                            marginBottom: "0px",
                          }}
                        >
                          {`${data.title}`}
                        </h3>

                        <Grid.Row
                          columns={
                            data.name === "Flight Non-Training" &&
                              data.customername &&
                              data.customername !== "-"
                              ? 3
                              : 2
                          }
                          style={{ paddingBottom: "0px", paddingTop: "20px" }}
                        >
                          <Grid.Column>
                            <p className="header-reservation">Instructor</p>
                            <Image
                              style={{ marginBottom: "10px", height: "100px" }}
                              src={
                                (data.instructor && 'data:image/png;base64,' + data.instructor.imageURL64) ||
                                "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg";
                              }}
                              size="tiny"
                              circular
                            />
                            <b>
                              {data.instructor
                                ? `${data.instructor.firstName} ${data.instructor.lastName}`
                                : "-"}
                            </b>
                            <p>
                              {data.instructor &&
                                data.instructor.phone &&
                                data.instructor.phone}
                            </p>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="header-reservation">Attendees </p>
                            <Image
                              style={{ marginBottom: "10px", height: "100px" }}
                              src={
                                (data.attendees &&
                                  data.attendees.length > 0 &&
                                  'data:image/png;base64,' + data.attendees[0].imageURL64) ||
                                "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg";
                              }}
                              size="tiny"
                              circular
                            />
                            <b>
                              {data.attendees && data.attendees.length > 0
                                ? `${data.attendees[0].studentId} ${data.attendees[0].firstName} ${data.attendees[0].lastName} ${data.attendees[0].class}`
                                : "-"}
                            </b>
                            {data.attendees && data.attendees.length > 0 && (
                              <p>{data.attendees[0].phone}</p>
                            )}
                          </Grid.Column>
                          {data.name === "Flight Non-Training" &&
                            data.customername &&
                            data.customername !== "-" && (
                              <Grid.Column>
                                <p className="header-reservation">Customer </p>
                                <Image
                                  style={{
                                    marginBottom: "10px",
                                    height: "100px",
                                  }}
                                  src="https://www.xing.com/assets/frontend_minified/img/users/nobody_m.1024x1024.jpg"
                                  size="tiny"
                                  circular
                                />
                                <b>{data.customername}</b>
                              </Grid.Column>
                            )}
                        </Grid.Row>
                        <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                          <Grid.Column>
                            <p className="header-reservation">Aircraft</p>
                            <b>{data.aircraftName}</b>
                          </Grid.Column>
                          <Grid.Column>
                            <p className="header-reservation">Reservation #</p>
                            <b>{data.reservationNumber}</b>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                          {data.flightData && data.flightData.flightRules && (
                            <Grid.Column>
                              <p className="header-reservation">Flight Rules</p>
                              <b>{data.flightData.flightRules}</b>
                            </Grid.Column>
                          )}
                          {data.flightData && data.flightData.flightRoute && (
                            <Grid.Column>
                              <p className="header-reservation">Route/Legs</p>
                              <b>
                                {data.flightData.flightRoute}{" "}
                                {data.flightData.area &&
                                  `(${data.flightData.area})`}
                              </b>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                        <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                          {data.flightData && data.flightData.callSign && (
                            <Grid.Column>
                              <p className="header-reservation">Call sign</p>
                              <b>{data.flightData.callSign}</b>
                            </Grid.Column>
                          )}
                          {data.trainningRecord && data.trainningRecord.landing && (
                            <Grid.Column>
                              <p className="header-reservation">Number of Land</p>
                              <b>{data.trainningRecord.landing}</b>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                        <Grid.Row columns="2" style={{ paddingBottom: "0px" }}>
                          {data.flightData && data.flightData.flightType && (
                            <Grid.Column>
                              <p className="header-reservation">Flight Type</p>
                              <b>{data.flightData.flightType}</b>
                              {data.flightData.monitorname && (
                                <p>
                                  {data.flightData.monitorname}
                                  {data.flightData.monitor_result &&
                                    data.flightData.monitor_result === "true" ? (
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      PASS{" "}
                                    </span>
                                  ) : (
                                    data.flightData.monitor_result ===
                                    "false" && (
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        Not Passed{" "}
                                      </span>
                                    )
                                  )}
                                </p>
                              )}
                              {data.flightData.testname && (
                                <p>
                                  {data.flightData.testname}
                                  {data.flightData.test_result &&
                                    data.flightData.test_result === "true" ? (
                                    <span style={{ color: "green" }}>
                                      {" "}
                                      PASS{" "}
                                    </span>
                                  ) : (
                                    data.flightData.test_result === "false" && (
                                      <span style={{ color: "red" }}>
                                        {" "}
                                        Not Passed{" "}
                                      </span>
                                    )
                                  )}
                                </p>
                              )}
                            </Grid.Column>
                          )}
                        </Grid.Row>
                        <Grid.Row columns="1" style={{ paddingBottom: "20px" }}>
                          {data.SPObserver && (
                            <Grid.Column>
                              <p className="header-reservation">
                                Student Observer{" "}
                              </p>
                              <p>
                                {data.SPObserver.map((item) => (
                                  <div>
                                    <b>
                                      {`${item.studentId} ${item.firstName} ${item.lastName} `}
                                    </b>
                                    {item.phone && item.phone}
                                  </div>
                                ))}{" "}
                              </p>
                            </Grid.Column>
                          )}
                          {data.IPObserver && (
                            <Grid.Column>
                              <br />
                              <p className="header-reservation">
                                Instructor Observer
                              </p>
                              <p>
                                {data.IPObserver.map((item) => (
                                  <div>
                                    <b>{`${item.firstName} ${item.lastName} `}</b>
                                    {item.phone && item.phone}
                                  </div>
                                ))}{" "}
                              </p>
                            </Grid.Column>
                          )}
                          {data.flightData && data.flightData.instructorSV && (
                            <Grid.Column>
                              <br />
                              <p className="header-reservation">
                                Instructor - SV
                              </p>
                              <p>
                                <b>
                                  {`${data.flightData.instructorSV.firstName} ${data.flightData.instructorSV.lastName} `}
                                </b>
                                {data.flightData.instructorSV &&
                                  data.flightData.instructorSV.phone &&
                                  data.flightData.instructorSV.phone}
                              </p>
                            </Grid.Column>
                          )}
                          {data.flightData && data.flightData.instructorPro && (
                            <Grid.Column>
                              <br />
                              <p className="header-reservation">
                                Instructor - Proficiency Check
                              </p>
                              <p>
                                <b>
                                  {`${data.flightData.instructorPro.firstName} ${data.flightData.instructorPro.lastName} `}
                                </b>
                                {data.flightData.instructorPro &&
                                  data.flightData.instructorPro.phone &&
                                  data.flightData.instructorPro.phone}
                              </p>
                            </Grid.Column>
                          )}
                          {data.flightData && data.flightData.instructorIP && (
                            <Grid.Column>
                              <br />
                              <p className="header-reservation">
                                Instructor - ฝึก IP
                              </p>
                              <p>
                                {" "}
                                <b>
                                  {`${data.flightData.instructorIP.firstName} ${data.flightData.instructorIP.lastName} `}
                                </b>
                                {data.flightData.instructorIP &&
                                  data.flightData.instructorIP.phone &&
                                  data.flightData.instructorIP.phone}
                              </p>
                            </Grid.Column>
                          )}
                        </Grid.Row>
                      </Wrap>
                    )
                  )}
                  <Grid.Row columns="1">
                    {data.flightData && (
                      <Grid.Column>
                        <Header
                          as="h3"
                          style={{
                            color: config.colorTheme,
                            padding: "7px",
                            background: "#efefef",
                            marginBottom: "0px",
                          }}
                        >
                          Flight # {data.flightData.flightNumber}
                        </Header>
                        <FlightData data={data} flightData={data.flightData} />
                      </Grid.Column>
                    )}
                    {data.displayType === 2 && (
                      <Grid.Column>
                        <Header
                          as="h3"
                          style={{
                            color: config.colorTheme,
                            padding: "7px",
                            background: "#efefef",
                            marginBottom: "0px",
                          }}
                        >
                          Material Requirement
                          {data.instructorId &&
                            state.bacUserData &&
                            state.bacUserData.id === data.instructorId && (
                              <Button
                                compact
                                floated="right"
                                size="mini"
                                content="Edit"
                                positive
                                onClick={() =>
                                  this.onInstructorUpdate(
                                    "material",
                                    data.material
                                  )
                                }
                              />
                            )}
                        </Header>

                        <p
                          style={{
                            color: "rgb(115, 115, 115)",
                            padding: "14px",
                          }}
                        >
                          {data.material ? data.material : "-"}
                        </p>
                      </Grid.Column>
                    )}
                    <Grid.Column>
                      <Header
                        as="h3"
                        style={{
                          color: config.colorTheme,
                          padding: "7px",
                          background: "#efefef",
                          marginBottom: "0px",
                        }}
                      >
                        Comments
                        {data.instructorId &&
                          state.bacUserData &&
                          state.bacUserData.id === data.instructorId && (
                            <Button
                              compact
                              floated="right"
                              size="mini"
                              content="Edit"
                              positive
                              onClick={() =>
                                this.onInstructorUpdate(
                                  "comments",
                                  data.comments
                                )
                              }
                            />
                          )}
                      </Header>

                      <p
                        style={{ color: "rgb(115, 115, 115)", padding: "14px" }}
                      >
                        {data.comments ? data.comments : "No Comments"}{" "}
                      </p>
                    </Grid.Column>
                    <Grid.Column>
                      <Header
                        as="h3"
                        style={{
                          color: config.colorTheme,
                          padding: "7px",
                          background: "#efefef",
                          marginBottom: "0px",
                        }}
                      >
                        Reservation History
                      </Header>
                      <Table striped size="small">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                            >
                              Comment
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                            >
                              Action Date
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                              }}
                            >
                              Action By
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>Created</Table.Cell>
                            <Table.Cell>-</Table.Cell>
                            <Table.Cell>{data.created_at}</Table.Cell>
                            <Table.Cell>{data.created_by}</Table.Cell>
                          </Table.Row>
                          {data.history &&
                            data.history.map((item) => (
                              <Wrap>
                                <Table.Row>
                                  <Table.Cell verticalAlign='top'>{item.actiontype}</Table.Cell>
                                  <Table.Cell verticalAlign='top'>
                                    {item.comment ? item.comment : "-"}
                                    {/*
                                      Object.keys(JSON.parse(item.updated_log)).length > 0
                                      &&  <div>
                                          <br/>
                                        {
                                          Object.keys(JSON.parse(item.updated_log)).map((key,index) =>
                                            <p> {key} : {JSON.parse(item.updated_log)[key]}</p>
                                          )
                                        }
                                    </div>
                                    */}
                                  </Table.Cell>
                                  <Table.Cell verticalAlign='top'>{item.actionAt}</Table.Cell>
                                  <Table.Cell verticalAlign='top'>{item.actionBy}</Table.Cell>
                                </Table.Row>
                              </Wrap>
                            ))}
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </span>
            </Grid.Column>
            {!disableClick && canManage && (
              <Grid.Column width="3" style={{ paddingRight: "0px" }}>
                {data.status === 3 && (
                  <Wrap>
                    <Button
                      size="small"
                      color="red"
                      content="Delete"
                      fluid
                      compact
                      onClick={() => this.props.onDelete(data)}
                    />
                    <br />
                  </Wrap>
                )}
                {data.status === 0 && (
                  <Wrap>
                    <Button
                      size="small"
                      content="Edit"
                      fluid
                      positive
                      onClick={() => this.props.onEdit(data)}
                    />
                    <br />
                    <Button
                      size="small"
                      content="Cancel"
                      fluid
                      compact
                      onClick={() => this.props.onCancel(data)}
                    />
                    <br />
                    <Button
                      size="small"
                      color="red"
                      content="Delete"
                      fluid
                      compact
                      onClick={() => this.props.onDelete(data)}
                    />
                    <br />
                    {/* {data.displayType === 0 && (
                    <Button
                      size="small"
                      content="Print Dispatch"
                      fluid
                      compact
                      onClick={this.printToPdf}
                      // disabled
                    />
                  )} */}
                  </Wrap>
                )}
                {data.status === 2 && data.displayType === 2 && (
                  <Wrap>
                    <Button
                      size="small"
                      content="Edit"
                      fluid
                      positive
                      onClick={() => this.props.onEdit(data)}
                    />
                    <br />
                    <Button
                      size="small"
                      content="Cancel"
                      fluid
                      compact
                      onClick={() => this.props.onCancel(data)}
                    />
                    <br />
                    <Button
                      size="small"
                      color="red"
                      content="Delete"
                      fluid
                      compact
                      onClick={() => this.props.onDelete(data)}
                    />{" "}
                    <br />
                  </Wrap>
                )}
                {data.status === 2 && data.displayType === 0 &&

                  (
                    <Wrap>
                      <Button
                        size="small"
                        content="Edit"
                        fluid
                        positive
                        onClick={() => this.props.onEdit(data)}
                      />

                      <br />
                    </Wrap>
                  )}
                <Button
                  size="small"
                  content="Save Image"
                  fluid
                  compact
                  onClick={this.printToPng}
                // disabled
                />
                <br />
                <ReactToPrint
                  trigger={() => (
                    <Button
                      size="small"
                      content="Print Dispatch"
                      fluid
                      compact
                    // disabled
                    />
                  )}
                  content={() => this.componentRef}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Wrap>
    );
  }
}

export default withRouter(eventDetail);
