import React from "react";
import { Form, Radio } from "semantic-ui-react";
import Wrap from "./wrap";
const renderField = ({
  input,
  width,
  label,
  required,
  defaultChecked,
  meta: { touched, error, warning }
}) => (
  <Wrap>
    <Form.Group unstackable>
      <Form.Field width={width || 4}>
        <label style={{ textAlign: "left" }}>
          {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
      </Form.Field>
      <Form.Field
        required={required}
        width={width || 4}
        style={{ marginBottom: "0px", textAlign: "left" }}
      >
        <Radio
          toggle
          label={input.value ? "Yes" : "No"}
          checked={input.value}
          onChange={(param, data) => input.onChange(data.checked)}
        />
      </Form.Field>
    </Form.Group>
  </Wrap>
);

export default renderField;
