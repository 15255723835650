import React, { Component } from "react";
import { Button, Modal, Header, Icon } from "semantic-ui-react";
import moment from "moment";
class ModalExampleSize extends Component {
  render() {
    const { open, title, loading,data } = this.props;
    return (
      <div>
        <Modal
          size="mini"
          open={open}
          //   centered={false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={this.props.onClose}
        >
          <Modal.Header
            style={{
              background: "#FFC107",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px"
            }}
          >
            Warning
          </Modal.Header>
          <Modal.Content>
            <Header as="h5">
              <Icon name="warning sign" />
              <Header.Content>
                CANNOT make reservation!!!  <br />Please check available schedule for Aircraft/Room or Student/Instructor.
              </Header.Content>
            </Header>
            {data && <Header as="h5" textAlign='center' style={{
              paddingTop: '10px',
              paddingBottom: '10px',
              fontSize: '90%',
              background: '#e8e8e8'
            }}>
              <Header.Content>
                {data.reservationnumber}<br/>
                {data.title}<br/>
              {moment(data.start).format("DD-MM-YYYY HH:mm")} to   {moment(data.end).format("DD-MM-YYYY HH:mm")}
              </Header.Content>
            </Header>}
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                loading={loading}
                onClick={this.props.onClose}
              />
            </div>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalExampleSize;
