import React, { Component } from "react";
import { BryntumScheduler } from "bryntum-react-shared";
import * as config from "../config";
import moment from "moment";

class SchedulerBryntum extends Component {
  state = {
    start: moment(this.props.day)
      .set("hours", this.props.start)
      .set("minutes", 0)
      .set("seconds", 0)._d,
    stop: moment(this.props.day)
      .set("hours", this.props.stop)
      .set("minutes", 0)
      .set("seconds", 0)._d,
    resources: this.props.resources,
    events: this.props.events,
  };

  interval = null;

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.refs.scheduler.schedulerEngine.crudManager.load(),
      60000
    );

    switch (this.props.viewName) {
      case "day":
        this.setState({
          stop: moment(this.props.day)
            .set("hours", this.props.stop)
            .set("minutes", 0)
            .set("seconds", 0)._d,
        });
        break;
      case "Day Horizontal":
        this.setState({
          stop: moment(this.props.day)
            .set("hours", this.props.stop)
            .set("minutes", 0)
            .set("seconds", 0)._d,
        });
        break;
      case "2 Day Horizontal":
        this.setState({
          stop: moment(this.props.day)
            .add(1, "days")
            .set("hours", this.props.stop)
            .set("minutes", 0)
            .set("seconds", 0)._d,
        });
        break;
      case "3 Day Horizontal":
        this.setState({
          stop: moment(this.props.day)
            .add(2, "days")
            .set("hours", this.props.stop)
            .set("minutes", 0)
            .set("seconds", 0)._d,
        });
        break;
      case "week":
        this.setState({
          stop: moment(this.props.day)
            .add(6, "days")
            .set("hours", this.props.stop)
            .set("minutes", 0)
            .set("seconds", 0)._d,
        });
        break;
      default:
        break;
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.resources !== this.props.resources) {
      this.setState({ resources: nextProps.resources });
    }
  }
  syncfail = () => {
    console.log("syncfail");
  };
  render() {
    const state = this.state;
    const props = this.props;
    return (
      <BryntumScheduler
        ref={"scheduler"}
        // eventDragCreateFeature={true}
        createEventOnDblClick={false}
        autoHeight={false}
        height={"67vh"}
        rowHeight={35}
        barMargin={2}
        startDate={state.start}
        endDate={state.stop}
        displayDateFormat={"HH:mm"}
        zoomOnTimeAxisDoubleClick={false}
        eventStyle={"border"}
        eventLayout={"stack"}
        timeAxis={{
          continuous: false,
        }}
        workingTime={{
          fromHour: props.start,
          toHour: props.stop,
        }}
        showRemoveRowInContextMenu={false}
        snap={true}
        mode={props.viewName === "day" ? "vertical" : "horizontal"}
        listeners={{
          // eventDrag(data){
          //   console.log(data,"eventDrag");
          //   return false;
          // },
          // eventDrop(data){
          //   console.log(data,"eventDrop");
          //   return false;
          // },
          eventResizeEnd(data) {
            if (data.changed) {
              props.eventResize(data.eventRecord.data);
            }
            return false;
          },
          dragCreateEnd({ newEventRecord, resourceRecord }) {
            props.slotClicked(
              resourceRecord.id,
              newEventRecord.data.startDate,
              newEventRecord.data.endDate
            );
            return false;
          },
        }}
        features={{
          scheduleContextMenu: {
            // Process items before menu is shown
            processItems({ eventRecord, items }) {
              return false;
              // return items.splice(1, 3);
            },
          },
          headerContextMenu: {
            processItems({ items }) {
              // Add or remove items here as needed
              // return false;
              return items.splice(1, 3);
            },
          },
          eventContextMenu: {
            // Process items before menu is shown
            processItems({ eventRecord, items }) {
              return false;
            },
          },
          timeRanges: true,
          sort: "name",
          group: props.viewName !== "day" && {
            field: "group",
            ascending: true,
          },
          headerZoom: false,
          eventDrag: false,
          eventResize: props.canManage && true,
          eventEdit: false,
          // eventDragCreate: false,
          eventDragCreate: {
            showTooltip: props.canManage && true,
          },
          eventTooltip: {
            template: (data) => `
                <div class="content">
                  <div style="width: 300px;">
                    <div class="ui basic clearing segment" style="padding: 0px; margin: 0px;">
                      <h4 class="ui left floated left aligned header">
                        <i aria-hidden="true" class="circle icon" style="color: ${
                          data.eventRecord.status === 0
                            ? "#ff9800"
                            : data.eventRecord.status === 1
                            ? "#96a7af"
                            : data.eventRecord.status === 2
                            ? "#27a8a2"
                            : data.eventRecord.status === 3
                            ? "#ce2921"
                            : "blue"
                        }"></i>
                        <div class="content">${data.eventRecord.title || ""}
                          <div class="sub header">${
                            data.eventRecord.reservationTypeName ||
                            "New reservation"
                          }</div>
                        </div>
                      </h4>
                    </div>
                    <br>
                    <div class="ui basic clearing segment" style="padding: 0px; margin: 0px;">
                        <div role="list" class="ui list">
                          <div role="listitem" class="item ">
                            <i aria-hidden="true" class="clock icon"></i>
                            <div class="content"><b> Date time : </b>${moment(
                              data.eventRecord.startDate
                            ).format("ddd, MMM D HH:mm")} - ${
              moment(data.eventRecord.startDate).format("ddd, MMM D") ===
              moment(data.eventRecord.endDate).format("ddd, MMM D")
                ? moment(data.eventRecord.endDate).format("HH:mm")
                : moment(data.eventRecord.endDate).format("ddd, MMM D HH:mm")
            }</div>
                          </div>
                          <div role="listitem" class="item ">
                          <i aria-hidden="true" class="plane icon"></i>
                          <div class="content"><b>Aircraft &nbsp; &nbsp;: </b>${
                            data.eventRecord.aircraftName
                              ? data.eventRecord.aircraftName
                              : "-"
                          } </div>
                          </div>
                          <div role="listitem" class="item ">
                          <i aria-hidden="true" class="building icon"></i>
                          <div class="content"><b> Room &nbsp; &nbsp; &nbsp; &nbsp;: </b>
                          ${
                            data.eventRecord.roomName
                              ? data.eventRecord.roomName
                              : "-"
                          } </div>
                          </div>
                          <div role="listitem" class="item ">
                          <i aria-hidden="true" class="user icon"></i>
                          <div class="content"><b> Instructor : </b>${
                            data.eventRecord.instructorName || "-"
                          }</div>
                          </div>
                          <div role="listitem" class="item ">
                          <i aria-hidden="true" class="user circle icon"></i>
                          <div class="content"><b> Student &nbsp; &nbsp;: </b>${
                            data.eventRecord.studentName || "-"
                          }</div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                `,
          },
        }}
        eventBodyTemplate={(data) =>
          `<section><p style="margin-bottom: 0px;">${data.headerText}</p><p >${data.footerText}</p></section>`
        }
        eventRenderer={(data) => {
          if (data.eventRecord.status === 0) {
            data.tplData.style = "background-color:#ff9800;border-color:#fff;";
          } else if (data.eventRecord.status === 1) {
            data.tplData.style = "background-color:#96a7af;border-color:#fff;";
          } else if (data.eventRecord.status === 2) {
            data.tplData.style = "background-color:#27a8a2;border-color:#fff;";
          } else if (data.eventRecord.status === 3) {
            data.tplData.style = "background-color:#ce2921;border-color:#fff;";
          } else {
            data.tplData.style = "background-color:blue;border-color:#fff;";
          }
          return {
            headerText: moment(data.eventRecord.startDate).format("LT"),
            footerText: data.eventRecord.name || "",
          };
        }}
        viewPreset={{
          name: "hourAndDay",
          headerConfig: {
            top: {
              unit: "day",
              dateFormat: "ddd DD/MM",
            },
            middle: {
              unit: "hour",
              dateFormat: "HH:mm",
            },
          },
          timeResolution: {
            unit: "minute",
            increment: 5,
          },
        }}
        crudManager={{
          autoLoad: true,
          autoSync: true,
          autoSyncTimeout: 100,
          transport: {
            load: {
              requestConfig: {
                url: config.urlAPI + "/reservationlist" + props.qString,
                method: "GET",
                disableCaching: false,
                // headers         : {
                //   Accept: 'application/json',
                // },
                fetchOptions: {
                  credentials: "same-origin",
                },
              },
            },
            sync: {
              requestConfig: {
                url: config.urlAPI + "/reservationlist" + props.qString,
                method: "GET",
                disableCaching: false,
                // headers         : {
                //   Accept: 'application/json',
                // },
                fetchOptions: {
                  credentials: "same-origin",
                },
              },
            },
          },
        }}
        columns={[
          {
            text: "",
            field: "name",
            width: 130,
            editor: false,
            enableHeaderContextMenu: false,
          },
        ]}
        onEventSelectionChange={(data) => {
          if (data.action === "update" || data.action === "select") {
            if (data.selected[0].reservationId) {
              this.refs.scheduler.schedulerEngine.clearEventSelection();
              props.eventClicked(data.selected[0].reservationId);
            }
            return false;
          }
        }}
      />
    );
  }
}

export default SchedulerBryntum;
