import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Breadcrumb,
  Loader,
  Table,
  Icon,
  Form,
  Button,
  Grid
} from "semantic-ui-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import FromLesson from "./lessonForm";
import ModalForm from "../../components/modal";
import { getLesson, postLesson, updateLesson, deleteLesson } from "./service";
import Confirmmodal from "../../components/confirmmodal";
class Lesson extends Component {
  state = {
    search: {},
    rawData: [],
    data: [],
    loading: true,
    search: {
      sort: {
        key: "name",
        text: "Lesson",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC "
      }
    },
    sortText: "Lesson (ASC)",
    options_sortby: [
      {
        key: "name",
        text: "Lesson",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC "
      },
      {
        key: "name",
        text: "Lesson",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC"
      }
    ]
  };

  componentDidMount() {
    let { search } = this.state;
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    this.getData(qString);
  }

  getData = qString => {
    getLesson(qString).then(res => {
      if (res && res.status === 200) {
        if (this.state.search && this.state.search.name) {
            let newdata = []
            res.data.map(item => {
                if (item.name.toLowerCase().indexOf(this.state.search.name.toLowerCase()) >= 0) {
                    newdata.push(item)
                }
            })
            this.setState({ data: newdata })
        } else {
            this.setState({ data: res.data })
        }
        this.setState({ rawData: res.data })
      }
      this.setState({ loading: false });
    });
  };

  onClickNew = () => {
    this.setState({
      open: true,
      currentData: null,
      isNew: true,
      content: "New Lesson"
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, currentData: null, isNew: false });
  };
  onClickEdit = value => {
    this.setState({
      open: true,
      currentData: value,
      isNew: false,
      content: "Lesson"
    });
  };
  saveData = data => {
    this.setState({ loading: true });
    let { search } = this.state;
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    postLesson(data).then(res => {
      if (res && res.status === 201) {
        this.onClickCancel();
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };
  updateData = data => {
    this.setState({ loading: true });

    let { search } = this.state;
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    updateLesson(data).then(res => {
      if (res && res.status === 200) {
        this.onClickCancel();
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };
  deleteData = () => {
    let { search, currentData } = this.state;
    let qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    this.setState({ loading: true });
    if (currentData && currentData.id) {
      deleteLesson(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getData(qString);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
    }
  };
  render() {
    const state = this.state;
    let maxRow = Math.ceil(state.data.length / 3);
    if (maxRow < 10) maxRow = 10;

    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Lesson">
            <MainButton
              size="small"
              onClick={this.onClickNew}
              text="Add Lesson"
            />
          </HeaderWithMenu>
          <Breadcrumb>
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course")}
              style={{ color: "grey" }}
            >
              Academic
            </Breadcrumb.Section>

            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section
              onClick={() => this.props.history.push("/Course/FlightSubjects")}
              style={{ color: "grey" }}
            >
              Flight Subjects
            </Breadcrumb.Section>
            <Breadcrumb.Divider icon="right angle" />
            <Breadcrumb.Section active>Lesson</Breadcrumb.Section>
          </Breadcrumb>
          <br />
          <br />
          <Form>
            <Form.Group>
              <Form.Input
                // width='4'
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                onChange={this.handleChangeSort}
                selectOnBlur={false}
              />
            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <Grid columns="3">
              <Grid.Column>
                <Table
                  compact
                  celled
                  width="8"
                  striped
                  style={{ marginBottom: "30px" }}
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell className="header-table" width={1} />
                      <Table.HeaderCell className="header-table" width={11}>
                        Lesson
                      </Table.HeaderCell>
                      <Table.HeaderCell className="header-table" width={4} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {state.data.slice(0, maxRow).map((item, num) => {
                      return (
                        <Table.Row>
                          <Table.Cell textAlign="right"> {num + 1} </Table.Cell>
                          <Table.Cell>
                            <p
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                              onClick={() => this.onClickEdit(item)}
                            >
                              {item.name}
                            </p>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <Icon
                              link
                              name="edit"
                              onClick={() => this.onClickEdit(item)}
                            />
                            &nbsp; &nbsp;
                            <Icon
                              link
                              color="red"
                              name="trash alternate"
                              onClick={() => this.onClickDelete(item)}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
              {state.data.slice(maxRow, maxRow + maxRow).length > 0 && (
                <Grid.Column>
                  <Table
                    compact
                    celled
                    width="8"
                    striped
                    style={{ marginBottom: "30px" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={1} />
                        <Table.HeaderCell className="header-table" width={11}>
                          Lesson
                        </Table.HeaderCell>
                        <Table.HeaderCell className="header-table" width={4} />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.data
                        .slice(maxRow, maxRow + maxRow)
                        .map((item, num) => {
                          return (
                            <Table.Row>
                              <Table.Cell textAlign="right">
                                {maxRow + num + 1}
                              </Table.Cell>
                              <Table.Cell>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => this.onClickEdit(item)}
                                >
                                  {item.name}
                                </p>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon
                                  link
                                  name="edit"
                                  onClick={() => this.onClickEdit(item)}
                                />
                                &nbsp; &nbsp;
                                <Icon
                                  link
                                  color="red"
                                  name="trash alternate"
                                  onClick={() => this.onClickDelete(item)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
              {state.data.slice(maxRow * 2, state.data.length).length > 0 && (
                <Grid.Column>
                  <Table
                    compact
                    celled
                    width="8"
                    striped
                    style={{ marginBottom: "30px" }}
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className="header-table" width={1} />
                        <Table.HeaderCell className="header-table" width={11}>
                          Lesson
                        </Table.HeaderCell>
                        <Table.HeaderCell className="header-table" width={4} />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.data
                        .slice(maxRow * 2, state.data.length)
                        .map((item, num) => {
                          return (
                            <Table.Row>
                              <Table.Cell textAlign="right">
                                {maxRow + maxRow + num + 1}
                              </Table.Cell>
                              <Table.Cell>
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => this.onClickEdit(item)}
                                >
                                  {item.name}
                                </p>
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon
                                  link
                                  name="edit"
                                  onClick={() => this.onClickEdit(item)}
                                />
                                &nbsp; &nbsp;
                                <Icon
                                  link
                                  color="red"
                                  name="trash alternate"
                                  onClick={() => this.onClickDelete(item)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              )}
            </Grid>
          )}
        </Segment>
        <ModalForm
          open={state.open}
          icon="book"
          content={state.content}
          close={this.onClickCancel}
        >
          <FromLesson
            loading={state.loading}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.isNew ? this.saveData : this.updateData}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="lesson"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }

  handleChangeSort = (e, data) => {
    let { search } = this.state;
    let qString;

    const result = data.options.find(member => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
      loading: true
    });

    qString =
      "?_sort=" + search.sort.key + "&_order=" + search.sort.description;
    this.getData(qString);
  };

  handleChangeSearch = e => {
    let { rawData, search } = this.state;
    search[e.target.name] = e.target.value;
    this.setState({ search });
    if (e.target.value) {
      let newdata = [];
      rawData.map(item => {
        if (
          item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawData });
    }
  };
}

export default withRouter(Lesson);
