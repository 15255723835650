import axios from 'axios'
import * as config from '../../config'

export function getActivityTypeList() {
    return axios.get(config.urlAPI + '/activityTypeList')
    // return axios.get('../jsonfile/activityTypeList.json')
        .then(res => {
            return res
        }).catch(err => {
            return err.response
        })
}

export function getActivityTypeDetail(id) {
    return axios.get(config.urlAPI + '/activityTypeDetail/' + id)
    // return axios.get('../jsonfile/activityTypeDetail.json')
        .then(res => {
            // add manual search
            // const result = res.data.find((item) => {
            //     return item.id === id
            // })
            // res.data = result
            return res
        }).catch(err => {
            return err.response
        })
}
