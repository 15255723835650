import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Breadcrumb,
  Modal,
  Dropdown,
  Button,
} from "semantic-ui-react";
import * as config from "../../config";
import {
  DateInput,
  MonthInput,
  DatesRangeInput,
} from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import { genGroundCompensation, exportGroundCompensation } from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class GroundCompensationReport extends Component {
  state = {
    date:
      moment().subtract(1, "days").format("YYYY-MM-DD") +
      " - " +
      moment().format("YYYY-MM-DD"),
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    options_worktime: [
      { key: "r01", value: "IP Fulltime", text: "IP Fulltime" },
      { key: "r02", value: "IP Parttime", text: "IP Parttime" },
    ],
    wrktime: [],
    column: [
      {
        Header: "No.",
        accessor: "no",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.no}</span>,
      },
      {
        Header: "Instructor",
        accessor: "name",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.name}</span>,
      },
      {
        Header: "Date",
        accessor: "date",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.date}</span>,
      },
      {
        Header: "Student",
        accessor: "student",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.student}</span>
        ),
      },
      {
        Header: "Place",
        accessor: "place",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.place}</span>,
      },
      {
        Header: "Subject",
        accessor: "subject",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.subject}</span>
        ),
      },
      {
        Header: "จำนวน ชม. : hr.",
        accessor: "hours",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.hours}</span>,
      },
      {
        Header: "Ground Instruction Type",
        accessor: "language",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.language}</span>
        ),
      },
      {
        Header: "ค่าสอน/ชม. : Baht/hr.",
        accessor: "compensation",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "ค่าเดินทาง : traveling expenses",
        accessor: "travel",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.travel}</span>
        ),
      },
      {
        Header: "ค่าเบี้ยเลี้ยง /ค่าอาหาร",
        accessor: "allowance",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.allowance}</span>
        ),
      },
      {
        Header: "รวมเป็นเงิน(บาท) : Totle (Baht)",
        accessor: "totol",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className="">{cellInfo.original.totol}</span>,
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { date, wrktime } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }

    // let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "&worktime[]=" + wrk;
      });
    }
    exportGroundCompensation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "GroundCompensation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "GroundCompensation_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const { date, wrktime } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    // let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    if (wrktime.length > 0) {
      wrktime.map((wrk) => {
        qString += "&worktime[]=" + wrk;
      });
    }
    this.getData(qString);
  };
  handleChangeDate = (event, { name, value }) => {
    // console.log('onChange',name,value);
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genGroundCompensation(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString = "?start=" + start + "&end=" + end;
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "days").format("YYYY-MM-DD") +
        "&end=" +
        moment().format("YYYY-MM-DD");
      this.setState({
        date:
          moment().subtract(1, "days").format("YYYY-MM-DD") +
          " - " +
          moment().format("YYYY-MM-DD"),
      });
    }
    // let qString = "?date=" + moment(date, "MMMM, YYYY").format("YYYY-MM");
    this.getData(qString);
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Ground Instructor Compensation List">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>
            Ground Instructor Compensation List
          </Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              {/*<label style={{ textAlign: "left" }}>Filter by Calendar</label>*/}
              {/* <MonthInput
                name="date"
                placeholder="Select Month"
                hideMobileKeyboard
                dateFormat="MMMM, YYYY"
                iconPosition="right"
                value={state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              /> */}
              <DatesRangeInput
                name="date"
                placeholder="Start - End"
                hideMobileKeyboard
                dateFormat="YYYY-MM-DD"
                iconPosition="right"
                value={this.state.date}
                allowSameEndDate
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Field>
              <Dropdown
                name="wrktime"
                placeholder="Select Work Time"
                multiple
                selection
                options={state.options_worktime}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <MainButton
              size="small"
              text="Filter"
              loading={state.dataLoading}
              onClick={this.onClickFilterDate}
            />
          </Form.Group>
        </Form>
        {/*<Table compact celled striped >
            <Table.Header>
                <Table.Row textAlign='center'>
                    <Table.HeaderCell className='header-table'>Instructor Name</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Flight Instruction</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Night</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>INTER INSTUCTION</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Check Solo</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>SAFETY PILOT</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>MONITOR</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>TEST FLIGHT</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>TEST ENGINE</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Flight Simulated</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>SIM A320</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>BLIND FOLD CHECK</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>ชั่วโมงประกัน</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>FLIGHT-ประกัน</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Sim-ประกัน</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Safety+Sim-ประกัน</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Night</Table.HeaderCell>
                    <Table.HeaderCell className='header-table'>Signature</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            {state.dataLoading ? (
              <Table.Body>
                <Table.Cell colSpan={18}>
                  <div style={{ textAlign: "center" }}>
                    <Icon loading name="circle notch" />
                    <br />
                    Loading..
                  </div>
                </Table.Cell>
              </Table.Body>
            ): (
              <Table.Body>
                {state.data.map(tmp =>
                  <Table.Row textAlign='center'>
                    <Table.Cell>{tmp.name}</Table.Cell>
                    <Table.Cell>{tmp.flightinstruction}</Table.Cell>
                    <Table.Cell>{tmp.night}</Table.Cell>
                    <Table.Cell>{tmp.inter}</Table.Cell>
                    <Table.Cell>{tmp.checksolo}</Table.Cell>
                    <Table.Cell>{tmp.safety}</Table.Cell>
                    <Table.Cell>{tmp.monitor}</Table.Cell>
                    <Table.Cell>{tmp.testflight}</Table.Cell>
                    <Table.Cell>{tmp.testengine}</Table.Cell>
                    <Table.Cell>{tmp.simulated}</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>{tmp.nightDay}</Table.Cell>
                    <Table.Cell>{tmp.signature}</Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            )
          }
          </Table>*/}
        <TableFixedColumns
          data={state.data}
          columns={state.column}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(GroundCompensationReport);
