import axios from "axios";
import * as config from "../../config";

export function resetpassUser(id) {
  return axios
    .get(config.urlAPI + "/user/adminresetpassword/"+id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getListAlertRead(id) {
  return axios
    .get(config.urlAPI + "/alerthasread/"+id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getMake() {
  return axios
    .get(config.urlAPI + "/aircraftmake", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postMake(data) {
  return axios
    .post(config.urlAPI + "/aircraftmake", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateMake(data) {
  return axios
    .put(config.urlAPI + "/aircraftmake/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteMake(id) {
  return axios
    .delete(config.urlAPI + "/aircraftmake/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getModel() {
  return axios
    .get(config.urlAPI + "/aircraftmodel", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postModel(data) {
  return axios
    .post(config.urlAPI + "/aircraftmodel", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateModel(data) {
  return axios
    .put(
      config.urlAPI + "/aircraftmodel/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteModel(id) {
  return axios
    .delete(config.urlAPI + "/aircraftmodel/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getAirport() {
  return axios
    .get(config.urlAPI + "/airport", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postAirport(data) {
  return axios
    .post(config.urlAPI + "/airport", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateAirport(data) {
  return axios
    .put(config.urlAPI + "/airport/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteAirport(id) {
  return axios
    .delete(config.urlAPI + "/airport/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getGroup() {
  return axios
    .get(config.urlAPI + "/group")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postGroup(data) {
  return axios
    .post(config.urlAPI + "/group", data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateGroup(data) {
  return axios
    .put(config.urlAPI + "/group/" + data.id, data)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getClass() {
  return axios
    .get(config.urlAPI + "/personnel/studentclass", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postClass(data) {
  return axios
    .post(config.urlAPI + "/personnel/studentclass", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateClass(data) {
  return axios
    .put(
      config.urlAPI + "/personnel/studentclass/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteClass(id) {
  return axios
    .delete(
      config.urlAPI + "/personnel/studentclass/" + id,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getCompany() {
  return axios
    .get(config.urlAPI + "/personnel/company", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postCompany(data) {
  return axios
    .post(config.urlAPI + "/personnel/company", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateCompany(data) {
  return axios
    .put(
      config.urlAPI + "/personnel/company/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteCompany(id) {
  return axios
    .delete(config.urlAPI + "/personnel/company/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getStudenttype() {
  return axios
    .get(config.urlAPI + "/personnel/studenttype", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postStudenttype(data) {
  return axios
    .post(config.urlAPI + "/personnel/studenttype", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateStudenttype(data) {
  return axios
    .put(
      config.urlAPI + "/personnel/studenttype/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteStudenttype(id) {
  return axios
    .delete(config.urlAPI + "/personnel/studenttype/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getHospital() {
  return axios
    .get(config.urlAPI + "/personnel/hospital", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postHospital(data) {
  return axios
    .post(config.urlAPI + "/personnel/hospital", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateHospital(data) {
  return axios
    .put(
      config.urlAPI + "/personnel/hospital/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteHospital(id) {
  return axios
    .delete(config.urlAPI + "/personnel/hospital/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getDisciplineScore() {
  return axios
    .get(config.urlAPI + "/personnel/disciplinescore", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}


export function postDisciplineScore(data) {
  return axios
    .post(
      config.urlAPI + "/personnel/disciplinescore",
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateDisciplineScore(data) {
  return axios
    .put(
      config.urlAPI + "/personnel/disciplinescore/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function deleteDisciplineScore(id) {
  return axios
    .delete(
      config.urlAPI + "/personnel/disciplinescore/" + id,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getStudent() {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/studentList")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getBatch() {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/batch")
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getNews(qString) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/news"+qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postNews(data) {
  return axios
    .post(config.urlAPI + "/news", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateNews(id,data) {
  return axios
    .post(config.urlAPI + "/news/" + id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteNews(id) {
  return axios
    .delete(config.urlAPI + "/news/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getAlert(qString) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/alert"+qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postAlert(data) {
  return axios
    .post(config.urlAPI + "/alert", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateAlert(id,data) {
  return axios
    .post(config.urlAPI + "/alert/" + id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteAlert(id) {
  return axios
    .delete(config.urlAPI + "/alert/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getLocation() {
  return axios
    .get(config.urlAPI + "/room/location", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postLocation(data) {
  return axios
    .post(config.urlAPI + "/room/location", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateLocation(data) {
  return axios
    .put(
      config.urlAPI + "/room/location/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteLocation(id) {
  return axios
    .delete(config.urlAPI + "/room/location/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getFlightRules(qString) {
  return axios
    .get(config.urlAPI + "/flightrule" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postFlightRules(data) {
  return axios
    .post(config.urlAPI + "/flightrule", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateFlightRules(data) {
  return axios
    .put(config.urlAPI + "/flightrule/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteFlightRules(id) {
  return axios
    .delete(config.urlAPI + "/flightrule/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getGroundInstructionType() {
  return axios
    .get(config.urlAPI + "/groundinstructiontype", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postGroundInstructionType(data) {
  return axios
    .post(config.urlAPI + "/groundinstructiontype", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateGroundInstructionType(data) {
  return axios
    .put(
      config.urlAPI + "/groundinstructiontype/" + data.id,
      data,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteGroundInstructionType(id) {
  return axios
    .delete(
      config.urlAPI + "/groundinstructiontype/" + id,
      config.headerCallAPI
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteUser(id) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .delete(config.urlAPI + "/user/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
//Staff
export function getPersonnelStaff(qString) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/personnel/staff" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
//student
export function getUserStudent(qString) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/personnel/student/perpage" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
//instructor
export function getPersonnel(qString) {
  // return axios.get(config.urlAPI + '/instructorsList' + search)
  return axios
    .get(config.urlAPI + "/personnel/user" + qString, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postPersonnel(data) {
  return axios
    .post(config.urlAPI + "/user/add", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updatePersonnel(data) {
  return axios
    .put(config.urlAPI + "/user/update/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getRoles() {
  return axios
    .get(config.urlAPI + "/role", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postRole(data) {
  return axios
    .post(config.urlAPI + "/role", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateRole(data) {
  return axios
    .post(config.urlAPI + "/role/" + data.id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function deleteRole(id) {
  return axios
    .delete(config.urlAPI + "/role/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function getRolesWithPermission() {
  return axios
    .get(config.urlAPI + "/role/with/permission", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function postRolesWithPermission(data) {
  return axios
    .post(config.urlAPI + "/role/add/permission", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function getEbook() {
  return axios
    .get(config.urlAPI + "/ebook", config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postEbook(data) {
  return axios
    .post(config.urlAPI + "/ebook", data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
export function updateEbook(id, data) {
  return axios
    .post(config.urlAPI + "/ebook/" + id, data, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function deleteEbook(id) {
  return axios
    .delete(config.urlAPI + "/ebook/" + id, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
// export function postFlightRules(data) {
//   return axios
//     .post(config.urlAPI + "/flightRules", data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
// export function updateFlightRules(data) {
//   return axios
//     .put(config.urlAPI + "/flightRules/" + data.id, data)
//     .then(res => {
//       return res;
//     })
//     .catch(err => {
//       return err.response;
//     });
// }
