import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Table,
  Pagination,
  Button,
  Modal,
  Icon,
} from "semantic-ui-react";
import Wrap from "../../components/wrap";
import { getTotalLogbookSP, exportLogbookSP } from "./service";
import moment from "moment";
import html2canvas from "html2canvas";

class Logbook extends Component {
  state = {
    data: [],
    studentId: this.props.id,
    total_thispage: [],
    total_all: [],
    total_amt: [],
    last_page: null,
    current_page: null,
    loadPopup: false,
    errorMessage: null,
  };

  componentDidMount() {
    this.getData(this.state.current_page);
  }

  getData = (current_page) => {
    let qString = "";
    if (current_page) qString = "?page=" + current_page;
    getTotalLogbookSP(this.state.studentId, qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data.data,
          total_thispage: res.data.total_thispage,
          total_all: res.data.total_all,
          total_amt: res.data.total_amt,
          flighthour_total: res.data.flighthour_total,
          flighthour_amt: res.data.flighthour_amt,
          flighthour_thispage: res.data.flighthour_thispage,
          last_page: res.data.last_page,
          current_page: res.data.current_page,
        });
      }
    });
  };
  handlePaginationChange = (e, { activePage }) => {
    this.getData(activePage);
  };
  getHour = (logbook, type) => {
    let result = logbook.find((x) => x.name === type);
    if (result && result.hour) {
      let time = result.hour.split(":");
      return time[0];
    }
  };
  getMinute = (logbook, type) => {
    let result = logbook.find((x) => x.name === type);
    if (result && result.hour) {
      let time = result.hour.split(":");
      return time[1];
    }
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    exportLogbookSP(this.state.studentId).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "StudentLogbook_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "StudentLogbook_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  printToPng = () => {
    window.scrollTo(0, 0);
    // var w = document.getElementById("print_to_png").prop( 'scrollWidth' );
    html2canvas(document.getElementById("print_to_png"), {
      logging: true,
      letterRendering: 1,
      allowTaint: true,
      useCORS: true,
      width: 1055,
      height: 625,
      scale: 3,
      // scrollX: -window.scrollX
    }).then((canvas) => {
      var dataURL = canvas.toDataURL("image/png");
      var a = document.createElement("a");
      a.href = dataURL;
      if (this.props.id) a.download = "logbook_" + this.props.id + ".png";
      else a.download = "my-logbook.png";
      document.body.appendChild(a);
      a.click();
    });
  };

  render() {
    const state = this.state;
    // console.log("props", this.props);
    return (
      <Wrap>
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
                <Modal.Content>
                  <div style={{ textAlign: "center" }}>
                    <Icon loading name="circle notch" />
                    <br />
                  Waiting Download File
                </div>
                </Modal.Content>
              )
            }
          </Wrap>
        </Modal >
        {/* <Button
          size="small"
          content="Save Image"
          color='orange'
          // fluid
          // compact
          onClick={this.printToPng}
        /> */}
        < Button
          size="small"
          content="Export Excel"
          onClick={this.onClickExport}
          color="green"
        />
        <Grid>
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column>
              <Pagination
                floated="right"
                activePage={state.current_page}
                size="mini"
                totalPages={state.last_page}
                onPageChange={this.handlePaginationChange}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Table
                id="print_to_png"
                compact
                celled
                striped
                structured
                unstackable
                style={{ width: "100%", display: "block", overflow: "auto" }}
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      DATE
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      AIRCRAFT TYPE
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      AIRCRAFT IDEN
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      ROUTE OF FLIGHT
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      NR INST APP
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      REMARKS AND ENDORSEMENTS
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      NR T/O
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      NR LDG
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="4"
                      className="header-table"
                      textAlign="center"
                    >
                      AIRCRAFT CATEGORY
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      SIM
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      SIM A320
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="4"
                      className="header-table"
                      textAlign="center"
                    >
                      AND CLASS
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="6"
                      className="header-table"
                      textAlign="center"
                    >
                      CONDITIONS OF FLIGHT
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      FLIGHT SIMULATED
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="8"
                      className="header-table"
                      textAlign="center"
                    >
                      TYPE OF PILOTING TIME
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      rowSpan="2"
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      TOTAL DURATION OF FLIGHT
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      FROM
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                    >
                      TO
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      SINGLE-ENGINE LAND
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      MULTI-ENGINE LAND
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    ></Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      NIGHT
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      ACTUAL INSTRUMENT
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      SIMULATED INSTRUMENT (HOOD)
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      CROSS COUNTRY
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      AS FLIGHT INSTRUCTOR
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      DUAL RECEIVED
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan="2"
                      className="header-table"
                      textAlign="center"
                    >
                      PILOT IN COMMAND
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.data.map((item) => (
                    <Table.Row>
                      <Table.Cell>{item.date}</Table.Cell>
                      <Table.Cell>{item.model}</Table.Cell>
                      <Table.Cell>{item.tailNumber}</Table.Cell>
                      <Table.Cell>{item.departure}</Table.Cell>
                      <Table.Cell>{item.destination}</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>{item.code}</Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "Single Engine")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "Single Engine")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "Multi Engine")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "Multi Engine")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "SIM")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "SIM")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "SIM A320")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "SIM A320")}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "NIGHT")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "NIGHT")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "ACTUAL INSTRUMENT")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "ACTUAL INSTRUMENT")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(
                          item.logbook,
                          "SIMULATED INSTRUMENT (HOOD)"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(
                          item.logbook,
                          "SIMULATED INSTRUMENT (HOOD)"
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "FLIGHT SIMULATED")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "FLIGHT SIMULATED")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "CROSS COUNTRY")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "CROSS COUNTRY")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "AS FLIGHT INSTRUCTOR")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "AS FLIGHT INSTRUCTOR")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "DUAL RECEIVED")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "DUAL RECEIVED")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getHour(item.logbook, "PILOT IN COMMAND")}
                      </Table.Cell>
                      <Table.Cell>
                        {this.getMinute(item.logbook, "PILOT IN COMMAND")}
                      </Table.Cell>
                      <Table.Cell>
                        {item.flighthour && item.flighthour.split(":")[0]}
                      </Table.Cell>
                      <Table.Cell>
                        {item.flighthour && item.flighthour.split(":")[1]}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="right" className="bold">
                      {" "}
                      TOTALS THIS PAGE
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "ACTUAL INSTRUMENT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(
                        state.total_thispage,
                        "ACTUAL INSTRUMENT"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(
                        state.total_thispage,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(
                        state.total_thispage,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(
                        state.total_thispage,
                        "AS FLIGHT INSTRUCTOR"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(
                        state.total_thispage,
                        "AS FLIGHT INSTRUCTOR"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_thispage, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_thispage, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_thispage &&
                        state.flighthour_thispage.split(":")[0]}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_thispage &&
                        state.flighthour_thispage.split(":")[1]}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="right" className="bold">
                      {" "}
                      AMT.FORWARDED
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "ACTUAL INSTRUMENT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "ACTUAL INSTRUMENT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(
                        state.total_amt,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(
                        state.total_amt,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "AS FLIGHT INSTRUCTOR")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "AS FLIGHT INSTRUCTOR")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_amt, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_amt, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_amt &&
                        state.flighthour_amt.split(":")[0]}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_amt &&
                        state.flighthour_amt.split(":")[1]}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell colSpan="7" textAlign="right" className="bold">
                      {" "}
                      TOTALS DATE
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "Single Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "Multi Engine")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "SIM")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "SIM A320")}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "NIGHT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "ACTUAL INSTRUMENT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "ACTUAL INSTRUMENT")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(
                        state.total_all,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(
                        state.total_all,
                        "SIMULATED INSTRUMENT (HOOD)"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "FLIGHT SIMULATED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "CROSS COUNTRY")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "AS FLIGHT INSTRUCTOR")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "AS FLIGHT INSTRUCTOR")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "DUAL RECEIVED")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getHour(state.total_all, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {this.getMinute(state.total_all, "PILOT IN COMMAND")}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_total &&
                        state.flighthour_total.split(":")[0]}
                    </Table.Cell>
                    <Table.Cell>
                      {state.flighthour_total &&
                        state.flighthour_total.split(":")[1]}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: "0px" }}>
            <Grid.Column>
              <Pagination
                floated="right"
                activePage={state.current_page}
                size="mini"
                totalPages={state.last_page}
                onPageChange={this.handlePaginationChange}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Wrap >
    );
  }
}

export default withRouter(Logbook);
