import React, { Component } from "react";
import { Button, Modal, Header, Icon, Form } from "semantic-ui-react";

const options = [
  { key: "1", text: "Aircraft Not Available", value: "Aircraft Not Available" },
  { key: "2", text: "CAAT Flight Check", value: "CAAT Flight Check" },
  {
    key: "3",
    text: "Instructor Not Available",
    value: "Instructor Not Available",
  },
  { key: "4", text: "Scheduling mistake", value: "Scheduling mistake" },
  { key: "5", text: "Sick IP", value: "Sick IP" },
  { key: "6", text: "Sick SP", value: "Sick SP" },
  { key: "7", text: "Student Not Ready", value: "Student Not Ready" },
  { key: "8", text: "VIP Conflict", value: "VIP Conflict" },
  { key: "9", text: "Weather", value: "Weather" },
  { key: "10", text: "Other", value: "Other" },
];

class ModalExampleSize extends Component {
  state = {
    comment: null,
    seleted: null,
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  onComfirm = () => {
    let { seleted, comment } = this.state;
    if (seleted === null || (seleted === "Other" && comment.trim() === ""))
      this.setState({ error: true });
    else if (seleted === "Other") {
      this.setState({ error: false });
      this.props.onComfirm(comment);
    } else {
      this.setState({ error: false });
      this.props.onComfirm(seleted);
    }
    this.setState({ seleted: null, comment: null });
  };
  render() {
    const { open, title, loading, actionText } = this.props;
    const state = this.state;
    return (
      <div>
        <Modal
          size="mini"
          open={open}
          //   centered={false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={this.props.onClose}
        >
          <Modal.Header
            style={{
              background: actionText ? "#03A9F4" : "red",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Confirm
          </Modal.Header>
          <Modal.Content>
            <Header as="h5">
              <Icon name="warning sign" />
              <Header.Content>
                Are you sure you want to {actionText ? actionText : "delete"}{" "}
                this {title}.? <br />
                You can't undo this action.
              </Header.Content>
            </Header>
            <br />
            <Form>
              <Form.Select
                fluid
                required
                error={state.error}
                label="Comment"
                options={options}
                placeholder="Comment"
                name="seleted"
                onChange={this.handleChange}
              />
              {state.seleted === "Other" && (
                <Form.TextArea
                  label="Comment"
                  name="comment"
                  error={state.error}
                  required
                  placeholder="Comment..."
                  onChange={this.handleChange}
                />
              )}
            </Form>
            <br />
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                loading={loading}
                onClick={this.onComfirm}
              />
              <Button
                size="small"
                negative
                floated="right"
                onClick={this.props.onClose}
              >
                No
              </Button>
            </div>
            <br />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default ModalExampleSize;
