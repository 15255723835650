import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Form } from "semantic-ui-react";
import { Field } from "redux-form";
import renderSelectField from "../../components/renderSelectField";
import renderField from "../../components/renderField";
import Wrap from "../../components/wrap";

class FromReservation extends Component {
  render() {
    const {
      reservationType,
      student,
      studentId,
      room,
      groundSubjects,
      instructor
    } = this.props;
    return (
      <Wrap>
        <Field
          name="aircraftId"
          component={renderSelectField}
          placeholder="Meeting Host"
          label="Meeting Host"
          options={student}
          search
        />
        <Field
          name="meetingName"
          component={renderField}
          placeholder="Meeting Name"
          label="Meeting Name"
        />
        <Field
          name="aircraftId"
          component={renderSelectField}
          placeholder="Room"
          label="Room"
          options={room}
          search
        />
        <Field
          name="attendeesId"
          component={renderSelectField}
          placeholder="Attendees"
          label="Attendees"
          options={room}
          search
        />
      </Wrap>
    );
  }
}

export default withRouter(FromReservation);
