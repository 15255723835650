import React, { Component } from "react";
import {
  Segment,
  Container,
  Header,
  Form,
  Message,
  Input,
  Button,
  Icon,
  Image,
  Grid,
  Divider,
  List,
  Label,
  Table,
  Radio,
  TextArea,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import HeaderWithMenu from "../../../components/headerwithmenu";
import * as config from "../../../config";
import {
  postLogin,
  getFlightTraningRecordById,
  postFlightTraningRecord,
} from "../service";
import { getSnagById } from "../../Portfolio/service";
import moment from "moment";
import Wrap from "../../../components/wrap";
import ModalForm from "../../../components/modal";
import Confirmmodal from "../../../components/customconfirmmodal";
import ScoreEdit from "./score_edit";
import ScoreView from "./score_view";
import MainButton from "../../../components/mainbutton";

class Score extends Component {
  state = {
    locationState: this.props.location
      ? this.props.location.state
      : this.props.state,
    page: null,
    data: [],
    currentDate: new Date(),
  };
  setEdit = () => {
    this.setState({ page: "edit" });
  };
  setView = () => {
    this.setState({ page: "view" });
  };
  getById = (id) => {
    getFlightTraningRecordById(id).then((res) => {
      if (res && res.status === 200) {
        const { currentDate, locationState } = this.state;
        let page, time1, time2;
        this.setState({ data: [] });
        time1 = moment(res.data.start).format("YYYY-MM-DD");
        time2 = moment(currentDate).format("YYYY-MM-DD");
        // if (moment(time1).isSame(time2)) {
        if (res.data.status === "SUBMITTED") {
          page = "view";
        } else if (res.data.status === "APPROVED") {
          page = "viewApprove";
        } else {
          page = "edit";
        }
        // }else {
        //   page = 'viewApprove'
        // }
        res.data.lesson.map((les) => {
          if (!les.remark) {
            les.remark = "";
          }
        });
        this.setState({ page, data: res.data });
      }
    });
  };
  componentDidMount() {
    // let qString = "/1"; //Flight Training
    const { locationState } = this.state;
    this.getById(locationState.id);
    // this.getSnag(locationState.data.attendance[0].id)
  }

  render() {
    const state = this.state;
    const { locationState } = this.state;
    if (state.data.length === 0)
      return (
        <div style={{ minHeight: "50vh" }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div>
      );
    return this.props.portfolio ? (
      <ScoreView
        portfolio={true}
        data={state.data}
        getData={(id) => this.getById(id)}
        locationState={locationState}
      />
    ) : (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Flight Training Record">
          {state.page && state.page === "view" && (
            <MainButton size="small" onClick={this.setEdit} text="Edit" />
          )}
        </HeaderWithMenu>
        {(state.page && state.page === "view") ||
        state.page === "viewApprove" ? (
          <ScoreView
            setPage={this.setEdit}
            data={state.data}
            getData={(id) => this.getById(id)}
            locationState={locationState}
          />
        ) : (
          <ScoreEdit
            setPage={this.setView}
            data={state.data}
            getData={(id) => this.getById(id)}
            locationState={locationState}
          />
        )}
      </Segment>
    );
  }
}

export default Score;
