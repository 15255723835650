import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'


class TableData extends Component {
    render() {
        return (
            <ReactTable
                style={this.props.styleTB}
                defaultPageSize={this.props.defaultPageSize || 10}
                pageSize={this.props.pageSize}
                // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
                // className="-striped -highlight"
                className="-highlight"
                loading={this.props.loading}
                data={this.props.data}
                columns={this.props.columns}
                defaultSorted={this.props.defaultSorted}
            // getTdProps={(state, rowInfo, column, instance) => {
            //     return {
            //         onClick: e => {
            //             console.log(column)
            //             if (column.id === 'datail') {
            //                 if (rowInfo) {
            //                     this.props.rowClick(rowInfo.original)
            //                 }
            //             }

            //             // else console.log("no")
            //         }
            //     };
            // }}
            />
        )
    }
}
export default TableData
