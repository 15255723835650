import axios from "axios";
import * as config from "../config";

export function sendMailResetPassword(data) {
  return axios
    .post(config.urlAPI + "/resetpassword", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function confirmResetPassword(data) {
  return axios
    .post(config.urlAPI + "/resetpassword/confirm", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postLogin(data) {
  return axios
    .post(config.urlAPI + "/login", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function postLoginGoogle(data) {
  return axios
    .post(config.urlAPI + "/loginGoogle", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}


export function logout() {
  return axios
    .get(config.urlAPI + "/logout", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getNotisList(qString) {
  return axios
    .get(config.urlAPI + "/notifyme/" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getNotisListNextPage(url) {
  return axios
    .get(url, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getNewsList(qString) {
  return axios
    .get(config.urlAPI + "/newstoday" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getAlertList(id) {
  return axios
    .get(config.urlAPI + "/alertme/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getPersonnelAlert(id) {
  return axios
    .get(config.urlAPI + "/personnelalert/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getPersonnelAlertList() {
  return axios
    .get(config.urlAPI + "/personnelalertDoc", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getPersonnelAlertFlightList() {
  return axios
    .get(config.urlAPI + "/personnelalertFlight", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getPersonnelAlertNameList(qString) {
  return axios
    .get(config.urlAPI + "/personnelalertlist" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getPersonnelAlertDrop() {
  return axios
    .get(config.urlAPI + "/personnelalertDrop" , config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function updateReadNews(id) {
  return axios
    .get(config.urlAPI + "/readnews/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateReadAlert(id) {
  return axios
    .get(config.urlAPI + "/readalert/" + id, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function resetPassword(data) {
  return axios
    .post(config.urlAPI + "/user/resetpassword", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
