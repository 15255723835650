import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Loader,
  Table,
  Form,
  Button,
  Pagination,
  Popup,
  Icon,
  Checkbox,
  Modal,
} from "semantic-ui-react";
import moment from "moment";
import * as config from "../../config";
import DayPicker from "../../components/dayPicker";
import {
  getFlightHourRecord,
  getLogbookSPBySearch,
  getLogbookIPBySearch,
  updateFlightHourRecord,
  updateAllFlightHourRecord,
  exportFlightHour
} from "./service";
import Wrap from "../../components/wrap";
import ModalForm from "../../components/modal";
import HeaderWithMenu from "../../components/headerwithmenu";

const options = [
  { key: "1", text: "Aircraft Not Available", value: "Aircraft Not Available" },
  { key: "2", text: "CAAT Flight Check", value: "CAAT Flight Check" },
  {
    key: "3",
    text: "Instructor Not Available",
    value: "Instructor Not Available",
  },
  { key: "4", text: "Scheduling mistake", value: "Scheduling mistake" },
  { key: "5", text: "Sick IP", value: "Sick IP" },
  { key: "6", text: "Sick SP", value: "Sick SP" },
  { key: "7", text: "Student Not Ready", value: "Student Not Ready" },
  { key: "8", text: "VIP Conflict", value: "VIP Conflict" },
  { key: "9", text: "Weather", value: "Weather" },
  { key: "10", text: "Other", value: "Other" },
];
class FlightHourRecord extends Component {
  state = {
    seletedDate: new Date(),
    currentData: [],
    loading: true,
    per_page: 15,
    current_page: 1,
    last_page: 1,
    data: [],
    unsuccess: [],
    logbookSP: [],
    logbookIP: [],
    sortText: "",
    options_sortby: [
      {
        key: "aircraft",
        text: "Aircraft",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC",
      },
      {
        key: "aircraft",
        text: "Aircraft",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "callSign",
        text: "Call sign",
        value: "3",
        icon: "sort alphabet ascending",
        description: "ASC",
      },
      {
        key: "callSign",
        text: "Call sign",
        value: "4",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      {
        key: "start",
        text: "Start",
        value: "5",
        icon: "sort alphabet ascending",
        description: "ASC",
      },
      {
        key: "start",
        text: "Start",
        value: "6",
        icon: "sort alphabet descending",
        description: "DESC",
      },
    ],
    sortBy: null,
    canManage: false,
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
  };
  componentDidMount() {
    if (this.checkPermissionMenu("Schedule", ["manage-flighthourrecord"]))
      this.setState({ canManage: true });

    this.getData(this.state.seletedDate, this.state.current_page);
    getLogbookIPBySearch("?editable=true").then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          if (item.group) {
            item.group.map((group) => {
              options.push({
                key: group.id,
                text: group.name,
                value: { id: group.id, name: group.name, group: group.group },
              });
            });
          } else {
            options.push({
              key: item.id,
              text: item.name,
              value: { id: item.id, name: item.name, group: item.group },
            });
          }
        });
        this.setState({ logbookIP: options });
      }
    });
    getLogbookSPBySearch("?editable=true").then((res) => {
      if (res && res.status === 200) {
        let options = [];
        res.data.map((item) => {
          if (item.group) {
            item.group.map((group) => {
              options.push({
                key: group.id,
                text: group.name,
                value: { id: group.id, name: group.name, group: group.group },
              });
            });
          } else {
            options.push({
              key: item.id,
              text: item.name,
              value: { id: item.id, name: item.name, group: item.group },
            });
          }
        });
        this.setState({ logbookSP: options });
      }
    });
  }

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };

  getData = (seletedDate, current_page) => {
    this.setState({ loading: true, canSave: false });
    let qString =
      moment(seletedDate).format("YYYY-MM-DD") + "&page=" + current_page;

    const { sortBy } = this.state;
    if (sortBy) {
      qString += "&_sort=" + sortBy.key + "&_order=" + sortBy.description;
    }

    getFlightHourRecord("?date=" + qString).then((res) => {
      if (res && res.status === 200) {
        if (res.data) {
          let canSave = res.data.data.find((element) => element.status === 0);
          let data = []
          res.data.data.map(item => {
            if (item.status === 1) {
              item.unsuccess = true
            }
            data.push(item)
          })
          this.setState({
            data: data,
            per_page: res.data.per_page,
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            canSave: canSave ? true : false,
          });
        }
      }
      this.setState({ loading: false });
    });
  };
  handlePaginationChange = (e, { activePage }) => {
    this.getData(this.state.seletedDate, activePage);
  };
  handleChangeDate = (seletedDate) => {
    this.setState({ seletedDate, loading: true });
    console.log("okok");
    this.getData(seletedDate, 1);
  };
  onChangeTime = (e, input) => {
    let { data } = this.state;
    data[input.index][input.content] = data[input.index][input.content] || [];
    if (input.name === "mm") {
      if (input.value > 59 || input.value.length > 2) {
        data[input.index][input.content].mm = 0;
      } else {
        data[input.index][input.content].mm = input.value;
      }
    } else {
      if (input.value.length > 2) {
        data[input.index][input.content].hh = 0;
      } else {
        data[input.index][input.content].hh = input.value;
      }
    }
    this.totalTime(input.index);
    this.setState({ data });
  };

  onChangeEditTime = (e, input) => {
    let { editItem } = this.state;
    editItem[input.content] = editItem[input.content] || [];
    if (input.name === "mm") {
      console.log(input.value);
      if (input.value > 59 || input.value.length > 2) {
        console.log("if");
        editItem[input.content].mm = 0;
      } else {
        console.log("else");
        editItem[input.content].mm = input.value;
      }
    } else {
      if (input.value.length > 2) {
        editItem[input.content].hh = 0;
      } else {
        editItem[input.content].hh = input.value;
      }
    }
    this.totalEditTime(input.index);
    this.setState({ editItem });
  };

  totalEditTime = (index) => {
    let { editItem } = this.state;
    this.setState({ invalidTime: false });
    if (editItem && editItem.SD && editItem.ST) {
      var start = moment.duration(
        `${editItem.ST ? editItem.ST.hh : "00"}:${editItem.ST ? editItem.ST.mm : "00"
        }`,
        "HH:mm"
      );
      var end = moment.duration(
        `${editItem.SD ? editItem.SD.hh : "00"}:${editItem.SD ? editItem.SD.mm : "00"
        }`,
        "HH:mm"
      );
      var diff = end.subtract(start);
      editItem.STTO = start;
      editItem.LDSD = end;
      if (diff.hours() >= 0 && diff.minutes() >= 0)
        editItem.flightInstruction = moment(
          `${diff.hours()}:${diff.minutes()}`,
          "HH:mm"
        ).format("HH:mm");
      else {
        this.setState({ invalidTime: true });
        editItem.flightInstruction = "Invalid";
      }
    }
    this.setState({ editItem });
  };

  totalTime = (index) => {
    let { data } = this.state;
    this.setState({ invalidTime: false });

    if (data[index].SD && data[index].ST) {
      var start = moment.duration(
        `${data[index].ST ? data[index].ST.hh : "00"}:${data[index].ST ? data[index].ST.mm : "00"
        }`,
        "HH:mm"
      );
      var end = moment.duration(
        `${data[index].SD ? data[index].SD.hh : "00"}:${data[index].SD ? data[index].SD.mm : "00"
        }`,
        "HH:mm"
      );
      var diff = end.subtract(start);
      data[index].STTO = start;
      data[index].LDSD = end;
      if (diff.hours() >= 0 && diff.minutes() >= 0)
        data[index].flightInstruction = moment(
          `${diff.hours()}:${diff.minutes()}`,
          "HH:mm"
        ).format("HH:mm");
      else {
        this.setState({ invalidTime: true });
        data[index].flightInstruction = "Invalid";
      }
    }
    this.setState({ data });
  };

  onAddLogbook = () => {
    let { currentData } = this.state;
    currentData.logbook.push({});
    this.setState({ currentData });
  };
  onDeleteLogbook = (index) => {
    let { currentData } = this.state;
    currentData.logbook.splice(index, 1);
    this.setState({ currentData });
  };

  getOptions = (data) => {
    let { currentData } = this.state;
    let current = [],
      options = [];
    currentData.logbook.map((item) => current.push(item.id));
    options = data.filter((member) => {
      if (current.indexOf(member.value.id) < 0) return member;
    });
    return options;
  };

  onChange = (e, data) => {
    let { currentData } = this.state;
    currentData.logbook[data.index] = data.value;
    this.setState({ currentData });
  };
  onEditLogbook = (type, data) => {
    let currentData = null;
    switch (type) {
      case "instructor":
        currentData = {
          id: data.id,
          aircraft: data.aircraft,
          name: data.instructor,
          logbook: data.instructorLogbook || [],
          options_logbook: this.state.logbookIP,
          status: data.status,
          type: "instructor",
        };
        this.setState({ open: true, currentData: currentData });
        break;
      case "student":
        currentData = {
          id: data.id,
          aircraft: data.aircraft,
          name: data.student,
          logbook: data.studentLogbook || [],
          options_logbook: this.state.logbookSP,
          status: data.status,
          type: "student",
        };
        this.setState({ open: true, currentData: currentData });
        break;
      case "instructorSV":
        currentData = {
          id: data.id,
          aircraft: data.aircraft,
          name: data.instructorSV,
          logbook: data.instructorSVLogbook || [],
          options_logbook: this.state.logbookIP,
          status: data.status,
          type: "instructorSV",
        };
        this.setState({ open: true, currentData: currentData });
        break;
      case "instructorIP":
        currentData = {
          id: data.id,
          aircraft: data.aircraft,
          name: data.instructorIP,
          logbook: data.instructorIPLogbook || [],
          options_logbook: this.state.logbookIP,
          status: data.status,
          type: "instructorIP",
        };
        this.setState({ open: true, currentData: currentData });
        break;
      case "instructorPro":
        currentData = {
          id: data.id,
          aircraft: data.aircraft,
          name: data.instructorPro,
          logbook: data.instructorProLogbook || [],
          options_logbook: this.state.logbookIP,
          status: data.status,
          type: "instructorPro",
        };
        this.setState({ open: true, currentData: currentData });
        break;
      default:
    }
  };
  onClickCancel = () => {
    this.setState({ open: false, currentData: null });
  };

  toggle = (e, index) => {
    let { data } = this.state;
    data[index].test_result = !data[index].test_result;
    this.setState({ data });
  };
  toggleUnsuccess = (e, index) => {
    let { data } = this.state;
    data[index].unsuccess = !data[index].unsuccess;
    this.setState({ data });
  };
  toggleEdit = () => {
    let { editItem } = this.state;
    editItem.test_result = !editItem.test_result;
    this.setState({ editItem });
  };

  handleChange = (e, data) => {
    let { unsuccess } = this.state;
    unsuccess[data.index][data.name] = data.value;
    this.setState({ unsuccess });
  };

  onClickEdit = (value, row) => {
    let editItem = [];
    if (value.SD) {
      let time = value.SD.split(":");
      editItem.SD = editItem.SD || [];
      editItem.SD.hh = time[0];
      editItem.SD.mm = time[1];
    }
    if (value.ST) {
      let time = value.ST.split(":");
      editItem.ST = editItem.ST || [];
      editItem.ST.hh = time[0];
      editItem.ST.mm = time[1];
    }
    if (value.test_result === "PASS") editItem.test_result = true;
    else editItem.test_result = false;
    editItem.flightInstruction = value.flightInstruction;

    this.setState({ editField: true, editItem, editRow: row });
  };
  saveLogbook = () => {
    this.setState({ loading: true });
    let { currentData } = this.state;
    let data;
    switch (currentData.type) {
      case "instructor":
        data = {
          id: currentData.id,
          instructor: currentData.logbook,
        };
        break;
      case "student":
        data = {
          id: currentData.id,
          student: currentData.logbook,
        };
        break;
      case "instructorSV":
        data = {
          id: currentData.id,
          instructorSV: currentData.logbook,
        };
        break;
      case "instructorIP":
        data = {
          id: currentData.id,
          instructorIP: currentData.logbook,
        };
        break;
      case "instructorPro":
        data = {
          id: currentData.id,
          instructorPro: currentData.logbook,
        };
        break;
      default:
    }
    updateFlightHourRecord(data).then((res) => {
      if (res && res.status === 200) {
        this.onClickCancel();
        this.getData(this.state.seletedDate, this.state.current_page);
      }
      this.setState({ loading: false });
    });
  };
  updateEditField = (item) => {
    let { editItem } = this.state;
    let flightHourRecord = [];
    if (
      editItem.flightInstruction &&
      editItem.flightInstruction !== "Invalid" &&
      editItem.ST &&
      editItem.SD
    ) {
      if (
        moment(`${editItem.ST.hh}:${editItem.ST.mm}`, "HH:mm").isValid() &&
        moment(`${editItem.SD.hh}:${editItem.SD.mm}`, "HH:mm").isValid()
      ) {
        item.ST = moment(`${editItem.ST.hh}:${editItem.ST.mm}`, "HH:mm").format(
          "HH:mm"
        );
        item.SD = moment(`${editItem.SD.hh}:${editItem.SD.mm}`, "HH:mm").format(
          "HH:mm"
        );
        item.old_flightInstruction = item.flightInstruction;
        item.flightInstruction = editItem.flightInstruction;
        if (editItem.test_result) item.test_result = "true";
        else item.test_result = "false";
        flightHourRecord.push(item);
      }
    }

    const result = flightHourRecord.filter((member) => {
      return member.unsuccess;
    });
    if (result && result.length > 0) {
      this.setState({ openUnsuccess: true, unsuccess: result, data: flightHourRecord });
    } else {
      updateAllFlightHourRecord(flightHourRecord).then((res) => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getData(this.state.seletedDate, this.state.current_page);
        }
        this.setState({ loading: false, editField: false });
      });
    }

  };

  validateBeforeSubmit = () => {
    let { data } = this.state;
    const result = data.filter((member) => {
      return member.unsuccess;
    });
    if (result && result.length > 0) {
      this.setState({ openUnsuccess: true, unsuccess: result });
    } else {
      this.saveData(data);
    }
  };

  onComfirm = () => {
    let { unsuccess, data, editField } = this.state;
    let alldata = [];
    data.forEach((element) => {
      const result = unsuccess.find((member) => {
        return member.id === element.id;
      });
      if (result) {
        if (result.seleted !== "Other") {
          result.reason = result.seleted;
        } else result.reason = result.seleted;
        alldata.push(result);
      } else alldata.push(element);
    });

    this.setState({ openUnsuccess: false });
    if (editField) {
      updateAllFlightHourRecord(alldata).then((res) => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.getData(this.state.seletedDate, this.state.current_page);
        }
        this.setState({ loading: false, editField: false });
      });
    } else this.saveData(alldata);


  };

  saveData = (data) => {
    // let { data } = this.state;
    this.setState({ loading: true });
    let flightHourRecord = [];
    data.map((item) => {
      // if(  item.flightInstruction && item.flightInstruction !== 'Invalid' && item.ST && item.SD){
      if (
        item.status !== 2 &&
        item.flightInstruction &&
        item.flightInstruction !== "Invalid" &&
        item.ST &&
        item.SD
      ) {
        if (
          moment(`${item.ST.hh}:${item.ST.mm}`, "HH:mm").isValid() &&
          moment(`${item.SD.hh}:${item.SD.mm}`, "HH:mm").isValid()
        ) {
          item.ST = moment(`${item.ST.hh}:${item.ST.mm}`, "HH:mm").format(
            "HH:mm"
          );
          item.SD = moment(`${item.SD.hh}:${item.SD.mm}`, "HH:mm").format(
            "HH:mm"
          );
          if (item.test_result) item.test_result = "true";
          else item.test_result = "false";
          flightHourRecord.push(item);
        }
      }
    });

    updateAllFlightHourRecord(flightHourRecord).then((res) => {
      if (res && res.status === 200) {
        this.onClickCancel();
        this.getData(this.state.seletedDate, this.state.current_page);
      }
      this.setState({ loading: false });
    });
  };

  handleChangeSort = (e, data) => {
    let { sortBy } = this.state;
    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    sortBy = result;

    this.setState(
      {
        sortBy,
        sortText: `${result.text} (${result.description})`,
      },
      () => {
        this.getData(this.state.seletedDate, this.state.current_page);
      }
    );
  };


  onClickExport = () => {
    const { sortBy, seletedDate } = this.state;

    this.setState({ loadPopup: true, errorMessage: null });
    let qString =
      moment(seletedDate).format("YYYY-MM-DD") + "&all";

    if (sortBy) {
      qString += "&_sort=" + sortBy.key + "&_order=" + sortBy.description;
    }

    exportFlightHour("?date=" + qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "FlightHour_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "FlightHour_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  render() {
    const state = this.state;
    return (
      <Wrap>
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <Modal
          size="small"
          open={state.openUnsuccess}
          //   centered={false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() => this.setState({ openUnsuccess: false })}
        >
          <Modal.Header
            style={{
              background: "#03A9F4",
              color: "white",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            Flight Unsuccess
          </Modal.Header>
          <Modal.Content>
            <Form>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="6">Flight</Table.HeaderCell>
                    <Table.HeaderCell>Reason</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.unsuccess.map((item, index) => (
                    <Table.Row>
                      <Table.Cell>{item.aircraft}</Table.Cell>
                      <Table.Cell>
                        <Form.Select
                          fluid
                          required
                          error={item.error}
                          options={options}
                          placeholder="Comment"
                          name="seleted"
                          index={index}
                          onChange={this.handleChange}
                        />
                        {item.seleted === "Other" && (
                          <Form.TextArea
                            name="reason"
                            error={item.error}
                            required
                            placeholder="Comment..."
                            index={index}
                            onChange={this.handleChange}
                          />
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Form>
            <br />
            <br />
            <div style={{ paddingBottom: "10px" }}>
              <Button
                positive
                size="small"
                floated="right"
                icon="checkmark"
                labelPosition="right"
                content="Yes"
                onClick={this.onComfirm}
              />
              <Button
                size="small"
                negative
                floated="right"
                onClick={() => this.setState({ openUnsuccess: false })}
              >
                No
              </Button>
            </div>
            <br />
          </Modal.Content>
        </Modal>
        <ModalForm
          open={state.open}
          icon="address book "
          content="Logbook"
          close={this.onClickCancel}
        >
          {state.currentData && (
            <Form>
              <Header as="h2" textAlign="center">
                {state.currentData.name}
                <Header.Subheader>
                  {state.currentData.aircraft}
                </Header.Subheader>
              </Header>
              <Table compact celled striped style={{ marginBottom: "30px" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className="header-table" width={1} />
                    <Table.HeaderCell className="header-table" width={10}>
                      name
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className="header-table"
                      textAlign="center"
                      width={3}
                    />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {state.currentData.logbook &&
                    state.currentData.logbook.map((item, num) => (
                      <Table.Row>
                        <Table.Cell textAlign="right"> {num + 1} </Table.Cell>
                        <Table.Cell>
                          {item.name ? (
                            item.name
                          ) : (
                            <Form.Select
                              style={{ marginBottom: "0px" }}
                              name="logbook"
                              index={num}
                              onChange={(e, data) => this.onChange(e, data)}
                              options={this.getOptions(
                                state.currentData.options_logbook
                              )}
                            />
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {(state.currentData.status != 2 ||
                            state.canManage) && (
                              <Icon
                                link
                                color="red"
                                name="trash alternate"
                                onClick={() => this.onDeleteLogbook(num)}
                              />
                            )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  {(state.currentData.status != 2 || state.canManage) && (
                    <Table.Row>
                      <Table.Cell className="footer-table"></Table.Cell>
                      <Table.Cell className="footer-table"></Table.Cell>
                      <Table.Cell className="footer-table" textAlign="center">
                        <Button
                          type="button"
                          primary
                          compact
                          size="small"
                          onClick={this.onAddLogbook}
                        >
                          Add
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              <br />
              <br />
              {(state.currentData.status != 2 || state.canManage) && (
                <Button
                  floated="left"
                  type="submit"
                  content="Save"
                  color="green"
                  compact
                  loading={state.loading}
                  onClick={this.saveLogbook}
                />
              )}
              {(state.currentData.status != 2 || state.canManage) && (
                <Button
                  floated="right"
                  type="button"
                  content="Cancel"
                  color="green"
                  compact
                  basic
                  onClick={this.onClickCancel}
                />
              )}
            </Form>
          )}
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          {/* <Header as="h2" style={{ color: config.colorTheme }}>
            Flight Hour Record
          </Header> */}
          <HeaderWithMenu name="Flight Hour Record">
            <Button size="small" onClick={this.onClickExport} color="green">
              Export Excel
            </Button>
          </HeaderWithMenu>
          <Form>
            {" "}
            <Form.Group style={{ marginBottom: "0px" }}>
              <Form.Field width="5" style={{ paddingTop: "10px" }}>
                <DayPicker
                  day={state.seletedDate}
                  onChange={this.handleChangeDate}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Form.Dropdown
                  icon={null}
                  // fluid
                  // floating
                  clearable
                  // width="4"
                  style={{ width: "100%", marginRight: "30px", height: "100%" }}
                  trigger={
                    <Button
                      basic
                      icon
                      fluid
                      labelPosition="right"
                      style={{ textAlign: "left" }}
                    >
                      <Icon name="angle down" />
                      Sort by : <b>{state.sortText || "-"}</b>
                    </Button>
                  }
                  options={state.options_sortby}
                  selectOnBlur={false}
                  onChange={this.handleChangeSort}
                />
              </Form.Field>

            </Form.Group>
          </Form>
          {state.loading ? (
            <Loader active inline="centered" content="Loading" />
          ) : (
            <Table
              celled
              padded
              compact
              unstackable
              style={{
                fontSize: "95%",
              }}
            >
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell
                    style={{ paddingLeft: "4px", paddingRight: "4px" }}
                  >
                    Call
                    <br />
                    Sign
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: "4px" }}>
                    Aircraft
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{ paddingLeft: "4px", paddingRight: "4px" }}
                  >
                    Time
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: "4px" }}>
                    IP
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ padding: "4px" }}>
                    STD
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    MS.
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    DEST
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    Area
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      width: "150px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>ST</div>
                    <div style={{ textAlign: "right" }}>TO</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={{
                      width: "150px",
                      paddingLeft: "14px",
                      paddingRight: "14px",
                    }}
                  >
                    <div style={{ textAlign: "left" }}>LD</div>
                    <div style={{ textAlign: "right" }}>SD</div>
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "100px", padding: "4px" }}>
                    Aircraft Test
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "100px", padding: "4px" }}>
                    Total
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ width: "50px", padding: "4px" }}>
                    Unsuccess
                  </Table.HeaderCell>
                  {state.canManage && (
                    <Table.HeaderCell
                      style={{ width: "50px", padding: "4px" }}
                    ></Table.HeaderCell>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {state.data.map((item, index) => (
                  <Table.Row verticalAlign="top">
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                      textAlign="center"
                    >
                      {item.callSign}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                    >
                      {item.status === 2 ? (
                        <p style={{ color: "green", fontWeight: "bold" }}>
                          {" "}
                          {item.aircraft}
                        </p>
                      ) : (
                        <p>
                          {" "}
                          {item.aircraft}{" "}
                          {item.flightType && ` (${item.flightType})`}{" "}
                        </p>
                      )}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                      className="bold"
                      textAlign="center"
                    >
                      {moment(item.start).format("DD/MM/YYYY")}
                      <br />
                      {moment(item.start).format("LT")}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                    >
                      <Popup
                        flowing
                        hoverable
                        trigger={
                          <p
                            style={{ cursor: "pointer" }}
                            className="bold"
                            onClick={() =>
                              this.onEditLogbook("instructor", item)
                            }
                          >
                            {" "}
                            {item.instructor}{" "}
                          </p>
                        }
                        content={
                          <Table size="small" selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell
                                  colSpan="2"
                                  style={{
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                  }}
                                >
                                  Logbook
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {item.instructorLogbook &&
                                item.instructorLogbook.length === 0 ? (
                                <Table.Row>
                                  <Table.Cell
                                    style={{
                                      paddingTop: "4px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                    No data
                                  </Table.Cell>
                                  <Table.Cell textAlign="center"></Table.Cell>
                                </Table.Row>
                              ) : (
                                item.instructorLogbook.map((elem) => {
                                  if (elem)
                                    return (
                                      <Table.Row>
                                        <Table.Cell
                                          style={{
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                          }}
                                        >
                                          {elem.name}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                          {elem.value}
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                })
                              )}
                            </Table.Body>
                          </Table>
                        }
                        basic
                      />

                      {item.instructorSV && (
                        <Popup
                          flowing
                          hoverable
                          trigger={
                            <p
                              style={{ cursor: "pointer" }}
                              className="bold"
                              onClick={() =>
                                this.onEditLogbook("instructorSV", item)
                              }
                            >
                              {" "}
                              {item.instructorSV}{" "}
                            </p>
                          }
                          content={
                            <Table size="small" selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell
                                    colSpan="2"
                                    style={{
                                      paddingTop: "4px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                    Logbook
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {item.instructorSVLogbook &&
                                  item.instructorSVLogbook.length === 0 ? (
                                  <Table.Row>
                                    <Table.Cell
                                      style={{
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      No data
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"></Table.Cell>
                                  </Table.Row>
                                ) : (
                                  item.instructorSVLogbook.map((elem) => {
                                    if (elem)
                                      return (
                                        <Table.Row>
                                          <Table.Cell
                                            style={{
                                              paddingTop: "4px",
                                              paddingBottom: "4px",
                                            }}
                                          >
                                            {elem.name}
                                          </Table.Cell>
                                          <Table.Cell textAlign="center">
                                            {elem.value}
                                          </Table.Cell>
                                        </Table.Row>
                                      );
                                  })
                                )}
                              </Table.Body>
                            </Table>
                          }
                          basic
                        />
                      )}
                      {item.instructorIP && (
                        <Popup
                          flowing
                          hoverable
                          trigger={
                            <p
                              style={{ cursor: "pointer" }}
                              className="bold"
                              onClick={() =>
                                this.onEditLogbook("instructorIP", item)
                              }
                            >
                              {" "}
                              {item.instructorIP}{" "}
                            </p>
                          }
                          content={
                            <Table size="small" selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell
                                    colSpan="2"
                                    style={{
                                      paddingTop: "4px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                    Logbook
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {item.instructorIPLogbook &&
                                  item.instructorIPLogbook.length === 0 ? (
                                  <Table.Row>
                                    <Table.Cell
                                      style={{
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      No data
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"></Table.Cell>
                                  </Table.Row>
                                ) : (
                                  item.instructorIPLogbook.map((elem) => {
                                    if (elem)
                                      return (
                                        <Table.Row>
                                          <Table.Cell
                                            style={{
                                              paddingTop: "4px",
                                              paddingBottom: "4px",
                                            }}
                                          >
                                            {elem.name}
                                          </Table.Cell>
                                          <Table.Cell textAlign="center">
                                            {elem.value}
                                          </Table.Cell>
                                        </Table.Row>
                                      );
                                  })
                                )}
                              </Table.Body>
                            </Table>
                          }
                          basic
                        />
                      )}
                      {item.instructorPro && (
                        <Popup
                          flowing
                          hoverable
                          trigger={
                            <p
                              style={{ cursor: "pointer" }}
                              className="bold"
                              onClick={() =>
                                this.onEditLogbook("instructorPro", item)
                              }
                            >
                              {" "}
                              {item.instructorPro}{" "}
                            </p>
                          }
                          content={
                            <Table size="small" selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell
                                    colSpan="2"
                                    style={{
                                      paddingTop: "4px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                    Logbook
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {item.instructorProLogbook &&
                                  item.instructorProLogbook.length === 0 ? (
                                  <Table.Row>
                                    <Table.Cell
                                      style={{
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                      }}
                                    >
                                      No data
                                    </Table.Cell>
                                    <Table.Cell textAlign="center"></Table.Cell>
                                  </Table.Row>
                                ) : (
                                  item.instructorProLogbook.map((elem) => {
                                    if (elem)
                                      return (
                                        <Table.Row>
                                          <Table.Cell
                                            style={{
                                              paddingTop: "4px",
                                              paddingBottom: "4px",
                                            }}
                                          >
                                            {elem.name}
                                          </Table.Cell>
                                          <Table.Cell textAlign="center">
                                            {elem.value}
                                          </Table.Cell>
                                        </Table.Row>
                                      );
                                  })
                                )}
                              </Table.Body>
                            </Table>
                          }
                          basic
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                    >
                      <Popup
                        flowing
                        hoverable
                        trigger={
                          <p
                            style={{ cursor: "pointer" }}
                            className="bold"
                            onClick={() => this.onEditLogbook("student", item)}
                          >
                            {" "}
                            {item.student}{" "}
                          </p>
                        }
                        content={
                          <Table size="small" selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell
                                  colSpan="2"
                                  style={{
                                    paddingTop: "4px",
                                    paddingBottom: "4px",
                                  }}
                                >
                                  Logbook
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {item.studentLogbook &&
                                item.studentLogbook.length === 0 ? (
                                <Table.Row>
                                  <Table.Cell
                                    style={{
                                      paddingTop: "4px",
                                      paddingBottom: "4px",
                                    }}
                                  >
                                    No data
                                  </Table.Cell>
                                  <Table.Cell textAlign="center"></Table.Cell>
                                </Table.Row>
                              ) : (
                                item.studentLogbook.map((elem) => {
                                  if (elem)
                                    return (
                                      <Table.Row>
                                        <Table.Cell
                                          style={{
                                            paddingTop: "4px",
                                            paddingBottom: "4px",
                                          }}
                                        >
                                          {elem.name}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                          {elem.value}
                                        </Table.Cell>
                                      </Table.Row>
                                    );
                                })
                              )}
                            </Table.Body>
                          </Table>
                        }
                        basic
                      />
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                      textAlign="center"
                    >
                      {item.subject}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                      textAlign="center"
                    >
                      {item.destination}
                    </Table.Cell>
                    <Table.Cell
                      style={{ paddingLeft: "4px", paddingRight: "4px" }}
                      textAlign="center"
                    >
                      {item.area}
                    </Table.Cell>
                    {item.status === 0 ? (
                      <Wrap>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                        >
                          <Form>
                            <Form.Group
                              unstackable
                              style={{ marginBottom: "0px" }}
                            >
                              <Form.Field
                                style={{
                                  marginBottom: "0px",
                                  paddingLeft: "6px",
                                  paddingRight: "2px",
                                }}
                                width={8}
                              >
                                <Form.Input
                                  placeholder="00"
                                  type="number"
                                  min="0"
                                  max="24"
                                  name="hh"
                                  content="ST"
                                  index={index}
                                  value={item.ST && item.ST.hh}
                                  //   readOnly={readOnly}
                                  onChange={this.onChangeTime}
                                />
                              </Form.Field>
                              <label
                                style={{ textAlign: "left", paddingTop: "6px" }}
                              >
                                :
                              </label>
                              <Form.Field
                                inline
                                style={{
                                  marginBottom: "0px",
                                  paddingLeft: "2px",
                                  paddingRight: "6px",
                                }}
                                width={8}
                              >
                                <Form.Input
                                  placeholder="00"
                                  type="number"
                                  min="0"
                                  max="59"
                                  name="mm"
                                  pattern="[0-9]{2}"
                                  index={index}
                                  value={item.ST && item.ST.mm}
                                  content="ST"
                                  //   value={state.mm}
                                  //   readOnly={readOnly}
                                  onChange={this.onChangeTime}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </Table.Cell>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                        >
                          <Form>
                            <Form.Group
                              unstackable
                              style={{ marginBottom: "0px" }}
                            >
                              <Form.Field
                                style={{
                                  marginBottom: "0px",
                                  paddingLeft: "6px",
                                  paddingRight: "2px",
                                }}
                                width={8}
                              >
                                <Form.Input
                                  placeholder="00"
                                  type="number"
                                  min="0"
                                  max="24"
                                  name="hh"
                                  content="SD"
                                  index={index}
                                  value={item.SD && item.SD.hh}
                                  //   readOnly={readOnly}
                                  onChange={this.onChangeTime}
                                />
                              </Form.Field>
                              <label
                                style={{ textAlign: "left", paddingTop: "6px" }}
                              >
                                :
                              </label>
                              <Form.Field
                                inline
                                style={{
                                  marginBottom: "0px",
                                  paddingLeft: "2px",
                                  paddingRight: "6px",
                                }}
                                width={8}
                              >
                                <Form.Input
                                  placeholder="00"
                                  type="number"
                                  min="0"
                                  max="59"
                                  name="mm"
                                  pattern="[0-9]{2}"
                                  index={index}
                                  value={item.SD && item.SD.mm}
                                  content="SD"
                                  //   value={state.mm}
                                  //   readOnly={readOnly}
                                  onChange={this.onChangeTime}
                                />
                              </Form.Field>
                            </Form.Group>
                          </Form>
                        </Table.Cell>
                      </Wrap>
                    ) : state.canManage ? (
                      <Wrap>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          textAlign="center"
                        // this.setState({editField : 'ST'})
                        >
                          {state.editField && state.editRow === index ? (
                            <Form>
                              <Form.Group
                                unstackable
                                style={{ marginBottom: "0px" }}
                              >
                                <Form.Field
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "6px",
                                    paddingRight: "2px",
                                  }}
                                  width={8}
                                >
                                  <Form.Input
                                    placeholder="00"
                                    type="number"
                                    min="0"
                                    max="24"
                                    name="hh"
                                    content="ST"
                                    index={index}
                                    value={
                                      state.editItem &&
                                      state.editItem.ST &&
                                      state.editItem.ST.hh
                                    }
                                    //   readOnly={readOnly}
                                    onChange={this.onChangeEditTime}
                                  />
                                </Form.Field>
                                <label
                                  style={{
                                    textAlign: "left",
                                    paddingTop: "6px",
                                  }}
                                >
                                  :
                                </label>
                                <Form.Field
                                  inline
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "2px",
                                    paddingRight: "6px",
                                  }}
                                  width={8}
                                >
                                  <Form.Input
                                    placeholder="00"
                                    type="number"
                                    min="0"
                                    max="59"
                                    name="mm"
                                    pattern="[0-9]{2}"
                                    index={index}
                                    value={
                                      state.editItem &&
                                      state.editItem.ST &&
                                      state.editItem.ST.mm
                                    }
                                    content="ST"
                                    onChange={this.onChangeEditTime}
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          ) : (
                            <Wrap>
                              <div
                                className="bold"
                                style={{
                                  textAlign: "left",
                                  paddingBottom: "10px",
                                  paddingLeft: "4px",
                                }}
                              >
                                {item.ST}
                              </div>
                              <div
                                style={{
                                  textAlign: "right",
                                  paddingRight: "4px",
                                  fontWeight: "bold",
                                }}
                              >
                                {moment(item.ST, "HH:mm")
                                  .add(10, "minutes")
                                  .format("HH:mm")}
                              </div>
                            </Wrap>
                          )}
                        </Table.Cell>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          textAlign="center"
                        >
                          {state.editField && state.editRow === index ? (
                            <Form>
                              <Form.Group
                                unstackable
                                style={{ marginBottom: "0px" }}
                              >
                                <Form.Field
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "6px",
                                    paddingRight: "2px",
                                  }}
                                  width={8}
                                >
                                  <Form.Input
                                    placeholder="00"
                                    type="number"
                                    min="0"
                                    max="24"
                                    name="hh"
                                    content="SD"
                                    index={index}
                                    value={
                                      state.editItem &&
                                      state.editItem.SD &&
                                      state.editItem.SD.hh
                                    }
                                    //   readOnly={readOnly}
                                    onChange={this.onChangeEditTime}
                                  />
                                </Form.Field>
                                <label
                                  style={{
                                    textAlign: "left",
                                    paddingTop: "6px",
                                  }}
                                >
                                  :
                                </label>
                                <Form.Field
                                  inline
                                  style={{
                                    marginBottom: "0px",
                                    paddingLeft: "2px",
                                    paddingRight: "6px",
                                  }}
                                  width={8}
                                >
                                  <Form.Input
                                    placeholder="00"
                                    type="number"
                                    min="0"
                                    max="59"
                                    name="mm"
                                    pattern="[0-9]{2}"
                                    index={index}
                                    value={
                                      state.editItem &&
                                      state.editItem.SD &&
                                      state.editItem.SD.mm
                                    }
                                    content="SD"
                                    onChange={this.onChangeEditTime}
                                  />
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          ) : (
                            <Wrap>
                              <div
                                style={{
                                  textAlign: "left",
                                  paddingBottom: "10px",
                                  paddingLeft: "4px",
                                  fontWeight: "bold",
                                }}
                              >
                                {moment(item.SD, "HH:mm")
                                  .add(-10, "minutes")
                                  .format("HH:mm")}
                              </div>
                              <div
                                className="bold"
                                style={{
                                  textAlign: "right",
                                  paddingRight: "4px",
                                }}
                              >
                                {item.SD}
                              </div>
                            </Wrap>
                          )}
                        </Table.Cell>
                      </Wrap>
                    ) : (
                      <Wrap>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          textAlign="center"
                        >
                          <div
                            className="bold"
                            style={{
                              textAlign: "left",
                              paddingBottom: "10px",
                              paddingLeft: "4px",
                            }}
                          >
                            {item.ST}
                          </div>
                          {/*  // <div className="diagonal-line"></div> */}
                          <div
                            style={{
                              textAlign: "right",
                              paddingRight: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            {moment(item.ST, "HH:mm")
                              .add(10, "minutes")
                              .format("HH:mm")}
                            {/* {item.STTO} */}
                          </div>
                        </Table.Cell>
                        <Table.Cell
                          style={{ paddingLeft: "4px", paddingRight: "4px" }}
                          textAlign="center"
                        >
                          <div
                            style={{
                              textAlign: "left",
                              paddingBottom: "10px",
                              paddingLeft: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            {moment(item.SD, "HH:mm")
                              .add(-10, "minutes")
                              .format("HH:mm")}
                            {/* {item.LDSD} */}
                          </div>
                          {/*  // <div className="diagonal-line"></div> */}

                          <div
                            className="bold"
                            style={{ textAlign: "right", paddingRight: "4px" }}
                          >
                            {item.SD}
                          </div>
                        </Table.Cell>
                      </Wrap>
                    )}
                    {item.status === 0 ? (
                      <Table.Cell
                        textAlign="left"
                        style={{ padding: "4px", fontWeight: "bold" }}
                      >
                        {item.testname && (
                          <p>
                            <Checkbox
                              label={item.testname}
                              onChange={(e) => this.toggle(e, index)}
                            />
                          </p>
                        )}
                      </Table.Cell>
                    ) : (
                      <Table.Cell
                        textAlign="center"
                        style={{ padding: "4px", fontWeight: "bold" }}
                      >
                        {state.editField &&
                          state.editRow === index &&
                          item.testname ? (
                          <p>
                            <Checkbox
                              label={item.testname}
                              checked={state.editItem.test_result}
                              onChange={this.toggleEdit}
                            />
                          </p>
                        ) : (
                          item.testname && (
                            <p>
                              {item.testname}
                              <br />
                              {item.test_result === "PASS" ? (
                                <span style={{ color: "green" }}>
                                  {item.test_result}
                                </span>
                              ) : (
                                item.test_result === "Not Passed" && (
                                  <span style={{ color: "red" }}>
                                    {item.test_result}
                                  </span>
                                )
                              )}
                            </p>
                          )
                        )}
                      </Table.Cell>
                    )}
                    <Table.Cell
                      textAlign="center"
                      style={{ padding: "4px", fontWeight: "bold" }}
                    >
                      {state.editField && state.editRow === index
                        ? state.editItem.flightInstruction
                        : item.flightInstruction}
                    </Table.Cell>
                    {
                      item.status === 0 ? (
                        <Table.Cell
                          textAlign="center"
                          style={{ padding: "4px", fontWeight: "bold" }}
                        >
                          <p>
                            <Checkbox
                              onChange={(e) => this.toggleUnsuccess(e, index)}
                            />
                          </p>
                        </Table.Cell>
                      ) : item.status === 2 ? (
                        state.editField &&
                          state.editRow === index ?
                          <Table.Cell
                            textAlign="center"
                            style={{ padding: "4px", fontWeight: "bold" }}
                          >
                            <p>
                              <Checkbox
                                onChange={(e) => this.toggleUnsuccess(e, index)}

                              />
                            </p>
                          </Table.Cell>
                          : <Table.Cell
                            textAlign="center"
                            style={{ padding: "4px", fontWeight: "bold" }}
                          >
                            <p>-</p>
                          </Table.Cell>

                      ) :
                        item.status === 1 && (state.editField &&
                          state.editRow === index ?
                          <Table.Cell
                            textAlign="center"
                            style={{ padding: "4px", fontWeight: "bold" }}
                          >
                            <p>
                              <Checkbox
                                onChange={(e) => this.toggleUnsuccess(e, index)}
                                checked={item.unsuccess}
                              />
                            </p>
                          </Table.Cell>
                          :
                          <Table.Cell
                            textAlign="center"
                            style={{ padding: "4px", fontWeight: "bold" }}
                          >
                            <p style={{ color: "red" }}>Unsuccess</p>
                          </Table.Cell>
                        )
                    }
                    {state.canManage && (
                      <Table.Cell
                        style={{
                          padding: "4px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {item.status !== 0 &&
                          state.editField &&
                          state.editRow === index ? (
                          <div>
                            <Button
                              animated="vertical"
                              size="mini"
                              color="green"
                              // loading={loading}
                              onClick={() => this.updateEditField(item)}
                            >
                              <Button.Content hidden>Update</Button.Content>
                              <Button.Content visible>
                                <Icon name="check" />
                              </Button.Content>
                            </Button>
                            <br /> <br />
                            <Button
                              size="mini"
                              animated="vertical"
                              onClick={() =>
                                this.setState({ editField: false })
                              }
                            >
                              <Button.Content hidden>Cancel</Button.Content>
                              <Button.Content visible>
                                <Icon name="x" />
                              </Button.Content>
                            </Button>
                          </div>
                        ) : (
                          item.status !== 0 && (
                            <Icon
                              link
                              name="edit"
                              onClick={() => this.onClickEdit(item, index)}
                            />
                          )
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell
                    colSpan={state.canManage ? 14 : 13}
                    textAlign=""
                  >
                    {state.canSave && (
                      <Button
                        floated="left"
                        type="submit"
                        compact
                        content="Save"
                        color="green"
                        disabled={state.invalidTime}
                        onClick={this.validateBeforeSubmit}
                      />
                    )}
                    <Pagination
                      floated="right"
                      activePage={state.current_page}
                      size="mini"
                      totalPages={state.last_page}
                      onPageChange={this.handlePaginationChange}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          )}
        </Segment>
      </Wrap>
    );
  }
}
export default withRouter(FlightHourRecord);
