import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Segment, Button, Icon, Image, Label } from "semantic-ui-react";
import * as config from "../../config";
import MainButton from "../../components/mainbutton";
import ModalForm from "../../components/modal";
import FormAircraft from "./aircraftForm";
import { getAircraftListBySearch, postFlight, deleteAircraft } from "./service";
import HeaderWithMenu from "../../components/headerwithmenu";
import TableList from "../../components/table";
import Confirmmodal from "../../components/confirmmodal";

class MainAircraft extends Component {
  state = {
    loading: false,
    aircraftSelected: [],
    rawData: [],
    data: [],
    search: {
      status: "Active",
      sort: {
        key: "tailNumber",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
    },
    sortText: "Name (ASC)",
    options_sortby: [
      {
        key: "tailNumber",
        text: "Name",
        value: "1",
        icon: "sort alphabet ascending",
        description: "ASC ",
      },
      {
        key: "tailNumber",
        text: "Name",
        value: "2",
        icon: "sort alphabet descending",
        description: "DESC",
      },
      // {
      //   key: "maintenanceStatus",
      //   text: "Maint. Status",
      //   value: "3",
      //   icon: "sort numeric ascending",
      //   description: "ASC "
      // },
      // {
      //   key: "maintenanceStatus",
      //   text: "Maint. Status",
      //   value: "4",
      //   icon: "sort numeric descending",
      //   description: "DESC"
      // }
    ],
    column: [
      {
        Header: "Image",
        accessor: "imageUrl",
        style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Image
            avatar
            size="mini"
            verticalAlign="middle"
            src={
              cellInfo.original.imageUrl &&
              cellInfo.original.imageUrl + "?time=" + new Date()
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://app.flightschedulepro.com/images/img-10.jpg";
            }}
          />
        ),
        width: 60,
      },
      {
        Header: "Aircraft",
        accessor: "tailNumber",
        sortable: false,
        width: 300,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: (cellInfo) => (
          <span>
            <p
              onClick={() => this.onClickProfile(cellInfo.original)}
            >{`${cellInfo.original.tailNumber} ${cellInfo.original.schedulingGroupName}`}</p>
          </span>
        ),
      },
      {
        Header: "Maint. Status",
        accessor: "location",
        sortable: false,
        width: 100,
        style: { textAlign: "center" },
        Cell: (cellInfo) =>
          cellInfo.original.maintenanceStatus === 1 ? (
            <Label
              size="mini"
              color="green"
              key="green"
              className="statusLabel"
            >
              OK
            </Label>
          ) : cellInfo.original.maintenanceStatus === 2 ? (
            <Label
              size="mini"
              name="warning circle"
              color="yellow"
              className="statusLabel"
            >
              Warning
            </Label>
          ) : (
            cellInfo.original.maintenanceStatus === 3 && (
              <Label
                size="mini"
                name="warning circle"
                color="red"
                className="statusLabel"
              >
                Expired
              </Label>
            )
          ),
      },
      {
        Header: "Hours Remaining",
        accessor: "timeRemainingMessage",
        sortable: false,
        style: { textAlign: "center" },
        Cell: (cellInfo) =>
          cellInfo.original.maintenanceStatus === 1 ? (
            cellInfo.original.timeRemainingMessage
          ) : (
            <span
              style={{
                color:
                  cellInfo.original.maintenanceStatus === 2 ? "orange" : "red",
              }}
            >
              {cellInfo.original.timeRemainingMessage}
            </span>
          ),
      },
      {
        Header: "SNAG",
        sortable: false,
        accessor: "discrepancy",
      },

      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: (cellInfo) => (
          <span>
            <Icon
              link
              // circular
              color="orange"
              name="plane"
              onClick={() => this.onClickProfile(cellInfo.original)}
            />{" "}
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        ),
      },
    ],
  };

  componentDidMount() {
    let { search } = this.state;
    let qString =
      "?isActive=true&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    this.getData(qString);
  }

  getData = (qString) => {
    getAircraftListBySearch(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data, rawData: res.data });
      }
      this.setState({ loading: false });
    });
  };

  onClickProfile = (data) =>
    this.props.history.push({
      pathname: "/Aircraft/Profile",
      state: { id: data.id },
    });

  onClickNew = () => this.setState({ open: true });
  onClickSave = (value) => {
    let data = value;
    if (value.category === "airplane") {
      data.aircraftClassId = value.airClass && value.airClass.id;
      data.simulatorClassId = null;
    } else {
      data.aircraftClassId = null;
      data.simulatorClassId = value.simClass;
    }
    data.enginesHavePropellers = value.enginesHavePropellers ? "true" : "false";
    data.enginesAreTurbines = value.enginesAreTurbines ? "true" : "false";
    data.isActive = "true";
    data.trackOilFuel = value.trackOilFuel ? "true" : "false";
    let { search } = this.state;
    this.setState({ loading: true });
    postFlight(data).then((res) => {
      if (res && res.status === 201) {
        let qString =
          "?isActive=true&_sort=" +
          search.sort.key +
          "&_order=" +
          search.sort.description;
        this.getData(qString);
        this.setState({ open: false });
      }
      this.setState({ open: false });
    });
  };
  deleteData = () => {
    let { currentData, search } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteAircraft(currentData.id).then((res) => {
        if (res && res.status === 200) {
          let qString =
            "?isActive=true&_sort=" +
            search.sort.key +
            "&_order=" +
            search.sort.description;
          this.getData(qString);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
  };
  onClickCancel = () =>
    this.setState({
      open: false,
      aircraftSelected: false,
    });
  onClickDelete = (value) => {
    this.setState({
      openConfirm: true,
      currentData: value,
    });
  };
  render() {
    const state = this.state;
    return (
      <div>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Aircraft / Simulator"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
        <ModalForm
          open={state.open}
          icon="plane"
          content=" Add Aircraft / Simulator"
          close={this.onClickCancel}
        >
          <FormAircraft
            loading={state.loading}
            onSubmit={this.onClickSave}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Aircraft / Simulator">
            <MainButton
              size="small"
              text="Add Aircraft / Simulator"
              onClick={this.onClickNew}
            />
          </HeaderWithMenu>
          <Form>
            <Form.Group>
              <Form.Input
                width="3"
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='4'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Sort by : <b>{state.sortText}</b>
                  </Button>
                }
                options={state.options_sortby}
                defaultValue={state.search.sort}
                onChange={this.handleChangeSort}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                // width='3'
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{state.search.status}</b>
                  </Button>
                }
                options={config.options_active}
                defaultValue={state.search.status}
                onChange={this.handleChangeStatus}
              />
            </Form.Group>
          </Form>
          <TableList
            loading={state.loading}
            data={state.data}
            columns={state.column}
          />
        </Segment>
      </div>
    );
  }

  handleChangeStatus = (e, data) => {
    let { search } = this.state;
    let active, qString;

    if (data.value === "Active") active = true;
    else active = false;

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search });

    qString =
      "?isActive=" +
      active +
      "&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    this.getData(qString);
  };

  handleChangeSort = (e, data) => {
    this.setState({ loading: true });
    let { search } = this.state;
    let active, qString;

    if (search.status === "Active") active = true;
    else active = false;

    const result = data.options.find((member) => {
      return member.value === data.value;
    });
    search["sort"] = result;
    this.setState({
      search,
      sortText: `${result.text} (${result.description})`,
    });

    qString =
      "?isActive=" +
      active +
      "&_sort=" +
      search.sort.key +
      "&_order=" +
      search.sort.description;
    this.getData(qString);
  };

  handleChangeSearch = (e) => {
    let { rawData, search } = this.state;
    let active;
    search[e.target.name] = e.target.value;
    this.setState({ search });

    if (search.status === "Active") active = true;
    else active = false;

    if (e.target.value) {
      let newdata = [];
      rawData.map((item) => {
        let description = `${item.tailNumber} ${item.make} ${item.model}`;
        if (
          description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawData });
    }
  };
}

export default withRouter(MainAircraft);
