import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Segment, Icon, Button } from "semantic-ui-react";
import * as config from "../../config";
import TableList from "../../components/table";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import FormRoom from "./roomForm";
import Confirmmodal from "../../components/confirmmodal";

import {
  getRoomListListBySearch,
  postRoom,
  updateRoom,
  deleteRoom,
  getLocation
} from "./service";

class Room extends Component {
  state = {
    rawdata: [],
    data: [],
    loading: true,
    options_location: [],
    options_locationForm: [],
    search: {
      status: "Active",
      type: 0,
      typeText: "All",
      location: 0,
      locationText: "All"
    },
    column: [
      {
        Header: "Name",
        accessor: "name",
        sortable: false,
        width: 200,
        style: { fontWeight: "bold", cursor: "pointer" },
        Cell: cellInfo => (
          <span>
            <p onClick={() => this.onClickEdit(cellInfo.original)}>
              {cellInfo.original.name}
            </p>
          </span>
        )
      },
      {
        Header: "Location",
        accessor: "locationName",
        sortable: false,
        width: 250
      },
      {
        Header: "Capacity",
        accessor: "capacity",
        sortable: false,
        width: 100,
        style: { textAlign: "center" }
      },
      {
        Header: "IT Equipment",
        accessor: "itEquipment",
        sortable: false
      },
      {
        Header: "Remark",
        accessor: "remark",
        sortable: false
      },
      {
        Header: "Action",
        accessor: "id",
        style: { textAlign: "center" },
        sortable: false,
        width: 100,
        Cell: cellInfo => (
          <span>
            <Icon
              link
              name="edit"
              onClick={() => this.onClickEdit(cellInfo.original)}
            />
            &nbsp; &nbsp;
            <Icon
              link
              color="red"
              name="trash alternate"
              onClick={() => this.onClickDelete(cellInfo.original)}
            />
          </span>
        )
      }
    ]
  };

  componentDidMount() {
    let option = [
        {
          id: 0,
          text: "All",
          value: 0
        }
      ],
      options_locationForm = [];
    getLocation().then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          option.push({
            id: item.id,
            text: item.name,
            value: item.id
          });
          options_locationForm.push({
            id: item.id,
            text: item.name,
            value: item.id
          });
        });
        this.setState({ options_location: option, options_locationForm });
      }
    });
    this.getData("?isActive=true");
  }

  getData = qString => {
    this.setState({ loading: true });
    getRoomListListBySearch(qString).then(res => {
      if (res && res.status === 200) {
        this.setState({ data: res.data, rawData: res.data });
      }
      this.setState({ loading: false });
    });
  };

  saveData = data => {
    this.setState({ loading: true });
    let { search } = this.state;
    data.isActive = "true";
    data.type_id = data.type;
    data.location_id = data.location;
    postRoom(data).then(res => {
      if (res && res.status === 201) {
        this.onClickCancel();
        let qString = "";
        if (search.status === "Active") qString = "?isActive=true";
        else qString = "?isActive=false";
        if (search.type !== 0) qString = qString + "&type=" + search.type;
        if (search.location !== 0)
          qString = qString + "&location=" + search.location;
        this.getData(qString);
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };

  updateData = data => {
    this.setState({ loading: true });
    let { search } = this.state;
    if (data.isActive) data.isActive = "true";
    else data.isActive = "false";
    data.type_id = data.type;
    data.location_id = data.location;
    updateRoom(data).then(res => {
      if (res && res.status === 200) {
        this.onClickCancel();
        let qString = "";
        if (search.status === "Active") qString = "?isActive=true";
        else qString = "?isActive=false";
        if (search.type !== 0) qString = qString + "&type=" + search.type;
        if (search.location !== 0)
          qString = qString + "&location=" + search.location;
        this.getData(qString);
      }
      this.setState({ loading: false });
    });
  };
  deleteData = () => {
    let { currentData, search } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteRoom(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.onClickCancel();
          this.setState({ openConfirm: false });
          let qString = "";
          if (search.status === "Active") qString = "?isActive=true";
          else qString = "?isActive=false";
          if (search.type !== 0) qString = qString + "&type=" + search.type;
          if (search.location !== 0)
            qString = qString + "&location=" + search.location;
          this.getData(qString);
        }
        this.setState({ loading: false });
      });
  };
  onClickNew = () => {
    this.setState({
      open: true,
      currentData: null,
      isNew: true,
      content: "New Room"
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, currentData: null, isNew: false });
  };
  onClickEdit = value => {
    if (value.isActive === "true") value.isActive = true;
    else value.isActive = false;
    this.setState({
      open: true,
      currentData: value,
      isNew: false,
      content: "Room"
    });
  };

  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Room">
            <MainButton
              size="small"
              text="Add Room"
              onClick={this.onClickNew}
            />
          </HeaderWithMenu>
          <Form>
            <Form.Group>
              <Form.Input
                width="3"
                icon="search"
                size="small"
                placeholder="Search"
                name="name"
                onChange={this.handleChangeSearch}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                width="3"
                selectOnBlur={false}
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Type : <b>{state.search.typeText}</b>
                  </Button>
                }
                options={config.room_type}
                defaultValue={state.search.type}
                onChange={this.handleChangeType}
              />

              <Form.Dropdown
                icon={null}
                fluid
                floating
                scrolling
                // width='4'
                selectOnBlur={false}
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Location : <b>{state.search.locationText}</b>
                  </Button>
                }
                options={state.options_location}
                defaultValue={state.search.location}
                onChange={this.handleChangeLocation}
              />
              <Form.Dropdown
                icon={null}
                fluid
                floating
                width="3"
                selectOnBlur={false}
                style={{ width: "100%", marginRight: "30px" }}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Status : <b>{state.search.status}</b>
                  </Button>
                }
                options={config.options_active}
                defaultValue={state.search.status}
                onChange={this.handleChangeStatus}
              />
            </Form.Group>
          </Form>
          <TableList
            loading={state.loading}
            data={state.data}
            columns={state.column}
          />
        </Segment>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Room"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
        <ModalForm
          open={state.open}
          icon="building"
          content={state.content}
          close={this.onClickCancel}
        >
          <FormRoom
            isNew={state.isNew}
            loading={state.loading}
            option_location={state.options_locationForm}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.isNew ? this.saveData : this.updateData}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
      </div>
    );
  }

  handleChangeStatus = (e, data) => {
    let { search } = this.state;
    let active, qString;

    if (data.value === "Active") active = true;
    else active = false;

    search["status"] = data.value; // "Active/Inactive"
    this.setState({ search });

    qString = "?isActive=" + active;
    if (search.type !== 0) qString = qString + "&type=" + search.type;
    if (search.location !== 0)
      qString = qString + "&location=" + search.location;
    this.getData(qString);
  };

  handleChangeType = (e, data) => {
    let { search } = this.state;
    let qString, active;
    search["type"] = data.value;

    config.room_type.map(item => {
      if (item.value === data.value) {
        search["typeText"] = item.text;
      }
    });

    if (data.value === 0) {
      if (search.status === "Active") active = true;
      else active = false;

      qString = "?isActive=" + active;
      if (search.location !== 0)
        qString = qString + "&location=" + search.location;
      this.getData(qString);
    } else {
      if (search.status === "Active") active = true;
      else active = false;

      qString = "?isActive=" + active + "&type=" + data.value;
      if (search.location !== 0)
        qString = qString + "&location=" + search.location;
      this.getData(qString);
    }

    this.setState({ search });
  };

  handleChangeLocation = (e, data) => {
    let { search, options_location } = this.state;
    let qString, active;
    search["location"] = data.value;

    options_location.map(item => {
      if (item.value === data.value) {
        search["locationText"] = item.text;
      }
    });

    if (data.value === 0) {
      if (search.status === "Active") active = true;
      else active = false;

      qString = "?isActive=" + active;
      if (search.type !== 0) qString = qString + "&type=" + search.type;
      this.getData(qString);
    } else {
      if (search.status === "Active") active = true;
      else active = false;

      qString = "?isActive=" + active + "&location=" + data.value;
      if (search.type !== 0) qString = qString + "&type=" + search.type;
      this.getData(qString);
    }

    this.setState({ search });
  };

  handleChangeSearch = e => {
    let { rawData, search, data } = this.state;
    search[e.target.name] = e.target.value;
    this.setState({ search });

    if (e.target.value) {
      let newdata = [];
      data.map(item => {
        if (
          item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else {
      this.setState({ data: rawData });
    }
  };
}

export default withRouter(Room);
