import React from "react";
import { Header, Grid } from "semantic-ui-react";
import * as config from "../config";

const HeadText = props => {
  return (
    <Grid style={{ paddingBottom: "14px" }}>
      <Grid.Column floated="left"
        computer={props.widthButton ? (16 - props.widthButton) : 8}
        tablet={props.widthButton ? (16 - props.widthButton) : 8}
        mobile={props.widthButton ? (16 - props.widthButton) : 8}>
        <Header as={props.as || "h2"} style={{ color: config.colorTheme }}>
          <Header.Content style={{ textAlign: "left" }}>
            {props.name}
          </Header.Content>
        </Header>
      </Grid.Column>
      <Grid.Column
        floated="right"
        computer={props.widthButton || 8}
        tablet={props.widthButton || 8}
        mobile={props.widthButton || 8}
        textAlign="right"
        verticalAlign="bottom"
      >
        {props.children}
      </Grid.Column>
    </Grid>
  );
};

export default HeadText;
