import React from "react";
import { Form, Radio, ItemMeta } from "semantic-ui-react";
import Wrap from "./wrap";
const renderField = ({
  input,
  width,
  label,
  options,
  required,
  inline,
  defaultChecked,
  meta: { touched, error, warning }
}) => (
  <Wrap>
    <Form.Group unstackable inline={inline}>
      <Form.Field width="4">
        <label style={{ textAlign: "left" }}>
          {label} {required ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
      </Form.Field>
      <Form.Field
        width={width || 12}
        required={required}
        style={{ marginBottom: "0px", textAlign: "left" }}
      >
        {options
          ? options.map(item => (
              <Form.Radio
                key={item.key}
                label={item.label}
                // name="radioGroup"
                checked={item.value === input.value}
                onChange={() => input.onChange(item.value)}
              />
              // </Form.Radio>
              // <Form.Radio
              //   label={item.label}
              //   value={item.value}
              //   checked={item.value}
              //   onChange={(param, data) => input.onChange(data.checked)} //input.onChange(data.checked)
              // />
            ))
          : null}
      </Form.Field>
    </Form.Group>
  </Wrap>
);

export default renderField;
