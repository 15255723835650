import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Button,
} from "semantic-ui-react";
import ModalForm from "../../components/modal";
import MainButton from "../../components/mainbutton";
import Confirmmodal from "../../components/confirmmodal";

import * as config from "../../config";
import {
  getGroundInstructionType,
  postGroundInstructionType,
  updateGroundInstructionType,
  deleteGroundInstructionType,
} from "./service";
class Settings extends Component {
  state = {
    menu: [
      {
        id: 1,
        icon: "building",
        description: "Ground Instruction Type",
        type: "groundtype",
      },
    ],
    data: [],
  };

  getData = (type) => {
    this.setState({ type, open: true });
    if (type === "groundtype") {
      this.setState({ content: "Ground Instruction Type" });
      this.getGroundInstructionType();
    }
  };
  getGroundInstructionType = () => {
    this.setState({ loading: true });
    getGroundInstructionType().then((res) => {
      if (res && res.status === 200)
        this.setState({
          // open: true,
          data: res.data,
          loading: false,
          loadingUp: false,
        });
    });
  };
  onClickDelete = (value) => {
    this.setState({
      openConfirm: true,
      currentData: value,
    });
  };
  onClickCancel = () => {
    this.setState({
      open: false,
      data: [],
      editId: null,
      editName: null,
      editCompensation: null,
    });
  };
  saveData = () => {
    let { type, name, compensation } = this.state;
    this.setState({ loading: true });
    if (type === "groundtype") {
      postGroundInstructionType({
        name: name,
        compensation: compensation,
      }).then((res) => {
        if (res && res.status === 201) {
          this.getGroundInstructionType();
          this.setState({ name: null });
        }
      });
    }
  };
  updateData = () => {
    let { type, editId, editName, editCompensation } = this.state;
    this.setState({ loadingUp: true });
    if (type === "groundtype") {
      updateGroundInstructionType({
        id: editId,
        name: editName,
        compensation: editCompensation,
      }).then((res) => {
        if (res && res.status === 200) {
          this.setState({ editId: null, editName: null });
          this.getGroundInstructionType();
        }
      });
    }
  };
  deleteData = () => {
    let { type, currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      if (type === "groundtype") {
        deleteGroundInstructionType(currentData.id).then((res) => {
          if (res && res.status === 200) {
            this.getGroundInstructionType();
            this.setState({ openConfirm: false });
          }
          this.setState({ loading: false });
        });
      }
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  //   handleChange = (e, { value }) => this.setState({ [naeditName: value });
  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <Header as="h2" style={{ color: config.colorTheme }}>
            Ground Instruction Type Setting
          </Header>
          <Card.Group itemsPerRow="5" doubling style={{ padding: "20px" }}>
            {state.menu.map((item) => (
              <Card
                raised
                key={item.id}
                style={{ background: "#ededed" }}
                onClick={() => this.getData(item.type)}
              >
                <Card.Content style={{ textAlign: "center" }}>
                  <Header as="h5" icon style={{ marginBottom: "0px" }}>
                    <Icon
                      name={item.icon}
                      style={{ color: config.colorTheme }}
                    />
                    {item.description}
                  </Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <br />
          <br />
        </Segment>

        {/* make */}
        <ModalForm
          open={state.open}
          icon="plane"
          content={state.content}
          close={this.onClickCancel}
        >
          {!state.editId && (
            <Form onSubmit={this.saveData}>
              <Form.Group>
                <Form.Input
                  placeholder="Make Name"
                  name="name"
                  width={8}
                  onChange={this.handleChange}
                />
                <Form.Input
                  placeholder="Baht/hr."
                  name="compensation"
                  width={5}
                  type="number"
                  min={0}
                  max={10000}
                  onChange={this.handleChange}
                />
                <MainButton
                  size="small"
                  width={3}
                  text="Add New"
                  // loading={state.loading}
                  disable={!state.name || state.loading}
                />
              </Form.Group>
            </Form>
          )}

          <Table compact celled striped style={{ marginBottom: "30px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="header-table" width={1} />
                <Table.HeaderCell className="header-table" width={6}>
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  width={4}
                  textAlign="center"
                >
                  Compensation
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  textAlign="center"
                  width={3}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.loading ? (
                <Table.Row>
                  <Table.Cell textAlign="right"> </Table.Cell>
                  <Table.Cell textAlign="left" colSpan="2">
                    Loading..
                  </Table.Cell>
                </Table.Row>
              ) : (
                state.data.map((item, num) => {
                  return (
                    <Table.Row>
                      <Table.Cell textAlign="right"> {num + 1} </Table.Cell>

                      {state.editId === item.id ? (
                        <Table.Cell colSpan="2">
                          <Form size="small">
                            <Form.Group style={{ marginBottom: "0px" }}>
                              <Form.Input
                                placeholder="Name"
                                name="editName"
                                width={8}
                                value={state.editName}
                                onChange={this.handleChange}
                              />
                              <Form.Input
                                placeholder="Baht/hr."
                                name="editCompensation"
                                width={5}
                                type="number"
                                min={0}
                                max={10000}
                                value={state.editCompensation}
                                onChange={this.handleChange}
                              />
                              <Button
                                animated="vertical"
                                size="small"
                                color="green"
                                loading={state.loadingUp}
                                onClick={this.updateData}
                              >
                                <Button.Content hidden>Update</Button.Content>
                                <Button.Content visible>
                                  <Icon name="check" />
                                </Button.Content>
                              </Button>
                              <Button
                                size="small"
                                animated="vertical"
                                onClick={() =>
                                  this.setState({
                                    editId: null,
                                    editName: null,
                                  })
                                }
                              >
                                <Button.Content hidden>Cancel</Button.Content>
                                <Button.Content visible>
                                  <Icon name="x" />
                                </Button.Content>
                              </Button>
                            </Form.Group>
                          </Form>
                        </Table.Cell>
                      ) : (
                        <Table.Cell>
                          <p
                            style={{
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.setState({
                                editId: item.id,
                                editName: item.name,
                                editCompensation: item.compensation,
                              })
                            }
                          >
                            {item.name}
                          </p>
                        </Table.Cell>
                      )}

                      {state.editId !== item.id && (
                        <Table.Cell textAlign="center">
                          {item.compensation && `${item.compensation} Baht/hr.`}
                        </Table.Cell>
                      )}
                      <Table.Cell textAlign="center">
                        <Icon
                          link
                          name="edit"
                          onClick={() =>
                            this.setState({
                              editId: item.id,
                              editName: item.name,
                              editCompensation: item.compensation,
                            })
                          }
                        />
                        &nbsp; &nbsp;{" "}
                        <Icon
                          link
                          color="red"
                          name="trash alternate"
                          onClick={() => this.onClickDelete(item)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title={state.type}
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(Settings);
