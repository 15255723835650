import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment, Form, Breadcrumb, Grid, Header,
  Image, Button, Table, Icon, Dimmer, Loader,
} from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import {
  getStaffById,
  getAllNotes,
  updateStaff,
  uploadImageProfile,
  deleteImageProfile
} from "./service";
import {
  getSpl, getMed
} from "../People/service"
import moment from "moment";

import * as config from "../../config";
import validate from "../../validate";
import HeaderWithMenu from "../../components/headerwithmenu";
import ModalForm from "../../components/modal";
import Wrap from "../../components/wrap";
import FormStaff from "./staffForm";
import MainPeople from "../People/main";
import Profile from "../People/profile";
import Notes from "../People/note";
import Documents from "../People/documents";
import Subject from "../People/subject";
import Hours from "../People/Hours";
import Logbook from "../People/Logbook";
import ScheduleById from "../Schedule/scheduleById";
import UploadImage from "../../components/uploadImage";
// import { ReservationTypes } from '../Schedule/data'

class ProfileInstructors extends Component {
  state = {
    locationState: this.props.location.state,
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
    data: [],
    dataEdit: [],
    loading: false,

  };
  onClickEdit = () => {
    this.setState({ open: true });
  };
  onClickCancel = () => {
    this.setState({ open: false, openUploadPhoto: false });
  };
  onClickUploadPhoto = () => {
    let { data } = this.state;
    this.setState({
      openUploadPhoto: true,
      imageURL: data.imageURL
    });
  };
  onUploadPhoto = file => {
    if (file) {
      this.setState({ loading: true, uploadError: null });
      let { data } = this.state;
      var formData = new FormData();
      if (typeof file === "object") formData.append("image", file[0]);
      else formData.append("image", file);
      uploadImageProfile(data.id, formData).then(res => {
        if (res && res.status === 200) {
          this.setState({
            imageURL: res.data.imageUrl
          });
          this.getData(data.id);
        } else if (res && res.status === 422) {
          this.setState({
            uploadError:
              res.data.errors &&
              res.data.errors.image &&
              res.data.errors.image[0]
          });
        } else {
          this.setState({
            uploadError: "Whoops, looks like something went wrong."
          });
        }
        this.setState({ loading: false });
      });
    }
  };
  onDeletePhoto = () => {
    let { data } = this.state;
    deleteImageProfile(data.id).then(res => {
      if (res && res.status === 200) {
        this.setState({
          imageURL: null
        });
        this.getData(data.id);
      }
      this.setState({ loading: false });
    });
  };
  getNotes = id => {
    // let qString = "?personnelId=" + id;
    let { bacUserData,bac_permission } = this.state
    getAllNotes(id).then(res => {
      if (res && res.status === 200) {
        let options = []
        // console.log('bacUserData',bacUserData);
        // console.log('bac_permission',bac_permission);
        // console.log('id',id);
        //ต้อง manage ได้ด้วยถึงจะเห็นทั้งหมด
        if (bacUserData.id === id) {
          res.data.map(members => {
            if(members.internal === "false" || members.internal === false){
              options.push(members)
            }
          })
        } else {
          if (this.checkPermissionMenu("Personnel", ["manage-instructor","manage-student","manage-staff","view-internalnote"])){
            res.data.map(members => {
                options.push(members)
            })
          }else {
            res.data.map(members => {
              if(members.internal === "false" || members.internal === false){
                options.push(members)
              }
            })
          }
        }

        this.setState({ note: options, rawNote: res.data});
      }
    });
  };
  getData = id => {
    getStaffById(id).then(res => {
      if (res && res.status === 200) {
       console.log("getStaffById", res.data);
        let dataSetEdit = {
          staffID: res.data.staffID,
          isActive: res.data.isActive,
          titleEng: res.data.titleEng,
          firstNameEng: res.data.firstNameEng,
          lastNameEng: res.data.lastNameEng,
          titleTh: res.data.titleTh,
          firstNameTh: res.data.firstNameTh,
          lastNameTh: res.data.lastNameTh,
          nickName: res.data.nickName,
          email: res.data.email,
          personnel_email: res.data.personnel_email,
          phone: res.data.phone,
          username: res.data.username,
          dateOfBirth: res.data.dateOfBirth,
          gender: res.data.gender,
          roles: res.data.rolesName,
          status: res.data.status,
          position: res.data.position
        };
        this.setState({ data: res.data, dataEdit: dataSetEdit });
      }
    });
  };
  onSubmitEdit = data => {
    console.log("editxxxxxxxxx", data);
    this.setState({ loading: true });
    if(data.isActive) data.isActive = 'true'
    else data.isActive = 'false'
    let dataStaff = {
      id: this.props.location.state.id,
      ...data,
    }
    console.log(dataStaff);
    updateStaff(dataStaff).then(res => {
      if (res && res.status === 200) {
        this.setState({ open: false, data: [] });
        this.getData(this.props.location.state.id);
      }
      this.setState({ loading: false });
    });
  };
  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(item => item.group === group);
    let allowed = permission.find(item => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  componentDidMount() {
    this.getData(this.props.location.state.id);
    this.getNotes(this.props.location.state.id);

  }

  render() {
    const state = this.state;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log('state.data',state.data)
    if (state.data.length === 0)
      return (
        <Segment className="contentSegment" style={{ minHeight: "50vh" }}>
          <Dimmer active inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </Segment>
      );
    // console.log("ff", state.data);
    return (
      <div>
        <ModalForm
          open={state.openUploadPhoto}
          icon="image outline"
          size="small"
          content={`${state.data.firstNameEng} ${state.data.lastNameEng}`}
          close={this.onClickCancel}
          save={this.onUploadPhoto}
        >
          <UploadImage
            uploadError={state.uploadError}
            loading={state.loading}
            imageUrl={state.imageURL}
            onChange={this.onUploadPhoto}
            onClickDelete={this.onDeletePhoto}
          />
        </ModalForm>

        <ModalForm
          icon="edit"
          content="Edit Profile"
          open={state.open}
          close={this.onClickCancel}
        >
          <FormStaff
            loading={state.loading}
            isAdd={false}
            initialValues={state.dataEdit ? state.dataEdit : null}
            onSubmit={this.onSubmitEdit}
            onCancel={this.onClickCancel}
          />
        </ModalForm>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Staff">
            {this.checkPermissionMenu("Personnel", ["manage-staff"])
              && (
                <Wrap>
                  <Button size="small" content="Edit Profile" onClick={this.onClickEdit} />
                  <Button size="small" content=" Upload Photo" onClick={this.onClickUploadPhoto} />
                </Wrap>
              )
            }
          </HeaderWithMenu>
          <Breadcrumb>
            {!state.locationState.status  &&
              <Wrap>
                <Breadcrumb.Section
                  onClick={() => this.props.history.push("/Staffs")}
                  style={{ color: "grey" }}
                >
                  Staffs
                  </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
              </Wrap>
            }
            <Breadcrumb.Section active>Staff Profile</Breadcrumb.Section>
          </Breadcrumb>
          < br />
          <br />
          <Grid>
            <Grid.Row columns={2} divided style={{ paddingBottom: 0 }}>
              <Grid.Column width={4}>
                <Image
                  src={
                    state.data.imageURL ? state.data.imageURL+'?time='+ new Date() :
                    "https://app.flightschedulepro.com/images/instructor-default.gif"
                  }
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://app.flightschedulepro.com/images/instructor-default.gif";
                  }}
                />
              </Grid.Column>
              <Grid.Column width={12}>
                <Header as="h2" style={{ color: config.colorTheme }}>
                  <Header.Content>
                     {state.data.staffID && <span>{state.data.staffID}&nbsp;&nbsp;</span>}
                     {state.data.firstNameEng && state.data.firstNameEng}&nbsp;&nbsp;
                     {state.data.lastNameEng && state.data.lastNameEng}
                    <Header.Subheader style={{ color: config.colorTheme }}>
                      {state.data.firstNameTh +
                        " " +
                        state.data.lastNameTh +
                        " " +
                        (state.data.nickName
                          ? "( " + state.data.nickName + " )"
                          : "")}
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="5">
                            <p className="bold">Position</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.position ? state.data.position : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Phone</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.phone ? state.data.phone : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">BAC Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.email ? state.data.email : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Personnel Email</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.personnel_email ? state.data.personnel_email : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Username</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.username
                              ? state.data.username
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        {/*<Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            <p className="bold">Roles</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.rolesName && state.data.rolesName.length > 0
                              ? state.data.rolesName.map(r =>
                                <p style={{marginBottom: 0}}>{r}<br/></p>
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>*/}
                      </Grid>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Status</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.status ? state.data.status : '-'}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Date of Birth</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.dateOfBirth
                              ? moment(state.data.dateOfBirth, 'DD-MM-YYYY').format(
                                "DD MMMM YYYY"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Gender</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.gender ? state.data.gender : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Last Login</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.lastLogin
                              ? moment(state.data.lastLogin).format(
                                "DD MMMM YYYY HH:mm"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ paddingTop: 0 }}>
                          <Grid.Column width="5">
                            {" "}
                            <p className="bold">Created</p>{" "}
                          </Grid.Column>
                          <Grid.Column width="11">
                            {state.data.createdAt
                              ? moment(state.data.createdAt).format(
                                "DD MMMM YYYY HH:mm"
                              )
                              : "-"}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: 0 }}>
              <Grid.Column>
                <MainPeople
                  tabs={[
                    {
                      menuItem: "Notes",
                      render: () => (
                        <Notes
                          page='staff'
                          personnelId={this.props.location.state.id}
                          getNote={this.getNotes}
                          listNote={state.note}
                        />
                      )
                    },
                  ]}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    );
  }
}

// export default withRouter(ProfileAircraft)

export default ProfileInstructors = reduxForm({
  // a unique name for the form
  form: "instructorsprofile",
  validate,
  enableReinitialize: true
})(ProfileInstructors);
