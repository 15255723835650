import React, { Component } from "react";
import { Form, Button, Image, Message, Icon } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderFileField from "../../components/renderFileField";

import renderTextAreaField from "../../components/renderTextAreaField";
import Wrap from "../../components/wrap";

const validate = (values) => {
  const errors = {};
  if (!values.type) errors.type = true;
  // if (values.type ==="")
  if (!values.topic) errors.topic = true;

  if (values.type && values.type === "Alert") {
    if (!values.priority) errors.priority = true;
  }

  return errors;
};

class Formnews extends Component {
  state = {
    option_type: [
      { key: "Alert", value: "Alert", text: "Alert" },
      { key: "Notice", value: "News", text: "News" },
    ],
    option_priority: [
      { key: "1", value: 1, text: "1" },
      { key: "2", value: 2, text: "2" },
      { key: "3", value: 3, text: "3" },
    ],
    image: null,
    file: null,
  };
  onDelete = (type) => {
    let { initialValues } = this.props;
    if (type === "image") {
      this.setState({ image: null });
      initialValues.imageCurrentUrl = null;
      this.props.dispatch(change("news", "image", null));
    } else if (type === "file") {
      this.setState({ file: null });
      initialValues.fileCurrentUrl = null;
      this.props.dispatch(change("news", "file", null));
    }
  };
  onDeleteFile = () => {
    let { initialValues } = this.props;
    this.setState({ file: null });
    initialValues.fileCurrentUrl = null;
    this.props.dispatch(change("news", "file", null));
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.initialValues) {
      this.setState({
        image: this.props.initialValues.imageCurrentUrl,
        file: this.props.initialValues.fileCurrentUrl,
      });
    }
  }
  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      type,
      options_roles,
      options_class,
      addNew,
      loading,
      errormsg,
      initialValues,
    } = this.props;
    console.log("initialValues", initialValues);
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        {addNew && (
          <Field
            name="type"
            // multiple={true}
            required={true}
            component={renderSelectField}
            label="Type"
            options={state.option_type}
          />
        )}

        {type === "Alert" && (
          <Wrap>
            <Field
              name="priority"
              component={renderSelectField}
              label="Priority"
              required
              options={state.option_priority}
            />
            <Field
              name="roles"
              component={renderSelectField}
              label="Display For (Role)"
              multiple
              options={options_roles}
            />
            <Field
              name="class"
              component={renderSelectField}
              label="Display For (Student class)"
              multiple
              options={options_class}
            />
          </Wrap>
        )}

        <Field
          name="topic"
          type="text"
          required={true}
          width="16"
          component={renderField}
          label="Topic"
        />
        {state.image && (
          <Icon.Group size="big" style={{ marginLeft: 85 }}>
            <Image
              src={initialValues.imageCurrentUrl + "?time=" + new Date()}
              size="small"
            />
            <Icon
              name="x"
              color="red"
              link
              corner="top right"
              onClick={() => this.onDelete("image")}
            />
          </Icon.Group>
        )}
        <Field
          name="image"
          type="file"
          component={renderFileField}
          label="Image"
        />
        {errormsg && errormsg.image && (
          <Message color="red">
            <p>{errormsg.image[0]}</p>
          </Message>
        )}

        <Field
          name="detail"
          type="text"
          required={true}
          width="16"
          component={renderTextAreaField}
          label="Detail"
        />
        {state.file && initialValues && initialValues.fileCurrentUrl && (
          <Message size="small" style={{ margin: "3px 0px 3px 0px" }}>
            <Message.Content style={{ textAlign: "left" }}>
              <Icon
                name="x"
                color="red"
                link
                onClick={() => this.onDeleteFile()}
              />

              {initialValues.fileCurrentUrl.map((item) => {
                return (
                  <div>
                    <a
                      href={item + "?time=" + new Date()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Attached file
                    </a>
                    <br />
                  </div>
                );
              })}
              
            </Message.Content>
          </Message>
        )}

        <Field
          name="file"
          type="file"
          component={renderFileField}
          multiple={true}
          label="Attached file"
        />
        {errormsg && errormsg.file && (
          <Message color="red">
            <p>{errormsg.file[0]}</p>
          </Message>
        )}
        <Field
          name="link"
          type="text"
          // required={true}
          width="16"
          placeholder="http://"
          component={renderField}
          label="Link"
        />
        <br />

        <Button
          floated="left"
          type="submit"
          compact
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        {/* disabled={pristine || submitting} */}
        <Button
          floated="right"
          type="button"
          compact
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

Formnews = reduxForm({
  // a unique name for the form
  form: "news",
  validate,
  enableReinitialize: true,
})(Formnews);

// Decorate with connect to read form values
const selector = formValueSelector("news"); // <-- same as form name
Formnews = connect((state) => {
  const type = selector(state, "type");
  return {
    type,
  };
})(Formnews);
export default Formnews;
