import axios from "axios";
import * as config from "../../config";
// select
export function getInstructor(search) {
  return axios
    .get(config.urlAPI + "/instructorslist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getReservationTypes(search) {
  return axios
    .get(
      config.urlAPI + "/reservation/reservationtypes" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAirport() {
  return axios
    .get(config.urlAPI + "/airport", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourse(search) {
  return axios
    .get(config.urlAPI + "/course" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getCourseMaster() {
  return axios
    .get(config.urlAPI + "/coursemaster", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getAircraft(search) {
  return axios
    .get(config.urlAPI + "/aircraftlist" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getClass() {
  return axios
    .get(config.urlAPI + "/personnel/studentclass", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function getStudentType() {
  return axios
    .get(config.urlAPI + "/personnel/studenttype", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Flight Plan Report
export function genFlightplan(search) {
  return axios
    .get(config.urlAPI + "/flightplan" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportFlightplan(search) {
  return axios
    .get(config.urlAPI + "/flightplan/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Technical Crew Voyage Report
export function genCrewVoyage(search) {
  return axios
    .get(config.urlAPI + "/voyage" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportCrewVoyage(search) {
  return axios
    .get(config.urlAPI + "/voyage/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//IP Flight Hour Report
export function genIpFlightHour(search) {
  return axios
    .get(config.urlAPI + "/ipflighthour" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportIpFlightHour(search) {
  return axios
    .get(config.urlAPI + "/ipflighthour/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//IP Flight Hour Report
export function genGroundCancel(search) {
  return axios
    .get(config.urlAPI + "/groundcancellation" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportGroundCancel(search) {
  return axios
    .get(config.urlAPI + "/groundcancellation/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//IP Flight Hour Report
export function genGroundCompensation(search) {
  return axios
    .get(config.urlAPI + "/groundCompensation" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportGroundCompensation(search) {
  return axios
    .get(config.urlAPI + "/groundCompensation/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Flight Reservation Report
export function genFlightReservation(search) {
  return axios
    .get(config.urlAPI + "/flightReservation" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportFlightReservation(search) {
  return axios
    .get(config.urlAPI + "/flightReservation/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Ground Reservation Report
export function genGroundReservation(search) {
  return axios
    .get(config.urlAPI + "/groundReservation" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportGroundReservation(search) {
  return axios
    .get(config.urlAPI + "/groundReservation/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Student Database
export function genStudentDatabase(search) {
  return axios
    .get(config.urlAPI + "/studentDatabase" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportStudentDatabase(search) {
  return axios
    .get(config.urlAPI + "/studentDatabase/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Proficiency Check
export function genProficiencyCheck(search) {
  return axios
    .get(config.urlAPI + "/proficiencyCheck" + search, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function exportProficiencyCheck(search) {
  return axios
    .get(config.urlAPI + "/proficiencyCheck/export" + search, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

//Student Data Summary Report
export function genSummaryByRegister(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByRegister" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummaryByGraduate(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByGraduate" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function genSummaryByCompany(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByCompany" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function genSummaryByGender(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByGender" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummaryByGenderAndStatus(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByGenderAndStatus" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummaryByStdType(search) {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByStdType" + search,
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummaryByClass() {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByClass",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummaryByCourseRegister() {
  return axios
    .get(
      config.urlAPI + "/studentDatabase/summaryByCourseRegister ",
      config.headerCallAPI
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function genSummary() {
  return axios
    .get(config.urlAPI + "/studentDatabase/summary ", config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}

export function getPerformance(qString) {
  return axios
    .get(config.urlAPI + "/performance" + qString, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}


export function savePerformance(data) {
  // performanceSim
  // "/performance/export
  return axios
    .post(config.urlAPI + "/performance/save", data, config.headerCallAPI)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
export function exportPerformance(enpoint, data) {
  // performanceSim
  // "/performance/export
  return axios
    .post(config.urlAPI + enpoint, data, {
      responseType: "arraybuffer",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("bac_access_token"),
        Accept: "application/json",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
}
