import React, { Component } from "react";
import { Form, Button } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import validate from "./aircraftValidate";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
import { getMake, getModel, getAirClass, getSimClass } from "./service";

class FromAircraft extends Component {
  state = {
    make_option: [],
    model_option: [],
    category_option: [
      { key: 1, text: "Airplane", value: "airplane" },
      { key: 4, text: "Flight Simulator", value: "sim" }
    ],
    airplaneClass_option: [],
    simClass_option: []
  };

  componentDidMount() {
    let make_option = [],
      model_option = [],
      airplaneClass_option = [],
      simClass_option = [];

    getMake().then(res => {
      if (res && res.status === 200) {
        res.data.map(item =>
          make_option.push({ key: item.id, text: item.name, value: item.id })
        );
        this.setState({ make_option });
      }
    });
    getModel().then(res => {
      if (res && res.status === 200) {
        res.data.map(item =>
          model_option.push({ key: item.id, text: item.name, value: item.id })
        );
        this.setState({ model_option });
      }
    });
    getAirClass().then(res => {
      if (res && res.status === 200) {
        res.data.map(item =>
          airplaneClass_option.push({
            key: item.id,
            text: item.name,
            value: item
          })
        );
        this.setState({ airplaneClass_option });
      }
    });

    getSimClass().then(res => {
      if (res && res.status === 200) {
        res.data.map(item =>
          simClass_option.push({
            key: item.id,
            text: item.name,
            value: item.id
          })
        );
        this.setState({ simClass_option });
      }
    });
  }

  render() {
    const state = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      category,
      airClass,
      loading
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Form.Group unstackable>
          <Field
            name="tailNumber"
            type="text"
            required={true}
            width="12"
            component={renderField}
            label="Registration / Tail"
          />
          <Field
            name="year"
            type="text"
            width="4"
            component={renderField}
            label="Year"
          />
        </Form.Group>
        <Form.Group widths="equal" unstackable>
          <Field
            name="makeId"
            required={true}
            component={renderSelectField}
            width="8"
            search={true}
            placeholder="Please Select"
            clearable={true}
            label="Make"
            options={state.make_option}
          />
          <Field
            name="modelId"
            required={true}
            component={renderSelectField}
            search={true}
            placeholder="Please Select"
            width="8"
            clearable={true}
            label="Model"
            options={state.model_option}
            onChange={this.handleChangeModel}
          />
        </Form.Group>
        <Form.Group unstackable>
          <Field
            name="category"
            required={true}
            component={renderSelectField}
            width="8"
            placeholder="Please Select"
            label="Category"
            options={state.category_option}
            clearable={true}
          />
          {category === "airplane" ? (
            <Field
              name="airClass"
              required={true}
              component={renderSelectField}
              width="8"
              placeholder="Please Select"
              label="Airplane Class"
              options={state.airplaneClass_option}
              clearable={true}
            />
          ) : category === "sim" ? (
            <Field
              name="simClass"
              required={true}
              component={renderSelectField}
              width="8"
              placeholder="Please Select"
              label="Flight Simulator Class"
              options={state.simClass_option}
              clearable={true}
            />
          ) : null}
        </Form.Group>

        {category && category === "sim" ? (
          <Field
            name="numberOfEngines"
            type="text"
            width="4"
            required={true}
            component={renderField}
            label="# of Engines"
          />
        ) : (
          category === "airplane" &&
          airClass &&
          airClass.multi === "true" && (
            <Field
              name="numberOfEngines"
              type="text"
              width="4"
              required={true}
              component={renderField}
              label="# of Engines"
            />
          )
        )}

        {category === "airplane" ? (
          <Wrap>
            <Field
              name="enginesHavePropellers"
              width="11"
              label="Engines have Propellers"
              component={renderRadioField}
            />
            <Field
              name="enginesAreTurbines"
              width="11"
              label="Engines are Turbines"
              component={renderRadioField}
            />
          </Wrap>
        ) : null}

        <Field
          name="trackOilFuel"
          width="11"
          label="Track Oil and Fuel"
          component={renderRadioField}
        />
        {/* <Field
          name="displayMismatches"
          width="11"
          label="Identify Meter Mismatch"
          component={renderRadioField}
        /> */}

        <br />
        <Button
          floated="left"
          type="submit"
          content="Save"
          color="green"
          compact
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          type="button"
          content="Cancel"
          color="green"
          basic
          compact
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

FromAircraft = reduxForm({
  // a unique name for the form
  form: "aircraft",
  validate,
  enableReinitialize: true
})(FromAircraft);

const selector = formValueSelector("aircraft");
FromAircraft = connect(state => {
  const { category, airClass } = selector(state, "category", "airClass");
  return {
    category,
    airClass
  };
})(FromAircraft);

export default FromAircraft;
