import React, { Component } from "react";
import "react-day-picker/lib/style.css";
import { Popup, Icon, Grid } from "semantic-ui-react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import moment from "moment";
import * as config from "../config";

class CalendarSmall extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDay: props.day || new Date()
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.day !== this.state.selectedDay) {
      this.setState({ selectedDay: nextProps.day });
    }
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? new Date() : day
    });
    this.props.onChange(selected ? new Date() : day);
  }
  prevDay = () => {
    // || this.props.view === "agenda"
    if (this.props.view === "month" ) {
      this.setState({
        selectedDay: moment(this.state.selectedDay).add(-1, "months")._d
      });
      this.props.onChange(moment(this.state.selectedDay).add(-1, "months")._d);
    } else {
      this.setState({
        selectedDay: moment(this.state.selectedDay).add(-1, "days")._d
      });
      this.props.onChange(moment(this.state.selectedDay).add(-1, "days")._d);
    }
  };
  nextDay = () => {
    // || this.props.view === "agenda"
    if (this.props.view === "month" ) {
      this.setState({
        selectedDay: moment(this.state.selectedDay).add(1, "month")._d
      });
      this.props.onChange(moment(this.state.selectedDay).add(1, "month")._d);
    } else {
      this.setState({
        selectedDay: moment(this.state.selectedDay).add(1, "days")._d
      });
      this.props.onChange(moment(this.state.selectedDay).add(1, "days")._d);
    }
  };
  render() {
    const state = this.state;
    return (
      <Grid columns={3}>
        <Grid.Row style={{ color: config.colorTheme, textAlign: "center" }}>
          <Grid.Column
            width="3"
            style={{ cursor: "pointer", paddingRight: "0px" }}
          >
            <Icon name="angle left" size="large" onClick={this.prevDay} />
          </Grid.Column>
          <Grid.Column width="11" verticalAlign="middle" textAlign="center">
          
            {this.props.view === "month"  ? (
              <b>{moment(state.selectedDay).format("MMMM, YYYY")} </b>
            ) : (
              <b>{moment(state.selectedDay).format("ddd, MMMM D, YYYY")} </b>
            )}

            {!this.props.disableDateClick && (
              <Popup
                basic
                flowing
                hoverable
                trigger={
                  <Icon
                    name="calendar alternate outline"
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                  />
                }
                on="click"
                style={{ padding: "0" }}
              >
                <DayPicker
                  selectedDays={this.state.selectedDay}
                  onDayClick={this.handleDayClick}
                />
              </Popup>
            )}
          </Grid.Column>
          <Grid.Column
            width="2"
            style={{ cursor: "pointer", paddingLeft: "0px" }}
          >
            <Icon name="angle right" size="large" onClick={this.nextDay} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default CalendarSmall;
