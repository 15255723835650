import React from "react";
import { Modal, Header } from "semantic-ui-react";
import * as config from "../config";

const ModalForm = props => {
  // console.log("in modal form", props.disableIcon);
  return (
    <Modal
      open={props.open}
      centered={props.centered || false}
      onClose={props.close}
      dimmer="blurring"
      size={props.size || "tiny"}
      closeIcon={props.disableIcon || true}
      closeOnDimmerClick={false}
    >
      {(props.icon || props.content) &&
        <Header
          icon={props.icon}
          content={props.content}
          style={
            props.style
              ? { ...props.style, padding: "10px" }
              : { padding: "10px" }
          }
        />
      }

      <Modal.Content scrolling>{props.children}</Modal.Content>
      {/* <Modal.Actions>
                <Button color='red' onClick={props.close}><Icon name='remove' /> No </Button>
                <Button color='green' onClick={props.save}> <Icon name='checkmark' /> Yes </Button>
            </Modal.Actions> */}
    </Modal>
  );
};
export default ModalForm;
