import React, { Component } from "react";
import { Form, Button, Table, Loader, Segment } from "semantic-ui-react";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import validate from "../../validate";
import renderSelectField from "../../components/renderSelectField";
import Wrap from "../../components/wrap";

class FormcourseModule extends Component {
  state = {
    option_flightSubject: []
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.option_flightSubject !== this.state.option_flightSubject) {
      if (nextProps.option_flightSubject) {
        this.setState({ option_flightSubject: nextProps.option_flightSubject });
      }
    }
  }

  renderMembers = ({ fields }) => {
    return (
      <Wrap>
        {fields.map((member, index) => (
          <Table.Row key={index}>
            <Table.Cell textAlign="center" className="bold">
              {this.props.initialValues.flightSubjects[index].name}
            </Table.Cell>
            <Table.Cell>
              <Field
                name={`${member}.subject`}
                component={renderSelectField}
                placeholder="Flight Subject"
                options={this.state.option_flightSubject}
                multiple
                search
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Wrap>
    );
  };
  render() {
    const { handleSubmit, pristine, submitting ,fetching,loading} = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Table compact celled striped style={{ marginBottom: "30px" }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                className="header-table"
                width={4}
                textAlign="center"
              >
                Flight Group
              </Table.HeaderCell>
              <Table.HeaderCell className="header-table" width={12}>
                Flight Subjects
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {fetching ? (
              <Table.Row>
                <Table.Cell textAlign="left" colSpan="2">
                  Loading..
                </Table.Cell>
              </Table.Row>
            ) :
            <FieldArray name="flightSubjects" component={this.renderMembers} />}
          </Table.Body>
        </Table>
        <br />
        <Button
          floated="left"
          compact
          type="submit"
          content="Save"
          color="green"
          loading={loading}
          disabled={pristine || submitting}
        />
        <Button
          floated="right"
          compact
          type="button"
          content="Cancel"
          color="green"
          basic
          onClick={this.props.onCancel}
        />
      </Form>
    );
  }
}

FormcourseModule = reduxForm({
  // a unique name for the form
  form: "coursemodulegroup",
  validate,
  enableReinitialize: true
})(FormcourseModule);

// Decorate with connect to read form values
const selector = formValueSelector("coursemodulegroup"); // <-- same as form name
FormcourseModule = connect(state => {
  const flightSubjects = selector(state, "flightSubjects");

  return {
    flightSubjects
  };
})(FormcourseModule);
export default FormcourseModule;
