import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, Grid, Table, Statistic } from "semantic-ui-react";
import TableFixedColumns from "../../components/tableFixedColumns";
import * as config from "../../config";
import HeaderWithMenu from "../../components/headerwithmenu";
import MainButton from "../../components/mainbutton";
import moment from "moment";
import Wrap from "../../components/wrap";

class Hours extends Component {
  state = {
    logbook: this.props.logbook,
    logbookColumn: [],
    logbookFixColumn: [
      {
        Header: "Flight No.",
        accessor: "firstName",
        fixed: "left",
        sortable: false,
        width: 150,
        Cell: cellInfo => (
          <span className="bold">
            {cellInfo.original.subject.code}
          </span>
        )
      },
    ],
  };
  setColunmlogbook = () => {
    const { logbookFixColumn, logbook } = this.state;
    let addlogbook = []
    let listlogbook = []

    logbook.map(lb => {
      if (lb.group) {
        let grbb = []
        lb.group.map(grb =>
          grbb.push({
            Header: grb.name,
          })
        )
        listlogbook.push({
          Header: lb.name,
          accessor: "",
          fixed: "left",
          sortable: false,
          columns: [
            ...grbb
          ]
        })

      } else {
        listlogbook.push({
          Header: lb.name,
          accessor: "",
          fixed: "left",
          sortable: false,
          Cell: cellInfo => (
            <span className="bold">
              x
          </span>
          )
        })
      }

    }

    )
    console.log('listlogbook', listlogbook)
    addlogbook.push({
      Header: "",
      accessor: "",
      sortable: false,
      fixed: "left",
      columns: [
        ...logbookFixColumn,
        ...listlogbook
        // {
        //   Header: "Flight Total",
        //   accessor: "flightTotal",
        //   sortable: false,
        //   fixed: "left",
        //   headerClassName: "my-flight-column-header-group",
        //   style: {
        //     textAlign: "center",
        //     fontWeight: "bold",
        //     color: "slateblue"
        //   },
        //   Cell: cellInfo => "0:00:00"
        //   // this.getEvent(
        //   //   cellInfo.original.id,
        //   //   moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD")
        //   // )
        // },
      ]
    })
    this.setState({
      logbookColumn: addlogbook,
    });
  }
  componentDidMount() {
    this.setColunmlogbook()
  }

  render() {
    const state = this.state;
    const props = this.props;
    console.log(props);
    return (
      <div>
        {/* <Grid columns="3"> */}
        {
          props.page == 'student' ?
            // <TableFixedColumns
            //   data={props.reservation}
            //   columns={state.logbookColumn}
            //   height={500}
            // />
            <p>student</p>
            : <p>instructor</p>
        }
        {/* </Grid> */}
      </div >
    );
  }
}

export default withRouter(Hours);
