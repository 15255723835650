import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Card, Icon, Table } from "semantic-ui-react";
import ModalForm from "../../components/modal";
import Confirmmodal from "../../components/confirmmodal";

import FormFlightrules from "./flightrulesForm";
import * as config from "../../config";
import {
  getFlightRules,
  postFlightRules,
  updateFlightRules,
  deleteFlightRules
} from "./service";

class Settings extends Component {
  state = {
    menu: [
      {
        id: 1,
        icon: "clipboard check",
        description: "Flight Time - IP",
        type: "IP"
      },
      {
        id: 2,
        icon: "clipboard check",
        description: "Flight Time - SP",
        type: "SP"
      }
    ],
    data: []
  };

  getData = type => {
    this.setState({ type, open: true });
    this.setState({
      content: `Flight Time Rule - ${type}`
    });
    this.getFlightRules(type);
  };
  getFlightRules = type => {
    this.setState({ loading: true });
    getFlightRules("?type=" + type).then(res => {
      if (res && res.status === 200)
        this.setState({
          // open: true,
          data: res.data,
          loading: false,
          loadingUp: false
        });
    });
  };
  onClickDelete = value => {
    this.setState({
      openConfirm: true,
      currentData: value
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, data: [], currentData: null });
  };
  saveData = value => {
    let { type } = this.state;
    this.setState({ loadingUp: true });
    postFlightRules({
      days: value.days,
      type: type,
      hours: value.hours ? value.hours : "0:00"
    }).then(res => {
      if (res && res.status === 201) {
        this.getFlightRules(type);
        this.setState({ currentData: null, loadingUp: false });
      }
    });
  };
  updateData = value => {
    let { type } = this.state;
    this.setState({ loadingUp: true });

    updateFlightRules({
      id: value.id,
      days: value.days,
      type: type,
      hours: value.hours ? value.hours : "0:00"
    }).then(res => {
      if (res && res.status === 200) {
        this.getFlightRules(type);
        this.setState({ currentData: null, loadingUp: false });
      }
    });
  };

  deleteData = () => {
    let { type, currentData } = this.state;
    this.setState({ loading: true });
    if (currentData && currentData.id)
      deleteFlightRules(currentData.id).then(res => {
        if (res && res.status === 200) {
          this.getFlightRules(type);
          this.setState({ openConfirm: false });
        }
        this.setState({ loading: false });
      });
  };
  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleChangeUpdate = (e, { value }) => this.setState({ editName: value });
  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <Header as="h2" style={{ color: config.colorTheme }}>
            Flight Time Rule
          </Header>
          <Card.Group itemsPerRow="5" doubling style={{ padding: "20px" }}>
            {state.menu.map(item => (
              <Card
                raised
                key={item.id}
                style={{ background: "#ededed" }}
                onClick={() => this.getData(item.type)}
              >
                <Card.Content style={{ textAlign: "center" }}>
                  <Header as="h5" icon style={{ marginBottom: "0px" }}>
                    <Icon
                      name={item.icon}
                      style={{ color: config.colorTheme }}
                    />
                    {item.description}
                  </Header>
                </Card.Content>
              </Card>
            ))}
          </Card.Group>
          <br />
          <br />
        </Segment>

        {/* make */}
        <ModalForm
          open={state.open}
          icon="plane"
          content={state.content}
          close={this.onClickCancel}
        >
          <FormFlightrules
            loading={state.loadingUp}
            initialValues={state.currentData ? state.currentData : null}
            onSubmit={state.currentData ? this.updateData : this.saveData}
            onCancel={() => this.setState({ currentData: null })}
          />
          <Table compact celled striped style={{ marginBottom: "30px" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="header-table" width={1} />
                <Table.HeaderCell className="header-table" width={4}>
                  Days
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  textAlign="center"
                  width={6}
                >
                  Flight (Hours)
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="header-table"
                  textAlign="center"
                  width={3}
                />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.loading ? (
                <Table.Row>
                  <Table.Cell textAlign="right"> </Table.Cell>
                  <Table.Cell textAlign="left" colSpan="2">
                    Loading..
                  </Table.Cell>
                </Table.Row>
              ) : (
                state.data.map((item, num) => {
                  return (
                    <Table.Row>
                      <Table.Cell textAlign="right"> {num + 1} </Table.Cell>
                      <Table.Cell>
                        <p
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                          onClick={() => this.setState({ currentData: item })}
                        >
                          {item.days}
                        </p>
                      </Table.Cell>
                      <Table.Cell textAlign="center">{item.hours}</Table.Cell>
                      <Table.Cell textAlign="center">
                        <Icon
                          link
                          name="edit"
                          onClick={() => this.setState({ currentData: item })}
                        />
                        &nbsp; &nbsp;
                        <Icon
                          link
                          color="red"
                          name="trash alternate"
                          onClick={() => this.onClickDelete(item)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              )}
            </Table.Body>
          </Table>
        </ModalForm>
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Flight Time Rule"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
      </div>
    );
  }
}

export default withRouter(Settings);
