import React, { Component } from "react";
import { Form, Button, Grid, Icon } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import renderField from "../../components/renderField";
import renderTimeField from "../../components/renderTimeField";
import MainButton from "../../components/mainbutton";
const validate = values => {
  const errors = {};
  if (!values.days) errors.days = true;
  return errors;
};

class Formflightrulesmain extends Component {
  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      initialValues,
      loading
    } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.props.onSubmit)}>
        <Grid>
          <Grid.Row style={{ padding: "0px" }}>
            <Grid.Column width="6">
              <Field
                name="days"
                required={true}
                width="16"
                label="Days"
                type="number"
                component={renderField}
              />
            </Grid.Column>
            <Grid.Column width="6" style={{ padding: "0px" }}>
              <Field
                name="hours"
                width="8"
                label="Flight (Hours)"
                component={renderTimeField}
              />
            </Grid.Column>
            <Grid.Column
              width="4"
              verticalAlign="middle"
              textAlign="right"
              style={{ paddingLeft: "0px" }}
            >
              {initialValues ? (
                <div>
                  <Button
                    animated="vertical"
                    size="mini"
                    color="green"
                    loading={loading}
                    type="submit"
                  >
                    <Button.Content hidden>Update</Button.Content>
                    <Button.Content visible>
                      <Icon name="check" />
                    </Button.Content>
                  </Button>
                  <Button
                    size="mini"
                    animated="vertical"
                    onClick={this.props.onCancel}
                  >
                    <Button.Content hidden>Cancel</Button.Content>
                    <Button.Content visible>
                      <Icon name="x" />
                    </Button.Content>
                  </Button>
                </div>
              ) : (
                <MainButton
                  size="small"
                  floated="right"
                  // width={3}
                  text="Add New"
                  type="submit"
                  loading={loading}
                  disabled={pristine || submitting}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {/* 
        <br />
        <Button
          floated="left"
          type="submit"
          content="Save"
          compact
          color="green"
          disabled={pristine || submitting}
        /> */}
      </Form>
    );
  }
}

export default Formflightrulesmain = reduxForm({
  // a unique name for the form
  form: "flightrules",
  validate,
  enableReinitialize: true
})(Formflightrulesmain);
