import axios from "axios";
import * as config from "../../config";

export function getStudentListByRegis(search) {
  return axios
    // .get(config.urlAPI + "/studentslist" + search, config.headerCallAPI)
    .get(config.urlAPI + "/students/regis" + search, config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function checkRegis(data) {
  return axios
    .post(config.urlAPI + "/registrationCourse", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postRegis(data) {
  return axios
    .post(config.urlAPI + "/registrationCourse/confirm", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function checkAddRegis(data) {
  return axios
    .post(config.urlAPI + "/registrationadditionalCourse", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postAddRegis(data) {
  return axios
    .post(config.urlAPI + "/registrationadditionalCourse/confirm", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postUnRegis(data) {
  return axios
    .post(config.urlAPI + "/registrationCourse/unregis", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postGraduate(data) {
  return axios
    .post(config.urlAPI + "/registrationCourse/graduated", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}

export function postTerminate(data) {
  return axios
    .post(config.urlAPI + "/registrationCourse/terminated", data , config.headerCallAPI)
    .then(res => {
      return res;
    })
    .catch(err => {
      return err.response;
    });
}
