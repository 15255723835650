import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Dropdown,
  Form,
  Icon,
  Segment,
  Menu,
  Button,
  Popup,
  Grid,
} from "semantic-ui-react";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import DayPicker from "../../components/dayPicker";
import TableFixedColumns from "../../components/tableFixedColumns";
import {
  getInstructorList,
  getTrainingCourse,
  getScheduletrackIP,
  getScheduletrackSP,
  getSubjecttrack,
  getStudentByCourse,
  getCourse,
  getSubjectByCourse,
  getFlightGroup,
  getCourseMaster,
  getFlighthrstrackSP,
  getFlighthrstrackIP,
} from "./service";

class ScheduleTrack extends Component {
  state = {
    loading: true,
    seletedDate: new Date(),
    daysInMonth: [],
    instructorList: [],
    instructorColumn: [],
    instructorFixColumn: [
      {
        Header: "First Name",
        accessor: "firstName",
        fixed: "left",
        sortable: false,
        width: 150,
        Cell: (cellInfo) => (
          <span className="bold">
            {cellInfo.original.firstName} {cellInfo.original.lastName}
          </span>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
        sortable: false,
        fixed: "left",
        style: { textAlign: "center" },
      },
      {
        Header: "Categories",
        accessor: "categories",
        sortable: false,
        fixed: "left",
        style: { textAlign: "center" },
      },
    ],
    studentList: [],
    studentColumn: [],
    studentFixColumn: [
      {
        Header: "First Name",
        accessor: "firstName",
        fixed: "left",
        sortable: false,
        width: 150,
        Cell: (cellInfo) => (
          <span className="bold">
            {cellInfo.original.studentId} {cellInfo.original.firstName}{" "}
            {cellInfo.original.lastName}
          </span>
        ),
      },

      {
        Header: "Class",
        accessor: "class",
        sortable: false,
        fixed: "left",
        maxWidth: 100,
        style: { textAlign: "center" },

        Cell: (cellInfo) => (
          <span>
            {cellInfo.original.class ? cellInfo.original.class.name : "-"}
          </span>
        ),
      },
    ],
    view: "Schedule",
    activeItem: "Instructors",
    data: [],
    options_course: [],
    options_categories: [
      {
        key: "o",
        text: "All Category",
        value: "All Category",
      },
      {
        key: "a",
        text: "CAT A",
        value: "CAT A",
      },
      {
        key: "b",
        text: "CAT B",
        value: "CAT B",
      },
      {
        key: "c",
        text: "CAT C",
        value: "CAT C",
      },
      {
        key: "d",
        text: "CAT D",
        value: "CAT D",
      },
    ],
    course: 1,
    search: {
      course: null,
      categories: "CAT A",
    },
  };

  componentDidMount() {
    this.getInstructor();
    // this.getAllEventInSch(this.state.seletedDate);
    this.getOptionCourse();
  }

  getAllEventInSch = (date) => {
    //แยกไป get ที่นี่
    let { view, activeItem } = this.state;
    if (activeItem === "Instructors" && view === "Schedule") {
      this.getScheduleIP(date);
    } else if (activeItem === "Instructors" && view === "Course") {
      this.getTrainingCourse();
      // this.setCourseTrack(date);
    } else if (activeItem === "Instructors" && view === "FlightHrs") {
      this.getFlighthrstrackIP(date);
    } else if (activeItem === "Students" && view === "Schedule") {
      this.getScheduleSP(date);
    } else if (activeItem === "Students" && view === "Subject") {
      this.getSubjecttrackSP();
    } else if (activeItem === "Students" && view === "FlightHrs") {
      this.getFlighthrstrackSP(date);
    }
  };
  getInstructor = () => {
    let { search } = this.state;
    let qString;
    if (search.categories === "All Category")
      qString = "?status=Active&_sort=firstName&_order=asc";
    else
      qString =
        "?status=Active&_sort=firstName&_order=asc&categories[]=" +
        search.categories;
    getInstructorList(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ instructorList: res.data });
      }
      this.getAllEventInSch(this.state.seletedDate);
    });
  };

  getOptionCourse = () => {
    let options_course = [];
    getCourse().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          options_course.push({
            key: item.id,
            id: item.id,
            title: `${item.name} (${item.desc})`,
            text: `${item.name} (${item.desc})`,
            value: {
              name: `${item.name} (${item.desc})`,
              id: item.id,
            },
          });
        });

        this.setState({
          options_course,
        });

        if (options_course[0] && options_course[0].value.id) {
          let search = this.state.search;
          search["course"] = options_course[0].value.name;
          this.setState({ course: options_course[0].value.id });
          this.getStudentByCourse(options_course[0].value.id);
        }
      }
    });
  };

  getStudentByCourse = (course) => {
    getStudentByCourse(course).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          studentList: res.data,
        });
        this.getAllEventInSch(this.state.seletedDate);
      }
    });
  };

  getFlighthrstrackIP = (date) => {
    this.setState({ loading: true });
    let qString = "?start=" + moment(date).format("YYYY-MM");
    this.setState({ instructorEvent: [] });
    this.state.instructorList.map(
      (item) => (qString = qString + "&instructor[]=" + item.id)
    );
    getFlighthrstrackIP(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          loading: false,
          instructorEvent: res.data,
        });
      }
      this.setDaysInMonthForFlightHrs(date);
    });
  };
  getFlighthrstrackSP = (date) => {
    this.setState({ loading: true });
    let qString = "?start=" + moment(date).format("YYYY-MM");
    this.setState({ instructorEvent: [] });
    this.state.studentList.map(
      (item) => (qString = qString + "&student[]=" + item.id)
    );
    getFlighthrstrackSP(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          loading: false,
          studentEvent: res.data,
        });
      }
      this.setDaysInMonthForFlightHrs(date);
    });
  };
  getScheduleIP = (date) => {
    this.setState({ loading: true });
    let qString = "?start=" + moment(date).format("YYYY-MM");
    this.setState({ instructorEvent: [] });
    this.state.instructorList.map(
      (item) => (qString = qString + "&instructor[]=" + item.id)
    );
    getScheduletrackIP(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          loading: false,
          instructorEvent: res.data,
        });
      }
      this.setDaysInMonth(date);
    });
  };

  getScheduleSP = (date) => {
    this.setState({ loading: true });
    let qString = "?start=" + moment(date).format("YYYY-MM");

    this.setState({ studentEvent: [] });
    this.state.studentList.map(
      (item) => (qString = qString + "&student[]=" + item.id)
    );
    getScheduletrackSP(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          loading: false,
          studentEvent: res.data,
        });
      }
      this.setDaysInMonth(date);
    });
  };

  getSubjecttrackSP = () => {
    this.setState({ loading: true });
    let qString = "?";
    this.state.studentList.map(
      (item) => (qString = qString + "student[]=" + item.id + "&")
    );
    getSubjecttrack(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({
          loading: false,
          subjectTrackData: res.data,
        });
      }
      this.setSubjectByCourse(this.state.course);
    });
  };

  getTrainingCourse = () => {
    this.setState({ loading: true });
    let qString = "?";
    this.state.instructorList.map(
      (item) => (qString = qString + "instructor[]=" + item.id + "&")
    );
    getTrainingCourse(qString).then((res) => {
      if (res.status === 200) {
        this.setState({ courseTrackData: res.data });
      }
      this.setState({ loading: false });
      this.setCourseTrack();
    });
  };

  showInstructorEvent = (id, date) => {
    const { instructorEvent } = this.state;
    const found =
      instructorEvent &&
      instructorEvent.find(
        (instructorEvent) =>
          instructorEvent.id === id && instructorEvent.date === date
      );
    if (found) {
      var text = (
        <span className="bold" style={{ fontSize: "85%" }}>
          <p>{found.event}</p>
        </span>
      );
      return <Popup content={text} trigger={text} />;
    }
    return <div>&nbsp; </div>;
  };

  showStudentEvent = (id, date) => {
    const { studentEvent } = this.state;
    const found =
      studentEvent &&
      studentEvent.find(
        (studentEvent) => studentEvent.id === id && studentEvent.date === date
      );
    if (found) {
      var text = (
        <span className="bold" style={{ fontSize: "85%" }}>
          <p>{found.event}</p>
        </span>
      );
      return <Popup content={text} trigger={text} />;
    }
    return <div>&nbsp; </div>;
  };

  showStudenSubjectTrack = (id, subjectId) => {
    const { subjectTrackData } = this.state;
    const found =
      subjectTrackData &&
      subjectTrackData.find(
        (data) => data.id === id && data.subject.indexOf(subjectId) >= 0
      );
    if (found) {
      return <Icon style={{ color: "#CDDC39" }} size="large" name="check" />;
    }
    return <div>&nbsp; </div>;
  };

  showHoursSP = (id, date, type) => {
    const { studentEvent } = this.state;
    const found =
      studentEvent &&
      studentEvent.find(
        (studentEvent) => studentEvent.id === id && studentEvent.date === date
      );

    return (
      <span className="bold">
        <p>{found ? found[type] : ""}</p>
      </span>
    );
  };
  showHoursIP = (id, date, type) => {
    const { instructorEvent } = this.state;
    const found =
      instructorEvent &&
      instructorEvent.find(
        (instructorEvent) =>
          instructorEvent.id === id && instructorEvent.date === date
      );
    return (
      <span className="bold">
        <p>{found ? found[type] : ""}</p>
      </span>
    );
  };
  showStudentTotallHour = (id, type) => {
    const { studentEvent } = this.state;
    const found =
      studentEvent &&
      studentEvent.filter((studentEvent) => studentEvent.id === id);
    if (found && found.length > 0) {
      const sum = found.reduce(
        (acc, time) => acc.add(moment.duration(time[type])),
        moment.duration()
      );
      return [
        this.pad2(Math.floor(sum.asHours())),
        this.pad2(sum.minutes()),
      ].join(":");
    }
  };
  showInstructorTotallHour = (id, type) => {
    const { instructorEvent } = this.state;
    const found =
      instructorEvent &&
      instructorEvent.filter((instructorEvent) => instructorEvent.id === id);
    if (found && found.length > 0) {
      const sum = found.reduce(
        (acc, time) => acc.add(moment.duration(time[type])),
        moment.duration()
      );
      return [
        this.pad2(Math.floor(sum.asHours())),
        this.pad2(sum.minutes()),
      ].join(":");
    }
  };
  showTraningCourse(id, type) {
    const { courseTrackData } = this.state;
    const found =
      courseTrackData && courseTrackData.find((item) => item.id === id);

    if (found && found[type])
      return <Icon style={{ color: "#CDDC39" }} size="large" name="check" />;
  }

  showTraningCourseById(id, type, sub_id) {
    const { courseTrackData } = this.state;
    const found =
      courseTrackData && courseTrackData.find((item) => item.id === id);

    if (found && found[type]) {
      const found_sub =
        found[type] && found[type].find((item) => item.id === sub_id);
      if (found_sub)
        return <Icon style={{ color: "#CDDC39" }} size="large" name="check" />;
    }
  }
  showTraningCourseByKey(id, type, key) {
    const { courseTrackData } = this.state;
    const found =
      courseTrackData && courseTrackData.find((item) => item.id === id);
    if (found && found[type]) {
      if (found[type][key])
        return <Icon style={{ color: "#CDDC39" }} size="large" name="check" />;
    }
  }

  setDaysInMonth = (date) => {
    // สร้างช่องๆตามจำนวนวันในแต่ละเดือน
    let month = moment(date).format("YYYY-MM");
    let number = moment(date).daysInMonth();
    let daysInMonth = [];
    let instructorC = [];
    let studentC = [];
    const { studentFixColumn, instructorFixColumn } = this.state;

    for (let index = 1; index <= number; index++) {
      instructorC.push({
        Header: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        accessor: "event",
        sortable: false,
        Cell: (cellInfo) =>
          this.showInstructorEvent(
            cellInfo.original.id,
            moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD")
          ),
      });
      studentC.push({
        Header: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        accessor: "event",
        sortable: false,
        Cell: (cellInfo) =>
          this.showStudentEvent(
            cellInfo.original.id,
            moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD")
          ),
      });
      daysInMonth.push({
        day: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        date: moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
        isWeekend:
          moment(`${month}-${this.pad2(index)}`).weekday() === 6 ||
          moment(`${month}-${this.pad2(index)}`).weekday() === 0
            ? true
            : false,
      });
    }
    this.setState({
      daysInMonth,
      instructorColumn: instructorFixColumn.concat(instructorC),
      studentColumn: studentFixColumn.concat(studentC),
    });
  };

  setDaysInMonthForFlightHrs = (date) => {
    let month = moment(date).format("YYYY-MM");
    let number = moment(date).daysInMonth();
    let daysInMonth = [];
    let instructorC = [],
      studentC = [];
    const { instructorFixColumn, studentFixColumn } = this.state;

    instructorC.push({
      Header: "",
      accessor: "",
      sortable: false,
      fixed: "left",
      columns: [
        ...instructorFixColumn,
        {
          Header: "Flight Total",
          accessor: "flightTotal",
          sortable: false,
          fixed: "left",
          headerClassName: "my-flight-column-header-group",
          style: {
            textAlign: "center",
            fontWeight: "bold",
          },
          Cell: (cellInfo) =>
            this.showInstructorTotallHour(cellInfo.original.id, "airplane"),
        },
        {
          Header: "Sim Total",
          accessor: "simTotal",
          sortable: false,
          fixed: "left",
          headerClassName: "my-sim-column-header-group",
          style: { textAlign: "center", fontWeight: "bold" },
          Cell: (cellInfo) =>
            this.showInstructorTotallHour(cellInfo.original.id, "sim"),
        },
      ],
    });
    studentC.push({
      Header: "",
      accessor: "",
      sortable: false,
      fixed: "left",

      columns: [
        ...studentFixColumn,
        {
          Header: "Flight Total",
          accessor: "flightTotal",
          sortable: false,
          fixed: "left",
          headerClassName: "my-flight-column-header-group",
          style: {
            textAlign: "center",
            fontWeight: "bold",
          },
          Cell: (cellInfo) =>
            this.showStudentTotallHour(cellInfo.original.id, "airplane"),
        },
        {
          Header: "Sim Total",
          accessor: "simTotal",
          sortable: false,
          fixed: "left",
          headerClassName: "my-sim-column-header-group",
          style: { textAlign: "center", fontWeight: "bold" },
          Cell: (cellInfo) =>
            this.showStudentTotallHour(cellInfo.original.id, "sim"),
        },
      ],
    });
    for (let index = 1; index <= number; index++) {
      instructorC.push({
        Header: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        accessor: "event",
        sortable: false,
        style: { textAlign: "center", fontWeight: "bold" },
        columns: [
          {
            Header: "Flight ",
            accessor: "airplane",
            sortable: false,
            headerClassName: "my-flight-column-header-group",
            style: {
              textAlign: "center",
              fontWeight: "bold",
              color: "#00a950",
            },
            Cell: (cellInfo) =>
              this.showHoursIP(
                cellInfo.original.id,
                moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
                "airplane"
              ),
          },
          {
            Header: "Sim",
            accessor: "sim",
            headerClassName: "my-sim-column-header-group",
            style: { textAlign: "center", fontWeight: "bold", color: "blue" },
            Cell: (cellInfo) =>
              this.showHoursIP(
                cellInfo.original.id,
                moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
                "sim"
              ),
          },
        ],
      });
      studentC.push({
        Header: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        accessor: "event",
        sortable: false,
        style: { textAlign: "center", fontWeight: "bold" },
        columns: [
          {
            Header: "Flight ",
            accessor: "airplane",
            sortable: false,
            headerClassName: "my-flight-column-header-group",
            style: {
              textAlign: "center",
              fontWeight: "bold",
              color: "#00a950",
            },
            Cell: (cellInfo) =>
              this.showHoursSP(
                cellInfo.original.id,
                moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
                "airplane"
              ),
          },
          {
            Header: "Sim",
            accessor: "sim",
            headerClassName: "my-sim-column-header-group",
            style: { textAlign: "center", fontWeight: "bold", color: "blue" },
            Cell: (cellInfo) =>
              this.showHoursSP(
                cellInfo.original.id,
                moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
                "sim"
              ),
          },
        ],
      });
      daysInMonth.push({
        day: moment(`${month}-${this.pad2(index)}`).format("ddd/DD"),
        date: moment(`${month}-${this.pad2(index)}`).format("YYYY-MM-DD"),
        isWeekend:
          moment(`${month}-${this.pad2(index)}`).weekday() === 6 ||
          moment(`${month}-${this.pad2(index)}`).weekday() === 0
            ? true
            : false,
      });
    }
    this.setState({
      daysInMonth,
      instructorColumn: instructorC,
      // instructorColumn: instructorFixColumn.concat(instructorC),
      // studentColumn: studentFixColumn.concat(studentC)
      studentColumn: studentC,
    });
  };

  setSubjectByCourse = (course) => {
    this.setState({ loading: true });
    let studentC = [];
    const { studentFixColumn } = this.state;
    studentC.push({
      Header: "",
      accessor: "",
      sortable: false,
      fixed: "left",
      columns: studentFixColumn,
    });
    getSubjectByCourse(course).then((res) => {
      if (res && res.status === 200) {
        res.data.module.map((mod) => {
          let tmp = [];
          mod.flightSubjects.map((subject) => {
            tmp.push({
              Header: `${subject.code}`,
              headerClassName: "my-course-column-header-group",
              accessor: "event",
              sortable: false,
              width: 30,
              style: { textAlign: "center" },
              Cell: (cellInfo) =>
                this.showStudenSubjectTrack(cellInfo.original.id, subject.id),
            });
          });
          studentC.push({
            Header: `${mod.name} - Flight Subjects`,
            accessor: "",
            sortable: false,
            columns: tmp,
          });
          tmp = []; // clear
          mod.groundSubjects.map((subject) => {
            tmp.push({
              Header: `${subject.name}`,
              headerClassName: "my-check-column-header-group",
              accessor: "event",
              sortable: false,
              width: 30,
              style: { textAlign: "center" },
              Cell: (cellInfo) =>
                this.showStudenSubjectTrack(cellInfo.original.id, subject.id),
            });
          });
          studentC.push({
            Header: `${mod.name} - Ground Subjects`,
            accessor: "",
            sortable: false,
            columns: tmp,
          });
        });
      }
      this.setState({
        studentColumn: studentC,
        loading: false,
      });
    });
  };

  async setCourseTrack() {
    this.setState({ loading: true });
    let instructorC = [],
      tmp = [];
    const { instructorFixColumn } = this.state;
    instructorC.push({
      Header: "",
      accessor: "",
      sortable: false,
      fixed: "left",
      columns: instructorFixColumn,
    });
    await getCourseMaster().then((res) => {
      tmp = [];
      if (res && res.status === 200) {
        res.data.map((group) => {
          tmp.push({
            Header: group.name,
            headerClassName: "my-course-column-header-group",
            accessor: "event",
            sortable: false,
            width: 30,
            style: { textAlign: "center" },
            Cell: (cellInfo) =>
              this.showTraningCourseById(
                cellInfo.original.id,
                "groundCourse",
                group.id
              ),
          });
        });
      }
      instructorC.push({
        Header: "Ground Subjects",
        accessor: "",
        sortable: false,
        columns: tmp,
      });
    });
    await getFlightGroup().then((res) => {
      tmp = [];
      if (res && res.status === 200) {
        res.data.map((group) => {
          tmp.push({
            Header: group.name,
            headerClassName: "my-check-column-header-group",
            accessor: "event",
            sortable: false,
            width: 30,
            style: { textAlign: "center" },
            Cell: (cellInfo) =>
              this.showTraningCourseById(
                cellInfo.original.id,
                "flightGroup",
                group.id
              ),
          });
        });
      }
      instructorC.push({
        Header: "Flight Subjects",
        accessor: "",
        sortable: false,
        columns: tmp,
      });
    });
    let checkGroup = ["CPL", "PPL", "IR", "IP", "ME"];
    tmp = [];
    checkGroup.map((group) => {
      tmp.push({
        Header: group,
        headerClassName: "my-course-column-header-group",
        accessor: "event",
        sortable: false,
        width: 30,
        style: { textAlign: "center" },
        Cell: (cellInfo) =>
          this.showTraningCourseByKey(cellInfo.original.id, "bacCheck", group),
      });
    });
    instructorC.push({
      Header: "Flight & SIM Check",
      accessor: "",
      sortable: false,
      columns: tmp,
    });
    instructorC.push({
      Header: "",
      accessor: "",
      sortable: false,
      columns: [
        {
          Header: "Ground Check",
          headerClassName: "my-check-column-header-group",
          accessor: "event",
          sortable: false,
          width: 30,
          style: { textAlign: "center" },
          Cell: (cellInfo) =>
            this.showTraningCourse(cellInfo.original.id, "groundCheck"),
        },
      ],
    });
    tmp = [];
    checkGroup.map((group) => {
      tmp.push({
        Header: group,
        headerClassName: "my-course-column-header-group",
        accessor: "event",
        sortable: false,
        width: 30,
        style: { textAlign: "center" },
        Cell: (cellInfo) =>
          this.showTraningCourseByKey(cellInfo.original.id, "caatCheck", group),
      });
    });
    instructorC.push({
      Header: "CAAT Check",
      accessor: "",
      sortable: false,
      columns: tmp,
    });
    this.setState({
      instructorColumn: instructorC,
      loading: false,
    });
  }

  getInstructorCourse = (id, course) => {
    const { trainingCourse } = this.state;
    if (trainingCourse) {
      let result = trainingCourse.find((item) => {
        return parseInt(item.instructorId) === parseInt(id);
      });
      if (result && result.subject && result.subject[course]) {
        let maxRow = Math.ceil(result.subject[course].length / 2);
        if (maxRow < 10) maxRow = 10;
        return (
          <Popup
            flowing
            hoverable
            // open
            trigger={<Icon color="green" name="check" />}
            basic
            position="right center"
            style={{ maxWidth: "30%" }}
          >
            <Grid columns="2" width="10">
              {result.subject[course].map((subject, i) => (
                <Grid.Column
                  key={i}
                  style={{ paddingTop: "6px", paddingBottom: "2px" }}
                >
                  <p className="bold" style={{ fontSize: "85%" }}>
                    {subject.code
                      ? `${subject.code} ${subject.name}`
                      : `${subject.name} ${
                          subject.hours && ` (${subject.hours})`
                        }`}
                  </p>
                </Grid.Column>
              ))}
            </Grid>
          </Popup>
        );
      }
    }
    return "";
  };
  getInstructorCheck = (id, subject) => {
    const { trainingCourse } = this.state;
    let result = trainingCourse.find((item) => {
      return parseInt(item.instructorId) === parseInt(id);
    });
    if (result && result.checkCourse) {
      if (subject === "DCP") {
        if (result.DCP) return <Icon color="green" name="check" />;
      } else {
        let currentCourse = result.checkCourse.find((item) => {
          return item.id === subject;
        });
        if (currentCourse) return <Icon color="green" name="check" />;
      }
    }
    return "";
  };

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Schedule Track" />
          <Menu widths="2" style={{ marginTop: "0px" }}>
            <Menu.Item
              name="Instructors"
              active={state.activeItem === "Instructors"}
              onClick={this.handleChangeType}
            >
              Instructors
            </Menu.Item>
            <Menu.Item
              name="Students"
              active={state.activeItem === "Students"}
              onClick={this.handleChangeType}
            >
              Students
            </Menu.Item>
          </Menu>
          {state.activeItem === "Instructors" && (
            <Form>
              <Form.Group inline style={{ marginBottom: "0px" }}>
                <Form.Dropdown
                  icon={null}
                  fluid
                  floating
                  scrolling
                  width="4"
                  style={{ width: "100%" }}
                  selectOnBlur={false}
                  trigger={
                    <Button
                      basic
                      icon
                      fluid
                      size="small"
                      labelPosition="right"
                      style={{ textAlign: "left" }}
                    >
                      <Icon name="angle down" />
                      Categories : <b>{state.search.categories}</b>
                    </Button>
                  }
                  options={state.options_categories}
                  onChange={this.handleChangeCategories}
                />
              </Form.Group>
            </Form>
          )}
          {state.activeItem === "Students" && (
            <Form>
              <Form.Group inline style={{ marginBottom: "0px" }}>
                <Form.Dropdown
                  icon={null}
                  fluid
                  floating
                  scrolling
                  width="5"
                  style={{ width: "100%" }}
                  selectOnBlur={false}
                  trigger={
                    <Button
                      basic
                      icon
                      fluid
                      size="small"
                      labelPosition="right"
                      style={{ textAlign: "left" }}
                    >
                      <Icon name="angle down" />
                      Course : <b>{state.search.course}</b>
                    </Button>
                  }
                  options={state.options_course}
                  onChange={this.handleChangeCourse}
                />
              </Form.Group>
            </Form>
          )}
          <Form>
            <Form.Group style={{ marginBottom: "8px" }}>
              <Form.Field width="4" style={{ paddingTop: "10px" }}>
                {state.view !== "Subject" && state.view !== "Course" && (
                  <DayPicker
                    day={state.seletedDate}
                    view="month"
                    disableDateClick
                    onChange={this.handleChangeDate}
                  />
                )}
              </Form.Field>
              <Form.Field
                width="12"
                style={{
                  paddingTop: "12px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {state.activeItem === "Instructors" ? (
                  <Button size="mini" basic color="grey">
                    <Dropdown
                      icon={false}
                      value={state.view}
                      options={[
                        {
                          key: "Schedule Track ",
                          text: "Schedule Track ",
                          value: "Schedule",
                          content: "Schedule Track ",
                        },
                        {
                          key: "Course Track ",
                          text: "Course Track ",
                          value: "Course",
                          content: "Course Track ",
                        },
                        {
                          key: "Flight Hrs. Track ",
                          text: "Flight Hrs. Track",
                          value: "FlightHrs",
                          content: "Flight Hrs.Track",
                        },
                      ]}
                      onChange={this.handleChangeView}
                      selectOnBlur={false}
                    />
                  </Button>
                ) : (
                  <Button size="mini" basic color="grey">
                    <Dropdown
                      icon={false}
                      value={state.view}
                      options={[
                        {
                          key: "Schedule Track ",
                          text: "Schedule Track ",
                          value: "Schedule",
                          content: "Schedule Track ",
                        },
                        {
                          key: "Subject Track ",
                          text: "Subject Track ",
                          value: "Subject",
                          content: "Subject Track ",
                        },
                        {
                          key: "Flight Hrs. Track ",
                          text: "Flight Hrs. Track",
                          value: "FlightHrs",
                          content: "Flight Hrs.Track",
                        },
                        // {
                        //   key: "Progress Checklist ",
                        //   text: "Progress Checklist",
                        //   value: "ProgressChecklist",
                        //   content: "Progress Checklist",
                        // },
                      ]}
                      onChange={this.handleChangeView}
                      selectOnBlur={false}
                    />
                  </Button>
                )}
              </Form.Field>
            </Form.Group>
          </Form>

          {state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Icon loading name="circle notch" />
              <br />
              Loading..
            </div>
          ) : state.activeItem === "Instructors" ? (
            <TableFixedColumns
              data={state.instructorList}
              columns={state.instructorColumn}
              height={500}
            />
          ) : (
            state.activeItem === "Students" && (
              <TableFixedColumns
                data={state.studentList}
                columns={state.studentColumn}
                height={500}
              />
            )
          )}
        </Segment>
      </div>
    );
  }

  handleChangeDate = (date) => {
    this.setState({
      seletedDate: date,
      loading: true,
    });
    this.getAllEventInSch(date);
  };
  handleChangeView = (e, data) => {
    this.setState(
      {
        view: data.value,
      },
      () => {
        this.getAllEventInSch(this.state.seletedDate);
      }
    );
  };
  handleChangeType = (e, data) => {
    this.setState(
      {
        activeItem: data.name,
        view: "Schedule",
      },
      () => {
        this.getAllEventInSch(this.state.seletedDate);
      }
    );
  };
  handleChangeCourse = (e, { value }) => {
    let search = this.state.search;
    search["course"] = value.name;
    this.setState(
      {
        course: value.id,
        search: search,
      },
      () => {
        this.getStudentByCourse(value.id);
      }
    );
  };
  handleChangeCategories = (e, { value }) => {
    let search = this.state.search;
    search["categories"] = value;
    this.setState(
      {
        search: search,
        instructorList: [],
      },
      () => {
        this.getInstructor();
        this.getAllEventInSch(this.state.seletedDate);
      }
    );
  };
  pad2(number) {
    return (number < 10 ? "0" : "") + number;
  }
}

export default withRouter(ScheduleTrack);
