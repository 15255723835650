import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Segment, Header, Card, Icon } from "semantic-ui-react";
import * as config from "../../config";

class MainCourse extends Component {
  state = {
    data: [
      {
        id: 1,
        icon: "student",
        description: "Course",
        linkto: "/Course/Course"
      },
      // {
      //   id: 2,
      //   icon: "book",
      //   description: "Module",
      //   linkto: "/Course/Module"
      // },
      {
        id: 3,
        icon: "computer",
        description: "Ground Subjects",
        linkto: "/Course/GroundSubjects"
      },
      {
        id: 4,
        icon: "plane",
        description: "Flight Subjects",
        linkto: "/Course/FlightSubjects"
      },
      {
        id: 5,
        icon: "telegram plane",
        description: "Lesson",
        linkto: "/Course/Lesson"
      }
      // {
      //   id: 6,
      //   icon: "address book",
      //   description: "Log Book",
      //   linkto: "/Course/Logbook"
      // },
      // {
      //   id: 7,
      //   icon: "address book outline",
      //   description: "Log Book - SP",
      //   linkto: "/Course/LogbookSP"
      // }
    ]
  };

  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Header as="h2" style={{ color: config.colorTheme }}>
          Academic
        </Header>
        <Card.Group itemsPerRow="6" doubling style={{ padding: "20px" }}>
          <Card
            raised
            key="master"
            style={{ background: "#ededed" }}
            onClick={() => this.props.history.push("/Course/CourseMaster")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon name="database" style={{ color: config.colorTheme }} />
                Course Master Data
              </Header>
            </Card.Content>
          </Card>
          <Card
            raised
            key="master"
            style={{ background: "#ededed" }}
            onClick={() => this.props.history.push("/Course/CourseMasterModule")}
          >
            <Card.Content style={{ textAlign: "center" }}>
              <Header as="h5" icon style={{ marginBottom: "0px" }}>
                <Icon name="database" style={{ color: config.colorTheme }} />
                Module Master Data
              </Header>
            </Card.Content>
          </Card>

        </Card.Group>
        <Card.Group itemsPerRow="6" doubling style={{ padding: "20px" }}>
          {state.data.map(item => (
            <Card
              raised
              key={item.id}
              style={{ background: "#ededed" }}
              onClick={() => this.props.history.push(item.linkto)}
            >
              <Card.Content style={{ textAlign: "center" }}>
                <Header as="h5" icon style={{ marginBottom: "0px" }}>
                  <Icon name={item.icon} style={{ color: config.colorTheme }} />
                  {item.description}
                </Header>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
        <br />
        <br />
      </Segment>
    );
  }

  handleSelectMenu = data => {
    this.props.history.push(data.linkto);
  };
}
export default withRouter(MainCourse);
