import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Segment,
  Header,
  Card,
  Icon,
  Table,
  Form,
  Breadcrumb,
  Modal,
  Button,
  Dropdown,
} from "semantic-ui-react";
import * as config from "../../config";
//config.options_report_reservation
import { MonthRangeInput } from "semantic-ui-calendar-react";
import MainButton from "../../components/mainbutton";
import HeaderWithMenu from "../../components/headerwithmenu";
import moment from "moment";
import {
  genStudentDatabase,
  exportStudentDatabase,
  getClass,
  getStudentType,
  getCourse,
} from "./service";
import Wrap from "../../components/wrap";
import TableFixedColumns from "../../components/tableFixedColumns";

class StudentDatabase extends Component {
  state = {
    date:
      moment().subtract(1, "months").format("MMMM, YYYY") +
      " - " +
      moment().format("MMMM, YYYY"),
    course: [],
    status: null,
    stdtype: [],
    sclass: [],
    dataLoading: true,
    loading: false,
    loadPopup: false,
    errorMessage: null,
    column: [
      {
        Header: "STD. TYPE",
        accessor: "stdType",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.stdType}</span>
        ),
      },
      {
        Header: "Start",
        accessor: "regisdate",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.regisdate}</span>
        ),
      },
      {
        Header: "End",
        accessor: "unregisdate",
        // sortable: 180,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.unregisdate}</span>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.status}</span>
        ),
      },
      {
        Header: "สังกัดเดิม",
        accessor: "last_company",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "สังกัดใหม่",
        accessor: "new_company",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "ปีที่ได้งาน",
        accessor: "year_employed",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "Position",
        accessor: "job_position",
        sortable: false,
        style: { textAlign: "center" },
      },
      {
        Header: "License",
        accessor: "",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className=""></span>,
      },
      {
        Header: "Remark",
        accessor: "",
        sortable: false,
        style: { textAlign: "center" },
        width: 250,
        Cell: (cellInfo) => <span className=""></span>,
      },
      {
        Header: "Name-Surname (SUN_SYSTEM)",
        accessor: "nameEng",
        sortable: false,
        // style: { textAlign: "center" },
        width: 200,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.nameEng}</span>
        ),
      },
      {
        Header: "Customer Code",
        accessor: "customerCode",
        sortable: false,
        style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.customerCode}</span>
        ),
      },
      {
        Header: "Batch Code",
        accessor: "",
        sortable: false,
        // style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className=""></span>,
      },
      {
        Header: "Licence Number",
        accessor: "licencenumber",
        sortable: false,
        // style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.licencenumber}</span>
        ),
      },
      {
        Header: "Date Of Issue",
        accessor: "issueddate",
        sortable: false,
        style: { textAlign: "center" },
        width: 130,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.issueddate}</span>
        ),
      },
      {
        Header: "Oreintation",
        accessor: "",
        sortable: false,
        // style: { textAlign: "center" },
        // width: 200,
        Cell: (cellInfo) => <span className=""></span>,
      },
      {
        Header: "Date Conferred",
        accessor: "",
        sortable: false,
        // style: { textAlign: "center" },
        // width: 150,
        Cell: (cellInfo) => <span className=""></span>,
      },
      {
        Header: "E-MAIL",
        accessor: "email",
        sortable: false,
        // style: { textAlign: "center" },
        width: 180,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.email || "ไม่มีข้อมูล"}</span>
        ),
      },
      {
        Header: "TEL",
        accessor: "phone",
        sortable: false,
        style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.phone || "ไม่มีข้อมูล"}</span>
        ),
      },
      {
        Header: "Nickname",
        accessor: "nickName",
        sortable: false,
        style: { textAlign: "center" },
        width: 80,
      },
      {
        Header: "Address",
        accessor: "address",
        sortable: false,
        // style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span className="">{cellInfo.original.address || "ไม่มีข้อมูล"}</span>
        ),
      },
      {
        Header: "Job",
        accessor: "job_name",
        sortable: false,
      },
      {
        Header: "เดือนเข้า",
        accessor: "regisyear",
        sortable: false,
      },
    ],
    columnFix: [
      {
        Header: "Std ID.",
        accessor: "studentId",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 80,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.studentId}</span>
        ),
      },
      {
        Header: "Gender",
        accessor: "gender",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 80,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.gender}</span>
        ),
      },
      {
        Header: "Title",
        accessor: "title",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 80,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.title}</span>
        ),
      },
      {
        Header: "Name",
        accessor: "firstName",
        fixed: "left",
        sortable: false,
        // style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.firstName}</span>
        ),
      },
      {
        Header: "Surname",
        accessor: "lastName",
        fixed: "left",
        sortable: false,
        // style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.lastName}</span>
        ),
      },
      {
        Header: "Course",
        accessor: "course",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.course}</span>
        ),
      },
      {
        Header: "Class",
        accessor: "class",
        fixed: "left",
        sortable: false,
        style: { textAlign: "center" },
        width: 100,
        Cell: (cellInfo) => (
          <span className="bold">{cellInfo.original.class}</span>
        ),
      },
    ],
  };
  onClickExport = () => {
    this.setState({ loadPopup: true, errorMessage: null });
    const { date, course, sclass, stdtype, status } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString =
        "?start=" +
        moment(start, "MMMM, YYYY").format("YYYY-MM") +
        "&end=" +
        moment(end, "MMMM, YYYY").format("YYYY-MM");
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "months").format("YYYY-MM") +
        "&end=" +
        moment().format("YYYY-MM");
      this.setState({
        date:
          moment().subtract(1, "months").format("MMMM, YYYY") +
          " - " +
          moment().format("MMMM, YYYY"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (sclass.length > 0) {
      sclass.map((tmp) => {
        qString += "&class[]=" + tmp;
      });
    }
    if (stdtype.length > 0) {
      stdtype.map((tmp) => {
        qString += "&stdtype[]=" + tmp;
      });
    }
    if (status) {
      qString += "&status=" + status;
    }
    exportStudentDatabase(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loadPopup: false });
        var newBlob = new Blob([res.data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          var newBlobIE = new Blob([res.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          return navigator.msSaveOrOpenBlob(
            newBlobIE,
            "StudentDatabase_" + moment().format("YYYYMMDDHHmmss") + ".xlsx"
          );
        }
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        this.setState({ link: link.href });
        link.download =
          "StudentDatabase_" + moment().format("YYYYMMDDHHmmss") + ".xlsx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL

          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        this.setState({
          errorMessage: "The request is taking too long. Please try again",
        });
      }
    });
  };
  onClickFilterDate = () => {
    const { date, course, sclass, stdtype, status } = this.state;
    const val = date.split(" - ");
    let start = val[0];
    let end = val[1];
    let qString = "";
    if (start && end) {
      qString =
        "?start=" +
        moment(start, "MMMM, YYYY").format("YYYY-MM") +
        "&end=" +
        moment(end, "MMMM, YYYY").format("YYYY-MM");
    } else {
      qString =
        "?start=" +
        moment().subtract(1, "months").format("YYYY-MM") +
        "&end=" +
        moment().format("YYYY-MM");
      this.setState({
        date:
          moment().subtract(1, "months").format("MMMM, YYYY") +
          " - " +
          moment().format("MMMM, YYYY"),
      });
    }
    if (course.length > 0) {
      course.map((tmp) => {
        qString += "&course[]=" + tmp;
      });
    }
    if (sclass.length > 0) {
      sclass.map((tmp) => {
        qString += "&class[]=" + tmp;
      });
    }
    if (stdtype.length > 0) {
      stdtype.map((tmp) => {
        qString += "&stdtype[]=" + tmp;
      });
    }
    if (status) {
      qString += "&status=" + status;
    }
    // console.log('qString',qString);
    this.getData(qString);
  };
  handleChangeDate = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };
  getData = (qString) => {
    this.setState({ dataLoading: true, data: [] });
    genStudentDatabase(qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ dataLoading: false });
    });
  };
  componentDidMount() {
    const { date } = this.state;
    getCourse("?additionalCourse=false&active=true").then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            options.push({
              key: item.id,
              text: `${item.name} (${item.desc})`,
              value: item.id,
            });
          });
        }
        this.setState({ options_course: options });
      }
    });
    getClass().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            options.push({ key: item.id, text: item.name, value: item.id });
          });
        }
        this.setState({ options_class: options });
      }
    });
    getStudentType().then((res) => {
      if (res && res.status === 200) {
        let options = [];
        if (res.data.length > 0) {
          res.data.map((item) => {
            options.push({ key: item.id, text: item.name, value: item.id });
          });
        }
        this.setState({ options_studenttype: options });
      }
    });

    let qString =
      "?start=" +
      moment().subtract(1, "months").format("YYYY-MM") +
      "&end=" +
      moment().format("YYYY-MM");
    this.getData(qString);
  }
  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Modal
          size={state.errorMessage ? "tiny" : "mini"}
          open={state.loadPopup}
          closeIcon={state.errorMessage ? true : false}
          dimmer="blurring"
          closeOnDimmerClick={false}
          onClose={() =>
            this.setState({ loadPopup: false, errorMessage: null })
          }
        >
          <Wrap>
            {state.errorMessage ? (
              <Wrap>
                <Modal.Header>
                  <Icon name="warning sign" /> Warning
                </Modal.Header>
                <Modal.Content>{state.errorMessage}</Modal.Content>
              </Wrap>
            ) : (
              <Modal.Content>
                <div style={{ textAlign: "center" }}>
                  <Icon loading name="circle notch" />
                  <br />
                  Waiting Download File
                </div>
              </Modal.Content>
            )}
          </Wrap>
        </Modal>
        <HeaderWithMenu name="Report - Student Database">
          <Button size="small" onClick={this.onClickExport} color="green">
            Export Excel
          </Button>
        </HeaderWithMenu>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.props.history.push("/Report")}
            style={{ color: "grey" }}
          >
            Report
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Student Database</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <br />
        <Form>
          <Form.Group>
            <Form.Field width={6}>
              <MonthRangeInput
                name="date"
                placeholder="Start - End"
                hideMobileKeyboard
                dateFormat="MMMM, YYYY"
                iconPosition="right"
                value={this.state.date}
                // defaultValue={state.date}
                onChange={this.handleChangeDate}
                clearable
              />
            </Form.Field>
            <Form.Field width={6}>
              <Dropdown
                name="course"
                placeholder="Select Course"
                search
                multiple
                selection
                options={state.options_course}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="sclass"
                placeholder="Select Class"
                search
                multiple
                selection
                options={state.options_class}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Dropdown
                name="status"
                placeholder="Select Status"
                clearable
                selection
                options={config.options_studentstatus_report}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group>
            <Form.Field width={4}>
              <Dropdown
                name="stdtype"
                placeholder="Select Student Type"
                search
                multiple
                selection
                options={state.options_studenttype}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field>
              <MainButton
                size="small"
                text="Filter"
                loading={state.dataLoading}
                onClick={this.onClickFilterDate}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <TableFixedColumns
          data={state.data}
          // columns={state.column}
          columns={state.columnFix.concat(state.column)}
          height={500}
          loading={state.dataLoading}
        />
      </Segment>
    );
  }
}

export default withRouter(StudentDatabase);
