export const roles = [
    {
        "id": 44225,
        "name": "Administrator",
        "description": "Reserve for one or two all powerful users.",
        "canMakeReservationsForAllUsers": true
    },
    {
        "id": 44227,
        "name": "Instructors",
        "description": "Flight Instructor / \nGround Instructor / \nSimulator Instructor / \nPCAT Instructor / \nMonitor Instructor",
        "canMakeReservationsForAllUsers": false
    },
    {
        "id": 44229,
        "name": "Students",
        "description": "All students in BAC must possess this role.",
        "canMakeReservationsForAllUsers": false
    },
    {
        "id": 50388,
        "name": "OUT",
        "description": "",
        "canMakeReservationsForAllUsers": false
    },
    {
        "id": 49626,
        "name": "Graduated",
        "description": "Completed with total flight 205 hrs.",
        "canMakeReservationsForAllUsers": false
    },
    {
        "id": 44230,
        "name": "Read Only",
        "description": "Read Only.",
        "canMakeReservationsForAllUsers": false
    }
]
