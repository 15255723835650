
import React from 'react'
import { SketchPicker } from 'react-color'
import { Form, Radio } from 'semantic-ui-react'
import Wrap from './wrap'

class SketchExample extends React.Component {
  state = {
    displayColorPicker: false,
    color: '#FFFFFF'
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };
  handleChange = (color) => {
    // console.log(color)
    const { onChange, value } = this.props.input;
    if(value !== color.rgb){
        onChange(color.hex)
    }
    
    this.setState({ color: color.hex })
  };
  render() {
    const styles = {
        color: {
          width: 28,
          height: 19,
          borderRadius: '2px',
          background: `${ this.state.color }`,
        },
        swatch: {
          padding: '2px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
    }
    const props = this.props 
    console.log('select color',props.input.name,props.input.value)
    return (
        <Wrap>
            <Form.Field width={props.width} required={props.required} style={{ marginBottom: '0px', textAlign: 'left' }}> 
                <div style={ styles.swatch } onClick={ this.handleClick }>
                <div style={ styles.color } />
                </div>
                { this.state.displayColorPicker ? 
                    <div style={ styles.popover }>
                    <div style={ styles.cover } onClick={ this.handleClose }/>
                        <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                    </div> 
                    : null 
                }
            </Form.Field>
        </Wrap>
    )
  }
}

export default SketchExample