import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Button, Form, Menu, Segment, Dropdown } from "semantic-ui-react";
import * as config from "../../config";
import {
  getEventBySearch,
  getEventDetail,
  getStudentBySearch,
  getGroup,
  getCourse,
  getCourseById,
  getEventByAttendanceSearch
} from "./service";
import moment from "moment";
import Wrap from "../../components/wrap";
import ModalForm from "../../components/modal";
import HeaderWithMenu from "../../components/headerwithmenu";
import EventDetailForm from "./eventDetail";
import ReservForm from "./reservationForm";
import FromReservation from "./reservationForm";
import HoriCalendar from "../../components/horiCalendarGround";

//  location ยึดตามเครื่องบิน Routes ยึดตามอะไร

class Schedule extends Component {
  state = {
    resources: [],
    events: [],
    studentByGroup: [],
    options_group: [],
    options_course: [],
    options_module: [],
    course: 1,
    module: 1,
    group: 1,
    search: {
      course: null,
      group: null,
      module: null
    },
    loading: false
  };

  componentDidMount() {
    this.getOptionCourse();
  }

  getOptionCourse = () => {
    let options_course = [];
    getCourse().then(res => {
      if (res && res.status === 200) {
        res.data.map(item => {
          options_course.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item
          });
        });

        this.setState({
          options_course
        });

        if (options_course[0] && options_course[0].value.id) {
          let search = this.state.search;
          search["course"] = options_course[0].value.name;
          console.log(options_course[0].value, "options_course[0].value");
          this.setState({ course: options_course[0].value.id });
          this.getStudentByCourse(options_course[0].value.id);
        }
      }
    });
  };

  getStudentByCourse = course => {
    // รอคิวรี่ course
    getStudentBySearch("?course.id=" + course).then(res => {
      let group = [];
      let student = [];
      let studentByGroup = [];
      if (res && res.status === 200) {
        studentByGroup = res.data.reduce(function(result, current) {
          current.groups.map(group => {
            result[group.value] = result[group.value] || [];
            result[group.value].push(current);
          });
          return result;
        }, {});

        let currentGroup = [];
        res.data.map(item => {
          // รอคิวรี่
          let result = item.course.find(member => {
            return member.id === course;
            //  return member.id === course;
          });

          if (result) {
            // รอคิวรี่
            item.groups.map(item => {
              if (currentGroup.indexOf(item.value) < 0) {
                currentGroup.push(item.value);
                group.push({
                  ...item,
                  key: item.value,
                  text: item.name,
                  value: item
                });
              }
            });
            student.push({
              key: item.id,
              text: `${item.studentId} ${item.firstName} ${item.lastName}`,
              value: item.id
            });
          }
        });
      }
      this.setState({
        studentByGroup: studentByGroup,
        options_group: group
      });
      if (group[0] && group[0].value) {
        let search = this.state.search;
        search["group"] = group[0].value.name;
        this.setState({ group: group[0].value.value, search });
        this.getEvents(group[0].value.value);
      } else {
        let search = this.state.search;
        search["group"] = "";
        this.setState({ search });
      }
    });
  };

  getEvents = group => {
    this.setState({ loading: true });
    let student = this.state.studentByGroup[group] || [];
    let qString = "?";
    student.map(item => {
      qString = qString + "studentId=" + item.studentId + "&";
    });
    getEventByAttendanceSearch(qString + "_sort=reservationId").then(res => {
      if (res && res.status === 200) {
        // รอคิวรี่
        let currentEvent;
        let qString = "?";
        res.data.map(event => {
          if (event.reservationId !== currentEvent) {
            currentEvent = event.reservationId;
            qString = qString + "id=" + event.reservationId + "&";
          }
        });
        getEventBySearch(qString).then(res => {
          if (res && res.status === 200) {
            let dataSchedule = [],
              resources = [];
            res.data.map(item => {
              let bgColor = null,
                textColor = null;
              if (item.displayType === 3) {
                bgColor = "#AC4EDD";
                textColor = "#ffffff";
              } else {
                // training course
                bgColor = "#EF476F";
                textColor = "#ffffff";
              }
              dataSchedule.push({
                id: item.id,
                start: moment(
                  `${moment().format("YYYY-MM-DD")} ${moment(item.start).format(
                    "HH:mm:ss"
                  )}`
                )._d,
                end: moment(
                  `${moment().format("YYYY-MM-DD")} ${moment(item.end).format(
                    "HH:mm:ss"
                  )}`
                )._d,
                title: item.title,
                status: item.status,
                bgColor: bgColor,
                textColor: textColor,
                resourceId: moment(item.start).format("DD-MM-YYYY")
              });
              resources.push({
                id: moment(item.start).format("DD-MM-YYYY"),
                name: moment(item.start).format("DD MMM  YYYY")
              });
            });
            const filteredArr = resources.reduce((acc, current) => {
              const x = acc.find(item => item.id === current.id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            this.setState({
              events: dataSchedule,
              loading: false,
              resources: filteredArr
            });
          }
        });
      }
    });
  };
  getDetail = id => {
    getEventDetail(id).then(res => {
      if (res && res.status === 200)
        this.setState({ eventDetail: res.data, open: true });
    });
  };
  onClickCancel = () => {
    this.setState({ open: false, eventDetail: null });
  };

  render() {
    const state = this.state;
    return (
      <div>
        <Segment className="contentSegment" textAlign="left">
          <HeaderWithMenu name="Ground Training Subjects" />
          <Form size="small" inline>
            <Form.Group style={{ marginBottom: "0px" }}>
              <Form.Dropdown
                icon={null}
                fluid
                floating
                scrolling
                width="3"
                style={{ width: "100%" }}
                selectOnBlur={false}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Course : <b>{state.search.course}</b>
                  </Button>
                }
                options={state.options_course}
                onChange={this.handleChangeCourse}
              />
              {/* <Form.Dropdown
                icon={null}
                fluid
                floating
                scrolling
                width="3"
                style={{ width: "100%" }}
                selectOnBlur={false}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Module : <b>{state.search.module}</b>
                  </Button>
                }
                options={state.options_module}
                onChange={this.handleChangeModule}
              /> */}
              <Form.Dropdown
                icon={null}
                fluid
                floating
                scrolling
                width="3"
                style={{ width: "100%" }}
                selectOnBlur={false}
                trigger={
                  <Button
                    basic
                    icon
                    fluid
                    size="small"
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    <Icon name="angle down" />
                    Course : <b>{state.search.group}</b>
                  </Button>
                }
                options={state.options_group}
                onChange={this.handleChangeGroup}
              />
            </Form.Group>
          </Form>
          <div style={{ textAlign: "right", fontSize: "85%" }} className="bold">
            <Icon name="circle" style={{ color: "#EF476F" }} /> Ground training.
            &nbsp; &nbsp;
            <Icon name="circle" style={{ color: "#AC4EDD" }} /> Others
          </div>
          {state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Icon loading name="circle notch" />
              <br />
              Loading..
            </div>
          ) : (
            <HoriCalendar
              start={8}
              stop={18}
              day={state.seletedDate}
              resources={state.resources}
              events={state.events}
              // eventClicked={() => console.log("d")}
              eventClicked={this.handleShowDetailEvent}
              // slotClicked={this.handleShowReservForm}
            />
          )}
        </Segment>

        <ModalForm
          open={state.open}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em"
          }}
          size="small"
          content="Reservation"
          close={this.onClickCancel}
        >
          <EventDetailForm data={state.eventDetail} />
        </ModalForm>
      </div>
    );
  }

  handleShowDetailEvent = data => {
    this.getDetail(data.id);
    // this.getDetail("09cd90e4-32b5-41f6-ab9a-af00e4ebf192");
  };

  handleChangeCourse = (e, { value }) => {
    let search = this.state.search;
    search["course"] = value.name;
    this.setState({
      course: value.id,
      events: [],
      resources: [],
      search: search
    });
    this.getStudentByCourse(value.id);
  };
  handleChangeModule = (e, { value }) => {};
  handleChangeGroup = (e, { value }) => {
    let search = this.state.search;
    search["group"] = value.name;
    this.setState({
      group: value.value,
      events: [],
      resources: [],
      search: search
    });
    this.getEvents(value.value);
  };
}

export default withRouter(Schedule);
