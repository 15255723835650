import React, { Component } from "react";
import { Form, Icon, Button, Popup, Input } from "semantic-ui-react";

class DropdownCheckbox extends Component {
  state = {
    checked: [],
    rawOptions: this.props.options,
    options: this.props.options
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.state.rawOptions)
      this.setState({
        rawOptions: nextProps.options,
        options: nextProps.options
      });
    if (nextProps.checked !== this.state.checked)
      this.setState({ checked: nextProps.checked || [] });
  }

  onChangeSelectAll = (e, data) => {
    let { checked, rawOptions } = this.state;
    if (data.checked) {
      checked = rawOptions.map(ele => ele.value);
    } else {
      checked = [];
    }
    this.setState({ checked });
  };

  onChange = (e, data) => {
    let { checked } = this.state;
    if (data.checked) {
      checked.push(data.value);
    } else {
      checked = checked.filter(ele => {
        return ele != data.value;
      });
    }
    this.setState({ checked });
  };

  onChangeSearch = (e, data) => {
    let { rawOptions } = this.state;
    let options = [];
    rawOptions.map(item => {
      if (item.text.toLowerCase().indexOf(data.value.toLowerCase()) >= 0) {
        options.push(item);
      }
    });
    this.setState({ options });
  };
  render() {
    const state = this.state;
    return (
      <Popup
        basic
        trigger={this.props.children}
        on="click"
        position="bottom left"
        style={{ padding: 0, width: "40vw" }}
      >
        <Popup.Content style={{ padding: "5px" }}>
          <Input
            icon
            size="mini"
            fluid
            placeholder="Search..."
            style={{ margin: "5px" }}
            onChange={this.onChangeSearch}
          >
            <input />
            <Icon name="search" />
          </Input>
          <Form style={{ paddingLeft: "5px", paddingBottom: "5px" }}>
            <Form.Checkbox
              label="Select All"
              className="bold"
              checked={state.checked.length === state.rawOptions.length}
              onChange={this.onChangeSelectAll}
            />
          </Form>
        </Popup.Content>
        <Popup.Content
          style={{ height: "30vh", overflow: "auto", paddingLeft: "10px" }}
        >
          <Form>
            {state.options.map(item => (
              <Form.Checkbox
                style={{ paddingRight: "3px" }}
                checked={state.checked.includes(item.value)}
                label={item.text}
                value={item.value}
                onChange={this.onChange}
              />
            ))}
          </Form>
        </Popup.Content>
        <Popup.Content style={{ padding: "3px" }}>
          <Button
            fluid
            content="Filter"
            onClick={() => this.props.onClick(state.checked)}
          />
        </Popup.Content>
      </Popup>
    );
  }
}
export default DropdownCheckbox;
