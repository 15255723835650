import React from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";

// Import React Table HOC Fixed columns
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
const ReactTableFixedColumns = withFixedColumns(ReactTable);

class Table extends React.Component {
  constructor() {
    super();
    this.state = {
      data: []
    };
  }
  render() {
    const { data, columns, height, defaultPageSize, loading } = this.props;
    return (
      <div>
        <ReactTableFixedColumns
          data={data}
          columns={columns}
          defaultPageSize={defaultPageSize || 20}
          style={{ height: height || 500 }}
          className="-striped"
          loading={loading || false}
        />
      </div>
    );
  }
}

export default Table;
