const validate = values => {
  const errors = {};

  if (!values.tailNumber) {
    errors.tailNumber = true;
  }

  if (!values.makeId) {
    errors.makeId = true;
  }

  if (!values.modelId) {
    errors.modelId = true;
  }

  if (!values.category) {
    errors.category = true;
  }

  if (values.category === "sim") {
    if (!values.simClass) {
      errors.simClass = true;
    }
      if (!values.numberOfEngines) {
          errors.numberOfEngines = true;
      }
  }
  if (values.category === "airplane") {
    if (!values.airClass) {
      errors.airClass = true;
    } else {
      if (values.airClass.multi) {
        if (!values.numberOfEngines) {
          errors.numberOfEngines = true;
        }
      }
    }
  }

  return errors;
};

export default validate;
