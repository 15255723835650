import React, { Component } from "react";
import { Segment, Button, Form, Header, Table, Icon, Label, Breadcrumb, Grid, Image, Checkbox } from "semantic-ui-react";
import * as config from "../../config";
import moment from "moment";
import { getReportoffenses, approveOffense, getDisciplinescore, penalizeOffense } from "./service";
import Wrap from '../../components/wrap'
import ReactToPrint from "react-to-print";

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
};

class Discipline extends Component {
  state = {
    bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
    search: {},
    rawData: null,
    data: null,
    open: false,
    penalise: false,
    currentData: null,
    totalScore: 0
  };



  componentDidMount() {
    this.getData()
  }


  getData = () => {
    this.setState({ loading: true })
    getReportoffenses().then((res) => {
      if (res && res.status === 200) {
        this.setState({
          rawData: res.data,
          data: res.data,
        });
      }
      this.setState({ loading: false })
    });
  }

  onClickShow = (item) => {
    if (JSON.parse(item.behavior))
      item['behavior'] = groupBy(JSON.parse(item.behavior), 'level')
    this.setState({
      open: true,
      currentData: item
    })
  }

  onClickShowPenalise = () => {
    this.setState({ loading: true })
    getDisciplinescore().then(res => {
      if (res && res.status === 200) {
        this.setState({
          data: res.data,
          behavior: groupBy(res.data, 'level'),
          loading: false,
          open: false,
          penalise: true
        })
      }
    })

  }

  onClickClose = () => {
    this.setState({ loading: true, penalise: false, open: false })
    this.getData()
  }

  handleChangeSearch = (e) => {
    let { rawData, search } = this.state;

    search[e.target.name] = e.target.value;
    this.setState({ search });

    if (e.target.value) {
      let newdata = [];
      rawData.map((item) => {
        let description = `${item.titleEng} ${item.firstNameEng} ${item.lastNameEng}`;
        if (
          description.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
        ) {
          newdata.push(item);
        }
      });
      this.setState({ data: newdata });
    } else this.setState({ data: rawData });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleClickScore = (e, key, index) => {
    if (e.checked) {
      this.setState(prevState => {
        prevState.behavior[key][index]['checked'] = true
        return {
          ...prevState,
          behavior: prevState.behavior
        }
      })
    } else {
      this.setState(prevState => {
        prevState.behavior[key][index]['checked'] = false
        return {
          ...prevState,
          behavior: prevState.behavior
        }
      })
    }

  }
  approveOffense = () => {
    this.setState({ loading: true })
    let { comment, currentData } = this.state
    approveOffense(currentData.id, { comment: comment }).then(res => {
      if (res && res.status === 200) {
        this.getData()
        this.setState({
          open: false,
          currentData: null
        })
      }
      this.setState({ loading: false })
    })
  }

  penaliseOffense = () => {
    this.setState({ loading: true })
    let { currentData, behavior } = this.state
    currentData['behavior'] = []
    currentData['behavior'].push(...behavior['light'], ...behavior['medium'], ...behavior['heavy'])
    // console.log(currentData) //รอส่งไป api เซฟ แล้วเปลี่ยนสเตตัส

    penalizeOffense(currentData.id, currentData).then(res => {
      if (res && res.status === 200) {
        this.setState({ open: false, penalise: false, currentData: null })
        this.getData()
      }
      this.setState({ loading: false })
    })
  }

  render() {
    let state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <Header as="h2" style={{ color: config.colorTheme }}>
          Discipline - Report Offense
        </Header>

        {state.loading
          ? <div style={{ textAlign: "center" }}>
            <Icon loading name="circle notch" />
            <br />
            Loading..
          </div>
          : state.open
            ? this.reportView()
            : state.penalise
              ? this.penaliseView()
              : this.tableView()}
      </Segment>
    );
  }



  tableView = () => {
    let state = this.state;
    return (
      <Wrap>
        <Form>
          <Form.Group>
            <Form.Input
              width="3"
              icon="search"
              size="small"
              placeholder="Search"
              name="name"
              onChange={this.handleChangeSearch}
            />
          </Form.Group>
        </Form>

        {state.data ? (
          <Table celled padded compact style={{ marginBottom: 10 }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Date time</Table.HeaderCell>
                <Table.HeaderCell>Student</Table.HeaderCell>
                <Table.HeaderCell>Class</Table.HeaderCell>
                <Table.HeaderCell width="6">Offense</Table.HeaderCell>
                <Table.HeaderCell width="1">Score</Table.HeaderCell>
                <Table.HeaderCell width="2">Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.data.map((item) => (
                <Table.Row>
                  <Table.Cell >
                    {moment(item.ondate, "DD-MM-YYYY").format("D MMMM YYYY")}
                  </Table.Cell>
                  <Table.Cell  >
                    <p>{`${item.titleEng} ${item.firstNameEng} ${item.lastNameEng} `}</p>
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: 'center' }}>
                    <p>{item.class}</p>
                  </Table.Cell>
                  <Table.Cell className="bold">
                    <p>{item.title}</p>
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: 'center' }} >
                    <p>{item.score ? item.score : '-'}</p>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {item.status === "Requested" ? (
                      <Label className="statusLabel" color="yellow">
                        Requested
                      </Label>
                    ) : item.status === "Completed" ? (
                      <Label className="statusLabel" color="green">
                        Completed
                      </Label>
                    ) : <Label className="statusLabel" color="blue">
                      Penalized
                    </Label>}
                  </Table.Cell>
                  <Table.Cell textAlign="center">

                    <Icon
                      link
                      circular
                      color="orange"
                      name="file alternate outline"
                      onClick={() => this.onClickShow(item)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <p style={{ color: "rgb(115, 115, 115)" }}>
            No description available
          </p>
        )}
      </Wrap>
    )
  }

  penaliseView = () => {
    let { currentData, bacUserData, loading, behavior, totalScore } = this.state;
    return (
      <Wrap>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.setState({ open: false, currentData: null, penalise: false })}
            style={{ color: "grey" }}
          >
            Discipline - Report Offense
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Report Offense</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        {currentData && currentData.language === "th"
          ? <Wrap>
            <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ border: 'none' }}>
                    <h3 style={{ textAlign: 'center' }}>
                      แบบบันทึกพฤติกรรมศิษย์การบินรายบุคคล
                    </h3>
                    <p style={{ textAlign: 'center' }}>
                      ศิษย์การบิน........
                      <b>
                        {currentData
                          ? ` ${currentData.titleTh} ${currentData.firstNameTh} ${currentData.lastNameTh} `
                          : ""}
                      </b>
                      ........ รุ่น........
                      <b>
                        {currentData ? currentData.class
                          : ""}
                      </b>
                      ........</p>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table fixed compact celled style={{ marginBottom: '0px' }}>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell style={{ width: '10%' }}>ลำดับ</Table.HeaderCell>
                  <Table.HeaderCell>ความประพฤติ</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '10%' }}>คะแนนที่หัก</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '15%' }}>จำนวนคะแนนที่ถูกหัก</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '15%' }}>ลายมือชื่อครูผู้หักคะแนน</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {behavior['light'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> ความผิดสถานเบา </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['light'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>{index + 1}</Table.Cell>
                        <Table.Cell>{item.name_th}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'light', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
                {behavior['medium'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> ความผิดสถานกลาง </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['medium'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {behavior['light'] ? behavior['light'].length + index + 1 : index + 1}
                        </Table.Cell>
                        <Table.Cell>{item.name_th}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'medium', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
                {behavior['heavy'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> ความผิดสถานหนัก </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['heavy'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {behavior['light'] && behavior['medium']
                            ? behavior['light'].length + behavior['medium'].length + index + 1
                            : behavior['light']
                              ? behavior['light'].length + index + 1
                              : behavior['medium']
                                ? behavior['medium'].length + index + 1
                                : index + 1
                          }
                        </Table.Cell>
                        <Table.Cell>{item.name_th}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'heavy', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
              </Table.Body>
              <Table.Footer >
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>รวมคะแนน</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Wrap>
          : currentData && <Wrap>
            <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px' }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ border: 'none' }}>
                    <h3 style={{ textAlign: 'center' }}>
                      Record of Behavior the Students Pilot on Personal
                    </h3>
                    <p style={{ textAlign: 'center' }}>
                      Students Pilot name........
                      <b>
                        {currentData
                          ? ` ${currentData.titleEng} ${currentData.firstNameEng} ${currentData.lastNameEng} `
                          : ""}
                      </b>
                      ........ Class........
                      <b>
                        {currentData ? currentData.class
                          : ""}
                      </b>
                      ........</p>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table fixed compact celled style={{ marginBottom: '0px' }}>
              <Table.Header>
                <Table.Row textAlign='center'>
                  <Table.HeaderCell style={{ width: '10%' }}>ลำดับ</Table.HeaderCell>
                  <Table.HeaderCell>Behavior</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '10%' }}>Score minus</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '15%' }}>Total Score minus</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '15%' }}>Singnature Teacher</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {behavior['light'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> light penalty </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['light'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>{index + 1}</Table.Cell>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'light', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
                {behavior['medium'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> Medium penalty </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['medium'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {behavior['light'] ? behavior['light'].length + index + 1 : index + 1}
                        </Table.Cell>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'medium', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
                {behavior['heavy'] &&
                  <Wrap>
                    <Table.Row textAlign='center'>
                      <Table.Cell></Table.Cell>
                      <Table.Cell> <b> heavy penalty </b> </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    {behavior['heavy'].map((item, index) =>
                      <Table.Row>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {behavior['light'] && behavior['medium']
                            ? behavior['light'].length + behavior['medium'].length + index + 1
                            : behavior['light']
                              ? behavior['light'].length + index + 1
                              : behavior['medium']
                                ? behavior['medium'].length + index + 1
                                : index + 1
                          }
                        </Table.Cell>
                        <Table.Cell>{item.name}</Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          <Checkbox

                            onChange={(e, data) => this.handleClickScore(data, 'heavy', index)}
                            label={item.score}
                          />
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center' }}>
                          {item.checked && item.score}
                        </Table.Cell>
                        <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                          {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                        </Table.Cell>
                      </Table.Row>
                    )
                    }
                  </Wrap>
                }
              </Table.Body>
              <Table.Footer >
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Total</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Wrap>
        }

        <br /><br />
        {currentData && currentData.status === "Completed"
          &&
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column textAlign='center'>
                <Button
                  color="primary"
                  onClick={this.penaliseOffense}
                  loading={loading}
                >
                  Submit
                </Button>
                <Button onClick={this.onClickClose}>Back</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>

        }
      </Wrap>
    )

  }



  reportView = () => {
    let { currentData, bacUserData, loading } = this.state;
    return (
      <Wrap>
        <Breadcrumb>
          <Breadcrumb.Section
            onClick={() => this.setState({ open: false, currentData: null })}
            style={{ color: "grey" }}
          >
            Discipline - Report Offense
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>Report Offense</Breadcrumb.Section>
        </Breadcrumb>
        <br />
        <ReactToPrint
          trigger={() => {
            return (
              <Button
                floated="right"
                icon="print"
                compact
                content="Print"
              />
            );
          }}
          content={() => this.componentRef}
        />
        <div ref={(el) => (this.componentRef = el)} style={{ paddingTop: '50px', paddingRight: '5vh', paddingLeft: '5vh' }}>
          {currentData.language === "th" ?
            <Wrap>
              <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <h3 style={{ textAlign: 'center' }}>
                        <Image
                          spaced
                          style={{ height: "50px" }}
                          src="../img/logo-bac.png" />
                        Bangkok Aviation Center Co.,Ltd.
                      </h3>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <b>บริษัท บางกอกเอวิเอชั่นเซ็นเตอร์ จํากัด</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very' style={{ marginTop: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>วันที่</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>
                        {moment(currentData.created_at).format("DD-MM-YYYY")}
                      </b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>เรื่อง</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>รายงานชี้แจง</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>เรียน</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>ครูปกครอง ฝ่ายปฏิบัติการบิน</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <p
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        ตามที่ กระผม/ดิฉัน&nbsp;&nbsp;
                        <b>
                          {currentData
                            ? `${currentData.titleTh} ${currentData.firstNameTh} ${currentData.lastNameTh}   `
                            : ""}
                        </b>
                        &nbsp;&nbsp;ศิษย์การบิน รุ่น &nbsp;&nbsp;
                        <b>
                          {currentData ? currentData.class
                            : ""}
                        </b>
                        &nbsp;&nbsp; ขอรายงานชี้แจงเรื่อง &nbsp;&nbsp;
                        {currentData.title} เหตุการณ์เมื่อวันที่{" "}
                        {currentData.ondate}&nbsp;&nbsp; รายละเอียดดังนี้
                        &nbsp;&nbsp;{currentData.detail}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <b
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        จึงเรียนมาเพื่อพิจารณา
                      </b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '50%', border: 'none' }}>
                    </Table.Cell>
                    <Table.Cell style={{ width: '50%', border: 'none', textAlign: 'center' }}>
                      (ลงชื่อ)........
                      <b>
                        {currentData
                          ? ` ${currentData.titleTh} ${currentData.firstNameTh} ${currentData.lastNameTh} `
                          : ""}
                      </b>
                      ........
                      <br />
                      ศิษย์การบิน
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Wrap>
            : <Wrap>
              <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <h3 style={{ textAlign: 'center' }}>
                        <Image
                          spaced
                          style={{ height: "50px" }}
                          src="../img/logo-bac.png" />
                        Bangkok Aviation Center Co.,Ltd.
                      </h3>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }}>
                      <b>Bangkok Aviation Center Co.,Ltd.</b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very' style={{ marginTop: '0px' }}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>Date</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>
                        {moment(currentData.created_at).format("DD-MM-YYYY")}
                      </b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>Subject</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>Report Offense</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ width: '10%', border: 'none' }}>
                      <b>To</b>
                    </Table.Cell>
                    <Table.Cell style={{ width: '90%', border: 'none' }}>
                      <b>Director BangkokvAviation Center ( Gurdian )</b>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <p
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        According to Sir/Madam &nbsp;&nbsp;
                        <b>
                          {currentData
                            ? `${currentData.titleEng} ${currentData.firstNameEng} ${currentData.lastNameEng}   `
                            : ""}
                        </b>
                        &nbsp;&nbsp;Students Pilot clasno &nbsp;&nbsp;
                        <b>
                          {currentData ? currentData.class
                            : ""}
                        </b>
                        &nbsp;&nbsp; Committed an offense &nbsp;&nbsp;
                        {currentData.title} On date {currentData.ondate}
                        &nbsp;&nbsp; Detail as follow &nbsp;&nbsp;
                        {currentData.detail}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ border: 'none' }} colSpan='2'>
                      <b
                        style={{
                          textIndent: "11%",
                          lineHeight: 2,
                          display: "block",
                          textAlign: "justify",
                        }}
                      >
                        For your information
                      </b>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table fixed unstackable compact basic='very'>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: '50%', border: 'none' }}>
                    </Table.Cell>
                    <Table.Cell style={{ width: '50%', border: 'none', textAlign: 'center' }}>
                      (Sign)........
                      <b>
                        {currentData
                          ? ` ${currentData.titleEng} ${currentData.firstNameEng} ${currentData.lastNameEng} `
                          : ""}
                      </b>
                      ........
                      <br />
                      Student Pilot
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Wrap>
          }

          <div class="page-break"></div>

          {
            currentData.status === "Penalized"
              && currentData.language === "en"
              ? <Wrap>
                <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px', paddingTop: '50px' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ border: 'none' }}>
                        <h3 style={{ textAlign: 'center' }}>
                          Record of Behavior the Students Pilot on Personal
                        </h3>
                        <p style={{ textAlign: 'center' }}>
                          Students Pilot name........
                          <b>
                            {currentData
                              ? ` ${currentData.titleEng} ${currentData.firstNameEng} ${currentData.lastNameEng} `
                              : ""}
                          </b>
                          ........ Class........
                          <b>
                            {currentData ? currentData.class
                              : ""}
                          </b>
                          ........</p>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table fixed unstackable celled compact basic style={{ marginBottom: '0px' }}>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell style={{ width: '10%' }}>ลำดับ</Table.HeaderCell>
                      <Table.HeaderCell>Behavior</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>Score minus</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '15%' }}>Total Score minus</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '15%' }}>Singnature Teacher</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {currentData.behavior['light'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> light penalty </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['light'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>{index + 1}</Table.Cell>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                    {currentData.behavior['medium'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> Medium penalty </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['medium'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {currentData.behavior['light'] ? currentData.behavior['light'].length + index + 1 : index + 1}
                            </Table.Cell>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                    {currentData.behavior['heavy'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> heavy penalty </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['heavy'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {currentData.behavior['light'] && currentData.behavior['medium']
                                ? currentData.behavior['light'].length + currentData.behavior['medium'].length + index + 1
                                : currentData.behavior['light']
                                  ? currentData.behavior['light'].length + index + 1
                                  : currentData.behavior['medium']
                                    ? currentData.behavior['medium'].length + index + 1
                                    : index + 1
                              }
                            </Table.Cell>
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                  </Table.Body>
                  <Table.Footer >
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>Total</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>

                <br /><br />
                <b><u>Remark</u></b>
                <p>
                  1. if the student pilot loss the score over 30 score will be  penalized<br />
                  2. if the students pilot loss the score over 80 score will be penalized by  Suspension to practice flying<br />
                  3. if the students pilot loss the score over 100 score will be make some request form to reture to school again<br />
                  4. if the students pilot who make the heavy penalty will be suspension retire from the school immediately<br />
                  5. when the school cut the score need to have evidence follow the form QF-DP-014<br />
                  6. if the students was cuted score need to have the teacher's signature
                </p>
                <br /><br />
              </Wrap>
              : currentData.status === "Penalized"
              && currentData.language === "th"
              && <Wrap>
                <Table fixed unstackable compact basic='very' style={{ marginBottom: '0px', paddingTop: '50px' }}>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell style={{ border: 'none' }}>
                        <h3 style={{ textAlign: 'center' }}>
                          แบบบันทึกพฤติกรรมศิษย์การบินรายบุคคล
                        </h3>
                        <p style={{ textAlign: 'center' }}>
                          ศิษย์การบิน ชื่อ........
                          <b>
                            {currentData
                              ? ` ${currentData.titleEng} ${currentData.firstNameEng} ${currentData.lastNameEng} `
                              : ""}
                          </b>
                          ........ รุ่น ........
                          <b>
                            {currentData ? currentData.class
                              : ""}
                          </b>
                          ........</p>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table fixed unstackable celled compact basic style={{ marginBottom: '0px' }}>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell style={{ width: '10%' }}>ลำดับ</Table.HeaderCell>
                      <Table.HeaderCell>ความประพฤติ</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>คะแนนที่หัก</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '15%' }}>จำนวนคะแนนที่ถูกหัก</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '15%' }}>ลายมือชื่อครูผู้หักคะแนน</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {currentData.behavior['light'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> ความผิดสถานเบา </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['light'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>{index + 1}</Table.Cell>
                            <Table.Cell>{item.name_th}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                    {currentData.behavior['medium'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> ความผิดสถานกลาง </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['medium'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {currentData.behavior['light'] ? currentData.behavior['light'].length + index + 1 : index + 1}
                            </Table.Cell>
                            <Table.Cell>{item.name_th}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                    {currentData.behavior['heavy'] &&
                      <Wrap>
                        <Table.Row textAlign='center'>
                          <Table.Cell></Table.Cell>
                          <Table.Cell> <b> ความผิดสถานหนัก </b> </Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                          <Table.Cell></Table.Cell>
                        </Table.Row>
                        {currentData.behavior['heavy'].map((item, index) =>
                          <Table.Row>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {currentData.behavior['light'] && currentData.behavior['medium']
                                ? currentData.behavior['light'].length + currentData.behavior['medium'].length + index + 1
                                : currentData.behavior['light']
                                  ? currentData.behavior['light'].length + index + 1
                                  : currentData.behavior['medium']
                                    ? currentData.behavior['medium'].length + index + 1
                                    : index + 1
                              }
                            </Table.Cell>
                            <Table.Cell>{item.name_th}</Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center' }}>
                              {item.checked && item.score}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: 'center', fontSize: '80%' }}>
                              {item.checked && `${bacUserData.firstName} ${bacUserData.lastName}`}
                            </Table.Cell>
                          </Table.Row>
                        )
                        }
                      </Wrap>
                    }
                  </Table.Body>
                  <Table.Footer >
                    <Table.Row>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>รวมคะแนน</Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                      <Table.HeaderCell style={{ backgroundColor: 'lightgrey' }}></Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                <br /><br />

                <b><u>Remark</u></b>
                <p>
                  1. หากศิษย์การบินคนใดถูกหักคะแนนเกินมากกว่า 30 คะแนน จะต้องถูกพิจารณาโทษโดยผู้จัดการแผนกปกครอง<br />
                  2. หากศิษย์การบินคนใดถูกหักคะแนนเกินมากกว่า 80 คะแนน จะถูกระงับการฝึกและเขียนเรียงความแจ้งความความประพฤติให้กับผู้ปกครองหรือต้นสังกัด<br />
                  3. หากศิษย์การบินคนใดถูกหักคะแนนเกินมากกว่า 100 คะแนน จะต้องทำเรื่องเข้าสภาการศึกษาเพื่อพิจารณาโทษ<br />
                  4. การเป็นการกระทำผิดขั้นรุนแรงที่อาจก่อให้เกิดการเสียชีวิต ให้พิจารณาโทษโดยสภาการศึกษาได้ทันที<br />
                  5. การตัดคะแนนในแต่ละหัวข้อต้องมีหลักฐานเพื่อยืนยันความผิดทุกครั้ง ตามแบบฟอร์ม QF-DP-01<br />
                  6. ผู้ที่ตัดคะแนนต้องเซ็นชื่อกำกับการตัดคะแนนในหัวข้อนั้นๆ ทุกครั้ง<br />
                  7. ศิษย์การบินทำความผิดคดีเดิมๆ จะโดนหักคะแนน *2 ทันที ไม่มีข้อยกเว้น<br />
                  8. ศิษย์การบินที่รับโล่ต้องไม่เคยถูกตัดคะแนนความประพฤติ
                </p>
                <br /><br />
              </Wrap>
          }
        </div>

        {currentData.status === "Requested"
          ?
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column textAlign='center'>
                <Button
                  color="primary"
                  onClick={this.approveOffense}
                  loading={loading}
                >
                  Approve
                </Button>
                <Button onClick={this.onClickClose}>Back</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          : currentData.status === "Completed" ?
            <Grid>
              <Grid.Row columns="equal">
                <Grid.Column textAlign='center'>
                  <Button
                    color="primary"
                    onClick={this.onClickShowPenalise}
                    loading={loading}
                  >
                    Penalise
                  </Button>
                  <Button onClick={() => this.setState({ open: false, currentData: null })}>Back</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            : <Grid>
              <Grid.Row columns="equal">
                <Grid.Column textAlign='center'>
                  <Button onClick={() => this.setState({ open: false, currentData: null })}>Back</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
        }

      </Wrap>
    )
  }



}

export default Discipline;
