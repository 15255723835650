import React, { Component } from "react";
import {
  Segment,
  Header,
  Form,
  Button,
  Icon,
  Image,
  Label,
  Loader,
  Table,
} from "semantic-ui-react";
import DayPicker from "../../../components/dayPicker";
import HeaderWithMenu from "../../../components/headerwithmenu";
import * as config from "../../../config";
import { getFTRecordByInstructor } from "../service";
import TableList from "../../../components/table";
import Wrap from "../../../components/wrap";
import { withRouter } from "react-router-dom";
import moment from "moment";

class FlightTrainingRecord extends Component {
  state = {
    rawData: [],
    data: [],
    seletedDate: new Date(),
    loading: true,
    column: [
      {
        Header: "Title",
        accessor: "title",
        // style: { textAlign: "center" },
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            <span
              className="bold"
              style={{ cursor: "pointer" }}
              onClick={() => this.onClickCheckScore(cellInfo.original)}
            >
              Aircraft: {cellInfo.original.aircraftName}
            </span>
            <br />
            <p>
              Subject:{" "}
              {cellInfo.original.subject &&
                `${cellInfo.original.subject.code} (${cellInfo.original.subject.name})`}
            </p>
          </Wrap>
          // <p>{cellInfo.original.title}</p>
        ),
      },
      {
        Header: "Instructor",
        accessor: "instructorName",
        width: 200,
        sortable: false,
        Cell: (cellInfo) => (
          <Wrap>
            {cellInfo.original.instructorName && (
              <span>{cellInfo.original.instructorName}</span>
            )}
          </Wrap>
        ),
      },
      // {
      //   Header: "Aircraft",
      //   accessor: "aircraftName",
      //   width: 200,
      //   sortable: false
      // },
      {
        Header: "Time",
        accessor: "start",
        style: { textAlign: "center" },
        sortable: false,
        width: 250,
        Cell: (cellInfo) => (
          <p>
            {moment(cellInfo.original.start).format("MMMM Do YYYY") ===
            moment(cellInfo.original.end).format("MMMM Do YYYY")
              ? moment(cellInfo.original.start).format(
                  "ddd, MMMM Do YYYY HH:mm"
                ) +
                " - " +
                moment(cellInfo.original.end).format("HH:mm")
              : moment(cellInfo.original.start).format("MMMM Do YYYY, HH:mm") +
                " - " +
                moment(cellInfo.original.end).format("MMMM Do YYYY, HH:mm")}
          </p>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        sortable: false,
        style: { textAlign: "center" },
        width: 150,
        Cell: (cellInfo) => (
          <span>
            {cellInfo.original.status === "SUMMITED" ? (
              <Label
                size="small"
                className="statusLabel"
                style={{
                  backgroundColor: "#FF9800",
                  color: "#FFFFFF",
                  border: "1px solid #FF9800",
                  borderRadius: "14px",
                }}
              >
                SUBMITTED
              </Label>
            ) : cellInfo.original.status === "APPROVED" ? (
              <Label
                size="small"
                className="statusLabel"
                style={{
                  backgroundColor: "#56B9F4",
                  color: "#FFFFFF",
                  border: "1px solid #56B9F4",
                  borderRadius: "14px",
                }}
              >
                APPROVED
              </Label>
            ) : null}
          </span>
        ),
      },
    ],
  };
  onClickCheckScore = (data) => {
    // console.log(data);
    this.props.history.push({
      pathname: "/InstructorTrainRecord/Score",
      state: { id: data.reservationId },
    });
  };
  handleChangeSearch = (e) => {
    console.log(e);
  };
  getFlightTraningRecordAll = (seletedDate) => {
    this.setState({ loading: true });
    let qString = moment(seletedDate).format("YYYY-MM-DD");
    getFTRecordByInstructor("?date=" + qString).then((res) => {
      if (res && res.status === 200) {
        this.setState({ data: res.data });
      }
      this.setState({ loading: false });
    });
  };
  handleChangeDate = (seletedDate) => {
    this.setState({ seletedDate, loading: true });
    this.getFlightTraningRecordAll(seletedDate);
  };
  componentDidMount() {
    this.getFlightTraningRecordAll(this.state.seletedDate);
  }

  render() {
    const state = this.state;
    return (
      <Segment className="contentSegment" textAlign="left">
        <HeaderWithMenu name="Flight Training Record">
          {/* <MainButton
                size='small'
                // onClick={this.show}
                text='Add Ebooks' />
          */}
        </HeaderWithMenu>
        <Form>
          {" "}
          <Form.Group style={{ marginBottom: "0px" }}>
            <Form.Field width="5" style={{ paddingTop: "10px" }}>
              <DayPicker
                day={state.seletedDate}
                onChange={this.handleChangeDate}
              />
            </Form.Field>
            <Form.Field width="8"></Form.Field>
            <Form.Field
              width="5"
              style={{ paddingTop: "4px", textAlign: "right" }}
            ></Form.Field>
          </Form.Group>
        </Form>
        {/*<TableList
          loading={state.loading}
          data={state.data}
          columns={state.column}
        />*/}
        {state.loading ? (
          <Loader active inline="centered" content="Loading" />
        ) : (
          <Table celled padded compact unstackable style={{ fontSize: "95%" }}>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell width="5">Title</Table.HeaderCell>
                <Table.HeaderCell width="3">Student</Table.HeaderCell>
                <Table.HeaderCell width="3">Instructor</Table.HeaderCell>
                <Table.HeaderCell width="3">Time</Table.HeaderCell>
                <Table.HeaderCell width="2">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.data.map((item, index) => (
                <Table.Row>
                  <Table.Cell
                    className="bold"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onClickCheckScore(item)}
                  >
                    Aircraft: {`${item.aircraftName}`}
                    <br />
                    Subject:{" "}
                    {item.subject &&
                      `${item.subject.code} (${item.subject.name})`}
                  </Table.Cell>
                  <Table.Cell>
                    {item.student && `${item.student.studentName || ""}`}
                  </Table.Cell>
                  <Table.Cell>
                    {item.instructorName && `${item.instructorName}`}
                  </Table.Cell>
                  <Table.Cell>
                    <p>
                      {moment(item.start).format("MMMM Do YYYY") ===
                      moment(item.end).format("MMMM Do YYYY")
                        ? moment(item.start).format("ddd, MMMM Do YYYY HH:mm") +
                          " - " +
                          moment(item.end).format("HH:mm")
                        : moment(item.start).format("MMMM Do YYYY, HH:mm") +
                          " - " +
                          moment(item.end).format("MMMM Do YYYY, HH:mm")}
                    </p>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <span>
                      {item.status === "SUBMITTED" ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#FF9800",
                            color: "#FFFFFF",
                            border: "1px solid #FF9800",
                            borderRadius: "14px",
                          }}
                        >
                          {item.status}
                        </Label>
                      ) : item.status === "APPROVED" ? (
                        <Label
                          size="small"
                          className="statusLabel"
                          style={{
                            backgroundColor: "#56B9F4",
                            color: "#FFFFFF",
                            border: "1px solid #56B9F4",
                            borderRadius: "14px",
                          }}
                        >
                          {item.status}
                        </Label>
                      ) : null}
                      {item.subject &&
                      item.subject.checkSubject &&
                      item.status === "APPROVED" &&
                      item.passed === "true" ? (
                        <span>
                          <Label
                            size="small"
                            className="statusLabel"
                            style={{
                              backgroundColor: "#33CC66",
                              color: "#FFFFFF",
                              border: "1px solid #33CC66",
                              borderRadius: "14px",
                              margin: "4px",
                            }}
                          >
                            PASS
                          </Label>
                        </span>
                      ) : (
                        item.subject &&
                        item.subject.checkSubject &&
                        item.status === "APPROVED" &&
                        item.passed === "false" && (
                          <span>
                            <Label
                              size="small"
                              className="statusLabel"
                              style={{
                                backgroundColor: "#ec2d4c",
                                color: "#FFFFFF",
                                border: "1px solid #ec2d4c",
                                borderRadius: "14px",
                                margin: "4px",
                              }}
                            >
                              NOT PASS
                            </Label>
                          </span>
                        )
                      )}
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Segment>
    );
  }
}

export default withRouter(FlightTrainingRecord);
