import React, { Component } from "react";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import LoginPage from "./containers/login";
import ResetPass from "./containers/resetpass";
import LayoutPage from "./layout/layout";
// our stuff
import "bryntum-scheduler/scheduler.stockholm.css";

class App extends Component {
  // window.addEventListener("beforeunload", () => localStorage.clear());
//   componentWillUnmount(){
//     window.addEventListener("beforeunload", () => localStorage.clear());
// ;
//   }

  render() {
    const login = localStorage.getItem("login");
    if(window.location.pathname && window.location.pathname === '/resetpassword')
    {
      return <ResetPass/>
    }
    return <div className="App">{login ? <LayoutPage /> : <LoginPage />}</div>;
  }
}

export default App;
