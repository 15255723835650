import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    Segment,
    Header,
    Card,
    Icon,
    Table,
    Form,
    Button
} from "semantic-ui-react";
import ModalForm from "../../components/modal";
import MainButton from "../../components/mainbutton";
import Confirmmodal from "../../components/confirmmodal";

import * as config from "../../config";
import {
    getGroup,
    postGroup,
    updateGroup,
    getClass,
    postClass,
    updateClass,
    getCompany,
    postCompany,
    updateCompany,
    getHospital,
    postHospital,
    updateHospital,
    deleteClass,
    deleteCompany,
    deleteHospital,
    getStudenttype,
    postStudenttype,
    updateStudenttype,
    deleteStudenttype
} from "./service";
class Settings extends Component {
    state = {
        menu: [
            {
                id: 1,
                icon: "users",
                description: "Class",
                type: "class"
            },
            {
                id: 2,
                icon: "users",
                description: "Student Type",
                type: "studenttype"
            },
            {
                id: 3,
                icon: "building",
                description: "Company Name",
                type: "company"
            },
            {
                id: 4,
                icon: "hospital",
                description: "Hospital",
                type: "hospital"
            }
        ],
        data: []
    };

    getData = type => {
        this.setState({ type, open: true });
        if (type === "group") {
            this.setState({ content: "Group" });
            this.getGroup();
        } else if (type === "class") {
            this.setState({ content: "Class" });
            this.getClass();
        } else if (type === "company") {
            this.setState({ content: "Company" });
            this.getCompany();
        } else if (type === "hospital") {
            this.setState({ content: "Hospital" });
            this.getHospital();
        } else if (type === "studenttype") {
            this.setState({ content: "Student Type" });
            this.getStudenttype();
        }
    };
    getGroup = () => {
        this.setState({ loading: true })
        getGroup().then(res => {
            if (res && res.status === 200)
                this.setState({
                    // open: true,
                    data: res.data,
                    loading: false,
                    loadingUp: false
                });
        });
    };
    getClass = () => {
        this.setState({ loading: true })
        getClass().then(res => {
            if (res && res.status === 200)
                this.setState({
                    // open: true,
                    data: res.data,
                    loading: false,
                    loadingUp: false
                });
        });
    };
    getCompany = () => {
        this.setState({ loading: true })
        getCompany().then(res => {
            if (res && res.status === 200)
                this.setState({
                    // open: true,
                    data: res.data,
                    loading: false,
                    loadingUp: false
                });
        });
    };
    getHospital = () => {
        this.setState({ loading: true })
        getHospital().then(res => {
            if (res && res.status === 200)
                this.setState({
                    // open: true,
                    data: res.data,
                    loading: false,
                    loadingUp: false
                });
        });
    };
    getStudenttype = ()=>{
      this.setState({ loading: true })
      getStudenttype().then(res => {
          if (res && res.status === 200)
              this.setState({
                  // open: true,
                  data: res.data,
                  loading: false,
                  loadingUp: false
              });
      });
    }
    onClickDelete = value => {
        this.setState({
            openConfirm: true,
            currentData: value
        });
    }
    onClickCancel = () => {
        this.setState({ open: false, data: [], editId: null, editName: null });
    };
    saveData = () => {
        let { type, name } = this.state;
        this.setState({ loading: true });
        if (type === "group") {
            postGroup({ name: name }).then(res => {
                if (res && res.status === 201) {
                    this.getGroup();
                    this.setState({ name: null });
                }
            });
        } else if (type === "class") {
            postClass({ name: name }).then(res => {
                if (res && res.status === 201) {
                    this.getClass();
                    this.setState({ name: null });
                }
            });
        } else if (type === "company") {
            postCompany({ name: name }).then(res => {
                if (res && res.status === 201) {
                    this.getCompany();
                    this.setState({ name: null });
                }
            });
        } else if (type === "hospital") {
            postHospital({ name: name }).then(res => {
                if (res && res.status === 201) {
                    this.getHospital();
                    this.setState({ name: null });
                }
            });
        }else if (type === "studenttype") {
            postStudenttype({ name: name }).then(res => {
                if (res && res.status === 201) {
                    this.getStudenttype();
                    this.setState({ name: null });
                }
            });
        }
    };
    updateData = () => {
        let { type, editId, editName } = this.state;
        this.setState({ loadingUp: true });
        if (type === "group") {
            updateGroup({ id: editId, name: editName }).then(res => {
                if (res && res.status === 200) {
                    this.setState({ editId: null, editName: null });

                    this.getGroup();
                }
            });
        } else if (type === "class") {
            updateClass({ id: editId, name: editName }).then(res => {
                if (res && res.status === 200) {
                    this.setState({ editId: null, editName: null });
                    this.getClass();
                }
            });
        } else if (type === "company") {
            updateCompany({ id: editId, name: editName }).then(res => {
                if (res && res.status === 200) {
                    this.setState({ editId: null, editName: null });
                    this.getCompany();
                }
            });
        } else if (type === "hospital") {
            updateHospital({ id: editId, name: editName }).then(res => {
                if (res && res.status === 200) {
                    this.setState({ editId: null, editName: null });
                    this.getHospital();
                }
            });
        }else if (type === "studenttype") {
          updateStudenttype({ id: editId, name: editName }).then(res => {
              if (res && res.status === 200) {
                  this.setState({ editId: null, editName: null });
                  this.getStudenttype();
              }
          });
        }
    };
    deleteData = () => {
        let { type, currentData } = this.state;
        this.setState({ loading: true });
        if(currentData && currentData.id)
        if (type === "group") {
            //
        } else if (type === "class") {
            deleteClass(currentData.id).then(res => {
                if (res && res.status === 200) {
                    this.getClass();
                    this.setState({ openConfirm: false, });
                }
                this.setState({ loading: false });
            });
        } else if (type === "company") {
            deleteCompany(currentData.id).then(res => {
                if (res && res.status === 200) {
                    this.getCompany();
                    this.setState({ openConfirm: false, });
                }
                this.setState({ loading: false });
            });
        } else if (type === "hospital") {
            deleteHospital(currentData.id).then(res => {
                if (res && res.status === 200) {
                    this.getHospital();
                    this.setState({ openConfirm: false, });
                }
                this.setState({ loading: false });
            });
        }else if (type === "studenttype") {
            deleteStudenttype(currentData.id).then(res => {
                if (res && res.status === 200) {
                    this.getStudenttype();
                    this.setState({ openConfirm: false, });
                }
                this.setState({ loading: false });
            });
        }

    };
    handleChange = (e, { name, value }) => this.setState({ [name]: value });
    handleChangeUpdate = (e, { value }) => this.setState({ editName: value });
    render() {
        const state = this.state;
        return (
            <div>
                <Segment className="contentSegment" textAlign="left">
                    <Header as="h2" style={{ color: config.colorTheme }}>
                        Personnel Setting
                    </Header>
                    <Card.Group itemsPerRow="5" doubling style={{ padding: "20px" }}>
                        {state.menu.map(item => (
                            <Card
                                raised
                                key={item.id}
                                style={{ background: "#ededed" }}
                                onClick={() => this.getData(item.type)}
                            >
                                <Card.Content style={{ textAlign: "center" }}>
                                    <Header as="h5" icon style={{ marginBottom: "0px" }}>
                                        <Icon
                                            name={item.icon}
                                            style={{ color: config.colorTheme }}
                                        />
                                        {item.description}
                                    </Header>
                                </Card.Content>
                            </Card>
                        ))}
                    </Card.Group>
                    <br />
                    <br />
                </Segment>

                {/* Group */}
                <ModalForm
                    open={state.open}
                    icon="plane"
                    content={state.content}
                    close={this.onClickCancel}
                >
                    {!state.editId &&   <Form onSubmit={this.saveData}>
                        <Form.Group>
                            <Form.Input
                                placeholder="Group Name"
                                name="name"
                                width={13}
                                onChange={this.handleChange}
                            />
                            <MainButton
                                size="small"
                                width={3}
                                text="Add New"
                                // loading={state.loading}
                                disable={!state.name || state.loading}
                            />
                        </Form.Group>
                    </Form>
                    }
                    <Table compact celled striped style={{ marginBottom: "30px" }}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="header-table" width={1} />
                                <Table.HeaderCell className="header-table" width={10}>
                                    name
                </Table.HeaderCell>
                                <Table.HeaderCell
                                    className="header-table"
                                    textAlign="center"
                                    width={3}
                                />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {state.loading ? (
                                <Table.Row>
                                    <Table.Cell textAlign="right"> </Table.Cell>
                                    <Table.Cell textAlign="left" colSpan="2">
                                        Loading..
                  </Table.Cell>
                                </Table.Row>
                            ) : state.data.map((item, num) => {
                                return (
                                    <Table.Row>
                                        <Table.Cell textAlign="right"> {num + 1} </Table.Cell>
                                        <Table.Cell>
                                            {state.editId === item.id ? (
                                                <Form size="small">
                                                    <Form.Group style={{ marginBottom: "0px" }}>
                                                        <Form.Input
                                                            placeholder="Name"
                                                            name="name"
                                                            width={10}
                                                            value={state.editName}
                                                            onChange={this.handleChangeUpdate}
                                                        />
                                                        <Button
                                                            animated="vertical"
                                                            size="small"
                                                            color="green"
                                                            loading={state.loadingUp}
                                                            onClick={this.updateData}
                                                        >
                                                            <Button.Content hidden>Update</Button.Content>
                                                            <Button.Content visible>
                                                                <Icon name="check" />
                                                            </Button.Content>
                                                        </Button>
                                                        <Button
                                                            size="small"
                                                            animated="vertical"
                                                            onClick={() =>
                                                                this.setState({ editId: null, editName: null })
                                                            }
                                                        >
                                                            <Button.Content hidden>Cancel</Button.Content>
                                                            <Button.Content visible>
                                                                <Icon name="x" />
                                                            </Button.Content>
                                                        </Button>
                                                    </Form.Group>
                                                </Form>
                                            ) : (
                                                    <p
                                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                                        onClick={() =>
                                                            this.setState({
                                                                editId: item.id,
                                                                editName: item.name
                                                            })
                                                        }
                                                    >
                                                        {item.name}
                                                    </p>
                                                )}
                                        </Table.Cell>
                                        <Table.Cell textAlign="center">
                                            <Icon
                                                link
                                                name="edit"
                                                onClick={() => this.setState({
                                                    editId: item.id,
                                                    editName: item.name
                                                })}
                                            />&nbsp; &nbsp;
                                                    <Icon link color="red" name="trash alternate" onClick={() => this.onClickDelete(item)} />
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                </ModalForm>
                <Confirmmodal
                    loading={state.loading}
                    open={state.openConfirm}
                    title={state.type}
                    onClose={() => this.setState({ openConfirm: false })}
                    onComfirm={this.deleteData}
                />
            </div>
        );
    }
}

export default withRouter(Settings);
