import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Segment, Header, List, Button, Icon, Form } from 'semantic-ui-react'
import ActtypeForm from './activitytypeForm'
import HeaderWithMenu from '../../components/headerwithmenu'

import * as config from '../../config'
import MainButton from '../../components/mainbutton'
import ModalForm from '../../components/modal'
import { getActivityTypeList,getActivityTypeDetail } from './service'

class Activitype extends Component {

    state = {
        editData: {},
        data: [],
    }
    showAdd = () => this.setState({ openAdd: true })
    showEdit = (data) => {
        console.log('showEdit',data)
        getActivityTypeDetail(data.id).then(res => {
            if (res && res.status === 200) {
                this.setState({ editData: res.data })
                this.setState({ openEdit: true })
            }
        })
        
    }
    close = () => this.setState({ openAdd: false,openEdit: false })
    onSubmitNewAtvt = (data) => {
        console.log("save", data)
    }
    // editData = (data) => {
    //     console.log("edit", data)
    // }
    componentDidMount() {
        getActivityTypeList().then(res => {
            if (res && res.status === 200) {
                this.setState({ data: res.data })
            }
        })
    }
    
    render() {
        const state = this.state

        return (
            <Segment className="contentSegment" textAlign='left'>
                <HeaderWithMenu name='Activity Type' >
                    <MainButton
                        size='small'
                        onClick={this.showAdd}
                        text='Add New' />
                </HeaderWithMenu>
                <ModalForm
                    icon='add'
                    content='Add Activity Type'
                    open={state.openAdd}
                    close={this.close}>
                    <ActtypeForm g="add" onSubmit={this.onSubmitNewAtvt} onCancel={this.close}/>
                </ModalForm>
                <ModalForm
                    icon='edit'
                    content='Edit Activity Type'
                    open={state.openEdit}
                    close={this.close}>
                    <ActtypeForm g="edit" initialValues={state.editData} onSubmit={this.onSubmitNewAtvt} onCancel={this.close}/>
                </ModalForm>
                <List divided >
                    { state.data.map(item => 
                        <List.Item key={item.id} style={{padding: 20}}>
                            <List.Content floated='right'>
                                <Button size='small' basic style={{marginLeft: 20}} onClick={() => this.showEdit(item)}>Edit</Button>
                            </List.Content>
                            <List.Content>
                                <List.Header as='h4'>{item.name}</List.Header>
                                <List.Description>
                                    Background (reservation, dispatched, completed): &nbsp;&nbsp;
                                    <Icon name="square" style={{ color: item.reservationBackground}}></Icon>
                                    <Icon name="square" style={{ color: item.dispatchedBackground}}></Icon>
                                    <Icon name="square" style={{ color: item.completedBackground}}></Icon>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )}
                </List>
            </Segment>
        )
    }
}

export default withRouter(Activitype)
// export default Activitype = reduxForm({
//     // a unique name for the form
//     form: 'activitype',
//     validate,
//     enableReinitialize: true
// })(Activitype)