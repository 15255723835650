import React from "react";
import { Button } from "semantic-ui-react";
import * as config from "../config";
import PropTypes from "prop-types";

const MainButton = props => {
  const { size, onClick, text, fluid, loading, disable } = props;
  return (
    <Button
      style={{ backgroundColor: config.colorTheme, color: "#ffffff" }}
      size={size}
      fluid={fluid}
      loading={loading}
      disabled={disable}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

MainButton.propTypes = {
  text: PropTypes.string.isRequired
};

export default MainButton;
