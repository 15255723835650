import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Segment, Header, Form, Button, Grid, Image, Label, Icon, Table } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form'
import * as config from '../../config'
import validate from '../../validate'
import HeaderWithMenu from '../../components/headerwithmenu'
// import TableList from '../../components/table'
import TableList from "../../components/tablePerPage";
import MainButton from '../../components/mainbutton'
import ModalForm from '../../components/modal'
import Wrap from '../../components/wrap'
import {
    getAllCompany, getAllGroup, getStaffListBySearch, getInstructorsWork,
    getInstructorTypes, getInstructorsEvent, postPersonnel,
    postInstructors
} from './service'
import FormInstructors from './staffForm'
import DropdownCheckbox from '../../components/dropdownCheckbox'
import ScheduleById from '../Schedule/scheduleById'

class Staff extends Component {

    state = {
        current_page: 0,
        per_page: 10,
        last_page: 1,
        open: false,
        openWorkShd: false,
        loading: true,
        clickState: '',
        rawData: [],
        data: [],
        dataEvent: [],
        btnLoading: false,
        search: {
            status: 'Active',
            group: '',
            sort: { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC ' },
            worktime: '',
            categories: '',
            name: null
        },
        options_group: [],
        options_instructortype: [],
        checked_instructortype: [],
        instructortype: [],
        sortText: 'Name [ASC]',
        options_sortby: [
            { key: 'firstName', text: 'Name', value: '1', icon: 'sort alphabet ascending', description: 'ASC' },
            { key: 'firstName', text: 'Name', value: '2', icon: 'sort alphabet descending', description: 'DESC' },
        ],
        column: [{
            Header: 'Image',
            accessor: 'image',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Image avatar
                    size='mini' verticalAlign='middle'
                    src={cellInfo.original.image && cellInfo.original.image+'?time='+ new Date()}
                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://app.flightschedulepro.com/images/instructor-default.gif' }}
                />
            ),
            width: 60,
        }, {
            Header: 'Name',
            accessor: 'description',
            sortable: false,
            // width: 280,
            Cell: cellInfo => (
                <Wrap>
                    <span className="bold" style={{ cursor: 'pointer' }} onClick={() => this.onClickProfile(cellInfo.original)}>{cellInfo.original.description}</span><br />
                    <p>ตำแหน่ง : {cellInfo.original.position || '-'}</p>
                </Wrap>
            )
        },
        {
            Header: 'Phone',
            accessor: 'phone',
            sortable: false,
            width: 100,
            Cell: cellInfo => (
                <Wrap>
                    <p>{cellInfo.original.phone}</p>
                </Wrap>
            )
        }, {
            Header: 'Email',
            accessor: 'email',
            sortable: false,
            style: { textAlign: 'center' },
            Cell: cellInfo => (
                <span>
                    {
                        cellInfo.original.email
                            ? <Icon link circular color='red' name='mail' />
                            : <Icon circular color='grey' name='mail' />
                    }

                </span>
            ),
            width: 70,
        }, {
            Header: 'Action',
            accessor: 'id',
            style: { textAlign: 'center' },
            sortable: false,
            Cell: cellInfo => (
                <Wrap>
                    <Icon link circular color='orange' name='user' onClick={() => this.onClickProfile(cellInfo.original)} />
                </Wrap>
            ),
            width: 100,
        }
        ],

    }
    componentDidMount() {
        let { search } = this.state
        let qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        this.getData(qString)
    }

    handleChangeSort = (e, data) => {
        let { search } = this.state
        let qString

        const result = data.options.find((member) => {
            return member.value === data.value
        })
        search['sort'] = result
        this.setState({ search, sortText: `${result.text} (${result.description})` })

        // qString = '?&_sort=' + search.sort.key + '&_order=' + search.sort.description
        qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
          qString += '&name=' + search.name
        }
        this.getData(qString)
    }

    handleChangeStatus = (e, data) => {
        let { search } = this.state
        let qString

        search['status'] = data.value // "Active/Inactive"
        this.setState({ search })
        qString = '?status=' + data.value
        if (search['name']) {
          qString += '&name=' + search.name
        }
        if (search['sort'])
            qString += '&_sort=' + search.sort.key + '&_order=' + search.sort.description

        this.getData(qString)
    }

    handleChangeSearch = (e) => {
        let { rawData, search } = this.state;
        let active;
        this.setState({loading:true})
        if(this._timeout){ //if there is already a timeout in process cancel it
          clearTimeout(this._timeout);
        }
        const val = e.target.value;
        const name = e.target.name
        this._timeout = setTimeout(()=>{
           this._timeout = null;
           search[name] = val
           let qString = "?name="+ val

           if (search['sort'])
               qString += '&status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
           this.getData(qString)

        },2000);
    }



    getData = (qString) => {
        this.setState({ loading: true })
        let { per_page, current_page, search } = this.state;
        current_page = current_page + 1;
        qString = qString + "&_perpage=" + per_page + "&page=" + current_page;
        getStaffListBySearch(qString).then(res => {
            if (res && res.status === 200) {
              // console.log('getStaffListBySearch',res.data)
                let newdata = []
                res.data.data.map(item => {
                    let description = `${item.staffID || ''} ${item.firstNameEng} ${item.lastNameEng}`
                    newdata.push({
                        id: item.id,
                        image: item.imageURL || [],
                        description: description,
                        email: item.email,
                        phone: item.phone,
                        position: item.position,
                        roles: item.rolesName || []
                    })
                })
                this.setState({ data: newdata, rawData: res.data.data, current_page:res.data.current_page -1 ,last_page:res.data.last_page })
            }
            this.setState({ loading: false })
        })
    }

    show = () => this.setState({ open: true })
    close = () => this.setState({ open: false, openWorkShd: false })

    onClickProfile = (data) => {
        // console.log(data)
        this.props.history.push({
            pathname: '/Staffs/Profile',
            state: { id: data.id }
        })
    }

    render() {

        let state = this.state
        return (
            <Segment className="contentSegment" textAlign='left'>
                {/* <Header as='h2' style={{ color: config.colorTheme }}>Instructors</Header> */}
                <HeaderWithMenu name='Staffs' >
                    {/* วานีปิดให้ 12/11/2019 */}
                    {/* <MainButton
                        size='small'
                        onClick={this.show}
                        text='Add Instructors' /> */}
                </HeaderWithMenu>
                <Form>
                    <Form.Group>
                        <Form.Input
                            width='3'
                            icon='search'
                            size='small'
                            placeholder='Search'
                            name='name'
                            onChange={this.handleChangeSearch} />
                        <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            // width='3'
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Sort by : <b>{state.sortText}</b>
                            </Button>}
                            options={state.options_sortby}
                            defaultValue={state.search.sort}
                            selectOnBlur={false}
                            onChange={this.handleChangeSort}
                        />
                      <Form.Dropdown
                            icon={null}
                            fluid
                            floating
                            // width='3'
                            style={{ width: '100%', marginRight: '30px' }}
                            trigger={<Button basic icon fluid size='small' labelPosition='right' style={{ textAlign: 'left' }}>
                                <Icon name='angle down' />
                                Status : <b>{state.search.status}</b>
                            </Button>}
                            options={config.options_active}
                            defaultValue={state.search.status}
                            selectOnBlur={false}
                            onChange={this.handleChangeStatus}
                        />
                    </Form.Group>
                </Form>
                <ModalForm
                    icon='calendar alternate'
                    content='Work Schedule'
                    size='large'
                    open={state.openWorkShd}
                    close={this.close}  >
                    <Wrap>
                        <ScheduleById id={state.currentData ? state.currentData.id : null} disableClick/>
                    </Wrap>
                </ModalForm>
                <ModalForm
                    icon='add user'
                    content='Add Instructor'
                    open={state.open}
                    close={this.close}  >
                    <FormInstructors
                        isAdd={true}
                        loading={state.btnLoading}
                        onSubmit={this.onSubmitAddInstructors}
                        onCancel={this.close}
                        options_instructortype={state.options_instructortype}
                    />
                </ModalForm>
                <TableList
                    data={state.data}
                    columns={state.column}
                    loading={state.loading}
                    rowClick={this.onClickProfile}
                    pageSize={state.per_page}
                    page={state.current_page}
                    pages={state.last_page}
                    onPageChange={this.onPageChange}
                    onPageSizeChange={this.onPageSizeChange}
                />
            </Segment>
        )
    }
    onPageSizeChange = (pageSize, pageIndex) => {
      this.setState({
        per_page: pageSize,current_page : pageIndex
      }, () => {
        let { search } = this.state
        let qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
          qString += '&name= '+ search.name
        }
        this.getData(qString)
      });
    };

    onPageChange = pageIndex => {
      this.setState({
      current_page : pageIndex
      }, () => {
        let { search } = this.state
        let qString = '?status=' + search.status + '&_sort=' + search.sort.key + '&_order=' + search.sort.description
        if (search['name']) {
          qString += '&name= '+ search.name
        }
        this.getData(qString)
      });

    };
}

export default withRouter(Staff)
// export default Instructors = reduxForm({
//     // a unique name for the form
//     form: 'instructors',
//     validate,
//     enableReinitialize: true

// })(Instructors)
