import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Grid, Header, Dimmer, Loader, Card,  Icon ,Button} from "semantic-ui-react";
import * as config from "../config";
import { confirmResetPassword} from "./service";

class Resetpass extends Component {

  state = {
    loading:true,
    errormsg: null,
    success : false
  };

  componentDidMount() {
    if(this.props.location && this.props.location.search ){
      const query = new URLSearchParams(this.props.location.search);
      const token = query.get('token')
      const username = query.get('username')
      confirmResetPassword({
        "token" : token,
        "username" : username
      }).then(res => {
        if(res && res.status === 200){
          this.setState({
              loading:false,
              success : true,
              name:res.data.name
          })
        }else {
          this.setState({
              loading:false,
              success : false,
              errormsg : res.data.message
          })
        }
      })
    }else{
      this.props.history.push('/')
    }
  }


  render() {
    const { success, errormsg,loading,name } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{
          height: "100vh",
          backgroundColor: config.colorTheme,
          margin: "0px"
        }}
      >
        <Grid.Column
          textAlign="center"
          computer="5"
          tablet="8"
          mobile="14"
          verticalAlign="middle"
        >
          <Card fluid raised>
            {success
              ?  <Card.Content style={{padding: '60px'}}>
                  {loading
                    ?  <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                      </Dimmer>
                    : <Header as='h2' icon style={{color:'green'}} >
                       <Icon name='check circle outline' />
                       PASSWORD RESET SUCCESSFULLY!!!
                       <Header.Subheader>  <br/>
                        <b>Hello {name}</b>
                        <br/>  <br/>  <br/>
                        Your password has been reset to your date of birth (DDMMYYYY). <a onClick={() => this.props.history.push('/')}>
                         Please LOGIN again.
                      </a>
                       </Header.Subheader>
                     </Header>
                   }

                </Card.Content>
              :
              <Card.Content style={{padding: '60px'}}>
                {loading
                  ?  <Dimmer active inverted>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                  : <Header as='h2' icon style={{color:'red'}} >
                     <Icon name='times circle outline' />
                     {errormsg}
                     <Header.Subheader>
                       <a onClick={() => this.props.history.push('/')}>
                        Try again?
                     </a>
                     </Header.Subheader>
                   </Header>
                 }

              </Card.Content>
             }
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(Resetpass);
