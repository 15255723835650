import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon, Button, Form, Menu, Segment, Dropdown } from "semantic-ui-react";
import * as config from "../../config";
import BryntumScheduler from "../../components/BryntumScheduler";
import VeriCalendar from "../../components/veriCalendar";
import DropdownCheckbox from "../../components/dropdownCheckbox";
import DayPicker from "../../components/dayPicker";
import Confirmmodal from "../../components/confirmmodal";
import Confirmmodalwithcomment from "../../components/confirmmodalwithcomment";
import Warningmodal from "../../components/warningmodal";

import {
  getEventBySearch,
  getEventByIdBySearch,
  getEventDetail,
  getAircraftListBySearch,
  getInstructorsListBySearch,
  getRoomListBySearch,
  getReservationTypes,
  postEvent,
  updateEvent,
  deleteEvent,
  cancelEvent,
  postEventAttendant,
  getAirport,
} from "./service";
import moment from "moment";
import Wrap from "../../components/wrap";
import ModalForm from "../../components/modal";
import EventDetailForm from "./eventDetail";
import ReservForm from "./reservationForm";
//  location ยึดตามเครื่องบิน Routes ยึดตามอะไร

class Schedule extends Component {
  state = {
    view: localStorage.getItem("ScheduleView") || "Day Horizontal",
    activeItem: "All Schedule",
    resources: [],
    events: [],
    // seletedDate: new Date(),
    seletedDate: new Date(),
    reservationtypes: [],
    options_reservationtypes: [],
    options_aircraft: [],
    options_inst: [],
    options_room: [],
    options_airport: [],
    filterByReservationText: "All activity types",
    filterByAircraftText: "All aircraft",
    filterByInstructorText: "All instructor",
    filterByRoomText: "All room",
    filterByDepartureText: "Departure",
    filterByDestinationText: "Destination",
    checked_aircraft: [],
    checked_room: [],
    checked_inst: [],
    checked_departure: [],
    checked_destination: [],
    loading: true,
    start: 6,
    option_start: config.time,
    stop: 21,
    option_stop: config.time,
    canManage: false,
    bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
  };

  async componentDidMount() {
    if (this.checkPermissionMenu("Schedule", ["manage-schedule"]))
      this.setState({ canManage: true });

    let aircraftoption = [],
      checked_aircraft = [];
    await getAircraftListBySearch("?isActive=true").then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          aircraftoption.push({
            key: item.id,
            id: item.id,
            title: `${item.tailNumber} ${item.schedulingGroupName}`,
            text: `${item.tailNumber} ${item.schedulingGroupName}`,
            value: item.id,
          });
          checked_aircraft.push(item.id);
        });
        this.setState({
          options_aircraft: aircraftoption,
          checked_aircraft,
        });
      }
    });
    let option = [],
      checked_inst = [];
    await getInstructorsListBySearch("?isActive=Active").then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            id: item.id,
            title: `${item.firstName} ${item.lastName}`,
            text: `${item.firstName} ${item.lastName}`,
            value: item.id,
          });
          checked_inst.push(item.id);
        });
        this.setState({
          options_inst: option,
          checked_inst,
        });
      }
    });
    let checked_room = [];
    option = [];
    await getRoomListBySearch("?isActive=true").then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            id: item.id,
            title: `${item.locationName} - ${item.name}`,
            text: `${item.locationName} - ${item.name}`,
            value: item.id,
          });
          checked_room.push(item.id);
        });
        this.setState({
          options_room: option,
          checked_room,
        });
      }
    });

    let checked_reservationtypes = [],
      options = [];
    await getReservationTypes().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          options.push({ key: item.id, text: item.name, value: item.id });
          checked_reservationtypes.push(item.id);
        });
        this.setState({
          options_reservationtypes: options,
          reservationtypes: res.data,
          checked_reservationtypes,
        });
      }
    });

    if (this.state.view === "month" || this.state.view === "agenda") {
      this.getEvent();
    } else this.setQStringForgetEvents(this.state.activeItem);
    // await this.setQStringForgetEvents(this.state.activeItem)
    // this.getOptionLocation()
    this.getOptionAirport();
    let option_stop = [];
    this.state.option_start.map((item) => {
      if (item.value > this.state.start) option_stop.push(item);
    });
    this.setState({ option_stop });
  }

  checkPermissionMenu = (group, permission) => {
    let { bac_permission } = this.state;
    let userpermissionGroup = bac_permission.find(
      (item) => item.group === group
    );
    let allowed = permission.find((item) => {
      if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
    });
    if (allowed) return true;
    else return false;
  };
  getOptionAirport = () => {
    let option = [],
      checked_airport = [];

    getAirport().then((res) => {
      if (res && res.status === 200) {
        res.data.map((item) => {
          option.push({
            key: item.id,
            id: item.id,
            title: item.name,
            text: item.name,
            value: item.id,
          });
          checked_airport.push(item.id);
        });
      }
      this.setState({
        options_airport: option,
        checked_departure: checked_airport,
        checked_destination: checked_airport,
      });
    });
  };

  getEvent = (activeItem) => {
    const {
      checked_reservationtypes,
      checked_aircraft,
      checked_room,
      checked_inst,
      checked_departure,
      checked_destination,
      options_airport,
      seletedDate,
    } = this.state;
    let filterItem = "?",
      filterDate = "";
    filterDate = "start_like=" + moment(seletedDate).format("YYYY-MM");
    checked_reservationtypes &&
      checked_reservationtypes.map((item) => {
        filterItem = filterItem + "reservationType[]=" + item + "&";
      });

    if (activeItem === "Instructors") {
      checked_inst &&
        checked_inst.map((item) => {
          filterItem = filterItem + "instructor[]=" + item + "&";
        });
    } else if (activeItem === "Flight") {
      checked_aircraft &&
        checked_aircraft.map((item) => {
          filterItem = filterItem + "aircraft[]=" + item + "&";
        });

      if (options_airport && checked_departure) {
        if (options_airport.length !== checked_departure.length) {
          checked_departure.map((item) => {
            filterItem = filterItem + "departure[]=" + item + "&";
          });
        }
      }
      if (options_airport && checked_destination) {
        if (options_airport.length !== checked_destination.length) {
          checked_destination.map((item) => {
            filterItem = filterItem + "destination[]=" + item + "&";
          });
        }
      }
    } else if (activeItem === "Rooms") {
      checked_room &&
        checked_room.map((item) => {
          filterItem = filterItem + "room[]=" + item + "&";
        });
    } else {
      checked_aircraft &&
        checked_aircraft.map((item) => {
          filterItem = filterItem + "aircraft[]=" + item + "&";
        });
      // checked_departure &&
      //   checked_departure.map((item) => {
      //     filterItem = filterItem + "departure[]=" + item + "&";
      //   });
      // checked_destination &&
      //   checked_destination.map((item) => {
      //     filterItem = filterItem + "destination[]=" + item + "&";
      //   });
      checked_room &&
        checked_room.map((item) => {
          filterItem = filterItem + "room[]=" + item + "&";
        });
    }

    getEventByIdBySearch(filterItem + filterDate).then((res) => {
      if (res && res.status === 200) {
        this.setState({ events: res.data, loading: false });
      }
    });
  };

  setQStringForgetEvents = (activeItem) => {
    const {
      checked_reservationtypes,
      checked_aircraft,
      checked_room,
      checked_inst,
      checked_departure,
      checked_destination,
      options_airport,
      seletedDate,
      view,
    } = this.state;
    let filterItem = "?",
      filterDate =
        "start_lte=" +
        moment(seletedDate).add(1, "days").format("YYYY-MM-DD") +
        "&end_gte=" +
        moment(seletedDate).format("YYYY-MM-DD");
    checked_reservationtypes &&
      checked_reservationtypes.map((item) => {
        filterItem = filterItem + "reservationType[]=" + item + "&";
      });

    if (activeItem === "Instructors") {
      checked_inst &&
        checked_inst.map((item) => {
          filterItem = filterItem + "instructor[]=" + item + "&";
        });
    } else if (activeItem === "Rooms") {
      checked_room &&
        checked_room.map((item) => {
          filterItem = filterItem + "room[]=" + item + "&";
        });
    } else {
      checked_aircraft &&
        checked_aircraft.map((item) => {
          filterItem = filterItem + "aircraft[]=" + item + "&";
        });

      if (options_airport && checked_departure) {
        if (options_airport.length !== checked_departure.length) {
          checked_departure.map((item) => {
            filterItem = filterItem + "departure[]=" + item + "&";
          });
        }
      }
      if (options_airport && checked_destination) {
        if (options_airport.length !== checked_destination.length) {
          checked_destination.map((item) => {
            filterItem = filterItem + "destination[]=" + item + "&";
          });
        }
      }

      checked_room &&
        checked_room.map((item) => {
          filterItem = filterItem + "room[]=" + item + "&";
        });
    }

    // if (view === 'month') filterDate = "start_like=" + moment(seletedDate).format('YYYY-MM')
    // else
    if (view === "2 Day Horizontal")
      filterDate =
        "start_lte=" +
        moment(seletedDate).add(2, "days").format("YYYY-MM-DD") +
        "&end_gte=" +
        moment(seletedDate).format("YYYY-MM-DD");
    else if (view === "3 Day Horizontal")
      filterDate =
        "start_lte=" +
        moment(seletedDate).add(3, "days").format("YYYY-MM-DD") +
        "&end_gte=" +
        moment(seletedDate).format("YYYY-MM-DD");
    else if (view === "week") {
      filterDate =
        "start_lte=" +
        moment(seletedDate).add(7, "days").format("YYYY-MM-DD") +
        "&end_gte=" +
        moment(seletedDate).format("YYYY-MM-DD");
    }
    switch (activeItem) {
      case "Flight":
        this.setState({
          qString: `${filterItem}${filterDate}&resources=flight`,
        });
        break;
      case "Instructors":
        this.setState({
          qString: `${filterItem}${filterDate}&resources=instructors`,
        });
        break;
      case "Rooms":
        this.setState({ qString: `${filterItem}${filterDate}&resources=room` });
        break;
      case "All Schedule":
        this.setState({ qString: `${filterItem}${filterDate}&resources=all` });
        break;
      default:
        this.setState({ qString: `${filterItem}${filterDate}&resources=all` });
        break;
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 100);
  };

  getDetail = (id) => {
    getEventDetail(id).then((res) => {
      if (res && res.status === 200)
        this.setState({ eventDetail: res.data, open: true });
    });
  };

  onClickNew = () => {
    this.setState({
      content: "New Reservation",
      openNewReserv: true,
      isNew: true,
      initialvaluesReserv: {
        flightRules: "VFR",
        // language: 'TH',
        courseType: "Full Time",
        start: moment().minutes(0).seconds(0).format("DD-MM-YYYY HH:mm"),
        end: moment()
          .add(1, "hours")
          .minutes(0)
          .seconds(0)
          .format("DD-MM-YYYY HH:mm"),
      },
    });
  };
  onClickCancel = () => {
    this.setState({
      openConfirm: false,
      openConfirmCancel: false,
      openConfirmUpdate: false,
      openConfirmDelete: false,

      open: false,
      openNewReserv: false,
      isNew: false,
      eventDetail: null,
      initialvaluesReserv: null,
    });
  };

  onClickCancelReservation = (value) => {
    this.setState({
      openConfirmCancel: true,
      currentDataDetele: value,
    });
  };
  onClickDeleteReservation = (value) => {
    if (value.reservationTypeName === "Ground training") {
      this.setState({
        openConfirmDelete: true,
        currentDataDetele: value,
      });
    } else {
      this.setState({
        openConfirm: true,
        currentDataDetele: value,
      });
    }
    // this.setState({
    //   openConfirm: true,
    //   currentDataDetele: value,
    // });
  };
  cancelData = (comment) => {
    let { type, currentDataDetele } = this.state;
    this.setState({ loading: true });
    if (currentDataDetele && currentDataDetele.id)
      cancelEvent(currentDataDetele.id, { comment: comment }).then((res) => {
        if (res && res.status === 200) {
          this.setState({ loading: true });
          this.setState({ openConfirm: false });
          if (this.state.view === "month" || this.state.view === "agenda") {
            this.getEvent(this.state.activeItem);
          } else this.setQStringForgetEvents(this.state.activeItem);
        }
        this.onClickCancel();
      });
  };
  deleteData = (comment) => {
    let { type, currentDataDetele } = this.state;
    this.setState({ loading: true });
    if (currentDataDetele && currentDataDetele.id)
      deleteEvent(currentDataDetele.id, null).then((res) => {
        if (res && res.status === 200) {
          this.setState({ openConfirm: false });
          if (this.state.view === "month" || this.state.view === "agenda") {
            this.getEvent(this.state.activeItem);
          } else this.setQStringForgetEvents(this.state.activeItem);
        }
        this.onClickCancel();
      });
  };
  onEditReserv = (value) => {
    let initialvaluesReserv = {
      id: value.id,
      reservationType: {
        id: value.reservationTypeId,
        name: value.reservationTypeName,
        displayType: value.displayType,
      },
      start: moment(value.start).format("DD-MM-YYYY HH:mm"),
      end: moment(value.end).format("DD-MM-YYYY HH:mm"),
      reservationName: value.title || null,
      status: value.status  ,
      aircraft: value.aircraftId || null,
      room: value.roomId || null,
      instructor: value.instructorId || null,
      comments: value.comments || null,
    };
    if (value.displayType === 0) {
      initialvaluesReserv.flightName = value.title;
      initialvaluesReserv.flightSubject = value.subject_id;
      initialvaluesReserv.flightSubjectsAdd = value.subject_id;
      initialvaluesReserv.studentId =
        value.attendees && value.attendees.length > 0 && value.attendees[0].id;
      initialvaluesReserv.departureId =
        value.flightData && value.flightData.departureId;
      initialvaluesReserv.destinationId =
        value.flightData && value.flightData.destinationId;
      initialvaluesReserv.area = value.flightData && value.flightData.area;

      initialvaluesReserv.customername = value.customername;

      // test group
      if (value.flightData.testengine === "true") {
        initialvaluesReserv.flightType = "testengine";
      } else if (value.flightData.testflight === "true") {
        initialvaluesReserv.flightType = "testflight";
      } else if (value.flightData.testnewaircraft === "true") {
        initialvaluesReserv.flightType = "testnewaircraft";
      }
      if (value.flightData.nightflight === "true") {
        initialvaluesReserv.nightFlight = "nightflight";
      }
      if (value.flightData.ipflight === "true") {
        initialvaluesReserv.flightTypeIPSV = "ipflight";
      }
      if (value.flightData.sim === "true") {
        initialvaluesReserv.flightTypeSim = "sim";
      } else if (value.flightData.simA320 === "true") {
        initialvaluesReserv.flightTypeSim = "simA320";
      }

      if (value.flightData.instructorSV && value.flightData.instructorSV.id) {
        initialvaluesReserv.flightTypeIPSV = "svflight";
        initialvaluesReserv.instructorSVId = value.flightData.instructorSV.id;
      } else if (
        value.flightData.instructorIP &&
        value.flightData.instructorIP.id
      ) {
        initialvaluesReserv.flightTypeIPSV = "ipflight";
        initialvaluesReserv.instructorIPId = value.flightData.instructorIP.id;
      } else if (
        value.flightData.instructorPro &&
        value.flightData.instructorPro.id
      ) {
        initialvaluesReserv.flightTypeIPSV = "ipproficiency";
        initialvaluesReserv.instructorProId = value.flightData.instructorPro.id;
      }

      let IPObserver = [];
      if (
        value.flightData.instructorObserver &&
        value.flightData.instructorObserver.id
      ) {
        initialvaluesReserv.instructorObserver = null;
        IPObserver.push(value.flightData.instructorObserver.id);
      }
      if (value.IPObserver) {
        value.IPObserver.map((item) => IPObserver.push(item.id));
      }
      initialvaluesReserv.IPObserver = IPObserver;

      if (value.flightData.monitorflight === "true") {
        initialvaluesReserv.flightTypeMonitor = "monitor";
        initialvaluesReserv.monitorId = value.flightData.monitor_id;
      }
      initialvaluesReserv.flightRules = value.flightData.flightRules;

      let SPObserver = [];
      if (value.observer && value.observer.id) {
        initialvaluesReserv.studentObserver = null;
        SPObserver.push(value.observer.id);
      }
      if (value.SPObserver) {
        value.SPObserver.map((item) => SPObserver.push(item.id));
      }
      initialvaluesReserv.SPObserver = SPObserver;

      initialvaluesReserv.callSign = value.flightData.callSign;
      initialvaluesReserv.flightSubjects = value.subject && {
        id: value.subject.subjectId,
        hours: value.subject.subjectHours,
        course: value.course_id,
      };
    } else if (value.displayType === 2) {
      // ground
      let attendees = [],
        additionalAttendees = [];
      // console.log(value,"grounf");
      value.attendees.map((item) => {
        if (item.additional) {
          additionalAttendees.push(item.id);
        } else {
          attendees.push(item.id);
        }
      });
      initialvaluesReserv.studentId = attendees;
      initialvaluesReserv.additionalStudentId = additionalAttendees;
      initialvaluesReserv.courseType = value.courseType;
      initialvaluesReserv.courseId = value.course_id;
      initialvaluesReserv.material = value.material;
      if (value.testSubject === "true") {
        initialvaluesReserv.testSubject = "testSubject";
      }

      initialvaluesReserv.groundSubjects = {
        id: value.subject.subjectId,
        hours: value.subject.subjectHours,
      };

      let IPObserver = [];
      if (value.IPObserver) {
        value.IPObserver.map((item) => IPObserver.push(item.id));
      }
      initialvaluesReserv.IPObserver = IPObserver;

      let SPObserver = [];
      if (value.SPObserver) {
        value.SPObserver.map((item) => SPObserver.push(item.id));
      }
      initialvaluesReserv.SPObserver = SPObserver;

      // initialvaluesReserv.attendees = value.attendees
      initialvaluesReserv.instructor = value.instructorId;
      initialvaluesReserv.courseType = value.courseType;
    } else if (value.displayType === 3) {
      let attendees = [];
      // initialvaluesReserv.attendees = value.attendees
      value.attendees.map((item) => attendees.push(item.id));
      initialvaluesReserv.studentId = attendees;
      initialvaluesReserv.instructor = value.instructorId;
    }
    this.setState({
      open: false,
      isNew: false,
      content: `Reservation #${value.reservationNumber}`,
      openNewReserv: true,
      initialvaluesReserv: initialvaluesReserv,
    });
  };
  onSubmitReserv = (value) => {
    this.setState({ loadingSave: true });
    postEvent(value).then((res) => {
      if (res && res.status === 201) {
        this.setState({ loading: true });
        if (this.state.view === "month" || this.state.view === "agenda") {
          this.getEvent(this.state.activeItem);
        } else this.setQStringForgetEvents(this.state.activeItem);
        this.onClickCancel();
      } else if (res && res.status === 422) {
        this.setState({
          openWarming: true,
          warningdata: res.data.reservations,
        });
      } else {
        this.onClickCancel();
      }
      this.setState({ loadingSave: false });
    });
  };

  confirmUpdateReserv = (value) => {
    this.setState({ loadingSave: true });
    if (
      value.reservationType &&
      value.reservationType.name === "Ground training"
    ) {
      this.setState({
        openConfirmUpdate: true,
        loadingSave: false,
        tmpValue: value,
      });
    } else {
      this.setState(
        {
          tmpValue: value,
        },
        () => {
          this.onUpdateReserv("");
        }
      );
      // this.onUpdateReserv(value);
    }
    // this.setState({ tmpValue: value });
    // this.setState({ loadingSave: true });
    // this.setState({ openConfirmUpdate: true });

    // setTimeout(() => {
    // this.setState({ loadingSave: false });
    //   this.setState({ openConfirmUpdate: true });
    // }, 1500);
  };
  onUpdateReserv = (comment) => {
    let { tmpValue } = this.state;
    tmpValue["edit_comment"] = comment;
    this.setState({
      loadingSave: true,
      loading: true,
      openConfirmUpdate: false,
    });
    updateEvent(tmpValue).then((res) => {
      if (res && res.status === 200) {
        this.setState({ loading: true });
        if (this.state.view === "month" || this.state.view === "agenda") {
          this.getEvent(this.state.activeItem);
        } else this.setQStringForgetEvents(this.state.activeItem);
        this.onClickCancel();
      } else if (res && res.status === 422) {
        this.setState({
          openWarming: true,
          warningdata: res.data.reservations,
        });
      } else {
        this.onClickCancel();
      }
      this.setState({
        loadingSave: false,
        loading: false,
        openConfirmUpdate: false,
        tmpValue: null,
      });
    });
  };
  render() {
    const state = this.state;
    return (
      <div>
        <Warningmodal
          loading={state.loading}
          open={state.openWarming}
          title="Reservation"
          data={state.warningdata}
          onClose={() =>
            this.setState({ openWarming: false, warningdata: null })
          }
        />
        <Confirmmodalwithcomment
          loading={state.loading}
          open={state.openConfirmCancel}
          title="Reservation"
          actionText="cancel"
          onClose={() => this.setState({ openConfirmCancel: false })}
          onComfirm={this.cancelData}
        />
        <Confirmmodalwithcomment
          loading={state.loading}
          open={state.openConfirmDelete}
          title="Reservation"
          actionText="delete"
          onClose={() => this.setState({ openConfirmDelete: false })}
          onComfirm={this.deleteData}
        />
        <Confirmmodal
          loading={state.loading}
          open={state.openConfirm}
          title="Reservation"
          onClose={() => this.setState({ openConfirm: false })}
          onComfirm={this.deleteData}
        />
        <Confirmmodalwithcomment
          loading={state.loading}
          open={state.openConfirmUpdate}
          title="Reservation"
          actionText="edit"
          onClose={() => this.setState({ openConfirmUpdate: false })}
          onComfirm={this.onUpdateReserv}
        />

        <Segment className="contentSegment" textAlign="left">
          <Menu widths="4" style={{ marginTop: "0px" }}>
            <Menu.Item
              name="All Schedule"
              active={state.activeItem === "All Schedule"}
              onClick={this.handleItemClick}
            >
              All Schedule
            </Menu.Item>
            <Menu.Item
              name="Flight"
              active={state.activeItem === "Flight"}
              onClick={this.handleItemClick}
            >
              Flight
            </Menu.Item>
            <Menu.Item
              name="Rooms"
              active={state.activeItem === "Rooms"}
              onClick={this.handleItemClick}
            >
              Rooms
            </Menu.Item>
            <Menu.Item
              name="Instructors"
              active={state.activeItem === "Instructors"}
              onClick={this.handleItemClick}
            >
              Instructors
            </Menu.Item>
          </Menu>
          <Form>
            <Form.Group>
              <Form.Field width="3">
                <DropdownCheckbox
                  style={{ textAlign: "left" }}
                  checked={state.checked_reservationtypes}
                  options={state.options_reservationtypes}
                  onClick={this.handleFilterByReservationtypes}
                >
                  <Button
                    fluid
                    size="small"
                    icon
                    basic
                    labelPosition="right"
                    style={{ textAlign: "left" }}
                  >
                    {state.filterByReservationText}
                    <Icon name="angle down" />
                  </Button>
                </DropdownCheckbox>
              </Form.Field>
              {state.activeItem === "All Schedule" ? (
                <Wrap>
                  <Form.Field width="3">
                    <DropdownCheckbox
                      checked={state.checked_aircraft}
                      options={state.options_aircraft}
                      onClick={this.handleFilterByAircraft}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByAircraftText}
                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                  {/* <Form.Field width="2">
                    <DropdownCheckbox
                      checked={state.checked_departure}
                      options={state.options_airport}
                      onClick={this.handleFilterByDeparture}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByDepartureText}

                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                  <Form.Field width="2">
                    <DropdownCheckbox
                      checked={state.checked_destination}
                      options={state.options_airport}
                      onClick={this.handleFilterByDestination}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByDestinationText}

                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                   */}
                  <Form.Field width="3">
                    <DropdownCheckbox
                      checked={state.checked_room}
                      options={state.options_room}
                      onClick={this.handleFilterByRoom}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByRoomText}
                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                </Wrap>
              ) : state.activeItem === "Flight" ? (
                <Wrap>
                  <Form.Field width="3">
                    <DropdownCheckbox
                      checked={state.checked_aircraft}
                      options={state.options_aircraft}
                      onClick={this.handleFilterByAircraft}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByAircraftText}
                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>

                  <Form.Field width="2">
                    <DropdownCheckbox
                      checked={state.checked_departure}
                      options={state.options_airport}
                      onClick={this.handleFilterByDeparture}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByDepartureText}

                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                  <Form.Field width="2">
                    <DropdownCheckbox
                      checked={state.checked_destination}
                      options={state.options_airport}
                      onClick={this.handleFilterByDestination}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByDestinationText}

                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                </Wrap>
              ) : state.activeItem === "Instructors" ? (
                <Form.Field width="3">
                  <DropdownCheckbox
                    checked={state.checked_inst}
                    options={state.options_inst}
                    onClick={this.handleFilterByInstructor}
                  >
                    <Button
                      fluid
                      size="small"
                      icon
                      basic
                      labelPosition="right"
                      style={{ textAlign: "left" }}
                    >
                      {state.filterByInstructorText}
                      <Icon name="angle down" />
                    </Button>
                  </DropdownCheckbox>
                </Form.Field>
              ) : (
                state.activeItem === "Rooms" && (
                  <Form.Field width="3">
                    <DropdownCheckbox
                      checked={state.checked_room}
                      options={state.options_room}
                      onClick={this.handleFilterByRoom}
                    >
                      <Button
                        fluid
                        size="small"
                        icon
                        basic
                        labelPosition="right"
                        style={{ textAlign: "left" }}
                      >
                        {state.filterByRoomText}
                        <Icon name="angle down" />
                      </Button>
                    </DropdownCheckbox>
                  </Form.Field>
                )
              )}
              <Form.Field>
                {state.canManage && (
                  <Button
                    positive
                    size="small"
                    onClick={this.onClickNew}
                    content="Book now"
                    disabled={
                      state.loading ||
                      (state.options_reservationtypes &&
                        state.options_reservationtypes.length === 0)
                    }
                  />
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginBottom: "0px" }}>
              <Form.Field width="6" style={{ paddingTop: "10px" }}>
                <DayPicker
                  day={state.seletedDate}
                  view={state.view}
                  onChange={this.handleChangeDate}
                />
              </Form.Field>
              <Form.Field
                width="12"
                style={{ paddingTop: "4px", textAlign: "right" }}
              >
                <Button size="mini" basic color="grey">
                  <Dropdown
                    icon={false}
                    value={state.view}
                    // text="Schedule view"
                    options={config.optionsSchedule}
                    onChange={this.handleChangeView}
                    selectOnBlur={false}
                  />
                </Button>
                <Button.Group
                  size="mini"
                  basic
                  color="grey"
                  style={{ border: "none" }}
                >
                  <Button>
                    <Dropdown
                      inline
                      icon={false}
                      value={state.start}
                      scrolling
                      options={state.option_start}
                      name="start"
                      onChange={this.handleChangeTime}
                      selectOnBlur={false}
                    />
                  </Button>
                  <Button.Or text=" to " />
                  <Button>
                    {" "}
                    <Dropdown
                      inline
                      icon={false}
                      value={state.stop}
                      scrolling
                      options={state.option_stop}
                      name="stop"
                      onChange={this.handleChangeTime}
                      selectOnBlur={false}
                    />{" "}
                  </Button>
                </Button.Group>
                {/*   <Button icon size='mini' style={{ marginLeft: '3px' }} basic color='grey'>
                                        <Icon name='print' />
                                    </Button>*/}
              </Form.Field>
            </Form.Group>
          </Form>
          <div style={{ textAlign: "right", fontSize: "85%" }} className="bold">
            <Icon name="circle" style={{ color: "#FF9800" }} /> Reserved &nbsp;
            &nbsp;
            <Icon name="circle" style={{ color: "#27A8A2" }} /> Completed &nbsp;
            &nbsp;
            <Icon name="circle" style={{ color: "#96a7af" }} /> Unsuccess &nbsp;
            &nbsp;
            <Icon name="circle" style={{ color: "#CE2921" }} /> Cancelled
          </div>
          {state.loading ? (
            <div style={{ textAlign: "center" }}>
              <Icon loading name="circle notch" />
              <br />
              Loading..
            </div>
          ) : state.view === "month" || state.view === "agenda" ? (
            <VeriCalendar
              start={state.start}
              stop={state.stop}
              day={state.seletedDate}
              views={state.view}
              events={state.events}
              eventClicked={this.handleShowDetailEvent}
              slotClicked={() => console.log("test")}
            />
          ) : (
            <BryntumScheduler
              canManage={state.canManage}
              start={state.start}
              stop={state.stop}
              day={state.seletedDate}
              qString={state.qString}
              viewName={state.view}
              eventClicked={this.handleShowDetailEvent}
              slotClicked={this.handleShowReservForm}
              eventResize={this.handelEventResize}
            />
          )}
        </Segment>
        <ModalForm
          open={state.open}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em",
          }}
          size="small"
          content="Reservation"
          close={this.onClickCancel}
        >
          <EventDetailForm
            data={state.eventDetail}
            canManage={state.canManage}
            onCancel={this.onClickCancelReservation}
            onDelete={this.onClickDeleteReservation}
            onEdit={this.onEditReserv}
          />
        </ModalForm>
        <ModalForm
          open={state.openNewReserv}
          style={{
            color: config.colorTheme,
            textAlign: "center",
            fontSize: "2em",
          }}
          // size='small'
          content={state.content}
          close={this.onClickCancel}
        >
          <ReservForm
            isNew={state.isNew}
            loading={state.loadingSave}
            initialValues={state.initialvaluesReserv}
            onCancel={this.onClickCancel}
            onSubmit={
              state.isNew ? this.onSubmitReserv : this.confirmUpdateReserv
            }
          />
        </ModalForm>
      </div>
    );
  }

  handleShowDetailEvent = (id) => {
    this.getDetail(id);
  };
  handelEventResize = (data) => {
    getEventDetail(data.id).then((res) => {
      if (res && res.status === 200) {
        let newdata = res.data;
        newdata.start = moment(data.startDate).format("YYYY-MM-DD HH:mm:ss");
        newdata.end = moment(data.endDate).format("YYYY-MM-DD HH:mm:ss");
        this.onEditReserv(newdata);
      }
    });
  };
  handleShowReservForm = (slotId, start, end) => {
    let { canManage } = this.state;
    if (canManage)
      this.setState({
        content: "New Reservation",
        openNewReserv: true,
        isNew: true,
        initialvaluesReserv: {
          resourceTypeId: slotId.split("-")[0],
          flightRules: "VFR",
          // language: 'TH',
          courseType: "Full Time",
          start: moment(start, "YYYY-MM-DD HH:mm:ss").format(
            "DD-MM-YYYY HH:mm"
          ),
          end: moment(end, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY HH:mm"),
          aircraft:
            slotId.split("-")[0] === "F"
              ? parseInt(slotId.split("-")[1])
              : null,
          instructor:
            slotId.split("-")[0] === "T"
              ? parseInt(slotId.split("-")[1])
              : null,
          roomId:
            slotId.split("-")[0] === "R"
              ? parseInt(slotId.split("-")[1])
              : null,
        },
      });
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name, loading: true });
    if (this.state.view === "month" || this.state.view === "agenda") {
      this.getEvent(name);
    } else this.setQStringForgetEvents(name);
    // this.setQStringForgetEvents(name)
  };

  handleChangeView = (e, data) => {
    this.setState({ view: data.value, loading: true });
    localStorage.setItem("ScheduleView", data.value);
    setTimeout(() => {
      if (data.value === "month" || data.value === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
    }, 300);
  };
  handleChangeTime = (e, data) => {
    this.setState({ loading: true });
    let { option_start, stop } = this.state;
    let option_stop = [];
    if (data.name === "start") {
      option_start.map((item) => {
        if (item.value > data.value) option_stop.push(item);
      });
      this.setState({
        start: data.value,
        option_stop,
        stop:
          data.value === "23:00"
            ? "23:00"
            : stop <= data.value
            ? option_stop[0].value
            : stop,
      });
    } else {
      this.setState({ stop: data.value });
    }
    setTimeout(() => {
      this.setState({ loading: false });
    }, 1500);
  };
  handleChangeDate = (seletedDate) => {
    this.setState({ seletedDate, loading: true });
    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };
  handleFilterByReservationtypes = (data) => {
    this.setState({ checked_reservationtypes: data, loading: true });
    let { reservationtypes } = this.state;
    if (data) {
      let filterByReservationText;
      if (data.length === reservationtypes.length) {
        filterByReservationText = "All reservation type";
      } else {
        filterByReservationText = `reservation type (${data.length})`;
      }
      this.setState({
        filterByReservationText: filterByReservationText,
      });
      setTimeout(() => {
        if (this.state.view === "month" || this.state.view === "agenda") {
          this.getEvent(this.state.activeItem);
        } else this.setQStringForgetEvents(this.state.activeItem);
        // this.setQStringForgetEvents(this.state.activeItem)
      }, 300);
    }
  };
  handleFilterByAircraft = (data) => {
    this.setState({ checked_aircraft: data, loading: true });
    let { options_aircraft, filterByAircraftText } = this.state;
    if (data.length === options_aircraft.length) {
      filterByAircraftText = "All aircraft";
    } else {
      filterByAircraftText = `aircraft (${data.length})`;
    }
    this.setState({
      filterByAircraftText: filterByAircraftText,
    });
    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };
  handleFilterByDeparture = (data) => {
    this.setState({ checked_departure: data, loading: true });
    let { options_airport, filterByDepartureText } = this.state;
    if (data.length === options_airport.length) {
      filterByDepartureText = "Departure";
    } else {
      filterByDepartureText = `Departure (${data.length})`;
    }
    this.setState({
      filterByDepartureText: filterByDepartureText,
    });
    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };
  handleFilterByDestination = (data) => {
    this.setState({ checked_destination: data, loading: true });
    let { options_airport, filterByDestinationText } = this.state;
    if (data.length === options_airport.length) {
      filterByDestinationText = "destination";
    } else {
      filterByDestinationText = `destination (${data.length})`;
    }
    this.setState({
      filterByDestinationText: filterByDestinationText,
    });

    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };

  handleFilterByRoom = (data) => {
    this.setState({ checked_room: data, loading: true });
    let { options_room, filterByRoomText } = this.state;
    if (data.length === options_room.length) {
      filterByRoomText = "All room";
    } else {
      filterByRoomText = `room (${data.length})`;
    }
    this.setState({
      filterByRoomText: filterByRoomText,
    });
    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };
  handleFilterByInstructor = (data) => {
    this.setState({ checked_inst: data, loading: true });
    let { options_inst, filterByInstructorText } = this.state;
    if (data.length === options_inst.length) {
      filterByInstructorText = "All instructor";
    } else {
      filterByInstructorText = `instructor (${data.length})`;
    }
    this.setState({
      filterByInstructorText: filterByInstructorText,
    });
    setTimeout(() => {
      if (this.state.view === "month" || this.state.view === "agenda") {
        this.getEvent(this.state.activeItem);
      } else this.setQStringForgetEvents(this.state.activeItem);
      // this.setQStringForgetEvents(this.state.activeItem)
    }, 300);
  };
}

export default withRouter(Schedule);
