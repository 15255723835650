import React, { Component } from "react";
import { Form, Button, Message, Image } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import Wrap from "../../components/wrap";
import { connect } from "react-redux";
import validate from "../../validate";
import renderField from "../../components/renderField";
import renderSelectField from "../../components/renderSelectField";
import renderRadioField from "../../components/renderRadioField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderDateField from "../../components/renderDateField";
import renderDateOnlyField from "../../components/renderDateOnlyField";
import renderTextAreaField from "../../components/renderTextAreaField";
import renderFileField from "../../components/renderFileField";
import renderRadioGroupField from "../../components/renderRadioGroupField";

import { roles } from "../Settings/data";

class FormPopup extends Component {
  state = {
    optionsRoles: [],
    optionsGroups: [
      {
        key: "2197ba29-9458-485a-95b4-1e79b118fa16",
        value: "AU05",
        text: "AU05",
      },
    ],
    radioMedicalClass: [
      { key: "m01", value: "I", label: "I" },
      { key: "m02", value: "II", label: "II" },
    ],
    optionsGender: [
      { key: "gender01", value: "male", text: "Male" },
      { key: "gender02", value: "female", text: "Female" },
    ],
    optionsInsType: [
      { key: "none", value: "", text: "None" },
      {
        key: "ins01",
        value: "Chief Flight Instructor",
        text: "Chief Flight Instructor",
      },
      {
        key: "ins02",
        value: "Asst. Chief Instructor",
        text: "Asst. Chief Instructor",
      },
      { key: "ins02", value: "Check Instructor", text: "Check Instructor" },
    ],
  };

  componentDidMount() {
    let options = [];
    roles.map((item) => {
      options.push({
        key: item.id,
        value: item.name,
        text: item.name,
      });
    });
    this.setState({ optionsRoles: options });
  }

  render() {
    const state = this.state;
    const props = this.props;
    const { handleSubmit, pristine, submitting } = this.props;
    // console.log("props.options_hospital", props.options_hospital)
    return (
      <div>
        {this.props.content === "profile" ? (
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Field
                name="firstName"
                type="text"
                required={true}
                component={renderField}
                label="First name (EN)"
              />
              <Field
                name="lastName"
                type="text"
                required={true}
                labelDesc="(EN)"
                component={renderField}
                label="Last name (EN)"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Field
                name="firstName_th"
                type="text"
                required={true}
                component={renderField}
                label="First name (TH)"
              />
              <Field
                name="lastName_th"
                type="text"
                required={true}
                component={renderField}
                label="Last name (TH)"
              />
            </Form.Group>
            <Field
              name="email"
              type="text"
              required={true}
              component={renderField}
              label="Email"
            />
            <Field
              name="companyName"
              type="text"
              required={true}
              component={renderField}
              label="Company Name"
            />
            <Field
              name="phone"
              type="text"
              required={true}
              component={renderField}
              label="Phone"
            />
            <Form.Group widths="equal">
              <Field
                name="birthDate"
                component={renderDateField}
                label="Date of Birth"
              />
              <Field
                name="gender"
                required={true}
                component={renderSelectField}
                label="Gender"
                options={state.optionsGender}
              />
            </Form.Group>
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "contact" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="name"
              type="text"
              required={true}
              component={renderField}
              label="Name"
            />
            <Field
              name="relat"
              type="text"
              required={true}
              component={renderField}
              label="Relationship"
            />
            <Field
              name="mobile_phone"
              type="text"
              required={true}
              component={renderField}
              label="Mobile Phone"
            />
            <Field
              name="work_phone"
              type="text"
              required={true}
              component={renderField}
              label="Work Phone"
            />
            <Field
              name="home_phone"
              type="text"
              required={true}
              component={renderField}
              label="Home Phone"
            />
            <Field
              name="email"
              type="text"
              required={true}
              component={renderField}
              label="E-mail"
            />
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "photo" ? (
          <Form onSubmit={handleSubmit}>
            <Image
              src={
                this.props.data.imageUrl ||
                "https://react.semantic-ui.com/images/wireframe/square-image.png"
              }
              size="small"
              circular
              centered
            />
            <Field
              name="file"
              type="file"
              required={true}
              component={renderFileField}
              label="Upload Picture"
            />
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "photoid" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="user"
              type="text"
              required={true}
              readOnly={true}
              component={renderField}
              label="Name"
            />
            <Field
              name="type"
              required={true}
              component={renderSelectField}
              label="Type"
              options={state.optionsGender}
            />
            <Field
              name="idnumber"
              type="text"
              required={true}
              component={renderField}
              label="ID Number"
            />
            <Field
              name="hasexp"
              label="Has Expiration"
              component={renderRadioField}
            />
            {/* <Field name="file" type="file"
                                        required={true}
                                        component={renderFileField} label="Upload File" /> */}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "cert" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="hospital_id"
              component={renderSelectField}
              label="Hospital"
              options={props.options_hospital}
            />
            <Field
              name="medicalclass"
              component={renderRadioGroupField}
              label="Medical Class"
              options={state.radioMedicalClass}
            />
            <Field
              name="expirydate"
              component={renderDateOnlyField}
              label="Med Expired Date"
            />
            {/* <Field name="file" type="file"
                                            component={renderFileField} label="Upload Med" /> */}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={this.props.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "pilot" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="splnumber"
              type="text"
              component={renderField}
              label={this.props.title + " No."}
            />
            <Field
              name="expirydate"
              component={renderDateOnlyField}
              label={this.props.title + " Expired Date"}
            />
            {/* <Field name="file" type="file"
                                                component={renderFileField} label="Upload SPL" /> */}
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={this.props.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "dcp" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="issueddate"
              type="text"
              component={renderDateOnlyField}
              label="Issued Date"
            />
            <Field
              name="expirydate"
              component={renderDateOnlyField}
              label="Expired Date"
            />
            <br />
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              loading={this.props.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.props.onCancel}
            />
          </Form>
        ) : this.props.content === "newdoc" ? (
          <Form onSubmit={handleSubmit}>
            <Field
              name="name"
              type="text"
              required={true}
              component={renderField}
              label="Name"
            />
            <Field
              name="desc"
              type="text"
              // required={true}
              component={renderTextAreaField}
              label="Description"
            />
            <Field
              name="file"
              type="file"
              removeabel
              // required={true}
              component={renderFileField}
              label="Attached file"
            />
            <Field
              name="expirydate"
              component={renderDateOnlyField}
              label="Expired Date"
            />
            {/* <Button compact type="submit" content='Save' color='green' disabled={pristine || submitting} /> */}
            <Button
              floated="left"
              type="submit"
              content="Save"
              color="green"
              // loading={state.loading}
              disabled={pristine || submitting}
            />
            <Button
              floated="right"
              type="button"
              content="Cancel"
              color="green"
              basic
              onClick={this.close}
            />
          </Form>
        ) : (
          <p>not content</p>
        )}
      </div>
    );
  }
}

FormPopup = reduxForm({
  // a unique name for the form
  form: "formpopup",
  validate,
  enableReinitialize: true,
})(FormPopup);

export default FormPopup;
