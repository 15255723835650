import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Message, Icon } from "semantic-ui-react";
import { Field } from "redux-form";
import renderSelectField from "../../components/renderSelectField";
import renderField from "../../components/renderField";
import renderCheckboxField from "../../components/renderCheckboxField";
import renderRadioGroupField from "../../components/renderRadioGroupField";
import renderCheckboxGroupField from "../../components/renderCheckboxGroupField";
import moment from "moment";
import Wrap from "../../components/wrap";

class FromReservation extends Component {
  state = {
    options_flightType: [],
    options_flightTypeIPSV: [
      {
        key: "ipflight",
        name: "ipflight",
        label: "Train IP",
        value: "ipflight",
      },
      {
        key: "svflight",
        name: "svflight",
        label: "SV Flight",
        value: "svflight",
      },
      {
        key: "ipproficiency",
        name: "ipproficiency",
        label: "IP Proficiency Check",
        value: "ipproficiency",
      },
    ],
    options_flightTypeMonitor: [
      {
        key: "monitor",
        name: "monitor",
        label: "Monitor",
        value: "monitor",
      },
    ],
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.reservationType &&
      nextProps.reservationType.name.indexOf("Non") >= 0
    ) {
      this.setState({
        options_flightType: [
          {
            key: "testflight",
            name: "testflight",
            label: "Test Flight",
            value: "testflight",
          },
          {
            key: "testengine",
            name: "testengine",
            label: "Test Engine",
            value: "testengine",
          },
          {
            key: "testnewaircraft",
            name: "testnewaircraft",
            label: "Test New Aircraft",
            value: "testnewaircraft",
          },
        ],
      });
    } else {
      this.setState({
        options_flightType: [
          {
            key: "testflight",
            name: "testflight",
            label: "Test Flight",
            value: "testflight",
          },
          {
            key: "testengine",
            name: "testengine",
            label: "Test Engine",
            value: "testengine",
          },
        ],
      });
    }
  }

  getLimitTime = (time, current) => {
    var startTime = moment(current, "hh:mm");
    var endTime = moment(time, "hh:mm");
    var duration = moment.duration(endTime.diff(startTime));
    var hours = parseInt(duration.asHours());
    var minutes = parseInt(duration.asMinutes()) % 60;
    return moment(hours + ":" + minutes, "H:m").format("HH:mm");
  };

  render() {
    const {
      reservationType,
      student,
      flightSubjectsByStudent,
      flightAddSubjectsByStudent,
      instuctorBySubject,
      aircraft,
      studentClass,
      instructorAll,
      instructors,
      locationBac,
      departureId,
      destinationId,
      flightType,
      flightTypeIPSV,
      flightTypeMonitor,
      monitortype,
      testtype,
      simCategory,
      studentAll,
      flightRulesSP,
      flightRulesIP,
      expiredIP,
      expiredSP,
      studentId,
      instructor,
    } = this.props;
    const state = this.state;
    return (
      <Wrap>
        <Field
          name="studentClass"
          component={renderSelectField}
          placeholder="Student Class"
          label="Filter by student class"
          options={studentClass}
          width="6"
          search
          disableclear
        />
        {reservationType && reservationType.name.indexOf("Non") >= 0 ? (
          <Field
            name="studentId"
            component={renderSelectField}
            placeholder="Student"
            label="Student"
            options={student}
            search
          />
        ) : (
          <Field
            name="studentId"
            component={renderSelectField}
            placeholder="Student"
            label="Student"
            options={student}
            search
            required
          />
        )}
        {studentId && flightRulesSP && (
          <Message size="small">
            {/* <Message.Header>Flight Time</Message.Header> */}
            {flightRulesSP &&
              flightRulesSP.map((item) =>
                item.type === "error" ? (
                  <p>
                    <Icon name="warning circle" style={{ color: "red" }} />
                    {`In ${item.days} day(s), Not over ${item.hours}`}
                    {" Hr(s) "}(
                    <span style={{ color: "red" }}>
                      {" "}
                      Over {item.balance} {" Hr(s) "}
                    </span>
                    )
                  </p>
                ) : (
                  <p>
                    <Icon name="check circle" style={{ color: "green" }} />
                    {`In ${item.days} day(s), Not over ${item.hours}`}
                    {" Hr(s) "}(
                    <span style={{ color: "green" }}>
                      {" "}
                      Available {item.balance} {" Hr(s) "}
                    </span>
                    )
                  </p>
                )
              )}
            {expiredSP &&
              expiredSP.map((item) =>
                item.type === "error" ? (
                  <p style={{ color: "red" }}>
                    <Icon name="file pdf circle" />
                    {item.message}
                  </p>
                ) : (
                  <p>
                    <Icon name="file pdf circle" style={{ color: "green" }} />
                    {item.message}
                  </p>
                )
              )}
          </Message>
        )}
        {reservationType && reservationType.name.indexOf("Non") >= 0 ? (
          <div>
            <Field
              name="customername"
              component={renderField}
              placeholder="Customer name"
              label="Customer name"
            />
            <Form.Group>
              <Field
                name="flightName"
                component={renderField}
                placeholder="Flight Name"
                label="Flight Name"
                required
              />
              <Field
                name="instructor"
                component={renderSelectField}
                placeholder="Instructor"
                label="Instructor"
                options={instructors}
                search
              />
            </Form.Group>
          </div>
        ) : reservationType &&
          reservationType.name.indexOf("Additional") >= 0 ? (
          <div>
            <Form.Group>
              <Field
                name="flightSubjects"
                component={renderSelectField}
                placeholder="Flight Subjects"
                label="Flight Subjects"
                options={flightAddSubjectsByStudent}
                search
                required
                clearable={true}
              />
              <Field
                name="instructor"
                component={renderSelectField}
                placeholder="Instructor"
                label="Instructor"
                options={instuctorBySubject}
                search
              />
            </Form.Group>
          </div>
        ) : (
          <div>
            <Form.Group>
              <Field
                width="8"
                name="flightSubjects"
                component={renderSelectField}
                placeholder="Flight Subjects"
                label="Flight Subjects"
                options={flightSubjectsByStudent}
                search
                clearable={true}
                required
              />
              <Field
                name="instructor"
                width="8"
                component={renderSelectField}
                placeholder="Instructor"
                label="Instructor"
                options={instuctorBySubject}
                search
                required
              />
            </Form.Group>
          </div>
        )}
        {instructor && flightRulesIP && (
          <Message size="small">
            {/* <Message.Header>Flight Time</Message.Header> */}

            {flightRulesIP &&
              flightRulesIP.map((item) =>
                item.type === "error" ? (
                  <p>
                    <Icon name="warning circle" style={{ color: "red" }} />
                    {`In ${item.days} day(s), Not over ${item.hours}`}
                    {" Hr(s) "}(
                    <span style={{ color: "red" }}>
                      {" "}
                      Over {item.balance} {" Hr(s) "}
                    </span>
                    )
                  </p>
                ) : (
                  <p>
                    <Icon name="check circle" style={{ color: "green" }} />
                    {`In ${item.days} day(s), Not over ${item.hours}`}
                    {" Hr(s) "}(
                    <span style={{ color: "green" }}>
                      {" "}
                      Available {item.balance} {" Hr(s) "}
                    </span>
                    )
                  </p>
                )
              )}
            {expiredIP &&
              expiredIP.map((item) =>
                item.type === "error" ? (
                  <p style={{ color: "red" }}>
                    <Icon name="file pdf circle" />
                    {item.message}
                  </p>
                ) : (
                  <p>
                    <Icon name="file pdf circle" style={{ color: "green" }} />
                    {item.message}
                  </p>
                )
              )}
          </Message>
        )}
        <Field
          name="aircraft"
          component={renderSelectField}
          placeholder="Aircraft"
          label="Aircraft"
          options={aircraft}
          search
          required
        />
        {!simCategory && (
          <Form.Group unstackable>
            <Field
              name="departureId"
              component={renderSelectField}
              placeholder="Departure"
              options={locationBac}
              search
            />
            <Field
              name="destinationId"
              component={renderSelectField}
              placeholder="Destination"
              options={locationBac}
              search
            />
          </Form.Group>
        )}

        {departureId && destinationId && departureId === destinationId && (
          <Field
            name="area"
            width="8"
            component={renderField}
            placeholder="Area"
          />
        )}
        <Field
          name="flightRules"
          component={renderRadioGroupField}
          inline
          label="Flight Rules"
          options={[
            { key: "VFR", label: "VFR", value: "VFR" },
            { key: "IFR", label: "IFR", value: "IFR" },
          ]}
        />
        <Field
          name="flightType"
          component={renderCheckboxGroupField}
          inline={true}
          label="Aircraft Test"
          options={state.options_flightType}
        />
        <Form.Group>
          <Form.Field width="4"></Form.Field>
          {flightType && (
            <Field
              name="testId"
              width="12"
              component={renderSelectField}
              placeholder="Test"
              options={testtype}
              search
            />
          )}
        </Form.Group>
        {reservationType && reservationType.name.indexOf("Training") >= 0 && (
          <Wrap>
            <Field
              name="flightTypeIPSV"
              component={renderCheckboxGroupField}
              inline={true}
              label="IP/SV"
              options={state.options_flightTypeIPSV}
            />
            <Form.Group>
              <Form.Field width="4"></Form.Field>
              {flightTypeIPSV === "ipflight" && (
                <Field
                  name="instructorIPId"
                  width="12"
                  component={renderSelectField}
                  placeholder="Instructor"
                  options={instructorAll}
                  search
                />
              )}
              {flightTypeIPSV === "svflight" && (
                <Field
                  name="instructorSVId"
                  width="12"
                  component={renderSelectField}
                  placeholder="Instructor"
                  options={instructorAll}
                  search
                />
              )}
              {flightTypeIPSV === "ipproficiency" && (
                <Field
                  name="instructorProId"
                  width="12"
                  component={renderSelectField}
                  placeholder="Instructor"
                  options={instructorAll}
                  search
                />
              )}
            </Form.Group>

            <Field
              name="flightTypeMonitor"
              component={renderCheckboxGroupField}
              inline={true}
              label="Monitor"
              options={state.options_flightTypeMonitor}
            />
            <Field
              name="flightTypeSim"
              component={renderCheckboxGroupField}
              inline={true}
              label="SIM"
              options={[
                {
                  key: "sim",
                  name: "sim",
                  label: "SIM",
                  value: "sim",
                },
                {
                  key: "simA320",
                  name: "simA320",
                  label: "SIM A320",
                  value: "simA320",
                },
              ]}
            />
            <Field
              name="nightFlight"
              component={renderCheckboxGroupField}
              inline={true}
              label="Night Flight"
              options={[
                {
                  key: "nightflight",
                  name: "nightflight",
                  label: "Night Flight",
                  value: "nightflight",
                },
              ]}
            />
            {(reservationType.name === "Flight Training" ||
              reservationType.name === "Flight Additional Training") && (
              <Wrap>
                <Field
                  name="flightTypeCAAT"
                  component={renderCheckboxGroupField}
                  inline={true}
                  label="CAAT Check"
                  options={[
                    {
                      key: "caatcheck",
                      name: "caatcheck",
                      label: "CAAT Check",
                      value: "caatcheck",
                    },
                  ]}
                />
                <Field
                  name="flightTypeTest"
                  component={renderCheckboxGroupField}
                  inline={true}
                  label="Check Solo"
                  options={[
                    {
                      key: "testtype",
                      name: "testtype",
                      label: "Check Solo",
                      value: "testtype",
                    },
                  ]}
                />
              </Wrap>
            )}
          </Wrap>
        )}
        <Form.Group>
          <Form.Field width="4">
            <label style={{ textAlign: "left" }}>Student Observer</label>
          </Form.Field>
          <Field
            name="SPObserver"
            width="12"
            multiple
            component={renderSelectField}
            placeholder="Student Multiple Observer"
            options={studentAll}
            search
          />
          {/* <Field
            name="studentObserver"
            width="12"
            component={renderSelectField}
            placeholder="Student Observer"
            options={studentAll}
            disabled
          /> */}
        </Form.Group>

        {/* {reservationType.name.indexOf("Non") >= 0 && ( */}
        <Form.Group>
          <Form.Field width="4">
            <label style={{ textAlign: "left" }}>Instructor Observer</label>
          </Form.Field>
          <Field
            name="IPObserver"
            width="12"
            multiple
            component={renderSelectField}
            placeholder="Instructor Multiple Observer"
            options={instructorAll}
            search
          />
          {/* <Field
            name="instructorObserver"
            width="12"
            component={renderSelectField}
            placeholder="Instructor Observer"
            options={instructorAll}
          /> */}
          {/* <Form.Select
            name="instructorObserver"
            width="12"
            // component={renderSelectField}
            placeholder="Instructor Observer"
            options={instructorAll}
            disabled
          /> */}
        </Form.Group>

        {/* )} */}

        <br />
        <Form.Group unstackable>
          <Field
            name="callSign"
            component={renderField}
            placeholder="Call Sign"
            label="Call Sign"
          />
        </Form.Group>
      </Wrap>
    );
  }
}

export default withRouter(FromReservation);
