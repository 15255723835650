import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { Grid, Icon, Header, Button, Image, Item, Table, Segment, Dropdown, Divider } from 'semantic-ui-react'
import moment from 'moment'
import * as config from '../../config'
import Wrap from '../../components/wrap';
import FlightData from './flightData'
import ModalForm from "../../components/modal";
import './style-flighttable.css'
import { getFlightTable, updateDepart, updateArrive, updateLanded, cancelDepart, cancelArrive, cancelLanded } from './service'
function setNumber(number) {
    return (number < 10 ? '0' : '') + number
}
const delay = <span style={{ color: 'yellow', backgroundColor: 'black' }}> &nbsp;&nbsp;Delay&nbsp;&nbsp;</span>
const change = <span style={{ color: '#29ff3e', backgroundColor: 'black' }}> &nbsp;&nbsp;Change&nbsp;&nbsp;</span>
const cancel = <span style={{ color: 'red', backgroundColor: 'black' }}> &nbsp;&nbsp;Cancel&nbsp;&nbsp;</span>
class FlightTable extends Component {
    state = {
        bacUserData: JSON.parse(localStorage.getItem("bacUserData")),
        bac_permission: JSON.parse(localStorage.getItem("bac_permission")),
        departures: [],
        arrivals: [],
        departed: [],
        arrived: [],
        open: false,
        currentValue: null,
        start_dp: 6,
        stop_dp: 21,
        option_start_dp: config.time,
        option_stop_dp: config.time,
        start_ar: 6,
        stop_ar: 21,
        option_start_ar: config.time,
        option_stop_ar: config.time,
        errors: null,
    }
    interval = null

    checkPermissionMenu = (group, permission) => {
        let { bac_permission } = this.state;
        let userpermissionGroup = bac_permission.find(item => item.group === group);
        let allowed = permission.find(item => {
            if (userpermissionGroup.permission.indexOf(item) !== -1) return item;
        });
        if (allowed) return true;
        else return false;
    };
    componentDidMount() {
        this.interval = setInterval(this.getEvents, 60000);
        this.getEvents();
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    getEvents = () => {
        let { start_dp, stop_dp, start_ar, stop_ar } = this.state
        let qStr = '?destinationStart=' + setNumber(start_ar)
            + '&destinationEnd=' + setNumber(stop_ar)
            + '&departureStart=' + setNumber(start_dp)
            + '&departureEnd=' + setNumber(stop_dp)
        getFlightTable(qStr).then(res => {
            // console.log('getFlightTable',res)
            if (res && res.status === 200) {
                this.setState({
                    departures: res.data.departure,
                    departed: res.data.departed,
                    arrivals: res.data.arrival,
                    arrived: res.data.arrivedlanded,
                    airport: res.data.location
                })
            }
        })
    }
    onClickRow = (cnt, data) => {
        // console.log(cnt, data)
        this.setState({ open: true, content: cnt, currentValue: data })

    }
    getAndSetEvents = (type, id) => {
        // console.log('getAndSetEvents');
        let { start_dp, stop_dp, start_ar, stop_ar } = this.state
        let qStr = '?destinationStart=' + setNumber(start_ar)
            + '&destinationEnd=' + setNumber(stop_ar)
            + '&departureStart=' + setNumber(start_dp)
            + '&departureEnd=' + setNumber(stop_dp)
        getFlightTable(qStr).then(res => {
            if (res && res.status === 200) {
                this.setState({
                    // departures: res.data.departure,
                    // arrivals: res.data.destination
                    departures: res.data.departure,
                    departed: res.data.departed,
                    arrivals: res.data.arrival,
                    arrived: res.data.arrivedlanded,
                    airport: res.data.location
                }, () => {
                    let { departures, arrivals, departed, arrived } = this.state
                    let options = []
                    options = [...departures, ...departed, ...arrivals, ...arrived]
                    const result = options.find(member => {
                        return member.id === id;
                    });
                    this.setState({ currentValue: result })
                })
            }
        })
    }

    close = () => this.setState({ open: false, errors: null })
    handleChangeTimeDP = (e, data) => {
        // console.log('handleChangeTimeDP');
        let { option_start_dp, stop_dp } = this.state
        let option_stop_dp = []
        if (data.name === 'start_dp') {
            option_start_dp.map(item => {
                if (item.value > data.value)
                    option_stop_dp.push(item)
            })
            this.setState({
                start_dp: data.value, option_stop_dp,
                stop_dp: data.value === '23:00' ? '23:00' : stop_dp <= data.value ? option_stop_dp[0].value : stop_dp
            }, () => this.getEvents())
        } else {
            this.setState({ stop_dp: data.value }, () => this.getEvents())
        }
    }
    handleChangeTimeAR = (e, data) => {
        // console.log('handleChangeTimeAR');
        let { option_start_ar, stop_ar } = this.state
        let option_stop_ar = []
        if (data.name === 'start_ar') {
            option_start_ar.map(item => {
                if (item.value > data.value)
                    option_stop_ar.push(item)
            })
            this.setState({
                start_ar: data.value, option_stop_ar,
                stop_ar: data.value === '23:00' ? '23:00' : stop_ar <= data.value ? option_stop_ar[0].value : stop_ar
            }, () => this.getEvents())
        } else {
            this.setState({ stop_ar: data.value }, () => this.getEvents())
        }
    }
    handleStampTime = (type) => {
        let { currentValue } = this.state
        if (type === 'Depart') {
            updateDepart(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Departures', currentValue.id);
                } else if (res && res.status === 429) {
                    this.setState({ errors: res.data.errors })
                }
            })
        } else if (type === 'Arrive') {
            updateArrive(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Arrival', currentValue.id);
                } else if (res && res.status === 429) {
                    this.setState({ errors: res.data.errors })
                }
            })
        } else if (type === 'Landed') {
            updateLanded(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Arrival', currentValue.id);
                } else if (res && res.status === 429) {
                    this.setState({ errors: res.data.errors })
                }
            })
        }
    }
    handleCancel = (type) => {
        let { currentValue, departure } = this.state
        if (type === 'Depart') {
            cancelDepart(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Departures', currentValue.id);
                    this.setState({ errors: null })
                }
            })
        } else if (type === 'Arrive') {
            cancelArrive(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Arrival', currentValue.id);
                    this.setState({ errors: null })
                }
            })
        } else if (type === 'Landed') {
            cancelLanded(currentValue.id).then(res => {
                if (res && res.status === 200) {
                    this.getAndSetEvents('Arrival', currentValue.id);
                    this.setState({ errors: null })
                }
            })
        }
    }
    render() {
        const state = this.state
        return (
            <div>
                <Segment className="contentSegment" textAlign='left'>
                    <Header as='h2' style={{ color: config.colorTheme }}>Flight Table {state.airport && `(${state.airport})`}</Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign='center'>
                                <Header as='h2' >{moment().format('MMMM Do YYYY')}</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal'>
                            <Grid.Column textAlign='center'>
                                <Segment>
                                    <Grid>
                                        <Grid.Row columns='2'>
                                            <Grid.Column textAlign='left'>
                                                <Header as='h3' >
                                                    {/*<Icon name='plane' />*/}
                                                    <Image src='./storage/icon/flight.png' />
                                                    <Header.Content> Departures</Header.Content>
                                                </Header>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button.Group size='mini' basic color='grey' style={{ border: 'none' }} floated='right'>
                                                    <Button>
                                                        <Dropdown
                                                            inline icon={false} value={state.start_dp}
                                                            scrolling
                                                            options={state.option_start_dp}
                                                            name='start_dp'
                                                            onChange={this.handleChangeTimeDP}
                                                            selectOnBlur={false}
                                                        />
                                                    </Button>
                                                    <Button.Or text=" to " />
                                                    <Button>
                                                        <Dropdown
                                                            inline icon={false} value={state.stop_dp}
                                                            scrolling
                                                            options={state.option_stop_dp}
                                                            name='stop_dp'
                                                            onChange={this.handleChangeTimeDP}
                                                            selectOnBlur={false}
                                                        />
                                                    </Button>
                                                </Button.Group>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column textAlign='center'>
                                <Segment>
                                    <Grid>
                                        <Grid.Row columns='2'>
                                            <Grid.Column textAlign='left'>
                                                {/*<Image src='./storage/icon/arrival.png' avatar/> Arrival*/}
                                                <Header as='h3' >
                                                    {/*<Icon name='plane' />*/}
                                                    <Image src='./storage/icon/airport.png' />
                                                    <Header.Content> Arrival</Header.Content>
                                                </Header>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Button.Group size='mini' basic color='grey' style={{ border: 'none' }} floated='right'>
                                                    <Button>
                                                        <Dropdown
                                                            inline icon={false} value={state.start_ar}
                                                            scrolling
                                                            options={state.option_start_ar}
                                                            name='start_ar'
                                                            onChange={this.handleChangeTimeAR}
                                                            selectOnBlur={false}
                                                        />
                                                    </Button>
                                                    <Button.Or text=" to " />
                                                    <Button>
                                                        <Dropdown
                                                            inline icon={false} value={state.stop_ar}
                                                            scrolling
                                                            options={state.option_stop_ar}
                                                            name='stop_ar'
                                                            onChange={this.handleChangeTimeAR}
                                                            selectOnBlur={false}
                                                        />
                                                    </Button>
                                                </Button.Group>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal' style={{ height: '40vh' }}>
                            <Grid.Column>
                                <Table className='flighttable' compact celled striped style={{ marginBottom: '30px', fontSize: '95%' }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className='header-table' width={3}>Reservation</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={1} textAlign='center'>Call Sign</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={5}>Aircraft</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3} textAlign='center'>Remark</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Destination</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Depart</Table.HeaderCell>
                                            {/* <Table.HeaderCell className='header-table' width={}>Action</Table.HeaderCell> */}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body style={{ maxHeight: '35vh' }}>
                                        {state.departures.length > 0 &&
                                            state.departures.map((item, index) => {
                                                return (
                                                    <Table.Row key={index} style={{ cursor: item.status === 3 ? 'default' : 'pointer' }} onClick={() => item.status !== 3 && this.onClickRow('DP', item)} >
                                                        <Table.Cell width={3}>{moment(item.start).format('HH:mm')} - {moment(item.end).format('HH:mm')}</Table.Cell>
                                                        <Table.Cell textAlign='center' width={1}>{item.flightData && item.flightData.callSign}</Table.Cell>
                                                        <Table.Cell width={5}>{item.aircraftName}</Table.Cell>
                                                        <Table.Cell textAlign='center' width={3}>
                                                            {
                                                                item.status === 3 ?
                                                                    cancel
                                                                    : item.history ?
                                                                        moment().isSameOrAfter(moment(item.start).add(30, 'minutes')) ?
                                                                            !item.flightData.departAt && <span>{change}&nbsp;{delay}</span>
                                                                            : change
                                                                        : moment().isSameOrAfter(moment(item.start).add(30, 'minutes')) ?
                                                                            !item.flightData.departAt && delay
                                                                            : ''
                                                            }
                                                        </Table.Cell>
                                                        <Table.Cell textAlign='center' width={2}>{item.flightData && item.flightData.destination}</Table.Cell>
                                                        <Table.Cell textAlign='center' width={2}>
                                                            <b>
                                                                {item.flightData.departAt ?
                                                                    moment(item.flightData.departAt).format('HH:mm')
                                                                    : <Image src='./storage/icon/depart_21x21.png' centered />
                                                                }
                                                            </b>
                                                            {/*{item.flightData.departAt && moment(item.flightData.departAt).isBetween(item.start, item.end) ?
                                                            <p style={{ color: 'green' }}>
                                                                {item.flightData.departAt && moment(item.flightData.departAt).format('HH:mm')}
                                                            </p>
                                                            : <p style={{ color: 'red' }}>
                                                                {item.flightData.departAt && moment(item.flightData.departAt).format('HH:mm')}
                                                            </p>
                                                        }*/}

                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column>
                                <Table className='flighttable' compact celled striped style={{ marginBottom: '30px', fontSize: '95%' }}>
                                    <Table.Header >
                                        <Table.Row>
                                            <Table.HeaderCell className='header-table' width={3}>Reservation</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={1} textAlign='center'>Call Sign</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3}>Aircraft</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3} textAlign='center'>Remark</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Departure</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Arrive</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Landed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body style={{ maxHeight: '35vh' }}>
                                        {state.arrivals.length > 0 && state.arrivals.map((item, index) => {
                                            return (
                                                <Table.Row key={index} style={{ cursor: item.status === 3 ? 'default' : 'pointer' }} onClick={() => item.status !== 3 && this.onClickRow('AR', item)}>
                                                    <Table.Cell width={3}>{moment(item.start).format('HH:mm')} - {moment(item.end).format('HH:mm')}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={1}> {item.flightData && item.flightData.callSign} </Table.Cell>
                                                    <Table.Cell width={3}> {item.aircraftName} </Table.Cell>
                                                    <Table.Cell textAlign='center' width={3}>
                                                        {
                                                            item.status === 3 ?
                                                                cancel
                                                                : item.history ?
                                                                    moment().isSameOrAfter(moment(item.end).add(30, 'minutes')) ?
                                                                        !item.flightData.arriveAt && <span>{change}&nbsp;{delay}</span>
                                                                        : change
                                                                    : moment().isSameOrAfter(moment(item.end).add(30, 'minutes')) ?
                                                                        !item.flightData.arriveAt && delay
                                                                        : ''
                                                        }

                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>{item.flightData && item.flightData.departure}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>
                                                        <b>
                                                            {item.flightData.arriveAt ?
                                                                moment(item.flightData.arriveAt).format('HH:mm')
                                                                : <Image src='./storage/icon/arrive_21x21.png' centered />
                                                            }
                                                        </b>
                                                        {/*{item.flightData.arriveAt && moment(item.flightData.arriveAt).isBetween(item.start, item.end) ?
                                                              <p style={{ color: 'green' }}>
                                                                  {item.flightData.arriveAt && moment(item.flightData.arriveAt).format('HH:mm')}
                                                              </p>
                                                              : <p style={{ color: 'red' }}>
                                                                  {item.flightData.arriveAt && moment(item.flightData.arriveAt).format('HH:mm')}
                                                              </p>
                                                          }*/}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>
                                                        <b>
                                                            {item.flightData.landedAt ?
                                                                moment(item.flightData.landedAt).format('HH:mm')
                                                                : <Image src='./storage/icon/landed_21x21.png' centered />
                                                            }
                                                        </b>
                                                        {/*  {item.flightData.landedAt && moment(item.flightData.landedAt).isBetween(item.start, item.end) ?
                                                              <p style={{ color: 'green' }}>
                                                                  {item.flightData.landedAt && moment(item.flightData.landedAt).format('HH:mm')}
                                                              </p>
                                                              : <p style={{ color: 'red' }}>
                                                                  {item.flightData.landedAt && moment(item.flightData.landedAt).format('HH:mm')}
                                                              </p>
                                                          }*/}
                                                    </Table.Cell>
                                                    {/* <Table.Cell>{item.orFor}</Table.Cell> */}
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>


                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns='equal' style={{ height: '35vh' }}>
                            <Grid.Column>
                                <Divider horizontal>DEPARTED</Divider>
                                <Table className='flighttable' compact celled striped style={{ marginBottom: '30px', fontSize: '95%' }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className='header-table' width={3}>Reservation</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={1} textAlign='center'>Call Sign</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={5}>Aircraft</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3} textAlign='center'>Remark</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Destination</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Depart</Table.HeaderCell>
                                            {/* <Table.HeaderCell className='header-table' width={}>Action</Table.HeaderCell> */}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body style={{ maxHeight: '25vh' }}>
                                        {state.departed.length > 0 && state.departed.map((item, index) => {
                                            return (
                                                <Table.Row key={index} style={{ cursor: item.status === 3 ? 'default' : 'pointer' }} onClick={() => item.status !== 3 && this.onClickRow('DP', item)}>
                                                    <Table.Cell width={3}>{moment(item.start).format('HH:mm')} - {moment(item.end).format('HH:mm')}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={1}>{item.flightData && item.flightData.callSign}</Table.Cell>
                                                    <Table.Cell width={5}>{item.aircraftName}</Table.Cell>
                                                    <Table.Cell width={3} textAlign='center'>
                                                        {
                                                            item.status === 3 ?
                                                                cancel
                                                                : item.history ?
                                                                    moment(item.flightData.departAt).isSameOrAfter(moment(item.start).add(30, 'minutes')) ?
                                                                        <span>{change}&nbsp;{delay}</span>
                                                                        : change
                                                                    : moment(item.flightData.departAt).isSameOrAfter(moment(item.start).add(30, 'minutes')) ?
                                                                        delay
                                                                        : ''
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>{item.flightData && item.flightData.destination}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>
                                                        <b>
                                                            {item.flightData.departAt ?
                                                                moment(item.flightData.departAt).format('HH:mm')
                                                                : <Image src='./storage/icon/depart_21x21.png' centered />
                                                            }
                                                        </b>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        }
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column>
                                <Divider horizontal>ARRIVED/LANDED</Divider>
                                <Table className='flighttable' compact celled striped style={{ marginBottom: '30px', fontSize: '95%' }}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className='header-table' width={3}>Reservation</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={1} textAlign='center'>Call Sign</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3}>Aircraft</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={3} textAlign='center'>Remark</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Departure</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Arrive</Table.HeaderCell>
                                            <Table.HeaderCell className='header-table' width={2} textAlign='center'>Landed</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body style={{ maxHeight: '25vh' }}>
                                        {state.arrived.length > 0 && state.arrived.map((item, index) => {
                                            return (
                                                <Table.Row key={index} style={{ cursor: item.status === 3 ? 'default' : 'pointer' }} onClick={() => item.status !== 3 && this.onClickRow('AR', item)}>
                                                    <Table.Cell width={3}>{moment(item.start).format('HH:mm')} - {moment(item.end).format('HH:mm')}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={1}> {item.flightData && item.flightData.callSign} </Table.Cell>
                                                    <Table.Cell width={3}> {item.aircraftName} </Table.Cell>
                                                    <Table.Cell width={3} textAlign='center'>
                                                        {
                                                            item.status === 3 ?
                                                                cancel
                                                                : item.history ?
                                                                    moment(item.flightData.arriveAt).isSameOrAfter(moment(item.end).add(30, 'minutes')) ?
                                                                        <span>{change}&nbsp;{delay}</span>
                                                                        : change
                                                                    : moment(item.flightData.arriveAt).isSameOrAfter(moment(item.end).add(30, 'minutes')) ?
                                                                        delay
                                                                        : ''
                                                        }
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>{item.flightData && item.flightData.departure}</Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>
                                                        <b>
                                                            {item.flightData.arriveAt ?
                                                                moment(item.flightData.arriveAt).format('HH:mm')
                                                                : <Image src='./storage/icon/arrive_21x21.png' centered />
                                                            }
                                                        </b>
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='center' width={2}>
                                                        <b>
                                                            {item.flightData.landedAt ?
                                                                moment(item.flightData.landedAt).format('HH:mm')
                                                                : <Image src='./storage/icon/landed_21x21.png' centered />
                                                            }
                                                        </b>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <ModalForm
                        size="tiny"
                        icon="plane"
                        content={state.content == 'DP' ? 'Departures' : 'Arrival'}
                        open={state.open}
                        close={this.close}>
                        {
                            state.currentValue &&
                            <Wrap>
                                <Grid >
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header as="h2" textAlign='center'>
                                                <Header.Content >
                                                    {state.currentValue.aircraftName}
                                                    <Header.Subheader>
                                                        {state.currentValue.title}
                                                    </Header.Subheader>
                                                </Header.Content>
                                            </Header>
                                            {/* <Header as='h2'>{state.currentValue.aircraftName}</Header> */}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width="7" textAlign="center">
                                            <Header
                                                as="h2"
                                                style={{ marginTop: "7px" }}
                                                content={state.currentValue.flightData.departure}
                                                subheader={moment(state.currentValue.start).format("HH:mm")}
                                            />
                                        </Grid.Column>
                                        <Grid.Column
                                            width="2"
                                            textAlign="center"
                                            verticalAlign="middle"
                                        >
                                            <Header as="h4" style={{ color: config.colorTheme }}>
                                                <Icon name="arrow right" />
                                            </Header>
                                        </Grid.Column>
                                        <Grid.Column width="7" textAlign="center">
                                            <Header
                                                as="h2"
                                                style={{ marginTop: "7px" }}
                                                content={state.currentValue.flightData.destination}
                                                subheader={moment(state.currentValue.end).format("HH:mm")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {state.content == 'DP' ?
                                        <Grid.Row>
                                            <Grid.Column width="3">
                                                <b>departAt</b>
                                            </Grid.Column>
                                            <Grid.Column width="5">
                                                {state.currentValue.flightData.departAt ?
                                                    moment(state.currentValue.flightData.departAt).format("YYYY-MM-DD HH:mm")
                                                    : '-'
                                                }
                                            </Grid.Column>
                                            <Grid.Column width="3">
                                                <b>departBy</b>
                                            </Grid.Column>
                                            <Grid.Column width="5">
                                                {state.currentValue.flightData.departBy || '-'}
                                            </Grid.Column>
                                        </Grid.Row>
                                        :
                                        <Wrap>
                                            <Grid.Row>
                                                <Grid.Column width="3">
                                                    <b>arriveAt</b>
                                                </Grid.Column>
                                                <Grid.Column width="5">
                                                    {state.currentValue.flightData.arriveAt ?
                                                        moment(state.currentValue.flightData.arriveAt).format("YYYY-MM-DD HH:mm")
                                                        : '-'
                                                    }
                                                </Grid.Column>
                                                <Grid.Column width="3">
                                                    <b>arriveBy</b>
                                                </Grid.Column>
                                                <Grid.Column width="5">
                                                    {state.currentValue.flightData.arriveBy || '-'}
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row style={{ paddingTop: 0 }}>
                                                <Grid.Column width="3">
                                                    <b>landedAt</b>
                                                </Grid.Column>
                                                <Grid.Column width="5">
                                                    {state.currentValue.flightData.landedAt ?
                                                        moment(state.currentValue.flightData.landedAt).format("YYYY-MM-DD HH:mm")
                                                        : '-'}
                                                </Grid.Column>
                                                <Grid.Column width="3">
                                                    <b>landedBy</b>
                                                </Grid.Column>
                                                <Grid.Column width="5">
                                                    {state.currentValue.flightData.landedBy || '-'}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Wrap>}
                                    {this.checkPermissionMenu("Schedule", ["manage-flighttable"]) &&
                                        <Grid.Row>
                                            {state.content == 'DP' ?
                                                <Grid.Column textAlign='center'>
                                                    {state.currentValue.flightData.departAt ?
                                                        <Button fluid onClick={() => this.handleCancel('Depart')}>Cancel Departed</Button>
                                                        : <Wrap>
                                                            <Button color='green' fluid onClick={() => this.handleStampTime('Depart')}>Depart</Button>
                                                            <p style={{ color: 'red' }}> {state.errors && "*" + state.errors}</p>
                                                        </Wrap>
                                                    }
                                                </Grid.Column>
                                                : <Grid.Column textAlign='center'>
                                                    {state.currentValue.flightData.arriveAt ?
                                                        state.currentValue.flightData.landedAt ?
                                                            <Button fluid onClick={() => this.handleCancel('Landed')}>Cancel Landed</Button>
                                                            : <Wrap>
                                                                <Button.Group fluid>
                                                                    <Button color='green' onClick={() => this.handleStampTime('Landed')}>Landed</Button>
                                                                    <Button.Or />
                                                                    <Button onClick={() => this.handleCancel('Arrive')}>Cancel Arrive</Button>
                                                                </Button.Group>
                                                                <p style={{ color: 'red' }}> {state.errors && "*" + state.errors}</p>
                                                            </Wrap>
                                                        : <Wrap>
                                                            <Button color='green' fluid onClick={() => this.handleStampTime('Arrive')}>Arrive</Button>
                                                            <p style={{ color: 'red' }}> {state.errors && "*" + state.errors}</p>
                                                        </Wrap>
                                                    }
                                                </Grid.Column>}
                                        </Grid.Row>
                                    }
                                </Grid>
                            </Wrap>
                        }
                    </ModalForm>
                </Segment>
            </div>
        )
    }
}

export default withRouter(FlightTable)
